import { Grid, Typography } from "@mui/material";
import { useFieldArray } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { AxiosResponse } from "axios";

import {
  FormArray,
  FormInput,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import NotificationLanguage from "./NotificationLanguage";
import { useCallback, useEffect, useState } from "react";
import { TNotificationForm } from "../NotificationSchema";

const translationPath = "page.register.notifications.";

interface INotificationContentsProps {
  service: (params?: any) => Promise<AxiosResponse<ISelectType[]>>;
}

const NotificationContents: React.FC<INotificationContentsProps> = ({
  service,
}) => {
  const { control, formState } = useFormContext<TNotificationForm>();
  const { t } = useTranslation();
  const [defaultLang, setDefaultLang] = useState<ISelectType>();
  const [languageOptions, setLanguageOptions] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(service);

  const loadLanguageOptionsHandler = useCallback(async () => {
    const { data, success } = await sendRequest(undefined);
    if (data && success) {
      setLanguageOptions(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    loadLanguageOptionsHandler();
  }, [loadLanguageOptionsHandler]);

  const documentsFieldArray = useFieldArray<TNotificationForm>({
    control,
    name: "notificationBatchContents",
  });

  const loadDefaultLang = useCallback((lang: ISelectType) => {
    setDefaultLang(lang);
  }, []);

  return (
    <Grid item xs={12}>
      <FormArray
        title={t(translationPath + "contents")}
        name="notificationBatchContents"
        showAddButton={
          documentsFieldArray.fields.length !== 0 && !defaultLang ? false : true
        }
        addButtonLabel={
          t(translationPath + "add_new_content") || "Add new content"
        }
        formArray={documentsFieldArray}
        appendValue={{
          id: "",
          content: "",
          gender: null,
          isHolder: "",
          isPayer: "",
          language: defaultLang ? defaultLang : languageOptions[0],
          maximumAge: "",
          minimumAge: "",
          name: "",
          profiles: [],
          title: "",
          users: [],
        }}
        fieldsObject={(index) => {
          const language = `notificationBatchContents.${index}.language`;
          const title = `notificationBatchContents.${index}.title`;
          const content = `notificationBatchContents.${index}.content`;

          return (
            <>
              <NotificationLanguage
                index={index}
                name={language}
                service={service}
                loadDefaultLang={loadDefaultLang}
                allLanguageOptions={languageOptions}
              />

              <Grid item xs={12} sm={6}>
                <FormInput
                  size="small"
                  name={title}
                  label={t(translationPath + "title") || "Title"}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormInput
                  size="small"
                  name={content}
                  label={t(translationPath + "content") || "Content"}
                  fullWidth
                  multiline
                  minRows={4}
                />
              </Grid>
            </>
          );
        }}
      />
      {formState.errors.notificationBatchContents && (
        <Typography variant="body2" color={"error"}>
          {formState.errors.notificationBatchContents.message
            ? t(`${formState.errors.notificationBatchContents.message}`)
            : formState.errors.notificationBatchContents.root?.message
            ? t(`${formState.errors.notificationBatchContents.root?.message}`)
            : null}
        </Typography>
      )}
    </Grid>
  );
};

export default NotificationContents;
