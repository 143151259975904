import { useTranslation } from "react-i18next";

import { FormInput, SwitchInput } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { buildTPath } from "../beneficiaryCancellationReason.utils";

const Fields = () => {
  const { t } = useTranslation();

  const translate = (path: string) => t(buildTPath(path));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} textAlign="end">
        <SwitchInput name="enable" label={translate("enable")} />
      </Grid>

      <Grid item xs={12} sm={12}>
        <FormInput
          size="small"
          name="name"
          fullWidth
          label={translate("name")}
        />
      </Grid>
    </Grid>
  );
};

export default Fields;
