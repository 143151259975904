import { useNotificationContext } from "@4uhub/lib4uhub";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { memo, useCallback } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTranslation } from "react-i18next";

const translationPath = "page.evaluation.forms.domains.";

export const DomainItem = memo(({ domain }: { domain: string }) => {
  const { t } = useTranslation();

  const { setMessage } = useNotificationContext();

  const onClickHandler = useCallback(() => {
    const link =
      domain[domain.length - 1] === "/"
        ? domain + "evaluationForm/{{id_pesquisa}}"
        : domain + "/evaluationForm/{{id_pesquisa}}";
    navigator.clipboard.writeText(link);
    setMessage({
      message: t(translationPath + "copied_successfully"),
      type: "success",
    });
  }, [domain, setMessage, t]);

  return (
    <Box
      sx={(t) => ({
        p: 2,
        height: 45,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: t.palette.background.default,
        borderRadius: 1,
        transition: "ease-in-out 0.1s",
        "&:hover": {
          backgroundColor:
            t.palette.grey[t.palette.mode === "light" ? 200 : 800],
        },
      })}
    >
      <Typography>{domain}</Typography>
      <Tooltip title={t(translationPath + "tooltip")} placement="left">
        <IconButton size="small" onClick={onClickHandler}>
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
});
