import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import {
  INewRestrictionRule,
  IRestrictionRule,
} from "../models/restriction-rules";

const RESTRICTION_RULE_EXTENSIONS_ROUTE =
  process.env.REACT_APP_4UBUS + "/api/v1/RestrictionRuleType/ListExtensions";

const RESTRICTION_RULE_ROUTE =
  process.env.REACT_APP_4UBUS + "/api/v1/RestrictionRule";

export const createRestrictionRule = (item: INewRestrictionRule) => {
  return GlobalAxios.post(RESTRICTION_RULE_ROUTE, item);
};

export const getRestrictionRuleById = (id: string) => {
  return GlobalAxios.get<IRestrictionRule>(RESTRICTION_RULE_ROUTE + "/" + id);
};

export const updateRestrictionRuleById = ({
  item,
  id,
}: {
  item: INewRestrictionRule;
  id: string;
}) => {
  return GlobalAxios.put(RESTRICTION_RULE_ROUTE + "/" + id, item);
};

export const restrictionRuleExtensions = (params?: any) => {
  return GlobalAxios.get<ISelectType[]>(RESTRICTION_RULE_EXTENSIONS_ROUTE, {
    params: params,
  });
};
