import { GlobalAxios } from "../axios/axios-config";

export interface IGetHealthPlan {
  id: string;
  establishmentId: string;
  name: string;
  ansProtocol: string;
  integrationId: string;
}

export class CollectorService<T extends any> {
  route_path: string;

  constructor(rp: string) {
    this.route_path = rp;
  }

  getAll = (establishmentId: string) => {
    return GlobalAxios.get<T[]>(this.route_path + establishmentId);
  };

  list = (params?: string) => {
    return GlobalAxios.get<IGetHealthPlan[]>(this.route_path + params);
  };
}

export default CollectorService;
