import { Box, Grid } from "@mui/material";
import { isSameDay, startOfDay } from "date-fns";
import React, { memo, useMemo, useRef } from "react";

import Day from "./Day";
import useElementSize from "../../../../hooks/useElementSize";
import useCalendar from "../CalendarContext/useCalendar";
import { DAY_HEADER_HEIGHT } from "./Header/Header";
import { groupEvents } from "../../utils";

interface IDayCalendarProps {
  days: Date[];
}

const DayCalendar: React.FC<IDayCalendarProps> = ({ days }) => {
  const ref = useRef(null);

  const { calendars } = useCalendar();

  const isOneDay = useMemo(() => days.length === 1, [days]);

  const { height } = useElementSize(ref);

  const showSourceColumns = useMemo(
    () => isOneDay && Object.keys(calendars).length > 0,
    [isOneDay, calendars]
  );

  const columns = useMemo(
    () => (showSourceColumns ? Object.keys(calendars).length : days.length),
    [showSourceColumns, calendars, days]
  );

  const firtDay = useMemo(() => days[0], [days]);

  return (
    <Box height={"100%"} width={"100%"}>
      <Grid
        container
        columns={columns}
        sx={{
          flexWrap: "nowrap",
        }}
        ref={ref}
      >
        {showSourceColumns &&
          calendars.map((c, i) => {
            const schedules = c.schedules.filter((s) =>
              isSameDay(new Date(s.calendarStartDate), firtDay)
            );

            const groupedSchedules = groupEvents(schedules);

            const sourceTitle = c.schedules[0].descriptionSite;

            return (
              <Day
                containerHeigth={height - DAY_HEADER_HEIGHT}
                schedules={groupedSchedules}
                showDay={false}
                sourceAvatar={"s.image"}
                sourceTitle={sourceTitle}
                date={startOfDay(firtDay)}
                index={i}
                key={c.identifier}
              />
            );
          })}

        {!showSourceColumns &&
          days.map((d, dIndex) => {
            const schedules = calendars.flatMap((s) =>
              s.schedules.filter(
                (e) =>
                  isSameDay(new Date(e.calendarStartDate), d) ||
                  isSameDay(new Date(e.calendarEndDate), d)
              )
            );
            
            const groupedSchedules = groupEvents(schedules);

            return (
              <Day
                containerHeigth={height - DAY_HEADER_HEIGHT}
                date={startOfDay(d)}
                index={dIndex}
                schedules={groupedSchedules}
                key={d.getTime()}
              />
            );
          })}
      </Grid>
    </Box>
  );
};

export default memo(DayCalendar);
