import { z } from "zod";

const translationPath = "components.ticketServiceAreaDays.errors.";

export const ticketServiceAreaDaySchema = z
  .object({
    id: z.string().optional(),
    enable: z.boolean(),
    dayOfWeek: z.string().min(1, translationPath + "day_of_week"),
    startTime: z.date({ invalid_type_error: translationPath + "initial_time" }),
    endTime: z.date({ invalid_type_error: translationPath + "end_time" }),
  })
  .superRefine((val, ctx) => {
    if (val.startTime >= val.endTime) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: translationPath + "initial_time_bigger",
        path: ["startTime"],
      });
    }
  });

export type TTicketServiceAreaDayForm = z.infer<
  typeof ticketServiceAreaDaySchema
>;
