import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../../../services/mainApi.service";
import { Table } from "@4uhub/lib4uhub";
import { IWorkGroupTypes } from "../../../../../models/work-group-type";
import { Chip } from "@mui/material";

const WORK_GROUP_TYPES_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/HealthProgramWorkGroupType";
const workGroupTypesService = new MainApiService<IWorkGroupTypes>(
  WORK_GROUP_TYPES_ROUTE
);

const translationPath = "page.settings.preventMedicine.workGroupTypes.";

const WorkGroupTypesPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { eI } = useParams();

  const columns: GridColDef<IWorkGroupTypes>[] = [
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
      renderCell: (params) => params.value ?? "-",
    },
    {
      field: "enable",
      headerName: t(translationPath + "status") || "Status",
      renderCell: (params) => (
        <Chip
          size="small"
          label={
            params.value
              ? t(translationPath + "active")
              : t(translationPath + "inactive")
          }
          color={params.value ? "success" : "error"}
        />
      ),
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IWorkGroupTypes>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<IWorkGroupTypes>
      showDefaultMacroFilters={false}
      service={workGroupTypesService}
      columns={columns}
      title={t(translationPath + "work_group_types")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      backButton
      backButtonUrl={`/e/${eI}/dashboard/settings`}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      defaultActions={false}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a work group type"
      }
      addButtonLabel={
        t(translationPath + "add_work_group_type") || "Add Work Group Type"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default WorkGroupTypesPage;
