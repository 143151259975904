import { Grid } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";


import FileValue from "./FileValue";
import { AutoComplete, ISelectType } from "@4uhub/lib4uhub";
import { IInputFile } from "@4uhub/lib4uhub"

//TODO IMPROVE PERFORMANCE
interface IIndivudalContactProps {
  index: number;
  mediaTypeList: ISelectType[];
  onFileSelected: (file: IInputFile) => void;
}

const translation_path = "page.register.extensions.";

const ExtensionFile: React.FC<IIndivudalContactProps> = ({
  index,
  mediaTypeList,
  onFileSelected,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} sm={4}>
        <AutoComplete
          getOptionLabel={(option) => {
            return option.name;
          }}
          size="small"
          label={t(translation_path + "media_type_code")}
          name={`extensionMediaFiles.${index}.mediaType`}
          options={mediaTypeList}
        />
      </Grid>
      <FileValue onFileSelected={onFileSelected} index={index} />
    </>
  );
};

export default memo(ExtensionFile);
