import { Card, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { IExtensionDependent } from "../../models/extensions-store";

const translationPath = "components.extensionsStoreDetail.";

const ExtensionDetailDependent = ({
  extensionDependencies,
}: {
  extensionDependencies: IExtensionDependent[];
}) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={12} md={12} mt={2}>
      <Typography
        variant="subtitle1"
        sx={(theme) => ({
          color:
            theme.palette.mode === "light"
              ? theme.palette.grey[600]
              : theme.palette.grey[400],
        })}
      >
        {t(translationPath + "dependencies")}:
      </Typography>
      <Card
        variant="outlined"
        sx={(theme) => ({
          mt: 2,
          maxHeight: 208,
          overflowY: "auto",
          backgroundColor:
            theme.palette.mode === "light" ? "#fff" : theme.palette.grey[900],
        })}
      >
        {extensionDependencies.map((dependent, index) => (
          <Stack
            p={2}
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            borderBottom={
              index !== extensionDependencies.length - 1
                ? "1px solid #ccc"
                : undefined
            }
            key={dependent.id}
          >
            <Typography variant="subtitle2">{dependent.name}</Typography>
          </Stack>
        ))}
      </Card>
    </Grid>
  );
};

export default ExtensionDetailDependent;
