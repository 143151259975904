import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../../../services/mainApi.service";
import { OverflowTooltip, Table } from "@4uhub/lib4uhub";
import { ITicketAutoFinishRules } from "../../../../../models/ticket-auto-finish-rule";
import { Chip } from "@mui/material";
import { format } from "date-fns";
import { ptBR, enUS } from "date-fns/locale";

const TICKET_AUTO_FINISH_RULE_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketAutoFinishRule/";
const ticketAutoFinishRuleService = new MainApiService<ITicketAutoFinishRules>(
  TICKET_AUTO_FINISH_RULE_ROUTE
);

const translationPath = "page.settings.crm.ticketAutoFinishRules.";

const TicketAutoFinishRulesPage = () => {
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { eI } = useParams();

  const columns: GridColDef<ITicketAutoFinishRules>[] = [
    {
      field: "ticketType",
      headerName: t(translationPath + "ticket_type") || "Ticket type",
      renderCell: (params) => {
        if (!params.value) return "-";
        return params.value.name;
      },
    },
    {
      field: "ticketChannel",
      headerName: t(translationPath + "ticket_channel") || "Board",
      renderCell: (params) => {
        if (!params.value) return "-";
        return params.value.name;
      },
    },
    {
      field: "ticketChannelStep",
      headerName: t(translationPath + "ticket_channel_step") || "Board list",
      renderCell: (params) => {
        if (!params.value) return "-";
        return params.value.name;
      },
    },
    {
      field: "message",
      headerName: t(translationPath + "message") || "Message",
      renderCell: (params) => {
        if (!params.value) return "-";
        return (
          <OverflowTooltip
            text={params.value}
            tooltip={params.value}
            variant="body2"
          />
        );
      },
    },
    {
      field: "minutes",
      headerName: t(translationPath + "minutes") || "Minutes",
      maxWidth: 100,
    },
    {
      field: "initialDate",
      headerName: t(translationPath + "initial_date") || "Initial date",
      maxWidth: 150,
      renderCell: (params) => {
        const date = new Date(params.value);
        return params.value
          ? format(date, "P", {
              locale: language === "pt-BR" ? ptBR : enUS,
            })
          : "-";
      },
    },
    {
      field: "endDate",
      headerName: t(translationPath + "end_date") || "End date",
      maxWidth: 150,
      renderCell: (params) => {
        const date = new Date(params.value);
        return params.value
          ? format(date, "P", {
              locale: language === "pt-BR" ? ptBR : enUS,
            })
          : "-";
      },
    },
    {
      field: "finish",
      headerName: t(translationPath + "finish") || "Finish",
      maxWidth: 150,
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            label={
              params.value
                ? t(translationPath + "finished")
                : t(translationPath + "not_finished")
            }
            color={params.value ? "success" : "error"}
          />
        );
      },
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<ITicketAutoFinishRules>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<ITicketAutoFinishRules>
      showDefaultMacroFilters={false}
      service={ticketAutoFinishRuleService}
      columns={columns}
      title={t(translationPath + "ticket_auto_finish_rules")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      backButton
      backButtonUrl={`/e/${eI}/dashboard/settings`}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a ticket auto finish rule"
      }
      addButtonLabel={
        t(translationPath + "add_ticket_auto_finish_rule") ||
        "Add Ticket Service Area"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default TicketAutoFinishRulesPage;
