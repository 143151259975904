import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const translationPath = "page.register.users.reset_password.";

interface IUserResetButtonsProps {
  loading: boolean;
  isAdmin: boolean;
  onCancel: () => void;
  onClickFunction?: () => void;
}

const UserResetButtons = ({
  loading,
  isAdmin,
  onCancel,
  onClickFunction,
}: IUserResetButtonsProps) => {
  const { t } = useTranslation();

  return (
    <Grid item sm={12}>
      <Stack direction={"row"} spacing={2}>
        <Button variant="outlined" fullWidth size={"small"} onClick={onCancel}>
          {t(translationPath + "cancel")}
        </Button>
        <Button
          variant="contained"
          disabled={loading ? true : false}
          fullWidth
          size={"small"}
          type={!isAdmin ? "submit" : "button"}
          onClick={!isAdmin ? undefined : onClickFunction}
        >
          {loading && (
            <Stack direction={"row"} spacing={2}>
              <CircularProgress size={18} />
              <Typography align="center" pt={0.5} variant="body2">
                {t(translationPath + "sending")}
              </Typography>
            </Stack>
          )}
          {!loading && t(translationPath + "confirm")}
        </Button>
      </Stack>
    </Grid>
  );
};

export default UserResetButtons;
