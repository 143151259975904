import { ptBR, enUS } from "date-fns/locale";
import { Grid } from "@mui/material";
import FormInformation from "../../../../../components/FormInformation/FormInformation";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

interface INotificationInformationsProps {
  statusName: string;
  isPublished: boolean;
  startPublish: string;
  finishPublish: string;
}

const translationPath = "components.notificationInformations.";

const NotificationInformations = ({
  statusName,
  startPublish,
  finishPublish,
  isPublished,
}: INotificationInformationsProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <>
      {statusName && (
        <Grid item sm={6}>
          <FormInformation
            label={t(translationPath + "status")}
            data={statusName}
          />
        </Grid>
      )}

      <Grid item sm={6}>
        <FormInformation
          label={t(translationPath + "is_published")}
          data={
            isPublished
              ? t(translationPath + "published")
              : t(translationPath + "not_published")
          }
        />
      </Grid>

      {startPublish.length !== 0 && (
        <Grid item sm={6}>
          <FormInformation
            label={t(translationPath + "start_publish")}
            data={format(new Date(startPublish), "P pp", {
              locale: language === "pt-BR" ? ptBR : enUS,
            }).slice(0, -3)}
          />
        </Grid>
      )}

      {finishPublish.length !== 0 && (
        <Grid item sm={6}>
          <FormInformation
            label={t(translationPath + "finish_publish")}
            data={format(new Date(finishPublish), "P pp", {
              locale: language === "pt-BR" ? ptBR : enUS,
            }).slice(0, -3)}
          />
        </Grid>
      )}
    </>
  );
};

export default NotificationInformations;
