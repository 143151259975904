import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import { AutoComplete } from "@4uhub/lib4uhub";

import SiteAppFileValue from "../SiteAppFileValue/SiteAppFileValue";
import { ISiteAppFileProps } from "./models";

//TODO IMPROVE PERFORMANCE

const translation_path = "page.register.extensions.";

const SiteAppFile: React.FC<ISiteAppFileProps> = ({
  index,
  mediaTypeList,
  onFileSelected,
  disable = false,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} sm={4}>
        <AutoComplete
          getOptionLabel={(option) => {
            return option.name;
          }}
          size="small"
          label={t(translation_path + "media_type_code")}
          name={`siteAppMediaFiles.${index}.mediaType`}
          options={mediaTypeList}
          disable={disable}
          sx={(t) =>
            disable
              ? {
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: t.palette.text.primary,
                  },
                }
              : null
          }
        />
      </Grid>
      <SiteAppFileValue
        onFileSelected={onFileSelected}
        index={index}
        disable={disable}
      />
    </>
  );
};

export default memo(SiteAppFile);
