import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  FormContainer,
  ISelectType,
  SwitchInput,
  useFetch,
} from "@4uhub/lib4uhub";
import HealthProgramTypeLanguages from "./HealthProgramTypeLanguages";
import {
  createHealthProgramType,
  getHealthProgramTypeById,
  updateHealthProgramTypeById,
} from "../../../../../../services/healthProgramType.service";
import {
  THealthProgramTypeForm,
  healthProgramTypeSchema,
} from "./HealthProgramTypeSchema";
import { useCallback, useEffect, useState } from "react";
import MainApi from "../../../../../../services/mainApi.service";

const translationPath = "page.settings.preventMedicine.healthProgramTypes.";

const LANGUAGE_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Language/";
const languageService = new MainApi<ISelectType>(LANGUAGE_ROUTE);

const HealthProgramType = () => {
  const { t } = useTranslation();

  const [allLanguages, setAllLanguages] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(languageService.list);

  const fetchLanguages = useCallback(async () => {
    const { data, success } = await sendRequest(undefined);
    if (data && success) {
      setAllLanguages(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchLanguages();
  }, [fetchLanguages]);

  const { sendRequest: save, loading: sLoading } = useFetch(
    createHealthProgramType
  );

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateHealthProgramTypeById
  );

  const { sendRequest: get, loading: gLoading } = useFetch(
    getHealthProgramTypeById
  );

  const updateHandler = (v: THealthProgramTypeForm, id: string) =>
    update({
      item: {
        ...v,
        enable: v.enable!,
        healthProgramTypeLanguages: v.healthProgramTypeLanguages.map(
          (languageItem) => ({
            ...languageItem,
            languageId: languageItem.language.id,
          })
        ),
      },
      id: id,
    });

  const saveHandler = (v: THealthProgramTypeForm) =>
    save({
      ...v,
      enable: true,
      healthProgramTypeLanguages: v.healthProgramTypeLanguages.map(
        (languageItem) => ({
          ...languageItem,
          languageId: languageItem.language.id,
        })
      ),
    });

  const loading = sLoading || uLoading || gLoading;

  if (allLanguages.length === 0) return null;

  return (
    <FormContainer<THealthProgramTypeForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={get}
      loading={loading}
      title={t(translationPath + "health_program_type")}
      schema={healthProgramTypeSchema}
      subtitle={t(translationPath + "new_health_program_type")}
      subtitleWatchField={"healthProgramTypeLanguages.0.name"}
      defaultValues={{
        healthProgramTypeLanguages: [
          {
            name: "",
            description: "",
            language: allLanguages[0],
          },
        ],
      }}
      fields={(type) => (
        <Grid container spacing={2}>
          {type === "update" && (
            <Grid item sm={12} textAlign={"end"}>
              <SwitchInput
                name="enable"
                label={t(translationPath + "enable")}
              />
            </Grid>
          )}

          <Grid item sm={12}>
            <HealthProgramTypeLanguages allLanguages={allLanguages} />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default HealthProgramType;
