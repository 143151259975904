import { z } from "zod";

const patientSchema = z.object(
  {
    id: z.string(),
    individualName: z.string(),
    identifier: z.string(),
    document: z.string(),
  },
  {
    invalid_type_error: "components.schedule.newSchedule.required",
  }
);

const insuranceSchema = z.object(
  {
    name: z.string(),
    id: z.string(),
  },
  {
    invalid_type_error: "components.schedule.newSchedule.required",
    required_error: "components.schedule.newSchedule.required"
  }
);

export const cardSchema = z.object({
  number: z.string().min(1, "components.schedule.newSchedule.required"),
  validity: z.date({
    required_error: "components.schedule.newSchedule.required",
    invalid_type_error: "components.schedule.newSchedule.required",
  }),
});

export const scheduleSchema = z.object({
  type: z.string(),
  patient: patientSchema,
  healthInsurance: insuranceSchema,
  identifer: z.number(),
});

export type TScheduleSchema = z.infer<typeof scheduleSchema>;
