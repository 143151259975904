import { memo, useCallback, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  AccessRegister,
  EstablishmentAutoComplete,
  FormCheckBox,
  FormInput,
  IEstablishment,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";

import EstablishmentsService from "../../../../services/establishments.service";

import Foreigner from "../../../../components/Foreigner";
import { useWatch } from "react-hook-form";
import { TLegalEntityForm } from "./LegalEntitySchema";
import LegalEntityGenericSelect from "./LegalEntityGenericSelect";
import { TFormType } from "@4uhub/lib4uhub/dist/types/components/Form/FormContainer/models";

const TRANSLATION_PATH = "page.register.legalPerson.";

const establishmentListService = new EstablishmentsService();

interface ILegalEntityBasicDataProps {
  type: TFormType;
}

const LegalEntityBasicData: React.FC<ILegalEntityBasicDataProps> = ({
  type,
}) => {
  const { sendRequest: listEstablishments } = useFetch(
    establishmentListService.list
  );

  const { t } = useTranslation();

  const [establishmentsList, setEstablishmentsList] = useState<
    IEstablishment[]
  >([]);
  
  const { control } = useFormContext<TLegalEntityForm>();

  const loadEstablishments = useCallback(async () => {
    const { data } = await listEstablishments(null);
    if (data) {
      setEstablishmentsList(data);
    }
  }, [listEstablishments]);

  useEffect(() => {
    loadEstablishments();
  }, [loadEstablishments]);

  const establishment = useWatch({
    name: "establishment",
    control,
  });

  return (
    <>
      <Grid item xs={12} sm={6}>
        <FormInput
          size="small"
          autoFocus={true}
          name="document"
          label={t(TRANSLATION_PATH + "document")}
          fullWidth
          mask="00.000.000/0000-00"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInput
          size="small"
          name="tradingName"
          label={t(TRANSLATION_PATH + "tradingName")}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FormInput
          size="small"
          name="corporateName"
          label={t(TRANSLATION_PATH + "corporateName")}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <LegalEntityGenericSelect
          label={t(TRANSLATION_PATH + "taxRegime")}
          name="taxRegime"
          identifier="TaxRegime"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <LegalEntityGenericSelect
          label={t(TRANSLATION_PATH + "fiscalContributor")}
          name="fiscalContributor"
          identifier="FiscalContributor"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormInput
          size="small"
          name="stateRegistration"
          label={t(TRANSLATION_PATH + "stateRegistration")}
          type="number"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormInput
          size="small"
          name="municipalInscription"
          label={t(TRANSLATION_PATH + "municipalInscription")}
          type="number"
          fullWidth
        />
      </Grid>
      {type === "create" && (
        <Grid item xs={12} sm={3}>
          <AccessRegister
            redirectTo="dashboard/admSystem/establishments"
            domainName="establishment"
            inputValue={
              establishment
                ? [{ id: establishment.id, name: establishment.name || "" }]
                : []
            }
            tooltip={t(TRANSLATION_PATH + "establishment_tooltip")}
          >
            <EstablishmentAutoComplete
              name="establishment"
              label={t(TRANSLATION_PATH + "establishmentId")}
              establishments={establishmentsList}
            />
          </AccessRegister>
        </Grid>
      )}

      <Grid item xs={12} sm={"auto"}>
        <FormCheckBox
          name="foreigner"
          label={t(TRANSLATION_PATH + "foreigner") + "?"}
        />
      </Grid>

      <Foreigner name="country" label={t(TRANSLATION_PATH + "country")} />
    </>
  );
};

export default memo(LegalEntityBasicData);
