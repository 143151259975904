import {
  AccessRegister,
  AutoComplete,
  ISelectType,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import { TNotificationForm } from "../NotificationSchema";

const translationPath = "page.register.notifications.";

const NotificationLanguage = ({
  name,
  index,
  service,
  loadDefaultLang,
  allLanguageOptions,
}: {
  name: string;
  index: number;
  service: (params?: any) => Promise<AxiosResponse<ISelectType[]>>;
  loadDefaultLang: (lang: ISelectType) => void;
  allLanguageOptions: ISelectType[];
}) => {
  const { control } = useFormContext<TNotificationForm>();
  const [languageOptions, setLanguageOptions] =
    useState<ISelectType[]>(allLanguageOptions);
  const { t } = useTranslation();

  const selectedLanguage = useWatch({
    name: "notificationBatchContents",
    control,
  });

  const loadLanguageOptions = useCallback(async () => {
    const selectedLang = selectedLanguage.find(
      (lang, indexLang) => indexLang === index
    );
    const filteredOptions = allLanguageOptions.filter(
      (objeto2) =>
        !selectedLanguage.some((objeto1) => objeto1.language?.id === objeto2.id)
    );
    loadDefaultLang(filteredOptions[0]);
    if (selectedLang?.language && filteredOptions.length !== 0) {
      setLanguageOptions([
        ...filteredOptions,
        { id: selectedLang.language.id, name: selectedLang.language.name },
      ]);
    } else {
      setLanguageOptions(filteredOptions);
    }
  }, [loadDefaultLang, selectedLanguage, index, allLanguageOptions]);

  useEffect(() => {
    loadLanguageOptions();
  }, [loadLanguageOptions]);

  return (
    <Grid item xs={12} sm={6}>
      <AccessRegister
        redirectTo="dashboard/globals/languages"
        domainName="language"
        inputValue={
          selectedLanguage[index].language
            ? [
                {
                  id: selectedLanguage[index].language!.id,
                  name: selectedLanguage[index].language!.name,
                },
              ]
            : []
        }
        tooltip={t(translationPath + "language_tooltip")}
      >
        <AutoComplete
          label={t(translationPath + "language") || "Language"}
          name={name}
          size="small"
          getOptionLabel={(option) => {
            return option.name;
          }}
          options={languageOptions}
          filterSelectedOptions
        />
      </AccessRegister>
    </Grid>
  );
};

export default NotificationLanguage;
