import { Stack, Typography } from "@mui/material";

import { memo } from "react";
import useDate from "../../../../../hooks/useDate";

interface IZHourProps {
  date: Date;
  showZHour: boolean;
}

const ZHour: React.FC<IZHourProps> = ({ date, showZHour }) => {
  const { localeFormat } = useDate();
  return (
    <Stack
      sx={(t) => ({
        justifyContent: "center",
        alignItems: "center",
        borderRight: `1px solid ${t.palette.divider}`,
        height: "100%",
      })}
    >
      {showZHour && (
        <Typography width={42} fontSize={10}>
          {localeFormat(date, "z")}
        </Typography>
      )}
    </Stack>
  );
};

export default memo(ZHour);
