import { GlobalAxios } from "./../axios/axios-config";
import {
  IGetContentById,
  IHealthProgramContentRuleContent,
  INewContent,
  IUpdateContent,
} from "../models/content";

const CONTENT_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Content/";

const HEALTH_PROGRAM_RULE_CONTENT_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/HealthProgramContentRule/Content";

export const createContent = (item: INewContent) => {
  return GlobalAxios.post(CONTENT_ROUTE, item);
};

export const getContentById = (id: string) => {
  return GlobalAxios.get<IGetContentById>(CONTENT_ROUTE + id);
};

export const updateContentById = ({
  item,
  id,
}: {
  item: IUpdateContent;
  id: string;
}) => {
  return GlobalAxios.put(CONTENT_ROUTE + id, item);
};

export const deleteContentById = (id: string) => {
  return GlobalAxios.delete(CONTENT_ROUTE + id);
};

export const createHealthProgramRuleContent = (
  item: IHealthProgramContentRuleContent
) => {
  return GlobalAxios.post(HEALTH_PROGRAM_RULE_CONTENT_ROUTE, item);
};

export const deleteHealthProgramRuleContent = (
  id: string,
  contentConfigId: string
) => {
  return GlobalAxios.delete<boolean, boolean>(
    process.env.REACT_APP_MAIN +
      `/api/v1/HealthProgramContentRule/${id}/Content/${contentConfigId}`
  );
};
