import { Box, Tooltip, Typography } from "@mui/material";
import { memo, useCallback } from "react";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";
import { CustomButton } from "./CustomButton/CustomButton";
import { Button, useFormContext } from "@4uhub/lib4uhub";
import {
  ICustomButton,
  useTemplateWhats,
} from "../../../../store/contexts/TemplateWhatsContext";
import { useTranslation } from "react-i18next";

const translationPath = "components.quickAnswerButtons.";

export const QuickAnswerButtons = memo(() => {
  const { t } = useTranslation();

  const { customButtons, ctaButtons, reorderCustomButtons, toggleButtonsList } =
    useTemplateWhats();

  const { setValue } = useFormContext();

  const hasTwoSections = ctaButtons.length > 0 && customButtons.length > 0;

  const changeOrder = useCallback(() => {
    toggleButtonsList("1");
  }, [toggleButtonsList]);

  const reorder = useCallback(
    (list: ICustomButton[], startIndex: number, endIndex: number) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      setValue(`customButtons.${startIndex}`, { ...removed, order: endIndex });

      return result;
    },
    [setValue]
  );

  const onDragEnd: OnDragEndResponder = useCallback(
    (e) => {
      if (!e.destination) {
        return;
      }

      const itemsReordered = reorder(
        customButtons,
        e.source.index,
        e.destination.index
      );

      reorderCustomButtons(itemsReordered);
    },
    [customButtons, reorderCustomButtons, reorder]
  );

  if (customButtons.length === 0) return null;

  return (
    <Box
      sx={(t) => ({
        p: 2,
        border: 1,
        borderColor: t.palette.divider,
        borderRadius: 1,
      })}
    >
      <Box sx={{ mb: 4, display: "flex", alignItems: "center", gap: 1 }}>
        {hasTwoSections && (
          <Tooltip
            title={t(translationPath + "change_buttons_order")}
            placement="top"
          >
            <Box>
              <Button size="small" sx={{ minWidth: 0 }} onClick={changeOrder}>
                <SwapVertIcon fontSize="small" color="primary" />
              </Button>
            </Box>
          </Tooltip>
        )}
        <Typography variant="body2" fontWeight="bold">
          {t(translationPath + "quick_aswer")}
        </Typography>
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="buttonsList" direction="vertical">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {customButtons.map((btn, index) => (
                <CustomButton
                  key={btn.id}
                  index={index}
                  button={btn}
                  customButtonsLength={customButtons.length}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
});
