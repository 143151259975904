import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import Fields from "./Fields";
import { FormContainer, useFetch } from "@4uhub/lib4uhub";

import { buildTPath, salesContractFormService } from "../contract-classifications.utils";
import { contractClassificationchema, TContractClassificationForm } from "./contractClassificationSchema";

const ContractClassification = () => {
  const { t } = useTranslation();

  const { sendRequest: create, loading: isSaving } = useFetch(
    salesContractFormService.create
  );
  const { sendRequest: update, loading: isUpdating } = useFetch(
    salesContractFormService.updateItemById
  );
  const { sendRequest: findById, loading: isFetching } = useFetch(
    salesContractFormService.getItemById
  );

  const handleFormValues = ({ 
    salesContractType: { id },
    ...rest
  }: TContractClassificationForm) => ({
    ...rest, salesContractTypeId: id,
  } as TContractClassificationForm & { salesContractTypeId: string });

  const getHandler = useCallback(async (id: string) => 
    findById(id),[findById]);

  const createHandler = (values: TContractClassificationForm) => 
    create(handleFormValues(values));

  const updateHandler = (values: TContractClassificationForm, id: string) => 
    update({ 
      id, 
      item: handleFormValues({ ...values, id } as TContractClassificationForm) 
    });

  const isLoading = isSaving || isUpdating || isFetching;

  return (
    <FormContainer<TContractClassificationForm>
      saveFunction={createHandler}
      updateFunction={updateHandler}
      getFunction={getHandler as any}
      loading={isLoading}
      title={t(buildTPath("contract_classification"))}
      schema={contractClassificationchema}
      subtitle={t(buildTPath("new_contract_classification"))}
      subtitleWatchField="name"
      fields={() => <Fields />}
    />
  );
};

export default ContractClassification;
