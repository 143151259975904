import { z } from "zod";

const translationPath = "page.register.questionsAnswers.errors.";

export const questionAnswerSchema = z.object({
  id: z.string().optional(),
  enable: z.boolean(),
  displayOrder: z.string().min(1, translationPath + "display_order"),
  question: z.string().min(1, translationPath + "question"),
  answer: z.string().min(1, translationPath + "answer"),
});

export type TQuestionAnswerForm = z.infer<typeof questionAnswerSchema>;
