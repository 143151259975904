import { Grid } from "@mui/material";
import { PropsWithChildren, ReactElement } from "react";

const TabContainer = ({ children }: PropsWithChildren): ReactElement => {
  return (
    <Grid
      container
      spacing={2}
      boxSizing="border-box"
      p={4}
      borderRadius={1}
      boxShadow=""
      sx={{
        margin: "0 auto",
        background: "#FFFFFF",
        marginBottom: 2,
      }}
    >
      <Grid item sm={12} mb={5}>
        {children}
      </Grid>
    </Grid>
  );
};

export default TabContainer;
