import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

// Schema
import {
  AutoComplete,
  AutoCompleteMulti,
  FormContainer,
  FormInput,
  ISelectType,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import {
  createEvent,
  getEventById,
  updateEventById,
} from "../../../../../../services/event.service";
import { TEventForm, eventSchema } from "./EventSchema";
import { useCallback, useState } from "react";
import MainApi from "../../../../../../services/mainApi.service";
import FormInformation from "../../../../../../components/FormInformation/FormInformation";

const translationPath = "page.settings.crm.events.";

const EVENT_TYPES_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketEventType/";
const eventTypesService = new MainApi<ISelectType>(EVENT_TYPES_ROUTE);

const TICKET_AUTOMATION_ROUTE =
  process.env.REACT_APP_AUTOMATIONS + "/api/v1/TicketAutomation/";
const ticketAutomationService = new MainApi<ISelectType>(
  TICKET_AUTOMATION_ROUTE
);

const Event = () => {
  const { t } = useTranslation();

  const [code, setCode] = useState<string>("");

  const { sendRequest: save, loading: sLoading } = useFetch(createEvent);

  const { sendRequest: update, loading: uLoading } = useFetch(updateEventById);

  const { sendRequest: get, loading: gLoading } = useFetch(getEventById);

  const handleGet = useCallback(
    async (id: string): Promise<Treturn<TEventForm>> => {
      const { data, success } = await get(id);
      if (data) {
        setCode(data.code || "");

        return {
          data: {
            ...data,
            eventType: data.ticketEventType,
          },
          success: true,
        };
      }
      return { data, success };
    },
    [get]
  );

  const handleSave = (v: TEventForm) => {
    return save({
      ...v,
      ticketEventTypeId: v.eventType.id,
      ticketAutomations: v.ticketAutomations
        ? v.ticketAutomations.map((t) => t.id)
        : [],
    });
  };

  const handleUpdate = (v: TEventForm, id: string) => {
    return update({
      item: {
        ...v,
        ticketEventTypeId: v.eventType.id,
        ticketAutomations: v.ticketAutomations
          ? v.ticketAutomations.map((t) => t.id)
          : [],
      },
      id: id,
    });
  };

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TEventForm>
      saveFunction={handleSave}
      updateFunction={handleUpdate}
      getFunction={handleGet}
      loading={loading}
      title={t(translationPath + "event")}
      schema={eventSchema}
      subtitle={t(translationPath + "new_event")}
      subtitleWatchField={"name"}
      fields={(type) => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            <SwitchInput name="enable" label={t(translationPath + "enable")} />
          </Grid>

          {type === "update" && (
            <Grid item xs={12} sm={12}>
              <FormInformation
                label={t(translationPath + "code")}
                data={code}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6}>
            <AutoComplete
              name="eventType"
              label={t(translationPath + "event_type")}
              getOptionLabel={(option) => option.name}
              size="small"
              fullWidth
              autoFocus
              request={eventTypesService.list}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="name"
              label={t(translationPath + "name")}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <AutoCompleteMulti
              name="ticketAutomations"
              label={t(translationPath + "ticket_automations")}
              getOptionLabel={(option) => option.name}
              size="small"
              fullWidth
              request={ticketAutomationService.list}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default Event;
