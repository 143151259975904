import { IconButton, Stack, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { tokenService } from "../../../services/token.service";
import {
  removePermissions,
  removeUserName,
} from "../../../services/user.service";
import { clearEstablishmentCode, useUser } from "@4uhub/lib4uhub";

const UserAvatar = () => {
  const navigate = useNavigate();
  const { removeAllTokens } = tokenService;

  const logout = useCallback(() => {
    removeAllTokens();
    removeUserName();
    removePermissions();
    clearEstablishmentCode();
    navigate("/login", { replace: true });
  }, [navigate, removeAllTokens]);

  const { user } = useUser();

  return (
    <Stack direction={"row"} justifyContent={"space-between"}>
      <Stack direction={"column"}>
        <Typography variant="body2">{user.fullName}</Typography>
        <Typography
          variant="caption"
          color={(theme) => theme.palette.text.secondary}
        >
          {user.email}
        </Typography>
      </Stack>
      <IconButton color="primary" aria-label="logout" onClick={logout}>
        <LogoutIcon />
      </IconButton>
    </Stack>
  );
};

export default UserAvatar;
