import { eachDayOfInterval, endOfWeek, startOfWeek } from "date-fns";

import DayCalendar from "../DayCalendar/DayCalendar";
import useCalendar from "../CalendarContext/useCalendar";

const WeekCalendar = () => {
  const { date } = useCalendar();

  const days = eachDayOfInterval({
    start: startOfWeek(date),
    end: endOfWeek(date),
  });

  return <DayCalendar days={days} />;
};

export default WeekCalendar;
