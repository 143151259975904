import { Stack, SxProps, Theme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { memo } from "react";
import { Button, DateInput, Form, FormInput, Loading } from "@4uhub/lib4uhub";
import { useForm } from "react-hook-form";

import { TAddHealth, addInsuranceSchema } from "./schema";
import { useInsurance } from "./useInsurance";
import SelectInsurance from "./SelectInsurance";

const KEYS = addInsuranceSchema.keyof().Enum;

interface IInsuranceFormProps {
  onSuccess: () => void;
  sx?: SxProps<Theme>;
  patient: {
    individualName: string;
    id: string;
    document: string;
  };
  id?: string;
}

const InsuranceForm: React.FC<IInsuranceFormProps> = ({
  onSuccess,
  sx,
  patient,
  id,
}) => {
  const { t } = useTranslation();

  const methods = useForm<TAddHealth>({
    resolver: zodResolver(addInsuranceSchema),
    defaultValues: {
      individual: {
        code: patient.id,
        name: patient.individualName,
        id: patient.id,
      },
    },
  });

  const { isEditMode, loading, getLoading, onSubmit } = useInsurance(
    methods,
    onSuccess,
    id
  );

  return (
    <Form {...methods}>
      <Stack gap={2} sx={sx}>
        {getLoading && <Loading />}
        <SelectInsurance />
        <DateInput
          label={t("components.schedule.newSchedule.insurance.validity.label")}
          name={KEYS.validate}
        />
        <FormInput
          size="small"
          fullWidth
          label={t("components.schedule.newSchedule.insurance.number.label")}
          name={KEYS.code}
        />
        <Button
          variant="contained"
          sx={{ alignSelf: "end" }}
          onClick={methods.handleSubmit(onSubmit)}
          loading={loading}
        >
          {t(
            `components.schedule.newSchedule.insurance.${
              isEditMode ? "edit" : "add"
            }`
          )}
        </Button>
      </Stack>
    </Form>
  );
};

export default memo(InsuranceForm);
