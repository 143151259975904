import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ReactNode } from "react";

interface ISettingsCacheContainer {
  title: string;
  description: string;
  gap: number;
  children: ReactNode;
  headerWidth?: number | string;
}

const SettingsCacheContainer = ({
  title,
  description,
  gap,
  children,
  headerWidth,
}: ISettingsCacheContainer) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack
      display={"flex"}
      flexDirection={!matches ? "row" : "column"}
      alignItems={!matches ? "center" : "end"}
      justifyContent={!matches ? "space-between" : undefined}
      gap={gap}
    >
      <Stack width={headerWidth ? headerWidth : undefined}>
        <Typography variant="subtitle1" fontWeight={"bold"}>
          {title}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={(theme) => ({
            color:
              theme.palette.mode === "light"
                ? theme.palette.grey[600]
                : theme.palette.grey[400],
          })}
        >
          {description}
        </Typography>
      </Stack>
      {children}
    </Stack>
  );
};

export default SettingsCacheContainer;
