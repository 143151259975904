import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";

import {
  Form,
  FormInput,
  useFetch,
  useNotificationContext,
} from "@4uhub/lib4uhub";
import { ISettingsGeneral } from "../../../../../models/settings";
import SettingsService from "../../../../../services/settings.service";
import SettingsPageContainer from "../../../../../components/Settings/SettingsPageContainer";
import {
  TSettingsGeolocalizationForm,
  settingsGeolocalizationSchema,
} from "./SettingsGeolocalizationSchema";
import { useAppSelector } from "../../../../../store/store";

const translationPath = "components.settingsGeneral.";

const service = new SettingsService();

const SettingsGeolocalization = () => {
  const { t } = useTranslation();
  const [userSettings, setUserSettings] = useState<ISettingsGeneral>();
  const methods = useForm<TSettingsGeolocalizationForm>({
    resolver: zodResolver(settingsGeolocalizationSchema),
  });
  const { setMessage } = useNotificationContext();
  const { sendRequest } = useFetch(service.getSettingsGeneral);
  const { sendRequest: update, loading } = useFetch(
    service.updateSettingsGeneral
  );
  const generalSettings = useAppSelector(
    (state) => state.settingsGeneral.settings
  );

  const onLoadSettingsGeneral = useCallback(async () => {
    const { data } = await sendRequest(undefined);
    if (data) {
      setUserSettings(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    onLoadSettingsGeneral();
  }, [onLoadSettingsGeneral]);

  useEffect(() => {
    if (userSettings) {
      methods.reset({
        ...userSettings,
      });
    }
  }, [userSettings, methods]);

  const onSubmitHandler = useCallback(
    async (values: TSettingsGeolocalizationForm) => {
      const { success } = await update({
        item: {
          ...values,
          cepCacheTime: Number(generalSettings.cepCacheTime),
          correiosPageSize: Number(generalSettings.correiosPageSize),
          correiosAddressesUrl: generalSettings.correiosAddressesUrl,
          correiosAuthenticationUrl: generalSettings.correiosAuthenticationUrl,
          correiosLocationsUrl: generalSettings.correiosLocationsUrl,
          correiosTokenAccessCode: generalSettings.correiosTokenAccessCode,
          correiosTokenPostCard: generalSettings.correiosTokenPostCard,
          correiosTokenUser: generalSettings.correiosTokenUser,
          correiosUrl: generalSettings.correiosUrl,
          bankBancosUrl: generalSettings.bankBancosUrl,
          bankBaseUrl: generalSettings.bankBaseUrl,
          bankCooperativasUrl: generalSettings.bankCooperativasUrl,
        },
      });
      if (success) {
        setMessage({
          message: `${t(translationPath + "success")}`,
          type: "success",
          notificationKey: new Date().getMilliseconds(),
        });
      }
    },
    [update, setMessage, t, generalSettings]
  );

  const { handleSubmit } = methods;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <SettingsPageContainer title="Configurações de geolocalização">
      <Form {...methods}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitHandler)}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: matches ? "100%" : "70%",
            mt: 2,
          }}
        >
          <Grid container columnSpacing={2} mt={5}>
            <Grid item xs={12} sm={12} mb={2}>
              <FormInput
                size="small"
                name="apiGeocodingKey"
                fullWidth
                label={t(translationPath + "api_geocoding_key")}
              />
            </Grid>
            <Grid item xs={12} sm={6} mb={2}>
              <Button
                variant="contained"
                disabled={loading ? true : false}
                type="submit"
                size="small"
              >
                {loading && (
                  <Stack
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <CircularProgress size={18} />
                    {t(translationPath + "saving")}
                  </Stack>
                )}
                {!loading && t(translationPath + "save")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Form>
    </SettingsPageContainer>
  );
};

export default SettingsGeolocalization;
