import { z } from "zod";

const translationPath = "page.register.extensions.defaultSettings.errors.";

export const DefaultSettingSchema = z.object({
  id: z.string().optional(),
  isPublic: z.boolean(),
  identifier: z.string().min(1, translationPath + "identifier"),
  property: z.string().optional().nullable(),
  value: z.string().min(1, translationPath + "value"),
  description: z.string().min(1, translationPath + "description"),
});

export type TDefaultSchemaForm = z.infer<typeof DefaultSettingSchema>;
