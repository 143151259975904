import { Box, Divider, useMediaQuery, useTheme } from "@mui/material";
import SettingsCacheTotal from "./SettingsCacheTotal";
import SettingsCacheKey from "./SettingsCacheKey/SettingsCacheKey";
import SearchCacheKey from "./SearchCacheKey/SearchCacheKey";
import SettingsPageContainer from "../../../../../components/Settings/SettingsPageContainer";

const SettingsCache = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <SettingsPageContainer title="Configurações de Cache">
      <Box mt={5} width={!matches ? "80%" : "100%"}>
        <SettingsCacheTotal />
        <Divider sx={{ marginBlock: 4 }} />
        <SettingsCacheKey />
        <Divider sx={{ marginBlock: 4 }} />
        <SearchCacheKey />
      </Box>
    </SettingsPageContainer>
  );
};

export default SettingsCache;
