import { z } from "zod";

const individualSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  {
    invalid_type_error:
      "components.schedule.newSchedule.insurance.individual.error",
    required_error:
      "components.schedule.newSchedule.insurance.individual.error",
  }
);

const insuranceSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
  },
  {
    required_error: "components.schedule.newSchedule.insurance.insurance.error",
    invalid_type_error:
      "components.schedule.newSchedule.insurance.insurance.error",
  }
);

export const addInsuranceSchema = z.object({
  insurance: insuranceSchema,
  individual: individualSchema,
  code: z
    .string({
      invalid_type_error:
        "components.schedule.newSchedule.insurance.number.error",
    })
    .optional(),
  validate: z
    .date({
      invalid_type_error:
        "components.schedule.newSchedule.insurance.validity.error",
    })
    .optional().nullable(),
});

export type TAddHealth = z.infer<typeof addInsuranceSchema>;
