import { z } from "zod";

const translationPath = "page.register.applications.errors.";

const typeSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
    description: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "type" }
);

const statusSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "status_type" }
);

const genericSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
});

const mediaFileSchema = z.object(
  {
    mediaType: genericSchema.optional(),
    id: z.string().min(1, translationPath + "file"),
    mediaTypeId: z.string().optional(),
    isPublic: z.boolean().optional(),
    fileName: z.string().optional(),
    cdnDomain: z.string().optional(),
    filePath: z.string().optional(),
    mimeType: z.string().optional(),
    fileSize: z.number().optional(),
  },
  { required_error: translationPath + "file" }
);

const siteAppMediaFilesSchema = z
  .object({
    mediaType: genericSchema,
    file: mediaFileSchema,
  })
  .superRefine((val, ctx) => {
    if (val.file.id.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["file"],
        message: translationPath + "file",
      });
    }
  });

const siteAppDomainTypeSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
    value: z.string().optional().nullable(),
    code: z.string().optional().nullable(),
    description: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "type" }
);

const domainsSchema = z.object({
  id: z.string().optional(),
  siteAppDomainType: siteAppDomainTypeSchema.optional().nullable(),
  domain: z.string().min(1, translationPath + "domain"),
});

export const registerSchema = z
  .object({
    id: z.string().optional(),
    name: z.string().min(1, translationPath + "name"),
    domains: z.array(domainsSchema).optional().nullable(),
    type: typeSchema,
    status: statusSchema,
    frontConfig: z.string().optional().nullable(),
    description: z.string().min(1, translationPath + "description"),
    siteAppMediaFiles: z.array(siteAppMediaFilesSchema),
  })
  .superRefine((val, ctx) => {
    if (val.type.code === "1") {
      if (val.domains) {
        if (val.domains.length === 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["domains"],
            message: `${translationPath + "domains"}`,
          });
        }
      }
      if (val.frontConfig?.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["frontConfig"],
          message: `${translationPath + "front_config"}`,
        });
      }
    }
  });

export type TRegisterForm = z.infer<typeof registerSchema>;
