import { Popover, Stack } from "@mui/material";
import React, { memo } from "react";

import Event from "./Event";
import DayLabel from "../DayCalendar/Header/DayLabel";
import { ICalendar } from "../../model";

interface IEventPopOverProps {
  id?: string;
  anchorEl: Element | null;
  date: Date;
  events: ICalendar[];
  open: boolean;
  handleClose: () => void;
}

const EventPopOver: React.FC<IEventPopOverProps> = ({
  anchorEl,
  open,
  id,
  date,
  events,
  handleClose,
}) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
    >
      <Stack p={3} gap={2} minWidth={200}>
        <DayLabel date={date} sx={{ alignItems: "center" }} />
        <Stack alignItems={"flex-start"} gap={1}>
          {events.map((event, i) => (
            <Event data={event} key={event.identifier} />
          ))}
        </Stack>
      </Stack>
    </Popover>
  );
};

export default memo(EventPopOver);
