import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { AutoComplete, useFormContext } from "@4uhub/lib4uhub";

import MainApiService from "../../../../services/mainApi.service";
import { IGeneric } from "../../../../models/generics";
import { useParams } from "react-router";

const SITE_APP_TYPE_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const siteAppTypeService = new MainApiService<IGeneric>(SITE_APP_TYPE_ROUTE);

const translationPath = "page.register.applications.";

interface ISiteAppProps {
  disable?: boolean;
}

const SiteAppType = ({ disable = false }: ISiteAppProps) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const { id } = useParams();

  return (
    <Grid item xs={12} sm={6}>
      <AutoComplete
        params={{ identifier: "siteAppType" }}
        getOptionLabel={(option: any) => {
          return option.name;
        }}
        onValueChange={(value) => {
          if (value?.name === "Site" && id) {
            setValue("domains", []);
          }
        }}
        size="small"
        label={t(translationPath + "type") || "Type"}
        name="type"
        request={siteAppTypeService.list}
        disable={disable}
        sx={(t) =>
          disable
            ? {
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: t.palette.text.primary,
                },
              }
            : null
        }
      />
    </Grid>
  );
};

export default SiteAppType;
