import { GlobalAxios } from "../axios/axios-config";
import { ITicketAutomationTimeClassification } from "../models/time-rules";

const TICKET_AUTOMATION_TIME_CLASSIFICATION_ROUTE =
  process.env.REACT_APP_AUTOMATIONS +
  "/api/v1/TicketAutomationTimeClassification/";

export const listTimeClassification = (params?: any) => {
  return GlobalAxios.get<ITicketAutomationTimeClassification[]>(
    TICKET_AUTOMATION_TIME_CLASSIFICATION_ROUTE + "list",
    {
      params: params,
    }
  );
};
