import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Paper,
  PaperProps,
} from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

interface ISelectAllProps extends PaperProps {
  toggleAll: () => void;
  selected: boolean;
}

const SelectAll: React.FC<ISelectAllProps> = (paperProps) => {
  const { children, toggleAll, selected, ...restPaperProps } = paperProps;

  const { t } = useTranslation();

  return (
    <Paper {...restPaperProps}>
      <Box
        onMouseDown={(e) => e.preventDefault()} // prevent blur
        pl={1.5}
        py={0.5}
      >
        <FormControlLabel
          onClick={(e) => {
            e.preventDefault(); // prevent blur
            toggleAll();
          }}
          label={t("components.schedule.selectAll")}
          control={<Checkbox id="select-all-checkbox" checked={selected} />}
        />
      </Box>
      <Divider />
      {children}
    </Paper>
  );
};

export default memo(SelectAll);
