import { FormInput, ISelectType, useFormContext } from "@4uhub/lib4uhub";
import {
  Grid,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { IGetContentMediaFile } from "../../../../models/content";
import CloseIcon from "@mui/icons-material/Close";

const Captions = ({
  index,
  allLanguages,
  contentMediaFiles,
}: {
  index: number;
  allLanguages: ISelectType[];
  contentMediaFiles: IGetContentMediaFile[];
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const [currentLang, setCurrentLang] = useState<string>(
    allLanguages.length !== 0 ? allLanguages[0].id : ""
  );

  const { setValue } = useFormContext();

  useEffect(() => {
    if (contentMediaFiles.length !== 0) {
      allLanguages.map((lang, i) => {
        const captions = contentMediaFiles[index]
          ? contentMediaFiles[index].contentMediaFileCaptions
          : [];
        setValue(`contentMediaFiles.${index}.captions.${i}.language`, lang.id);
        setValue(
          `contentMediaFiles.${index}.captions.${i}.caption`,
          captions.find((c) => c.language.id === lang.id)?.caption || ""
        );
        return true;
      });
    }
  }, [allLanguages, contentMediaFiles, setValue, index]);

  return (
    <>
      <Grid item xs={12} sm={2}>
        <ToggleButtonGroup
          value={currentLang}
          onChange={(e, v) => {
            if (v) {
              setCurrentLang(v);
              setCurrentIndex(allLanguages.findIndex((lang) => lang.id === v));
            }
          }}
          exclusive
          size="small"
          aria-label="toggle-button"
          fullWidth
        >
          {allLanguages.map((option) => {
            return (
              <ToggleButton
                value={option.id}
                key={option.id}
                selected={option.id === currentLang}
                size="small"
              >
                {option.code?.toUpperCase()}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Grid>
      {allLanguages.map((lang) => (
        <Fragment key={lang.id}>
          {currentLang === lang.id && (
            <Grid item xs={12} sm={10}>
              <FormInput
                size="small"
                name={`contentMediaFiles.${index}.captions.${currentIndex}.caption`}
                fullWidth
                label={`Legenda em ${lang.name}`}
                onChange={(e) => {
                  setValue(
                    `contentMediaFiles.${index}.captions.${currentIndex}.caption`,
                    e.target.value
                  );
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setValue(
                          `contentMediaFiles.${index}.captions.${currentIndex}.caption`,
                          ""
                        );
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
          )}
        </Fragment>
      ))}
    </>
  );
};

export default Captions;
