import { z } from "zod";

const translationPath = "page.4uHcp.hcp.healthProgramsContentRules.errors.";

const healthProgramSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.number().optional().nullable(),
  },
  { invalid_type_error: translationPath + "health_program" }
);

export const healthProgramContentRuleSchema = z
  .object({
    id: z.string().optional(),
    name: z.string().min(1, translationPath + "name"),
    healthProgram: healthProgramSchema,
    initialDate: z.date({
      invalid_type_error: translationPath + "initial_date",
    }),
    endDate: z.date({
      invalid_type_error: translationPath + "end_date",
    }),
  })
  .superRefine((val, ctx) => {
    if (val.initialDate > val.endDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: translationPath + "initial_date_greater",
        path: ["endDate"],
      });
    }
  });

export type THealthProgramContentRuleForm = z.infer<
  typeof healthProgramContentRuleSchema
>;
