import { memo } from "react";
import { SelectButtons } from "./SelectButtons/SelectButtons";
import { Box } from "@mui/material";
import { CallToActionButtons } from "./CallToActionButtons/CallToActionButtons";
import { QuickAnswerButtons } from "./QuickAnswerButtons/QuickAnswerButtons";
import { useTemplateWhats } from "../../../store/contexts/TemplateWhatsContext";

export const ButtonsField = memo(() => {
  const { buttonsList } = useTemplateWhats();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <SelectButtons />
      {buttonsList[0] === "1" ? (
        <CallToActionButtons />
      ) : (
        <QuickAnswerButtons />
      )}
      {buttonsList[1] === "1" ? (
        <CallToActionButtons />
      ) : (
        <QuickAnswerButtons />
      )}
    </Box>
  );
});
