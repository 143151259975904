import { Grid, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

import { FormContainer, FormInput, Treturn, useFetch } from "@4uhub/lib4uhub";
import {
  createIpmGeneralSetting,
  getIpmGeneralSettingById,
  updateIpmGeneralSettingById,
} from "../../../../../../../services/ipmGeneralSettings.service";
import {
  TIpmGeneralSettingsForm,
  ipmGeneralSettingsSchema,
} from "./IpmGeneralSettingsSchema";
import { useCallback, useState } from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const translationPath = "page.settings.4uBus.ipm.generalSettings.";

const IpmGeneralSetting = () => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  const { sendRequest: save, loading: sLoading } = useFetch(
    createIpmGeneralSetting
  );
  const { sendRequest: update, loading: uLoading } = useFetch(
    updateIpmGeneralSettingById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    getIpmGeneralSettingById
  );

  const updateHandler = (v: TIpmGeneralSettingsForm, id: string) =>
    update({
      item: {
        ...v,
        heartBeatTimeMinutes: +v.heartBeatTimeMinutes,
        itensPerBatch: +v.itensPerBatch,
        totalBatches: +v.totalBatches,
      },
      id: id,
    });

  const saveHandler = (v: TIpmGeneralSettingsForm) =>
    save({
      ...v,
      heartBeatTimeMinutes: +v.heartBeatTimeMinutes,
      itensPerBatch: +v.itensPerBatch,
      totalBatches: +v.totalBatches,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TIpmGeneralSettingsForm>> => {
      const { data, success } = await get(id);

      if (data) {
        const newData: TIpmGeneralSettingsForm = {
          ...data,
          heartBeatTimeMinutes: data.heartBeatTimeMinutes.toString(),
          itensPerBatch: data.itensPerBatch.toString(),
          totalBatches: data.totalBatches.toString(),
        };
        return { data: newData, success };
      }
      return { data, success };
    },
    [get]
  );

  const toggleShowPassword = () => {
    setShowPassword((oldState) => (oldState = !oldState));
  };

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TIpmGeneralSettingsForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "general_setting")}
      schema={ipmGeneralSettingsSchema}
      subtitle={t(translationPath + "new_general_setting")}
      subtitleWatchField={"host"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="host"
              fullWidth
              autoFocus
              label={t(translationPath + "host") || "Host"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="ipmUser"
              fullWidth
              label={t(translationPath + "ipm_user") || "IPM user"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="ipmPassword"
              fullWidth
              label={t(translationPath + "ipm_password") || "IPM password"}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={toggleShowPassword}>
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="token4uBus"
              fullWidth
              label={t(translationPath + "token_4ubus") || "4uBus token"}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormInput
              size="small"
              name="itensPerBatch"
              fullWidth
              label={
                t(translationPath + "itens_per_batch") || "Itens per batch"
              }
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormInput
              size="small"
              name="totalBatches"
              fullWidth
              label={t(translationPath + "total_batches") || "Total batches"}
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormInput
              size="small"
              name="heartBeatTimeMinutes"
              fullWidth
              label={
                t(translationPath + "heart_beat_time_minutes") ||
                "Heart beat time minutes"
              }
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="establishmentCodes"
              fullWidth
              label={
                t(translationPath + "establishment_codes") ||
                "Code of sending establishments (separated by comma)"
              }
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default IpmGeneralSetting;
