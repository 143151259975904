import { Box, Grid } from "@mui/material";
import { memo } from "react";
import { Draggable } from "react-beautiful-dnd";
import { TextField } from "../../../TextField/TextField";
import { Button, useFormContext } from "@4uhub/lib4uhub";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CloseIcon from "@mui/icons-material/Close";
import { useTemplateWhats } from "../../../../../store/contexts/TemplateWhatsContext";
import { useFieldArray } from "react-hook-form";
import { ICustomButtonProps } from "./models";
import { useTranslation } from "react-i18next";

const translationPath = "components.customButton.";

export const CustomButton = ({
  index,
  button,
  customButtonsLength,
}: ICustomButtonProps) => {
  const { t } = useTranslation();

  const { control } = useFormContext();

  const { remove } = useFieldArray({ name: "customButtons", control });

  const { isFixedData, updateCustomButtonHandler, deleteCustomButtonHandler } =
    useTemplateWhats();

  const deleteHandler = () => {
    deleteCustomButtonHandler(button.id);
    remove(index);
  };

  return (
    <Draggable
      draggableId={button.id}
      index={index}
      isDragDisabled={customButtonsLength < 2 || isFixedData}
    >
      {(provided, snapshot) => (
        <Box
          sx={(t) => ({
            p: 1.5,
            display: "flex",
            alignItems: "center",
            gap: 1,
            borderRadius: 1,
            boxShadow: snapshot.isDragging ? 1 : "unset",
            backgroundColor: t.palette.background.default,
            "&:hover": {
              backgroundColor:
                customButtonsLength >= 2
                  ? t.palette.grey[t.palette.mode === "light" ? 100 : 900]
                  : undefined,
            },
          })}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {customButtonsLength >= 2 && !isFixedData && (
            <DragIndicatorIcon fontSize="small" color="inherit" />
          )}
          <Box
            sx={(t) => ({
              p: 2,
              width: "100%",
              backgroundColor:
                t.palette.grey[t.palette.mode === "light" ? 200 : 800],
              border: 1,
              borderColor: t.palette.divider,
              borderRadius: 1,
            })}
          >
            <Grid container spacing={1}>
              <Grid item sm={12}>
                <TextField
                  name={`customButtons.${index}.text`}
                  label={t(translationPath + "text")}
                  fullWidth
                  size="small"
                  disabled={isFixedData}
                  onChangeValue={(text) =>
                    updateCustomButtonHandler(button.id, "text", text)
                  }
                  maxLength={25}
                  sx={(t) => ({
                    background: `linear-gradient(${t.palette.background.default} 40px, transparent 40px)`,
                    borderRadius: 1,
                  })}
                />
              </Grid>
            </Grid>
          </Box>
          {!isFixedData && (
            <Button
              size="small"
              color="inherit"
              sx={{ minWidth: 0 }}
              onClick={deleteHandler}
            >
              <CloseIcon fontSize="small" />
            </Button>
          )}
        </Box>
      )}
    </Draggable>
  );
};

export default memo(CustomButton);
