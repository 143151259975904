import { Typography } from "@mui/material";
import { memo } from "react";
import useCalendar from "../CalendarContext/useCalendar";

const ActualDate = () => {
  const { getActualDate } = useCalendar();

  return <Typography>{getActualDate()}</Typography>;
};

export default memo(ActualDate);
