import { z } from "zod";

const translationPath = "page.register.provinces.errors.";

const countrySchema = z.object(
  {
    id: z.string().nonempty(translationPath + "country"),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "country" }
);

export const provinceSchema = z.object({
  id: z.string().optional(),
  code: z.string().nonempty(translationPath + "code"),
  name: z.string().nonempty(translationPath + "name"),
  enable: z.boolean(),
  country: countrySchema,
});

export type TProvinceForm = z.infer<typeof provinceSchema>;
