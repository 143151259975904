import { z } from "zod";

const typeSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().nullish(),
  },
  { invalid_type_error: "O tipo é obrigatório" }
);

const timeClassificationSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().nullish(),
  },
  { invalid_type_error: "A classificação do tempo é obrigatória" }
);

export const timeRuleSchema = z.object({
  id: z.string().optional(),
  value: z.string().min(1, "O valor é obrigatório"),
  type: typeSchema.nullable().refine((val) => val, {
    message: "O tipo é obrigatório",
  }),
  timeClassification: timeClassificationSchema.nullable().refine((val) => val, {
    message: "A classificação do tempo é obrigatória",
  }),
});

export type TTimeRuleForm = z.infer<typeof timeRuleSchema>;
