import { useTranslation } from "react-i18next";

import { FormInput, SwitchInput, useFormContext } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";

import { TFormType } from "@4uhub/lib4uhub/dist/types/components/Form/FormContainer/models";
import FormInformation from "../../../../../components/FormInformation/FormInformation";
import { buildTPath } from "../channel-classifications.utils";
import { TChannelClassificationForm } from "./channelClassification.schema";

const Fields = ({ type }: { type: TFormType }) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext<TChannelClassificationForm>();

  const code = getValues("code");

  const hasCode = type === "update" && code;

  return (
    <Grid container spacing={4}>
      {hasCode && (
        <Grid item xs={6} sm={6}>
          <FormInformation
            data={code}
            label={t(buildTPath("code"))}
            minHeight={40}
          />
        </Grid>
      )}

      <Grid item xs={6} sm={hasCode ? 6 : 12} textAlign="end">
        <SwitchInput name="enable" label={t(buildTPath("enable"))} />
      </Grid>

      <Grid item xs={12} sm={12}>
        <FormInput
          size="small"
          name="name"
          fullWidth
          label={t(buildTPath("name"))}
        />
      </Grid>
    </Grid>
  );
};

export default Fields;
