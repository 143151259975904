import { z } from "zod";
import { buildTPath } from "../contract-attachment-type.utils";

const salesContractTypeSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().nullish(),
  },
  { invalid_type_error: buildTPath("errors.contract_type") }
);

export const contractAttachmentTypesSchema = z.object({
  id: z.string().optional().nullable(),
  name: z.string().min(1, buildTPath("errors.name")),
  enable: z.boolean(),
  salesContractType: salesContractTypeSchema,
});

export type TcontractAttachmentTypesReasonForm = z.infer<
  typeof contractAttachmentTypesSchema
>;
