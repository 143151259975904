import { useFormContext } from "@4uhub/lib4uhub";
import { memo, useEffect } from "react";
import { Controller, useWatch } from "react-hook-form";
import { TBodyField } from "./models";
import { Grid, InputAdornment, Typography } from "@mui/material";
import { WhatsappSection } from "../WhatsappSection/WhatsappSection";
import { BodyInput } from "./BodyInput/BodyInput";
import { useTemplateWhats } from "../../../store/contexts/TemplateWhatsContext";
import { useTranslation } from "react-i18next";

const translationPath = "chatBot.page.templates.whatsapp.";

export const BodyField = memo(
  ({ name, label, automationId, componentType, ...otherProps }: TBodyField) => {
    const { t } = useTranslation();

    const { control } = useFormContext();

    const { updateBasicData } = useTemplateWhats();

    const textValue = useWatch({
      name: name ?? "body",
      control,
    });

    useEffect(() => {
      if (textValue) {
        updateBasicData(name ?? "body", textValue);
      } else {
        updateBasicData(name ?? "body", "");
      }
    }, [textValue, updateBasicData, name]);

    return (
      <Grid item xs={12} sm={12}>
        <WhatsappSection
          title={t(translationPath + "body")}
          subtitle={t(translationPath + "body_sub")}
        >
          <Controller
            control={control}
            name={name ?? "body"}
            defaultValue={""}
            render={({ fieldState: { error }, field }) => {
              return (
                <BodyInput
                  field={field}
                  error={error}
                  fullWidth
                  inputProps={{ maxLength: 1024 }}
                  automationId={automationId}
                  componentType={componentType}
                  multiline
                  minRows={4}
                  label={label ? label : undefined}
                  placeholder={
                    label
                      ? undefined
                      : `${t(translationPath + "body_placeholder")}`
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{ marginBottom: 2, alignSelf: "end" }}
                      >
                        <Typography
                          variant="body2"
                          sx={(t) => ({
                            color:
                              t.palette.grey[
                                t.palette.mode === "light" ? 400 : 600
                              ],
                          })}
                        >
                          {textValue ? textValue.length : 0}/{1024}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  {...otherProps}
                />
              );
            }}
          />
        </WhatsappSection>
      </Grid>
    );
  }
);
