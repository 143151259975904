import { Grid } from "@mui/material";
import { useFieldArray } from "react-hook-form";

import { FormArray, IInputFile, useFormContext } from "@4uhub/lib4uhub";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import ProtocolDefaultMediaFile from "./ProtocolDefaultMediaFile";
import { TProtocolDefaultForm } from "./ProtocolDefaultSchema";

const translationPath = "page.settings.crm.protocolsDefault.";

const ProtocolDefaultMediaFiles = ({
  onDeleteFileHandler,
}: {
  onDeleteFileHandler: (file: IInputFile) => void;
}) => {
  const { t } = useTranslation();
  const { control, formState } = useFormContext<TProtocolDefaultForm>();

  const mediaFilesArray = useFieldArray<
    TProtocolDefaultForm,
    "ticketDefaultDocMediaFiles",
    "key"
  >({
    control,
    name: "ticketDefaultDocMediaFiles",
    keyName: "key",
  });

  const fieldsObject = useCallback(
    (index: number) => (
      <ProtocolDefaultMediaFile
        onFileSelected={onDeleteFileHandler}
        index={index}
      />
    ),
    [onDeleteFileHandler]
  );

  const onDeleteVoidHandler = (data: any) => {
    if (data.file) {
      onDeleteFileHandler(data.file);
    }
  };

  return (
    <Grid item xs={12}>
      <FormArray
        title={t(translationPath + "media_files")}
        name="ticketDefaultDocMediaFiles"
        errorMessage={formState.errors?.ticketDefaultDocMediaFiles?.message}
        addButtonLabel={`${t(translationPath + "add_media_file")}`}
        formArray={mediaFilesArray}
        onDeleteVoid={onDeleteVoidHandler}
        appendValue={{
          file: {
            id: "",
          },
          description: "",
        }}
        fieldsObject={fieldsObject}
      />
    </Grid>
  );
};

export default ProtocolDefaultMediaFiles;
