import { AutoComplete, AutoCompleteMulti, ISelectType } from "@4uhub/lib4uhub";
import { Divider, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MainApi from "../../../../../services/mainApi.service";

const translationPath = "page.4uBus.register.accessTokens.";

const RESTRICTION_RULE_PROFILE_ROUTE =
  process.env.REACT_APP_4UBUS + "/api/v1/RestrictionRuleProfile/";
const restrictionRuleProfileService = new MainApi<ISelectType>(
  RESTRICTION_RULE_PROFILE_ROUTE
);

interface IAccessTokenProfilesProps {
  extensionsOptions: ISelectType[];
  selectedExtensionCode: string;
  onSelectedExtension: (value: ISelectType | null) => void;
}

const AccessTokenProfiles = ({
  extensionsOptions,
  selectedExtensionCode,
  onSelectedExtension,
}: IAccessTokenProfilesProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Divider sx={{ mt: 4, width: "100%" }} />

      <Grid mb={2} item sm={12}>
        <Typography variant="h6">{t(translationPath + "profiles")}</Typography>
      </Grid>

      <Grid item xs={12} sm={12}>
        <AutoComplete
          name="extension"
          label={t(translationPath + "extension") || "Extension"}
          getOptionLabel={(option) => option.name}
          size="small"
          fullWidth
          options={extensionsOptions}
          defaultValue={extensionsOptions[0]}
          onValueChange={onSelectedExtension}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <AutoCompleteMulti
          params={{ ExtensionCode: selectedExtensionCode }}
          name="restrictionRuleProfiles"
          label={
            t(translationPath + "restriction_rule_profiles") ||
            "Restriction rule profiles"
          }
          getOptionLabel={(option) => option.name}
          size="small"
          fullWidth
          request={
            selectedExtensionCode
              ? restrictionRuleProfileService.list
              : undefined
          }
        />
      </Grid>
    </>
  );
};

export default AccessTokenProfiles;
