import { Grid } from "@mui/material";
import { useFieldArray } from "react-hook-form";

import { FormArray, IInputFile, useFormContext } from "@4uhub/lib4uhub";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import BannerFile from "./BannerFile";
import { TBannerForm } from "../BannerSchema";

const translationPath = "page.register.banners.";

const BannerFiles = ({
  onDeleteFileHandler,
}: {
  onDeleteFileHandler: (file: IInputFile) => void;
}) => {
  const { t } = useTranslation();
  const { control, formState } = useFormContext<TBannerForm>();

  const extensionMediasArray = useFieldArray<
    TBannerForm,
    "bannerMediaFiles",
    "key"
  >({
    control,
    name: "bannerMediaFiles",
    keyName: "key",
  });

  const fieldsObject = useCallback(
    (index: number) => (
      <BannerFile onFileSelected={onDeleteFileHandler} index={index} />
    ),
    [onDeleteFileHandler]
  );

  const onDeleteVoidHandler = (data: any) => {
    if (data.file) {
      onDeleteFileHandler(data.file);
    }
  };

  return (
    <Grid item xs={12}>
      <FormArray
        title={t(translationPath + "media_file")}
        name="bannerMediaFiles"
        errorMessage={formState.errors?.bannerMediaFiles?.message}
        addButtonLabel={`${t(translationPath + "add_media_file")}`}
        formArray={extensionMediasArray}
        onDeleteVoid={onDeleteVoidHandler}
        appendValue={{
          file: {
            id: "",
          },
        }}
        showAddButton={false}
        fieldsObject={fieldsObject}
        showDeleteButton={false}
      />
    </Grid>
  );
};

export default BannerFiles;
