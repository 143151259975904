import { z } from "zod";

const translation_path = "page.register.roles.errors.";

const establishment = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
  companyId: z.string(),
  enable: z.boolean(),
  domain: z.string().optional().nullable(),
});

const genericSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const domainSchema = z.object({
  id: z.string().optional(),
  softwares: z.array(genericSchema),
});

const privilege = z.object({
  id: z.string(),
  name: z.string(),
  descripion: z.string().optional(),
  type: z.string().optional(),
  enable: z.boolean().optional(),
  domainId: z.string().optional(),
  actions: z.array(z.string()).optional(),
  domain: domainSchema.optional(),
});

const profileSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string(),
  permissionLevel: z.number(),
  allowLink: z.boolean(),
  enable: z.boolean(),
  profileTypeId: z.string(),
});

export const rolesSchema = z.object({
  name: z.string().min(1, translation_path + "name"),
  description: z.string().min(1, translation_path + "description"),
  privileges: z.array(privilege).min(1, translation_path + "privilege"),
  establishments: z
    .array(establishment)
    .nonempty(translation_path + "establishments"),
  profiles: z.array(profileSchema),
  enable: z.boolean(),
  id: z.string().optional(),
});

export type TRolesForm = z.infer<typeof rolesSchema>;
