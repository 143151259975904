import { useFetch } from "@4uhub/lib4uhub";
import { Button, Fade, Paper, Popper, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExtensionStoreService from "../../services/extensionsStore.service";
import { useCallback } from "react";

const translationPath = "components.extensionUninstallPopper.";

const extensionsStoreService = new ExtensionStoreService();

const ExtensionUninstallPopper = ({
  open,
  anchorEl,
  onClose,
  extensionId,
  siteAppId,
  onRealoadExtensionsList,
  toggleDetail,
}: {
  open: boolean;
  anchorEl: HTMLElement | null;
  extensionId: string;
  siteAppId: string;
  onClose: () => void;
  onRealoadExtensionsList: () => void;
  toggleDetail: (value: boolean) => void;
}) => {
  const { t } = useTranslation();

  const { sendRequest, loading } = useFetch(
    extensionsStoreService.uninstallExtension
  );

  const uninstallExtensionHandler = useCallback(async () => {
    const { data, success } = await sendRequest({ extensionId, siteAppId });
    if (data && success) {
      toggleDetail(false);
      onRealoadExtensionsList();
      onClose();
    }
  }, [
    sendRequest,
    extensionId,
    siteAppId,
    onClose,
    onRealoadExtensionsList,
    toggleDetail,
  ]);

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement={"top-end"}
      transition
      style={{ zIndex: 1500 }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper sx={{ p: 2, width: 220 }}>
            <Stack textAlign={"center"} mb={2}>
              <Typography>{t(translationPath + "title")}</Typography>
            </Stack>
            <Stack
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              gap={1}
            >
              <Button
                size="small"
                fullWidth
                variant="outlined"
                onClick={onClose}
              >
                {t(translationPath + "cancel")}
              </Button>
              <Button
                size="small"
                fullWidth
                variant="contained"
                onClick={uninstallExtensionHandler}
                disabled={loading}
              >
                {!loading
                  ? t(translationPath + "uninstall")
                  : t(translationPath + "uninstalling")}
              </Button>
            </Stack>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

export default ExtensionUninstallPopper;
