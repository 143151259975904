import {
  addDays,
  addMonths,
  addWeeks,
  isBefore,
  isSameDay,
  subDays,
  subMonths,
  subWeeks,
} from "date-fns";
import { memo, useCallback, useMemo, useState } from "react";
import { ECalendarMode, IBaseCalendar } from "../models";
import { CalendarContext } from "./CalendarContext";
import useDate from "../../../../hooks/useDate";

interface ICalendarProviderProps extends IBaseCalendar {
  children: React.ReactNode;
  mode: ECalendarMode;
  onModeChange: (m: ECalendarMode) => void;
  onDateChange: (date: Date) => void;
}

const CalendarProvider = ({
  children,
  mode,
  calendars,
  date,
  allAvaiableColor = "#b3f9bd",
  fullDayColor = "#f79e9e",
  someAvaiableColor = "#f7f3ad",
  onDateChange,
  onModeChange,
  onAvaiableTimeClick,
  renderPopOver,
}: ICalendarProviderProps) => {
  const [direction, setDirection] = useState(0);

  const { localeFormat } = useDate();

  const goToDay = useCallback(
    (pDate: Date, mode?: ECalendarMode) => {
      if (mode) {
        onModeChange(mode);
      }
      if (isSameDay(date, pDate)) return;

      if (isBefore(pDate, date)) {
        setDirection(-1);
      } else {
        setDirection(1);
      }
      
      onDateChange(pDate);
    },
    [date, onDateChange, onModeChange]
  );

  const next = useCallback(() => {
    switch (mode) {
      case ECalendarMode.DAY:
        onModeChange(ECalendarMode.DAY);
        goToDay(addDays(date, 1));
        break;
      case ECalendarMode.WEEK:
        goToDay(addWeeks(date, 1));
        break;
      case ECalendarMode.MONTH:
        goToDay(addMonths(date, 1));
        break;
      default:
        break;
    }
  }, [date, mode, goToDay, onModeChange]);

  const previous = useCallback(() => {
    switch (mode) {
      case ECalendarMode.DAY:
        onModeChange(ECalendarMode.DAY);
        goToDay(subDays(date, 1));
        break;
      case ECalendarMode.WEEK:
        goToDay(subWeeks(date, 1));
        break;
      case ECalendarMode.MONTH:
        goToDay(subMonths(date, 1));
        break;
      default:
        break;
    }
  }, [date, mode, goToDay, onModeChange]);

  const getActualDate = useCallback(() => {
    switch (mode) {
      case ECalendarMode.DAY:
        return localeFormat(date, "PPP");

      default:
        return localeFormat(date, "MMMM 'de' yyyy");
    }
  }, [date, mode, localeFormat]);

  const goToToday = useCallback(() => {
    const today = new Date();
    goToDay(today);
  }, [goToDay]);

  const value = useMemo(
    () => ({
      date,
      mode,
      calendars,
      direction,
      allAvaiableColor,
      fullDayColor,
      someAvaiableColor,
      goToToday,
      next,
      previous,
      getActualDate,
      onModeChange,
      goToDay,
      onAvaiableTimeClick,
      renderPopOver,
    }),
    [
      date,
      mode,
      calendars,
      direction,
      allAvaiableColor,
      fullDayColor,
      someAvaiableColor,
      goToToday,
      next,
      previous,
      getActualDate,
      onModeChange,
      goToDay,
      onAvaiableTimeClick,
      renderPopOver,
    ]
  );

  return (
    <CalendarContext.Provider value={value}>
      {children}
    </CalendarContext.Provider>
  );
};

export default memo(CalendarProvider) as typeof CalendarProvider;
