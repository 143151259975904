import { Box, Grid, Stack, Typography } from "@mui/material";
import { memo } from "react";

import useDate from "../../../../hooks/useDate";

interface IHourProps {
  hour: Date;
  showHourLabel: boolean;
  showHour?: boolean;
}

const Hour: React.FC<IHourProps> = ({
  hour,
  showHourLabel,
  showHour = true,
}) => {
  const { localeFormat } = useDate();
  return (
    <Grid item key={hour.getTime()} height={100} xs={12}>
      <Grid
        container
        alignItems={"start"}
        justifyContent={"flex-start"}
        height={"100%"}
      >
        <Stack
          sx={(t) => ({
            justifyContent: "start",
            alignItems: "center",
            borderRight: `1px solid ${t.palette.divider}`,
            height: "100%",
          })}
          mt={-1}
        >
          {showHour && (
            <Typography width={42} fontSize={10}>
              {showHourLabel ? localeFormat(hour, "p") : ""}
            </Typography>
          )}
        </Stack>

        <Box
          sx={(t) => ({
            borderTop: showHourLabel
              ? `1px solid ${t.palette.divider}`
              : undefined,
            flex: 1,
            position: "relative",
          })}
        />
      </Grid>
    </Grid>
  );
};

export default memo(Hour);
