import {
  AutoComplete,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { workspacesList } from "../../../../../../services/ticketWorkspace.service";
import { useCallback, useEffect, useState } from "react";
import { TTicketAutoFinishRuleForm } from "./TicketAutoFinishRuleSchema";

const translationPath = "page.settings.crm.ticketAutoFinishRules.";

interface ITicketWorkspaceProps {
  ticketWorkspaceId: string;
}

const TicketWorkspace = ({ ticketWorkspaceId }: ITicketWorkspaceProps) => {
  const { t } = useTranslation();

  const { setValue } = useFormContext<TTicketAutoFinishRuleForm>();

  const [ticketWorkspaceOptions, setTicketWorkspaceOptions] = useState<
    ISelectType[]
  >([]);

  const { sendRequest } = useFetch(workspacesList);

  const fetchTicketWorkspaces = useCallback(async () => {
    const { data, success } = await sendRequest(null);
    if (data && success) {
      setTicketWorkspaceOptions(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchTicketWorkspaces();
  }, [fetchTicketWorkspaces]);


  useEffect(() => {
    if (ticketWorkspaceId.length !== 0) {
      setValue(
        "ticketWorkspace",
        ticketWorkspaceOptions.find(
          (workspace) => workspace.id === ticketWorkspaceId
        )
      );
    }
  }, [setValue, ticketWorkspaceId, ticketWorkspaceOptions]);

  return (
    <Grid item xs={12} sm={6}>
      <AutoComplete
        getOptionLabel={(option) => {
          return option.name;
        }}
        size="small"
        label={t(translationPath + "ticket_workspace")}
        name="ticketWorkspace"
        options={ticketWorkspaceOptions}
        onValueChange={() => {
          setValue("ticketChannel", null);
          setValue("ticketChannelStep", null);
        }}
      />
    </Grid>
  );
};

export default TicketWorkspace;
