import { Box, Stack, Typography } from "@mui/material";
import { isToday, isFirstDayOfMonth } from "date-fns";
import { memo, useCallback, useMemo } from "react";

import { ECalendarMode } from "../models";
import DayLabel from "./DayLabel";
import EventList from "./EventList";
import useCalendar from "../CalendarContext/useCalendar";
import { ICalendar } from "../../model";
import useDate from "../../../../hooks/useDate";

interface IDayProps {
  day: Date;
  isSameMonth: boolean;
  events: ICalendar[];
  hasAvaiable?: boolean;
  isFull?: boolean;
  allAvaiable?: boolean;
  index: number;
}

const Day: React.FC<IDayProps> = ({
  day,
  isSameMonth,
  events,
  index,
  hasAvaiable = false,
  isFull = false,
  allAvaiable = false,
}) => {
  const { goToDay, fullDayColor, allAvaiableColor, someAvaiableColor } =
    useCalendar();

  const isTo = useMemo(() => isToday(day), [day]);

  const { localeFormat } = useDate();

  const isFirstDay = useMemo(
    () => isFirstDayOfMonth(day) && !isSameMonth,
    [isSameMonth, day]
  );

  const onClickHandler = useCallback(() => {
    goToDay(day, ECalendarMode.DAY);
  }, [goToDay, day]);

  const backgroundColor = useMemo(() => {
    if (isFull) {
      return fullDayColor;
    }

    if (hasAvaiable) {
      return someAvaiableColor;
    }

    if (allAvaiable) {
      return allAvaiableColor;
    }

    return "transparent";
  }, [
    isFull,
    hasAvaiable,
    allAvaiable,
    allAvaiableColor,
    someAvaiableColor,
    fullDayColor,
  ]);

  return (
    <Box
      sx={(t) => ({
        border: `1px solid ${t.palette.divider}`,
        padding: 2,
        width: "100%",
        height: "100%",
        overflow: "hidden",
        backgroundColor: backgroundColor,
      })}
    >
      <Stack gap={1} height={"100%"}>
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          gap={2}
          justifyContent={"center"}
        >
          <DayLabel
            label={localeFormat(day, isFirstDay ? "d MMM." : "d")}
            onClick={onClickHandler}
            isSameMonth={isSameMonth}
            isToday={isTo}
          />
          {index < 7 && (
            <Typography
              alignSelf={"center"}
              color={(t) => t.palette.text.secondary}
            >
              {localeFormat(day, "EEE.")}
            </Typography>
          )}
        </Stack>
        <EventList events={events} day={day} />
      </Stack>
    </Box>
  );
};

export default memo(Day);
