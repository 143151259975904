import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../services/mainApi.service";
import { IGetBanks } from "../../../models/banks";
import { Table } from "@4uhub/lib4uhub";

const BANK_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Bank/";
const bankService = new MainApiService<IGetBanks>(BANK_ROUTE);

const translationPath = "page.register.banks.";

const BanksPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columns: GridColDef<IGetBanks>[] = [
    {
      field: "ispbCode",
      headerName: t(translationPath + "ispb_code") || "ISPB Code",
      maxWidth: 120,
    },
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
    },
  ];

  const handleRowClick = (params: GridRowParams<IGetBanks>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  return (
    <Table<IGetBanks>
      showDefaultMacroFilters={false}
      service={bankService}
      columns={columns}
      title={t(translationPath + "banks")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={
        t(translationPath + "search_bank") || "Search for a bank"
      }
      addButtonLabel={t(translationPath + "add_bank") || "Add Bank"}
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default BanksPage;
