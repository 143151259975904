import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { IVariableFieldProps } from "./models";
import { TextField } from "../../../../TextField/TextField";
import { useTranslation } from "react-i18next";

const translationPath = "components.variableField.";

export const VariableField = memo(
  ({ variable, index, labelWidth }: IVariableFieldProps) => {
    const { t } = useTranslation();

    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Box minWidth={labelWidth} mr={2}>
          <Typography>{variable}</Typography>
        </Box>
        <TextField
          label={`${t(translationPath + "label")}${variable}`}
          name={`bodyExamples.${index}.example`}
          size="small"
          fullWidth
          sx={(t) => ({
            background: `linear-gradient(${t.palette.background.default} 40px, transparent 40px)`,
            borderRadius: 1,
          })}
        />
      </Box>
    );
  }
);
