import { Table, useFetch } from "@4uhub/lib4uhub";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { Chip } from "@mui/material";
import { format } from "date-fns";
import { enUS, ptBR } from "date-fns/locale";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CellData from "../../../../components/UI/CellData/CellData";
import { IRobotList } from "../../../../models/robots-list";
import { getRobotsLogsList } from "../../../../services/robotsList.service";

const translationPath = "page.4uBus.robots.list.";

const RobotsListPage = () => {
  const [rows, setRows] = useState<IRobotList[]>([]);

  const navigate = useNavigate();

  const { sendRequest } = useFetch(getRobotsLogsList);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const columns: GridColDef<IRobotList>[] = [
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
    },
    {
      field: "description",
      headerName: t(translationPath + "description") || "Description",
    },
    {
      field: "code",
      headerName: t(translationPath + "code") || "Code",
      maxWidth: 100,
    },
    {
      field: "started",
      headerName: t(translationPath + "started") || "Started",
      maxWidth: 130,
      renderCell: (params) => {
        const date = new Date(params.value);
        return format(date, "Pp", {
          locale: language === "pt-BR" ? ptBR : enUS,
        }).replaceAll(",", "");
      },
    },
    {
      field: "lastSuccess",
      headerName: t(translationPath + "last_success") || "Last Success",
      maxWidth: 130,
      renderCell: (params) => {
        const date = new Date(params.value);
        return format(date, "Pp", {
          locale: language === "pt-BR" ? ptBR : enUS,
        }).replaceAll(",", "");
      },
    },
    {
      field: "lastError",
      headerName: t(translationPath + "last_error") || "Last Error",
      renderCell: (params) => {
        return params.value ? <CellData value={params.value} /> : "-";
      },
    },
    {
      field: "lastErrorMessage",
      headerName:
        t(translationPath + "last_error_message") || "Last Error Message",
      renderCell: (params) => {
        return params.value ? <CellData value={params.value} /> : "-";
      },
    },
    {
      field: "enable",
      headerName: t(translationPath + "status") || "Status",
      maxWidth: 100,
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            label={
              params.value
                ? t(translationPath + "active")
                : t(translationPath + "inactive")
            }
            color={params.value ? "success" : "error"}
          />
        );
      },
    },
  ];

  const fetchRobotsList = useCallback(async () => {
    const { data, success } = await sendRequest(null);
    if (data && success) {
      setRows(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchRobotsList();
  }, [fetchRobotsList]);

  const redirect = (params: GridRowParams<IRobotList>) => {
    navigate(`robot/${params.row.id}`);
  };

  return (
    <Table<IRobotList>
      showDefaultMacroFilters={false}
      columns={columns}
      title={t(translationPath + "list")}
      rows={rows}
      searchInput
      showButton={false}
      showDeleteButton={false}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      rowActions={[
        {
          icon: <PlayCircleIcon />,
          text: t(translationPath + "executions_robots"),
          action: redirect,
        },
      ]}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a robot"
      }
    />
  );
};

export default RobotsListPage;
