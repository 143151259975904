import { AutoComplete, Button, useFormContext } from "@4uhub/lib4uhub";
import { Box, Grid } from "@mui/material";
import { memo, useEffect } from "react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CloseIcon from "@mui/icons-material/Close";
import { Draggable } from "react-beautiful-dnd";
import { CallAction } from "./CallAction/CallAction";
import { WhatsappCallAction } from "./WhatsappCallAction/WhatsappCallAction";
import { AccessSiteAction } from "./AccessSiteAction/AccessSiteAction";
import { CopyOfferCodeAction } from "./CopyOfferCodeAction/CopyOfferCodeAction";
import { useTemplateWhats } from "../../../../../store/contexts/TemplateWhatsContext";
import { BUTTONS_OPTIONS } from "../../SelectButtons/SelectButtons";
import { useFieldArray, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IButtonSettingProps } from "./models";

const translationPath = "components.buttonSetting.";

export const ButtonSetting = memo(
  ({ index, button, ctaButtonsLength }: IButtonSettingProps) => {
    const { t } = useTranslation();

    const { control, getValues, setValue, unregister } = useFormContext();

    const currentActionType = useWatch({
      name: `ctaButtons.${index}.actionType`,
      control,
    });

    const { remove } = useFieldArray({ name: "ctaButtons", control });

    const { isFixedData, deleteCtaHandler } = useTemplateWhats();

    const deleteHandler = () => {
      deleteCtaHandler(button.id, index);
      remove(index);
    };

    useEffect(() => {
      if (currentActionType && currentActionType.id === "5") {
        setValue(`ctaButtons.${index}.text`, "Copiar código da oferta");
      }
    }, [currentActionType, setValue, index]);

    return (
      <Draggable
        draggableId={button.id}
        index={index}
        isDragDisabled={ctaButtonsLength < 2 || isFixedData}
      >
        {(provided, snapshot) => (
          <Box
            sx={(t) => ({
              p: 1.5,
              display: "flex",
              alignItems: "center",
              gap: 1,
              borderRadius: 1,
              boxShadow: snapshot.isDragging ? 1 : "unset",
              backgroundColor: t.palette.background.default,
              "&:hover": {
                backgroundColor:
                  ctaButtonsLength >= 2
                    ? t.palette.grey[t.palette.mode === "light" ? 100 : 900]
                    : undefined,
              },
            })}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            {ctaButtonsLength >= 2 && !isFixedData && (
              <DragIndicatorIcon fontSize="small" color="inherit" />
            )}
            <Box
              sx={(t) => ({
                p: 2,
                width: "100%",
                backgroundColor:
                  t.palette.mode === "light"
                    ? t.palette.grey[200]
                    : t.palette.grey[800],
                border: 1,
                borderColor: t.palette.divider,
                borderRadius: 1,
              })}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                  <AutoComplete
                    label={t(translationPath + "action_type")}
                    name={`ctaButtons.${index}.actionType`}
                    defaultValue={button.actionType}
                    getOptionLabel={(option) => t(option.name)}
                    disable={isFixedData}
                    options={BUTTONS_OPTIONS.filter(
                      (at) => at.id !== "0" && at.id !== "1"
                    )}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {t(option.name)}
                        </li>
                      );
                    }}
                    onValueChange={(value) => {
                      if (
                        getValues(`ctaButtons.${index}.actionType`)?.id !==
                        value?.id
                      ) {
                        unregister(`ctaButtons.${index}`);
                        setValue(
                          `ctaButtons.${index}.order`,
                          ctaButtonsLength ? ctaButtonsLength - 1 : 1
                        );
                        setValue(`ctaButtons.${index}.actionType`, value);
                        value &&
                          value.id === "4" &&
                          setValue(`ctaButtons.${index}.payload`, "");
                      }
                    }}
                    clearIcon={false}
                    fullWidth
                    size="small"
                    sx={(t) => ({
                      backgroundColor: t.palette.background.default,
                      borderRadius: 1,
                    })}
                  />
                </Grid>
                {currentActionType && currentActionType.id === "2" && (
                  <AccessSiteAction index={index} id={button.id} />
                )}
                {currentActionType && currentActionType.id === "3" && (
                  <CallAction index={index} id={button.id} />
                )}
                {currentActionType && currentActionType.id === "4" && (
                  <WhatsappCallAction index={index} id={button.id} />
                )}
                {currentActionType && currentActionType.id === "5" && (
                  <CopyOfferCodeAction index={index} />
                )}
              </Grid>
            </Box>
            {!isFixedData && (
              <Button
                size="small"
                color="inherit"
                sx={{ minWidth: 0 }}
                onClick={deleteHandler}
              >
                <CloseIcon fontSize="small" />
              </Button>
            )}
          </Box>
        )}
      </Draggable>
    );
  }
);
