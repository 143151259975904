import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AutoComplete, ISelectType, useFetch } from "@4uhub/lib4uhub";

import { buildTPath } from "../../advantages-and-benefits-club.utils";
import { listSalesContract } from "../../../../../../services/salesContract.service";

interface IOption extends ISelectType {
  contractTitle: string;
}

const MainContractSelect = () => {
  const [options, setOptions] = useState<IOption[]>([]);
  const { t } = useTranslation();

  const { sendRequest } = useFetch(listSalesContract);

  const translate = (field: string) => t(buildTPath(field));

  const getOptions = useCallback(async () => {
    const { data, success } = await sendRequest?.({});

    if (data && success) {
      const response = data?.map((item: IOption) => ({
        ...item,
        name: item?.contractTitle || "",
      }));

      setOptions(response);
    }
  }, [sendRequest]);

  useEffect(() => {
    getOptions();
  }, [getOptions]);

  return (
    <AutoComplete
      name="mainContract"
      size="small"
      label={translate("main_contract")}
      getOptionLabel={({ name }) => name}
      options={options}
    />
  );
};

export default MainContractSelect;
