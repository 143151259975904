import { Divider, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import MainApiService from "../../../../services/mainApi.service";

import { TEstablishmentForm, establishmentSchema } from "./EstablishmentSchema";
import EstablishmentService from "../../../../services/establishment.service";
import {
  AutoComplete,
  FormContainer,
  FormInput,
  ISelectType,
  QPJQuickRegisterForm,
  SwitchInput,
  useFetch,
} from "@4uhub/lib4uhub";
import EstablishmentLanguages from "./EstablishmentLanguages";
import EstablishmentSoftwares from "./EstablishmentSoftwares";

const establishmentsService = new EstablishmentService();

const COMPANY_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Company/";
const companyService = new MainApiService<ISelectType>(COMPANY_ROUTE);

const Establishment = () => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(
    establishmentsService.create
  );
  const { sendRequest: update, loading: uLoading } = useFetch(
    establishmentsService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    establishmentsService.getItemById
  );

  const handleSave = (v: TEstablishmentForm) => {
    return save({
      ...v,
      integrationId:
        v.integrationId !== 0 && v.integrationId !== null
          ? v.integrationId.toString()
          : null,
      legalEntityId: v.legalEntity ? v.legalEntity.id : undefined,
      domainAdmin: v.domainAdmin || null,
      companyId: v.company.id,
      languageIds: v.languages!.map((language) => language.id),
      softwareIds: v.softwares ? v.softwares.map((s) => s.id) : undefined,
    });
  };

  const handleUpdate = (v: TEstablishmentForm, id: string) =>
    update({
      item: {
        ...v,
        id: id,
        legalEntityId: v.legalEntity ? v.legalEntity.id : undefined,
        integrationId:
          (v.integrationId !== null || v.integrationId !== "") &&
          v.integrationId !== 0
            ? v.integrationId?.toString()
            : null,
        domainAdmin: v.domainAdmin || null,
        companyId: v.company.id,
        languageIds: v.languages!.map((language) => language.id),
        softwareIds: v.softwares ? v.softwares.map((s) => s.id) : undefined,
      },
      id: id,
    });

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TEstablishmentForm>
      saveFunction={handleSave}
      updateFunction={handleUpdate}
      getFunction={get}
      loading={loading}
      title={t("page.register.establishments.establishment")}
      schema={establishmentSchema}
      subtitle={t("page.register.establishments.new_establishment")}
      subtitleWatchField={"name"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            <SwitchInput
              name="enable"
              label={t("page.register.establishments.enable") || "Enable"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <AutoComplete
              getOptionLabel={(option) => {
                return option.name;
              }}
              size="small"
              label={t("page.register.establishments.company") || "Company"}
              name="company"
              request={companyService.list}
              SearchAsYouTypeParams={(v) => ({ SearchBy: v })}
              autoFocus
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <QPJQuickRegisterForm
              name="legalEntity"
              label={
                t("page.register.establishments.legal_entity") || "Legal Entity"
              }
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="name"
              label={t("page.register.establishments.name") || "Name"}
              fullWidth
            />
          </Grid>

          <EstablishmentLanguages />

          <Grid item xs={12} sm={3}>
            <FormInput
              size="small"
              name="integrationId"
              type="number"
              label={
                t("page.register.establishments.integration") || "Integration"
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <FormInput
              size="small"
              name="ansCode"
              label={
                t("page.register.establishments.ansCode") ||
                "Operator Code in ANS"
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="domainAdmin"
              type="string"
              label={
                t("page.register.establishments.domain_admin") || "Admin domain"
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="crmDomain"
              label={
                t("page.register.establishments.crmDomain") || "CRM domain"
              }
              fullWidth
            />
          </Grid>

          <EstablishmentSoftwares />

          <Grid item xs={12} sm={12} mt={2}>
            <Divider sx={{ width: "100%", marginBottom: 2 }} />
            <Typography variant="h6">
              {t("page.register.establishments.config_on_premise")}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="parentHost"
              label={
                t("page.register.establishments.parent_host") || "Parent host"
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="fileManagerHost"
              label={
                t("page.register.establishments.filemanager_host") ||
                "Filemanager Host"
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="collectorHost"
              label={
                t("page.register.establishments.collector_host") ||
                "Collector host"
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="realTimeHost"
              label={
                t("page.register.establishments.real_time_host") ||
                "Real time host"
              }
              fullWidth
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default Establishment;
