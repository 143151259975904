import { GlobalAxios } from "./../axios/axios-config";
import { ICreateTypebotConfig, ITypebotConfig } from "../models/ticket-service-typebot";

const endpoint = process.env.REACT_APP_TICKETS + "/api/v1/TicketServiceTypebotConfig/";

export const createTypebotConfig = async (item: ICreateTypebotConfig) => {
  return GlobalAxios.post(endpoint, item);
};

export const getTypebotConfigById = async (id: string) => {
  return GlobalAxios.get<ITypebotConfig>(endpoint + id);
};

export const updateTypebotConfig = (
  { 
    item,
    id 
  }: { item: Omit<ITypebotConfig, 'ticketService'>, id: string }) => {
  return GlobalAxios.put(endpoint + id, item);
}

export const getTypebotConfigByTicketId = (id: string) => {
  return GlobalAxios.get<ITypebotConfig>(endpoint + "TicketService/" + id);
};
