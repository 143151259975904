import { Button } from "@4uhub/lib4uhub";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import useCalendar from "../CalendarContext/useCalendar";

const TodayButton = () => {
  const { goToToday } = useCalendar();

  const { t } = useTranslation();

  return (
    <Button variant="outlined" onClick={goToToday}>
      {t("components.schedule.today")}
    </Button>
  );
};

export default memo(TodayButton);
