import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const translationPath = "components.variablesContainer.";

export const VariablesContainer = memo(
  ({ children }: { children: JSX.Element }) => {
    const { t } = useTranslation();

    return (
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
      >
        <Box
          sx={(t) => ({
            p: 2,
            display: "flex",
            flexDirection: "column",
            backgroundColor:
              t.palette.grey[t.palette.mode === "light" ? 100 : 900],
            borderRadius: 1,
          })}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography fontWeight="bold">
              {t(translationPath + "title")}
            </Typography>
            <Typography variant="subtitle2">
              {t(translationPath + "description")}
            </Typography>
          </Box>
          {children}
        </Box>
      </motion.main>
    );
  }
);
