import { GlobalAxios } from "../axios/axios-config";
import {
  IIpmGeneralSetting,
  IIpmGeneralSettings,
} from "../models/ipm-general-settings";

const IPM_GENERAL_SETTINGS_ROUTE =
  process.env.REACT_APP_4UBUS + "/api/v1/4uBusIPMConfigs/";

export const createIpmGeneralSetting = (item: IIpmGeneralSetting) => {
  return GlobalAxios.post(IPM_GENERAL_SETTINGS_ROUTE, item);
};

export const getIpmGeneralSettingById = (id: string) => {
  return GlobalAxios.get<IIpmGeneralSettings>(IPM_GENERAL_SETTINGS_ROUTE + id);
};

export const updateIpmGeneralSettingById = ({
  item,
  id,
}: {
  item: IIpmGeneralSetting;
  id: string;
}) => {
  return GlobalAxios.put(IPM_GENERAL_SETTINGS_ROUTE + id, item);
};
