import { GlobalAxios } from "../axios/axios-config";
import { IHealthCheck, IHealthChecks } from "../models/health-checks";

const HEALTH_CHECKS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/HealthCheck";

export const createHealthCheck = (item: IHealthCheck) => {
  return GlobalAxios.post(HEALTH_CHECKS_ROUTE, item);
};

export const getHealthCheckById = (id: string) => {
  return GlobalAxios.get<IHealthChecks>(HEALTH_CHECKS_ROUTE + "/" + id);
};

export const updateHealthCheckById = ({
  item,
  id,
}: {
  item: IHealthCheck;
  id: string;
}) => {
  return GlobalAxios.put(HEALTH_CHECKS_ROUTE + "/" + id, item);
};
