import { z } from "zod";
import {
  CEL_TEL_VALIDATOR,
  CPF_VALIDATOR,
  EMAIL_VALIDATOR,
  SITE_VALIDATOR,
} from "../../../../utils/Validators/regexValidators";
import { addressSchema } from "@4uhub/lib4uhub";
const translation_path = "page.register.individuals.errors.";

const selectTypeSchema = z.object({
  id: z.string(),
  name: z.string().optional(),
  code: z.string().optional(),
  identifier: z.string().optional(),
  description: z.string().optional().nullable(),
});

const contactSchema = z
  .object({
    id: z.string().optional(),
    contactTypeId: z.string().optional(),
    contactType: selectTypeSchema,
    otherType: z.string().nullable(),
    value: z.string(),
  })
  .superRefine((val, ctx) => {
    if (val.contactType.code) {
      const code = val.contactType.code;
      if (code === "1" || code === "2") {
        if (!CEL_TEL_VALIDATOR.test(val.value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["value"],
            message: translation_path + "contact.phone",
          });
        }
      }
      if (code === "3" || code === "4") {
        if (!EMAIL_VALIDATOR.test(val.value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["value"],
            message: translation_path + "contact.email",
          });
        }
      }
      if (code === "5") {
        if (!SITE_VALIDATOR.test(val.value))
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["value"],
            message: translation_path + "contact.site",
          });
      }
      if (code === "6") {
        if (!val.value) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["value"],
            message: translation_path + "contact.other",
          });
        }
      }
    }
  });

const genderSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
    description: z.string().optional().nullable(),
  },
  {
    invalid_type_error: `${translation_path + "gender"}`,
  }
);

const familyIndividualSchema = z.object(
  {
    id: z.string(),
    individualName: z.string(),
    document: z.string(),
  },
  {
    invalid_type_error: `${translation_path + "family_individual"}`,
  }
);

const kinshipDegreeSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  {
    invalid_type_error: `${translation_path + "kinship_degree"}`,
  }
);

const familyGroupSchema = z.object({
  individual: familyIndividualSchema.optional(),
  kinshipDegree: kinshipDegreeSchema.optional(),
});

export const IndividualSchema = z
  .object({
    document: z
      .string()
      .min(1, translation_path + "document")
      .regex(CPF_VALIDATOR, translation_path + "document_regex"),
    name: z.string().min(1, translation_path + "name"),
    identityCard: z.string().nullable(),
    contacts: z.array(contactSchema),
    birthDate: z.date({
      invalid_type_error: translation_path + "birth_date",
    }),
    addressess: z.array(addressSchema),
    id: z.string().optional(),
    foreigner: z.boolean(),
    country: z.string().nullish(),
    gender: genderSchema,
    familyGroup: z.array(familyGroupSchema).optional(),
  })
  .superRefine((val, ctx) => {
    if (val.foreigner && !val.country) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["country"],
        message: translation_path + "country",
      });
    }
    if (!val.foreigner) {
      return (val.country = null);
    }
  });

export type TIndividualForm = z.infer<typeof IndividualSchema>;
