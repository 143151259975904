import { CircularProgress, Stack } from "@mui/material";

const CustomLoading = ({ size = 25 }: { size?: number }) => {
  return (
    <Stack
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      mt={5}
      mb={5}
    >
      <CircularProgress size={size} />
    </Stack>
  );
};

export default CustomLoading;
