import { z } from "zod";

const translationPath = "page.settings.4uBus.ipm.generalSettings.errors.";

export const ipmGeneralSettingsSchema = z.object({
  id: z.string().optional(),
  host: z
    .string()
    .min(1, translationPath + "host")
    .refine(
      (val) => !val || val.startsWith("https://") || val?.startsWith("http://"),
      {
        message: translationPath + "host_https",
      }
    ),
  ipmUser: z.string().min(1, translationPath + "ipm_user"),
  ipmPassword: z.string().min(1, translationPath + "ipm_password"),
  token4uBus: z.string().min(1, translationPath + "token_4ubus"),
  itensPerBatch: z.string().min(1, translationPath + "itens_per_batch"),
  totalBatches: z.string().min(1, translationPath + "total_batches"),
  heartBeatTimeMinutes: z
    .string()
    .min(1, translationPath + "heart_beat_time_minutes"),
  establishmentCodes: z
    .string()
    .min(1, translationPath + "establishment_codes"),
});

export type TIpmGeneralSettingsForm = z.infer<typeof ipmGeneralSettingsSchema>;
