import { Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const translationPath = "components.extensionsStoreDetail.";

const ExtensionDetailAbout = ({ description }: { description: string }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={12} md={12} mt={5}>
      <Typography
        variant="subtitle1"
        sx={(theme) => ({
          color:
            theme.palette.mode === "light"
              ? theme.palette.grey[600]
              : theme.palette.grey[400],
        })}
      >
        {t(translationPath + "about")}
      </Typography>
      <Stack width={"100%"} mt={2}>
        <Typography variant="subtitle2">{description}</Typography>
      </Stack>
    </Grid>
  );
};

export default ExtensionDetailAbout;
