import { FormCheckBox, FormInput, useFormContext } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo } from "react";
import { useWatch } from "react-hook-form";
import { TQuestionForm } from "./QuestionSchema";
import { useTranslation } from "react-i18next";

const translationPath = "page.assessments.questions.";

export const QuestionOptionType = memo(() => {
  const { t } = useTranslation();

  const { control } = useFormContext<TQuestionForm>();

  const isRequiredJustification = useWatch({
    name: "questionOptionType.isRequiredJustification",
    control,
  });

  return (
    <>
      <Grid item sm={12}>
        <FormCheckBox
          name="questionOptionType.isRequiredJustification"
          label={`${t(translationPath + "required_justification")}`}
        />
      </Grid>

      {isRequiredJustification && (
        <>
          <Grid item sm={6}>
            <FormInput
              name="questionOptionType.requiredJustificationLessThan"
              label={t(translationPath + "required_justification_less_than")}
              type="number"
              fullWidth
              size="small"
            />
          </Grid>

          <Grid item sm={6}>
            <FormInput
              name="questionOptionType.requiredJustificationMinChars"
              label={t(translationPath + "required_justification_min_chars")}
              type="number"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item sm={12}>
            <FormInput
              name="questionOptionType.requiredJustificationMessage"
              label="Texto justificativa"
              fullWidth
              size="small"
              multiline
            />
          </Grid>
        </>
      )}
    </>
  );
});
