import { useTranslation } from "react-i18next";

import { AutoComplete } from "@4uhub/lib4uhub";

import { buildTPath } from "../../advantages-and-benefits-club.utils";
import { salesContractClassificationService } from "../../../../../../services/salesContractClassification.service";

const ContractClassificationSelect = () => {
  const { t } = useTranslation();

  const translate = (field: string) => t(buildTPath(field));

  return (
    <AutoComplete
      name="salesContractClassification"
      size="small"
      label={translate("contract_classification")}
      getOptionLabel={({ name }) => name}
      request={salesContractClassificationService.list}
    />
  );
};

export default ContractClassificationSelect;
