import { Stack, Tooltip, Typography } from "@mui/material";
import { ReactNode } from "react";

const ExtensionsCardPrices = ({
  title,
  icon,
  price,
}: {
  title: string;
  icon: ReactNode;
  price: number;
}) => {
  return (
    <Tooltip title={title}>
      <Stack
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        gap={0.5}
      >
        {icon}
        <Typography variant="subtitle2">
          {currencyFormatter.format(price)}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

export default ExtensionsCardPrices;

export const currencyFormatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});
