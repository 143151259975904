import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import {
  IIpmTasyRelationship,
  IIpmTasyRelationships,
} from "../models/ipm-tasy";

const IPM_ROUTE =
  process.env.REACT_APP_4UBUS + "/api/v1/EstablishmentSetRelationships/";

const IPM_SYNC_ROUTE =
  process.env.REACT_APP_4UBUS + "/api/v1/SetRelationshipType/";

export const createIpm = (item: IIpmTasyRelationship) => {
  return GlobalAxios.post(IPM_ROUTE, item);
};

export const getIpmById = (id: string) => {
  return GlobalAxios.get<IIpmTasyRelationships>(IPM_ROUTE + id);
};

export const updateIpmById = ({
  item,
  id,
}: {
  item: IIpmTasyRelationship;
  id: string;
}) => {
  return GlobalAxios.put(IPM_ROUTE + id, item);
};

export const syncIpmTasyNow = () => {
  return GlobalAxios.post(IPM_SYNC_ROUTE + "Sync/Now");
};

export const getIntegratedIpmTasy = () => {
  return GlobalAxios.get<ISelectType[]>(IPM_SYNC_ROUTE + "List");
};
