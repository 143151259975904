import { z } from "zod";
const translationsPath =
  "page.4uSalesContract.registers.contractProducts.products.errors.";

const situationSchema = z.object({
  id: z.string(),
  name: z.string().optional().nullable(),
  code: z.string().optional().nullable(),
});

const salesProductCtrClassificationSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
    code: z.number().optional().nullable(),
  },
  { invalid_type_error: translationsPath + "sales_product_ctr_classification" }
);

const salesContractTypeSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationsPath + "sales_contract_type" }
);

const salesGrantorSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationsPath + "sales_grantors" }
);

export const contractProductSchema = z.object({
  name: z.string().min(1, translationsPath + "name"),
  fantasyName: z.string().min(1, translationsPath + "fantasy_name"),
  salesContractType: salesContractTypeSchema,
  salesProductCtrClassification: salesProductCtrClassificationSchema,
  integrationId: z.string().optional().nullable(),
  situation: situationSchema.optional().nullable(),
  salesGrantors: z
    .array(salesGrantorSchema)
    .min(1, translationsPath + "sales_grantors"),
});

export type TContractProductForm = z.infer<typeof contractProductSchema>;
