import { z } from "zod";

const translationPath = "page.settings.4uBus.configs.errors.";

export const configSchema = z.object({
  id: z.string().optional(),
  host4uBus: z
    .string()
    .min(1, translationPath + "host_4ubus")
    .refine((val) => val.startsWith("https://") || val?.startsWith("http://"), {
      message: translationPath + "host_4ubus_https",
    }),
  hostAws: z
    .string()
    .min(1, translationPath + "host_aws")
    .refine((val) => val.startsWith("https://") || val?.startsWith("http://"), {
      message: translationPath + "host_aws_https",
    }),
});

export type TConfigForm = z.infer<typeof configSchema>;
