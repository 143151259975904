import { z } from "zod";

const translationPath = "page.settings.crm.protocolsDefault.errors.";

const mediaFileSchema = z.object(
  {
    id: z.string().min(1, translationPath + "file"),
    mediaTypeId: z.string().optional(),
    isPublic: z.boolean().optional(),
    fileName: z.string().optional(),
    cdnDomain: z.string().optional(),
    filePath: z.string().optional(),
    mimeType: z.string().optional(),
    fileSize: z.number().optional(),
  },
  { required_error: translationPath + "file" }
);

const contentMediaFilesSchema = z
  .object({
    file: mediaFileSchema,
    description: z
      .string()
      .min(1, translationPath + "min_description")
      .max(1000, translationPath + "max_description"),
  })
  .superRefine((val, ctx) => {
    if (val.file.id.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["file"],
        message: translationPath + "file",
      });
    }
  });

const ticketWorkspaceSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional(),
});

export const protocolDefaultSchema = z.object({
  id: z.string().optional(),
  enable: z.boolean(),
  title: z.string().min(1, translationPath + "title"),
  message: z.string().min(1, translationPath + "message"),
  ticketWorkspaces: z
    .array(ticketWorkspaceSchema)
    .min(1, translationPath + "workspaces"),
  ticketDefaultDocMediaFiles: z
    .array(contentMediaFilesSchema)
    .optional()
    .nullable(),
});
export type TProtocolDefaultForm = z.infer<typeof protocolDefaultSchema>;
