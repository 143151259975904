import { useTranslation } from "react-i18next";

import { AutoComplete } from "@4uhub/lib4uhub";

import { buildTPath } from "../../advantages-and-benefits-club.utils";
import { listSalesGrantors } from "../../../../../../services/salesGrantor.service";

const GrantorSelect = () => {
  const { t } = useTranslation();

  const translate = (field: string) => t(buildTPath(field));

  return (
    <AutoComplete
      name="salesGrantor"
      size="small"
      fullWidth
      label={translate("grantor")}
      getOptionLabel={({ name }) => name}
      request={listSalesGrantors}
    />
  );
};

export default GrantorSelect;
