import {
  createContext,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { ITicketAutomation } from "../../models/ticket-automation-rules";

interface IAutomationRulesContextProps {
  automation: ITicketAutomation | null;
  selectAutomation: (value: ITicketAutomation | null) => void;
}

const AutomationRulesContext = createContext<IAutomationRulesContextProps>({
  automation: null,
  selectAutomation: () => {},
});

interface IAutomationRulesProviderProps {
  children: JSX.Element;
}

export const AutomationRulesProvider: React.FC<IAutomationRulesProviderProps> =
  memo(({ children }) => {
    const [automation, setAutomation] = useState<ITicketAutomation | null>(
      null
    );

    const selectAutomation = useCallback((value: ITicketAutomation | null) => {
      setAutomation(value);
    }, []);

    const value = useMemo(
      () => ({
        automation,
        selectAutomation,
      }),
      [automation, selectAutomation]
    );

    return (
      <AutomationRulesContext.Provider value={value}>
        {children}
      </AutomationRulesContext.Provider>
    );
  });

export const useAutomationRules = () => {
  const context = useContext(AutomationRulesContext);

  if (!context) {
    throw new Error("useAutomationRules must be used with DashBoardProvider");
  }

  return context;
};
