import { Box, Grid, Typography } from "@mui/material";
import { memo } from "react";
import { TextField } from "../../../../TextField/TextField";
import { ICopyOfferCodeActionProps } from "./models";
import { useTranslation } from "react-i18next";

const translatuionPath = "components.copyOfferCodeAction.";

export const CopyOfferCodeAction = memo(
  ({ index }: ICopyOfferCodeActionProps) => {
    const { t } = useTranslation();

    return (
      <>
        <Grid item xs={12} sm={3}>
          <TextField
            name={`ctaButtons.${index}.text`}
            label={t(translatuionPath + "text")}
            fullWidth
            size="small"
            maxLength={25}
            disabled
            sx={(t) => ({
              background: `linear-gradient(${t.palette.background.default} 40px, transparent 40px)`,
              borderRadius: 1,
            })}
          />
        </Grid>
        <Grid item sm={12}>
          <Box sx={{ my: 2, display: "flex", flexDirection: "column" }}>
            <Typography variant="body2" fontWeight="bold" fontSize={13}>
              {t(translatuionPath + "info.title")}
            </Typography>
            <Typography
              variant="body2"
              fontSize={13}
              sx={(t) => ({
                color: t.palette.grey[t.palette.mode === "light" ? 600 : 400],
              })}
            >
              {t(translatuionPath + "info.subtitle")}
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={12}>
          <TextField
            name={`ctaButtons.${index}.payload`}
            label={t(translatuionPath + "payload")}
            fullWidth
            maxLength={15}
            size="small"
            sx={(t) => ({
              background: `linear-gradient(${t.palette.background.default} 40px, transparent 40px)`,
              borderRadius: 1,
            })}
          />
        </Grid>
      </>
    );
  }
);
