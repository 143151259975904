import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import { IAccessToken, IAccessTokens } from "../models/access-token";

const ACCESS_TOKEN_ROUTE = process.env.REACT_APP_4UBUS + "/api/v1/4uBusUsers";

export const createAccessToken = (item: IAccessToken) => {
  return GlobalAxios.post(ACCESS_TOKEN_ROUTE, item);
};

export const getAccessTokenById = (id: string) => {
  return GlobalAxios.get<IAccessTokens>(ACCESS_TOKEN_ROUTE + "/" + id);
};

export const updateAccessTokenById = ({
  item,
  id,
}: {
  item: IAccessToken;
  id: string;
}) => {
  return GlobalAxios.put(ACCESS_TOKEN_ROUTE + "/" + id, item);
};

export const busUsersList = () => {
  return GlobalAxios.get<ISelectType[]>(ACCESS_TOKEN_ROUTE + "/List");
};
