import { z } from "zod";

const translationPath =
  "page.settings.4uBus.externalExamPrescription.rules.errors.";

export const ruleSchema = z
  .object({
    identifier: z.string().optional(),
    initialDate: z.date({
      invalid_type_error: translationPath + "initial_date",
    }),
    endDate: z.date({ invalid_type_error: translationPath + "end_date" }),
    medicalProcedureTypeCode: z
      .string()
      .min(1, translationPath + "medical_procedure_type_code")
      .max(3, translationPath + "max_medical_procedure_type_code"),
    sectorCode: z
      .string()
      .max(5, translationPath + "max_sector_code")
      .optional()
      .nullable(),
    execPhysicianCode: z.string().optional().nullable(),
    isExceptions: z.boolean(),
    patientInsuranceCode: z.string().optional().nullable(),
  })
  .superRefine((val, ctx) => {
    if (val.initialDate > val.endDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: translationPath + "initial_date_bigger",
        path: ["initialDate"],
      });
    }

    if (val.execPhysicianCode && val.execPhysicianCode.length > 10) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: translationPath + "max_exec_physician_code",
        path: ["execPhysicianCode"],
      });
    }

    if (val.patientInsuranceCode && val.patientInsuranceCode.length > 5) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: translationPath + "max_patient_insurance_code",
        path: ["patientInsuranceCode"],
      });
    }

    if (!val.isExceptions) {
      if (!val.sectorCode || val.sectorCode.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: translationPath + "sector_code",
          path: ["sectorCode"],
        });
      }
    }
  });

export type TRuleForm = z.infer<typeof ruleSchema>;
