import { z } from "zod";
import { buildTPath } from "../beneficiary-termination-reason.utils";

export const beneficiaryTerminationReasonSchema = z.object({
  id: z.string().nullish(),
  name: z.string().min(1, buildTPath("errors.name")),
  enable: z.boolean(),
});

export type TBeneficiaryTerminationReasonForm = z.infer<
  typeof beneficiaryTerminationReasonSchema
>;
