import { FormInput, useFormContext } from "@4uhub/lib4uhub";
import { Grid, Stack, Typography } from "@mui/material";
import { memo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

const translationPath = "page.settings.preventMedicine.healthProgramTypes.";

interface IHealthProgramTypeDescriptionProps {
  name: string;
}

const HealthProgramTypeDescription = ({
  name,
}: IHealthProgramTypeDescriptionProps) => {
  const { t } = useTranslation();

  const { control } = useFormContext();

  const description = useWatch({
    name: `${name}`,
    control,
  });

  return (
    <Grid item sm={12}>
      <FormInput
        name={name}
        size="small"
        label={t(translationPath + "description")}
        fullWidth
        multiline
        minRows={4}
      />
      <Stack width={"100%"} textAlign={"end"}>
        <Typography
          sx={(t) => ({
            color:
              description?.length > 500
                ? t.palette.error.main
                : t.palette.text.secondary,
          })}
        >
          {`${description?.length || 0}/500 ${t(
            translationPath + "characters"
          )}`}
        </Typography>
      </Stack>
    </Grid>
  );
};

export default memo(HealthProgramTypeDescription);
