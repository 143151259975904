import { Button } from "@4uhub/lib4uhub";
import {
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ISyncSectionProps } from "./models";
import { useTranslation } from "react-i18next";

const translationPath = "components.syncSection.";

const SyncSection = ({
  title,
  description,
  loading,
  disable,
  onSync,
  onViewData,
}: ISyncSectionProps) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack
      display={"flex"}
      flexDirection={!matches ? "row" : "column"}
      alignItems={!matches ? "center" : "end"}
      justifyContent={!matches ? "space-between" : undefined}
      gap={2}
    >
      <Stack>
        <Typography variant="subtitle1" fontWeight={"bold"}>
          {title}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={(theme) => ({
            color:
              theme.palette.mode === "light"
                ? theme.palette.grey[600]
                : theme.palette.grey[400],
          })}
        >
          {description}
        </Typography>
      </Stack>
      <Stack flexDirection={"row"} gap={1}>
        <Button
          variant="contained"
          disabled={loading || disable ? true : false}
          onClick={onSync}
        >
          {loading && (
            <Stack direction={"row"} spacing={2}>
              <CircularProgress size={25} sx={{ marginInline: 2 }} />
            </Stack>
          )}
          {!loading && t(translationPath + "sync")}
        </Button>
        <Button
          variant="outlined"
          disabled={loading ? true : false}
          onClick={onViewData}
        >
          {t(translationPath + "view_data")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default SyncSection;
