import { z } from "zod";
import { buildTPath } from "../contract-produc-classification.utils";

export const contractProductClassificationSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, buildTPath("errors.name")),
  enable: z.boolean(),
  code: z.number().optional(),
});

export type TContractProductClassificationForm = z.infer<
  typeof contractProductClassificationSchema
>;
