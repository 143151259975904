import {
  AutoComplete,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { evaluationFormList } from "../../../../../../../services/evaluationForm.service";
import { useAutomationRules } from "../../../../../../../store/contexts/AutomationRulesContext";
import { TMessageForm } from "./messageSchema";

const translationPath = "components.messages.";

export const SelectEvaluationForm = memo(() => {
  const { t } = useTranslation();

  const { automation } = useAutomationRules();

  const { setValue } = useFormContext<TMessageForm>();

  const [options, setOptions] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(evaluationFormList);

  const fetchEvaluationForm = useCallback(async () => {
    const { data, success } = await sendRequest(null);
    if (data && success) {
      setOptions(data.map((d) => ({ id: d.id, name: d.title })));
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchEvaluationForm();
  }, [fetchEvaluationForm]);

  useEffect(() => {
    setValue("ticketAutomation", automation);
  }, [setValue, automation]);

  if (automation && !automation.allowForm) return null;

  return (
    <Grid item xs={12} sm={10}>
      <AutoComplete
        name="evaluationForm"
        label={t(translationPath + "evaluation_form")}
        getOptionLabel={(option) => option.name}
        size="small"
        fullWidth
        options={options}
      />
    </Grid>
  );
});
