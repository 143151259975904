import { Grid } from "@mui/material";

import { IInputFile } from "@4uhub/lib4uhub";

import { useTranslation } from "react-i18next";
import { InputFileForm, useFormContext } from "@4uhub/lib4uhub";
import { useWatch } from "react-hook-form";
import { GlobalAxios } from "../../../../axios/axios-config";

const FILE_ROUTE = process.env.REACT_APP_FILEMANAGER + "/api/v1/Upload";
interface IFileValueProps {
  index: number;
  onFileSelected: (file: IInputFile) => void;
}

const FileValue: React.FC<IFileValueProps> = ({ index, onFileSelected }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const mediaTypeCode = useWatch({
    name: `extensionMediaFiles.${index}.mediaType.code`,
    control,
  });

  return (
    <Grid item xs={12} sm={8}>
      <InputFileForm
        axios={GlobalAxios}
        fileRoute={FILE_ROUTE}
        name={`extensionMediaFiles.${index}.file`}
        fileInputProps={{
          accept: ".jpg, ,jpeg, .png, .webp",
          label: `${t("page.register.extensions.file")}`,
          maxFileSize: 2,
          mediaTypeCode: mediaTypeCode,
          mediaTypeIdentifier: "ExtensionMediaType",
          parentName: "Extension",
          type: "Public",
          onFileSelected: onFileSelected,
          inputProps: {
            size: "small",
          },
        }}
      />
    </Grid>
  );
};

export default FileValue;
