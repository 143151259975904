import { GlobalAxios } from "../axios/axios-config";
import { ISelectCountry } from "../components/UI/Inputs/AutoCompleteCountry/models";

const COUNTRY_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Country/";

export const countriesList = (params?: any) => {
  return GlobalAxios.get<ISelectCountry[]>(COUNTRY_ROUTE + "list", {
    params: params,
  });
};
