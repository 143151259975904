import { Divider, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  AutoCompleteMulti,
  FormContainer,
  FormInput,
  SwitchInput,
  useFetch,
} from "@4uhub/lib4uhub";

import { TRolesForm, rolesSchema } from "./RolesSchema";
import RolesPrivileges from "./RolesPrivileges";
import RolesEstablishments from "./RolesEstablishments";
import RolesService from "../../../../services/roles.service";
import ProfilesService from "../../../../services/profiles.service";

const GENERICS_ROUTE =
  process.env.REACT_APP_IDENTIY + "/api/v1/RoleEstablishments/";

const service = new RolesService(GENERICS_ROUTE);

const profilesService = new ProfilesService();

const translationPath = "page.register.roles.";

const Roles = () => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(service.create);
  const { sendRequest: update, loading: uLoading } = useFetch(
    service.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(service.getItemById);

  const handleUpdate = (v: TRolesForm, id: string) => {
    return update({
      item: {
        ...v,
        id: id,
        establishmentIds: v.establishments.map((i) => i.id),
        privilegeIds: v.privileges.map((p) => p.id),
        profileIds: v.profiles.map((profile) => profile.id),
      },
      id: id,
    });
  };

  const handleSave = (v: TRolesForm) => {
    return save({
      ...v,
      establishmentIds: v.establishments.map(
        (establishment) => establishment.id
      ),
      privilegeIds: v.privileges.map((privilege) => privilege.id),
      profileIds: v.profiles.map((profile) => profile.id),
    });
  };

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TRolesForm>
      loading={loading}
      title={t(translationPath + "roles")}
      defaultValues={{
        privileges: [],
        establishments: [],
      }}
      saveFunction={handleSave}
      updateFunction={handleUpdate}
      getFunction={get}
      schema={rolesSchema}
      subtitle={t(translationPath + "new_role")}
      subtitleWatchField={"name"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            <SwitchInput
              name="enable"
              label={t(translationPath + "enable") || "Enable"}
            />
          </Grid>

          <Grid item xs={12}>
            <FormInput
              size="small"
              autoFocus={true}
              name="name"
              label={t(translationPath + "name") || "Name"}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              size="small"
              multiline
              name="description"
              fullWidth
              minRows={4}
              label={t(translationPath + "description") || "Description"}
            />
          </Grid>

          <Grid item xs={12} sm={12} mt={2}>
            <Divider sx={{ width: "100%", marginBottom: 2 }} />
            <Typography variant="h6">
              {t(translationPath + "config_access")}
            </Typography>
          </Grid>

          {/* Establishments */}
          <RolesEstablishments />
          <Grid item xs={12}>
            <AutoCompleteMulti
              params={{}}
              size="small"
              label={t(translationPath + "roles_profile") || "Roles"}
              name={"profiles"}
              request={profilesService.list}
            />
          </Grid>

          {/* Privileges */}
          <RolesPrivileges />
        </Grid>
      )}
    />
  );
};

export default Roles;
