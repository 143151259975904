import { z } from "zod";
import { buildTPath } from "../beneficiarySuspensionsReason.utils";

export const beneficiarySuspensionsReasonSchema = z.object({
  name: z.string().min(1, buildTPath("errors.name")),
  enable: z.boolean(),
});

export type TBeneficiarySuspensionReasonForm = z.infer<
  typeof beneficiarySuspensionsReasonSchema
>;
