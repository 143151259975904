import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../../../../services/mainApi.service";
import { Table, useNotificationContext } from "@4uhub/lib4uhub";
import { IIpmGeneralSettings } from "../../../../../../models/ipm-general-settings";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const IPM_GENERAL_SETTINGS_ROUTE =
  process.env.REACT_APP_4UBUS + "/api/v1/4uBusIPMConfigs/";
const ipmGeneralSettingsService = new MainApiService<IIpmGeneralSettings>(
  IPM_GENERAL_SETTINGS_ROUTE
);

const translationPath = "page.settings.4uBus.ipm.generalSettings.";

const IpmGeneralSettingsPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { eI } = useParams();

  const { setMessage } = useNotificationContext();

  const copyTokenHandler = (
    event: React.MouseEvent<HTMLButtonElement> | undefined,
    token: string
  ) => {
    if (event) {
      event.stopPropagation();
      setMessage({
        message: "Token do 4uBus copiado com sucesso",
        type: "success",
      });
    }
    navigator.clipboard.writeText(token);
  };

  const columns: GridColDef<IIpmGeneralSettings>[] = [
    {
      field: "host",
      headerName: t(translationPath + "host") || "Host",
    },
    {
      field: "token4uBus",
      headerName: t(translationPath + "token_4ubus") || "Token 4uBus",
      renderCell: (params) => {
        if (params.value.length === 0 || !params.value) {
          return "-";
        }

        return (
          <Stack
            width={"100%"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={1}
          >
            <Tooltip title={params.value}>
              <Typography variant="subtitle2" noWrap>
                {params.value}
              </Typography>
            </Tooltip>
            <Tooltip title={t(translationPath + "token_copy")}>
              <IconButton
                size="small"
                onClick={(e) => copyTokenHandler(e, params.value || "")}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      field: "establishment",
      headerName: t(translationPath + "establishment") || "Estabelecimento",
      renderCell: (params) => (params.value ? params.value.name : "-"),
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IIpmGeneralSettings>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<IIpmGeneralSettings>
      showDefaultMacroFilters={false}
      service={ipmGeneralSettingsService}
      columns={columns}
      title={t(translationPath + "general_settings")}
      searchInput={false}
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      backButton
      backButtonUrl={`/e/${eI}/dashboard/settings`}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a general setting"
      }
      addButtonLabel={
        t(translationPath + "add_general_setting") || "Add General Setting"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default IpmGeneralSettingsPage;
