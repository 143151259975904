import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table } from "@4uhub/lib4uhub";

import MainApiService from "../../../../services/mainApi.service";
import { IHealthProgramsContentRules } from "../../../../models/health-program-content-rule";
import { format } from "date-fns";
import { ptBR, enUS } from "date-fns/locale";

import SubjectIcon from "@mui/icons-material/Subject";

const HEALTH_PROGRAMS_CONTENT_RULES_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/HealthProgramContentRule/";
const healthProgramsContentRulesService =
  new MainApiService<IHealthProgramsContentRules>(
    HEALTH_PROGRAMS_CONTENT_RULES_ROUTE
  );

const translationPath = "page.4uHcp.hcp.healthProgramsContentRules.";

const HealthProgramsContentRulesPage = () => {
  const navigate = useNavigate();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const columns: GridColDef<IHealthProgramsContentRules>[] = [
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
    },
    {
      field: "healthProgram",
      headerName: t(translationPath + "health_program") || "Health Program",
      renderCell: (params) => (params.value ? params.value.name : "-"),
    },
    {
      field: "initialDate",
      headerName: t(translationPath + "initial_date") || "Initial Date",
      maxWidth: 150,
      renderCell: (params) => {
        const date = new Date(params.value);
        return params.value
          ? format(date, "P", {
              locale: language === "pt-BR" ? ptBR : enUS,
            })
          : "-";
      },
    },
    {
      field: "endDate",
      headerName: t(translationPath + "end_date") || "End Date",
      maxWidth: 150,
      renderCell: (params) => {
        const date = new Date(params.value);
        return params.value
          ? format(date, "P", {
              locale: language === "pt-BR" ? ptBR : enUS,
            })
          : "-";
      },
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (
    params: GridRowParams<IHealthProgramsContentRules>
  ) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const redirectToContentsPage = (id: string) => {
    navigate(`${id}/contents`);
  };

  return (
    <Table<IHealthProgramsContentRules>
      showDefaultMacroFilters={false}
      service={healthProgramsContentRulesService}
      columns={columns}
      title={t(translationPath + "health_programs_content_rules")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      rowActions={[
        {
          text: t(translationPath + "contents") || "Contents",
          action: (params) => redirectToContentsPage(params.row.id),
          icon: <SubjectIcon />,
        },
      ]}
      searchInputPlaceHolder={
        t(translationPath + "search") ||
        "Search for a health program content rule"
      }
      addButtonLabel={
        t(translationPath + "add_health_program_content_rule") ||
        "Add Health Program Content Rule"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default HealthProgramsContentRulesPage;
