import {
  Box,
  Grid,
  IconButton,
  SwipeableDrawer,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

interface IDrawerProps {
  open: boolean;
  children: any;
  anchor: "left" | "right";
  alignClose?: "start" | "end";
  keyDownClose?: boolean;
  toggleDetail: (open: boolean) => void;
}

const Drawer = ({
  open,
  children,
  anchor,
  alignClose = "start",
  keyDownClose = true,
  toggleDetail,
}: IDrawerProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <SwipeableDrawer
      anchor={anchor}
      open={open}
      onClose={(e) => toggleDetail(false)}
      onOpen={() => toggleDetail(true)}
      sx={{ zIndex: 1400 }}
    >
      <Box
        sx={(theme) => ({
          width: matches ? "100vw" : 600,
          height: "100%",
          backgroundColor:
            theme.palette.mode === "light"
              ? "#fff"
              : theme.palette.background.default,
        })}
        role="presentation"
        onKeyDown={() => {
          if (keyDownClose) {
            toggleDetail(false);
          }
        }}
      >
        <Grid container p={4} spacing={2} mb={2}>
          <Grid item xs={12} sm={12} md={12} textAlign={alignClose}>
            <IconButton
              size="small"
              onClick={() => toggleDetail(false)}
              sx={{ padding: 0 }}
            >
              <CloseIcon
                sx={(theme) => ({
                  color:
                    theme.palette.mode === "light"
                      ? theme.palette.grey[600]
                      : theme.palette.grey[200],
                })}
              />
            </IconButton>
          </Grid>
          {children}
        </Grid>
      </Box>
    </SwipeableDrawer>
  );
};

export default Drawer;
