import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  FormContainer,
  ISelectType,
  SwitchInput,
  useFetch,
} from "@4uhub/lib4uhub";
import WorkGroupTypeLanguages from "./WorkGroupTypeLanguages";
import {
  createWorkGroupType,
  getWorkGroupTypeById,
  updateWorkGroupTypeById,
} from "../../../../../../services/workGroupTypes.service";
import { TWorkGroupTypeForm, workGroupTypeSchema } from "./WorkGroupTypeSchema";
import { useCallback, useEffect, useState } from "react";
import MainApi from "../../../../../../services/mainApi.service";

const LANGUAGE_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Language/";
const languageService = new MainApi<ISelectType>(LANGUAGE_ROUTE);

const translationPath = "page.settings.preventMedicine.workGroupTypes.";

const WorkGroupType = () => {
  const { t } = useTranslation();

  const [allLanguages, setAllLanguages] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(languageService.list);

  const fetchLanguages = useCallback(async () => {
    const { data, success } = await sendRequest(undefined);
    if (data && success) {
      setAllLanguages(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchLanguages();
  }, [fetchLanguages]);

  const { sendRequest: save, loading: sLoading } =
    useFetch(createWorkGroupType);

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateWorkGroupTypeById
  );

  const { sendRequest: get, loading: gLoading } =
    useFetch(getWorkGroupTypeById);

  const updateHandler = (v: TWorkGroupTypeForm, id: string) =>
    update({
      item: {
        ...v,
        enable: v.enable!,
        healthProgramWorkGroupTypeLanguages:
          v.healthProgramWorkGroupTypeLanguages.map((languageItem) => ({
            ...languageItem,
            languageId: languageItem.language.id,
          })),
      },
      id: id,
    });

  const saveHandler = (v: TWorkGroupTypeForm) =>
    save({
      ...v,
      enable: true,
      healthProgramWorkGroupTypeLanguages:
        v.healthProgramWorkGroupTypeLanguages.map((languageItem) => ({
          ...languageItem,
          languageId: languageItem.language.id,
        })),
    });

  const loading = sLoading || uLoading || gLoading;

  if (allLanguages.length === 0) return null;

  return (
    <FormContainer<TWorkGroupTypeForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={get}
      loading={loading}
      title={t(translationPath + "work_group_type")}
      schema={workGroupTypeSchema}
      subtitle={t(translationPath + "new_work_group_type")}
      subtitleWatchField={"healthProgramWorkGroupTypeLanguages.0.name"}
      defaultValues={{
        healthProgramWorkGroupTypeLanguages: [
          {
            name: "",
            description: "",
            language: allLanguages[0],
          },
        ],
      }}
      fields={(type) => (
        <Grid container spacing={2}>
          {type === "update" && (
            <Grid item sm={12} textAlign={"end"}>
              <SwitchInput
                name="enable"
                label={t(translationPath + "enable")}
              />
            </Grid>
          )}

          <Grid item sm={12}>
            <WorkGroupTypeLanguages allLanguages={allLanguages} />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default WorkGroupType;
