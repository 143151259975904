import { Grid } from "@mui/material";
import { AutoComplete, ISelectType, useFetch } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

import MainApiService from "../../../../services/mainApi.service";
import { useCallback, useEffect, useState } from "react";

const GENDER_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const genderService = new MainApiService<ISelectType>(GENDER_ROUTE);

const translation_path = "page.register.individuals.";

const IndividualGender = () => {
  const { t } = useTranslation();
  const [genders, setGenders] = useState<ISelectType[]>();

  const { sendRequest } = useFetch(genderService.list);

  const onLoadGenders = useCallback(async () => {
    const { data } = await sendRequest({ Identifier: "Gender" });
    if (data) {
      setGenders(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    onLoadGenders();
  }, [onLoadGenders]);

  return (
    <Grid item xs={12} sm={6}>
      <AutoComplete
        getOptionLabel={(option) => {
          return option.name;
        }}
        size="small"
        label={t(translation_path + "gender")}
        name={"gender"}
        options={genders}
      />
    </Grid>
  );
};

export default IndividualGender;
