import { AutoCompleteMulti, ISelectType, useFetch } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MainApi from "../../../../../../../services/mainApi.service";

const translationPath = "components.messages.";

const endpoint =
  process.env.REACT_APP_MAIN + "/api/v1/Establishment/ByCompanyGroup/";

const establishmentService = new MainApi(endpoint);

export const SelectEstablishment = memo(
  ({ automationCode }: { automationCode: string }) => {
    const { t } = useTranslation();

    const [options, setOptions] = useState<ISelectType[]>([]);

    const { sendRequest } = useFetch(establishmentService.list);

    const fetchEstablishments = useCallback(async () => {
      const { data, success } = await sendRequest(null);
      if (data && success) {
        setOptions(data.map(({ name, id }) => ({ id, name })));
      }
    }, [sendRequest]);

    useEffect(() => {
      fetchEstablishments();
    }, [fetchEstablishments]);

    return (
      <Grid
        item
        xs={12}
        sm={automationCode === "9" || automationCode === "10" ? 6 : 12}
      >
        <AutoCompleteMulti
          name="establishments"
          label={t(translationPath + "establishment")}
          getOptionLabel={(option) => option.name}
          size="small"
          fullWidth
          options={options}
        />
      </Grid>
    );
  }
);
