import { IProfile } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";

const PROFILES_ROUTE =
  process.env.REACT_APP_IDENTIY + "/api/v1/Users/Profiles/WithoutAllowLink";

export class ProfilesService {
  list = (params?: any) => {
    return GlobalAxios.get<IProfile[]>(PROFILES_ROUTE, {
      params: params,
    });
  };
}

export default ProfilesService;
