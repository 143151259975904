import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import { IBeneficiarySuspensionReasonPayload } from "../models/beneficiary-suspension-reason";

const serviceURL =
  process.env.REACT_APP_MAIN + "/api/v1/BeneficiaryContractReasonOfSuspension";

export const createBeneficiarySuspensionReason = (
  payload: IBeneficiarySuspensionReasonPayload
) => {
  return GlobalAxios.post(serviceURL, payload);
};

export const updateBeneficiarySuspensionReason = ({
  id,
  item,
}: {
  id: string;
  item: IBeneficiarySuspensionReasonPayload;
}) => {
  return GlobalAxios.put(`${serviceURL}/${id}`, item);
};

export const findBeneficiarySuspensionReasonById = (id: string) => {
  return GlobalAxios.get(`${serviceURL}/${id}`);
};

export const listBeneficiarySuspensionsReasons = () => {
  return GlobalAxios.get<ISelectType[]>(`${serviceURL}/list`);
};

export const removeBeneficiarySuspensionReason = (id: string) => {
  return GlobalAxios.delete(`${serviceURL}/${id}`);
};
