import {
  Button,
  DomainProvider,
  useFetch,
  useNotificationContext,
} from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { generateNotificationBatch } from "../../../../../services/notification.service";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import NotificationBatchCancel from "./NotificationBatchCancel";

const translationPath = "components.notificationBatchActions.";

const NotificationBatchActions = ({ statusCode }: { statusCode: string }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setMessage } = useNotificationContext();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const { sendRequest: generateBatch, loading: generateLoading } = useFetch(
    generateNotificationBatch
  );

  const generateBatchHandler = useCallback(async () => {
    if (id) {
      const { data, success } = await generateBatch(id);
      if (data && success) {
        setMessage({
          message: `${t(translationPath + "batch_generated")}`,
          type: "success",
        });
        navigate(-1);
      }
    }
  }, [generateBatch, navigate, id, setMessage, t]);

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <DomainProvider value={{ domainName: "notificationadm" }} redirect={false}>
      <Grid
        item
        sm={12}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"end"}
        gap={1}
      >
        <NotificationBatchCancel
          anchorEl={anchorEl}
          handleClose={handleClose}
          open={open}
          statusCode={statusCode}
        />
        <Button
          variant="outlined"
          size="small"
          onClick={handleCancel}
          disabled={statusCode === "4"}
        >
          {t(translationPath + "cancel")}
        </Button>
        <Button
          loading={generateLoading}
          variant="contained"
          size="small"
          onClick={generateBatchHandler}
          disabled={statusCode === "4"}
        >
          {t(translationPath + "generate")}
        </Button>
      </Grid>
    </DomainProvider>
  );
};

export default NotificationBatchActions;
