import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { IGeneric } from "../../../models/generics";
import { useTranslation } from "react-i18next";
import { MainApi } from "../../../services/mainApi.service";
import { Table } from "@4uhub/lib4uhub";

const GENERICS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const TRANSLATION_PATH = "page.register.generics.";
const genericService = new MainApi<IGeneric>(GENERICS_ROUTE);

const GenericsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columns: GridColDef<IGeneric>[] = [
    {
      field: "identifier",
      headerName: t(TRANSLATION_PATH + "identifier") || "Identifier",
    },
    {
      field: "code",
      headerName: t(TRANSLATION_PATH + "code") || "Code",
    },
    {
      field: "value",
      headerName: t(TRANSLATION_PATH + "value") || "Value",
    },
  ];

  const handleRowClick = (params: GridRowParams<IGeneric>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<IGeneric>
      showDefaultMacroFilters={false}
      service={genericService}
      columns={columns}
      title={t("page.register.generics.generics")}
      searchInput
      showButton={false}
      defaultActions={false}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={
        t(TRANSLATION_PATH + "search_identifier") || "Searh for a identifier"
      }
      addButtonLabel={t(TRANSLATION_PATH + "add_generic") || "Add Generic"}
      confirmOptions={{
        title: t(TRANSLATION_PATH + "confirm.title"),
        description: t(TRANSLATION_PATH + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default GenericsPage;
