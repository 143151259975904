import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";

const BUS_PRIVILEGES_ROUTE =
  process.env.REACT_APP_4UBUS + "/api/v1/RestrictionRuleType/";

export const listRestrictionRuleExtensions = () => {
  return GlobalAxios.get<ISelectType[]>(
    BUS_PRIVILEGES_ROUTE + "ListExtensions"
  );
};
