import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

// Schema
import MainApiService from "../../../../services/mainApi.service";

import { IGeneric } from "../../../../models/generics";
import CompanyGroupsService from "../../../../services/companyGroups.service";
import { TCompanyGroupForm, companyGroupSchema } from "./CompanyGroupSchema";
import {
  AutoComplete,
  FormContainer,
  FormInput,
  SwitchInput,
  useFetch,
} from "@4uhub/lib4uhub";

const companyGroupService = new CompanyGroupsService();

const FILTER_RESTRICTION_TYPE_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const filterRestrictionTypeService = new MainApiService<IGeneric>(
  FILTER_RESTRICTION_TYPE_ROUTE
);

const translationPath = "page.register.companyGroups.";

const CompanyGroup = () => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(
    companyGroupService.create
  );
  const { sendRequest: update, loading: uLoading } = useFetch(
    companyGroupService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    companyGroupService.getItemById
  );

  const saveHandler = (v: TCompanyGroupForm) =>
    save({
      ...v,
      filterRestrictionTypeId: v.filterRestrictionType.id,
    });

  const updateHandler = (v: TCompanyGroupForm, id: string) =>
    update({
      item: {
        ...v,
        filterRestrictionTypeId: v.filterRestrictionType.id,
      },
      id: id,
    });

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TCompanyGroupForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={get}
      loading={loading}
      title={t(translationPath + "company_group")}
      schema={companyGroupSchema}
      subtitle={t(translationPath + "new_company_group")}
      subtitleWatchField={"name"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            <SwitchInput
              name="enable"
              label={t(translationPath + "enable") || "Enable"}
            />
            <SwitchInput
              name="isMain"
              label={t(translationPath + "is_main") || "Is Main"}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <FormInput
              size="small"
              name="code"
              type="number"
              fullWidth
              label={t(translationPath + "code") || "Code"}
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <FormInput
              size="small"
              name="name"
              fullWidth
              label={t(translationPath + "name") || "Name"}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <AutoComplete
              params={{ Identifier: "filterRestrictionType" }}
              getOptionLabel={(option) => {
                return option.value || option.name;
              }}
              size="small"
              label={t(translationPath + "filter_restriction_type")}
              name={"filterRestrictionType"}
              request={filterRestrictionTypeService.list}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default CompanyGroup;
