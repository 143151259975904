import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { FormContainer, Treturn, useFetch } from "@4uhub/lib4uhub";
import { useCallback } from "react";
import { ITimeRuleProps } from "../../TimeRules/TimeRuleForm/models";
import { SelectAutomationMessage } from "./SelectAutomationMessage";
import { FormMessage } from "../../../../../../../components/FormMessage/FormMessage";
import {
  createTicketAutomationRuleMessage,
  getTicketAutomationRuleMessageById,
  updateTicketAutomationRuleMessageById,
} from "../../../../../../../services/ticketAutomationRuleMessage.service";
import {
  TAutomationRuleMessageForm,
  automationRuleMessageSchema,
} from "./automationMessageSchema";

const translationPath = "components.automationMessages.";

const AutomationMessage = ({
  id,
  ticketAutomationRuleId,
  onBackButton,
}: ITimeRuleProps) => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(
    createTicketAutomationRuleMessage
  );

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateTicketAutomationRuleMessageById
  );

  const { sendRequest: get, loading: gLoading } = useFetch(
    getTicketAutomationRuleMessageById
  );

  const updateHandler = (v: TAutomationRuleMessageForm, id: string) =>
    update({
      item: {
        id: id,
        ...v,
        ticketAutomationMessageId: v.ticketAutomationMessage.id,
        ticketAutomationRuleId: ticketAutomationRuleId ?? "",
      },
      id: id,
    });

  const saveHandler = (v: TAutomationRuleMessageForm) =>
    save({
      ...v,
      ticketAutomationMessageId: v.ticketAutomationMessage.id,
      ticketAutomationRuleId: ticketAutomationRuleId ?? "",
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TAutomationRuleMessageForm>> => {
      const { data, success } = await get(id);

      if (data) {
        const newData: TAutomationRuleMessageForm = {
          ...data,
          ticketAutomationMessage: {
            id: data.ticketAutomationMessage.id,
            name: data.ticketAutomationMessage.ticketAutomationMessageType.name,
          },
          message: data.message,
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TAutomationRuleMessageForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "automation_message")}
      schema={automationRuleMessageSchema}
      subtitle={t(translationPath + "new_automation_message")}
      subtitleWatchField={"ticketAutomationMessage.name"}
      backButtonOnClick={onBackButton}
      redirectOnSave={false}
      onSuccess={onBackButton}
      isModalForm={true}
      propId={id}
      formActionsOnBottom={false}
      fields={() => (
        <Grid container spacing={2}>
          <SelectAutomationMessage />

          <Grid item xs={12} sm={12}>
            <FormMessage
              name="message"
              label={`${t(translationPath + "message")}`}
              showVariables={false}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default AutomationMessage;
