import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import {
  IBeneficiaryCancellationReason,
  IBeneficiaryCancellationReasonPayload,
} from "../models/beneficiary-cancellation-reason";

const serviceURL =
  process.env.REACT_APP_MAIN +
  "/api/v1/BeneficiaryContractReasonOfCancellation";

export const createBeneficiaryCancellationReason = (
  payload: IBeneficiaryCancellationReasonPayload
) => {
  return GlobalAxios.post(serviceURL, payload);
};

export const updateBeneficiaryCancellationReason = ({
  id,
  item,
}: {
  id: string;
  item: IBeneficiaryCancellationReasonPayload;
}) => {
  return GlobalAxios.put(`${serviceURL}/${id}`, item);
};

export const findBeneficiaryCancellationReasonById = (id: string) => {
  return GlobalAxios.get<IBeneficiaryCancellationReason>(`${serviceURL}/${id}`);
};

export const listBeneficiaryCancellationReason = () => {
  return GlobalAxios.get<ISelectType[]>(`${serviceURL}/list}`);
};

export const removeBeneficiaryCancellationReason = (id: string) => {
  return GlobalAxios.delete(`${serviceURL}/${id}`);
};
