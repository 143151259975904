import { GlobalAxios } from "../axios/axios-config";
import { IDocumentVersion, IHistoric } from "../models/documents";

interface docAcceptanceDocument {
  languageId: string;
  name: string;
  content: string;
}

export interface IDocuments {
  id?: string;
  docTypeId?: string;
  version: string;
  versionDate: string;
  docAcceptanceParentId: string | null;
  enable: boolean;
  docAcceptanceDocuments: docAcceptanceDocument[];
}
const BASE_URL = process.env.REACT_APP_MAIN;

const DOCS_ROUTE = BASE_URL + "/api/v1/DocAcceptance/";

export class DocumentsApiService {
  create = (item: IDocuments) => {
    return GlobalAxios.post(DOCS_ROUTE, item);
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<IDocumentVersion>(DOCS_ROUTE + id);
  };

  updateItemById = ({ item, id }: { item: IDocuments; id: string }) => {
    return GlobalAxios.put(DOCS_ROUTE + id, item);
  };

  list = (id: string) => {
    return GlobalAxios.get<IHistoric>(DOCS_ROUTE + id + "/list");
  };
}

export default DocumentsApiService;
