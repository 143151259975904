import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  FormContainer,
  FormSelect,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { TimeInput } from "../../../../../../../../components/TimeInput";
import {
  createTicketServiceAreaDay,
  getTicketServiceAreaDayById,
  updateTicketServiceAreaDayById,
} from "../../../../../../../../services/ticketServiceAreaDay.service";
import {
  TTicketServiceAreaDayForm,
  ticketServiceAreaDaySchema,
} from "./ticketServiceAreaDaySchema";

const translationPath = "components.ticketServiceAreaDays.";
const daysOfWeekTranslation = "components.daysOfWeek.";

const TicketServiceAreaDay = ({
  id,
  onSuccess,
  onCancel,
}: {
  id: string | null;
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const [daysOfWeek] = useState([
    {
      value: "Sunday",
      label: `${t(daysOfWeekTranslation + "sunday")}`,
    },
    { value: "Monday", label: `${t(daysOfWeekTranslation + "monday")}` },
    { value: "Tuesday", label: `${t(daysOfWeekTranslation + "tuesday")}` },
    { value: "Wednesday", label: `${t(daysOfWeekTranslation + "wednesday")}` },
    { value: "Thursday", label: `${t(daysOfWeekTranslation + "thursday")}` },
    { value: "Friday", label: `${t(daysOfWeekTranslation + "friday")}` },
    { value: "Saturday", label: `${t(daysOfWeekTranslation + "saturday")}` },
  ]);

  const { id: ticketServiceAreaId } = useParams();

  const { sendRequest: save, loading: sLoading } = useFetch(
    createTicketServiceAreaDay
  );
  const { sendRequest: update, loading: uLoading } = useFetch(
    updateTicketServiceAreaDayById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    getTicketServiceAreaDayById
  );

  const handleSave = (v: TTicketServiceAreaDayForm) => {
    return save({
      ...v,
      ticketServiceAreaId: ticketServiceAreaId ?? "",
      startTime: v.startTime.toTimeString().substring(0, 8),
      endTime: v.endTime.toTimeString().substring(0, 8),
      dayOfWeek: v.dayOfWeek,
    });
  };

  const updateHandler = (v: TTicketServiceAreaDayForm, id: string) =>
    update({
      item: {
        ...v,
        ticketServiceAreaId: ticketServiceAreaId ?? "",
        startTime: v.startTime.toTimeString().substring(0, 8),
        endTime: v.endTime.toTimeString().substring(0, 8),
        dayOfWeek: v.dayOfWeek,
      },
      id: id,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TTicketServiceAreaDayForm>> => {
      const { data, success } = await get(id);
      if (data) {
        const startTime = new Date();
        const getStartTime = data.startTimeTimeOnly.split(":");
        startTime.setHours(parseInt(getStartTime[0]));
        startTime.setMinutes(parseInt(getStartTime[1]));

        const endTime = new Date();
        const getEndTime = data.endTimeTimeOnly.split(":");
        endTime.setHours(parseInt(getEndTime[0]));
        endTime.setMinutes(parseInt(getEndTime[1]));

        return {
          data: {
            ...data,
            startTime: startTime,
            endTime: endTime,
          },
          success: success,
        };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TTicketServiceAreaDayForm>
      saveFunction={handleSave}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "ticket_service_area_day")}
      schema={ticketServiceAreaDaySchema}
      subtitle={
        id
          ? t(translationPath + "edit_ticket_service_area_day")
          : t(translationPath + "new_ticket_service_area_day")
      }
      isModalForm
      redirectOnSave={false}
      onSuccess={onSuccess}
      customHeight={"fit-content"}
      marginTop={0}
      marginBottom={0}
      backButtonOnClick={onCancel}
      propId={id || undefined}
      formActionsOnBottom={false}
      showBackButton={false}
      fields={(type) => (
        <Grid container spacing={2} pb={5}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            <SwitchInput name="enable" label={t(translationPath + "enable")} />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormSelect
              label={t(translationPath + "day_of_week")}
              name="dayOfWeek"
              options={daysOfWeek}
              fullWidth
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TimeInput
              name="startTime"
              label={t(translationPath + "initial_time") || "Initial Time"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TimeInput
              name="endTime"
              label={t(translationPath + "end_time") || "End Time"}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default TicketServiceAreaDay;
