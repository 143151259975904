import { GlobalAxios } from "./../axios/axios-config";
import { IGetServiceDayById, INewServiceDay } from "../models/service-day";
import { ISelectType } from "@4uhub/lib4uhub";

const SERVICE_DAY_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketServiceDays/";

export class ServiceDayService {
  create = (item: INewServiceDay) => {
    return GlobalAxios.post(SERVICE_DAY_ROUTE, item);
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<IGetServiceDayById>(SERVICE_DAY_ROUTE + id);
  };

  updateItemById = ({ item, id }: { item: INewServiceDay; id: string }) => {
    return GlobalAxios.put(SERVICE_DAY_ROUTE + id, item);
  };

  list = (params?: any) => {
    return GlobalAxios.get<ISelectType[]>(SERVICE_DAY_ROUTE + "list", {
      params: params,
    });
  };
}

export default ServiceDayService;
