import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  AutoComplete,
  FormContainer,
  FormInput,
  ISelectType,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import { useCallback } from "react";

import MainApi from "../../../../../../services/mainApi.service";
import { useParams } from "react-router-dom";
import {
  createRestrictionRule,
  getRestrictionRuleById,
  updateRestrictionRuleById,
} from "../../../../../../services/restrictionRule.service";
import {
  TRestrictionRuleForm,
  restrictionRuleSchema,
} from "./RestrictionRuleSchema";

const translationPath = "page.4uBus.register.restrictionRules.";

const GENERIC_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const genericService = new MainApi<ISelectType>(GENERIC_ROUTE);

const RESTRICTION_RULE_TYPE_ROUTE =
  process.env.REACT_APP_4UBUS + "/api/v1/RestrictionRuleType/";
const restrictionRuleTypeService = new MainApi<ISelectType>(
  RESTRICTION_RULE_TYPE_ROUTE
);

const RestrictionRule = () => {
  const { t } = useTranslation();

  const { extensionCode, profileTasyId } = useParams();

  const { sendRequest: save, loading: sLoading } = useFetch(
    createRestrictionRule
  );

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateRestrictionRuleById
  );

  const { sendRequest: get, loading: gLoading } = useFetch(
    getRestrictionRuleById
  );

  const saveHandler = (v: TRestrictionRuleForm) =>
    save({
      ...v,
      actionTypeId: v.actionType.id,
      restrictionRuleProfileId: profileTasyId ?? "",
      restrictionRuleTypeId: v.restrictionRuleType.id,
    });

  const updateHandler = (v: TRestrictionRuleForm, id: string) =>
    update({
      item: {
        ...v,
        actionTypeId: v.actionType.id,
        restrictionRuleProfileId: profileTasyId ?? "",
        restrictionRuleTypeId: v.restrictionRuleType.id,
      },
      id: id,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TRestrictionRuleForm>> => {
      const { data, success } = await get(id);

      if (data) {
        const newData: TRestrictionRuleForm = {
          ...data,
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TRestrictionRuleForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "restriction_rule")}
      schema={restrictionRuleSchema}
      subtitle={t(translationPath + "new_restriction_rule")}
      subtitleWatchField={"value"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            <SwitchInput name="enable" label={t(translationPath + "enable")} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <AutoComplete
              params={{ ExtensionCode: extensionCode }}
              name="restrictionRuleType"
              label={
                t(translationPath + "restriction_rule_type") ||
                "Restriction Rule Type"
              }
              getOptionLabel={(option) => option.name}
              size="small"
              fullWidth
              autoFocus
              request={restrictionRuleTypeService.list}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <AutoComplete
              params={{ Identifier: "RestrictionRulesActionType" }}
              name="actionType"
              label={t(translationPath + "action_type") || "Action Type"}
              getOptionLabel={(option) => option.name}
              size="small"
              fullWidth
              request={genericService.list}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="value"
              fullWidth
              label={t(translationPath + "value") || "Value"}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="description"
              fullWidth
              label={t(translationPath + "description") || "Description"}
              multiline
              minRows={4}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default RestrictionRule;
