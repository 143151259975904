import { z } from "zod";

const translationPath = "page.register.extensions.errors.";

const genericSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
});

const softwareSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional(),
  },
  { invalid_type_error: translationPath + "software" }
);

const mediaFileSchema = z.object(
  {
    mediaType: genericSchema.optional(),
    id: z.string().nonempty(translationPath + "file"),
    mediaTypeId: z.string().optional(),
    isPublic: z.boolean().optional(),
    fileName: z.string().optional(),
    cdnDomain: z.string().optional(),
    filePath: z.string().optional(),
    mimeType: z.string().optional(),
    fileSize: z.number().optional(),
  },
  { required_error: translationPath + "file" }
);

const extensionMediaFilesSchema = z
  .object({
    mediaType: genericSchema,
    file: mediaFileSchema,
  })
  .superRefine((val, ctx) => {
    if (val.file.id.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["file"],
        message: translationPath + "file",
      });
    }
  });

const extensionDependenciesSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional(),
  },
  { invalid_type_error: translationPath + "extension_dependencies" }
);

const extensionTargetSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional(),
});

export const extensionSchema = z.object({
  id: z.string().optional(),
  displayOrder: z.string().min(1, translationPath + "display_order"),
  enable: z.boolean(),
  software: softwareSchema,
  name: z.string().min(1, translationPath + "name"),
  title: z.string().min(1, translationPath + "title"),
  description: z.string().optional(),
  extensionTags: z.array(z.string()).nonempty(translationPath + "tags"),
  extensionMediaFiles: z.array(extensionMediaFilesSchema),
  extensionDependencies: z
    .array(extensionDependenciesSchema)
    .optional()
    .nullable(),
  extensionTargets: z
    .array(extensionTargetSchema)
    .min(1, translationPath + "extension_targets"),
});

export type TExtensionForm = z.infer<typeof extensionSchema>;
