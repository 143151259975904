import { useTranslation } from "react-i18next";

import { AutoComplete } from "@4uhub/lib4uhub";

import { buildTPath } from "../../advantages-and-benefits-club.utils";
import MainApi from "../../../../../../services/mainApi.service";

const route = process.env.REACT_APP_MAIN + "/api/v1/generic/";

const genericService = new MainApi(route);

const SituationSelect = () => {
  const { t } = useTranslation();

  const translate = (field: string) => t(buildTPath(field));

  return (
    <AutoComplete
      name="situation"
      size="small"
      label={translate("situation")}
      getOptionLabel={({ name }) => name}
      request={genericService.list}
      fullWidth
      params={{
        Identifier: "SalesContractsSituation",
      }}
    />
  );
};

export default SituationSelect;
