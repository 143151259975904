import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { IExtension, IExtensionInstall } from "../../models/extensions-store";
import { useTranslation } from "react-i18next";
import MainApiService from "../../services/mainApi.service";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  AutoCompleteMulti,
  Form,
  useFetch,
  useNotificationContext,
} from "@4uhub/lib4uhub";

const translationPath = "components.extensionsCard.";

const EXTENSION_INSTALL_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/SiteAppExtension/InstallExtension";
const extensionInstallService = new MainApiService<IExtensionInstall>(
  EXTENSION_INSTALL_ROUTE
);

const siteAppSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional(),
    description: z.string().optional(),
  },
  { invalid_type_error: translationPath + "errors.site_app" }
);

const extensionsCardSchema = z.object({
  siteApps: z.array(siteAppSchema).min(1, translationPath + "errors.site_app"),
});

type TExtensionsCardForm = z.infer<typeof extensionsCardSchema>;

const ExtensionsCardModal = ({
  extension,
  onClose,
  onRealoadExtensionsList,
}: {
  extension: IExtension;
  onClose: () => void;
  onRealoadExtensionsList: () => void;
}) => {
  const { t } = useTranslation();
  const { sendRequest, loading } = useFetch(extensionInstallService.create);
  const { setMessage } = useNotificationContext();

  const methods = useForm<TExtensionsCardForm>({
    resolver: zodResolver(extensionsCardSchema),
  });

  const { handleSubmit } = methods;

  const onInstallExtension = useCallback(
    async (values: TExtensionsCardForm) => {
      const { data, success } = await sendRequest({
        siteAppIds: values.siteApps.map((siteApp) => siteApp.id),
        extensionId: extension.id,
      });
      if (data && success) {
        onRealoadExtensionsList();
        onClose();
        setMessage({
          message: `${t("page.extensionsStore.installed_successfully")}`,
          type: "success",
          notificationKey: new Date().getMilliseconds(),
        });
      }
    },
    [setMessage, sendRequest, extension.id, onClose, t, onRealoadExtensionsList]
  );

  return (
    <Box minWidth={300}>
      <Form {...methods}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(onInstallExtension)}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Typography variant="h6" textAlign={"center"} lineHeight={1.2}>
            {extension.title}
          </Typography>
          <Stack mt={4} gap={2}>
            <AutoCompleteMulti
              name="siteApps"
              options={extension.availableFor.map((av) => av.siteApp)}
              getOptionLabel={(option) => {
                return option.name;
              }}
              size="small"
              label={t(translationPath + "site_app")}
              autoFocus
            />
            <Button
              fullWidth
              variant="contained"
              type="submit"
              disabled={loading}
            >
              {loading && (
                <Stack direction={"row"} spacing={2}>
                  <CircularProgress size={18} />
                  <Typography align="center" pt={0.5} variant="body2">
                    {t(translationPath + "installing")}
                  </Typography>
                </Stack>
              )}
              {!loading && `${t(translationPath + "install")}`}
            </Button>
          </Stack>
        </Box>
      </Form>
    </Box>
  );
};

export default ExtensionsCardModal;
