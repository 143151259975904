import { Button, Grid, Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import { usePanel } from "../../../store/contexts/DashBoardPannelContext";
import Filter from "./Filter/Filter";
import Help from "./Help";
import { useRole } from "@4uhub/lib4uhub";

interface IPanelHeaderProps {
  onClick: () => void;
}

const PanelHeader: React.FC<IPanelHeaderProps> = ({ onClick }) => {
  const { panel, isView } = usePanel();
  const { t } = useTranslation();

  const addPermission = useRole(["Create"]);

  return (
    <Grid container justifyContent={"space-between"} direction={"row"}>
      <Typography sx={(t) => t.typography.h5}>{panel.name}</Typography>
      <Grid item>
        <Grid container direction={"row"} gap={3}>
          <Help />
          <Filter />
          {!isView && addPermission && (
            <Button onClick={onClick} variant="contained" size="small">
              {t("components.panel.add_dashboard")}
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(PanelHeader);
