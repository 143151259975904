import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Table, useFetch } from "@4uhub/lib4uhub";
import { Typography } from "@mui/material";
import SituationChip from "./SituationChip";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";

import { useCallback, useMemo, useState } from "react";
import { IContractProduct } from "../../../../models/contract-product";
import MainApi from "../../../../services/mainApi.service";
import {
  approveSalesProduct,
  cancelSalesProduct,
  inactivateSalesProduct,
  reactivateSalesProduct,
  suspendSalesProduct,
} from "../../../../services/salesProductCtr.service";

const serviceURL = process.env.REACT_APP_MAIN + "/api/v1/SalesProductCtr/";
const service = new MainApi<IContractProduct>(serviceURL);

const ContractProductsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(0);

  const translationsPath =
    "page.4uSalesContract.registers.contractProducts.products.";

  const { sendRequest: approve } = useFetch(approveSalesProduct);
  const { sendRequest: inactivate } = useFetch(inactivateSalesProduct);
  const { sendRequest: reactivate } = useFetch(reactivateSalesProduct);
  const { sendRequest: suspend } = useFetch(suspendSalesProduct);
  const { sendRequest: cancel } = useFetch(cancelSalesProduct);

  const columns: GridColDef<IContractProduct>[] = [
    {
      field: "code",
      headerName: t(translationsPath + "code") || "Code",
      renderCell: ({ value }) => (
        <Typography variant="body2">{value}</Typography>
      ),
    },
    {
      field: "name",
      headerName: t(translationsPath + "name") || "Name",
      renderCell: ({ value }) => (
        <Typography variant="body2">{value}</Typography>
      ),
    },
    {
      field: "situation",
      headerName: t(translationsPath + "situation") || "Situation",
      renderCell: ({ value }) => (
        <SituationChip code={value?.code} name={value.name} />
      ),
    },
  ];

  const handleRowClick = ({ id }: GridRowParams<IContractProduct>) => {
    navigate(`${id.toString()}/edit`);
  };

  const onApproveSalesProduct = useCallback(
    async (id: string) => {
      const { success } = await approve(id);
      if (success) {
        setRefresh((prevState) => (prevState += 1));
      }
    },
    [approve, setRefresh]
  );
  const onInactivateSalesProduct = useCallback(
    async (id: string) => {
      const { success } = await inactivate(id);
      if (success) {
        setRefresh((prevState) => (prevState += 1));
      }
    },
    [inactivate, setRefresh]
  );

  const onReactivationSalesProduct = useCallback(
    async (id: string) => {
      const { success } = await reactivate(id);
      if (success) {
        setRefresh((prevState) => (prevState += 1));
      }
    },
    [reactivate, setRefresh]
  );

  const onSuspensionSalesProduct = useCallback(
    async (id: string) => {
      const { success } = await suspend(id);
      if (success) {
        setRefresh((prevState) => (prevState += 1));
      }
    },
    [suspend, setRefresh]
  );

  const onCancelSalesProduct = useCallback(
    async (id: string) => {
      const { success } = await cancel(id);
      if (success) {
        setRefresh((prevState) => (prevState += 1));
      }
    },
    [cancel, setRefresh]
  );

  const handleAdd = () => {
    navigate("new");
  };

  const props = useMemo(() => {
    return { refresh };
  }, [refresh]);

  return (
    <Table<IContractProduct>
      searchInput
      showDefaultMacroFilters={false}
      columns={columns}
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      service={service}
      title={t(translationsPath + "title")}
      defaultPageSize={20}
      serviceProps={props}
      pageSizeOptions={[5, 10, 20]}
      addButtonLabel={t(translationsPath + "add") || "Add Product"}
      searchInputPlaceHolder={t(translationsPath + "search")}
      confirmOptions={{
        title: t(translationsPath + "confirm.title"),
        description: t(translationsPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
      rowActions={(params) => [
        params.row.situation.code === "1" && params.row.approvalDate === null
          ? {
              action: (params) => {
                onApproveSalesProduct(params.row.id);
              },
              icon: <CheckCircleIcon fontSize="small" />,
              text: `${t(translationsPath + "approve")}`,
            }
          : null,
        params.row.inactivationDate === null &&
        params.row.situation.code === "1"
          ? {
              action: (params) => {
                onInactivateSalesProduct(params.row.id);
              },
              icon: <DoDisturbOnIcon fontSize="small" />,
              text: `${t(translationsPath + "inactivation")}`,
            }
          : null,
        params.row.situation.code === "4" && params.row.reactivationDate == null
          ? {
              action: (params) => {
                onReactivationSalesProduct(params.row.id);
              },
              icon: <PlayCircleFilledIcon fontSize="small" />,
              text: `${t(translationsPath + "reactivation")}`,
            }
          : null,
        params.row.suspensionDate === null && params.row.situation.code === "1"
          ? {
              action: (params) => {
                onSuspensionSalesProduct(params.row.id);
              },
              icon: <PauseCircleFilledIcon fontSize="small" />,
              text: `${t(translationsPath + "suspension")}`,
            }
          : null,
        params.row.cancelationDate === null
          ? {
              action: (params) => {
                onCancelSalesProduct(params.row.id);
              },
              icon: <CancelIcon fontSize="small" />,
              text: `${t(translationsPath + "cancelation")}`,
            }
          : null,
      ]}
    />
  );
};

export default ContractProductsPage;
