import { useFormContext } from "@4uhub/lib4uhub";
import { memo } from "react";
import { Controller, useWatch } from "react-hook-form";
import { BodyInput } from "../BodyInput/BodyInput";
import { InputAdornment, TextFieldProps, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const translationPath = "chatBot.page.templates.whatsapp.";

type TBodyFieldControlledProps = {
  name?: string;
  label?: string;
  automationId?: string;
  showVariables?: boolean;
} & TextFieldProps;

export const BodyFieldControlled = memo(
  ({
    name,
    label,
    automationId,
    showVariables = true,
    ...otherProps
  }: TBodyFieldControlledProps) => {
    const { t } = useTranslation();

    const { control } = useFormContext();

    const textValue = useWatch({
      name: name ?? "body",
      control,
    });

    return (
      <Controller
        control={control}
        name={name ?? "body"}
        defaultValue={""}
        render={({ fieldState: { error }, field }) => {
          return (
            <BodyInput
              field={field}
              error={error}
              fullWidth
              inputProps={{ maxLength: 1024 }}
              automationId={automationId ?? ""}
              multiline
              minRows={4}
              label={label ? label : undefined}
              showVariables={showVariables}
              placeholder={
                label ? undefined : `${t(translationPath + "body_placeholder")}`
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ marginBottom: 2, alignSelf: "end" }}
                  >
                    <Typography
                      variant="body2"
                      sx={(t) => ({
                        color:
                          t.palette.grey[
                            t.palette.mode === "light" ? 400 : 600
                          ],
                      })}
                    >
                      {textValue ? textValue.length : 0}/{1024}
                    </Typography>
                  </InputAdornment>
                ),
              }}
              {...otherProps}
            />
          );
        }}
      />
    );
  }
);
