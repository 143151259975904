import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  DateInput,
  FormContainer,
  FormInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import {
  TContractProductHistoryForm,
  contractProductHistorySchema,
} from "./contractProductHistorySchema";
import { memo, useCallback } from "react";
import { IContracProductProps } from "./models";
import {
  createContracProductHistory,
  getContracProductHistoryById,
  updateContracProductHistoryById,
} from "../../../../../services/contractProductHistory.service";
import { useParams } from "react-router-dom";
import { useSalesContract } from "../../../../../store/contexts/SalesContractContext";

const translationPath =
  "page.4uSalesContract.registers.salesProductCtrHistories.";

export const ContractProductHistory = memo(({ id }: IContracProductProps) => {
  const { id: salesProductCtrId } = useParams();
  const { onClose, onRefresh } = useSalesContract();
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(
    createContracProductHistory
  );

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateContracProductHistoryById
  );

  const { sendRequest: get, loading: gLoading } = useFetch(
    getContracProductHistoryById
  );

  const updateHandler = (v: TContractProductHistoryForm, id: string) => {
    return update({
      item: {
        id: id,
        title: v.title,
        history: v.history,
        salesProductCtrId: salesProductCtrId ?? "",
        historyDate: v.historyDate.toISOString().substring(0, 10),
      },
      id: id,
    });
  };

  const saveHandler = (v: TContractProductHistoryForm) =>
    save({
      title: v.title,
      history: v.history,
      salesProductCtrId: salesProductCtrId ?? "",
      historyDate: v.historyDate.toISOString().substring(0, 10),
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TContractProductHistoryForm>> => {
      const { data, success } = await get(id);

      if (data) {
        const newData: TContractProductHistoryForm = {
          ...data,
          title: data.title,
          history: data.history,
          historyDate: new Date(`${data.historyDate}T00:00:00`),
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const successHandle = () => {
    onClose();
    onRefresh();
  };

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TContractProductHistoryForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "title")}
      schema={contractProductHistorySchema}
      subtitle={t(translationPath + "new_history")}
      subtitleWatchField={"title"}
      showBackButton={false}
      onSuccess={successHandle}
      redirectOnSave={false}
      backButtonOnClick={onClose}
      isModalForm={true}
      propId={id}
      marginBottom={0}
      customHeight={"fit-content"}
      formActionsOnBottom={false}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="title"
              fullWidth
              label={t(translationPath + "titleForm") || "Title"}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <DateInput
              name={"historyDate"}
              label={t(translationPath + "history_date") || "History Date"}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormInput
              name="history"
              label={t(translationPath + "history") || "history"}
              size="small"
              fullWidth
              multiline
              minRows={4}
            />
          </Grid>
        </Grid>
      )}
    />
  );
});
