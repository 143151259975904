import { z } from "zod";

const translationPath = "page.register.cardsTemplates.errors.";

const healthPlanSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "health_plan" }
);

const establishmentParamTypeSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "establishment_param_type" }
);

const establishmentParamTypeBackSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "establishment_param_type_back" }
);

export const cardTemplateSchema = z.object({
  id: z.string().optional(),
  initialDate: z.date({
    invalid_type_error: translationPath + "initial_date",
  }),
  endDate: z.date({
    invalid_type_error: translationPath + "end_date",
  }),
  enable: z.boolean(),
  healthPlan: healthPlanSchema,
  establishmentParamType: establishmentParamTypeSchema,
  establishmentParamTypeBack: establishmentParamTypeBackSchema,
});

export type TCardTemplateForm = z.infer<typeof cardTemplateSchema>;
