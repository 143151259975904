import { GlobalAxios } from "./../axios/axios-config";
import { ISearchParams, ISearchReturn } from "../models/requests";
import {
  IExtensionConfiguration,
  IExtensionGetConfiguration,
  IExtensionSettings,
} from "../models/extension";
import { ISelectType } from "@4uhub/lib4uhub";

const EXTENSION_SETTINGS_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/ExtensionConfig/";

export class ExtensionSettingsService {
  create = (item: IExtensionConfiguration) => {
    return GlobalAxios.post(EXTENSION_SETTINGS_ROUTE, item);
  };

  getAll = (settingsParams: ISearchParams) => {
    return GlobalAxios.get<ISearchReturn<IExtensionSettings>>(
      EXTENSION_SETTINGS_ROUTE,
      {
        params: {
          ...settingsParams,
        },
      }
    );
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<IExtensionGetConfiguration>(
      EXTENSION_SETTINGS_ROUTE + id
    );
  };

  updateItemById = ({
    item,
    id,
  }: {
    item: IExtensionConfiguration;
    id: string;
  }) => {
    return GlobalAxios.put(EXTENSION_SETTINGS_ROUTE + id, item);
  };

  deleteItemById = (id: string) => {
    return GlobalAxios.delete(EXTENSION_SETTINGS_ROUTE + id);
  };

  list = (params?: any) => {
    return GlobalAxios.get<ISelectType[]>(EXTENSION_SETTINGS_ROUTE + "list", {
      params: params,
    });
  };
}

export default ExtensionSettingsService;
