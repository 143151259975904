import { GlobalAxios } from "../axios/axios-config";
import { IHealthProgram, INewHealthProgram } from "../models/health-programs";

const HEALTH_PROGRAM_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/HealthProgram/";

export const createHealthProgram = (item: INewHealthProgram) => {
  return GlobalAxios.post(HEALTH_PROGRAM_ROUTE, item);
};

export const getHealthProgramById = (id: string) => {
  return GlobalAxios.get<IHealthProgram>(HEALTH_PROGRAM_ROUTE + id);
};

export const updateHealthProgramById = ({
  item,
  id,
}: {
  item: INewHealthProgram;
  id: string;
}) => {
  return GlobalAxios.put(HEALTH_PROGRAM_ROUTE + id, item);
};
