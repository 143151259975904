import { Grid } from "@mui/material";
import { useFieldArray } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { memo, useCallback, useEffect, useState } from "react";

import { TLegalEntityForm } from "./LegalEntitySchema";
import ContactValue from "../../../../components/ContactValue";
import { IGeneric } from "../../../../models/generics";

import {
  AutoComplete,
  FormArray,
  ISelectType,
  MainApiService,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";

import { GlobalAxios } from "../../../../axios/axios-config";

const translation_path = "page.register.legalPerson.";

const GENERICS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";

const service = new MainApiService<IGeneric>(GENERICS_ROUTE, GlobalAxios);

const LegalEntityContacts: React.FC = () => {
  const { control, formState, setValue } = useFormContext<TLegalEntityForm>();
  const [contactTypes, setContactTypes] = useState<ISelectType[]>();
  const { t } = useTranslation();

  const { sendRequest } = useFetch(service.list);

  const onLoadContactTypes = useCallback(async () => {
    const { data } = await sendRequest({ identifier: "ContactType" });
    if (data) {
      setContactTypes(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    onLoadContactTypes();
  }, [onLoadContactTypes]);

  const contactsFieldArray = useFieldArray<TLegalEntityForm>({
    control,
    name: "contacts",
  });

  return (
    <Grid item xs={12}>
      <FormArray<TLegalEntityForm>
        title="Contatos"
        name="contacts"
        errorMessage={formState.errors?.contacts?.message}
        addButtonLabel={t(translation_path + "addContact") || "Adicionar"}
        formArray={contactsFieldArray}
        appendValue={{
          contactType: contactTypes
            ? contactTypes[0]
            : {
                code: "",
                id: "",
                name: "",
              },
          otherType: "",
          id: "c13c5474-1821-4dd2-a0e3-ed34f7a025b5",
          contactTypeId: "",
          value: "",
        }}
        fieldsObject={(index) => {
          const cField = `contacts.${index}.contactType`;

          return (
            <>
              <Grid item xs={12} sm={4}>
                <AutoComplete
                  getOptionLabel={(option) => {
                    return option.name;
                  }}
                  size="small"
                  label={t(translation_path + "contactType")}
                  name={cField}
                  onValueChange={(v) =>
                    setValue(
                      `contacts.${index}.contactType.code`,
                      v?.code as any
                    )
                  }
                  options={contactTypes}
                />
              </Grid>
              <ContactValue index={index} />
            </>
          );
        }}
      />
    </Grid>
  );
};

export default memo(LegalEntityContacts);
