import { z } from "zod";
import { VERSION_VALIDATOR } from "../../../../../utils/Validators/regexValidators";

const translationPath = "page.register.documents.errors.";

const language = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional(),
});

const docAcceptanceDocument = z
  .object({
    language: language.nullable(),
    name: z.string().nonempty(translationPath + "name"),
    content: z.string().nonempty(translationPath + "content"),
  })
  .superRefine((val, ctx) => {
    if (!val.language) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["language"],
        message: `${translationPath + "language"}`,
      });
    }
  });

export const DocumentsSchema = z.object({
  version: z
    .string()
    .nonempty(translationPath + "version")
    .regex(VERSION_VALIDATOR, translationPath + "version_regex"),
  versionDate: z.date(),
  docAcceptanceParentId: z.string().nullable().optional(),
  enable: z.boolean(),
  docAcceptanceDocuments: z.array(docAcceptanceDocument),
});

export type TDocumentsForm = z.infer<typeof DocumentsSchema>;
