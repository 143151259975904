import { z } from "zod";
import { applyTranslations } from "./typebot.utils";

export const ticketServiceTypebotSchema = z.object({
  typebotViewerHost: z.string().min(1, applyTranslations('errors.viewer_host')),
}, {
  invalid_type_error: applyTranslations('errors.viewer_host')
});

export type TTicketServiceTypebotForm = z.infer<typeof ticketServiceTypebotSchema>;
