import { getEstablishmentToken } from "@4uhub/lib4uhub";
import { GlobalAxios, removeUserData } from "../axios/axios-config";
import {
  IIntegratedEstablishments,
  INewEstablishment,
} from "../models/establishments";
import { TEstablishmentForm } from "../pages/admSystem/establishments/EstablishmentForm/EstablishmentSchema";
import { tokenService } from "./token.service";

const ESTABLISHMENTS_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/Establishment/";

const BUS_ESTABLISHMENT_ROUTE =
  process.env.REACT_APP_4UBUS + "/api/v1/Establishment";

export class EstablishmentService {
  create = (item: INewEstablishment) => {
    return GlobalAxios.post(ESTABLISHMENTS_ROUTE, item);
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<TEstablishmentForm>(ESTABLISHMENTS_ROUTE + id);
  };

  updateItemById = ({ item, id }: { item: any; id: string }) => {
    return GlobalAxios.put(ESTABLISHMENTS_ROUTE + id, item);
  };
}
export const getEIFromUrl = (): number | undefined => {
  const splitedUrl = window.location.href.split("/");
  const eIndex = splitedUrl.findIndex((u) => u === "e");

  if (eIndex === -1) {
    return;
  }
  return Number(splitedUrl[eIndex + 1]);
};

export const getEstToken = () => {
  const establishmentcode = getEIFromUrl();

  let token = tokenService
    .getAccessTokens()
    .find((t) => t.code === Number(establishmentcode || 0));

  return getEstablishmentToken(removeUserData, establishmentcode, token);
};

export const syncEstablishmentNow = () => {
  return GlobalAxios.post(BUS_ESTABLISHMENT_ROUTE + "/Sync/Now");
};

export const getIntegratedEstablishments = () => {
  return GlobalAxios.get<IIntegratedEstablishments>(BUS_ESTABLISHMENT_ROUTE);
};

export default EstablishmentService;
