import { Stack, useMediaQuery, useTheme } from "@mui/material";
import MenuSecondaryItem from "../MenuSecondaryItem/MenuSecondaryItem";
import ChatBubbleIcon from "@mui/icons-material/ChatBubbleOutline";
import ExtensionIcon from "@mui/icons-material/Extension";
import { ISecondaryMenuItem } from "../../../models/menu";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { DomainProvider } from "@4uhub/lib4uhub";
import { useParams } from "react-router-dom";

const MenuSecondaryItemList = ({ onClick }: { onClick?: () => void }) => {
  const { t } = useTranslation();
  const { eI } = useParams();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const items: ISecondaryMenuItem[] = useMemo(() => {
    const initialItems = [
      {
        name: t("components.menu.secondary_menu.extensions_store"),
        link: `/e/${eI}/dashboard/extensions-store`,
        id: "extensions_store",
        icon: <ExtensionIcon />,
        domain: "siteappextension",
      },
      {
        name: t("components.menu.secondary_menu.suport"),
        link: "",
        id: "suport",
        icon: <ChatBubbleIcon />,
      },
    ];

    return initialItems;
  }, [t, eI]);

  return (
    <Stack
      direction={matches ? "row" : "column"}
      alignItems={"flex-start"}
      justifyContent={matches ? "space-between" : undefined}
      spacing={2}
      width={matches ? "100%" : undefined}
      pb={matches ? 2 : undefined}
      onClick={onClick}
    >
      {items.map((item) =>
        item.domain ? (
          <DomainProvider
            value={{ domainName: item.domain }}
            key={item.id}
            redirect={false}
          >
            <MenuSecondaryItem {...item} />
          </DomainProvider>
        ) : (
          <MenuSecondaryItem {...item} key={item.id} />
        )
      )}
    </Stack>
  );
};

export default MenuSecondaryItemList;
