import { GlobalAxios } from "../axios/axios-config";
import { IIpmTasyLog } from "../models/ipm-tasy-logs";
import { ITasyIpmLog } from "../models/tasy-ipm-logs";

const BASE_URL =
  process.env.REACT_APP_4UBUS + "/api/v1/TasyIPMPatientEncounterLog/";

export const listIpmTasyFull = (id: number) => {
  return GlobalAxios.get<IIpmTasyLog>(BASE_URL + "ListIPMTasyFull", {
    params: {
      id,
    },
  });
};

export const listTasyIpmFull = (id: number) => {
  return GlobalAxios.get<ITasyIpmLog>(BASE_URL + "ListTasyIPMFull", {
    params: {
      id,
    },
  });
};
