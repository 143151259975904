import { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Chip, ChipOwnProps } from "@mui/material";

const translationsPath =
  "page.4uSalesContract.registers.contractProducts.products.";

type TCode = "1" | "2" | "3" | "4" | "5";

const SituationChip = ({
  code,
  name,
}: {
  code: TCode;
  name: string;
}): ReactElement => {
  const { t } = useTranslation();

  const color = useMemo((): ChipOwnProps["color"] => {
    switch (code) {
      case "1":
        return "success";
      case "2":
        return "error";
      case "4":
        return "warning";
      case "5":
        return "primary";
      default:
        return;
    }
  }, [code]);

  const label = () => {
    switch (code) {
      case "1":
        return t(translationsPath + "active");
      case "2":
        return t(translationsPath + "canceled");
      case "3":
        return t(translationsPath + "suspended");
      case "4":
        return t(translationsPath + "inactive");
      case "5":
        return t(translationsPath + "approved");
      default:
        return name;
    }
  };

  return <Chip size="small" color={color} label={label()} />;
};

export default SituationChip;
