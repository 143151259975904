import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  FormContainer,
  FormInput,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import { useParams } from "react-router-dom";
import { useCallback } from "react";

import QuestionAnswerService from "../../../../../../services/questionAnswer.service";
import FormTextEditor from "../../../../../../components/UI/TextEditor/FormTextEditor";
import {
  TQuestionAnswerForm,
  questionAnswerSchema,
} from "./QuestionAnswerSchema";

const questionAnswerService = new QuestionAnswerService();

const translationPath = "page.register.questionsAnswers.";

const Faq = () => {
  const { t } = useTranslation();
  const { faqId } = useParams();

  const { sendRequest: save, loading: sLoading } = useFetch(
    questionAnswerService.create
  );
  const { sendRequest: update, loading: uLoading } = useFetch(
    questionAnswerService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    questionAnswerService.getItemById
  );

  const saveHandler = (v: TQuestionAnswerForm) =>
    save({
      ...v,
      faqId: faqId || "",
      displayOrder: +v.displayOrder,
    });

  const updateHandler = (v: TQuestionAnswerForm, id: string) =>
    update({
      item: {
        ...v,
        faqId: faqId || "",
        displayOrder: +v.displayOrder,
      },
      id: id,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TQuestionAnswerForm>> => {
      const { data, success } = await get(id);

      if (data) {
        const newData: TQuestionAnswerForm = {
          ...data,
          displayOrder: data.displayOrder.toString(),
        };
        return { data: newData, success };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TQuestionAnswerForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "question_answer")}
      schema={questionAnswerSchema}
      subtitle={t(translationPath + "new_question_answer")}
      subtitleWatchField={"question"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            <SwitchInput name="enable" label={t(translationPath + "enable")} />
          </Grid>

          <Grid item xs={12} sm={2}>
            <FormInput
              size="small"
              name="displayOrder"
              fullWidth
              label={t(translationPath + "display_order") || "Display order"}
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <FormInput
              size="small"
              name="question"
              fullWidth
              label={t(translationPath + "question") || "Question"}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormTextEditor
              name="answer"
              menuBarProps={{
                fontSize: true,
                markFormatting: true,
                selectColor: false,
                textFormatting: true,
              }}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default Faq;
