import axios, { isAxiosError } from "axios";
import { useCallback, useState } from "react";
import { getDomain } from "../services/domain.service";
import i18n from "../i18n";
import { useNotificationContext } from "@4uhub/lib4uhub";
import { getEstToken } from "../services/establishment.service";

const useDownload = () => {
  const token = getEstToken();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const { setMessage } = useNotificationContext();

  const downloadFile = useCallback(
    (url: string, id: string, fileName: string) => {
      setLoading(true);
      axios
        .get(url + id, {
          responseType: "blob", // Define o tipo de resposta como um blob (objeto binário)
          headers: {
            Authorization: "Bearer " + token?.token,
            Domain: getDomain(),
            "Accept-Language": i18n.language,
          },
        })
        .then((response) => {
          // Lida com a resposta da API
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);

          // Cria um link temporário e simula o clique para iniciar o download
          const link = document.createElement("a");
          link.href = fileURL;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {
          if (isAxiosError(err)) {
            if (err.response?.data.errors) {
              let errorMessage = "";

              Object.keys(err.response?.data.errors).forEach((key) => {
                errorMessage +=
                  err.response?.data.errors[key].join(" | ") + " ";
              });

              setMessage({
                message: errorMessage,
                type: "error",
              });
            }
            if (err.response?.data.detail) {
              setMessage({
                message: err.response?.data.detail || err.message,
                type: "error",
              });
            }
          } else {
            setMessage({
              message: err.message,
              type: "error",
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [setMessage, token]
  );

  const downloadFileParams = useCallback(
    (url: string, item: any, fileName: string) => {
      setLoading(true);
      setError(undefined);
      axios
        .get(url, {
          params: item,
          responseType: "blob", // Define o tipo de resposta como um blob (objeto binário)
          headers: {
            Authorization: "Bearer " + token?.token,
            "Accept-Language": i18n.language,
          },
        })
        .then((response) => {
          // Lida com a resposta da API
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);

          // Cria um link temporário e simula o clique para iniciar o download
          const link = document.createElement("a");
          link.href = fileURL;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {
          if (isAxiosError(err)) {
            if (err.response?.status === 404) {
              setError(err.response.data);
            }
            if (err.response?.data.errors) {
              let errorMessage = "";

              Object.keys(err.response?.data.errors).forEach((key) => {
                errorMessage +=
                  err.response?.data.errors[key].join(" | ") + " ";
              });

              setMessage({
                message: errorMessage,
                type: "error",
              });
            }
            if (err.response?.data.detail) {
              setMessage({
                message: err.response?.data.detail || err.message,
                type: "error",
              });
            }
          } else {
            setMessage({
              message: err.message,
              type: "error",
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [setMessage, token]
  );

  return { downloadFile, downloadFileParams, loading, error };
};

export default useDownload;
