import { GlobalAxios } from "./../axios/axios-config";
import { ISearchParams, ISearchReturn } from "../models/requests";
import axios from "axios";
import { IExtensionGet, IExtensionPut } from "../models/extension";
import { ISelectType } from "@4uhub/lib4uhub";

const BUS_EXTENSION_ROUTE =
  process.env.REACT_APP_4UBUS + "/api/v1/EstablishmentExtension";

export class ExtensionsService {
  route_path: string;

  constructor(rp: string) {
    this.route_path = rp;
  }

  getAll = (serchParams?: ISearchParams) => {
    return axios.get<ISearchReturn<any>>(this.route_path, {
      params: serchParams,
    });
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<IExtensionGet>(this.route_path + id);
  };

  updateItemById = ({ item, id }: { item: IExtensionPut; id: string }) => {
    return GlobalAxios.put(this.route_path + id, item);
  };

  deleteItemById = (id: string) => {
    return GlobalAxios.delete(this.route_path + id);
  };

  list = (params?: any) => {
    return GlobalAxios.get<ISelectType[]>(this.route_path + "list", {
      params: params,
    });
  };
}

export default ExtensionsService;

export const syncExtensionNow = () => {
  return GlobalAxios.post(BUS_EXTENSION_ROUTE + "/SyncNow");
};

export const getIntegratedExtension = () => {
  return GlobalAxios.get<any>(BUS_EXTENSION_ROUTE + "/List");
};
