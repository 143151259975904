import {
  Dispatch,
  SetStateAction,
  createContext,
  memo,
  useContext,
  useMemo,
} from "react";
import { ICalendar, IFilter } from "./model";
import { TTeste } from "./Schedule";
import useFilter from "./useFilter";
import { useCallback, useEffect, useState } from "react";
import {
  endOfWeek,
  format,
  lastDayOfMonth,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { useFetch } from "@4uhub/lib4uhub";

import { ECalendarMode } from "./Calendar/models";
import { ScheduleAppointmentService } from "./Schedule.service";
import { canShowStatus, sortEvent } from "./utils";
interface IScheduleContext {
  date: Date;
  filter: IFilter;
  calendars: TTeste;
  fullDayColor?: string;
  someAvaiableColor?: string;
  allAvaiableColor?: string;
  loading?: boolean;
  open: boolean;
  fetch: () => Promise<void>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onDateChange: (date: Date) => void;
  removeCalendar: (pIdentifer: number) => void;
  setFilter: (params: Partial<IFilter>) => void;
}

interface IScheduleProviderProps {
  children: React.ReactNode;
  fullDayColor?: string;
  someAvaiableColor?: string;
  allAvaiableColor?: string;
}

interface ISource {
  identifier: string;
  name: string;
  schedules: ICalendar[];
}

const ScheduleContext = createContext<IScheduleContext | undefined>(undefined);

export const ScheduleProvider: React.FC<IScheduleProviderProps> = memo(
  ({ children, ...props }) => {
    const { filter, setFilter, date, setDate } = useFilter();

    const [open, setOpen] = useState(false);

    const [calendars, setCalendars] = useState<TTeste>([]);

    const { sendRequest, loading } = useFetch(
      ScheduleAppointmentService.CalendarAllConsultationExam
    );

    const calendarDateStart = useMemo(() => {
      let day = date;

      if (filter.mode === ECalendarMode.MONTH) {
        day = startOfWeek(startOfMonth(day));
      }

      if (filter.mode === ECalendarMode.WEEK) {
        day = startOfWeek(day);
      }

      return format(day, "MM/dd/yyyy");
    }, [date, filter.mode]);

    const calendarDateEnd = useMemo(() => {
      let day = date;

      if (filter.mode === ECalendarMode.MONTH) {
        day = endOfWeek(lastDayOfMonth(day));
      }

      if (filter.mode === ECalendarMode.WEEK) {
        day = endOfWeek(day);
      }

      return format(day, "MM/dd/yyyy");
    }, [date, filter.mode]);

    const fetch = useCallback(async () => {
      if (filter.professionals.length === 0 && filter.exams.length === 0) {
        return;
      }

      const { data, success } = await sendRequest({
        CalendarDateStart: calendarDateStart,
        CalendarDateEnd: calendarDateEnd,
        TasyScheduleTypeCode:
          filter.source === "-1" ? null : filter.source /*TODO */,
        CalendarIdentifierList: filter.professionals.map((p) => Number(p)),
        GroupingIdentifierList: filter.exams.map((p) => Number(p)),
        SituationCalendar: filter.situation === "-1" ? null : filter.situation,
        ScheduleShiftCodeList:
          filter.period === "-1" ? [] : [filter.period] /*TODO */,
      });

      if (success && data) {
        const exams: ISource[] = data.exams
          .map((e) => ({
            identifier: String(e.groupingIdentifier),
            name: e.groupingName,
            schedules: e.schedules.filter((c) =>
              canShowStatus(c.calendarStatusCode)
            ).sort(sortEvent),
          }))
          .filter((e) => e.schedules.length > 0);

        const consultations: ISource[] = data.consultations.map((c) => ({
          identifier: String(c.schedules[0].calendarIdentifier),
          name: c.physicianNickName,
          schedules: c.schedules.filter((c) =>
            canShowStatus(c.calendarStatusCode)
          ).sort(sortEvent),
        })).filter((e) => e.schedules.length > 0);

        const concat = exams.concat(consultations);

        setCalendars(concat);
      }
    }, [filter, calendarDateStart, calendarDateEnd, sendRequest]);

    const removeCalendar = useCallback((pIdentifer: number) => {
      setCalendars((calendars) =>
        calendars.map((c) => {
          c.schedules = c.schedules.filter((s) => s.identifier !== pIdentifer);

          return c;
        })
      );
    }, []);

    useEffect(() => {
      fetch();
    }, [fetch]);

    const value = useMemo(
      () => ({
        ...props,
        calendars,
        date,
        filter,
        loading,
        open,
        setOpen,
        removeCalendar,
        fetch,
        onDateChange: setDate,
        setFilter,
      }),
      [
        props,
        calendars,
        date,
        filter,
        loading,
        open,
        fetch,
        setDate,
        setOpen,
        setFilter,
        removeCalendar,
      ]
    );

    return (
      <ScheduleContext.Provider value={value}>
        {children}
      </ScheduleContext.Provider>
    );
  }
);

export const useSchedule = () => {
  const context = useContext(ScheduleContext);

  if (context === undefined) {
    throw new Error("use useSchedule must be usede with ScheduleProvider");
  }

  return context;
};
