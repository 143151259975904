import { useTranslation } from "react-i18next";

import { FormInput, useFormContext } from "@4uhub/lib4uhub";
import { Box, Grid } from "@mui/material";
import SalesContractTypeSelect from "./SalesContractTypeSelect";
import SalesGrantorsMultiSelect from "./SalesGrantorsMultiSelect";
import SalesProductCtrClassificationSelect from "./ProductCtrClassificationSelect";

import { TContractProductForm } from "../contract-product.schema";
import FormInformation from "../../../../../../../components/FormInformation/FormInformation";
import { TFormType } from "@4uhub/lib4uhub/dist/types/components/Form/FormContainer/models";

const translationsPath =
  "page.4uSalesContract.registers.contractProducts.products.";
const Fields = ({ type }: { type: TFormType }) => {
  const { t } = useTranslation();

  const { getValues } = useFormContext<TContractProductForm>();

  const situation = getValues("situation");
  return (
    <Box
      sx={(t) => ({
        p: 1,
        px: 7,
        width: "100%",
        height: "calc(100vh - 18rem)",
        overflow: "auto",
        backgroundColor: t.palette.background.default,
        borderRadius: 1,
      })}
    >
      <Grid
        container
        spacing={2}
        sx={(t) => ({
          py: 6,
        })}
      >
        {type !== "create" && situation && (
          <Grid item xs={12} sm={12}>
            {" "}
            <FormInformation
              label={t(translationsPath + "situation")}
              data={situation.name?.toString() ?? ""}
              minHeight={40}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <FormInput
            name="integrationId"
            size="small"
            fullWidth
            label={t(translationsPath + "integration_code")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInput
            name="name"
            size="small"
            fullWidth
            label={t(translationsPath + "name")}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormInput
            name="fantasyName"
            size="small"
            fullWidth
            label={t(translationsPath + "fantasy_name")}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SalesContractTypeSelect />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SalesProductCtrClassificationSelect />
        </Grid>

        <Grid item xs={12} sm={12}>
          <SalesGrantorsMultiSelect />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Fields;
