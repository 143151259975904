import { AutoComplete, ISelectType, useFetch } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import MainApi from "../../../../../../../services/mainApi.service";
import { useTranslation } from "react-i18next";

const GENERIC_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const genericService = new MainApi<ISelectType>(GENERIC_ROUTE);

const translationPath = "chatBot.page.register.ticketService.";

export const SelectMessageReceivedEvent = memo(() => {
  const { t } = useTranslation();

  const [messageReceivedEvents, setMessageReceivedEvents] = useState<
    ISelectType[]
  >([]);

  const { sendRequest } = useFetch(genericService.list);

  const fetchMessageReceivedEvents = useCallback(async () => {
    const { data, success } = await sendRequest({
      Identifier: "TicketMessageReceivedEvents",
    });
    if (data && success) {
      setMessageReceivedEvents(
        data.sort((a, b) =>
          Number(a.code) && Number(b.code)
            ? Number(a.code) < Number(b.code)
              ? -1
              : Number(a.code) > Number(b.code)
              ? 1
              : 0
            : 0
        )
      );
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchMessageReceivedEvents();
  }, [fetchMessageReceivedEvents]);

  return (
    <Grid item xs={12} sm={6}>
      <AutoComplete
        params={{ Identifier: "TicketMessageReceivedEvents" }}
        getOptionLabel={(option) => {
          return option.name;
        }}
        size="small"
        label={t(translationPath + "ticket_message_received_rvent")}
        name="ticketMessageReceivedEvent"
        options={messageReceivedEvents}
      />
    </Grid>
  );
});
