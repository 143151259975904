import { Box, Divider, Grid, Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 300,
  maxWidth: 500,
  maxHeight: 700,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 10,
  p: 4,
};

const LogsModal: React.FC<{
  change: object;
  title: string;
}> = ({ change, title }) => {
  return (
    <Box sx={style}>
      <Grid container justifyContent="flex-end" sx={{ marginBottom: "10px" }}>
        <Grid item xs={11}>
          <Typography variant="h5">{title}</Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={2} sx={{ marginTop: "5px" }}>
        {Object.keys(change).map((key) => (
          <Grid item xs={12} key={key}>
            <Typography variant="h6">{key}</Typography>
            <Typography
              color={(theme) => theme.palette.text.secondary}
              variant="subtitle1"
              id="modal-modal-description"
            >
              {change[key as keyof typeof change]}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default LogsModal;
