import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { getSystem } from "../components/Menu/Menu";
import { useAppSelector } from "../store/store";
import { useApp } from "../store/appContex";
import { useEffect } from "react";

const translationPath = "page.dashboard.";

const WelcomePage = () => {
  const { app } = useApp();

  const { eI } = useParams();

  const navigate = useNavigate();

  const menuState = useAppSelector((state) => state.menu);

  const { t } = useTranslation();

  useEffect(() => {
    if (menuState.items.length > 0) {
      const firstMenu = menuState.items[0];

      const system = getSystem(app);

      const menu = menuState.items.find((m) => m.parentId === firstMenu.id);

      if (menu) {
        const realLink = menu.link === "/" ? system : `${system}${menu.link}`;

        navigate(`/e/${eI}/${realLink}`, { replace: true });
      }
    }
  }, [menuState, navigate, eI, app]);

  return (
    <Stack
      sx={{
        height: "100%",
        width: "100%",
        paddingTop: 5,
      }}
    >
      <Typography variant="h5" mb={2}>
        {t(translationPath + "dashboard")}
      </Typography>
    </Stack>
  );
};

export default WelcomePage;
