import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";
import { FormInput, SwitchInput } from "@4uhub/lib4uhub";

import { buildTPath } from "../beneficiarySuspensionsReason.utils";

const Fields = () => {
  const { t } = useTranslation();

  const translate = (path: string) => t(buildTPath(path));

  return (
    <Grid container direction="column" spacing={5}>
      <Grid item xs={12} sm={12} textAlign="end">
        <SwitchInput name="enable" label={translate("enable")} />
      </Grid>

      <Grid item xs={12} sm={5}>
        <FormInput
          size="small"
          name="name"
          fullWidth
          label={t(buildTPath("name"))}
        />
      </Grid>
    </Grid>
  );
};

export default Fields;
