import { useCallback, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useFieldArray } from "react-hook-form";

import {
  FormArray,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";

import MainApiService from "../../../../services/mainApi.service";
import { TExtensionForm } from "./ExtensionSchema";
import ExtensionFile from "./ExtensionFile";
import { useTranslation } from "react-i18next";

import { IInputFile } from "@4uhub/lib4uhub";
import { IGeneric } from "../../../../models/generics";

const GENERICS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const service = new MainApiService<IGeneric>(GENERICS_ROUTE);

const translationPath = "page.register.extensions.";

const ExtensionFiles = ({
  onDeleteFileHandler,
}: {
  onDeleteFileHandler: (file: IInputFile) => void;
}) => {
  const { t } = useTranslation();
  const { control, formState } = useFormContext<TExtensionForm>();
  const [mediaTypeList, setMediaTypeList] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(service.list);

  const loadMediaTypeList = useCallback(async () => {
    const { data,success } = await sendRequest({
      identifier: "ExtensionMediaType",
    });
    if (data) {
      setMediaTypeList(data);
    }

    return { data, success };
  }, [sendRequest]);

  useEffect(() => {
    loadMediaTypeList();
  }, [loadMediaTypeList]);

  const extensionMediasArray = useFieldArray<
    TExtensionForm,
    "extensionMediaFiles",
    "key"
  >({
    control,
    name: "extensionMediaFiles",
    keyName: "key",
  });

  const fieldsObject = useCallback(
    (index: number) => (
      <ExtensionFile
        onFileSelected={onDeleteFileHandler}
        mediaTypeList={mediaTypeList}
        index={index}
      />
    ),
    [mediaTypeList, onDeleteFileHandler]
  );

  const onDeleteVoidHandler = (data: any) => {
    if (data.file) {
      onDeleteFileHandler(data.file);
    }
  };

  return (
    <Grid item xs={12}>
      <FormArray
        title={t(translationPath + "media_files")}
        name="extensionMediaFiles"
        errorMessage={formState.errors?.extensionMediaFiles?.message}
        addButtonLabel={`${t(translationPath + "add_media_file")}`}
        formArray={extensionMediasArray}
        onDeleteVoid={onDeleteVoidHandler}
        appendValue={{
          mediaType: mediaTypeList
            ? mediaTypeList[0]
            : { id: "", name: "", code: "" },
          file: {
            id: "",
          },
        }}
        fieldsObject={fieldsObject}
      />
    </Grid>
  );
};

export default ExtensionFiles;
