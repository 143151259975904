import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

// Schema
import MainApiService from "../../../../../../services/mainApi.service";
import {
  EstablishmentParamsSchema,
  TEstablishmentParamsForm,
} from "./EstablishmentParamSchema";
import { useCallback, useState } from "react";
import {
  AutoComplete,
  FormContainer,
  FormInput,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import EstablishmentParamService from "../../../../../../services/establishmentParam.service";
import { IGeneric } from "../../../../../../models/generics";

const establishmentParamService = new EstablishmentParamService();

const ESTABLISHMENTS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const establishmentsListService = new MainApiService<IGeneric>(
  ESTABLISHMENTS_ROUTE
);

const EstablishmentParam = () => {
  const { t } = useTranslation();

  const [description, setDescription] = useState<string>();

  const { sendRequest: save, loading: sLoading } = useFetch(
    establishmentParamService.create
  );
  const { sendRequest: update, loading: uLoading } = useFetch(
    establishmentParamService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    establishmentParamService.getItemById
  );

  const handleSave = (v: TEstablishmentParamsForm) => {
    return save({
      ...v,
      establishmentParamTypeId: v.establishmentParamType.id,
    });
  };

  const updateHandler = (v: TEstablishmentParamsForm, id: string) =>
    update({
      item: { ...v, establishmentParamTypeId: v.establishmentParamType.id },
      id: id,
    });

  const handleGet = useCallback(
    async (id: string): Promise<Treturn<TEstablishmentParamsForm>> => {
      const { data, success } = await get(id);
      if (success) {
        setDescription(data?.establishmentParamType.description);
        return { data, success };
      }
      return {
        success: false,
        data: null,
      };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;
  return (
    <FormContainer<TEstablishmentParamsForm>
      saveFunction={handleSave}
      updateFunction={updateHandler}
      getFunction={handleGet}
      loading={loading}
      defaultValues={{
        isPublic: false,
      }}
      title={t("page.register.establishmentParams.establishmentParams")}
      schema={EstablishmentParamsSchema}
      subtitleWatchField="description"
      subtitle={t("page.register.establishmentParams.new_param")}
      fields={(type) => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={10}>
            <AutoComplete
              disable={type === "update" ? true : false}
              params={{ identifier: "EstablishmentParamType" }}
              autoFocus
              onValueChange={(e) => {
                setDescription(e?.description);
              }}
              getOptionLabel={(option: any) => {
                return option.name;
              }}
              renderOption={(props, option) => {
                let render: string = option.name;

                if (option.code) {
                  render = `${option.name} (${option.code})`;
                }
                return (
                  <li {...props} key={option.id}>
                    {render}
                  </li>
                );
              }}
              size="small"
              label={
                t(
                  "page.register.establishmentParams.establishmentParamTypeId"
                ) || "Param type"
              }
              name="establishmentParamType"
              request={establishmentsListService.list}
            />
          </Grid>
          <Grid item xs={"auto"}>
            <SwitchInput
              name="isPublic"
              label={t("page.register.establishmentParams.public") || "Public"}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={(theme) => ({
                marginLeft: theme.spacing(1),
              })}
              variant="subtitle2"
            >
              {description}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormInput
              name="description"
              multiline
              minRows={4}
              label={
                t("page.register.establishmentParams.description") ||
                "Description"
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} mb={5}>
            <FormInput
              name="value"
              multiline
              minRows={4}
              label={t("page.register.establishmentParams.value") || "Value"}
              fullWidth
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default EstablishmentParam;
