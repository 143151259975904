import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import {
  MainApiService,
  OverflowTooltip,
  Table,
  useRole,
} from "@4uhub/lib4uhub";

import { GlobalAxios } from "../../../axios/axios-config";
import { IEvaluationForms } from "../../../models/evaluation-forms";
import { Chip } from "@mui/material";

const translationPath = "page.evaluation.forms.";

const FORMS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/EvaluationForm/";

const formsService = new MainApiService<IEvaluationForms>(
  FORMS_ROUTE,
  GlobalAxios
);

const FormsPage = () => {
  const navigate = useNavigate();

  const createPermission = useRole(["Create"]);

  const { t } = useTranslation();

  const columns: GridColDef<IEvaluationForms>[] = useMemo(
    () => [
      {
        field: "title",
        headerName: t(translationPath + "title") || "Title",
        renderCell: (params) => {
          return params.value ? (
            <OverflowTooltip
              text={params.value}
              tooltip={params.value}
              textTransform="none"
              variant="body2"
            />
          ) : (
            "-"
          );
        },
      },
      {
        field: "description",
        headerName: t(translationPath + "description") || "Description",
        renderCell: (params) => {
          return params.value ? (
            <OverflowTooltip
              text={params.value}
              tooltip={params.value}
              textTransform="none"
              variant="body2"
            />
          ) : (
            "-"
          );
        },
      },
      {
        field: "enable",
        headerName: t(translationPath + "status") || "Status",
        renderCell: (params) => (
          <Chip
            size="small"
            label={
              params.value
                ? t(translationPath + "active")
                : t(translationPath + "inactive")
            }
            color={params.value ? "success" : "error"}
          />
        ),
      },
    ],
    [t]
  );

  const handleRowClick = (params: GridRowParams<IEvaluationForms>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  return (
    <Table<IEvaluationForms>
      showDefaultMacroFilters={false}
      service={formsService}
      columns={columns}
      title={t(translationPath + "forms") || "Forms"}
      searchInput
      onAdd={handleAdd}
      showButton={createPermission}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Searh for a form"
      }
      addButtonLabel={t(translationPath + "add_form") || "Add Form"}
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default FormsPage;
