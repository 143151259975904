import { ptBR, enUS } from "date-fns/locale";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Chip } from "@mui/material";
import {
  Table,
  UsePrivileges,
  useFetch,
  useNotificationContext,
} from "@4uhub/lib4uhub";

import MainApiService from "../../../../services/mainApi.service";
import { IGetAllNotifications } from "../../../../models/notifications";
import { format } from "date-fns";

import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import { useCallback, useMemo, useState } from "react";
import {
  cancelNotificationBatch,
  generateNotificationBatch,
} from "../../../../services/notification.service";

const NOTIFICATIONS_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/Notification/";
const notificationsService = new MainApiService<IGetAllNotifications>(
  NOTIFICATIONS_ROUTE
);

const translationPath = "page.register.notifications.";

const NotificationsPage = () => {
  const navigate = useNavigate();

  const { privileges } = UsePrivileges();
  const batchPrivilege =
    !!privileges.find((p) => p.domainName === "notificationadm") ||
    !!privileges.find((p) => p.domainName === "all");

  const { setMessage } = useNotificationContext();

  const [render, setRender] = useState(0);

  const { sendRequest: generateBatch, loading: gLoading } = useFetch(
    generateNotificationBatch
  );

  const { sendRequest: cancelBatch, loading: cLoading } = useFetch(
    cancelNotificationBatch
  );

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const onRefreshHandler = useCallback(() => {
    setRender((prevState) => (prevState = prevState + 1));
  }, []);

  const columns: GridColDef<IGetAllNotifications>[] = [
    {
      field: "title",
      headerName: t(translationPath + "title") || "Title",
      renderCell: (params) =>
        params.row.notificationBatchContents.find(
          (content) => content.language.code === language
        )?.title || "-",
    },
    {
      field: "startPublish",
      headerName: t(translationPath + "start_publish") || "Start publish",
      renderCell: (params) => {
        const date = new Date(params.value);
        return params.value
          ? format(date, "P pp", {
              locale: language === "pt-BR" ? ptBR : enUS,
            }).slice(0, -3)
          : "-";
      },
    },
    {
      field: "finishPublish",
      headerName: t(translationPath + "finish_publish") || "Finish publish",
      renderCell: (params) => {
        const date = new Date(params.value);
        return params.value
          ? format(date, "P pp", {
              locale: language === "pt-BR" ? ptBR : enUS,
            }).slice(0, -3)
          : "-";
      },
    },
    {
      field: "type",
      headerName: t(translationPath + "type") || "Type",
      renderCell: (params) => {
        return params.value.name;
      },
    },
    {
      field: "isPublished",
      headerName: t(translationPath + "published_status") || "Published status",
      renderCell: (params) => (
        <Chip
          size="small"
          color={params.value ? "success" : "error"}
          label={
            params.value
              ? t(translationPath + "published")
              : t(translationPath + "not_published")
          }
        />
      ),
    },
    {
      field: "status",
      headerName: t(translationPath + "status") || "Notification status",
      renderCell: (params) => (
        <Chip
          size="small"
          color={
            params.value.code === "1"
              ? "primary"
              : params.value.code === "2"
              ? "default"
              : params.value.code === "3"
              ? "success"
              : "error"
          }
          label={
            params.value.code === "1"
              ? t(translationPath + "registered")
              : params.value.code === "2"
              ? t(translationPath + "generating")
              : params.value.code === "3"
              ? t(translationPath + "generated")
              : params.value.code === "4"
              ? t(translationPath + "canceled")
              : t(translationPath + "error")
          }
        />
      ),
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IGetAllNotifications>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const generateBatchHandler = useCallback(
    async (id: string) => {
      if (id) {
        const { data, success } = await generateBatch(id);
        if (data && success) {
          onRefreshHandler();
          setMessage({
            message: `${t(
              "components.notificationBatchActions.batch_generated"
            )}`,
            type: "success",
          });
        }
      }
    },
    [generateBatch, setMessage, t, onRefreshHandler]
  );

  const cancelBatchHandler = useCallback(
    async (id: string) => {
      if (id) {
        const { data, success } = await cancelBatch(id);
        if (data && success) {
          onRefreshHandler();
          setMessage({
            message: `${t(
              "components.notificationBatchActions.batch_canceled"
            )}`,
            type: "success",
          });
        }
      }
    },
    [cancelBatch, setMessage, t, onRefreshHandler]
  );

  const loading = gLoading || cLoading;

  const props = useMemo(() => {
    return {
      render: render,
    };
  }, [render]);

  return (
    <Table<IGetAllNotifications>
      showDefaultMacroFilters={false}
      service={notificationsService}
      columns={columns}
      title={t(translationPath + "notifications")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      syncing={loading}
      pageSizeOptions={[5, 10, 20]}
      serviceProps={props}
      rowActions={(params) => {
        let actions = [];

        if (params.row.status.code !== "4" && batchPrivilege) {
          actions.push(
            {
              action: () => generateBatchHandler(params.row.id),
              icon: <NotificationsIcon />,
              text: t("components.notificationBatchActions.generate"),
            },
            {
              action: () => cancelBatchHandler(params.row.id),
              icon: <NotificationsOffIcon />,
              text: t("components.notificationBatchActions.cancel"),
            }
          );
        }

        return actions;
      }}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a Notification"
      }
      addButtonLabel={
        t(translationPath + "add_notification") || "Add Notification"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default NotificationsPage;
