import { Stack } from "@mui/material";
import MenuLinkItem from "../MenuItem/MenuItem";
import { useAppSelector } from "../../../store/store";

const MenuItemList: React.FC<{
  activeAccordion: boolean;
  onClick?: () => void;
}> = ({ activeAccordion, onClick }) => {
  const items = useAppSelector((state) => state.menu.items).filter(
    (item) => !item.parentId
  );

  return (
    <Stack
      direction="column"
      alignItems={"flex-start"}
      spacing={1}
      width={"100%"}
    >
      {items.map((item) => (
        <MenuLinkItem
          item={item}
          key={item.id}
          activeAccordion={activeAccordion}
          onClick={onClick}
        />
      ))}
    </Stack>
  );
};

export default MenuItemList;
