import { memo } from "react";

import { INewFileProps } from "./models";
import Captions from "./Captions";
import ContentsFileValue from "../ContentsFileValue/ContentsFileValue";

const ContentFile: React.FC<INewFileProps> = ({
  index,
  onFileSelected,
  allLanguages,
  contentMediaFiles,
  mediaTypeCode,
}) => {
  return (
    <>
      <ContentsFileValue
        onFileSelected={onFileSelected}
        index={index}
        mediaTypeCode={mediaTypeCode}
      />
      <Captions
        index={index}
        allLanguages={allLanguages}
        contentMediaFiles={contentMediaFiles}
      />
    </>
  );
};

export default memo(ContentFile);
