import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";

import { IGeneric } from "../models/generics";
import {
  TLanguagesForm,
  languagesSchema,
} from "./globals/languages/LanguagesForm/LanguagesSchema";

import MainApiService from "../services/mainApi.service";

import { useFieldArray, useForm } from "react-hook-form";
import EditorComponent from "../components/UI/TextEditor/Editor";
import { TGenericForm } from "./globals/generics/GenericForm/GenericSchema";
import {
  Form,
  FormArray,
  FormContainer,
  Loading,
  Table,
  AppLoading,
  BackButton,
  useNotificationContext,
  AutoComplete,
  FormCheckBox,
  FormInput,
  SwitchInput,
  AutoCompleteMulti,
  DateTime,
  FormSelect,
  SelectBool,
} from "@4uhub/lib4uhub";

const GENERICS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const ESTABLISHMENTS_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/Establishment/";
const ROULES_LIST_ROUTE = process.env.REACT_APP_IDENTIY + "/api/v1/Roles/";
const PARENT_LIST_ROUTE = process.env.REACT_APP_IDENTIY + "/api/v1/Menus/";
const translationPath = "page.register.generics.";

const parentListService = new MainApiService<TGenericForm>(PARENT_LIST_ROUTE);
const establishmentsListService = new MainApiService<TGenericForm>(
  ESTABLISHMENTS_ROUTE
);
const rolesListService = new MainApiService<TGenericForm>(ROULES_LIST_ROUTE);
const genericService = new MainApiService<IGeneric>(GENERICS_ROUTE);

interface IExempleComponent {
  element: JSX.Element;
  description: string;
}
const Exemple: React.FC<IExempleComponent> = ({ element, description }) => {
  return (
    <Grid container gap={4} direction={"row"}>
      <Grid item xs={7}>
        {element}
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle1">{description}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

const ExemplesPage = () => {
  const [appLoading, setAppLoading] = useState(false);

  const [contextLoading, setContextLoading] = useState(false);

  const { setMessage } = useNotificationContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columns: GridColDef<IGeneric>[] = [
    {
      field: "identifier",
      headerName: t(translationPath + "identifier") || "Identifier",
    },
    {
      field: "code",
      headerName: t(translationPath + "code") || "Code",
    },
    {
      field: "value",
      headerName: t(translationPath + "value") || "Value",
    },
  ];

  const handleRowClick = (params: GridRowParams<IGeneric>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("/register/generics/new");
  };

  const methods = useForm({
    defaultValues: {
      datetime: "2023-06-21T15:32:45.643069+00:00",
      contacts: [],
    },
  });

  const contactsFieldArray = useFieldArray<any>({
    control: methods.control,
    name: "contacts",
  });

  const handleContextLoading = () => {
    setContextLoading(true);
    setTimeout(() => {
      setContextLoading(false);
    }, 2000);
  };

  const handleAppLoading = () => {
    setAppLoading(true);
    setTimeout(() => {
      setAppLoading(false);
    }, 2000);
  };

  const notificationHandler = () => {
    setMessage({
      message: "Mensagem de erro",
      type: "error",
      notificationKey: new Date().getMilliseconds(),
    });
  };

  return (
    <Stack alignItems={"center"} spacing={10}>
      <Typography variant="h3">Exemplos</Typography>
      <Form {...methods}>
        <Stack maxWidth={"800px"} minWidth={"300px"} spacing={2}>
          <Exemple
            element={
              <>
                <Button
                  variant="contained"
                  onClick={handleAppLoading}
                  fullWidth
                >
                  App Loading
                </Button>
                {appLoading && <AppLoading />}
              </>
            }
            description="Loading da aplicação"
          ></Exemple>

          <Exemple
            element={
              <>
                <Button
                  variant="contained"
                  onClick={handleContextLoading}
                  fullWidth
                >
                  Context Loading
                </Button>
                <Box position={"relative"}>{contextLoading && <Loading />}</Box>
              </>
            }
            description="Loading de contexto"
          ></Exemple>
          <Exemple
            element={
              <AutoComplete
                params={{ identifier: "TaxRegime" }}
                getOptionLabel={(option) => {
                  return option.name;
                }}
                size="small"
                label={"TaxRegime"}
                name="taxRegimeId"
                request={genericService.list}
              />
            }
            description="Autocomplete onde os dados são requisitados assim que o componentes é montado"
          ></Exemple>

          <Exemple
            element={
              <AutoComplete
                getOptionLabel={(option) => {
                  return option.code || option.description || "";
                }}
                size="small"
                label={"Parent"}
                name="parent"
                request={parentListService.list}
                SearchAsYouTypeParams={(v) => ({ SearchBy: v })}
              />
            }
            description="Autocomplete onde os dados são buscado conforme o valor do input é inserido"
          ></Exemple>

          <Exemple
            element={
              <AutoCompleteMulti
                size="small"
                label={"Roles"}
                name="roles"
                request={rolesListService.list}
              />
            }
            description="Autocomplete multiplo onde os dados são requisitados assim que o componentes é montado"
          ></Exemple>

          <Exemple
            element={
              <AutoCompleteMulti
                SearchAsYouTypeParams={(v) => ({ SearchBy: v })}
                size="small"
                label={"Establishments"}
                name="establishments"
                request={establishmentsListService.list}
              />
            }
            description="Autocomplete multiplo onde os dados são buscado conforme o valor do input é inserido"
          ></Exemple>

          <Exemple
            element={<BackButton />}
            description="Botão que volta um página"
          ></Exemple>

          <Exemple
            element={<DateTime label="Date Time" name="datetime" />}
            description="Date time picker para formulários"
          ></Exemple>

          <Exemple
            element={
              <FormArray
                title="Contatos"
                name="contacts"
                errorMessage={methods.formState.errors?.contacts?.message?.toString()}
                addButtonLabel="Adicionar"
                formArray={contactsFieldArray}
                appendValue={{
                  code: "1",
                  id: "c13c5474-1821-4dd2-a0e3-ed34f7a025b5",
                  value: "",
                  name: "Fone",
                }}
                fieldsObject={(index) => {
                  const valueField = `contacts.${index}.value`;
                  const cField = `contacts.${index}`;
                  return (
                    <>
                      <Grid item xs={12} sm={4}>
                        <AutoComplete
                          params={{ identifier: "ContactType" }}
                          getOptionLabel={(option) => {
                            return option.name;
                          }}
                          size="small"
                          label={"Tipo de contato"}
                          name={cField}
                          request={genericService.list}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <FormInput size="small" name={valueField} fullWidth />
                      </Grid>
                    </>
                  );
                }}
              />
            }
            description="Container para facilitar a criação de array de formulários"
          ></Exemple>

          <Exemple
            element={<FormCheckBox label="Form CheckBox" name="CheckBox" />}
            description="CheckBox para formulários"
          ></Exemple>

          <Exemple
            element={
              <FormInput
                label="Form Input"
                name="FormInput"
                fullWidth
                size="small"
              />
            }
            description="Input de texto para formulários"
          ></Exemple>

          <Exemple
            element={<SwitchInput label="Form Input" name="SwitchInput" />}
            description="Switch  para formulários"
          ></Exemple>

          <Exemple
            element={
              <FormSelect
                label="Form Input"
                name="Select"
                options={[
                  { label: "Sim", value: 1 },
                  { label: "Não", value: 0 },
                ]}
                fullWidth
                size="small"
              />
            }
            description="Select simples"
          ></Exemple>

          <Exemple
            element={
              <Button onClick={notificationHandler} variant="contained">
                Notificar
              </Button>
            }
            description="Notificação"
          ></Exemple>

          <Exemple
            element={<EditorComponent />}
            description="Editor de texto"
          ></Exemple>

          <Exemple
            element={<SelectBool name="teste" fullWidth />}
            description="Seletor de true or false"
          ></Exemple>

          <Exemple
            element={
              <Table<IGeneric>
                showDefaultMacroFilters={false}
                service={genericService}
                columns={columns}
                title="Generics"
                searchInput
                onAdd={handleAdd}
                onRowClick={handleRowClick}
                defaultPageSize={20}
                pageSizeOptions={[5, 10, 20]}
                searchInputPlaceHolder={
                  t(translationPath + "search_identifier") ||
                  "Searh for a identifier"
                }
                addButtonLabel={
                  t(translationPath + "add_generic") || "Add Generic"
                }
                confirmOptions={{
                  title: t(translationPath + "confirm.title"),
                  description: t(translationPath + "confirm.description"),
                  confirmationButtonProps: {
                    variant: "contained",
                  },
                }}
              />
            }
            description="Componente de table usado na maior parte dos cruds"
          ></Exemple>

          <Exemple
            element={
              <FormContainer<TLanguagesForm>
                saveFunction={async (v) => {
                  return console.log(v);
                }}
                actionsBar={false}
                updateFunction={async (v) => {
                  return console.log(v);
                }}
                defaultValues={{
                  enable: true,
                }}
                title={t("page.register.languages.languages")}
                schema={languagesSchema}
                subtitle={t("page.register.languages.new_language")}
                subtitleWatchField={"name"}
                fields={() => (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormInput
                        size="small"
                        name="name"
                        label={t("page.register.languages.name") || "Name"}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormInput
                        size="small"
                        name="code"
                        label={t("page.register.languages.code") || "Code"}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <SwitchInput name="enable" label="Enable" />
                    </Grid>
                  </Grid>
                )}
              />
            }
            description="Container de formulário usado na maior parte dos cruds"
          ></Exemple>
        </Stack>
      </Form>
    </Stack>
  );
};

export default ExemplesPage;
