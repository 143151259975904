import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  AutoComplete,
  FormContainer,
  FormInput,
  ISelectType,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import MainApi from "../../../../services/mainApi.service";
import {
  createQuestion,
  getQuestionById,
  updateQuestionById,
} from "../../../../services/question.service";
import { TQuestionForm, questionSchema } from "./QuestionSchema";
import { useCallback, useEffect, useState } from "react";
import { QuestionOptionType } from "./QuestionOptionType";
import { QuestionAlternatives } from "./QuestionAlternatives";
import { QuestionCategory } from "./QuestionCategory";
import { QuestionDescription } from "./QuestionDescription";

const QUESTION_EVALUATION_TYPE_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/QuestionEvaluationType/";
const questionEvalutionTypeService = new MainApi<ISelectType[]>(
  QUESTION_EVALUATION_TYPE_ROUTE
);

const translationPath = "page.assessments.questions.";

const Question = () => {
  const { t } = useTranslation();

  const [evaluationTypes, setEvaluationTypes] = useState<ISelectType[]>();

  const { sendRequest, loading: eLoading } = useFetch(
    questionEvalutionTypeService.list
  );

  const fetchEvaluationTypes = useCallback(async () => {
    const { data, success } = await sendRequest(null);
    if (data && success) {
      setEvaluationTypes(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchEvaluationTypes();
  }, [fetchEvaluationTypes]);

  const { sendRequest: save, loading: sLoading } = useFetch(createQuestion);

  const { sendRequest: update, loading: uLoading } =
    useFetch(updateQuestionById);

  const { sendRequest: get, loading: gLoading } = useFetch(getQuestionById);

  const saveHandler = (v: TQuestionForm) =>
    save({
      ...v,
      questionCategoryId: v.questionCategory.id,
      questionEvaluationTypeId: v.questionEvaluationType.id,
      questionOptionTypes: v.questionOptionType
        ? {
            ...v.questionOptionType,
            isRequiredJustification:
              v.questionOptionType.isRequiredJustification,
            requiredJustificationLessThan: v.questionOptionType
              .isRequiredJustification
              ? Number(v.questionOptionType.requiredJustificationLessThan)
              : undefined,
            requiredJustificationMinChars: v.questionOptionType
              .isRequiredJustification
              ? Number(v.questionOptionType.requiredJustificationMinChars)
              : undefined,
            requiredJustificationMessage:
              v.questionOptionType &&
              v.questionOptionType.isRequiredJustification &&
              v.questionOptionType.requiredJustificationMessage
                ? v.questionOptionType.requiredJustificationMessage
                : undefined,
          }
        : undefined,
    });

  const updateHandler = (v: TQuestionForm, id: string) =>
    update({
      item: {
        ...v,
        id,
        questionCategoryId: v.questionCategory.id,
        questionOptionTypes: v.questionOptionType
          ? {
              ...v.questionOptionType,
              isRequiredJustification:
                v.questionOptionType.isRequiredJustification,
              requiredJustificationLessThan: v.questionOptionType
                .isRequiredJustification
                ? Number(v.questionOptionType.requiredJustificationLessThan)
                : undefined,
              requiredJustificationMinChars: v.questionOptionType
                .isRequiredJustification
                ? Number(v.questionOptionType.requiredJustificationMinChars)
                : undefined,
              requiredJustificationMessage:
                v.questionOptionType &&
                v.questionOptionType.isRequiredJustification &&
                v.questionOptionType.requiredJustificationMessage
                  ? v.questionOptionType.requiredJustificationMessage
                  : undefined,
            }
          : undefined,
      },
      id: id,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TQuestionForm>> => {
      const { data } = await get(id);
      if (data) {
        return {
          data: {
            ...data,
            questionOptionType: data.questionOptionType
              ? {
                  ...data.questionOptionType,
                  requiredJustificationLessThan: data.questionOptionType
                    .requiredJustificationLessThan
                    ? data.questionOptionType.requiredJustificationLessThan.toString()
                    : "",
                  requiredJustificationMinChars: data.questionOptionType
                    .requiredJustificationMinChars
                    ? data.questionOptionType.requiredJustificationMinChars.toString()
                    : "",
                }
              : undefined,
          },
          success: true,
        };
      }
      return {
        data: null,
        success: false,
      };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  if (eLoading) return null;

  return (
    <FormContainer<TQuestionForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={evaluationTypes ? getHandler : undefined}
      loading={loading}
      title={t(translationPath + "question")}
      schema={questionSchema}
      subtitle={t(translationPath + "new_question")}
      subtitleWatchField={"title"}
      defaultValues={{
        questionEvaluationType: evaluationTypes
          ? evaluationTypes[0]
          : undefined,
      }}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <AutoComplete
              name="questionEvaluationType"
              label={
                t(translationPath + "question_evaluation_type") ||
                "Question Evaluation Type"
              }
              size="small"
              fullWidth
              disable
              getOptionLabel={(option) => option.name}
              options={evaluationTypes}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="integrationId"
              fullWidth
              label={t(translationPath + "integration_id") || "Integration Id"}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="title"
              fullWidth
              label={t(translationPath + "title") || "Title"}
            />
          </Grid>

          <QuestionDescription />

          <QuestionCategory />

          <QuestionAlternatives />

          <QuestionOptionType />
        </Grid>
      )}
    />
  );
};

export default Question;
