import { z } from "zod";

const translationPath = "page.register.establishments.errors.";

const companySchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string(),
  },
  {
    invalid_type_error: `${translationPath + "company"}`,
  }
);

const languagesSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string(),
  enable: z.boolean(),
});

const softwareSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const legalEntitySchema = z.object({
  id: z.string(),
  identifier: z.string(),
  corporateName: z.string(),
  tradingName: z.string(),
  document: z.string(),
  dataOrigin: z.string(),
});

export const establishmentSchema = z.object({
  id: z.string().optional(),
  name: z.string().nonempty(translationPath + "name"),
  integrationId: z.coerce.number().nullable(),
  enable: z.boolean(),
  collectorHost: z
    .string({ invalid_type_error: translationPath + "collector_https" })
    .nullable()
    .refine(
      (val) => !val || val.startsWith("https://") || val?.startsWith("http://"),
      {
        message: translationPath + "collector_https",
      }
    ),
  parentHost: z
    .string({ invalid_type_error: translationPath + "parent_https" })
    .nullable()
    .refine(
      (val) => !val || val.startsWith("https://") || val?.startsWith("http://"),
      {
        message: translationPath + "parent_https",
      }
    ),
  realTimeHost: z
    .string({ invalid_type_error: translationPath + "real_time_https" })
    .nullable()
    .refine(
      (val) => !val || val.startsWith("https://") || val?.startsWith("http://"),
      {
        message: translationPath + "real_time_https",
      }
    ),
  languages: z.array(languagesSchema).nonempty(translationPath + "languages"),
  company: companySchema,
  domainAdmin: z
    .string()
    .min(1, translationPath + "domain_admin")
    .refine(
      (val) =>
        !val || val?.startsWith("https://") || val?.startsWith("http://"),
      {
        message: translationPath + "domain_admin_https",
      }
    ),
  crmDomain: z.string().nullable(),
  ansCode: z.string().nullable(),
  fileManagerHost: z
    .string()
    .nullable()
    .refine(
      (val) =>
        !val || val?.startsWith("https://") || val?.startsWith("http://"),
      {
        message: translationPath + "filemanager_host_https",
      }
    ),
  softwares: z.array(softwareSchema).optional().nullable(),
  legalEntity: legalEntitySchema.nullish(),
});

export type TEstablishmentForm = z.infer<typeof establishmentSchema>;
