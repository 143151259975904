import { TTeste } from "../Schedule";
import { ICalendar } from "../model";

export interface ITime {
  calendarStartDate: string;
  calendarEndDate: string;
}

export enum ECalendarMode {
  MONTH = "month",
  DAY = "day",
  WEEK = "week",
}

export interface IGroupedEvents<T extends ITime> {
  times: T[];
}

export interface IBaseCalendar {
  date: Date;
  calendars: TTeste;
  fullDayColor?: string;
  someAvaiableColor?: string;
  allAvaiableColor?: string;
  onAvaiableTimeClick?: (avaiableTime: ICalendar) => void;
  renderPopOver: (
    event: ICalendar,
    onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void
  ) => React.ReactNode;
}
