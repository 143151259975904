import { GlobalAxios } from "../axios/axios-config";
import {
  INewQuestion,
  IQuestion,
  IQuestionAlternatives,
  IQuestionList,
  IUpdateQuestion,
} from "../models/questions";

const QUESTION_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Question";

const QUESTION_ALTERNATIVES_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/QuestionEvaluationTypeAlternative/list";

export const createQuestion = (item: INewQuestion) => {
  return GlobalAxios.post(QUESTION_ROUTE, item);
};

export const getQuestionById = (id: string) => {
  return GlobalAxios.get<IQuestion>(QUESTION_ROUTE + "/" + id);
};

export const updateQuestionById = ({
  item,
  id,
}: {
  item: IUpdateQuestion;
  id: string;
}) => {
  return GlobalAxios.put(QUESTION_ROUTE + "/" + id, item);
};

export const listQuestions = (params?: any) => {
  return GlobalAxios.get<IQuestionList[]>(QUESTION_ROUTE + "/list", {
    params: params,
    paramsSerializer: { indexes: null },
  });
};

export const listAlternatives = (params?: any) => {
  return GlobalAxios.get<IQuestionAlternatives[]>(QUESTION_ALTERNATIVES_ROUTE, {
    params: params,
  });
};
