import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useCallback, useState } from "react";
import LogsModal from "../LogsModal/LogsModal";
import { TChange } from "../../../models/logs";
import { useTranslation } from "react-i18next";
import NoData from "../NoData/NoData";
import { Modal } from "@4uhub/lib4uhub";

const LogsTable: React.FC<{ changes: TChange[] | null }> = ({ changes }) => {
  const [open, setOpen] = useState(false);
  const [change, setChange] = useState<object>();
  const [modalTitle, setModalTitle] = useState<string>("");
  const { t } = useTranslation();

  const handleOpen = useCallback(
    (value: any, title: string) => {
      setModalTitle(title);
      setChange(value);
      setOpen(true);
    },
    [setModalTitle, setChange, setOpen]
  );

  const handleClose = () => setOpen(false);

  if (!changes)
    return <NoData message={t("components.logs_no_data.message")} />;

  return (
    <>
      {change && (
        <Modal open={open} onClose={handleClose}>
          <LogsModal change={change} title={modalTitle} />
        </Modal>
      )}
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t("components.logs_table.name")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t("components.logs_table.original_value")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t("components.logs_table.new_value")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {changes.map((change: any) => {
              const oValueIsObject = typeof change.originalValue === "object";
              const nValueIsObject = typeof change.newValue === "object";

              return (
                <TableRow
                  key={change.columnName}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {change.columnName}
                  </TableCell>
                  <TableCell>
                    {oValueIsObject && (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() =>
                          handleOpen(
                            change.originalValue,
                            `${t("components.logs_table.original_value")}`
                          )
                        }
                      >
                        {t("components.logs_table.button_see_more")}
                      </Button>
                    )}
                    {!oValueIsObject && change.originalValue}
                  </TableCell>
                  <TableCell>
                    {nValueIsObject && (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() =>
                          handleOpen(
                            change.newValue,
                            `${t("components.logs_table.new_value")}`
                          )
                        }
                      >
                        {t("components.logs_table.button_see_more")}
                      </Button>
                    )}
                    {!nValueIsObject && change.newValue}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default LogsTable;
