import { IEstablishment, useFormContext } from "@4uhub/lib4uhub";
import { Grid, Stack, Tooltip, Typography } from "@mui/material";
import { IUserGet } from "../../../../models/users";
import { TUserForm } from "./UserSchema";
import { useTranslation } from "react-i18next";
import { TFormType } from "../../../../models/form";

import InfoIcon from "@mui/icons-material/Info";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

interface IMainUserEstablishmentProps {
  user: IUserGet | undefined;
  type: TFormType;
  establishmentsList: IEstablishment[];
}

const MainUserEstablishment = ({
  user,
  type,
  establishmentsList,
}: IMainUserEstablishmentProps) => {
  const { t } = useTranslation();

  const { setValue } = useFormContext<TUserForm>();

  const { eI } = useParams();

  const currentEstablishment = establishmentsList.find(
    (e) => e.tenantSequential === Number(eI)
  );

  const { getValues } = useFormContext<TUserForm>();

  useEffect(() => {
    const mainEstablishment = establishmentsList.find(
      (e) => e.tenantSequential === Number(eI)
    );

    if (type === "create" && mainEstablishment) {
      setValue("establishmentStandard", mainEstablishment);
    }
  }, [setValue, type, establishmentsList, eI]);

  return (
    <Grid item xs={12}>
      <Stack flexDirection={"row"} flexWrap={"wrap"} gap={1}>
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          gap={0.5}
          sx={(t) => ({
            color:
              t.palette.mode === "light"
                ? t.palette.grey[600]
                : t.palette.grey[400],
          })}
        >
          <Tooltip
            title={t("page.register.users.tooltip_main_estabishment")}
            disableHoverListener={type === "update"}
          >
            {type === "create" ? (
              <InfoIcon fontSize="small" sx={{ cursor: "pointer" }} />
            ) : (
              <></>
            )}
          </Tooltip>
          <Typography lineHeight={1.2}>
            {t("page.register.users.main_establishments")}:
          </Typography>
        </Stack>
        <Typography lineHeight={1.2}>
          {type === "create" && currentEstablishment
            ? currentEstablishment.name
            : getValues("establishmentStandard")
            ? getValues("establishmentStandard").name
            : ""}
        </Typography>
      </Stack>
    </Grid>
  );
};

export default MainUserEstablishment;
