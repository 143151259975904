import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  AutoComplete,
  AutoCompleteMulti,
  Button,
  FormContainer,
  FormInput,
  ISelectType,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import { useCallback, useEffect, useState } from "react";

import SitesAppsService from "../../../../../services/sitesApps.service";
import FaqService from "../../../../../services/faq.service";
import { TFaqForm, faqSchema } from "./FaqSchema";
import MainApi from "../../../../../services/mainApi.service";
import { useNavigate, useParams } from "react-router";
import { getEIFromUrl } from "../../../../../services/establishment.service";
import AddIcon from "@mui/icons-material/Add";

const faqService = new FaqService();

const translationPath = "page.register.faq.";

const siteAppExtensionService = new SitesAppsService();

const LANGUAGE_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Language/";
const languageService = new MainApi<ISelectType>(LANGUAGE_ROUTE);

const Faq = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const eI = getEIFromUrl();
  const navigate = useNavigate();

  const [siteAppOptions, setSiteAppOptions] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(
    siteAppExtensionService.siteAppExtensionList
  );

  const loadSiteApp = useCallback(async () => {
    const { data, success } = await sendRequest({ ExtensionCode: "6" });

    if (data && success) {
      setSiteAppOptions(
        data.map((item) => ({ id: item.siteApp.id, name: item.siteApp.name }))
      );
    }
  }, [sendRequest]);

  useEffect(() => {
    loadSiteApp();
  }, [loadSiteApp]);

  const { sendRequest: save, loading: sLoading } = useFetch(faqService.create);

  const { sendRequest: update, loading: uLoading } = useFetch(
    faqService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    faqService.getItemById
  );

  const saveHandler = (v: TFaqForm) =>
    save({
      ...v,
      siteAppIds: v.siteApp.map((item) => item.id),
      languageId: v.language.id,
      displayOrder: +v.displayOrder,
    });

  const updateHandler = (v: TFaqForm, id: string) =>
    update({
      item: {
        ...v,
        siteAppIds: v.siteApp.map((item) => item.id),
        languageId: v.language.id,
        displayOrder: +v.displayOrder,
      },
      id: id,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TFaqForm>> => {
      const { data, success } = await get(id);

      if (data) {
        const newData: TFaqForm = {
          ...data,
          siteApp: data.siteAppFaqs.map((item) => item.siteApp),
          displayOrder: data.displayOrder ? data.displayOrder.toString() : "",
        };
        return { data: newData, success };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TFaqForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "faq")}
      schema={faqSchema}
      subtitle={t(translationPath + "new_faq")}
      subtitleWatchField={"name"}
      fields={(type) => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            {type === "update" && (
              <Button
                size="small"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={(e) => {
                  navigate(
                    `/e/${eI}/dashboard/siteApp/faq/${id}/questions-answers`
                  );
                }}
                sx={{ mr: 2 }}
              >
                {t(translationPath + "questions_answers")}
              </Button>
            )}
            <SwitchInput name="enable" label={t(translationPath + "enable")} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="name"
              fullWidth
              label={t(translationPath + "name") || "Name"}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <FormInput
              size="small"
              name="displayOrder"
              fullWidth
              label={t(translationPath + "display_order") || "Display order"}
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <AutoComplete
              getOptionLabel={(option) => option.name}
              size="small"
              label={t(translationPath + "language")}
              name="language"
              request={languageService.list}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="whatsappPhoneNumber"
              fullWidth
              label={
                t(translationPath + "whatsapp_phone_number") ||
                "Whatsapp phone number"
              }
              mask={"(00)000000000"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="mail"
              fullWidth
              label={t(translationPath + "mail") || "Mail"}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <AutoCompleteMulti
              size="small"
              label={t(translationPath + "site_app")}
              name="siteApp"
              options={siteAppOptions}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default Faq;
