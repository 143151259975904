import React, { useEffect, useState } from "react";
import { useFetch } from "@4uhub/lib4uhub";

import { logsService } from "../../services/logs.service";
import LogsAccordions from "./LogsAccordions/LogsAccordions";
import { TLog, TParams } from "../../models/logs";

const Logs: React.FC<TParams> = (params) => {
  const [logs, setLogs] = useState<TLog[]>([]);
  const { sendRequest } = useFetch(logsService.getLogs);

  useEffect(() => {
    const loadRequest = async () => {
      const { data } = await sendRequest(params);
      if (data) {
        setLogs(data);
      }
    };

    loadRequest();
  }, [params, sendRequest]);

  return <LogsAccordions logs={logs} />;
};

export default Logs;
