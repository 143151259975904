import React from "react";
import { Tab, Tabs } from "@mui/material";
import { ITabsComponentProps } from "./models";

export const TabsComponent = ({
  value,
  onChange,
  tabs,
}: ITabsComponentProps) => {
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Tabs
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      scrollButtons={"auto"}
      allowScrollButtonsMobile
      variant="scrollable"
    >
      {tabs.map((tab) => (
        <Tab
          label={tab.label}
          {...a11yProps(tab.index)}
          key={tab.index}
          disabled={tab.disable ? true : false}
        />
      ))}
    </Tabs>
  );
};
