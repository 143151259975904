import { Popover, PopoverProps } from "@mui/material";
import { memo } from "react";

import useCalendar from "../CalendarContext/useCalendar";
import { ICalendar } from "../../model";

interface IEventPopver {
  popoverProps: PopoverProps;
  event: ICalendar;
}

const EventPopover: React.FC<IEventPopver> = ({ popoverProps, event }) => {
  const { renderPopOver } = useCalendar();

  return (
    <Popover
      open={popoverProps.open}
      onClose={popoverProps.onClose}
      id={popoverProps.id}
      anchorEl={popoverProps.anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: -5,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={(t) => ({ marginRight: t.spacing(2) })}
    >
      {renderPopOver(event, popoverProps.onClose)}
    </Popover>
  );
};

export default memo(EventPopover);
