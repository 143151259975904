import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import MainApiService from "../../../../../services/mainApi.service";
import {
  DefaultSettingSchema,
  TDefaultSchemaForm,
} from "./DefaultSettingSchema";
import { FormContainer, FormInput, useFetch } from "@4uhub/lib4uhub";
import { useParams } from "react-router-dom";
import { IDefaultSettings } from "../../../../../models/extension";
import { SwitchInput } from "../../../../../components/UI/Inputs/Switch";

const DEFAULT_SETTINGS_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/ExtensionDefaultConfig/";
const individualService = new MainApiService<IDefaultSettings>(
  DEFAULT_SETTINGS_ROUTE
);

const translationPath = "page.register.extensions.defaultSettings.";

const DefaultSettingsForm = () => {
  const { t } = useTranslation();
  const urlParams = useParams();

  const { sendRequest: save, loading: sLoading } = useFetch(
    individualService.create
  );
  const { sendRequest: update, loading: uLoading } = useFetch(
    individualService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    individualService.getItemById
  );

  const handleSave = (v: TDefaultSchemaForm) => {
    return save({
      ...v,
      extensionId: urlParams.extId || "",
    });
  };

  const handleUpdate = (v: TDefaultSchemaForm, id: string) => {
    return update({
      item: {
        ...v,
        extensionId: urlParams.extId || "",
      },
      id: id,
    });
  };

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TDefaultSchemaForm>
      loading={loading}
      title={t(translationPath + "default_setting", {})}
      saveFunction={handleSave}
      updateFunction={handleUpdate}
      getFunction={get}
      schema={DefaultSettingSchema}
      subtitle={t(translationPath + "new_default_setting")}
      subtitleWatchField={"identifier"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            <SwitchInput
              name="isPublic"
              label={t(translationPath + "is_public")}
              defaultValue={false}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="identifier"
              label={t(translationPath + "identifier")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="property"
              label={t(translationPath + "property")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="value"
              label={t(translationPath + "value")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="description"
              label={t(translationPath + "description")}
              fullWidth
              multiline
              minRows={4}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default DefaultSettingsForm;
