import {
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  Stack,
} from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useFetch } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";

import { IListComponent } from "../../../../models/dashboard";
import DashBoardService from "../../../../services/dashboard.service";
import { useDashboard } from "../../../../store/contexts/DashBoardContext";
import { usePanel } from "../../../../store/contexts/DashBoardPannelContext";
import Component from "./Component";

const service = new DashBoardService();

export interface IComponent extends IListComponent {
  order: number;
  dashBoardComponentId?: string;
}

const Components = () => {
  const [components, setComponents] = useState<IListComponent[]>([]);

  const { languageId } = usePanel();

  const { t } = useTranslation();

  const {
    dashboard: {
      dashboardRootId,
      dashboardEstablishmentComponents,
      id: dashboardId,
    },
    updateComponentEnable,
    onDragComponents,
  } = useDashboard();

  const { sendRequest } = useFetch(service.listComponents);

  const { sendRequest: updateComponentEnableRequest } = useFetch(
    service.updateComponentEnable
  );

  const { sendRequest: updateComponentsOrderRequest } = useFetch(
    service.updateComponentsOrder
  );

  const fetchComponents = useCallback(async () => {
    const { data, success } = await sendRequest({
      DashboardId: dashboardRootId,
      languageId: languageId,
    });

    if (success && data) {
      setComponents(data);
    }
  }, [languageId, dashboardRootId, sendRequest]);

  const orderedComponents = useMemo(
    () =>
      components.map((d) => {
        const dashBoardComponent = dashboardEstablishmentComponents.find(
          (dc) => dc.componentRootId === d.id
        );

        return {
          ...d,
          order: dashBoardComponent?.displayOrder || components.length + 1,
          dashBoardComponentId: dashBoardComponent?.id,
        };
      }),
    [dashboardEstablishmentComponents, components]
  );

  useEffect(() => {
    fetchComponents();
  }, [fetchComponents]);

  const onChangeHandler = useCallback(
    async (rootId: string, checked: boolean, componentId?: string) => {
      if (!componentId) return;
      const { success } = await updateComponentEnableRequest({
        id: componentId,
        enable: checked,
      });
      if (success) {
        updateComponentEnable(componentId, checked);
      }
    },
    [updateComponentEnableRequest, updateComponentEnable]
  );

  const showMesage = useMemo(
    () => dashboardEstablishmentComponents.filter(d => d.enable).length === 1,
    [dashboardEstablishmentComponents]
  );

  const onDragEnd: OnDragEndResponder = useCallback(
    async (e) => {
      const element = orderedComponents?.find((c) => e.draggableId === c.id);

      if (element) {
        if (e.destination) {
          orderedComponents.splice(e.source.index, 1);

          orderedComponents.splice(e.destination.index, 0, element);

          orderedComponents.forEach((t, i) => {
            t.order = i + 1;
          });

          setComponents(orderedComponents);

          onDragComponents(orderedComponents);

          updateComponentsOrderRequest({
            dashboardEstablishmentComponentIds: orderedComponents
              .filter((c) => c.dashBoardComponentId)
              .map((c) => c.dashBoardComponentId),
            dashboardEstablishmentId: dashboardId,
          });
        }
      }
    },
    [
      dashboardId,
      orderedComponents,
      onDragComponents,
      updateComponentsOrderRequest,
    ]
  );

  return (
    <Stack gap={2} padding={2}>
      <FormControl>
        <FormLabel component="legend">{t("components.panel.select")}</FormLabel>

        <FormGroup>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="componentsList" direction="vertical">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {orderedComponents
                    .sort((a, b) => a.order - b.order)
                    .map((c, index) => (
                      <Component
                        key={c.id}
                        index={index}
                        data={c}
                        actualEstablishments={dashboardEstablishmentComponents}
                        onChange={onChangeHandler}
                      />
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </FormGroup>
        {showMesage && (
          <FormHelperText>{t("components.panel.helper_text")}</FormHelperText>
        )}
      </FormControl>
    </Stack>
  );
};
export default memo(Components);
