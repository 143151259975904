import { GlobalAxios } from "../axios/axios-config";
import { IEventType, INewEventType } from "../models/event-type";

const EVENT_TYPE_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketEventType/";

export const createEventType = (item: INewEventType) => {
  return GlobalAxios.post(EVENT_TYPE_ROUTE, item);
};

export const getEventTypeById = (id: string) => {
  return GlobalAxios.get<IEventType>(EVENT_TYPE_ROUTE + id);
};

export const updateEventTypeById = ({
  item,
  id,
}: {
  item: INewEventType;
  id: string;
}) => {
  return GlobalAxios.put(EVENT_TYPE_ROUTE + id, item);
};
