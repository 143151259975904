import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "@4uhub/lib4uhub";

import { IGeneric } from "../../../models/generics";
import MainApiService from "../../../services/mainApi.service";

const GENERICS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Language/";
const TRANSLATION_PATH = "page.register.languages.";

const genericService = new MainApiService<IGeneric>(GENERICS_ROUTE);

const LanguagesPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columns: GridColDef<IGeneric>[] = useMemo(
    () => [
      {
        field: "name",
        headerName: t(TRANSLATION_PATH + "name") || "Name",
      },
      {
        field: "code",
        headerName: t(TRANSLATION_PATH + "code") || "Code",
      },
    ],
    [t]
  );

  const handleRowClick = (params: GridRowParams<IGeneric>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<IGeneric>
      showDefaultMacroFilters={false}
      service={genericService}
      columns={columns}
      title={t(TRANSLATION_PATH + "languages") || "Languages"}
      searchInput
      showButton={false}
      defaultActions={false}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={
        t(TRANSLATION_PATH + "search_language") || "Searh for a language"
      }
      addButtonLabel={t(TRANSLATION_PATH + "add_language") || "Add Language"}
      confirmOptions={{
        title: t(TRANSLATION_PATH + "confirm.title"),
        description: t(TRANSLATION_PATH + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default LanguagesPage;
