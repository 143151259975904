import { GlobalAxios } from "../../../../axios/axios-config";
import {
  IInsurance,
  IInsuranceFavorite,
  IInsuranceFavoriteBase,
} from "./model";

const TASY_INSURANCE_ROUTE =
  process.env.REACT_APP_CALENDAR + "/api/v1/IndividualTasyInsuranceFavorite";

const INSURANCE_ROUTE =
  process.env.REACT_APP_CALENDAR + "/api/v1/tasy/InsuranceRegister";

export class ScheduleInsuranceService {
  public static list = (params?: { IndividualId: string }) => {
    return GlobalAxios.get<IInsuranceFavorite[]>(
      TASY_INSURANCE_ROUTE + "/list",
      { params }
    );
  };

  public static delete = (id: string) => {
    return GlobalAxios.delete<IInsuranceFavorite[]>(
      TASY_INSURANCE_ROUTE + `/${id}`
    );
  };

  public static getById = (id: string) => {
    return GlobalAxios.get<IInsuranceFavorite>(TASY_INSURANCE_ROUTE + `/${id}`);
  };

  public static edit = ({
    data,
    id,
  }: {
    id: string;
    data: IInsuranceFavoriteBase;
  }) => {
    return GlobalAxios.put<IInsuranceFavorite>(
      TASY_INSURANCE_ROUTE + `/${id}`,
      { ...data, id }
    );
  };

  public static create = (params: IInsuranceFavoriteBase) => {
    return GlobalAxios.post<{ id: string }>(TASY_INSURANCE_ROUTE, params);
  };

  public static all = () => {
    return GlobalAxios.get<IInsurance[]>(INSURANCE_ROUTE + "/all");
  };
}
