import { z } from "zod";

const translationPath = "page.settings.crm.ticketAutoFinishRules.errors.";

const ticketTypeSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "ticket_type" }
);

const ticketChannelStepSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  {
    invalid_type_error: translationPath + "ticket_channel_step",
    required_error: translationPath + "ticket_channel_step",
  }
);

const ticketChannelSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "ticket_channel" }
);

const ticketWorkspaceSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

export const ticketAutoFinishRuleSchema = z
  .object({
    id: z.string().optional(),
    finish: z.boolean(),
    ticketType: ticketTypeSchema,
    ticketChannelStep: ticketChannelStepSchema.nullable(),
    ticketChannel: ticketChannelSchema
      .nullable()
      .refine((val) => val, translationPath + "ticket_channel"),
    ticketWorkspace: ticketWorkspaceSchema
      .optional()
      .nullable()
      .refine((val) => val, translationPath + "ticket_workspace"),
    initialDate: z.date({
      invalid_type_error: translationPath + "initial_date",
    }),
    endDate: z.date({ invalid_type_error: translationPath + "end_date" }),
    minutes: z
      .string()
      .refine((val) => val || val.length !== 0, translationPath + "minutes")
      .refine((val) => Number(val) <= 1440, translationPath + "max_minutes"),
    message: z.string().optional(),
  })
  .superRefine((val, ctx) => {
    if (val.finish) {
      if (!val.message || val.message.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["message"],
          message: translationPath + "message",
        });
      }
    }
    if (val.endDate < val.initialDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["endDate"],
        message: translationPath + "end_date_less",
      });
    }
  });

export type TTicketAutoFinishRuleForm = z.infer<
  typeof ticketAutoFinishRuleSchema
>;
