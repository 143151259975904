import { useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Fields, { IFieldsRef } from "./Fields";
import { FormContainer,  Treturn, useFetch } from "@4uhub/lib4uhub";

import { TTicketServiceTypebotForm, ticketServiceTypebotSchema } from "./TicketServiceTypeBotSchema";
import { createTypebotConfig, getTypebotConfigById, updateTypebotConfig } from "../../../../../../services/ticketServiceTypeBot.service";
import { applyTranslations } from "./typebot.utils";


const TicketServiceTypeBot = () => {
  const fieldsRef = useRef<IFieldsRef>(null);

  const { t } = useTranslation();
  const { ticketServiceId } = useParams();
  const { sendRequest: find, loading: isFetching } = useFetch(getTypebotConfigById);
  const { sendRequest: create, loading: isCreating } = useFetch(createTypebotConfig);
  const { sendRequest: update, loading: isUpdating } = useFetch(updateTypebotConfig);

  const onCreate = (values: TTicketServiceTypebotForm) => {
    return create({
      ...values,
      ticketServiceId: ticketServiceId!,
    });
  };

  const onUpdate = ({ typebotViewerHost }: TTicketServiceTypebotForm, id: string) => {
    return update({
      id,
      item: {
        id,
        typebotViewerHost,
      },
    });
  };

  const onFind = useCallback(
    async (id: string): Promise<Treturn<TTicketServiceTypebotForm>> => {
      const { data, success } = await find(id);

      if (data) {
        const { ticketService: { verifyToken: token } = {}, ...rest } = data;

        if (token) fieldsRef?.current?.setVerifyToken?.(token);

        return { data: rest, success: true };
      }

      return { data, success };
    },
    [find]
  );

  const isLoading = isCreating || isUpdating || isFetching;

  return (
    <FormContainer<TTicketServiceTypebotForm>
      getFunction={onFind}
      saveFunction={onCreate}
      updateFunction={onUpdate}
      loading={isLoading}
      title={t(applyTranslations("ticket_service_typebot"))}
      schema={ticketServiceTypebotSchema}
      subtitle={t(applyTranslations("new_ticket_service_typebot"))}
      subtitleWatchField={"typebotViewerHost"}
      fields={() => <Fields  />}
    />
  );
};

export default TicketServiceTypeBot;
