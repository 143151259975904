import { GlobalAxios } from "../axios/axios-config";
import {
  IEvaluationForm,
  IEvaluationFormList,
  IGetEvaluationForm,
} from "../models/evaluation-forms";

const EVALUATION_FORM_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/EvaluationForm/";

export const evaluationFormList = (params?: any) => {
  return GlobalAxios.get<IEvaluationFormList[]>(
    EVALUATION_FORM_ROUTE + "list",
    {
      params: params,
    }
  );
};

export const createEvaluationForm = (item: IEvaluationForm) => {
  return GlobalAxios.post(EVALUATION_FORM_ROUTE, item);
};

export const getEvaluationFormById = (id: string) => {
  return GlobalAxios.get<IGetEvaluationForm>(EVALUATION_FORM_ROUTE + id);
};

export const updateEvaluationFormById = ({
  item,
  id,
}: {
  item: IEvaluationForm;
  id: string;
}) => {
  return GlobalAxios.put(EVALUATION_FORM_ROUTE + id, item);
};
