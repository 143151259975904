import { Grid } from "@mui/material";
import { useFieldArray } from "react-hook-form";

import {
  FormArray,
  IInputFile,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import MainApiService from "../../../../../services/mainApi.service";

import { IGeneric } from "../../../../../models/generics";
import { TRegisterForm } from "../RegisterSchema";
import SiteAppFile from "./SiteAppFile";

const GENERICS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const service = new MainApiService<IGeneric>(GENERICS_ROUTE);

const translationPath = "page.register.extensions.";

const SiteAppFiles = ({
  onDeleteFileHandler,
  disable = false,
}: {
  onDeleteFileHandler: (file: IInputFile) => void;
  disable?: boolean;
}) => {
  const { t } = useTranslation();
  const { control, formState } = useFormContext<TRegisterForm>();
  const [mediaTypeList, setMediaTypeList] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(service.list);

  const loadMediaTypeList = useCallback(async () => {
    const { data } = await sendRequest({
      identifier: "SiteAppMediaType",
    });
    if (data) {
      setMediaTypeList(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    loadMediaTypeList();
  }, [loadMediaTypeList]);

  const extensionMediasArray = useFieldArray<
    TRegisterForm,
    "siteAppMediaFiles",
    "key"
  >({
    control,
    name: "siteAppMediaFiles",
    keyName: "key",
  });

  const fieldsObject = useCallback(
    (index: number) => (
      <SiteAppFile
        onFileSelected={onDeleteFileHandler}
        mediaTypeList={mediaTypeList}
        index={index}
        disable={disable}
      />
    ),
    [mediaTypeList, onDeleteFileHandler, disable]
  );

  const onDeleteVoidHandler = (data: any) => {
    if (data.file) {
      onDeleteFileHandler(data.file);
    }
  };

  return (
    <Grid item xs={12}>
      <FormArray
        title={t(translationPath + "media_files")}
        name="siteAppMediaFiles"
        errorMessage={formState.errors?.siteAppMediaFiles?.message}
        addButtonLabel={`${t(translationPath + "add_media_file")}`}
        formArray={extensionMediasArray}
        onDeleteVoid={onDeleteVoidHandler}
        showAddButton={disable ? false : true}
        showDeleteButton={disable ? false : true}
        appendValue={{
          mediaType: mediaTypeList
            ? mediaTypeList[0]
            : { id: "", name: "", code: "" },
          file: {
            id: "",
          },
        }}
        fieldsObject={fieldsObject}
      />
    </Grid>
  );
};

export default SiteAppFiles;
