import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";
import { TLanguagesForm } from "./LanguagesSchema";
import {
  AccessRegister,
  EstablishmentAutoComplete,
  useFormContext,
} from "@4uhub/lib4uhub";
import { IEstablishment } from "@4uhub/lib4uhub";

interface ILanguagesEstablishmentsProps {
  establishmentsList: IEstablishment[];
}

const LanguagesEstablishments = ({
  establishmentsList,
}: ILanguagesEstablishmentsProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<TLanguagesForm>();

  const establishments = useWatch({
    name: "establishments",
    control,
  });

  return (
    <Grid item xs={12} sm={12}>
      <AccessRegister
        redirectTo="dashboard/admSystem/establishments"
        domainName="establishment"
        inputValue={
          establishments
            ? establishments.map((establishment) => ({
                id: establishment.id,
                name: establishment.name,
              }))
            : []
        }
        tooltip={t("page.register.languages.establishment_tooltip")}
      >
        <EstablishmentAutoComplete
          multiple
          name={"establishments"}
          label={
            t("page.register.languages.establishments") || "Establishments"
          }
          establishments={establishmentsList}
        />
      </AccessRegister>
    </Grid>
  );
};

export default LanguagesEstablishments;
