import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Chip } from "@mui/material";
import { Table } from "@4uhub/lib4uhub";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";

import MainApi from "../../../../services/mainApi.service";
import { buildTPath } from "./beneficiary-classifications.utils";
import { IBeneficiaryClassification } from "../../../../models/beneficiary-classification";

const serviceURL =
  process.env.REACT_APP_MAIN +
  "/api/v1/SalesContractBeneficiaryClassification/";
  
const service = new MainApi<IBeneficiaryClassification>(serviceURL);

const BeneficiaryClassificationsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const translate = (path: string): string => t(buildTPath(path));

  const columns: GridColDef<IBeneficiaryClassification>[] = [
    {
      field: "name",
      headerName: t(buildTPath("name"))!,
    },
    {
      field: "enable",
      maxWidth: 100,
      headerName: t(buildTPath("status"))!,
      renderCell: ({ value }) => (
        <Chip
          size="small"
          color={value ? "success" : "error"}
          label={value ? t(buildTPath("active")) : t(buildTPath("inactive"))}
        />
      ),
    },
  ];

  const handleRowClick = ({
    id,
  }: GridRowParams<IBeneficiaryClassification>) => {
    navigate(`${id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  return (
    <Table<IBeneficiaryClassification>
      showDefaultMacroFilters={false}
      service={service}
      columns={columns}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      title={translate("title")}
      addButtonLabel={translate("add")}
      searchInputPlaceHolder={translate("search")}
      confirmOptions={{
        title: translate("confirm.title"),
        description: translate("confirm.description"),
        confirmationButtonProps: { variant: "contained" },
      }}
    />
  );
};

export default BeneficiaryClassificationsPage;
