import { GlobalAxios } from "./../axios/axios-config";
import {
  INewScheduleCheckIn,
  IScheduleCheckIn,
} from "../models/schedule-check-in";

const SCHEDULE_CONFIRMATION_ROUTE =
  process.env.REACT_APP_CALENDAR + "/api/v1/TasySchedCheckInRule";

export const createScheduleCheckIn = (item: INewScheduleCheckIn) => {
  return GlobalAxios.post(SCHEDULE_CONFIRMATION_ROUTE, item);
};

export const getScheduleCheckInById = (id: string) => {
  return GlobalAxios.get<IScheduleCheckIn>(
    SCHEDULE_CONFIRMATION_ROUTE + "/" + id
  );
};

export const updateScheduleCheckInById = ({
  item,
  id,
}: {
  item: INewScheduleCheckIn;
  id: string;
}) => {
  return GlobalAxios.put(SCHEDULE_CONFIRMATION_ROUTE + "/" + id, item);
};
