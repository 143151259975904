import { Stack, Typography } from "@mui/material";
import { memo } from "react";

import { IListIDashboard } from "../../../../models/dashboard";

interface IDashBoardModalItemProps {
  onClick: (id: string) => void;
  data: IListIDashboard;
}

const DashBoardModalItem: React.FC<IDashBoardModalItemProps> = ({
  onClick,
  data,
}) => {
    
  const onClickHandler = () => {
    onClick(data.id);
  };

  return (
    <Stack
      padding={2}
      direction={"row"}
      gap={2}
      sx={(t) => ({
        cursor: "pointer",
        borderRadius: 1,
        "&:hover": {
          backgroundColor: t.palette.divider,
        },
      })}
      key={data.id}
      onClick={onClickHandler}
    >
      <Typography>{data.name}</Typography>
    </Stack>
  );
};

export default memo(DashBoardModalItem);
