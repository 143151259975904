import ResetPasswordForm from "../../../components/ResetPasswordForm/ResetPasswordForm";
import CardContainer from "../../../components/CardContainer/CardContainer";

const ResetPasswordPage: React.FC = () => {
  return (
    <CardContainer>
      <ResetPasswordForm />
    </CardContainer>
  );
};

export default ResetPasswordPage;
