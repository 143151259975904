import { Grid } from "@mui/material";
import { memo, useMemo } from "react";

import DashBoardComponent from "./DashBoardComponent";
import { useDashboard } from "../../../../store/contexts/DashBoardContext";

const ComponentsList: React.FC<{ width: number }> = ({ width }) => {
  const {
    dashboard: { dashboardEstablishmentComponents },
  } = useDashboard();

  const filtered = useMemo(
    () =>
      dashboardEstablishmentComponents
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .filter((d) => d.enable),
    [dashboardEstablishmentComponents]
  );
  
  return (
    <Grid container gap={2}>
      {filtered.map((c) => (
        <DashBoardComponent data={c} key={c.id} />
      ))}
    </Grid>
  );
};
export default memo(ComponentsList);
