import { Grid } from "@mui/material";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  FormArray,
  ISelectType,
  MainApiService,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { useCallback, useEffect, useState } from "react";

import { TIndividualForm } from "./IndividualSchema";
import IndividualContact from "./individualContact";
import { IGeneric } from "../../../../models/generics";

import { GlobalAxios } from "../../../../axios/axios-config";

const translation_path = "page.register.individuals.";

const GENERICS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const service = new MainApiService<IGeneric>(GENERICS_ROUTE, GlobalAxios);

const LegalEntityContacts: React.FC = () => {
  const { t } = useTranslation();
  const { control, formState } = useFormContext<TIndividualForm>();
  const [contactTypeList, setContactTypeList] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(service.list);

  const loadContactTypeList = useCallback(async () => {
    const { data } = await sendRequest({ identifier: "ContactType" });
    if (data) {
      setContactTypeList(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    loadContactTypeList();
  }, [loadContactTypeList]);

  const contactsFieldArray = useFieldArray<TIndividualForm, "contacts", "key">({
    control,
    name: "contacts",
    keyName: "key",
  });

  const fieldsObject = useCallback(
    (index: number) => (
      <IndividualContact contactTypeList={contactTypeList} index={index} />
    ),
    [contactTypeList]
  );

  return (
    <Grid item xs={12}>
      <FormArray
        title={t(translation_path + "contacts") || "Contacts"}
        name="contacts"
        errorMessage={formState.errors?.contacts?.message}
        addButtonLabel={t(translation_path + "add_contact") || "Add Contact"}
        formArray={contactsFieldArray}
        appendValue={{
          id: "",
          contactTypeId: "",
          otherType: "",
          contactType: contactTypeList
            ? contactTypeList[0]
            : { id: "", name: "", code: "" },
          value: "",
        }}
        fieldsObject={fieldsObject}
      />
    </Grid>
  );
};

export default LegalEntityContacts;
