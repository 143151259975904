import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";
import { DateInput } from "@4uhub/lib4uhub";

import { buildTPath } from "../../advantages-and-benefits-club.utils";

const Dates = (): ReactElement => {
  const { t } = useTranslation();

  const translate = (field: string) => t(buildTPath(field));

  return (
    <>
      <Grid item xs={12} sm={6}>
        <DateInput name="contractDate" label={translate("contratation_date")} />
      </Grid>

      {/* <Grid item xs={12} sm={6}>
        <DateInput
          name="terminationDate"
          label={translate("termination_date")}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <DateInput name="approvalDate" label={translate("approval_date")} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <DateInput
          name="cancelationDate"
          label={translate("cancelation_date")}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <DateInput
          name="reactivationDate"
          label={translate("reactivation_date")}
        />
      </Grid> */}
    </>
  );
};

export default Dates;
