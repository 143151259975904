import { z } from "zod";

const translationPath = "chatBot.page.templates.whatsapp.errors.";

const customButtonSchema = z.object({
  id: z.string().nullable(),
  order: z.number(),
  text: z
    .string()
    .min(1, translationPath + "custom_button_text")
    .max(25, translationPath + "custom_button_text_max"),
});

const actionTypeSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  value: z.string().optional(),
});

const urlTypeSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
    description: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "url_type" }
);

const countrySchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string(),
    ddiCode: z.string(),
    phoneMask: z.string(),
  },
  { invalid_type_error: translationPath + "country" }
);

const ctaButtonSchema = z
  .object({
    id: z.string().optional(),
    order: z.number(),
    actionType: actionTypeSchema,
    text: z
      .string()
      .min(1, translationPath + "text")
      .max(25, translationPath + "text_max"),
    urlType: urlTypeSchema.optional(),
    country: countrySchema.optional(),
    payload: z.string().optional(),
  })
  .superRefine((val, ctx) => {
    if (val.actionType.id !== "4") {
      if (!val.payload || val.payload.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: translationPath + "payload",
          path: ["payload"],
        });
      }
    }
  });

const ticketServiceSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "ticket_service" }
);

const ticketAutomationSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "ticket_automation" }
);

const bodyExampleSchema = z.object({
  example: z.string().min(1, translationPath + "example"),
});

export const whatsappSchema = z.object({
  id: z.string().optional(),
  enable: z.boolean(),
  name: z.string().min(1, translationPath + "name"),
  ticketService: ticketServiceSchema,
  ticketAutomation: ticketAutomationSchema,
  title: z.string().optional(),
  body: z
    .string()
    .min(1, translationPath + "body")
    .max(1024, translationPath + "body_max"),
  footer: z.string().optional(),
  customButtons: z.array(customButtonSchema).optional(),
  ctaButtons: z.array(ctaButtonSchema).optional(),
  bodyExamples: z.array(bodyExampleSchema).optional(),
});

export type TWhatsappForm = z.infer<typeof whatsappSchema>;
