import {
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNotificationContext } from "@4uhub/lib4uhub";
import { useCallback } from "react";

const WhatsConfigData = ({
  data,
  tooltipTilte,
}: {
  data: string;
  tooltipTilte: string;
}) => {
  const { setMessage } = useNotificationContext();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const copyToClippboard = useCallback(() => {
    navigator.clipboard.writeText(data.replace(/\s/g, ""));
    setMessage({
      message: "Copiado para a área de transferência",
      notificationKey: new Date().getMilliseconds(),
      type: "success",
    });
  }, [setMessage, data]);

  return (
    <Stack display={"flex"} flexDirection={"row"} alignItems={"center"} gap={1}>
      <Typography
        sx={{
          width: "100%",
          maxWidth: matches ? 190 : undefined,
          overflowWrap: "break-word",
        }}
      >
        {data}
      </Typography>
      <Tooltip title={tooltipTilte}>
        <IconButton size="small" onClick={copyToClippboard}>
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default WhatsConfigData;
