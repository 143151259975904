import { FormEmojiTextInput } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const translationPath = "page.settings.crm.protocolsDefault.";

const ProtocolMessage = () => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={12} display={"flex"} alignItems={"start"}>
      <FormEmojiTextInput
        size="small"
        name="message"
        label={t(translationPath + "message")}
        fullWidth
        multiline
        minRows={2}
      />
    </Grid>
  );
};

export default ProtocolMessage;
