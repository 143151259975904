import { Grid } from "@mui/material";
import { useFieldArray } from "react-hook-form";

import { useTranslation } from "react-i18next";
import {
  AddressForm,
  FormArray,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { useCallback, useEffect, useState } from "react";

import { TLegalEntityForm } from "./LegalEntitySchema";
import { IGeneric } from "../../../../models/generics";
import MainApiService from "../../../../services/mainApi.service";
import ExternalService from "../../../../services/external.service";

const translation_path = "page.register.legalPerson.";
const GENERICS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const NEIGHBORHOOD_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Neighborhood/";

const service = new MainApiService<IGeneric>(GENERICS_ROUTE);

const neighborhoodService = new MainApiService<ISelectType>(NEIGHBORHOOD_ROUTE);
const cepService = new ExternalService();

const LegalEntityAdrresses: React.FC = () => {
  const { control, formState } = useFormContext<TLegalEntityForm>();
  const { t } = useTranslation();

  const [addressesTypeList, setAddressesTypeList] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(service.list);

  const loadAddressTypes = useCallback(async () => {
    const { data } = await sendRequest({ identifier: "AddressType" });
    if (data) {
      setAddressesTypeList(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    loadAddressTypes();
  }, [loadAddressTypes]);

  const addressesFieldArray = useFieldArray<TLegalEntityForm>({
    control,
    name: "addressess",
  });

  return (
    <Grid item xs={12}>
      <FormArray
        title={t(translation_path + "addresses")}
        name="addressess"
        errorMessage={formState.errors?.addressess?.message}
        addButtonLabel={t(translation_path + "add_address") || "Add"}
        formArray={addressesFieldArray}
        appendValue={{
          addressType: addressesTypeList
            ? addressesTypeList[0]
            : { id: "", name: "", code: "" },
          otherType: "",
          zipCode: "",
          otherNeighborhood: false,
          addressDescription: "",
          neighborhoodName: "",
          number: "",
          complement: "",
        }}
        fieldsObject={(index) => {
          const addressType = `addressess.${index}.addressType`;
          const zipCode = `addressess.${index}.zipCode`;
          const city = `addressess.${index}.city`;
          const neighborhood = `addressess.${index}.neighborhood`;
          const otherNeighborhood = `addressess.${index}.otherNeighborhood`;
          const provincy = `addressess.${index}.provincy`;
          const addressDescription = `addressess.${index}.addressDescription`;
          const neighborhoodName = `addressess.${index}.neighborhoodName`;
          const number = `addressess.${index}.number`;
          const complement = `addressess.${index}.complement`;

          return (
            <AddressForm
              cepRequest={cepService.getCep}
              neighborhoodRequest={neighborhoodService.list}
              addressesTypeList={addressesTypeList}
              addressType={addressType}
              zipCode={zipCode}
              city={city}
              neighborhood={neighborhood}
              otherNeighborhood={otherNeighborhood}
              provincy={provincy}
              addressDescription={addressDescription}
              neighborhoodName={neighborhoodName}
              number={number}
              complement={complement}
            />
          );
        }}
      />
    </Grid>
  );
};

export default LegalEntityAdrresses;
