import { Link, Paper } from "@mui/material";
import styles from "./CardContainer.module.scss";

type TCardContainer = {
  children: any;
  link?: string;
  textLink?: string;
};

const CardContainer: React.FC<TCardContainer> = ({
  children,
  link,
  textLink,
}) => {
  return (
    <div
      className={styles.container}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Paper className={styles["card-container"]}>
        <img src="/logo_4uHub.png" className={styles.logo} alt="4uhub logo" />
        {children}
      </Paper>
      {link && (
        <Link href={link} underline="none">
          {textLink}
        </Link>
      )}
    </div>
  );
};

export default CardContainer;
