import { z } from "zod";

const translationPath = "page.settings.preventMedicine.workGroupTypes.errors.";

const language = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional(),
  },
  { invalid_type_error: translationPath + "language" }
);

const healthProgramWorkGroupTypeLanguageSchema = z.object({
  name: z.string().min(1, translationPath + "name"),
  description: z.string().optional().nullable(),
  language: language,
});

export const workGroupTypeSchema = z.object({
  id: z.string().optional(),
  enable: z.boolean().optional(),
  healthProgramWorkGroupTypeLanguages: z.array(
    healthProgramWorkGroupTypeLanguageSchema
  ),
});

export type TWorkGroupTypeForm = z.infer<typeof workGroupTypeSchema>;
