import { GlobalAxios } from "./../axios/axios-config";
import {
  INewScheduleConfirmation,
  IScheduleConfirmation,
} from "../models/schedule-confirmation";

const SCHEDULE_CONFIRMATION_ROUTE =
  process.env.REACT_APP_CALENDAR + "/api/v1/TasySchedConfirmationRules";

export const createScheduleConfirmation = (item: INewScheduleConfirmation) => {
  return GlobalAxios.post(SCHEDULE_CONFIRMATION_ROUTE, item);
};

export const getScheduleConfirmationById = (id: string) => {
  return GlobalAxios.get<IScheduleConfirmation>(
    SCHEDULE_CONFIRMATION_ROUTE + "/" + id
  );
};

export const updateScheduleConfirmationById = ({
  item,
  id,
}: {
  item: INewScheduleConfirmation;
  id: string;
}) => {
  return GlobalAxios.put(SCHEDULE_CONFIRMATION_ROUTE + "/" + id, item);
};
