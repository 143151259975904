import {
  AutoComplete,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";
import { TTicketAutoFinishRuleForm } from "./TicketAutoFinishRuleSchema";
import { ticketChannelsByWorkspace } from "../../../../../../services/ticketChannelStep.service";
import { useCallback, useEffect, useState } from "react";

const translationPath = "page.settings.crm.ticketAutoFinishRules.";

const TicketChannel = () => {
  const { t } = useTranslation();

  const [ticketChannelOptions, setTicketChannelOptions] = useState<
    ISelectType[]
  >([]);

  const { control, setValue } = useFormContext<TTicketAutoFinishRuleForm>();

  const ticketWorkspace = useWatch({
    name: "ticketWorkspace",
    control,
  });

  const { sendRequest } = useFetch(ticketChannelsByWorkspace);

  const fetchTicketChannels = useCallback(async () => {
    if (!ticketWorkspace) return;
    const { data, success } = await sendRequest(ticketWorkspace.id);
    if (data && success) {
      setTicketChannelOptions(data);
    }
  }, [sendRequest, ticketWorkspace]);

  useEffect(() => {
    fetchTicketChannels();
  }, [fetchTicketChannels]);

  return (
    <Grid item xs={12} sm={6}>
      <AutoComplete
        getOptionLabel={(option) => {
          return option.name;
        }}
        size="small"
        label={t(translationPath + "ticket_channel")}
        name="ticketChannel"
        options={ticketChannelOptions}
        onValueChange={() => {
          setValue("ticketChannelStep", null);
        }}
      />
    </Grid>
  );
};

export default TicketChannel;
