import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { memo } from "react";

import {
  FormTransferList,
  IPrivilegesDomain,
  ISelectType,
  MainApiService,
} from "@4uhub/lib4uhub";

import { GlobalAxios } from "../../../../axios/axios-config";
import PrivilegesService from "../../../../services/privileges.service";

const SOFTWARE_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Software/";

const softwareList = new MainApiService<ISelectType>(
  SOFTWARE_ROUTE,
  GlobalAxios
);

const service = new PrivilegesService();

const RolesPrivileges: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} mb={5}>
      <Typography variant="h6" mb={1}>
        {t("page.register.roles.privileges")}
      </Typography>
      <Grid item xs={12} sm={12}>
        <FormTransferList<IPrivilegesDomain>
          request={service.list}
          name="privileges"
          filterRequest={softwareList.list}
          selectFilterLabel={`${t("page.register.roles.select_software")}`}
          sections={(items) => {
            const uniqueSoftwares: ISelectType[] = [];
            items
              .flatMap((item) => item.domain.softwares)
              .forEach((software) => {
                if (
                  !uniqueSoftwares.some(
                    (uniqueSoftware) => uniqueSoftware.id === software.id
                  )
                ) {
                  uniqueSoftwares.push(software);
                }
              });
            return uniqueSoftwares;
          }}
          onSeparate={(item, option) =>
            item.domain.softwares.some((soft) => soft.id === option.id)
          }
        />
      </Grid>
    </Grid>
  );
};

export default memo(RolesPrivileges);
