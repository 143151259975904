import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import { useCallback } from "react";
import { FormContainer, Treturn, useFetch } from "@4uhub/lib4uhub";

import IndividualBasicData from "./IndividualBasicData";
import IndividualContacts from "./IndividualContacts";
import IndividualAdresses from "./IndividualAddresses";
import { IndividualSchema, TIndividualForm } from "./IndividualSchema";
import IndividualsService from "../../../../services/individuals.service";
import { add } from "date-fns";
import FamilyGroup from "./FamilyGroup";

const individualService = new IndividualsService();

const Individual = () => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(
    individualService.create
  );
  const { sendRequest: update, loading: uLoading } = useFetch(
    individualService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    individualService.getItemById
  );

  const handleGet = useCallback(
    async (id: string): Promise<Treturn<TIndividualForm>> => {
      const { data, success } = await get(id);

      if (data) {
        return {
          data: {
            ...data,
            birthDate: add(new Date(data.birthDate), { days: 1 }),
            familyGroup: data.familyGroup
              ? data.familyGroup.map((familyGroup) => ({
                  individual: {
                    id: familyGroup.individual.id,
                    individualName: familyGroup.individual.name,
                    document: familyGroup.individual.document,
                  },
                  kinshipDegree: familyGroup.kinshipDegree,
                }))
              : [],
            addressess: data.addressess.map((address) => ({
              id: address.id,
              addressType: address.addressType,
              otherType: address.otherType,
              otherNeighborhood: address.neighborhood ? false : true,
              zipCode:
                address.zipCode.substring(0, 5) +
                "-" +
                address.zipCode.substring(5),
              city: address.city,
              provincy: address.city.provincy,
              addressDescription: address.addressDescription,
              neighborhoodName: address.neighborhoodName
                ? address.neighborhoodName
                : null,
              neighborhood: address.neighborhood ? address.neighborhood : null,
              number: address.number || undefined,
              complement: address.complement || undefined,
            })),
          },
          success,
        };
      }
      return {
        data: null,
        success: false,
      };
    },
    [get]
  );

  const handleSave = (v: TIndividualForm) => {
    v.birthDate.setHours(0, 0, 0, 0);

    return save({
      ...v,
      identityCard: v.identityCard || null,
      foreigner: v.foreigner,
      birthDate: v.birthDate.toISOString().substring(0, 10),
      country: v.country || null,
      genderId: v.gender.id,
      contacts: v.contacts.map((contact) => ({
        contactTypeId: contact.contactType.id,
        value: contact.value,
        otherType: contact.otherType || null,
      })),
      addressess: v.addressess.map((address) => ({
        cityId: address.city?.id || "",
        neighborhoodId: !address.otherNeighborhood
          ? address.neighborhood?.id
          : null,
        addressTypeId: address.addressType.id,
        otherType: address.otherType || null,
        zipCode: address.zipCode.replaceAll("-", ""),
        addressDescription: address.addressDescription,
        neighborhoodName: address.otherNeighborhood
          ? address.neighborhoodName
          : null,
        number: address.number || null,
        complement: address.complement,
      })),
      familyGroups: v.familyGroup
        ? v.familyGroup.map((familyGroup) => ({
            individualId: familyGroup.individual?.id ?? "",
            kinshipDegreeId: familyGroup.kinshipDegree?.id ?? "",
          }))
        : undefined,
    });
  };

  const handleUpdate = (v: TIndividualForm, id: string) => {
    v.birthDate.setHours(0, 0, 0, 0);

    return update({
      item: {
        ...v,
        identityCard: v.identityCard || null,
        foreigner: v.foreigner,
        birthDate: v.birthDate.toISOString().substring(0, 10),
        country: v.country || null,
        genderId: v.gender.id,
        contacts: v.contacts.map((contact) => ({
          contactTypeId: contact.contactType.id,
          value: contact.value,
          otherType: contact.otherType || null,
        })),
        addressess: v.addressess.map((address) => ({
          cityId: address.city?.id || "",
          neighborhoodId: !address.otherNeighborhood
            ? address.neighborhood?.id
            : undefined,
          addressTypeId: address.addressType.id,
          otherType: address.otherType || null,
          zipCode: address.zipCode.replaceAll("-", ""),
          addressDescription: address.addressDescription,
          neighborhoodName: address.otherNeighborhood
            ? address.neighborhoodName
            : undefined,
          number: address.number || null,
          complement: address.complement,
        })),
        familyGroups: v.familyGroup
          ? v.familyGroup.map((familyGroup) => ({
              individualId: familyGroup.individual?.id ?? "",
              kinshipDegreeId: familyGroup.kinshipDegree?.id ?? "",
            }))
          : undefined,
      },
      id: id,
    });
  };

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TIndividualForm>
      loading={loading}
      title={t("page.register.individuals.individual")}
      saveFunction={handleSave}
      updateFunction={handleUpdate}
      getFunction={handleGet}
      schema={IndividualSchema}
      subtitle={t("page.register.individuals.new_individual")}
      subtitleWatchField={"name"}
      defaultValues={{
        addressess: [],
      }}
      fields={() => (
        <Grid container spacing={2}>
          {/* Basic Data */}
          <IndividualBasicData />
          {/* Contacts */}
          <IndividualContacts />
          {/* Addresses */}
          <IndividualAdresses />
          {/* Family Group */}
          <FamilyGroup />
        </Grid>
      )}
    />
  );
};

export default Individual;
