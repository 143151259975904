import { GlobalAxios } from "../axios/axios-config";
import { ITicketAutomationMessage } from "../models/ticket-automation-message";

const TICKET_AUTOMATION_MESSAGE_ROUTE =
  process.env.REACT_APP_AUTOMATIONS + "/api/v1/TicketAutomationMessage/";

export const listTicketAutomationMessage = (params?: any) => {
  return GlobalAxios.get<ITicketAutomationMessage[]>(
    TICKET_AUTOMATION_MESSAGE_ROUTE + "list",
    {
      params: params,
    }
  );
};
