import { IPrivilegesDomain } from "@4uhub/lib4uhub";
import { GlobalAxios } from "./../axios/axios-config";
import { IIntegratedPrivilege } from "../models/privileges";

const PRIVILEGES_ROUTE = process.env.REACT_APP_IDENTIY + "/api/v1/Privileges/";

const BUS_PRIVILEGE_ROUTE = process.env.REACT_APP_4UBUS + "/api/v1/Privilege/";

export class PrivilegesService {
  list = (params?: any) => {
    return GlobalAxios.get<IPrivilegesDomain[]>(
      PRIVILEGES_ROUTE + "list/" + params
    );
  };
}

export default PrivilegesService;

export const syncPrivilegeNow = () => {
  return GlobalAxios.post(BUS_PRIVILEGE_ROUTE + "Sync/Now");
};

export const getIntegratedPrivilege = () => {
  return GlobalAxios.get<IIntegratedPrivilege>(BUS_PRIVILEGE_ROUTE + "List");
};
