import { IGetNeighborhoods, INeighborhood } from "../models/neighborhoods";
import { GlobalAxios } from "./../axios/axios-config";

const NEIGHBORHOOD_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Neighborhood";

export class NeighborhoodService {
  create = (item: INeighborhood) => {
    return GlobalAxios.post(NEIGHBORHOOD_ROUTE, item);
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<IGetNeighborhoods>(NEIGHBORHOOD_ROUTE + "/" + id);
  };

  updateItemById = ({ item, id }: { item: INeighborhood; id: string }) => {
    return GlobalAxios.put(NEIGHBORHOOD_ROUTE + "/" + id, item);
  };

  deleteItemById = (id: string) => {
    return GlobalAxios.delete(NEIGHBORHOOD_ROUTE + id);
  };
}

export default NeighborhoodService;
