import { Button } from "@4uhub/lib4uhub";
import { Box } from "@mui/material";
import { memo, useCallback } from "react";

import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatStrikethroughIcon from "@mui/icons-material/FormatStrikethrough";
import CodeIcon from "@mui/icons-material/Code";
import { ITextFormatActionsProps } from "./models";

type TMode = "bold" | "italic" | "strikethrough" | "code";

export const TextFormatActions = memo(
  ({ message, inputRef, setMessage }: ITextFormatActionsProps) => {
    const handleValueToAddClick = useCallback(
      (valueToAdd: string) => {
        const ref = inputRef.current;
        if (ref) {
          ref.focus();
          if (ref.selectionStart !== null && ref.selectionEnd !== null) {
            const start = message.substring(0, ref.selectionStart);
            const selectedText = message.substring(
              ref.selectionStart,
              ref.selectionEnd
            );
            const end = message.substring(ref.selectionEnd);

            if (ref.selectionStart === ref.selectionEnd) {
              // Não há seleção, apenas insira o valor
              const text = start + valueToAdd + end;
              const newCursorPosition =
                start.length + Math.floor(valueToAdd.length / 2);
              setMessage(text);
              setTimeout(() => {
                ref.setSelectionRange(newCursorPosition, newCursorPosition);
              }, 0);
            } else {
              // Há seleção, envolva o texto selecionado
              const text =
                start +
                valueToAdd.charAt(0) +
                selectedText +
                valueToAdd.charAt(1) +
                end;
              setMessage(text);
              setTimeout(() => {
                ref.setSelectionRange(
                  (ref.selectionStart ?? 0) + 1,
                  (ref.selectionEnd ?? 0) + 1
                );
              }, 0);
            }
          } else {
            setMessage((o) => o + valueToAdd);
          }
        }
      },
      [inputRef, message, setMessage]
    );

    const handleMode = useCallback(
      (mode: TMode) => {
        if (mode === "bold") handleValueToAddClick("**");
        if (mode === "italic") handleValueToAddClick("__");
        if (mode === "strikethrough") handleValueToAddClick("~~");
        if (mode === "code") handleValueToAddClick("``````");
      },
      [handleValueToAddClick]
    );

    return (
      <Box sx={{ display: "flex" }}>
        <Button
          color="inherit"
          size="small"
          sx={{ minWidth: 0 }}
          onClick={() => handleMode("bold")}
        >
          <FormatBoldIcon fontSize="small" />
        </Button>
        <Button
          color="inherit"
          size="small"
          sx={{ minWidth: 0 }}
          onClick={() => handleMode("italic")}
        >
          <FormatItalicIcon fontSize="small" />
        </Button>
        <Button
          color="inherit"
          size="small"
          sx={{ minWidth: 0 }}
          onClick={() => handleMode("strikethrough")}
        >
          <FormatStrikethroughIcon fontSize="small" />
        </Button>
        <Button
          color="inherit"
          size="small"
          sx={{ minWidth: 0 }}
          onClick={() => handleMode("code")}
        >
          <CodeIcon fontSize="small" />
        </Button>
      </Box>
    );
  }
);
