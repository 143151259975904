import { z } from "zod";

const translationPath = "page.settings.crm.externalProcessType.errors.";

export const externalProcessTypeSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, translationPath + "name"),
});

export type TExternalProcessTypeForm = z.infer<
  typeof externalProcessTypeSchema
>;
