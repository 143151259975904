import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import { getEIFromUrl } from "../../services/establishment.service";
import DynamicIcon from "../UI/DynamicIcon";
import useCoordinatesConjuctions from "../../hooks/useCoordinatesConjunctions";

interface ISettingsSubmenuItemProps {
  icon?: string | null;
  link: string;
  name: string;
}

const SettingsSubmenuItem = ({
  icon,
  name,
  link,
}: ISettingsSubmenuItemProps) => {
  const eI = getEIFromUrl();

  const navigate = useNavigate();

  const translatedName = useCoordinatesConjuctions(name);

  return (
    <motion.main
      id="main"
      initial={{ opacity: 0 }}
      key={name}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, ease: [0.6, -0.05, 0.01, 0.99] }}
    >
      <Stack
        height={30}
        sx={(t) => ({
          paddingInline: 2,
          paddingLeft: 4,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
          borderRadius: 1,
          cursor: "pointer",
          transition: "all 0.15s ease-in-out",
          "&:hover": {
            backgroundColor:
              t.palette.mode === "light"
                ? t.palette.grey[100]
                : t.palette.grey[900],
            color:
              t.palette.mode === "light"
                ? t.palette.primary.main
                : t.palette.primary.light,
          },
        })}
        onClick={() => navigate(`/e/${eI}/dashboard${link}`)}
      >
        {icon && <DynamicIcon icon={icon} />}
        <Typography>{translatedName}</Typography>
      </Stack>
    </motion.main>
  );
};

export default SettingsSubmenuItem;
