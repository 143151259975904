import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ISelectType, useFetch } from "@4uhub/lib4uhub";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ECalendarSource } from "../model";
import { ScheduleAppointmentService } from "../Schedule.service";
interface IPeriodProps {
  value: ECalendarSource;
  onChange: (v: ECalendarSource) => void;
}

const CalendarSource: React.FC<IPeriodProps> = ({ onChange, value }) => {
  const [types, setTypes] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(ScheduleAppointmentService.getTypes);

  const { t } = useTranslation();

  const fetch = useCallback(async () => {
    const { data, success } = await sendRequest(null);

    if (success && data) {
      setTypes(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: ECalendarSource
  ) => {
    onChange(newAlignment);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      size="small"
      fullWidth
      value={value}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      sx={{ paddingX: 4 }}
    >
      {types.map((t) => (
        <ToggleButton value={t.code!} key={t.id}>
          {t.name}
        </ToggleButton>
      ))}
      <ToggleButton value={ECalendarSource.BOTH}>
        {t("components.schedule.menu.source.both")}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
export default CalendarSource;
