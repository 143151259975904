import Logs from "../../components/Logs/Logs";

const LogsPage = () => {
  // Esse componente é apenas um exemplo de uso
  return (
    <>
      <h1>Logs</h1>
      <Logs
        Domain="generic"
        Identifier="e7688ac3-48b5-4ae3-896c-48982f50afa4"
      />
    </>
  );
};

export default LogsPage;
