import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import GrantorFields from "./Fields";
import { FormContainer, useFetch } from "@4uhub/lib4uhub";

import { grantorSchema, TGrantorForm } from "./grantorSchema";
import { buildTPath, grantorsFormService } from "../../../grantors.utils";

const Grantor = () => {
  const { t } = useTranslation();

  const { sendRequest: create, loading: isSaving } = useFetch(
    grantorsFormService.create
  );
  const { sendRequest: update, loading: isUpdating } = useFetch(
    grantorsFormService.updateItemById
  );
  const { sendRequest: findById, loading: isFetching } = useFetch(
    grantorsFormService.getItemById
  );

  const handleFormValues = ({ 
    establishments, 
    legalEntity: { id: legalEntityId } = {} as any, 
    ...rest 
  }: TGrantorForm) => ({
    ...rest,
    legalEntityId,
    establishmentIds: establishments.map(({ id }) => id),
  });

  const getHandler = useCallback(async (id: string) =>  findById(id),[findById]);

  const createHandler = (values: TGrantorForm) => create(handleFormValues(values));

  const updateHandler = (values: TGrantorForm, id: string) => update({ id, item: handleFormValues({ ...values, id } as any) });

  const isLoading = isSaving || isUpdating || isFetching;

  return (
    <FormContainer<TGrantorForm>
      saveFunction={createHandler}
      updateFunction={updateHandler}
      getFunction={getHandler as any}
      loading={isLoading}
      title={t(buildTPath("grantors"))}
      schema={grantorSchema}
      subtitle={t(buildTPath("new_grantor"))}
      subtitleWatchField="name"
      fields={() => <GrantorFields />}
    />
  );
};

export default Grantor;
