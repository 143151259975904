import { z } from "zod";

const translationPath = "components.settingsGeneral.errors.";

export const settingsCentralBankSchema = z.object({
  bankBaseUrl: z.string().min(1, translationPath + "bank_base"),
  bankBancosUrl: z.string().min(1, translationPath + "bank_bancos"),
  bankCooperativasUrl: z.string().min(1, translationPath + "bank_cooperativas"),
});

export type TSettingsCentralBankForm = z.infer<
  typeof settingsCentralBankSchema
>;
