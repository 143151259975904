import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { memo } from "react";

import { ECalendarMode } from "../models";
import useCalendar from "../CalendarContext/useCalendar";
import { useTranslation } from "react-i18next";

const ModeSelector = () => {
  const { mode, onModeChange } = useCalendar();

  const handleChange = (event: SelectChangeEvent) => {
    onModeChange(event.target.value as ECalendarMode);
  };

  const { t } = useTranslation();

  return (
    <FormControl size="small">
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={mode}
        onChange={handleChange}
      >
        <MenuItem value={ECalendarMode.DAY}>
          {t("components.schedule.day")}
        </MenuItem>
        <MenuItem value={ECalendarMode.WEEK}>
          {t("components.schedule.week")}
        </MenuItem>
        <MenuItem value={ECalendarMode.MONTH}>
          {t("components.schedule.month")}
        </MenuItem>
      </Select>
    </FormControl>
  );
};
export default memo(ModeSelector);
