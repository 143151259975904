import { GlobalAxios } from "../axios/axios-config";
import {
  ICaptation,
  IDisapproveCaptation,
  IUpdateCaptation,
} from "../models/health-programs-fundraising";

const HEALTH_PROGRAM_CAPTATION =
  process.env.REACT_APP_MAIN + "/api/v1/HealthProgramCaptation/";

export const getCaptationById = (id: string) => {
  return GlobalAxios.get<ICaptation>(HEALTH_PROGRAM_CAPTATION + id);
};

export const updateCaptationById = ({
  item,
  id,
}: {
  id: string;
  item: IUpdateCaptation;
}) => {
  return GlobalAxios.put(HEALTH_PROGRAM_CAPTATION + id, item);
};

export const approveHealthProgramCaptaion = (
  healthProgramCaptationsIds: string[]
) => {
  return GlobalAxios.post(HEALTH_PROGRAM_CAPTATION + "ApproveCaptation", {
    healthProgramCaptationsIds,
  });
};

export const disapproveHealthProgramCaptaion = (
  params: IDisapproveCaptation
) => {
  return GlobalAxios.post(
    HEALTH_PROGRAM_CAPTATION + "DisapproveCaptation",
    params
  );
};
