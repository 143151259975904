import { AutoComplete, FormInput, ISelectType } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const translationPath = "page.register.applications.";

const SiteAppDomain = ({
  domainType,
  domain,
  domainTypes,
  disable = false,
}: {
  domainType: string;
  domain: string;
  domainTypes: ISelectType[];
  disable?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} sm={3}>
        <AutoComplete
          getOptionLabel={(option) => {
            return option.name || option.value || "";
          }}
          size="small"
          label={t(translationPath + "domain_type")}
          name={domainType}
          options={domainTypes}
          disable={disable}
          sx={(t) =>
            disable
              ? {
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: t.palette.text.primary,
                  },
                }
              : null
          }
        />
      </Grid>
      <Grid item xs={12} sm={9}>
        <FormInput
          size="small"
          name={domain}
          label={t(translationPath + "domain")}
          fullWidth
          disabled={disable}
          sx={(t) =>
            disable
              ? {
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: t.palette.text.primary,
                  },
                }
              : null
          }
        />
      </Grid>
    </>
  );
};

export default SiteAppDomain;
