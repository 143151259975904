import { GlobalAxios } from "../axios/axios-config";
import {
  IEditTicketAutomationRule,
  INewTicketAutomationRule,
  ITicketAutomationRule,
} from "../models/ticket-automation-rules";

const AUTOMATION_RULE_ROUTE =
  process.env.REACT_APP_AUTOMATIONS + "/api/v1/TicketAutomationRules/";

export const createAutomationRule = (item: INewTicketAutomationRule) => {
  return GlobalAxios.post(AUTOMATION_RULE_ROUTE, item);
};

export const getAutomationRuleById = (id: string) => {
  return GlobalAxios.get<ITicketAutomationRule>(AUTOMATION_RULE_ROUTE + id);
};

export const updateAutomationRuleById = ({
  item,
  id,
}: {
  item: IEditTicketAutomationRule;
  id: string;
}) => {
  return GlobalAxios.put(AUTOMATION_RULE_ROUTE + id, item);
};
