import { z } from "zod";
const translationPath =
  "page.4uSalesContract.registers.salesProductCtrPriceList.errors.";

const genericSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const salesProductCtrPriceListItemSchema = z
  .object({
    id: z.string().optional(),
    degreeOfEntitlement: genericSchema.nullish(),
    initalAge: z.string().nullable(),
    endAge: z.string().nullable(),
    price: z.string().min(1, translationPath + "price"),
  })
  .superRefine((val, ctx) => {
    if (!val.degreeOfEntitlement) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["degreeOfEntitlement"],
        message: translationPath + "degree_of_entitlement",
      });
    }
  });

export const contractProductPriceListSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, translationPath + "name"),
  code: z.string().min(1, translationPath + "code"),
  initialDate: z.date({ invalid_type_error: translationPath + "initial_date" }),
  endDate: z.date({ invalid_type_error: translationPath + "end_date" }),
  salesProductCtrPriceListItems: z
    .array(salesProductCtrPriceListItemSchema)
    .min(1, translationPath + "sales_product_ctr_price_list_items"),
});

export type TContractProductPriceListForm = z.infer<
  typeof contractProductPriceListSchema
>;
