import { memo } from "react";
import { QPFQuickRegisterForm } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

const Patient = () => {
  const { t } = useTranslation();
  return (
    <QPFQuickRegisterForm
      name={"patient"}
      label={t(
        "components.scheduleAssistant.insurance.addInsurance.individual.label"
      )}
    />
  );
};

export default memo(Patient);
