import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";


// Schema
import MainApiService from "../../../../services/mainApi.service";
import { TBankForm, bankSchema } from "./BankSchema";
import { FormContainer, FormInput, useFetch } from "@4uhub/lib4uhub";

const BANK_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Bank/";
const bankService = new MainApiService<TBankForm>(BANK_ROUTE);

const translationPath = "page.register.banks.";

const Country = () => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(bankService.create);
  const { sendRequest: update, loading: uLoading } = useFetch(
    bankService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    bankService.getItemById
  );

  const updateHandler = (v: TBankForm, id: string) =>
    update({ item: v, id: id });

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TBankForm>
      saveFunction={save}
      updateFunction={updateHandler}
      getFunction={get}
      loading={loading}
      title={t(translationPath + "bank")}
      schema={bankSchema}
      subtitle={t(translationPath + "new_bank")}
      subtitleWatchField={"name"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="name"
              fullWidth
              label={t(translationPath + "name") || "Name"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="shortName"
              fullWidth
              label={t(translationPath + "short_name") || "Short Name"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              type="number"
              name="ispbCode"
              fullWidth
              label={t(translationPath + "ispb_code") || "ISPB Code"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              type="number"
              name="bacenCode"
              fullWidth
              label={t(translationPath + "bacen_code") || "Code"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              type="number"
              name="ibgeCityCode"
              fullWidth
              label={t(translationPath + "ibge_city_code") || "Code"}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default Country;
