import { Typography, useMediaQuery, useTheme } from "@mui/material";
import WhatsConfigData from "./WhatsConfigData";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";

const translationPath = "chatBot.page.register.ticketServiceWhats.";

const VerifyToken = ({ verifyToken }: { verifyToken: string }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      display={"flex"}
      flexDirection={matches ? "column" : "row"}
      alignItems={matches ? "start" : "center"}
    >
      <Typography
        mr={matches ? 0 : 2}
        sx={(theme) => ({
          color:
            theme.palette.mode === "light"
              ? theme.palette.grey[600]
              : theme.palette.grey[200],
        })}
      >
        {t(translationPath + "verify_token")}:
      </Typography>
      <WhatsConfigData
        data={verifyToken}
        tooltipTilte={t(translationPath + "verify_token_tooltip")}
      />
    </Box>
  );
};

export default VerifyToken;
