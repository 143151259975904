import { GlobalAxios } from "../axios/axios-config";
import {
  INewTicketAutoFinishRule,
  ITicketAutoFinishRule,
} from "../models/ticket-auto-finish-rule";

const TICKET_AUTO_FINISH_RULES_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketAutoFinishRule";

export const createTicketAutoFinishRule = (item: INewTicketAutoFinishRule) => {
  return GlobalAxios.post(TICKET_AUTO_FINISH_RULES_ROUTE, item);
};

export const getTicketAutoFinishRuleById = (id: string) => {
  return GlobalAxios.get<ITicketAutoFinishRule>(
    TICKET_AUTO_FINISH_RULES_ROUTE + "/" + id
  );
};

export const updateTicketAutoFinishRuleById = ({
  item,
  id,
}: {
  item: INewTicketAutoFinishRule;
  id: string;
}) => {
  return GlobalAxios.put(TICKET_AUTO_FINISH_RULES_ROUTE + "/" + id, item);
};
