import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";

import { Grid } from "@mui/material";
import Checkboxes, { TForm } from "./Checkboxes";
import {
  QPFQuickRegisterForm,
  QPJQuickRegisterForm,
  useFormContext,
} from "@4uhub/lib4uhub";

import { buildTPath } from "../../../sales-channel.utils";

const EntitySelect = (): ReactElement => {
  const { t } = useTranslation();
  const { control } = useFormContext<TForm>();
  const entityType = useWatch({ control, name: "entity", defaultValue: "QPF" });

  return (
    <>
      <Checkboxes />
      <Grid item xs={12} lg={7}>
        {entityType === "QPF" && (
          <QPFQuickRegisterForm
            label={t(buildTPath("individual_person"))}
            name="individual"
          />
        )}

        {entityType === "QPJ" && (
          <QPJQuickRegisterForm
            label={t(buildTPath("legal_entity"))}
            name="legalEntity"
          />
        )}
      </Grid>
    </>
  );
};

export default EntitySelect;
