import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Loading } from "@4uhub/lib4uhub";
import { Draggable } from "react-beautiful-dnd";
import DragHandleIcon from "@mui/icons-material/DragHandle";

import { IDashboardEstablishmentComponent } from "../../../../models/dashboard";
import { ChangeEvent, memo, useCallback, useMemo, useState } from "react";
import { IComponent } from "./Components";

interface ICompontProps {
  data: IComponent;
  onChange: (id: string, checked: boolean, eqId?: string) => Promise<void>;
  actualEstablishments: IDashboardEstablishmentComponent[];
  index: number;
}

const Component: React.FC<ICompontProps> = ({
  data: { id, name },
  onChange,
  actualEstablishments,
  index,
}) => {
  const [loading, setLoading] = useState(false);

  const componentId = actualEstablishments.find(
    (e) => e.componentRootId === id
  )?.id;

  const onChangeHandler = useCallback(
    async (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setLoading(true);
      await onChange(id, checked, componentId);
      setLoading(false);
    },
    [onChange, componentId, id]
  );

  const checked = useMemo(
    () => !!actualEstablishments.find((e) => e.componentRootId === id)?.enable,
    [actualEstablishments, id]
  );

  const disabled = useMemo(
    () => actualEstablishments.filter(a => a.enable).length === 1 && checked,
    [actualEstablishments, checked]
  );

  return (
    <Draggable draggableId={id} index={index} isDragDisabled={!checked}>
      {(provided, snapshot) => (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          {...provided.draggableProps}
          paddingInline={1}
          sx={(t) => ({
            alignItems: "center",
            boxShadow: snapshot.isDragging ? t.shadows[1] : "unset",
          })}
          ref={provided.innerRef}
          gap={2}
        >
          <FormControlLabel
            control={<Checkbox onChange={onChangeHandler} checked={checked} />}
            disabled={disabled}
            label={
              loading ? (
                <Loading sx={{ position: "relative" }} size={20} />
              ) : (
                <Typography>{name}</Typography>
              )
            }
          />
          {checked && (
            <div {...provided.dragHandleProps}>
              <IconButton>
                <DragHandleIcon fontSize="small" />
              </IconButton>
            </div>
          )}
        </Stack>
      )}
    </Draggable>
  );
};

export default memo(Component);
