import { Grid, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";

// Schema
import { useCallback } from "react";
import {
  FormContainer,
  FormInput,
  Information,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import {
  createScheduleConfirmation,
  getScheduleConfirmationById,
  updateScheduleConfirmationById,
} from "../../../../../../../services/scheduleConfirmation.service";
import {
  TScheduleConfirmationForm,
  scheduleConfirmationSchema,
} from "./ScheduleConfirmationSchema";

const translationPath = "page.settings.schedule.scheduleConfirmation.";

const ScheduleConfirmation = () => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(
    createScheduleConfirmation
  );
  const { sendRequest: update, loading: uLoading } = useFetch(
    updateScheduleConfirmationById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    getScheduleConfirmationById
  );

  const handleSave = (v: TScheduleConfirmationForm) => {
    return save({
      ...v,
      time: Number(v.time),
    });
  };

  const updateHandler = (v: TScheduleConfirmationForm, id: string) =>
    update({
      item: {
        ...v,
        time: Number(v.time),
      },
      id: id,
    });

  const handleGet = useCallback(
    async (id: string): Promise<Treturn<TScheduleConfirmationForm>> => {
      const { data } = await get(id);
      if (data) {
        return {
          data: {
            ...data,
            time: data.time.toString(),
          },
          success: true,
        };
      }
      return {
        data: null,
        success: false,
      };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TScheduleConfirmationForm>
      saveFunction={handleSave}
      updateFunction={updateHandler}
      getFunction={handleGet}
      loading={loading}
      title={t(translationPath + "schedule_confirmation")}
      schema={scheduleConfirmationSchema}
      subtitle={t(translationPath + "new_schedule_confirmation")}
      subtitleWatchField={"time"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Information info={t(translationPath + "help")}>
              <FormInput
                size="small"
                name="time"
                fullWidth
                label={t(translationPath + "time") || "Time"}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {t(translationPath + "min")}
                    </InputAdornment>
                  ),
                }}
              />
            </Information>
          </Grid>
        </Grid>
      )}
    />
  );
};

export default ScheduleConfirmation;
