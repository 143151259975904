import { z } from "zod";

const translationPath = "page.settings.crm.events.errors.";

const eventTypeSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional(),
  },
  { invalid_type_error: translationPath + "event_type" }
);

const ticketAutomationSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional(),
});

export const eventSchema = z.object({
  id: z.string().optional(),
  enable: z.boolean(),
  name: z.string().min(1, translationPath + "name"),
  eventType: eventTypeSchema,
  ticketAutomations: z.array(ticketAutomationSchema).nullish(),
});
export type TEventForm = z.infer<typeof eventSchema>;
