import { z } from "zod";
import { buildTPath } from "../../../grantors.utils";

const legalEntitySchema = z.object({
  id: z.string(),
  document: z.string().optional().nullable(),
  corporateName: z.string().optional().nullable(),
}, { invalid_type_error: buildTPath('errors.legal_entity') });

const establishmentSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().nullable()
});

export const grantorSchema = z.object(
  {
    name: z.string().min(1, buildTPath('errors.name')),
    enable: z.boolean(),
    legalEntity: legalEntitySchema,
    establishments: z.array(establishmentSchema).min(1, buildTPath('errors.establishment')),
  }
);

export type TGrantorForm = z.infer<typeof grantorSchema>;
