import { GlobalAxios } from "../axios/axios-config";
import {
  IContractProductHistory,
  INewContractProductHistory,
} from "../models/sales-product-history";

const SALES_PRODUCT_HISTORY_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/SalesProductCtrHistory/";

export const createContracProductHistory = (
  item: INewContractProductHistory
) => {
  return GlobalAxios.post(SALES_PRODUCT_HISTORY_ROUTE, item);
};

export const getContracProductHistoryById = (id: string) => {
  return GlobalAxios.get<IContractProductHistory>(
    SALES_PRODUCT_HISTORY_ROUTE + id
  );
};

export const updateContracProductHistoryById = ({
  item,
  id,
}: {
  item: INewContractProductHistory;
  id: string;
}) => {
  return GlobalAxios.put(SALES_PRODUCT_HISTORY_ROUTE + id, item);
};

export const approveSalesProductHistory = (id: string) => {
  return GlobalAxios.put(`${SALES_PRODUCT_HISTORY_ROUTE}ApprovalDate/${id}`);
};
