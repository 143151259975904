import { Grid, Stack, Typography } from "@mui/material";
import { memo } from "react";

import DynamicIcon from "../../../UI/DynamicIcon";
import { IDashboardEstablishmentComponent } from "../../../../models/dashboard";
import { useTranslation } from "react-i18next";

interface IDashBoardComponentProps {
  data: IDashboardEstablishmentComponent;
}

const DashBoardComponent: React.FC<IDashBoardComponentProps> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={"auto"} minWidth={150} padding={2} borderRadius={(t) => 1}>
      <Stack>
        <Stack direction={"row"} gap={1} color={(t) => t.palette.grey[600]}>
          {data.icon && <DynamicIcon icon={data.icon} fontSize="small" />}
          <Typography>{data.name}</Typography>
        </Stack>
        <Typography sx={{ fontWeight: 500, fontSize: data.value ? 30 : 15 }}>
          {data.value ? JSON.parse(data.value).data : t("components.panel.no_data")}
        </Typography>
      </Stack>
    </Grid>
  );
};

export default memo(DashBoardComponent);
