import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  AutoComplete,
  ISelectType,
  Modal,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { useWatch } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";

import { TScheduleSchema } from "../schema";
import { ScheduleInsuranceService } from "./insurance.service";
import InsuranceForm from "./AddInsurance/InsuranceForm";
import AddButton from "./AddButton";

const Insurance = () => {
  const [insurances, setInsurances] = useState<ISelectType[]>([]);

  const [openCreate, setOpenCreate] = useState(false);

  const [openEdit, setOpenEdit] = useState(false);

  const { control, resetField } = useFormContext<TScheduleSchema>();

  const patient = useWatch({ control, name: "patient" });

  const healthInsurance = useWatch({ control, name: "healthInsurance" });

  const { t } = useTranslation();

  const { sendRequest } = useFetch(ScheduleInsuranceService.list);

  const showEdit = useMemo(
    () =>
      healthInsurance &&
      healthInsurance.id !== "1" &&
      healthInsurance.id !== "2",
    [healthInsurance]
  );

  const patientId = useMemo(() => patient?.id, [patient]);

  const disabled = useMemo(() => !patientId, [patientId]);

  const fetch = useCallback(async () => {
    resetField("healthInsurance");

    if (!patientId) return;

    const { data, success } = await sendRequest({
      IndividualId: patientId,
    });

    if (data && success) {
      setInsurances(
        data.map((d) => ({
          name: d.insuranceName,
          id: d.code,
          code: d.code,
        }))
      );
    }
  }, [sendRequest, patientId, resetField]);

  const onClickHandler = () => {
    if (disabled) return;
    setOpenCreate(true);
  };

  const handleCreateClose = () => {
    setOpenCreate(false);
  };

  const handleCreateSuccess = () => {
    handleCreateClose();
    fetch();
  };

  const handleEditSuccess = () => {
    setOpenEdit(false);
    fetch();
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <>
      <Modal open={openCreate} onClose={handleCreateClose}>
        <Typography variant="h5">
          {t("components.schedule.newSchedule.insurance.add")}
        </Typography>
        {patient && (
          <InsuranceForm onSuccess={handleCreateSuccess} patient={patient} />
        )}
      </Modal>

      <Modal open={openEdit} onClose={() => setOpenEdit(false)}>
        <Typography variant="h5">
          {t("components.schedule.newSchedule.insurance.edit")}
        </Typography>
        {patient && (
          <InsuranceForm
            onSuccess={handleEditSuccess}
            patient={patient}
            id={healthInsurance?.id}
          />
        )}
      </Modal>

      <Stack gap={1}>
        <Stack flexDirection={"row"} width={"100%"} gap={1} alignItems={'center'}>
          <AutoComplete
            name="healthInsurance"
            label={t("components.schedule.newSchedule.insurance.insurance.label")}
            options={insurances}
            getOptionLabel={(o) => o.name}
            size="small"
            disabled={disabled}
            disable={disabled}
            style={{ width: 300 }}
          />
          {showEdit && (
            <IconButton sx={{ xs: "auto" }} onClick={() => setOpenEdit(true)} size="small">
              <EditIcon fontSize="small" />
            </IconButton>
          )}
        </Stack>
        <AddButton disabled={disabled} onClick={onClickHandler} />
      </Stack>
    </>
  );
};

export default memo(Insurance);
