import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  FormContainer,
  FormInput,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";

import { TLanguagesForm, languagesSchema } from "./LanguagesSchema";
import LanguagesService from "../../../../services/languages.service";
import { useCallback, useEffect, useState } from "react";
import EstablishmentsService from "../../../../services/establishments.service";
import LanguagesEstablishments from "./LanguagesEstablishments";
import { IEstablishment } from "@4uhub/lib4uhub";

const languagesService = new LanguagesService();
const establishmentListService = new EstablishmentsService();

const Languages = () => {
  const { t } = useTranslation();
  const [languageCode, setLanguageCode] = useState<string>();
  const [establishmentsList, setEstablishmentsList] = useState<
    IEstablishment[]
  >([]);

  const { sendRequest: listEstablishments } = useFetch(
    establishmentListService.list
  );

  const loadEstablishments = useCallback(async () => {
    const { data } = await listEstablishments(null);
    if (data) {
      setEstablishmentsList(data);
    }
  }, [listEstablishments]);

  useEffect(() => {
    loadEstablishments();
  }, [loadEstablishments]);

  const { sendRequest: update, loading: uLoading } = useFetch(
    languagesService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    languagesService.getItemById
  );

  const updateFunctionHandler = useCallback(
    (v: TLanguagesForm, id: string) =>
      update({
        item: {
          id: id,
          ...v,
          enable: v.enable,
          establishmentIds: v.establishments.map(
            (establishment) => establishment.id
          ),
        },
        id: id,
      }),
    [update]
  );

  const getFunctionHandler = useCallback(
    async (id: string): Promise<Treturn<TLanguagesForm>> => {
      const { data } = await get(id);
      if (data) {
        if (data.code) {
          setLanguageCode(data.code);
        }
        return { data, success: true };
      }
      return {
        data: null,
        success: false,
      };
    },
    [get]
  );

  return (
    <FormContainer<TLanguagesForm>
      updateFunction={updateFunctionHandler}
      getFunction={getFunctionHandler}
      loading={uLoading || gLoading}
      defaultValues={{
        enable: true,
      }}
      title={t("page.register.languages.languages")}
      schema={languagesSchema}
      subtitle={t("page.register.languages.new_language")}
      subtitleWatchField={"name"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <FormInput
              size="small"
              autoFocus={true}
              name="name"
              label={t("page.register.languages.name") || "Name"}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sm={2} display={"flex"} alignItems={"center"}>
            <div style={{ display: "flex", gap: 10 }}>
              <Typography color={(theme) => theme.palette.text.secondary}>
                {t("page.register.languages.code") || "Code"}:
              </Typography>
              <Typography>{languageCode}</Typography>
            </div>
          </Grid>
          <Grid item xs={6} sm={2}>
            <SwitchInput name="enable" label="Enable" />
          </Grid>
          <LanguagesEstablishments establishmentsList={establishmentsList} />
        </Grid>
      )}
    />
  );
};

export default Languages;
