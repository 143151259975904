import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { memo, useState } from "react";
import {
  FormInput,
  ISelectType,
  Information,
  useFormContext,
} from "@4uhub/lib4uhub";
import Position from "./Position";
import ProtocolRuleFieldType from "./ProtocolRuleFieldType";
import { useWatch } from "react-hook-form";
import HelpIcon from "@mui/icons-material/Help";

interface IProtocolRuleDetailProps {
  fieldIndex: number;
  ticketProtocolRuleFieldList: ISelectType[];
  loadDefaultOrder: (lang: ISelectType) => void;
  showButton: (show: boolean) => void;
}

const translationPath = "chatBot.page.service.protocolGenerateRule.";

const ProtocolRuleDetail: React.FC<IProtocolRuleDetailProps> = ({
  fieldIndex,
  ticketProtocolRuleFieldList,
  loadDefaultOrder,
  showButton,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [ruleFieldCode, setRuleFieldCode] = useState("");

  const ticketProtocolRuleDetails = useWatch({
    name: `ticketProtocolRuleDetails.${fieldIndex}.fixedCode`,
    control,
  });

  const type = useWatch({
    name: `ticketProtocolRuleDetails.${fieldIndex}.ticketProtocolRuleFieldType`,
    control,
  });

  const canShowFixedCode = ruleFieldCode === "5" || ticketProtocolRuleDetails;

  return (
    <>
      <Position
        fieldIndex={fieldIndex}
        loadDefaultOrder={loadDefaultOrder}
        showButton={showButton}
      />

      <ProtocolRuleFieldType
        fieldIndex={fieldIndex}
        onLoadFieldType={setRuleFieldCode}
        ticketProtocolRuleFieldList={ticketProtocolRuleFieldList}
        canShowFixedCode={!!canShowFixedCode}
      />

      {canShowFixedCode && (
        <Grid item xs={12} sm={3}>
          <FormInput
            size="small"
            name={`ticketProtocolRuleDetails.${fieldIndex}.fixedCode`}
            label={t(translationPath + "fixed_code")}
            fullWidth
          />
        </Grid>
      )}

      <Grid item xs={12} sm={4}>
        <Information
          icon={<HelpIcon />}
          info={
            type
              ? t(translationPath + "mask_information", {
                  type: type.name,
                })
              : t(translationPath + "mask_no_information")
          }
        >
          <FormInput
            size="small"
            name={`ticketProtocolRuleDetails.${fieldIndex}.mask`}
            label={t(translationPath + "mask")}
            fullWidth
          />
        </Information>
      </Grid>
    </>
  );
};

export default memo(ProtocolRuleDetail);
