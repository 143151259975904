import { z } from "zod";
import { buildTPath } from "../channel-classifications.utils";

const translate = (field: string) => buildTPath("errors." + field);

export const channelClassificationSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, translate("name")),
  code: z.any().nullish(),
  enable: z.boolean({ invalid_type_error: translate("status") }),
});

export type TChannelClassificationForm = z.infer<
  typeof channelClassificationSchema
>;
