import { GlobalAxios } from "./../axios/axios-config";
import { ISearchParams, ISearchReturn } from "../models/requests";
import { ISelectType } from "@4uhub/lib4uhub";
import {
  INewProtocolGenerate,
  IProtocolGenerate,
} from "../models/protocol-generate";
import { TCardTemplateForm } from "../pages/settings/Settings/SettingsSiteApp/CardsTemplates/CardTemplateForm/CardTemplateSchema";

const PROTOCOL_GENERATE_RULE_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketProtocolRules/";

export class ProtocolGenerateRuleService {
  create = (item: INewProtocolGenerate) => {
    return GlobalAxios.post(PROTOCOL_GENERATE_RULE_ROUTE, item);
  };

  getAll = (serchParams?: ISearchParams) => {
    return GlobalAxios.get<ISearchReturn<TCardTemplateForm>>(
      PROTOCOL_GENERATE_RULE_ROUTE,
      {
        params: serchParams,
      }
    );
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<IProtocolGenerate>(
      PROTOCOL_GENERATE_RULE_ROUTE + id
    );
  };

  updateItemById = ({
    item,
    id,
  }: {
    item: INewProtocolGenerate;
    id: string;
  }) => {
    return GlobalAxios.put(PROTOCOL_GENERATE_RULE_ROUTE + id, item);
  };

  deleteItemById = (id: string) => {
    return GlobalAxios.delete(PROTOCOL_GENERATE_RULE_ROUTE + id);
  };

  list = (params?: any) => {
    return GlobalAxios.get<ISelectType[]>(
      PROTOCOL_GENERATE_RULE_ROUTE + "list",
      {
        params: params,
      }
    );
  };
}

export default ProtocolGenerateRuleService;
