import ReactGridLayout from "react-grid-layout";
import { memo } from "react";

import { usePanel } from "../../../store/contexts/DashBoardPannelContext";
import DashBoard from "../DashBoards/DashBoard";
import { Loading, useRole } from "@4uhub/lib4uhub";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";

export const MIN_HEIGTH = 5;
export const MIN_WIDTH = 2;
interface ILayoutProps {
  width: number;
}

const Layout: React.FC<ILayoutProps> = ({ width }) => {
  const { dashboards, onLayoutChange, dashBoardsLoading, isView } = usePanel();

  const updatePermission = useRole(["Update"]);

  const { t } = useTranslation();

  if (dashBoardsLoading) {
    return <Loading sx={{ position: "relative" }} />;
  }

  if (dashboards.length === 0) {
    return (
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
        height={"100%"}
      >
        <img
          src="/illustrations/dashboard.png"
          style={{ maxWidth: "300px", width: "80%" }}
          alt="dashboard"
        />
        <Typography sx={(t) => t.typography.h6}>
          {t("components.panel.no_dashboards")}
        </Typography>
      </Stack>
    );
  }

  return (
    <Box height={"calc(100% - 50px)"} overflow={"auto"}>
      <ReactGridLayout
        className="layout"
        draggableHandle=".myDraggable"
        onDragStop={!isView && updatePermission ? onLayoutChange : undefined}
        onResizeStop={!isView && updatePermission ? onLayoutChange : undefined}
        width={width}
        isResizable={!isView && updatePermission}
        isDraggable={!isView && updatePermission}
        cols={12}
        rowHeight={30}
      >
        {dashboards.map((d) => (
          <div
            key={d.id}
            data-grid={{
              x: d.xPosition,
              y: d.yPosition,
              w: d.width,
              h: d.height,
              minW: MIN_WIDTH,
              minH: MIN_HEIGTH,
              maxh: 20,
            }}
          >
            <DashBoard data={d} />
          </div>
        ))}
      </ReactGridLayout>
    </Box>
  );
};

export default memo(Layout);
