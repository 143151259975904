import { z } from "zod";

const translationPath = "page.4uHcp.hcp.healthPrograms.errors.";

const healthProgramSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.number().optional(),
  },
  { invalid_type_error: translationPath + "health_program_type" }
);

export const captationSchema = z.object({
  id: z.string().optional(),
  healthProgram: healthProgramSchema,
});

export type TCaptationForm = z.infer<typeof captationSchema>;
