import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  AutoComplete,
  FormContainer,
  FormSelect,
  ISelectType,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import MainApi from "../../../../../../services/mainApi.service";
import { TServiceDayForm, serviceDaySchema } from "./ServiceDaySchema";
import { useCallback, useState } from "react";
import ServiceDayService from "../../../../../../services/serviceDay.service";
import { TimeInput } from "../../../../../../components/TimeInput";

const serviceDayService = new ServiceDayService();

const TICKET_SERVICE_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketServices/";
const ticketService = new MainApi<ISelectType>(TICKET_SERVICE_ROUTE);

const translationPath = "chatBot.page.service.serviceDay.";
const daysOfWeekTranslation = "components.daysOfWeek.";

const ServiceDay = () => {
  const { t } = useTranslation();
  const [daysOfWeek] = useState([
    {
      value: "Sunday",
      label: `${t(daysOfWeekTranslation + "sunday")}`,
    },
    { value: "Monday", label: `${t(daysOfWeekTranslation + "monday")}` },
    { value: "Tuesday", label: `${t(daysOfWeekTranslation + "tuesday")}` },
    { value: "Wednesday", label: `${t(daysOfWeekTranslation + "wednesday")}` },
    { value: "Thursday", label: `${t(daysOfWeekTranslation + "thursday")}` },
    { value: "Friday", label: `${t(daysOfWeekTranslation + "friday")}` },
    { value: "Saturday", label: `${t(daysOfWeekTranslation + "saturday")}` },
  ]);

  const { sendRequest: save, loading: sLoading } = useFetch(
    serviceDayService.create
  );

  const { sendRequest: update, loading: uLoading } = useFetch(
    serviceDayService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    serviceDayService.getItemById
  );

  const handleSave = (v: TServiceDayForm) => {
    return save({
      ...v,
      ticketServiceId: v.ticketService.id,
      startTime: v.startTimeTimeOnly.toTimeString().substring(0, 8),
      endTime: v.endTimeTimeOnly.toTimeString().substring(0, 8),
      dayOfWeek: v.dayOfWeek,
    });
  };

  const handleUpdate = (v: TServiceDayForm, id: string) => {
    return update({
      item: {
        ...v,
        ticketServiceId: v.ticketService.id,
        startTime: v.startTimeTimeOnly.toTimeString().substring(0, 8),
        endTime: v.endTimeTimeOnly.toTimeString().substring(0, 8),
        dayOfWeek: v.dayOfWeek,
      },
      id: id,
    });
  };

  const hourToDate = (hour: string) => {
    const startTime = new Date().setHours(
      +hour.slice(0, 2),
      +hour.slice(3, 5),
      +hour.slice(6, 8)
    );
    return new Date(startTime);
  };

  const handleGet = useCallback(
    async (id: string): Promise<Treturn<TServiceDayForm>> => {
      const { data, success } = await get(id);
      if (data) {
        return {
          data: {
            ...data,
            startTimeTimeOnly: hourToDate(data.startTimeTimeOnly),
            endTimeTimeOnly: hourToDate(data.endTimeTimeOnly),
          },
          success: true,
        };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TServiceDayForm>
      saveFunction={handleSave}
      updateFunction={handleUpdate}
      getFunction={handleGet}
      loading={loading}
      title={t(translationPath + "service_day")}
      schema={serviceDaySchema}
      subtitle={t(translationPath + "new_service_day")}
      subtitleWatchField={"dayOfWeek"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            <SwitchInput name="enable" label={t(translationPath + "enable")} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormSelect
              label={t(translationPath + "day_of_week")}
              name="dayOfWeek"
              options={daysOfWeek}
              fullWidth
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <AutoComplete
              getOptionLabel={(option) => {
                return option.name;
              }}
              size="small"
              label={t(translationPath + "ticket_service")}
              name="ticketService"
              request={ticketService.list}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TimeInput
              name="startTimeTimeOnly"
              label={t(translationPath + "start_time")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TimeInput
              name="endTimeTimeOnly"
              label={t(translationPath + "end_time")}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default ServiceDay;
