import React, { memo } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IAddButtonProps {
  disabled: boolean;
  onClick: () => void;
}

const AddButton: React.FC<IAddButtonProps> = ({ disabled, onClick }) => {
  const { t } = useTranslation();

  return (
    <Stack
      alignItems={"center"}
      direction={"row"}
      onClick={onClick}
      gap={1}
      sx={[
        {
          cursor: "pointer",
          "&:hover": {
            opacity: 0.7,
          },
        },
        disabled && {
          opacity: 0.7,
          cursor: "default",
        },
      ]}
    >
      <AddCircleOutlineIcon color="inherit" fontSize="small" />
      <Typography variant="body2">
        {t("components.schedule.newSchedule.insurance.add")}
      </Typography>
    </Stack>
  );
};

export default memo(AddButton);
