import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import {
  IBeneficiaryTerminationReason,
  IBeneficiaryTerminationReasonPayload,
} from "../models/beneficiary-termination-reason";

const serviceURL =
  process.env.REACT_APP_MAIN + "/api/v1/BeneficiaryContractReasonOfTermination";

export const createBeneficiaryTerminationReason = (
  payload: IBeneficiaryTerminationReasonPayload
) => {
  return GlobalAxios.post(serviceURL, payload);
};

export const updateBeneficiaryTerminationReason = ({
  id,
  item,
}: {
  item: IBeneficiaryTerminationReasonPayload;
  id: string;
}) => {
  return GlobalAxios.put(`${serviceURL}/${id}`, item);
};

export const findBeneficiaryTerminationReasonById = (id: string) => {
  return GlobalAxios.get<IBeneficiaryTerminationReason>(`${serviceURL}/${id}`);
};

export const listBeneficiaryTerminationReasons = () => {
  return GlobalAxios.get<ISelectType[]>(`${serviceURL}/list`);
};

export const removeBeneficiaryTerminationReason = (id: string) => {
  return GlobalAxios.delete(`${serviceURL}/${id}`);
};
