import { z } from "zod";

const translationPath = "components.automationMessages.errors.";

const ticketAutomationMessageSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().nullish(),
  },
  { invalid_type_error: translationPath + "automation_message_type" }
);

export const automationRuleMessageSchema = z.object({
  id: z.string().optional(),
  ticketAutomationMessage: ticketAutomationMessageSchema,
  message: z.string().min(1, translationPath + "message"),
});

export type TAutomationRuleMessageForm = z.infer<
  typeof automationRuleMessageSchema
>;
