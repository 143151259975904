import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IGetPriceRules } from "../../../../models/extension";
import ExtensionsGeneralService from "../../../../services/extensionsGeneral.service";

import { format } from "date-fns";
import { ptBR, enUS } from "date-fns/locale";
import { useCallback, useMemo, useState } from "react";
import { Table } from "@4uhub/lib4uhub";

const TRANSLATION_PATH = "page.register.extensions.priceRules.";

const PRICING_RULE_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/ExtensionPricingRule";
const pricingRuleService = new ExtensionsGeneralService<IGetPriceRules>(
  PRICING_RULE_ROUTE
);

const PriceRulesPage = () => {
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { extId } = useParams();
  const [pricingRules, setPricingRules] = useState<IGetPriceRules[]>([]);

  const columns: GridColDef<IGetPriceRules>[] = [
    {
      field: "typePricing",
      headerName: t(TRANSLATION_PATH + "type") || "Type",
      renderCell: (params) => {
        return params.value.name;
      },
    },
    {
      field: "initialDate",
      headerName: t(TRANSLATION_PATH + "initial_date") || "Start",
      renderCell: (params) => {
        const initialDate = new Date(params.value);
        return format(initialDate, "P", {
          locale: language === "pt-BR" ? ptBR : enUS,
        });
      },
    },
    {
      field: "endDate",
      headerName: t(TRANSLATION_PATH + "end_date") || "End",
      renderCell: (params) => {
        const endDate = new Date(params.value);
        return format(endDate, "P", {
          locale: language === "pt-BR" ? ptBR : enUS,
        });
      },
    },
    {
      field: "value",
      headerName: t(TRANSLATION_PATH + "value") || "Value",
      renderCell: (params) => {
        return `R$${Number(params.value)
          .toFixed(2)
          .toString()
          .replace(".", ",")}`;
      },
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IGetPriceRules>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const props = useMemo(
    () => ({
      ExtensionId: extId,
    }),
    [extId]
  );

  const onLoadSiteAppData = useCallback((data: IGetPriceRules[]) => {
    setPricingRules(data);
  }, []);
  return (
    <Table<IGetPriceRules>
      onFetchData={onLoadSiteAppData}
      showDefaultMacroFilters={false}
      columns={columns}
      title={t(TRANSLATION_PATH + "price_rules", {
        extension:
          pricingRules.length !== 0 ? pricingRules[0].extension.name : "",
      })}
      searchInput
      backButton
      service={pricingRuleService}
      serviceProps={props}
      onRowClick={handleRowClick}
      onAdd={handleAdd}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={
        t(TRANSLATION_PATH + "search_type") || "Search for a type"
      }
      addButtonLabel={
        t(TRANSLATION_PATH + "add_price_rule") || "Add Price Rule"
      }
      confirmOptions={{
        title: t(TRANSLATION_PATH + "confirm.title"),
        description: t(TRANSLATION_PATH + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default PriceRulesPage;
