import { IModalCollector } from "../../../../models/collectors";
import EstablishmentOnPremise from "./EstablishmentOnPremise";
import { Box } from "@mui/material";
import ParamsOnPremise from "./ParamsOnPremise";
import GenericsOnPremise from "./GenericsOnPremise";
import HealthOnPremise from "./HealthOnPremise";
import MedicSpecialtiesOnPremise from "./MedicSpecialtiesOnPremise";
import ExtensionsOnPremise from "./ExtensionsOnPremise";
import ProviderTypes from "./ProviderTypes";
import ProfessionalCouncilsOnPremise from "./ProfessionalCouncilsOnPremise";
import MedicalPracticeAreas from "./MedicalPracticeAreas";

const OnPremiseModal = ({
  modalCollector,
}: {
  modalCollector: IModalCollector | undefined;
}) => {
  return (
    <Box sx={{ overflow: "hidden" }}>
      {modalCollector?.modalType === "establishment" && (
        <EstablishmentOnPremise modalCollector={modalCollector} />
      )}
      {modalCollector?.modalType === "params" && (
        <ParamsOnPremise modalCollector={modalCollector} />
      )}
      {modalCollector?.modalType === "generics" && (
        <GenericsOnPremise modalCollector={modalCollector} />
      )}
      {modalCollector?.modalType === "health" && (
        <HealthOnPremise modalCollector={modalCollector} />
      )}
      {modalCollector?.modalType === "medic_specialties" && (
        <MedicSpecialtiesOnPremise modalCollector={modalCollector} />
      )}
      {modalCollector?.modalType === "extensions" && (
        <ExtensionsOnPremise modalCollector={modalCollector} />
      )}
      {modalCollector?.modalType === "provider_types" && (
        <ProviderTypes modalCollector={modalCollector} />
      )}
      {modalCollector?.modalType === "professional_councils" && (
        <ProfessionalCouncilsOnPremise modalCollector={modalCollector} />
      )}
      {modalCollector?.modalType === "medical_practice_areas" && (
        <MedicalPracticeAreas modalCollector={modalCollector} />
      )}
    </Box>
  );
};

export default OnPremiseModal;
