import { GlobalAxios } from "./../axios/axios-config";
import { IPriceRule } from "../models/extension";
import { TPriceRuleForm } from "../pages/globals/extensions/PriceRules/PriceRulesForm/PriceRuleSchema";

const PRICE_RULE_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/ExtensionPricingRule";

export class PriceRulesService {
  create = (item: IPriceRule) => {
    return GlobalAxios.post(PRICE_RULE_ROUTE, item);
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<TPriceRuleForm>(PRICE_RULE_ROUTE + "/" + id);
  };

  updateItemById = ({ item, id }: { item: IPriceRule; id: string }) => {
    return GlobalAxios.put(PRICE_RULE_ROUTE + "/" + id, item);
  };
}

export default PriceRulesService;
