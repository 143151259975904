import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";


// Schema
import MainApiService from "../../../../services/mainApi.service";
import { TProvinceForm, provinceSchema } from "./ProvinceSchema";
import ProvinceService from "../../../../services/province.service";
import { AutoComplete, FormContainer, FormInput, ISelectType, SwitchInput, useFetch } from "@4uhub/lib4uhub";

const provinceService = new ProvinceService();

const COUNTRY_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Country/";
const countryService = new MainApiService<ISelectType>(COUNTRY_ROUTE);

const translationPath = "page.register.provinces.";

const Province = () => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(
    provinceService.create
  );
  const { sendRequest: update, loading: uLoading } = useFetch(
    provinceService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    provinceService.getItemById
  );

  const saveHandler = (v: TProvinceForm) =>
    save({
      ...v,
      countryId: v.country.id,
    });

  const updateHandler = (v: TProvinceForm, id: string) =>
    update({
      item: {
        ...v,
        countryId: v.country.id,
      },
      id: id,
    });

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TProvinceForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={get}
      loading={loading}
      title={t(translationPath + "province")}
      schema={provinceSchema}
      subtitle={t(translationPath + "new_province")}
      subtitleWatchField={"name"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            <SwitchInput name="enable" label={t(translationPath + "enable")} />
          </Grid>

          <Grid item xs={12} sm={2}>
            <FormInput
              size="small"
              name="code"
              fullWidth
              label={t(translationPath + "code")}
            />
          </Grid>

          <Grid item xs={12} sm={5}>
            <FormInput
              size="small"
              name="name"
              fullWidth
              label={t(translationPath + "name")}
            />
          </Grid>

          <Grid item xs={12} sm={5}>
            <AutoComplete
              getOptionLabel={(option) => {
                return option.name;
              }}
              size="small"
              label={t(translationPath + "country")}
              name="country"
              request={countryService.list}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default Province;
