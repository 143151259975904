import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useCallback, useEffect } from "react";
import { authService } from "../../services/auth.service";
import { useNavigate, useParams } from "react-router-dom";
import {
  Form,
  FormInput,
  useFetch,
  useNotificationContext,
} from "@4uhub/lib4uhub";

const ResetPasswordForm = () => {
  const { t } = useTranslation();
  const { sendRequest: resetPassword, loading } = useFetch(
    authService.resetPassword
  );
  const { setMessage } = useNotificationContext();
  const navigate = useNavigate();
  const { userName } = useParams();

  const resetPasswordFormSchema = z
    .object({
      newPassword: z
        .string()
        .min(1, `${t("components.resetPasswordForm.new_password_required")}`),
      confirmNewPassword: z
        .string()
        .min(
          1,
          `${t("components.resetPasswordForm.confirm_new_password_required")}`
        ),
      confirmationCode: z
        .string()
        .min(
          1,
          `${t("components.resetPasswordForm.confirmation_code_required")}`
        ),
    })
    .refine((data) => data.newPassword === data.confirmNewPassword, {
      message: `${t("components.resetPasswordForm.passwords_match")}`,
      path: ["confirmNewPassword"], // path of error
    });

  type TResetPasswordForm = z.infer<typeof resetPasswordFormSchema>;

  const methods = useForm<TResetPasswordForm>({
    resolver: zodResolver(resetPasswordFormSchema),
  });

  const {
    formState: { isSubmitSuccessful },
    reset,
    handleSubmit,
  } = methods;

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);
  const onSubmitHandler = useCallback(
    async (form: TResetPasswordForm) => {
      if (userName) {
        const { success } = await resetPassword({
          userName: userName,
          confirmationCode: form.confirmationCode,
          newPassword: form.newPassword,
          softwareCode: "1",
        });

        if (success) {
          setMessage({
            message: `${t(
              "components.resetPasswordForm.notification_password_success"
            )}`,
            type: "success",
            notificationKey: new Date().getMilliseconds(),
          });
          navigate("/login");
        }
      }
    },
    [resetPassword, t, navigate, setMessage, userName]
  );

  return (
    <Form {...methods}>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmitHandler)}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography variant="h6" align="center">
          {t("components.resetPasswordForm.title")}
        </Typography>
        <Typography variant="subtitle2" lineHeight={1.2} align="center" mb={4}>
          {t("components.resetPasswordForm.subtitle")}
        </Typography>
        <FormInput
          size="small"
          autoFocus={true}
          name="confirmationCode"
          fullWidth
          label={t("components.resetPasswordForm.confirmation_code")}
          sx={{ mb: 2 }}
        />
        <FormInput
          size="small"
          name="newPassword"
          fullWidth
          type="password"
          label={t("components.resetPasswordForm.password")}
          sx={{ mb: 2 }}
        />
        <FormInput
          size="small"
          name="confirmNewPassword"
          fullWidth
          type="password"
          label={t("components.resetPasswordForm.confirm_password")}
          sx={{ mb: 2 }}
        />
        <Button
          variant="contained"
          disabled={loading ? true : false}
          type="submit"
        >
          {loading && (
            <Stack direction={"row"} spacing={2}>
              <CircularProgress size={18} />
              <Typography align="center" pt={0.5} variant="body2">
                {t("components.resetPasswordForm.sending")}
              </Typography>
            </Stack>
          )}
          {!loading && t("components.resetPasswordForm.send")}
        </Button>
      </Box>
    </Form>
  );
};

export default ResetPasswordForm;
