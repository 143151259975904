import {
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IRadioGoupInputProps } from "./Models";
import { useFormContext } from "@4uhub/lib4uhub";

const RadioGroupInput = ({
  name,
  radios,
  disableRadios = false,
  groupLabel,
}: IRadioGoupInputProps) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      render={({ fieldState: { error }, field }) => {
        const { onChange, value, ref } = field;
        return (
          <Box
            sx={(theme) => ({
              paddingBlock: 1,
              paddingInline: 2,
              border:
                theme.palette.mode === "light"
                  ? `1px solid ${theme.palette.grey[500]}`
                  : `1px solid ${theme.palette.grey[400]}`,
              borderRadius: 2,
            })}
          >
            <FormLabel>{groupLabel}</FormLabel>
            <RadioGroup>
              <Stack
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                flexWrap={"wrap"}
              >
                {radios.map((radio) => (
                  <FormControlLabel
                    key={radio.label}
                    value={value}
                    control={
                      <Radio
                        size="small"
                        checked={value === radio.value && true}
                        disabled={disableRadios}
                        onChange={(e, c) => {
                          if (c) {
                            onChange(radio.value);
                          }
                        }}
                      />
                    }
                    label={radio.label}
                    ref={ref}
                  />
                ))}
              </Stack>
              <Typography
                variant="body2"
                color={(theme) => theme.palette.error.main}
              >
                {error ? t(error?.message || "") : ""}
              </Typography>
            </RadioGroup>
          </Box>
        );
      }}
      name={name}
      control={control}
    />
  );
};

export default RadioGroupInput;
