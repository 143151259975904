import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { FormHelperText } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ITime } from "./models";
import { useFormContext } from "@4uhub/lib4uhub";
import { Controller } from "react-hook-form";

export const TimeInput = ({
  name,
  label,
  autoFocus = false,
  initialDate,
}: ITime) => {
  const { control } = useFormContext();
  const { t, i18n: locale } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={initialDate ? initialDate : null}
      render={(field) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              slotProps={{
                textField: {
                  size: "small",
                  error: !!field.fieldState.error,
                  fullWidth: true,
                },
              }}
              autoFocus={autoFocus}
              label={label}
              format={locale.language === "en-us" ? "hh:mm" : "HH:mm"}
              ampm={locale.language === "en-us" ? true : false}
              ampmInClock={locale.language === "en-us" ? true : false}
              value={field.field.value ? new Date(field.field.value) : null}
              onChange={(v: any) => {
                field.field.onChange(new Date(v));
              }}
            />
            <FormHelperText
              sx={(theme) => ({
                color: theme.palette.error.main,
                marginLeft: theme.spacing(2),
              })}
            >
              {field.fieldState.error
                ? t(field.fieldState.error?.message || "")
                : ""}
            </FormHelperText>
          </LocalizationProvider>
        );
      }}
    />
  );
};
