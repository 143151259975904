import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

// Schema
import MainApiService from "../../../../services/mainApi.service";
import { useCallback } from "react";
import NeighborhoodService from "../../../../services/neighborhood.service";
import { TNeighborhoodForm, neighborhoodSchema } from "./NeighborhoodSchema";
import {
  AutoComplete,
  FormContainer,
  FormInput,
  ISelectType,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";

const neighborhoodService = new NeighborhoodService();

const CITY_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/City/";
const cityService = new MainApiService<ISelectType>(CITY_ROUTE);

const translationPath = "page.register.neighborhoods.";

const Neighborhood = () => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(
    neighborhoodService.create
  );
  const { sendRequest: update, loading: uLoading } = useFetch(
    neighborhoodService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    neighborhoodService.getItemById
  );

  const saveHandler = (v: TNeighborhoodForm) =>
    save({
      ...v,
      cityId: v.city.id,
    });

  const updateHandler = (v: TNeighborhoodForm, id: string) =>
    update({
      item: {
        ...v,
        cityId: v.city.id,
      },
      id: id,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TNeighborhoodForm>> => {
      const { data, success } = await get(id);

      if (!data) {
        return { data, success };
      }

      return {
        data: { ...data, city: { id: data.city.id, name: data.city.name } },
        success: true,
      };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TNeighborhoodForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "neighborhood")}
      schema={neighborhoodSchema}
      subtitle={t(translationPath + "new_neighborhood")}
      subtitleWatchField={"name"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            <SwitchInput
              name="enable"
              label={t(translationPath + "enable") || "Enable"}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormInput
              size="small"
              name="name"
              fullWidth
              label={t(translationPath + "name") || "Name"}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <AutoComplete
              SearchAsYouTypeParams={(v) => ({ SearchBy: v })}
              getOptionLabel={(option) => {
                return option.name;
              }}
              size="small"
              label={t(translationPath + "city")}
              name="city"
              request={cityService.list}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormInput
              size="small"
              name="code"
              fullWidth
              label={t(translationPath + "code") || "Code"}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default Neighborhood;
