import { GlobalAxios } from "./../axios/axios-config";
import { ISearchReturn } from "../models/requests";
import { IExtension, IExtensionUninstall } from "../models/extensions-store";

const EXTENSIONS_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/SiteAppExtension";

export class ExtensionStoreService {
  getAll = () => {
    return GlobalAxios.get<ISearchReturn<IExtension>>(EXTENSIONS_ROUTE);
  };

  uninstallExtension = (params: IExtensionUninstall) => {
    return GlobalAxios.post(EXTENSIONS_ROUTE + "/UninstallExtension", params);
  };
}

export default ExtensionStoreService;
