import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { Modal, Table } from "@4uhub/lib4uhub";
import MainApi from "../../../../../../../services/mainApi.service";
import { memo, useMemo, useState } from "react";
import { ITicketServiceAreaDays } from "../../../../../../../models/ticket-service-area-days";
import { Grid, Stack } from "@mui/material";
import TicketServiceAreaDay from "./TicketServiceAreaDayForm/TicketServiceAreaDay";
import { useParams } from "react-router-dom";

const SITE_APP_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketServiceAreaDays/";
const siteAppService = new MainApi<ITicketServiceAreaDays>(SITE_APP_ROUTE);

const translationPath = "components.ticketServiceAreaDays.";
const daysOfWeekTranslation = "components.daysOfWeek.";

export const TicketServiceAreaDays = memo(() => {
  const { t } = useTranslation();

  const { id: ticketServiceId } = useParams();

  const [open, setOpen] = useState(false);

  const [refresh, setRefresh] = useState(0);

  const [id, setId] = useState<string | null>(null);

  const closeHandler = () => {
    setId(null);
    setOpen(false);
  };

  const successHandler = () => {
    setRefresh((prevState) => prevState + 1);
    closeHandler();
  };

  const columns: GridColDef<ITicketServiceAreaDays>[] = [
    {
      field: "dayOfWeek",
      headerName: t(translationPath + "day_of_week") || "Day of week",
      renderCell: (params) =>
        t(daysOfWeekTranslation + params.value.toLowerCase()),
    },
    {
      field: "startTimeTimeOnly",
      headerName: t(translationPath + "initial_time") || "Initial Time",
      flex: 1,
      renderCell: (params) => {
        return params.value ? params.value.substring(0, 5) : "-";
      },
    },
    {
      field: "endTimeTimeOnly",
      headerName: t(translationPath + "end_time") || "End Time",
      flex: 1,
      renderCell: (params) => {
        return params.value ? params.value.substring(0, 5) : "-";
      },
    },
  ];

  const handleAdd = () => {
    setOpen(true);
  };

  const handleRowClick = (params: GridRowParams<ITicketServiceAreaDays>) => {
    setId(params.id.toString());
    setOpen(true);
  };

  const props = useMemo(() => {
    return {
      refresh: refresh > 0 ? refresh : undefined,
    };
  }, [refresh]);

  if (!ticketServiceId) return null;

  return (
    <Stack mb={15}>
      <Grid container spacing={2} width={"80%"} alignSelf={"center"}>
        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            height: 600,
          }}
        >
          <Modal open={open} onClose={closeHandler}>
            <TicketServiceAreaDay
              id={id}
              onSuccess={successHandler}
              onCancel={closeHandler}
            />
          </Modal>
          <Table<ITicketServiceAreaDays>
            showDefaultMacroFilters={false}
            service={siteAppService}
            columns={columns}
            title={t(translationPath + "ticket_service_area_days")}
            searchInput
            onAdd={handleAdd}
            onRowClick={handleRowClick}
            backButton={false}
            defaultPageSize={20}
            serviceProps={{ ...props, TicketServiceAreaId: ticketServiceId }}
            pageSizeOptions={[5, 10, 20]}
            searchInputPlaceHolder={
              t(translationPath + "search") || "Search for a service day"
            }
            addButtonLabel={
              t(translationPath + "add_ticket_service_area_day") ||
              "Add Service Day"
            }
            confirmOptions={{
              title: t(translationPath + "confirm.title"),
              description: t(translationPath + "confirm.description"),
              confirmationButtonProps: {
                variant: "contained",
              },
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
});
