import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../../../services/mainApi.service";
import { Table } from "@4uhub/lib4uhub";
import { IGetTicketServiceMessages } from "../../../../../models/ticket-service-message";
import { IProtocolGenerate } from "../../../../../models/protocol-generate";

const TICKET_SERVICE_MESSAGE_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketServiceMessage";
const ticketServMessageService = new MainApiService<IGetTicketServiceMessages>(
  TICKET_SERVICE_MESSAGE_ROUTE
);

const translationPath = "chatBot.page.service.ticketServiceMessage.";

const TicketServiceMessagePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { eI } = useParams();

  const columns: GridColDef<IGetTicketServiceMessages>[] = [
    {
      field: "message",
      headerName: t(translationPath + "message") || "Message",
    },
    {
      field: "messageType",
      headerName: t(translationPath + "message_type") || "Message type",
    },
    {
      field: "ticketService",
      headerName: t(translationPath + "ticket_service") || "Ticket service",
      renderCell: (params) => params.value.name,
    },
  ];

  const handleRowClick = (params: GridRowParams<IProtocolGenerate>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<IGetTicketServiceMessages>
      showDefaultMacroFilters={false}
      service={ticketServMessageService}
      columns={columns}
      title={t(translationPath + "ticket_service_messages")}
      searchInput
      onRowClick={handleRowClick}
      backButton
      backButtonUrl={`/e/${eI}/dashboard/settings`}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a message"
      }
      showButton={false}
      showDeleteButton={false}
      defaultActions={false}
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default TicketServiceMessagePage;
