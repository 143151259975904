import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TDocType } from "../../models/documents";

export type AuthState = TDocType | null;

const DOCUMENT_INITIAL_STATE = null as AuthState;

export const slice = createSlice({
  name: "document",
  initialState: DOCUMENT_INITIAL_STATE,
  reducers: {
    saveDocument(state: TDocType | null, action: PayloadAction<TDocType>) {
      state = action.payload;
      return state;
    },
    removeDocument(state) {
      return DOCUMENT_INITIAL_STATE;
    },
  },
});

export const documentsSliceActions = slice.actions;

export default slice.reducer;
