import { z } from "zod";
import {
  CEL_TEL_VALIDATOR,
  CNJP_VALIDATOR,
  EMAIL_VALIDATOR,
  SITE_VALIDATOR,
} from "../../../../utils/Validators/regexValidators";
import { addressSchema } from "@4uhub/lib4uhub";

const translation_path = "page.register.legalPerson.errors.";

const genericObject = z.object(
  {
    id: z.string(),
    name: z.string().optional(),
    code: z.string().optional().nullable(),
    identifier: z.string().optional(),
    description: z.string().optional().nullable(),
  },
  { invalid_type_error: translation_path + "establishment" }
);

const taxRegimeObject = z.object(
  {
    id: z.string(),
    name: z.string().optional(),
    code: z.string().optional().nullable(),
    identifier: z.string().optional(),
    description: z.string().optional().nullable(),
  },
  { invalid_type_error: translation_path + "tax_regime" }
);

const fiscalContribuitorObject = z.object(
  {
    id: z.string(),
    name: z.string().optional(),
    code: z.string().optional().nullable(),
    identifier: z.string().optional(),
    description: z.string().optional().nullable(),
  },
  { invalid_type_error: translation_path + "fiscal_contribuitor" }
);

const selectTypeSchema = z.object({
  id: z.string(),
  name: z.string().optional(),
  code: z.string().optional(),
  identifier: z.string().optional(),
  description: z.string().optional().nullable(),
});

const contactSchema = z
  .object({
    id: z.string().optional(),
    contactTypeId: z.string().optional().nullable(),
    contactType: selectTypeSchema,
    otherType: z.string().nullable(),
    value: z.string(),
  })
  .superRefine((val, ctx) => {
    if (val.contactType.code) {
      const code = val.contactType.code;
      if (code === "1" || code === "2") {
        if (!CEL_TEL_VALIDATOR.test(val.value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["value"],
            message: translation_path + "contact.value.phone",
          });
        }
      }
      if (code === "3" || code === "4") {
        if (!EMAIL_VALIDATOR.test(val.value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["value"],
            message: translation_path + "contact.value.email",
          });
        }
      }
      if (code === "5") {
        if (!SITE_VALIDATOR.test(val.value))
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["value"],
            message: translation_path + "contact.value.site",
          });
      }
      if (code === "6") {
        if (!val.value) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["value"],
            message: translation_path + "contact.other",
          });
        }
      }
    }
  });

export const LegalEntitySchema = z
  .object({
    document: z
      .string()
      .nonempty(translation_path + "document.nomempty")
      .regex(CNJP_VALIDATOR, translation_path + "document.regex"),
    tradingName: z.string(),
    corporateName: z.string().nonempty(translation_path + "corporateName"),
    stateRegistration: z.string().optional().nullable(),
    municipalInscription: z.string().optional().nullable(),
    fiscalContributor: fiscalContribuitorObject,
    taxRegime: taxRegimeObject,
    contacts: z.array(contactSchema),
    addressess: z.array(addressSchema),
    id: z.string().optional(),
    foreigner: z.boolean(),
    country: z.string().optional().nullable(),
    establishment: genericObject,
  })
  .superRefine((val, ctx) => {
    if (val.foreigner && !val.country) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["country"],
        message: translation_path + "country",
      });
    }
  });

export type TLegalEntityForm = z.infer<typeof LegalEntitySchema>;
