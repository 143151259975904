import { AutoCompleteMulti, ISelectType, useFetch } from "@4uhub/lib4uhub";
import { Chip, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import UsersService from "../../../../../../services/users.service";
import { documentFormat } from "../../../../../../utils/conversions";

const translationPath = "page.register.notifications.";

const usersService = new UsersService();

const NotificationFiltersUsers = ({ name }: { name: string }) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(usersService.listNotificationUsers);

  const loadUsers = useCallback(async () => {
    const { data, success } = await sendRequest(undefined);
    if (data && success) {
      setUsers(
        data.map((user) => ({
          id: user.id,
          name: user.fullName,
          code: user.document,
        }))
      );
    }
  }, [sendRequest]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  return (
    <Grid item xs={12} sm={12}>
      <AutoCompleteMulti
        size="small"
        label={t(translationPath + "users")}
        getOptionLabel={(option) => option.name + option.code}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.name +
                ` (${option.code ? documentFormat(option.code) : ""})`}
            </li>
          );
        }}
        renderTags={(value, getTagProps) => {
          return value.map((option, index) => (
            <Chip
              label={
                option.name +
                ` (${option.code ? documentFormat(option.code) : ""})`
              }
              color="default"
              size="small"
              {...getTagProps({ index })}
            />
          ));
        }}
        name={name}
        options={users}
      />
    </Grid>
  );
};

export default NotificationFiltersUsers;
