import { Button, Card, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { IMenuRoute } from "../../services/menuRoute.service";

const CardMenu = ({ menu }: { menu: IMenuRoute }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { eI } = useParams();

  return (
    <Card
      variant="outlined"
      sx={(theme) => ({
        width: "100%",
        height: 250,
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        backgroundColor:
          theme.palette.mode === "light" ? "#fff" : theme.palette.grey[900],
      })}
    >
      <Typography
        variant="h6"
        textAlign={"center"}
        lineHeight={1.1}
        mt={2}
        height={"20%"}
      >
        {menu.name}
      </Typography>
      <Stack textAlign={"center"} mt={4}>
        <Typography variant="subtitle2">{menu.description}</Typography>
      </Stack>
      <Button
        variant="outlined"
        sx={{ width: 100, position: "absolute", bottom: 15 }}
        onClick={() => navigate(`/e/${eI}/dashboard${menu.link}`)}
      >
        {t("components.cardMenu.button")}
      </Button>
    </Card>
  );
};

export default CardMenu;
