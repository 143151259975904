import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { ISelectType, useFetch } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

import { ScheduleAppointmentService } from "../Schedule.service";

interface ISituationProps {
  value: string | null;
  onChange: (v: string | null) => void;
}

const Situation: React.FC<ISituationProps> = ({ onChange, value }) => {
  const [situations, setSituations] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(
    ScheduleAppointmentService.getSituationOptions
  );

  const { t } = useTranslation();

  const fetch = useCallback(async () => {
    const { data, success } = await sendRequest(null);

    if (success && data) {
      setSituations([
        { id: "Ambas", name: t("components.schedule.menu.both"), code: "-1" },
        ...data,
      ]);
    }
  }, [sendRequest, t]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <FormControl size="small">
      <FormLabel id="demo-radio-buttons-group-label">
        {t("components.schedule.menu.situation")}
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={value}
        sx={{ gap: 0 }}
        onChange={(_, v) => onChange(v)}
      >
        {situations.map((s) => (
          <FormControlLabel
            key={s.id}
            value={s.code}
            control={<Radio size="small" sx={{ padding: 1, pl: 2 }} />}
            label={<Typography sx={{ fontSize: 14 }}>{s.name}</Typography>}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default memo(Situation);
