import {
  FormArray,
  FormInput,
  ISelectType,
  useFormContext,
} from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import { useFieldArray } from "react-hook-form";
import { Grid } from "@mui/material";
import HealthProgramTypeLang from "./HealthProgramTypeLang";
import { useState } from "react";
import { THealthProgramTypeForm } from "./HealthProgramTypeSchema";
import HealthProgramTypeDescription from "./HealthProgramTypeDescription";

const translationPath = "page.settings.preventMedicine.healthProgramTypes.";

interface IHealthProgramTypeLanguagesProps {
  allLanguages: ISelectType[];
}

const HealthProgramTypeLanguages = ({
  allLanguages,
}: IHealthProgramTypeLanguagesProps) => {
  const { t } = useTranslation();

  const { control, formState } = useFormContext<THealthProgramTypeForm>();

  const [defaultLang, setDefaultLang] = useState<ISelectType>();

  const healthProgramTypeArray = useFieldArray<
    THealthProgramTypeForm,
    "healthProgramTypeLanguages",
    "key"
  >({
    control,
    name: "healthProgramTypeLanguages",
    keyName: "key",
  });

  return (
    <FormArray
      title={""}
      name="healthProgramWorkGroupTypeLanguages"
      errorMessage={formState.errors?.healthProgramTypeLanguages?.message}
      addButtonLabel={t(translationPath + "add_language") || "Add Language"}
      formArray={healthProgramTypeArray}
      showAddButton={
        healthProgramTypeArray.fields.length !== 0 && !defaultLang
          ? false
          : true
      }
      appendValue={{
        name: "",
        description: "",
        language: defaultLang ? defaultLang : allLanguages[0],
      }}
      fieldsObject={(index) => {
        const name = `healthProgramTypeLanguages.${index}.name`;
        const description = `healthProgramTypeLanguages.${index}.description`;
        const language = `healthProgramTypeLanguages.${index}.language`;

        return (
          <>
            <Grid item xs={12} sm={6}>
              <FormInput
                name={name}
                size="small"
                label={t(translationPath + "name")}
                fullWidth
              />
            </Grid>

            <HealthProgramTypeLang
              index={index}
              name={language}
              allLanguages={allLanguages}
              loadDefaultLang={setDefaultLang}
            />

            <HealthProgramTypeDescription name={description} />
          </>
        );
      }}
    />
  );
};

export default HealthProgramTypeLanguages;
