import { z } from "zod";

const translationPath = "page.register.countries.errors.";

export const countrySchema = z.object({
  id: z.string().optional(),
  enable: z.boolean(),
  name: z.string().min(1, translationPath + "name"),
  code: z.string().min(1, translationPath + "code"),
  ddiCode: z.string().optional(),
  phoneMask: z.string().optional(),
});

export type TCountryForm = z.infer<typeof countrySchema>;
