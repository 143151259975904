import { z } from "zod";

const translation_path = "page.register.establishmentParams.errors.";

const establishmentParamType = z.object(
  {
    id: z.string().nonempty(translation_path + "establishmentParamTypeId"),
    name: z.string(),
    code: z.string(),
    description: z.string().optional(),
  },
  { invalid_type_error: translation_path + "establishmentParamTypeId" }
);

export const EstablishmentParamsSchema = z.object({
  establishmentParamType: establishmentParamType,
  value: z.string().nonempty(translation_path + "value"),
  description: z.string().nonempty(translation_path + "description"),
  id: z.string().optional(),
  isPublic: z.boolean(),
});

export type TEstablishmentParamsForm = z.infer<
  typeof EstablishmentParamsSchema
>;
