import { Grid } from "@mui/material";
import { useFieldArray } from "react-hook-form";

import { useTranslation } from "react-i18next";

import {
  FormArray,
  FormInput,
  ISelectType,
  TagsInput,
  useFormContext,
} from "@4uhub/lib4uhub";
import { useCallback, useState } from "react";
import ContentsLanguage from "./ContentsLanguage";
import FormTextEditor from "../../../UI/TextEditor/FormTextEditor";
import { TContentForm } from "../ContentSchema";
import ContentVideos from "./ContentVideos/ContentVideos";

const translation_path = "components.content.";

interface IConentsFormProps {
  allLanguages: ISelectType[];
}

const Contents: React.FC<IConentsFormProps> = ({ allLanguages }) => {
  const { control, formState } = useFormContext<TContentForm>();
  const { t } = useTranslation();
  const [defaultLang, setDefaultLang] = useState<ISelectType>();

  const contentsFieldArray = useFieldArray<TContentForm>({
    control,
    name: "contents",
  });

  const loadDefaultLang = useCallback((lang: ISelectType) => {
    setDefaultLang(lang);
  }, []);

  return (
    <Grid item xs={12}>
      <FormArray
        title={t(translation_path + "contents")}
        name="contents"
        showAddButton={
          contentsFieldArray.fields.length !== 0 && !defaultLang ? false : true
        }
        errorMessage={
          formState.errors?.contents?.message ||
          formState.errors?.contents?.root?.message
        }
        addButtonLabel={
          t(translation_path + "add_new_content") || "Add new content"
        }
        formArray={contentsFieldArray}
        appendValue={{
          contentTags: [],
          language: defaultLang ? defaultLang : allLanguages[0],
          contentText: "",
          summary: "",
          title: "",
          contentVideos: [],
        }}
        fieldsObject={(index) => {
          const language = `contents.${index}.language`;
          const contentText = `contents.${index}.contentText`;
          const title = `contents.${index}.title`;
          const summary = `contents.${index}.summary`;
          const contentTags = `contents.${index}.contentTags`;

          return (
            <>
              <ContentsLanguage
                index={index}
                name={language}
                loadDefaultLang={loadDefaultLang}
                allLanguages={allLanguages}
              />

              <Grid item xs={12} sm={6}>
                <FormInput
                  size="small"
                  name={title}
                  label={t(translation_path + "title") || "Title"}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TagsInput
                  name={contentTags}
                  size="small"
                  label={t(translation_path + "content_tags") || "Content tags"}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormInput
                  size="small"
                  name={summary}
                  label={t(translation_path + "summary") || "Summary"}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormTextEditor
                  name={contentText}
                  menuBarProps={{
                    fontSize: true,
                    markFormatting: true,
                    selectColor: false,
                    textFormatting: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <ContentVideos
                  index={index}
                />
              </Grid>
            </>
          );
        }}
      />
    </Grid>
  );
};

export default Contents;
