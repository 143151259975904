import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useConfig } from "@4uhub/lib4uhub";

import { useAppSelector } from "../../../store/store";

import SubMenuItem from "./SubMenuItem";
import DynamicLogo from "./DynamicLogo";
import useCoordinatesConjuctions from "../../../hooks/useCoordinatesConjunctions";

const SubMenu: React.FC<{ accordion?: boolean; onClick?: () => void }> = ({
  accordion,
  onClick,
}) => {
  const menuState = useAppSelector((state) => state.menu);

  const navigate = useNavigate();

  const { closeMenu } = useConfig();

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const subMenuItems = menuState.items.filter(
    (item) => item.parentId && item.parentId === menuState.openItem?.id
  );

  const name = useCoordinatesConjuctions(
    menuState.openItem?.name ?? ""
  );

  const onClickHandler = useCallback(
    (link?: string, target?: string) => {
      if (link) {
        if (target === "_blank") {
          window.open(link, "_blank");
        }
        if (target === "_self") {
          navigate(link);
        }
        if (onClick) {
          onClick();
        }
        closeMenu();
      }
    },
    [navigate, closeMenu, onClick]
  );

  return (
    <Box
      sx={(theme) => ({
        width: matches ? "250px" : "100%",
        [theme.breakpoints.down("lg")]: {
          width: "100%",
          boxShadow: "none",
          margin: 0,
        },
        overflowY: "auto",
        backgroundColor: theme.palette.mode === "light" ? "#FAFAFA" : "#242424",
        borderRight: matches ? "1px solid" : undefined,
        borderRightColor: theme.palette.divider,
      })}
    >
      {matches && (
        <Stack sx={{ pt: 8, pl: 5 }} flexDirection={"row"} gap={1}>
          <DynamicLogo />
        </Stack>
      )}

      <Stack
        direction="column"
        sx={(theme) => ({
          [theme.breakpoints.down("lg")]: {
            width: "100%",
          },
        })}
        alignItems={"flex-start"}
        spacing={matches ? 2 : 1}
        padding={matches ? 4 : 1}
        paddingLeft={matches ? undefined : 4}
        paddingTop={matches ? { lg: 10, xl: 10 } : undefined}
      >
        {!accordion && menuState.openItem?.name && (
          <Typography variant="h6" >
            {name}
          </Typography>
        )}
        {subMenuItems.map((item) => (
          <SubMenuItem data={item} onClick={onClickHandler} key={item.id} />
        ))}
      </Stack>
    </Box>
  );
};

export default SubMenu;
