import { useCallback, useEffect, useMemo, useState } from "react";
import { UseFormReturn } from "react-hook-form";

import { TAddHealth } from "./schema";
import { ScheduleInsuranceService } from "../insurance.service";
import { useFetch } from "@4uhub/lib4uhub";
import { add, format } from "date-fns";

export const useInsurance = (
  methods: UseFormReturn<TAddHealth>,
  onSuccess?: () => void,
  id?: string
) => {
  const [loading, setLoading] = useState(false);

  const { sendRequest: save } = useFetch(ScheduleInsuranceService.create);

  const { sendRequest: getById, loading: getLoading } = useFetch(
    ScheduleInsuranceService.getById
  );

  const { sendRequest: edit } = useFetch(ScheduleInsuranceService.edit);

  const isEditMode = useMemo(() => !!id, [id]);

  const get = useCallback(async () => {
    if (!id) return;
    const { data, success } = await getById(id);

    if (data && success) {
      methods.reset({
        code: data.cardNumber,
        insurance: {
          id: String(data.code || data.insuranceIntegrationId),
          name: data.insuranceName,
        },
        individual: data.individual,
        validate: data.cardValidity ? new Date(data.cardValidity) : undefined,
      });
    }
  }, [getById, methods, id]);

  const fetchSave = useCallback(
    async (v: TAddHealth) => {
      const { success } = await save({
        cardNumber: v.code,
        cardValidity: v.validate
          ? format(add(v.validate, { days: 1 }), "yyyy-MM-dd")
          : undefined,
        insuranceIntegrationId: v.insurance.id,
        insuranceName: v.insurance.name,
        individualId: v.individual.id,
      });

      if (success) {
        onSuccess && onSuccess();
      }
    },
    [onSuccess, save]
  );

  const fetchEdit = useCallback(
    async (v: TAddHealth) => {
      if (!id) return;
      const { success } = await edit({
        id: id,
        data: {
          cardNumber: v.code,
          cardValidity: v.validate
            ? format(add(v.validate, { days: 1 }), "yyyy-MM-dd")
            : undefined,
          insuranceIntegrationId: v.insurance.id,
          insuranceName: v.insurance.name,
          individualId: v.individual.id,
        },
      });

      if (success) {
        onSuccess && onSuccess();
      }
    },
    [onSuccess, edit, id]
  );

  const onSubmit = useCallback(
    async (v: TAddHealth) => {
      setLoading(true);
      if (isEditMode) {
        await fetchEdit(v);
      } else {
        await fetchSave(v);
      }
      setLoading(false);
    },
    [fetchEdit, fetchSave, isEditMode]
  );

  useEffect(() => {
    get();
  }, [get]);

  return { isEditMode, onSubmit, loading, getLoading };
};
