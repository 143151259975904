import { AutoCompleteMulti, ISelectType, useFetch } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { memo, useCallback, useEffect, useState } from "react";
import SitesAppsService from "../../../../../services/sitesApps.service";

const siteAppExtensionService = new SitesAppsService();

const translationPath = "page.4uHcp.hcp.healthPrograms.";

const HealthProgramApplications = () => {
  const { t } = useTranslation();

  const [applicationsOptions, setApplicationsOptions] = useState<ISelectType[]>(
    []
  );

  const { sendRequest } = useFetch(
    siteAppExtensionService.siteAppExtensionList
  );

  const fetchApplications = useCallback(async () => {
    const { data, success } = await sendRequest({ ExtensionCode: "18" });

    if (data && success) {
      setApplicationsOptions(
        data.map((item) => ({ id: item.siteApp.id, name: item.siteApp.name }))
      );
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchApplications();
  }, [fetchApplications]);

  return (
    <Grid item xs={12} sm={12}>
      <AutoCompleteMulti
        size="small"
        label={t(translationPath + "applications") || "Applications"}
        name="siteApps"
        options={applicationsOptions}
      />
    </Grid>
  );
};

export default memo(HealthProgramApplications);
