import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import { SelectTree } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../../../../axios/axios-config";

const CATEGORIES_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/QuestionCategories";

const translationPath = "page.assessments.questions.";

export const QuestionCategory = memo(() => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={12}>
      <SelectTree
        name="questionCategory"
        size="small"
        fullWidth
        route={CATEGORIES_ROUTE}
        listRoute={CATEGORIES_ROUTE + "/ListAutoRelated"}
        axios={GlobalAxios}
        selectTreeProps={{
          label: t(translationPath + "category.label"),
          noOptionsText: t(translationPath + "category.no_options_text"),
          optionsButtonLabel: t(
            translationPath + "category.options_button_label"
          ),
          drawerProps: {
            title: t(translationPath + "category.drawer_props.title"),
            searchPlaceholder: t(
              translationPath + "category.drawer_props.search_placeholder"
            ),
            createButtonLabel: t(
              translationPath + "category.drawer_props.create_button_label"
            ),
            applyButtonLabel: t(
              translationPath + "category.drawer_props.apply_button_label"
            ),
            inputLabel: t(
              translationPath + "category.drawer_props.input_label"
            ),
            deleteConfirm: {
              title: t(
                translationPath + "category.drawer_props.delete_confirm.title"
              ),
              message: t(
                translationPath + "category.drawer_props.delete_confirm.message"
              ),
            },
          },
        }}
      />
    </Grid>
  );
});
