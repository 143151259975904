import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { ECalendarSource, IFilter } from "./model";
import { format } from "date-fns";
import { ECalendarMode } from "./Calendar/models";

export const URL_FORMAT_DATE = "yyyy/MM/dd";

const setFilterParam = (
  o: URLSearchParams,
  value: string | null,
  name: string
) => {
  if (value) {
    o.set(name, value);
  } else {
    o.delete(name);
  }
};

const useFilter = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const dateParam = useMemo(() => searchParams.get("date"), [searchParams]);

  const date = useMemo(
    () => (dateParam ? new Date(dateParam) : new Date()),
    [dateParam]
  );

  const speciality = useMemo(
    () => searchParams.get("speciality"),
    [searchParams]
  );

  const professionals = useMemo(
    () => JSON.stringify(searchParams.getAll("professionals")),
    [searchParams]
  );

  const exams = useMemo(
    () => JSON.stringify(searchParams.getAll("exams")),
    [searchParams]
  );

  const situation = useMemo(
    () => searchParams.get("situation") || "-1",
    [searchParams]
  );

  const period = useMemo(
    () => searchParams.get("period") || "-1",
    [searchParams]
  );

  const source = useMemo(
    () =>
      (searchParams.get("source") as ECalendarSource) || ECalendarSource.BOTH,
    [searchParams]
  );

  const mode = useMemo(
    () => (searchParams.get("mode") as ECalendarMode) || ECalendarMode.DAY,
    [searchParams]
  );

  const filter: IFilter = useMemo(() => {
    return {
      speciality: speciality,
      professionals: JSON.parse(professionals),
      exams: JSON.parse(exams),
      situation: situation,
      period: period,
      source: source,
      mode: mode,
    };
  }, [speciality, professionals, exams, situation, period, source, mode]);
  

  const setDate = useCallback(
    (date: Date) => {
      setSearchParams((o) => {
        setFilterParam(o, format(date, URL_FORMAT_DATE), "date");

        return o;
      });
    },
    [setSearchParams]
  );

  const setFilter = useCallback(
    ({
      exams,
      period,
      professionals,
      situation,
      source,
      speciality,
      mode,
    }: Partial<IFilter>) => {
      setSearchParams((o) => {
        if (professionals) {
          o.delete("professionals");
          professionals.forEach((id) => {
            o.append("professionals", id);
          });
        }

        if (exams) {
          o.delete("exams");
          exams.forEach((id) => {
            o.append("exams", id);
          });
        }

        if (period) {
          setFilterParam(o, period, "period");
        }

        if (situation) {
          setFilterParam(o, situation, "situation");
        }

        if (speciality) {
          setFilterParam(o, speciality, "speciality");
        }

        if(speciality === null){
          o.delete('speciality')
        }

        if (source) {
          setFilterParam(o, source, "source");
        }

        if (mode) {
          setFilterParam(o, mode, "mode");
        }

        return o;
      });
    },
    [setSearchParams]
  );

  const clearAllFilters = useCallback(() => {
    setSearchParams(
      (o) => {
        o.delete("exams");
        o.delete("professionals");
        o.delete("period");
        o.delete("situation");
        o.delete("speciality");
        o.delete("source");
        o.delete("date");
        o.delete("mode");
        return o;
      },
      {
        replace: true,
      }
    );
  }, [setSearchParams]);

  const value = useMemo(
    () => ({ filter, clearAllFilters, setFilter, date, setDate }),
    [filter, clearAllFilters, setFilter, setDate, date]
  );

  return value;
};

export default useFilter;
