import { useTranslation } from "react-i18next";

import { Divider, Grid, Typography } from "@mui/material";

import { DateInput, FormCheckBox, FormInput } from "@4uhub/lib4uhub";
import ExceptionFields from "./ExceptionFields";

const translationPath = "page.settings.4uBus.externalExamPrescription.rules.";

const Fields = () => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography fontWeight="bold">{t(translationPath + "if")}</Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <DateInput
          name="initialDate"
          label={t(translationPath + "initial_date")}
          autoFocus
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <DateInput name="endDate" label={t(translationPath + "end_date")} />
      </Grid>

      <Grid item xs={12} sm={12}>
        <FormInput
          size="small"
          name="medicalProcedureTypeCode"
          fullWidth
          label={t(translationPath + "medical_procedure_type_code")}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <FormInput
          size="small"
          name="patientInsuranceCode"
          fullWidth
          label={t(translationPath + "patient_insurance_code")}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <Divider sx={{ my: 2, width: "100%" }} />
        <Typography fontWeight="bold">{t(translationPath + "then")}</Typography>
      </Grid>

      <Grid item xs={12} sm={12}>
        <FormCheckBox
          name="isExceptions"
          label={t(translationPath + "exception")!}
        />
      </Grid>

      <ExceptionFields />
    </Grid>
  );
};

export default Fields;
