import { z } from "zod";

const translationPath = "chatBot.page.service.serviceDay.errors.";

const ticketServiceSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional(),
  },
  { invalid_type_error: translationPath + "ticket_service" }
);

export const serviceDaySchema = z.object({
  id: z.string().optional(),
  enable: z.boolean(),
  dayOfWeek: z.string().min(1, translationPath + "day_of_week"),
  startTimeTimeOnly: z.date({
    invalid_type_error: translationPath + "start_time",
  }),
  endTimeTimeOnly: z.date({
    invalid_type_error: translationPath + "end_time",
  }),
  ticketService: ticketServiceSchema,
});
export type TServiceDayForm = z.infer<typeof serviceDaySchema>;
