import {
  createContext,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

interface ISalesContractContextProps {
  propId?: string;
  tabName: string;
  open: boolean;
  refresh: number;
  onOpen: () => void;
  onClose: () => void;
  onRefresh: () => void;
  selectPropId: (value?: string) => void;
  selectTabName: (value: string) => void;
}

const SalesContractContext = createContext<ISalesContractContextProps>({
  propId: undefined,
  tabName: "",
  open: false,
  refresh: 0,
  onRefresh: () => {},
  onOpen: () => {},
  onClose: () => {},
  selectPropId: () => {},
  selectTabName: () => {},
});

interface ISalesContractProviderProps {
  children: ReactNode;
}

export const SalesContractProvider: React.FC<ISalesContractProviderProps> =
  memo(({ children }) => {
    const [propId, setPropId] = useState<string | undefined>();

    const [tabName, setTabName] = useState("");

    const [open, setOpen] = useState(false);

    const [refresh, setRefresh] = useState(0);

    const selectPropId = useCallback((value?: string) => {
      setPropId(value);
    }, []);

    const selectTabName = useCallback((value: string) => {
      setTabName(value);
    }, []);

    const onOpen = useCallback(() => {
      setOpen(true);
    }, []);

    const onClose = useCallback(() => {
      setOpen(false);
      setPropId(undefined);
      selectTabName("");
    }, [selectTabName]);

    const onRefresh = useCallback(() => {
      setRefresh((oldState) => (oldState += 1));
    }, []);

    const value = useMemo(() => {
      return {
        propId,
        tabName,
        open,
        refresh,
        onOpen,
        onClose,
        selectPropId,
        selectTabName,
        onRefresh,
      };
    }, [
      propId,
      open,
      tabName,
      refresh,
      onOpen,
      onClose,
      selectPropId,
      selectTabName,
      onRefresh,
    ]);
    return (
      <SalesContractContext.Provider value={value}>
        {children}
      </SalesContractContext.Provider>
    );
  });

export const useSalesContract = () => {
  const context = useContext(SalesContractContext);

  if (!context) {
    throw new Error("useSalesContract must be used with DashBoardProvider");
  }

  return context;
};
