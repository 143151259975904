import { useTranslation } from "react-i18next";

import { QPJQuickRegisterForm } from "@4uhub/lib4uhub";

import { buildTPath } from "../../../grantors.utils";

const LegalEntitySelect = () => {
    const { t } = useTranslation()

    return (
        <QPJQuickRegisterForm
            label={t(buildTPath("legal_entity"))}
            name="legalEntity"
        />
    )
};

export default LegalEntitySelect;