import { Modal } from "@4uhub/lib4uhub";
import HelpIcon from "@mui/icons-material/Help";
import { Box, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation, Trans } from "react-i18next";

const Help = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <HelpIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title={t("components.panel.helper.title") + ""}
      >
        <Box>
          <Typography>{t("components.panel.helper.first")}</Typography>
          <ul>
            <li>
              <Typography>
                <Trans
                  i18nKey="components.panel.helper.second"
                  components={{ 1: <strong /> }}
                />
              </Typography>
            </li>
            <li>
              <Typography>
                <Trans
                  i18nKey="components.panel.helper.third"
                  components={{ 1: <strong /> }}
                />
              </Typography>
            </li>
            <li>
              <Typography>
                <Trans
                  i18nKey="components.panel.helper.fourth"
                  components={{ 1: <strong /> }}
                />
              </Typography>
            </li>
          </ul>
        </Box>
      </Modal>
    </>
  );
};

export default Help;
