import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import NoData from "../../../../../../components/Logs/NoData/NoData";
import PrettyJson from "../../../../../admSystem/establishments/OnPremise/PrettyJson";

interface ISearchCacheKeyModal {
  searchKey: string;
  data: any;
}

const translationPath = "components.settingsCache.searchCacheKey.";

const SearchCacheKeyModal = ({ data, searchKey }: ISearchCacheKeyModal) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Stack mb={5}>
        <Typography
          sx={(theme) => ({
            fontSize: 16,
            color:
              theme.palette.mode === "light"
                ? theme.palette.grey[600]
                : theme.palette.grey[400],
          })}
        >
          {t(translationPath + "results")}:
        </Typography>
        <Typography fontSize={18} fontWeight={"bold"}>
          {searchKey}
        </Typography>
      </Stack>
      {data.length === 0 && <NoData message={t(translationPath + "no_data")} />}
      {data.length !== 0 && <PrettyJson>{data}</PrettyJson>}
    </Box>
  );
};

export default SearchCacheKeyModal;
