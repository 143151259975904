import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import {
  IParamsTicketChannelStepList,
  ITicketChannelStepByTicketChannel,
} from "../models/ticket-channel-step";

const BASE_URL = process.env.REACT_APP_TICKETS + "/api/v1/TicketChannelSteps/";

const TICKET_CHANNELS_STEP_ROUTE =
  process.env.REACT_APP_TICKETS +
  "/api/v1/TicketChannelSteps/TicketChannel/all/";

const TICKET_CHANNELS_BY_WORKSPACE_ROUTE =
  process.env.REACT_APP_TICKETS +
  "/api/v1/TicketChannels/TicketWorkspaces/all-ticketChannels/";

export const ticketChannelStep = (id: string) => {
  return GlobalAxios.get<ISelectType[]>(TICKET_CHANNELS_STEP_ROUTE + id);
};

export const ticketChannelsByWorkspace = (workspaceId: string) => {
  return GlobalAxios.get<ISelectType[]>(
    TICKET_CHANNELS_BY_WORKSPACE_ROUTE + workspaceId
  );
};

export const ticketChannelStepsByTicketChannel = (
  params: IParamsTicketChannelStepList
) => {
  return GlobalAxios.get<ITicketChannelStepByTicketChannel[]>(
    BASE_URL + "TicketChannel/" + params.id,
    {
      params: {
        all: params.all,
        statusCode: params.statusCode,
      },
    }
  );
};
