import { useTranslation } from "react-i18next";

import { AutoComplete } from "@4uhub/lib4uhub";
import { buildTPath } from "../../sales-channel.utils";
import { listMainGenerics } from "../../../../../../services/generic.service";

const SalesChannelTypeSelect = () => {
  const { t } = useTranslation();

  return (
    <AutoComplete
      getOptionLabel={({ name }) => name}
      size="small"
      label={t(buildTPath("sales_channel_type"))}
      name="salesChannelType"
      request={listMainGenerics}
      params={{ Identifier: "SalesChannelType" }}
    />
  );
};

export default SalesChannelTypeSelect;
