import {
  IAllEstablishmentParams,
  IEstablishmentParam,
  IEstablishmentParamSync,
} from "../models/establishment-param";
import { ISearchParams, ISearchReturn } from "../models/requests";
import { GlobalAxios } from "../axios/axios-config";
import { getDomain } from "./domain.service";
import { ISelectType } from "@4uhub/lib4uhub";
import { TEstablishmentParamsForm } from "../pages/settings/Settings/SettingsGeneral/establishmentParams/EstablishmentParamsForm/EstablishmentParamSchema";

const BASE_URL = process.env.REACT_APP_MAIN;

let headers = {
  Authorization: `Basic ${btoa(getDomain())}`,
  Domain: getDomain(),
};

const ESTABLISHMENTPARAM_ROUTE = BASE_URL + "/api/v1/EstablishmentParam";

const BUS_ESTABLISHMENT_PARAM_ROUTE =
  process.env.REACT_APP_4UBUS + "/api/v1/EstablishmentParam";

export class EstablishmentParamService {
  create = (item: IEstablishmentParam) => {
    return GlobalAxios.post(ESTABLISHMENTPARAM_ROUTE, item);
  };

  getAll = (serchParams?: ISearchParams) => {
    return GlobalAxios.get<ISearchReturn<IAllEstablishmentParams>>(
      ESTABLISHMENTPARAM_ROUTE,
      {
        params: serchParams,
      }
    );
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<TEstablishmentParamsForm>(
      ESTABLISHMENTPARAM_ROUTE + `/${id}`
    );
  };

  updateItemById = ({
    item,
    id,
  }: {
    item: IEstablishmentParam;
    id: string;
  }) => {
    return GlobalAxios.put(ESTABLISHMENTPARAM_ROUTE + `/${id}`, item);
  };

  deleteItemById = (id: string) => {
    return GlobalAxios.delete(ESTABLISHMENTPARAM_ROUTE + `/${id}`);
  };

  list = (params?: any) => {
    return GlobalAxios.get<ISelectType[]>(ESTABLISHMENTPARAM_ROUTE + "/list", {
      params: params,
    });
  };

  getParam = (paramTypeCode: number) => {
    return GlobalAxios.get<{ value: string }[]>(
      `${ESTABLISHMENTPARAM_ROUTE}/GetParam/${paramTypeCode}`,
      {
        headers: headers,
      }
    );
  };
}

export const syncEstablishmentParamNow = () => {
  return GlobalAxios.post(BUS_ESTABLISHMENT_PARAM_ROUTE + "/Sync/Now");
};

export const getIntegratedEstablishmentParam = () => {
  return GlobalAxios.get<IEstablishmentParamSync>(
    BUS_ESTABLISHMENT_PARAM_ROUTE
  );
};

export default EstablishmentParamService;
