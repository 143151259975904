import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import { PriceRuleSchema, TPriceRuleForm } from "./PriceRuleSchema";

import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PriceRulesService from "../../../../../services/priceRules.service";
import MainApiService from "../../../../../services/mainApi.service";
import { IGeneric } from "../../../../../models/generics";
import {
  AutoComplete,
  DateInput,
  FormContainer,
  FormInput,
  ISelectType,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";

const priceRuleService = new PriceRulesService();

const TYPE_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const typeService = new MainApiService<IGeneric>(TYPE_ROUTE);

const translationPath = "page.register.extensions.priceRules.";

const PriceRulesForm = () => {
  const { t } = useTranslation();
  const [typeList, setTypeList] = useState<ISelectType[]>([]);
  const urlParams = useParams();

  const { sendRequest } = useFetch(typeService.list);

  const loadTypeList = useCallback(async () => {
    const { data } = await sendRequest({ identifier: "TypePricing" });
    if (data) {
      setTypeList(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    loadTypeList();
  }, [loadTypeList]);

  const { sendRequest: save, loading: sLoading } = useFetch(
    priceRuleService.create
  );
  const { sendRequest: update, loading: uLoading } = useFetch(
    priceRuleService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    priceRuleService.getItemById
  );

  const handleGet = useCallback(
    async (id: string): Promise<Treturn<TPriceRuleForm>> => {
      const { data } = await get(id);
      if (data) {
        return {
          data: {
            ...data,
            value: data.value.toString(),
            initialDate: new Date(data?.initialDate),
            endDate: new Date(data?.endDate),
          },
          success: true,
        };
      }
      return {
        data: null,
        success: false,
      };
    },
    [get]
  );

  const handleSave = (v: TPriceRuleForm) => {
    return save({
      extensionId: urlParams.extId || "",
      value: Number(v.value),
      typePricingId: v.typePricing.id,
      initialDate: v.initialDate.toISOString(),
      endDate: v.endDate.toISOString(),
    });
  };

  const handleUpdate = (v: TPriceRuleForm, id: string) => {
    return update({
      item: {
        id: id,
        extensionId: urlParams.extId || "",
        value: Number(v.value),
        typePricingId: v.typePricing.id,
        initialDate: v.initialDate.toISOString(),
        endDate: v.endDate.toISOString(),
      },
      id: v.id!,
    });
  };

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TPriceRuleForm>
      loading={loading}
      title={t(translationPath + "price_rule")}
      saveFunction={handleSave}
      updateFunction={handleUpdate}
      getFunction={handleGet}
      schema={PriceRuleSchema}
      subtitle={t(translationPath + "edit_price_rule")}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <AutoComplete
              getOptionLabel={(option) => {
                return option.name;
              }}
              size="small"
              label={t(translationPath + "type")}
              name={"typePricing"}
              options={typeList}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name={"value"}
              type="number"
              label={t(translationPath + "value")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateInput
              name={"initialDate"}
              label={t(translationPath + "initial_date")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateInput
              name={"endDate"}
              label={t(translationPath + "end_date")}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default PriceRulesForm;
