import { z } from "zod";

const translationPath = "page.settings.schedule.scheduleCheckIn.errors.";

export const scheduleCheckInSchema = z.object({
  id: z.string().optional(),
  time: z.string().min(1, translationPath + "time"),
  metersApproximation: z
    .string()
    .min(1, translationPath + "meters_approximation"),
  latitude: z.string().min(1, translationPath + "latitude"),
  longitude: z.string().min(1, translationPath + "longitude"),
});

export type TScheduleCheckInForm = z.infer<typeof scheduleCheckInSchema>;
