import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { OverflowTooltip, Table } from "@4uhub/lib4uhub";
import { memo, useCallback, useMemo, useState } from "react";
import MainApi from "../../../../../../services/mainApi.service";
import { Box } from "@mui/material";
import { IAutomationMessages } from "../../../../../../models/automation-messages";
import AutomationMessage from "./AutomationMessageForm/AutomationMessage";
import { useParams } from "react-router-dom";

const TICKET_AUTOMATION_MESSAGES_ROUTE =
  process.env.REACT_APP_AUTOMATIONS + "/api/v1/TicketAutomationRuleMessage/";
const ticketAutomationService = new MainApi<IAutomationMessages>(
  TICKET_AUTOMATION_MESSAGES_ROUTE
);

const translationPath = "components.automationMessages.";

export const AutomationMessages = memo(() => {
  const [timeRuleForm, setTimeRuleForm] = useState(false);

  const [selectedId, setSelectedId] = useState<string>();

  const { t } = useTranslation();

  const { id } = useParams();

  const columns: GridColDef<IAutomationMessages>[] = [
    {
      field: "ticketAutomationMessage",
      headerName:
        t(translationPath + "automation_message_type") ||
        "Automation Message Type",
      renderCell: (params) =>
        params.value ? (
          <OverflowTooltip
            text={
              params.row.ticketAutomationMessage.ticketAutomationMessageType
                .name
            }
            tooltip={
              params.row.ticketAutomationMessage.ticketAutomationMessageType
                .name
            }
            textTransform="none"
            variant="body2"
          />
        ) : (
          "-"
        ),
    },
    {
      field: "message",
      headerName: t(translationPath + "message") || "Message",
      renderCell: (params) =>
        params.value ? (
          <OverflowTooltip
            text={params.value}
            tooltip={params.value}
            textTransform="none"
            variant="body2"
          />
        ) : (
          "-"
        ),
    },
  ];

  const handleAdd = () => {
    setTimeRuleForm(true);
  };

  const handleRowClick = (params: GridRowParams<IAutomationMessages>) => {
    if (!params.row) return;
    setTimeRuleForm(true);
    setSelectedId(params.row.id);
  };

  const handleBack = useCallback(() => {
    setSelectedId(undefined);
    setTimeRuleForm(false);
  }, []);

  const props = useMemo(() => {
    return {
      TicketAutomationRuleId: id,
    };
  }, [id]);

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      {timeRuleForm && (
        <Box
          sx={(t) => ({
            p: 1,
            px: 10,
            width: "100%",
            height: "100%",
            overflow: "auto",
            backgroundColor: t.palette.background.default,
            borderRadius: 1,
          })}
        >
          <AutomationMessage
            id={selectedId}
            ticketAutomationRuleId={id}
            onBackButton={handleBack}
          />
        </Box>
      )}
      {!timeRuleForm && (
        <Box height={600}>
          <Table<IAutomationMessages>
            showDefaultMacroFilters={false}
            service={ticketAutomationService}
            columns={columns}
            title={""}
            searchInput
            onAdd={handleAdd}
            onRowClick={handleRowClick}
            searchInputAutoFocus={false}
            defaultPageSize={20}
            serviceProps={props}
            pageSizeOptions={[5, 10, 20]}
            searchInputPlaceHolder={
              t(translationPath + "search") || "Searh for a time rule"
            }
            addButtonLabel={
              t(translationPath + "add_automation_message") ||
              "Add Automation Message"
            }
            confirmOptions={{
              title: t(translationPath + "confirm.title"),
              description: t(translationPath + "confirm.description"),
              confirmationButtonProps: {
                variant: "contained",
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
});
