import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  AutoComplete,
  AutoCompleteMulti,
  FormContainer,
  ISelectType,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import { useCallback, useEffect, useState } from "react";

import SitesAppsService from "../../../../../services/sitesApps.service";
import MainApi from "../../../../../services/mainApi.service";
import NotificationContents from "./NotificationContents/NotificationContents";
import NotificationFilters from "./NotificationFilters/NotificationFilters";
import {
  createNotification,
  getNotificationById,
  updateNotificationById,
} from "../../../../../services/notification.service";
import { TNotificationForm, notificationSchema } from "./NotificationSchema";
import NotificationInformations from "./NotificationInformations";
import NotificationBatchActions from "./NotificationBatchActions";

const translationPath = "page.register.notifications.";

const siteAppExtensionService = new SitesAppsService();

const LANGUAGE_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Language/";
const languageService = new MainApi<ISelectType>(LANGUAGE_ROUTE);

const GENERIC_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const genericService = new MainApi<ISelectType>(GENERIC_ROUTE);

const Notification = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<ISelectType>();
  const [isPublished, setIsPublished] = useState(false);
  const [startPublish, setStartPublish] = useState("");
  const [finishPublish, setFinishPublish] = useState("");

  const [siteAppOptions, setSiteAppOptions] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(
    siteAppExtensionService.siteAppExtensionList
  );

  const loadSiteApp = useCallback(async () => {
    const { data, success } = await sendRequest({ ExtensionCode: "9" });

    if (data && success) {
      setSiteAppOptions(
        data.map((item) => ({ id: item.siteApp.id, name: item.siteApp.name }))
      );
    }
  }, [sendRequest]);

  useEffect(() => {
    loadSiteApp();
  }, [loadSiteApp]);

  const { sendRequest: save, loading: sLoading } = useFetch(createNotification);

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateNotificationById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(getNotificationById);

  const saveHandler = (v: TNotificationForm) =>
    save({
      ...v,
      typeId: v.type.id,
      siteApps: v.siteApps.map((sa) => sa.id),
      notificationBatchContents: v.notificationBatchContents.map((content) => ({
        content: content.content,
        languageId: content.language ? content.language.id : "",
        title: content.title,
      })),
      notificationBatchFilters: v.notificationBatchFilters.map((filter) => ({
        ...filter,
        genderId: filter.gender ? filter.gender.id : undefined,
        maximumAge: +filter.maximumAge || undefined,
        minimumAge: +filter.minimumAge || undefined,
        profiles: filter.profiles.map((profile) => profile.id),
        users: filter.users.map((user) => user.id),
        isHolder:
          filter.isHolder === "isHolder"
            ? true
            : filter.isHolder === "isNotHolder"
            ? false
            : null,
        isPayer:
          filter.isPayer === "isPayer"
            ? true
            : filter.isPayer === "isNotPayer"
            ? false
            : null,
      })),
    });

  const updateHandler = (v: TNotificationForm, id: string) =>
    update({
      item: {
        ...v,
        typeId: v.type.id,
        siteApps: v.siteApps.map((sa) => sa.id),
        notificationBatchContents: v.notificationBatchContents.map(
          (content) => ({
            content: content.content,
            languageId: content.language ? content.language.id : "",
            title: content.title,
          })
        ),
        notificationBatchFilters: v.notificationBatchFilters.map((filter) => ({
          ...filter,
          genderId: filter.gender ? filter.gender.id : undefined,
          maximumAge: +filter.maximumAge || undefined,
          minimumAge: +filter.minimumAge || undefined,
          profiles: filter.profiles.map((profile) => profile.id),
          users: filter.users.map((user) => user.id),
          isHolder:
            filter.isHolder === "isHolder"
              ? true
              : filter.isHolder === "isNotHolder"
              ? false
              : null,
          isPayer:
            filter.isPayer === "isPayer"
              ? true
              : filter.isPayer === "isNotPayer"
              ? false
              : null,
        })),
      },
      id: id,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TNotificationForm>> => {
      const { data, success } = await get(id);

      if (data) {
        setStatus(data.status);
        setIsPublished(data.isPublished);
        if (data.startPublish) setStartPublish(data.startPublish);
        if (data.finishPublish) setFinishPublish(data.finishPublish);

        const newData: TNotificationForm = {
          ...data,
          notificationBatchContents: data.notificationBatchContents,
          notificationBatchFilters: data.notificationBatchFilters.map(
            (filter) => ({
              ...filter,
              maximumAge: filter.maximumAge ? filter.maximumAge.toString() : "",
              minimumAge: filter.minimumAge ? filter.minimumAge.toString() : "",
              users: filter.users.map((user) => ({
                id: user.id,
                name: user.fullName,
              })),
              isHolder:
                filter.isHolder === null
                  ? "both"
                  : filter.isHolder
                  ? "isHolder"
                  : !filter.isHolder
                  ? "isNotHolder"
                  : "both",
              isPayer:
                filter.isPayer === null
                  ? "both"
                  : filter.isPayer
                  ? "isPayer"
                  : !filter.isPayer
                  ? "isNotPayer"
                  : "both",
            })
          ),
        };

        return { data: newData, success };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TNotificationForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "notification")}
      schema={notificationSchema}
      subtitle={t(translationPath + "new_notification")}
      subtitleWatchField={"type.name"}
      fields={(type) => (
        <Grid container spacing={2}>
          {type === "update" && (
            <NotificationBatchActions statusCode={status?.code || ""} />
          )}

          {type === "update" && (
            <NotificationInformations
              statusName={status ? status.name : ""}
              isPublished={isPublished}
              startPublish={startPublish}
              finishPublish={finishPublish}
            />
          )}

          <Grid item xs={12} sm={6}>
            <AutoComplete
              params={{ identifier: "NotificationType" }}
              getOptionLabel={(option) => option.name}
              size="small"
              label={t(translationPath + "type")}
              name="type"
              request={genericService.list}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <AutoCompleteMulti
              size="small"
              label={t(translationPath + "site_app")}
              name="siteApps"
              options={siteAppOptions}
            />
          </Grid>

          <NotificationContents service={languageService.list} />

          <NotificationFilters />
        </Grid>
      )}
    />
  );
};

export default Notification;
