import { GlobalAxios } from "../axios/axios-config";
import { IEvent, IEvents } from "../models/event";

const EVENT_ROUTE = process.env.REACT_APP_TICKETS + "/api/v1/TicketEvent/";

export const createEvent = (item: IEvent) => {
  return GlobalAxios.post(EVENT_ROUTE, item);
};

export const getEventById = (id: string) => {
  return GlobalAxios.get<IEvents>(EVENT_ROUTE + id);
};

export const updateEventById = ({ item, id }: { item: IEvent; id: string }) => {
  return GlobalAxios.put(EVENT_ROUTE + id, item);
};
