import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";

const TICKET_SERVICE_LIST =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketWorkspaces/";

export const workspacesList = (params?: any) => {
  return GlobalAxios.get<ISelectType[]>(TICKET_SERVICE_LIST + "List", {
    params: params,
  });
};
