import { forwardRef, ReactElement, useImperativeHandle, useState } from "react";

import { Grid } from "@mui/material";
import { FormInput } from "@4uhub/lib4uhub";
import WhatsappApiConfigs from "../../../../../../components/WhatsConfigData/WhatsappApiConfigs";
import { applyTranslations } from "./typebot.utils";
import { useTranslation } from "react-i18next";

export interface IFieldsProps {}

export interface IFieldsRef {
    setVerifyToken: (token: string) => void;
}

const Fields = forwardRef<IFieldsRef, IFieldsProps>((props, ref): ReactElement => {
    const [verifyToken, setVerifyToken] = useState<string | undefined>();

    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({ setVerifyToken }), []);

    return  (
    <>
        {verifyToken && (
            <Grid container mb={8}>
                <WhatsappApiConfigs verifyToken={verifyToken} />
            </Grid>
        )}

        <Grid container spacing={2}>
            <FormInput 
                name="typebotViewerHost" 
                label={t(applyTranslations('typebot_viewer_host'))}
                size="small" 
                fullWidth 
            />
        </Grid>
  </>);
});

export default Fields;