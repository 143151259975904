import { GlobalAxios } from "../axios/axios-config";
import {
  IHealthProgramContentRule,
  INewHealthProgramContentRule,
} from "../models/health-program-content-rule";

const HEALTH_PROGRAM_CONTENT_RULE_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/HealthProgramContentRule/";

export const createHealthProgramContentRule = (
  item: INewHealthProgramContentRule
) => {
  return GlobalAxios.post(HEALTH_PROGRAM_CONTENT_RULE_ROUTE, item);
};

export const getHealthProgramContentRuleById = (id: string) => {
  return GlobalAxios.get<IHealthProgramContentRule>(
    HEALTH_PROGRAM_CONTENT_RULE_ROUTE + id
  );
};

export const updateHealthProgramContentRuleById = ({
  item,
  id,
}: {
  item: INewHealthProgramContentRule;
  id: string;
}) => {
  return GlobalAxios.put(HEALTH_PROGRAM_CONTENT_RULE_ROUTE + id, item);
};
