import { z } from "zod";

const translation_path = "page.extensionSettings.errors.";

export const extensionSettingSchema = z.object({
  id: z.string().optional(),
  isPublic: z.boolean(),
  identifier: z.string().min(1, translation_path + "identifier"),
  property: z.string().nullable(),
  value: z.string().min(1, translation_path + "value"),
  description: z.string().min(1, translation_path + "description"),
});

export type TExtensionSettingForm = z.infer<typeof extensionSettingSchema>;
