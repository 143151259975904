import { GlobalAxios } from "../axios/axios-config";
import { IGetBanners, INewBanner } from "../models/banners";

const HOLIDAYS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Banner";

export const createBanner = (item: INewBanner) => {
  return GlobalAxios.post(HOLIDAYS_ROUTE, item);
};

export const getBannerById = (id: string) => {
  return GlobalAxios.get<IGetBanners>(HOLIDAYS_ROUTE + "/" + id);
};

export const updateBannerById = ({
  item,
  id,
}: {
  item: INewBanner;
  id: string;
}) => {
  return GlobalAxios.put(HOLIDAYS_ROUTE + "/" + id, item);
};
