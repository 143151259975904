import { Stack, useTheme } from "@mui/system";
import { IExtension } from "../../models/extensions-store";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import NoData from "../Logs/NoData/NoData";
import ExtensionsCard from "./ExtensionsCard";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

interface IExtensionsStoreListProps {
  extensions: IExtension[];
  extensionsOption: boolean;
  searchInput: string;
  onOpenDetailHandler: (detail: IExtension) => void;
  onRealoadExtensionsList: () => void;
}

const translationPath = "page.extensionsStore.";

const ExtensionsStoreList = ({
  extensions,
  extensionsOption,
  searchInput,
  onOpenDetailHandler,
  onRealoadExtensionsList,
}: IExtensionsStoreListProps) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const hasNoExtensionsInstall = !extensions.length && !extensionsOption;
  const hasNoExtensionsInstalled = !extensions.length && extensionsOption;

  return (
    <motion.main
      id="main"
      key={extensionsOption.toString()}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
    >
      {!extensionsOption && extensions.length !== 0 && (
        <Stack mt={5}>
          <Typography variant="h6">{t(translationPath + "title")}</Typography>
          <Typography variant="subtitle1">
            {t(translationPath + "subtitle")}
          </Typography>
        </Stack>
      )}
      <Grid
        container
        mt={1}
        maxWidth={"100%"}
        display={"flex"}
        flexWrap={"wrap"}
        rowSpacing={5}
        flexDirection={matches ? "row" : "column"}
        columnSpacing={matches ? 50 : 0}
        sx={{
          paddingInline: matches ? 0 : 8,
        }}
      >
        {extensions.map((extension) => (
          <Grid item xs={12} sm={2} key={extension.id}>
            <ExtensionsCard
              card={extension}
              isAdded={extensionsOption}
              openDetail={() => onOpenDetailHandler(extension)}
              onRealoadExtensionsList={onRealoadExtensionsList}
            />
          </Grid>
        ))}
        {searchInput.length !== 0 && !extensions.length && (
          <Grid item sm={12}>
            <NoData message={t(translationPath + "no_data")} />
          </Grid>
        )}
        {!searchInput.length && hasNoExtensionsInstall && (
          <Grid item sm={12}>
            <NoData message={t(translationPath + "no_data_install")} />
          </Grid>
        )}
        {!searchInput.length && hasNoExtensionsInstalled && (
          <Grid item sm={12}>
            <NoData message={t(translationPath + "no_data_installed")} />
          </Grid>
        )}
      </Grid>
    </motion.main>
  );
};

export default ExtensionsStoreList;
