import { ReactElement, useCallback } from "react";
import { useParams } from "react-router-dom";

import { FormContainer, useFetch } from "@4uhub/lib4uhub";
import TabContainer from "../../Tabs/TabContainer";
import Fields from "./Fields";

import { ISalesContractPayload } from "../../../../../models/sales-contract";
import {
  createSalesContract,
  findSalesContractById,
  updateSalesContract,
} from "../../../../../services/salesContract.service";
import {
  advantagesAndBenefitsClubSchema,
  type TAdvantagesAndBenefitsClubForm,
} from "../advantage-and-benefits-club.schema";

const AdvantagesAndBenefitsClub = (): ReactElement => {
  const { id } = useParams();

  const { sendRequest: get, loading: isFetching } = useFetch(
    findSalesContractById
  );
  const { sendRequest: create, loading: isCreating } =
    useFetch(createSalesContract);
  const { sendRequest: update, loading: isUpdating } =
    useFetch(updateSalesContract);

  const handlePayload = useCallback(
    ({
      situation,
      mainContract,
      salesGrantor,
      salesContractType,
      individualStipulant,
      legalEntityStipulant,
      salesContractClassification,
      salesContractTerminationReason,
      ...rest
    }: TAdvantagesAndBenefitsClubForm) => {
      const payload: ISalesContractPayload = {
        ...rest,
        situationId: situation?.id,
        mainContractId: mainContract?.id,
        salesGrantorId: salesGrantor?.id,
        salesContractTypeId: salesContractType?.id,
        individualStipulantId: individualStipulant?.id,
        legalEntityStipulantId: legalEntityStipulant?.id,
        salesContractClassificationId: salesContractClassification?.id,
        salesContractTerminationReasonId: salesContractTerminationReason?.id,
      };

      return payload;
    },
    []
  );

  const handleDate = (date: string | Date) => {
    const timezone = `${date}T00:00:00Z`;

    return new Date(timezone);
  };

  const handlePerson = (data: any) => {
    if (data) {
      return {
        ...data,
        id: data?.identifier,
      };
    }

    return null;
  };

  const updateHandler = useCallback(
    (updates: TAdvantagesAndBenefitsClubForm, id: string) =>
      update({ id, item: handlePayload(updates) } as {
        id: string;
        item: ISalesContractPayload;
      }),
    [update, handlePayload]
  );

  const createHandler = useCallback(
    (values: TAdvantagesAndBenefitsClubForm) => create(handlePayload(values)),
    [create, handlePayload]
  );

  const getHandler = useCallback(
    async (id: string) => {
      const { data, success } = await get(id);

      if (data && success) {
        let entity: "QPF" | "QPJ" = "QPF";

        if (data?.legalEntityStipulant) {
          entity = "QPJ";
        }

        const response = {
          ...data,
          entity,
          contractDate: handleDate(data?.contractDate),
          renewalPeriodMonths: data?.renewalPeriodMonths?.toString?.(),
          salesContractType: data?.salecContractType,
          mainContract: data?.mainContract
            ? {
                ...data?.mainContract,
                name: data?.mainContract?.contractTitle,
              }
            : null,
          individualStipulant: handlePerson(data?.individualStipulant),
          legalEntityStipulant: data?.legalEntityStipulant,
        };

        return { data: response, success };
      }

      return { data, success } as any;
    },
    [get]
  );

  const isLoading = isFetching || isCreating || isUpdating;

  return (
    <TabContainer>
      <FormContainer<TAdvantagesAndBenefitsClubForm>
        title=""
        subtitle=""
        isModalForm
        propId={id}
        loading={isLoading}
        schema={advantagesAndBenefitsClubSchema}
        getFunction={getHandler}
        saveFunction={createHandler}
        updateFunction={updateHandler}
        fields={() => <Fields />}
      />
    </TabContainer>
  );
};

export default AdvantagesAndBenefitsClub;
