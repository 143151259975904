import {
  Button,
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetch, useNotificationContext } from "@4uhub/lib4uhub";
import CacheService from "../../../../../services/cache.service";

const translationPath = "components.settingsCache.settingsCacheTotal.";

const service = new CacheService();

const SettingsCacheTotal = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const { setMessage } = useNotificationContext();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { sendRequest, loading } = useFetch(service.cleanAllCache);

  const onCleanCaches = async () => {
    const { success } = await sendRequest(null);
    if (success) {
      setMessage({
        message: `${t(translationPath + "clean_all_success")}`,
        type: "success",
        notificationKey: new Date().getMilliseconds(),
      });
    }
  };

  return (
    <Stack
      display={"flex"}
      flexDirection={!matches ? "row" : "column"}
      alignItems={!matches ? "center" : "end"}
      justifyContent={!matches ? "space-between" : undefined}
      gap={2}
    >
      <Stack>
        <Typography variant="subtitle1" fontWeight={"bold"}>
          {t(translationPath + "clean_all_cache")}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={(theme) => ({
            color:
              theme.palette.mode === "light"
                ? theme.palette.grey[600]
                : theme.palette.grey[400],
          })}
        >
          {t(translationPath + "clean_all_description")}
        </Typography>
      </Stack>
      <Button
        variant="contained"
        disabled={loading ? true : false}
        onClick={onCleanCaches}
      >
        {loading && (
          <Stack direction={"row"} spacing={2}>
            <CircularProgress size={25} sx={{ marginInline: 2 }} />
          </Stack>
        )}
        {!loading && t(translationPath + "clean_all_button")}
      </Button>
    </Stack>
  );
};

export default SettingsCacheTotal;
