import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../services/mainApi.service";
import {
  Modal,
  Table,
  useFetch,
  useNotificationContext,
  useRole,
} from "@4uhub/lib4uhub";
import LockIcon from "@mui/icons-material/Lock";
import { useCallback, useState } from "react";
import UsersResetModal from "./UsersResetModal";
import UsersResetSuccess from "./UsersResetSuccess";

import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { authService } from "../../../services/auth.service";
import { IGetAllUsers } from "../../../models/users";
import { Chip } from "@mui/material";
import { format } from "date-fns";
import { ptBR, enUS } from "date-fns/locale";

const translationPath = "page.register.users.";

const USERS_ROUTE = process.env.REACT_APP_IDENTIY + "/api/v1/Users/";
const usersService = new MainApiService<IGetAllUsers>(USERS_ROUTE);

const UsersPage = () => {
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [open, setOpen] = useState(false);
  const { setMessage } = useNotificationContext();
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IGetAllUsers>();

  const { sendRequest: resendEmail, loading } = useFetch(
    authService.resendEmail
  );

  const createPermission = useRole(["Create"]);

  const columns: GridColDef<IGetAllUsers>[] = [
    {
      field: "userName",
      headerName: t(translationPath + "username") || "Username",
    },
    {
      field: "fullName",
      headerName: t(translationPath + "full_name") || "Full name",
    },
    {
      field: "email",
      headerName: t(translationPath + "email") || "E-mail",
    },
    {
      field: "emailConfirmed",
      headerName:
        t(translationPath + "email_confirmed_label") || "E-mail status",
      maxWidth: 150,
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            label={
              params.value
                ? t(translationPath + "email_confirmed_tag")
                : t(translationPath + "email_not_confirmed_tag")
            }
            color={params.value ? "success" : "error"}
          />
        );
      },
    },
    {
      field: "emailConfirmationDate",
      headerName:
        t(translationPath + "email_confirmation_date") ||
        "E-mail confirmation date",
      renderCell: (params) => {
        return params.row.emailConfirmationDate
          ? format(
              new Date(params.row.emailConfirmationDate.substring(0, 19)),
              "Pp",
              {
                locale: language === "pt-BR" ? ptBR : enUS,
              }
            ).replaceAll(",", "")
          : "-";
      },
    },
  ];

  const handleRowClick = (params: GridRowParams<IGetAllUsers>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  const onCloseHandler = () => setOpen(false);

  const onCloseModalHandler = () => setOpenModal(false);
  const onOpenModalHandler = () => setOpenModal(true);

  const onResendEmailConfirmation = useCallback(
    async (username: string) => {
      const { success } = await resendEmail({
        userName: username,
        softwareCode: "1",
      });
      if (success) {
        setMessage({
          message: `${t(
            "components.forgetPasswordForm.notification_email_sent"
          )}`,
          type: "success",
          notificationKey: new Date().getMilliseconds(),
        });
      }
    },
    [setMessage, resendEmail, t]
  );

  return (
    <>
      <Modal open={openModal} onClose={onCloseModalHandler} maxDialogWidth="sm">
        <UsersResetSuccess email={selectedUser ? selectedUser.email : ""} />
      </Modal>
      <Modal open={open} onClose={onCloseHandler} maxDialogWidth="sm">
        <UsersResetModal
          user={selectedUser!}
          onCancel={onCloseHandler}
          onCloseHandler={onCloseHandler}
          onOpenHandler={onOpenModalHandler}
        />
      </Modal>
      <Table<IGetAllUsers>
        showDefaultMacroFilters={false}
        service={usersService}
        columns={columns}
        title={t(translationPath + "users") || "System users"}
        searchInput
        onAdd={handleAdd}
        showDeleteButton={false}
        showButton={createPermission}
        onRowClick={handleRowClick}
        showDelete={false}
        defaultPageSize={20}
        syncing={loading}
        pageSizeOptions={[5, 10, 20]}
        searchInputPlaceHolder={
          t(translationPath + "search_user") || "Searh for a user"
        }
        addButtonLabel={t(translationPath + "add_user") || "Add User"}
        rowActions={(params) => [
          {
            action: (params) => {
              setSelectedUser(params.row);
              setOpen(true);
            },
            icon: <LockIcon fontSize="small" />,
            text: `${t(translationPath + "reset_password.button")}`,
          },
          params.row.emailConfirmed
            ? null
            : {
                action: (params) => {
                  onResendEmailConfirmation(params.row.userName);
                },
                icon: <MarkEmailReadIcon fontSize="small" />,
                text: `${t(translationPath + "resend_mail")}`,
              },
        ]}
        confirmOptions={{
          title: t(translationPath + "confirm.title"),
          description: t(translationPath + "confirm.description"),
          confirmationButtonProps: {
            variant: "contained",
          },
        }}
      />
    </>
  );
};

export default UsersPage;
