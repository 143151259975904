import { GlobalAxios } from "./../axios/axios-config";
import {
    INewTicketAutomationRuleMessage,
    ITicketAutomationRuleMessage,
} from "../models/ticket-automation-message";

const TICKET_SERVICE_WHATS_ROUTE =
  process.env.REACT_APP_AUTOMATIONS + "/api/v1/TicketAutomationRuleMessage/";

export const createTicketAutomationRuleMessage = (
  item: INewTicketAutomationRuleMessage
) => {
  return GlobalAxios.post(TICKET_SERVICE_WHATS_ROUTE, item);
};

export const getTicketAutomationRuleMessageById = (id: string) => {
  return GlobalAxios.get<ITicketAutomationRuleMessage>(
    TICKET_SERVICE_WHATS_ROUTE + id
  );
};

export const updateTicketAutomationRuleMessageById = ({
  item,
  id,
}: {
  item: INewTicketAutomationRuleMessage;
  id: string;
}) => {
  return GlobalAxios.put(TICKET_SERVICE_WHATS_ROUTE + id, item);
};
