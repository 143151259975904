import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

// Schema
import {
  DateInput,
  FormContainer,
  FormInput,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import {
  TProtocolGenerateForm,
  protocolGenerateSchema,
} from "./ProtocolGenerateSchema";
import ProtocolRuleDetails from "./ProtocolRuleDetails";
import { useCallback } from "react";
import ProtocolGenerateRuleService from "../../../../../../services/protocolGenerateRule.service";

const protocolGenerateRuleService = new ProtocolGenerateRuleService();

const translationPath = "chatBot.page.service.protocolGenerateRule.";

const ProtocolGenerate = () => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(
    protocolGenerateRuleService.create
  );

  const { sendRequest: update, loading: uLoading } = useFetch(
    protocolGenerateRuleService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    protocolGenerateRuleService.getItemById
  );

  const handleGet = useCallback(
    async (id: string): Promise<Treturn<TProtocolGenerateForm>> => {
      const { data, success } = await get(id);
      if (data) {
        return {
          data: {
            ...data,
            initialDate: new Date(data?.initialDate),
            endDate: new Date(data?.endDate),
            name: data.name,
            ticketProtocolRuleDetails: data.ticketProtocolRuleDetails
              .sort((a, b) => a.position - b.position)
              .map((rule) => ({
                fixedCode: rule.fixedCode,
                mask: rule.mask,
                position: {
                  id: rule.position.toString(),
                  name: rule.position.toString(),
                },
                ticketProtocolRuleFieldType: {
                  id: rule.ticketProtocolRuleFieldType.id,
                  name: rule.ticketProtocolRuleFieldType.name,
                },
              })),
          },
          success: true,
        };
      }
      return { data, success };
    },
    [get]
  );

  const handleSave = (v: TProtocolGenerateForm) => {
    return save({
      ...v,
      initialDate: v.initialDate.toISOString(),
      endDate: v.endDate.toISOString(),
      ticketProtocolRuleDetails: v.ticketProtocolRuleDetails.map((rule) => ({
        fixedCode: rule.fixedCode || "",
        mask: rule.mask,
        position: Number(rule.position?.name),
        ticketProtocolRuleFieldTypeId:
          rule.ticketProtocolRuleFieldType?.id || "",
      })),
    });
  };

  const handleUpdate = (v: TProtocolGenerateForm, id: string) => {
    return update({
      item: {
        ...v,
        initialDate: v.initialDate.toISOString(),
        endDate: v.endDate.toISOString(),
        ticketProtocolRuleDetails: v.ticketProtocolRuleDetails.map((rule) => ({
          fixedCode: rule.fixedCode || "",
          mask: rule.mask,
          position: Number(rule.position?.name),
          ticketProtocolRuleFieldTypeId:
            rule.ticketProtocolRuleFieldType?.id || "",
        })),
      },
      id: id,
    });
  };

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TProtocolGenerateForm>
      saveFunction={handleSave}
      updateFunction={handleUpdate}
      getFunction={handleGet}
      loading={loading}
      title={t(translationPath + "protocol_generate_rule")}
      schema={protocolGenerateSchema}
      subtitle={t(translationPath + "new_protocol_generate_rule")}
      subtitleWatchField={"name"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            <SwitchInput name="enable" label={t(translationPath + "enable")} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="name"
              label={t(translationPath + "name")}
              autoFocus
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <DateInput
              name="initialDate"
              label={t(translationPath + "initial_date")}
              initialDate={new Date()}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <DateInput name="endDate" label={t(translationPath + "end_date")} />
          </Grid>

          <ProtocolRuleDetails />
        </Grid>
      )}
    />
  );
};

export default ProtocolGenerate;
