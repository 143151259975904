import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import {
  IProductContractClassification,
  IProductContractClassificationPayload,
} from "../models/contract-product-classification";

const serviceURL =
  process.env.REACT_APP_MAIN + "/api/v1/SalesProductCtrClassification";

export const listProductsCtrClassifications = () => {
  return GlobalAxios.get<ISelectType[]>(`${serviceURL}/list`);
};

export const createContractProductClassification = (
  payload: IProductContractClassificationPayload
) => {
  return GlobalAxios.post(serviceURL, payload);
};
export const updateContractProductClassification = ({
  item,
  id,
}: {
  item: IProductContractClassificationPayload;
  id: string;
}) => {
  return GlobalAxios.put(`${serviceURL}/${id}`, item);
};

export const findContractProductClassificationById = (id: string) => {
  return GlobalAxios.get<IProductContractClassification>(`${serviceURL}/${id}`);
};

export const listContractProductsClassification = () => {
  return GlobalAxios.get<ISelectType[]>(`${serviceURL}/list`);
};

export const removeContractProductClassification = (id: string) => {
  return GlobalAxios.delete<boolean>(`${serviceURL}/${id}`);
};
