import { GlobalAxios } from "../axios/axios-config";
import {
  IGetProfileTasyBus,
  INewProfileTasyBus,
} from "../models/profile-tasy-bus";

const PROFILE_TASY_BUS_ROUTE =
  process.env.REACT_APP_4UBUS + "/api/v1/RestrictionRuleProfile/";

export const createProfileTasyBus = (item: INewProfileTasyBus) => {
  return GlobalAxios.post<{ id: string }>(PROFILE_TASY_BUS_ROUTE, item);
};

export const getProfileTasyBusById = (id: string) => {
  return GlobalAxios.get<IGetProfileTasyBus>(PROFILE_TASY_BUS_ROUTE + id);
};

export const updateProfileTasyBusById = ({
  item,
  id,
}: {
  item: INewProfileTasyBus;
  id: string;
}) => {
  return GlobalAxios.put(PROFILE_TASY_BUS_ROUTE + id, item);
};
