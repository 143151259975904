import { memo } from "react";

import { IBannerFileProps } from "./models";
import BannerFileValue from "../BannerFileValue/BannerFileValue";

const BannerFile: React.FC<IBannerFileProps> = ({ index, onFileSelected }) => {
  return <BannerFileValue onFileSelected={onFileSelected} index={index} />;
};

export default memo(BannerFile);
