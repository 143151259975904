import { FormLabel, Stack } from "@mui/material";

import IconButton from "./IconButton";
import { IItem, ISelectIconProps } from "./model";
import { genericMemo } from "../SubFormArray/genericMemo";

const SelectIcon = <T extends IItem>({
  options,
  label,
  addIcon,
  ...props
}: ISelectIconProps<T>) => {
  const onClickHandler = (data: T) => {
    if (props.multi) {
      const { onChange, value } = props;
      if (!value) {
        onChange([data]);
      } else {
        const index = value.findIndex((d) => d.id === data.id);

        if (index === -1) {
          value.push(data);
        } else {
          value.splice(index, 1);
        }

        onChange(value);
      }
    } else if (props.multi === false) {
      const { onChange, value } = props;

      if (value?.id === data.id) {
        onChange(null);
      } else {
        onChange(data);
      }
    }
  };

  const isSelected = (v: T): boolean => {
    if (props.multi) {
      const { value } = props;

      return !!value?.find(pV => pV.id === v.id);
    } else if (props.multi === false) {
      const { value } = props;

      return value?.id === v.id;
    }

    return false;
  };

  return (
    <Stack gap={1}>
      {label && <FormLabel>{label}</FormLabel>}
      <Stack flexDirection={"row"} gap={1}>
        {options.map((o) => (
          <IconButton
            item={o}
            onClick={onClickHandler}
            selected={isSelected(o)}
            key={o.id}
          />
        ))}
        {addIcon && addIcon}
      </Stack>
    </Stack>
  );
};

export default genericMemo(SelectIcon);
