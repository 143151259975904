import MainApiService from "../../../../services/mainApi.service";

import { IContractAttachmentType } from "../../../../models/contract-attachment-type";

export const endpoint =
  process.env.REACT_APP_MAIN + "/api/v1/SalesContractAttachmentType/";

export const contractAttachmentTypeService =
  new MainApiService<IContractAttachmentType>(endpoint);

export const translationPath =
  "page.4uSalesContract.registers.contractAttachmentType.";

export const buildTPath = (field: string): string =>
  `${translationPath}${field}`;
