import { z } from "zod";

const translationPath = "chatBot.page.register.ticketServiceWhats.errors.";

const ticketServiceSchema = z.object({
  verifyToken: z.string(),
});

export const ticketServiceWhatsSchema = z.object({
  id: z.string().optional(),
  whatsAppBusinessPhoneNumberId: z
    .string()
    .min(1, translationPath + "business_phone_number"),
  whatsAppBusinessAccountId: z
    .string()
    .min(1, translationPath + "business_account"),
  whatsAppBusinessId: z.string().min(1, translationPath + "whatsapp_business"),
  accessToken: z.string().min(1, translationPath + "access_token"),
  ticketService: ticketServiceSchema.optional(),
});

export type TTicketServiceWhatsForm = z.infer<typeof ticketServiceWhatsSchema>;
