import React from "react";
import { Stack } from "@mui/material";
import MenuActions from "./MenuActions";
import { IconMenu } from "./IconMenu";
import { ISideMenuProps } from "./models";

export const SideMenu: React.FC<ISideMenuProps> = ({
  children,
  showMenuIcon = true,
  sx,
}) => {
  return (
    <Stack
      direction={{ sm: "column", lg: "row" }}
      height={{ lg: "100%", xl: "100%" }}
      gap={2}
      sx={sx}
    >
      <Stack
        direction={{ xs: "row", sm: "row", lg: "column" }}
        alignItems={"center"}
        justifyContent={showMenuIcon ? "space-between" : "flex-end"}
        height="100%"
      >
        {showMenuIcon && <IconMenu />}

        <MenuActions>{children}</MenuActions>
      </Stack>
    </Stack>
  );
};
