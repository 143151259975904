import { z } from "zod";

const translation_path = "page.register.languages.errors.";

const establishment = z.object({
  id: z.string(),
  companyId: z.string().optional(),
  name: z.string(),
  code: z.string().optional().nullable(),
  enable: z.boolean().optional(),
});

export const languagesSchema = z.object({
  name: z.string().nonempty(translation_path + "name"),
  establishments: z
    .array(establishment)
    .nonempty(translation_path + "establishments"),
  code: z.string().optional().nullable(),
  enable: z.boolean(),
  id: z.string().optional(),
});

export type TLanguagesForm = z.infer<typeof languagesSchema>;
