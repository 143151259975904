import { Stack } from "@mui/material";
import { memo, useMemo, useRef } from "react";
import { Button } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

import Event from "./Event";
import useElementSize from "../../../../hooks/useElementSize";
import usePopover from "../../../../hooks/usePopover";
import EventPopOver from "./EventPopOver";
import { ICalendar } from "../../model";

interface IEventListProps {
  events: ICalendar[];
  day: Date;
}

const EventList: React.FC<IEventListProps> = ({ events, day }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { clientHeight } = useElementSize(containerRef);

  const { anchorEl, handleClick, handleClose, id, open } = usePopover(
    "More events Popover"
  );

  const { t } = useTranslation();

  const numberOfEvents = useMemo(
    () => Math.floor(clientHeight / 32),
    [clientHeight]
  );

  const otherEvents = useMemo(
    () => events.length - numberOfEvents,
    [events, numberOfEvents]
  );

  return (
    <>
      <Stack
        alignItems={"flex-start"}
        ref={containerRef}
        sx={{ width: "100%" }}
        gap={1}
        height={`calc(100% - ${otherEvents > 0 ? "56px" : "24px"})`}
      >
        {events.slice(0, numberOfEvents).map((event, i) => (
          <Event data={event} key={event.identifier} />
        ))}
      </Stack>
      {otherEvents > 0 && (
        <Button size="small" onClick={handleClick}>
          {t("components.schedule.seeMore", {
            quantity: otherEvents,
          })}
        </Button>
      )}
      <EventPopOver
        anchorEl={anchorEl}
        date={day}
        events={events}
        handleClose={handleClose}
        open={open}
        id={id}
      />
    </>
  );
};

export default memo(EventList);
