import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { ChangeEvent, memo, useCallback, useEffect, useState } from "react";
import { ISelectType, useFetch } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

import { ScheduleAppointmentService } from "../Schedule.service";

interface IPeriodProps {
  value: string;
  onChange: (v: string) => void;
}

const Period: React.FC<IPeriodProps> = ({ onChange, value }) => {
  const [periods, setPeriods] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(ScheduleAppointmentService.getPeriodOptions);

  const { t } = useTranslation();

  const fetch = useCallback(async () => {
    const { data, success } = await sendRequest(null);

    if (success && data) {
      setPeriods([
        {
          id: "Ambos",
          name: t("components.schedule.menu.source.both"),
          code: "-1",
        },
        ...data,
      ]);
    }
  }, [sendRequest, t]);

  const onChangeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    onChange(value);
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <FormControl size="small">
      <FormLabel id="demo-radio-buttons-group-label">
        {t("components.schedule.menu.period")}
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={value}
        sx={{ gap: 0 }}
        onChange={onChangeHandler}
      >
        {periods.map((s) => (
          <FormControlLabel
            key={s.id}
            value={s.code}
            control={<Radio size="small" sx={{ padding: 1, pl: 2 }} />}
            sx={{ fontSize: 10 }}
            label={<Typography sx={{ fontSize: 14 }}>{s.name}</Typography>}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default memo(Period);
