import {
  createContext,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export interface ITemplateWhatsBasicData {
  title: string;
  body: string;
  footer: string;
}

interface IFormMessageContextProps {
  templateWhatsBasicData: ITemplateWhatsBasicData;
  variables: string[];
  fetchVariables: (values: string[]) => void;
  updateBasicData: (fieldName: string, value: string) => void;
}

const FormMessageContext = createContext<IFormMessageContextProps | undefined>(
  undefined
);

interface IFormMessageProviderProps {
  defaultbody: string;
  children: JSX.Element;
}

export const FormMessageProvider: React.FC<IFormMessageProviderProps> = memo(
  ({ defaultbody, children }) => {
    const [variables, setVariables] = useState<string[]>([]);

    const fetchVariables = useCallback((values: string[]) => {
      setVariables(values);
    }, []);

    const [templateWhatsBasicData, setTemplateWhatsBasicData] =
      useState<ITemplateWhatsBasicData>({
        title: "",
        body: defaultbody,
        footer: "",
      });

    const updateBasicData = useCallback((fieldName: string, value: string) => {
      setTemplateWhatsBasicData((prev) => ({
        ...prev,
        [fieldName]: value,
      }));
    }, []);

    const value = useMemo(
      () => ({
        templateWhatsBasicData,
        variables,
        updateBasicData,
        fetchVariables,
      }),
      [templateWhatsBasicData, variables, updateBasicData, fetchVariables]
    );

    return (
      <FormMessageContext.Provider value={value}>
        {children}
      </FormMessageContext.Provider>
    );
  }
);

export const useFormMessage = () => {
  const context = useContext(FormMessageContext);

  if (!context) {
    throw new Error("useFormMessage must be used with TemplateWhatsProvider");
  }

  return context;
};
