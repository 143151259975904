import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useConfirm } from "material-ui-confirm";
import { useFetch } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

import DashBoardService from "../../../../services/dashboard.service";
import { useDashboard } from "../../../../store/contexts/DashBoardContext";
import { usePanel } from "../../../../store/contexts/DashBoardPannelContext";
import { useCallback } from "react";

const service = new DashBoardService();

const DeleteDashboard = () => {
  const confirm = useConfirm();

  const { t } = useTranslation();

  const { onDeleteDashboard } = usePanel();

  const {
    dashboard: { id, name },
  } = useDashboard();

  const { sendRequest } = useFetch(service.deleteDashboard);

  const handleClick = useCallback(async () => {
    try {
      await confirm({
        confirmationButtonProps: {
          variant: "contained",
        },
        title: t("components.panel.delete_dashboard.title", { name }),
        description: t("components.panel.delete_dashboard.description"),
        cancellationText: t("components.panel.delete_dashboard.cancel"),
        confirmationText: t("components.panel.delete_dashboard.confirm"),
      });

      const { success } = await sendRequest(id);

      if (success) {
        onDeleteDashboard(id);
      }
    } catch (error) {}
  }, [name, id, onDeleteDashboard, sendRequest, confirm, t]);

  return (
    <>
      <MenuItem onClick={handleClick}>
        <ListItemIcon>
          <DeleteIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          {t("components.panel.delete_dashboard.delete")}
        </ListItemText>
      </MenuItem>
    </>
  );
};
export default DeleteDashboard;
