import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import DocumentForm from "./DocumentForm";
import DocumentsApiService from "../../../../../services/documents.service";
import { DocumentsSchema, TDocumentsForm } from "./DocumentsSchema";
import { useParams } from "react-router-dom";
import { useCallback, useState } from "react";
import { useAppSelector } from "../../../../../store/store";
import MainApiService from "../../../../../services/mainApi.service";
import { IDocumentVersion } from "../../../../../models/documents";
import {
  DateTime,
  FormContainer,
  FormInput,
  ISelectType,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";

const documentsService = new DocumentsApiService();
const translationPath = "page.register.documents.create_document.";

const DOCS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/DocAcceptance/";
const lastDocumentService = new MainApiService<IDocumentVersion>(DOCS_ROUTE);
const LANGUAGES_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Language/";
const service = new MainApiService<ISelectType>(LANGUAGES_ROUTE);

const Documents = () => {
  const { t } = useTranslation();
  const { docId } = useParams();
  const document = useAppSelector((state) => state.documents);
  const { sendRequest: getLastDoc } = useFetch(lastDocumentService.getItemById);
  const [defaultLanguage, setDefaultLanguage] = useState<ISelectType[]>();

  const { sendRequest } = useFetch(service.list);

  const { sendRequest: save, loading: sLoading } = useFetch(
    documentsService.create
  );
  const { sendRequest: update, loading: uLoading } = useFetch(
    documentsService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    documentsService.getItemById
  );

  const defaultValues = async () => {
    const { data } = await sendRequest(null);
    if (data) {
      setDefaultLanguage(data);
    }
    const { data: lastDoc } = await getLastDoc(docId!);
    return {
      version: lastDoc?.version || "",
      versionDate: new Date(),
      enable: lastDoc?.enable || true,
      docAcceptanceDocuments: lastDoc?.version
        ? lastDoc.docAcceptanceDocuments.map((doc: any) => ({
            language: doc.language,
            content: doc.content,
            name: doc.name,
          }))
        : [
            {
              language: data ? data[0] : undefined,
              content: "",
              name: "",
            },
          ],
    };
  };

  const handleGetFunction = useCallback(
    async (id: string): Promise<Treturn<TDocumentsForm>> => {
      const { data, success } = await get(id);
      if (data) {
        return {
          data: {
            ...data,
            versionDate: new Date(data.versionDate),
          },
          success: true,
        };
      }
      return { data, success };
    },
    [get]
  );

  const handleSave = (v: TDocumentsForm) => {
    return save({
      ...v,
      versionDate: v.versionDate.toISOString(),
      docAcceptanceDocuments: v.docAcceptanceDocuments.map((d) => ({
        ...d,
        languageId: d.language ? d.language.id : "",
      })),
      docTypeId: document ? document.id : "",
      docAcceptanceParentId: v.docAcceptanceParentId || null,
    });
  };

  const handleUpdate = (v: TDocumentsForm, id: string) => {
    return update({
      item: {
        ...v,
        versionDate: v.versionDate.toISOString(),
        docAcceptanceDocuments: v.docAcceptanceDocuments.map((d) => ({
          ...d,
          languageId: d.language ? d.language.id : "",
        })),
        docAcceptanceParentId: v.docAcceptanceParentId || null,
        id: id,
      },
      id: id,
    });
  };

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TDocumentsForm>
      loading={loading}
      title={t("page.register.documents.documents" || "Documents")}
      saveFunction={handleSave}
      updateFunction={handleUpdate}
      getFunction={handleGetFunction}
      schema={DocumentsSchema}
      subtitle={document ? document.name : ""}
      defaultValues={defaultValues}
      fields={() => (
        <Grid container spacing={2}>
          {/* Basic Data */}
          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="version"
              label={t(translationPath + "version")}
              fullWidth
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DateTime
              name="versionDate"
              label={t(translationPath + "version_date")}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <SwitchInput
              name="enable"
              label={t(translationPath + "enable") || "Enable"}
            />
          </Grid>
          {/* Create documents */}
          <DocumentForm
            service={service.list}
            defaultLanguage={defaultLanguage}
          />
        </Grid>
      )}
    />
  );
};

export default Documents;
