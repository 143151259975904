import { Stack, SxProps, Theme, Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

interface ILegendPRops {
  fullDayColor?: string;
  someAvaiableColor?: string;
  allAvaiableColor?: string;
}

const Legend: React.FC<ILegendPRops> = ({
  allAvaiableColor,
  fullDayColor,
  someAvaiableColor,
}) => {
  const { t } = useTranslation();

  const style: SxProps<Theme> = (t) => ({
    p: 1,
    ...t.typography.caption,
    borderRadius: 1,
    color: t.palette.common.black,
  });

  return (
    <Stack gap={1}>
      <Typography>{t("components.schedule.menu.subtitle.title")}</Typography>
      <Typography sx={[style, { backgroundColor: someAvaiableColor }]}>
        {t("components.schedule.menu.subtitle.someFree")}
      </Typography>
      <Typography sx={[style, { backgroundColor: fullDayColor }]}>
        {t("components.schedule.menu.subtitle.full")}
      </Typography>
      <Typography sx={[style, { backgroundColor: allAvaiableColor }]}>
        {t("components.schedule.menu.subtitle.allFree")}
      </Typography>
    </Stack>
  );
};

export default memo(Legend);
