import { Box, Typography, Stack } from "@mui/material";

import { IIconButtonProps, IItem } from "./model";
import { genericMemo } from "../SubFormArray/genericMemo";

const SIZE = 70;

const IconButton = <T extends IItem>({
  item,
  sx,
  disable,
  onClick,
  selected,
}: IIconButtonProps<T>) => {
  const onClickHandler = () => {
    onClick(item);
  };

  return (
    <Stack
      alignItems={"center"}
      maxWidth={SIZE * 1.5}
      onClick={onClickHandler}
      sx={[
        {
          "&:hover": {
            opacity: 0.7,
            cursor: "pointer",
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        sx={(t) => ({
          backgroundColor: selected
            ? t.palette.primary.main
            : t.palette.grey[400],
          borderRadius: 2,
          width: SIZE,
          height: SIZE,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
        })}
      >
        {item.icon}
      </Box>
      {item.name && <Typography textAlign={"center"}>{item.name}</Typography>}
    </Stack>
  );
};

export default genericMemo(IconButton);
