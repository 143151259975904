import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";

import DeleteIcon from "@mui/icons-material/Delete";
import { useConfirm } from "material-ui-confirm";

import { ILegalEntityList } from "../../../models/legalEntitiy";
import { useMemo } from "react";
import { CNPJMask } from "../../../utils/maks";
import { IMultipleRowActions, MainApiService, Table, useFetch, useRole } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../../../axios/axios-config";

const LEGAL_ENTITY_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/LegalEntity/";

const TRANSLATION_PATH = "page.register.legalPerson.";

const genericService = new MainApiService<ILegalEntityList>(
  LEGAL_ENTITY_ROUTE,
  GlobalAxios
);

const PessoaJuridica = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const deleteLegalEntity = useFetch(genericService.deleteItemById);
  const confirm = useConfirm();
  const createPermission = useRole(["Create"]);
  const deletePermission = useRole(["Delete"]);

  // Todo
  const columns: GridColDef<ILegalEntityList>[] = useMemo(
    () => [
      {
        field: "document",
        headerName: t(TRANSLATION_PATH + "document") || "Documento",
        maxWidth: 150,
        renderCell: (params) => {
          return params.value ? CNPJMask.resolve(params.value) : "";
        },
      },
      {
        field: "corporateName",
        headerName: t(TRANSLATION_PATH + "corporateName") || "Corporate Name",
        flex: 1,
      },
      {
        field: "tradingName",
        headerName: t(TRANSLATION_PATH + "tradingName") || "Trading Name",
        flex: 1,
      },
    ],
    [t]
  );

  const handleRowClick = (params: GridRowParams<ILegalEntityList>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  const multipleRowsAction: IMultipleRowActions[] = [
    {
      text:
        t(TRANSLATION_PATH + "delete_legalPersons") ||
        "Deletar Pessoas Jurídicas",
      icon: <DeleteIcon fontSize="small" />,
      action(ids, api) {
        confirm({
          title: t(TRANSLATION_PATH + "confirm.title_plural"),
          description: t(TRANSLATION_PATH + "confirm.description_plural"),
          cancellationText:
            t("components.table.confirm.cancellationText") || "Cancelar",
          confirmationText:
            t("components.table.confirm.confirmationText") || "Sim, deletar",
        })
          .then(() => {
            ids.forEach(async (id) => {
              const data = await deleteLegalEntity.sendRequest(id.toString());
              if (data) {
                api.current.updateRows([{ id: id, _action: "delete" }]);
              }
            });
          })
          .catch(() => {
            /* ... */
          });
      },
    },
  ];

  return (
    <Table<ILegalEntityList>
      showDefaultMacroFilters={false}
      service={genericService}
      columns={columns}
      title={t(TRANSLATION_PATH + "legalPersons") || "Pessoas Jurídicas"}
      searchInput
      multipleRowsActions={multipleRowsAction}
      onAdd={handleAdd}
      showButton={createPermission}
      defaultActions={deletePermission}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={
        t(TRANSLATION_PATH + "search_legalPerson") ||
        "Procure por Documento, Razão Social ou Nome Fantasia"
      }
      addButtonLabel={
        t(TRANSLATION_PATH + "add_legalPerson") || "Adicionar Pessoa Jurídica"
      }
      confirmOptions={{
        title: t(TRANSLATION_PATH + "confirm.title"),
        description: t(TRANSLATION_PATH + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default PessoaJuridica;
