import { GlobalAxios } from "./../axios/axios-config";
import { ISearchParams, ISearchReturn } from "../models/requests";
import MainApiService from "./mainApi.service";

export class ExtensionsGeneralService<T extends object> extends MainApiService<T> {
  route_path: string;

  constructor(rp: string) {
    super(rp)
    this.route_path = rp;
  }

  getAll = (serchParams?: ISearchParams) => {
    return GlobalAxios.get<ISearchReturn<T>>(this.route_path, {
      params: {
        ...serchParams,
      },
    });
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<T>(this.route_path + id);
  };

  updateItemById = ({ item, id }: { item: T; id: string }) => {
    return GlobalAxios.put(this.route_path + id, item);
  };

  deleteItemById = (id: string) => {
    return GlobalAxios.delete(this.route_path + "/" + id);
  };

}

export default ExtensionsGeneralService;
