import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";
import { AutoComplete, FormInput, SwitchInput } from "@4uhub/lib4uhub";

import { buildTPath } from "../contract-attachment-type.utils";
import { salesContractTypeService } from "../../../sales-contract.utils";

const Fields = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12} sm={12} textAlign="end">
          <SwitchInput name="enable" label={t(buildTPath("enabled"))} />
        </Grid>

        <Grid item xs={12} sm={5}>
          <FormInput
            size="small"
            name="name"
            fullWidth
            label={t(buildTPath("name"))}
          />
        </Grid>

        <Grid item xs={12} sm={5}>
          <AutoComplete
            size="small"
            name="salesContractType"
            label={t(buildTPath("contract_type"))}
            fullWidth
            getOptionLabel={({ name }) => name}
            request={salesContractTypeService.list}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Fields;
