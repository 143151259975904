import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../../../services/mainApi.service";
import { Typography } from "@mui/material";
import { ISelectType, Table } from "@4uhub/lib4uhub";

const PARAM_DEFAULT_ESTABLISHMENT_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/ParamDefaultEstablishment/";
const genericService = new MainApiService<ISelectType>(
  PARAM_DEFAULT_ESTABLISHMENT_ROUTE
);

const translationPath = "page.register.paramDefaultEstablishment.";

const ParamDefaultEstablishmentPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { eI } = useParams();

  const columns: GridColDef<ISelectType>[] = [
    {
      field: "establishmentParamType",
      headerName:
        t(translationPath + "establishment_param_type") ||
        "Establishment Param Type",
      renderCell: (param) => (
        <Typography
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          variant="body2"
        >
          {param.value.name}
        </Typography>
      ),
      maxWidth: 280,
    },
    {
      field: "value",
      headerName: t(translationPath + "value") || "Value",
    },
    {
      field: "description",
      headerName: t(translationPath + "description") || "Description",
    },
  ];

  const handleRowClick = (params: GridRowParams<ISelectType>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  return (
    <Table<ISelectType>
      showDefaultMacroFilters={false}
      backButton
      backButtonUrl={`/e/${eI}/dashboard/settings`}
      showBreadcrumb
      service={genericService}
      columns={columns}
      title={t(translationPath + "param_default_establishment")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={
        t(translationPath + "search_param") || "Search for a param"
      }
      addButtonLabel={t(translationPath + "add_param") || "Add Standard Param"}
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default ParamDefaultEstablishmentPage;
