import { Box, CircularProgress, Stack } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";

import { IExtension } from "../../models/extensions-store";
import ExtensionStoreService from "../../services/extensionsStore.service";

import ExtensionsStoreDetail from "../../components/ExtensionsStore/ExtensionsStoreDetail";
import ExtensionsStoreHeader from "../../components/ExtensionsStore/ExtensionsStoreHeader";
import ExtensionsStoreList from "../../components/ExtensionsStore/ExtensionsStoreList";
import { useFetch } from "@4uhub/lib4uhub";

const service = new ExtensionStoreService();

const ExtensionsStorePage = () => {
  const [reloadExtensionsList, setReloadExtensionsList] = useState(0);
  const [extensionsOption, setExtensionsOption] = useState(false);
  const [extensions, setExtensions] = useState<IExtension[]>([]);

  const [extensionSelected, setExtensionSelected] = useState<IExtension>();
  const [detailOpen, setDetailOpen] = useState(false);
  const [searchInput, setSearchInput] = useState<string>("");

  const onToggleExtensionsOption = () =>
    setExtensionsOption((prevState) => (prevState = !prevState));

  const { sendRequest, loading } = useFetch(service.getAll);

  const loadExtensions = useCallback(async () => {
    const { data } = await sendRequest(undefined);
    if (data) {
      setExtensions(data.items);
    }
  }, [sendRequest]);

  useEffect(() => {
    loadExtensions();
  }, [loadExtensions, reloadExtensionsList]);

  const filteredExtensions = useMemo(() => {
    return extensions
      .filter((extensions) => {
        if (extensionsOption) {
          return extensions.instaledOn.length !== 0;
        }
        return extensions;
      })
      .filter(
        (extension) =>
          extension.extensionTags
            .map((t) => t.name)
            .join(", ")
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          extension.title.toLowerCase().includes(searchInput.toLowerCase())
      );
  }, [extensions, extensionsOption, searchInput]);

  const onRealoadExtensionsList = () => {
    setReloadExtensionsList((prevState) => (prevState += 1));
  };

  const onOpenDetailHandler = (detail: IExtension) => {
    setExtensionSelected(detail);
    setDetailOpen(true);
  };

  return (
    <Box width={"100%"}>
      {extensionSelected && (
        <ExtensionsStoreDetail
          open={detailOpen}
          toggleDetail={setDetailOpen}
          extension={extensionSelected}
          onRealoadExtensionsList={onRealoadExtensionsList}
          onToggleExtensionsOption={() => setExtensionsOption(true)}
        />
      )}
      <ExtensionsStoreHeader
        onSearch={setSearchInput}
        extensionsOption={extensionsOption}
        toggleExtensionsOption={onToggleExtensionsOption}
      />
      {loading && (
        <Stack
          mt={5}
          width={"100%"}
          height={"65vh"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress size={60} />
        </Stack>
      )}
      {!loading && (
        <ExtensionsStoreList
          extensions={filteredExtensions}
          extensionsOption={extensionsOption}
          onOpenDetailHandler={onOpenDetailHandler}
          onRealoadExtensionsList={onRealoadExtensionsList}
          searchInput={searchInput}
        />
      )}
    </Box>
  );
};

export default ExtensionsStorePage;
