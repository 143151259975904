import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import Foreigner from "../../../../components/Foreigner";
import IndividualGender from "./IndividualGender";

import { DateInput, FormCheckBox, FormInput } from "@4uhub/lib4uhub";

const translation_path = "page.register.individuals.";

const IndividualBasicData: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} sm={6}>
        <FormInput
          size="small"
          autoFocus={true}
          name="document"
          label={t(translation_path + "document")}
          fullWidth
          mask="000.000.000-00"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormInput
          size="small"
          name="name"
          label={t(translation_path + "name")}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormInput
          size="small"
          name="identityCard"
          type="number"
          label={t(translation_path + "identity_card")}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <DateInput
          label={t(translation_path + "birth_date")}
          name="birthDate"
        />
      </Grid>

      <IndividualGender />

      <Grid item xs={12} sm={"auto"}>
        <FormCheckBox
          name="foreigner"
          label={t(translation_path + "foreigner") + "?"}
        />
      </Grid>

      <Foreigner label={t(translation_path + "country")} name="country" />
    </>
  );
};

export default IndividualBasicData;
