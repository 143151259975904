import { z } from "zod";

const translation_path = "page.register.users.errors.";

const establishmentsSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
    companyId: z.string(),
    enable: z.boolean(),
    domain: z.string().optional().nullable(),
  },
  {
    invalid_type_error: `${translation_path + "establishment_standard"}`,
  }
);

const individualIdSchema = z.object(
  {
    id: z.string(),
    individualName: z.string(),
    document: z.string(),
  },
  {
    invalid_type_error: `${translation_path + "individual"}`,
  }
);

const roleSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().optional().nullable(),
});

const profileSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string(),
  permissionLevel: z.number(),
  allowLink: z.boolean(),
  enable: z.boolean(),
  profileTypeId: z.string(),
  roles: z.array(roleSchema).optional(),
});

export const userSchema = z
  .object({
    userName: z.string().nonempty(translation_path + "username"),
    email: z.string().email(translation_path + "email"),
    password: z.string().optional(),
    enable: z.boolean(),
    establishmentStandard: establishmentsSchema,
    establishments: z
      .array(establishmentsSchema)
      .nonempty(translation_path + "establishments"),
    roles: z.array(roleSchema).optional(),
    individual: individualIdSchema.nullish(),
    profiles: z.array(profileSchema).optional(),
    id: z.string().optional(),
  })
  .superRefine((val, ctx) => {
    if (!val.id) {
      if (!val.password) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["password"],
          message: `${translation_path + "password"}`,
        });
      }
      if (!val.individual) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["individual"],
          message: `${translation_path + "individual"}`,
        });
      }
    }
    if (val.profiles?.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["profiles"],
        message: `${translation_path + "profiles"}`,
      });
    }
  });

export type TUserForm = z.infer<typeof userSchema>;
