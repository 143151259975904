import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

// Schema
import MainApiService from "../../../../services/mainApi.service";
import { useCallback } from "react";
import CityService from "../../../../services/city.service";
import { TCityForm, citySchema } from "./CitySchema";
import {
  AutoComplete,
  FormContainer,
  FormInput,
  ISelectType,
  SwitchInput,
  useFetch,
} from "@4uhub/lib4uhub";

const cityService = new CityService();

const PROVINCY_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Province/";
const provincyService = new MainApiService<ISelectType>(PROVINCY_ROUTE);

const translationPath = "page.register.cities.";

const City = () => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(cityService.create);

  const { sendRequest: update, loading: uLoading } = useFetch(
    cityService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    cityService.getItemById
  );

  const saveHandler = (v: TCityForm) =>
    save({
      ...v,
      provincyId: v.provincy.id,
    });

  const updateHandler = (v: TCityForm, id: string) =>
    update({
      item: {
        ...v,
        provincyId: v.provincy.id,
      },
      id: id,
    });

  const getHandler = useCallback(
    async (id: string) => {
      const data = get(id);
      return data;
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TCityForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "city")}
      schema={citySchema}
      subtitle={t(translationPath + "new_city")}
      subtitleWatchField={"name"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            <SwitchInput
              name="enable"
              label={t(translationPath + "enable") || "Enable"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="name"
              fullWidth
              label={t(translationPath + "name") || "Name"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="ibgeCode"
              type="number"
              fullWidth
              label={t(translationPath + "ibge_code") || "Code"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="correioCode"
              type="number"
              fullWidth
              label={t(translationPath + "correio_code") || "Code"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <AutoComplete
              SearchAsYouTypeParams={(v) => ({ SearchBy: v })}
              getOptionLabel={(option) => {
                return option.name;
              }}
              size="small"
              label={t(translationPath + "provincy")}
              name="provincy"
              request={provincyService.list}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default City;
