import { GlobalAxios } from "../axios/axios-config";
import { ITicketServiceMessage } from "../models/ticket-service-message";
import { TTicketServiceMessageForm } from "../pages/settings/Settings/SettingsCrm/TicketServiceMessage/TicketServiceMessageForm/TicketServiceMessageSchema";

const TICKET_SERVICE_MESSAGE_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketServiceMessage/";

export class TicketServMessageService {
  getItemById = (id: string) => {
    return GlobalAxios.get<TTicketServiceMessageForm>(
      TICKET_SERVICE_MESSAGE_ROUTE + id
    );
  };

  updateItemById = ({
    item,
    id,
  }: {
    item: ITicketServiceMessage;
    id: string;
  }) => {
    return GlobalAxios.put(TICKET_SERVICE_MESSAGE_ROUTE + id, item);
  };
}

export default TicketServMessageService;
