import { GlobalAxios } from "../axios/axios-config";
import { IEstablishment } from "@4uhub/lib4uhub";

const BASE_URL = process.env.REACT_APP_IDENTIY;
const ESTABLISHMENT_ROUTE = BASE_URL + "/api/v1/Users/Me/Establishments";

const ESTABLISHMENT_MASTER_ROUTE =
  BASE_URL + "/api/v1/Users/Me/Establishments/List";

export class EstablishmentsService {
  list = (params?: any) => {
    return GlobalAxios.get<IEstablishment[]>(ESTABLISHMENT_ROUTE, {
      params: params,
    });
  };

  masterList = (params?: any) => {
    return GlobalAxios.get<IEstablishment[]>(ESTABLISHMENT_MASTER_ROUTE, {
      params: params,
    });
  };
}

export default EstablishmentsService;
