import { useCallback, useEffect, useState } from "react";
import PrettyJson from "../../../admSystem/establishments/OnPremise/PrettyJson";
import { useFetch } from "@4uhub/lib4uhub";
import { listTasyIpmFull } from "../../../../services/tasyIpmLog.service";
import { ITasyIpmLog } from "../../../../models/tasy-ipm-logs";
import NoData from "../../../../components/Logs/NoData/NoData";
import { Box, CircularProgress } from "@mui/material";

interface ITasyIpmModalProps {
  id: number;
}

const TasyIpmModal = ({ id }: ITasyIpmModalProps) => {
  const [content, setContent] = useState<ITasyIpmLog>();

  const { sendRequest, loading } = useFetch(listTasyIpmFull);

  const fetchData = useCallback(async () => {
    const { data, success } = await sendRequest(id);
    if (data && success) {
      setContent(data);
    }
  }, [sendRequest, id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return (
      <Box
        minWidth={100}
        minHeight={100}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!content) {
    return <NoData message="Nenhum registro encontrado" />;
  }

  return <PrettyJson>{content}</PrettyJson>;
};

export default TasyIpmModal;
