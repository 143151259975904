import { GlobalAxios } from "../axios/axios-config";
import { IIntegratedUser, IPrivilege, IUser } from "../models/user";

const USER_NAME_ID = "username";
const PERMISSION_ID = "permissions";

const BUS_USER_ROUTE = process.env.REACT_APP_4UBUS + "/api/v1/User/";

export const getUserName = () => localStorage.getItem(USER_NAME_ID);

export const saveUserName = (username: string) =>
  localStorage.setItem(USER_NAME_ID, username);

export const removeUserName = () => localStorage.removeItem(USER_NAME_ID);

export const BASE_URL = process.env.REACT_APP_IDENTIY;

export const userMe = () =>
  GlobalAxios.get<IUser>(BASE_URL + "/api/v1/Users/Me");

export const userMePrivileges = () => {
  return GlobalAxios.get<IPrivilege[]>(
    BASE_URL + "/api/v1/Users/Me/Privileges"
  );
};

export const savePermissions = (privileges: IPrivilege[]) =>
  localStorage.setItem(PERMISSION_ID, JSON.stringify(privileges));

export const removePermissions = () => localStorage.removeItem(PERMISSION_ID);

export const syncUserNow = () => {
  return GlobalAxios.post(BUS_USER_ROUTE + "Sync/Now");
};

export const getIntegratedUser = () => {
  return GlobalAxios.get<IIntegratedUser>(BUS_USER_ROUTE + "List");
};
