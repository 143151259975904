import {
  AutoComplete,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import MainApi from "../../../../../../services/mainApi.service";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

const WHATSAPP_TEMPLATE_ROUTE =
  process.env.REACT_APP_AUTOMATIONS + "/api/v1/TicketAutomationTemplate/";
const whatsappTemplateService = new MainApi<ISelectType>(
  WHATSAPP_TEMPLATE_ROUTE
);

const translationPath = "chatBot.page.automations.automationRules.";

export const WhatsappTemplate = memo(() => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(whatsappTemplateService.list);

  const { control } = useFormContext();

  const ticketService = useWatch({
    name: "ticketService",
    control,
  });

  const ticketAutomation = useWatch({
    name: "ticketAutomation",
    control,
  });

  const fetchWhatsappTemplates = useCallback(async () => {
    const { data, success } = await sendRequest({
      TicketServiceId: ticketService ? ticketService.id : undefined,
      TicketAutomationId: ticketAutomation ? ticketAutomation.id : undefined,
    });
    if (data && success) {
      setOptions(data);
    }
  }, [sendRequest, ticketService, ticketAutomation]);

  useEffect(() => {
    fetchWhatsappTemplates();
  }, [fetchWhatsappTemplates]);

  return (
    <Grid item xs={12} sm={12}>
      <AutoComplete
        name="ticketAutomationTemplate"
        label={t(translationPath + "whatsapp_template")}
        getOptionLabel={(option) => option.name}
        size="small"
        fullWidth
        options={options}
      />
    </Grid>
  );
});
