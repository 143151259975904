import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Stack,
} from "@mui/material";
import { INationalHolidays } from "../../models/holidays";
import { SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { ptBR, enUS } from "date-fns/locale";

const translationPath = "components.nationalHolidays.";

const HolidaysList = ({
  holidays,
  loading,
  setHolidays,
  formatDate,
}: {
  holidays: INationalHolidays[];
  loading: boolean;
  setHolidays: (value: SetStateAction<INationalHolidays[]>) => void;
  formatDate: (date: string) => Date;
}) => {
  const hasHolidays = !loading && holidays.length;
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <Stack height={300} minWidth={300}>
      <FormControlLabel
        control={
          <Checkbox
            checked={holidays.every((holiday) => holiday.checked === true)}
            onChange={(e) => {
              setHolidays((prevState) =>
                prevState.map((item) => ({
                  ...item,
                  checked: e.target.checked,
                }))
              );
            }}
          />
        }
        label={t(translationPath + "select_all")}
      />
      <Stack sx={{ overflowX: "auto" }}>
        {loading && (
          <Stack
            height={"100%"}
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress size={40} />
          </Stack>
        )}
        {hasHolidays &&
          holidays.map((holiday) => (
            <Stack key={holiday.date} pl={2.2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={holiday.checked}
                    value={
                      holiday.date.replaceAll("-", "/") + "-" + holiday.name
                    }
                    onChange={(e) => {
                      setHolidays((prevState) =>
                        prevState.map((item) =>
                          item.date === holiday.date
                            ? { ...item, checked: e.target.checked }
                            : item
                        )
                      );
                    }}
                  />
                }
                label={
                  format(formatDate(holiday.date), "P", {
                    locale: language === "pt-BR" ? ptBR : enUS,
                  }) +
                  " - " +
                  holiday.name
                }
              />
            </Stack>
          ))}
      </Stack>
    </Stack>
  );
};

export default HolidaysList;
