import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import {
  IBeneficiaryClassification,
  IBeneficiaryClassificationPayload,
} from "../models/beneficiary-classification";

const serviceURL =
  process.env.REACT_APP_MAIN + "/api/v1/SalesContractBeneficiaryClassification";

export const createBeneficiaryClassification = (
  payload: IBeneficiaryClassificationPayload
) => {
  return GlobalAxios.post(serviceURL, payload);
};

export const updateBeneficiaryClassification = ({
  id,
  item,
}: {
  item: IBeneficiaryClassificationPayload;
  id: string;
}) => {
  return GlobalAxios.put(`${serviceURL}/${id}`, item);
};

export const findBeneficiaryClassificationById = (id: string) => {
  return GlobalAxios.get<IBeneficiaryClassification>(`${serviceURL}/${id}`);
};

export const listBeneficiaryClassifications = () => {
  return GlobalAxios.get<ISelectType[]>(`${serviceURL}/list`);
};

export const removeBeneficiaryClassification = (id: string) => {
  return GlobalAxios.delete(`${serviceURL}/${id}`);
};
