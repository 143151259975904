import { GlobalAxios } from "../axios/axios-config";
import { INewWorkGroup, IWorkGroup } from "../models/work-group";

const WORK_GROUP_TYPES_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/HealthProgramWorkGroup";

export const createWorkGroup = (item: INewWorkGroup) => {
  return GlobalAxios.post(WORK_GROUP_TYPES_ROUTE, item);
};

export const getWorkGroupById = (id: string) => {
  return GlobalAxios.get<IWorkGroup>(WORK_GROUP_TYPES_ROUTE + "/" + id);
};

export const updateWorkGroupById = ({
  item,
  id,
}: {
  item: INewWorkGroup;
  id: string;
}) => {
  return GlobalAxios.put(WORK_GROUP_TYPES_ROUTE + "/" + id, item);
};
