import ForgetPasswordForm from "../../../components/ForgetPasswordForm/ForgetPasswordForm";
import CardContainer from "../../../components/CardContainer/CardContainer";
import { useTranslation } from "react-i18next";

const ForgetPasswordPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CardContainer
      link="/login"
      textLink={
        t("components.forgetPasswordForm.back_login") || "Back to login"
      }
    >
      <ForgetPasswordForm />
    </CardContainer>
  );
};

export default ForgetPasswordPage;
