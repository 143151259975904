import {
  Card,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import SettingsIcon from "@mui/icons-material/Settings";
import { IInstaledOn } from "../../models/extensions-store";
import ExtensionUninstallButton from "./ExtensionUninstallButton";
import { DomainProvider } from "@4uhub/lib4uhub";

const translationPath = "components.extensionsStoreDetail.";

const ExtensionDetailConfig = ({
  installedOn,
  extensionId,
  onRealoadExtensionsList,
  toggleDetail,
}: {
  installedOn: IInstaledOn[];
  extensionId: string;
  onRealoadExtensionsList: () => void;
  toggleDetail: (value: boolean) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={12} md={12} mt={2}>
      <Typography
        variant="subtitle1"
        sx={(theme) => ({
          color:
            theme.palette.mode === "light"
              ? theme.palette.grey[600]
              : theme.palette.grey[400],
        })}
      >
        {t(translationPath + "settings")}:
      </Typography>
      <Card
        variant="outlined"
        sx={(theme) => ({
          mt: 2,
          maxHeight: 208,
          overflowY: "auto",
          backgroundColor:
            theme.palette.mode === "light" ? "#fff" : theme.palette.grey[900],
        })}
      >
        {installedOn.map((installed, index) => (
          <Stack
            p={2}
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            borderBottom={
              index !== installedOn.length - 1 ? "1px solid #ccc" : undefined
            }
            key={installed.siteApp.id}
          >
            <Typography variant="subtitle2">
              {installed.siteApp.name}
            </Typography>
            <Stack flexDirection={"row"}>
              <ExtensionUninstallButton
                extensionId={extensionId}
                siteAppId={installed.siteApp.id}
                onRealoadExtensionsList={onRealoadExtensionsList}
                toggleDetail={toggleDetail}
              />
              <DomainProvider
                redirect={false}
                value={{ domainName: "extensionconfig" }}
              >
                <Tooltip title={t(translationPath + "tooltip_settings")}>
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() =>
                      window.open(
                        `extension-settings/${installed.siteApp.id}/${extensionId}`,
                        "_blank"
                      )
                    }
                  >
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
              </DomainProvider>
            </Stack>
          </Stack>
        ))}
      </Card>
    </Grid>
  );
};

export default ExtensionDetailConfig;
