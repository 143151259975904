import { Box } from "@mui/material";
import { memo, useEffect, useMemo, useRef } from "react";
import { getWeek } from "date-fns";
import { motion } from "framer-motion";

import MonthCalendar from "./MonthCalendar/MonthCalendar";
import DayCalendar from "./DayCalendar/DayCalendar";
import WeekCalendar from "./WeekCalendar/WeekCalendar";
import { ECalendarMode } from "./models";
import useCalendar from "./CalendarContext/useCalendar";
import { TOTAL_MINUTES } from "./HourPosition";
import { DAY_HEADER_HEIGHT } from "./DayCalendar/Header/Header";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? "1%" : "-1%",
      opacity: 0,
    };
  },
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      x: direction < 0 ? "1%" : "-1%",
      opacity: 0,
    };
  },
};

interface ICalendarBodyProps {
  headerHeigth: number;
}

const HOUR_OF_DAY_SCROLL_START = 8;

const CalendarBody: React.FC<ICalendarBodyProps> = ({ headerHeigth }) => {
  const { mode, date, direction } = useCalendar();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && mode !== ECalendarMode.MONTH) {
      const minutes_passed = HOUR_OF_DAY_SCROLL_START * 60;

      const percentage = minutes_passed / TOTAL_MINUTES;

      const topPosition = percentage * (ref.current.scrollHeight - DAY_HEADER_HEIGHT);


      ref.current.scrollTo({ top: topPosition - 20 });
    }
  }, [mode]);

  const animationKey = useMemo(() => {
    switch (mode) {
      case ECalendarMode.DAY:
        return date.getDay();
      case ECalendarMode.MONTH:
        return date.getMonth() * date.getFullYear();
      case ECalendarMode.WEEK:
        return getWeek(date) * date.getMonth() * date.getFullYear();
      default:
        break;
    }
  }, [date, mode]);

  return (
    <Box
      height={`calc(100% - ${headerHeigth + 1}px)`}
      width={"100%"}
      sx={{ overflow: "auto" }}
      ref={ref}
    >
      <motion.div
        key={mode}
        initial={{ opacity: 0 }}
        style={{ height: "100%" }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, ease: [0.6, -0.05, 0.01, 0.99] }}
      >
        <motion.div
          initial="enter"
          animate="center"
          exit="exit"
          custom={direction}
          style={{ height: "100%" }}
          variants={variants}
          key={animationKey}
          transition={{
            x: { type: "tween", duration: 0.1 },
            opacity: { duration: 0.1 },
          }}
        >
          {mode === ECalendarMode.MONTH && <MonthCalendar />}
          {mode === ECalendarMode.DAY && <DayCalendar days={[date]} />}
          {mode === ECalendarMode.WEEK && <WeekCalendar />}
        </motion.div>
      </motion.div>
    </Box>
  );
};

export default memo(CalendarBody);
