import { GlobalAxios } from "./../axios/axios-config";
import {
  INewTicketServiceAreaDay,
  ITicketServiceAreaDay,
} from "./../models/ticket-service-area-days";

const TICKET_SERVICE_AREA_DAYS_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketServiceAreaDays/";

export const createTicketServiceAreaDay = (item: INewTicketServiceAreaDay) => {
  return GlobalAxios.post(TICKET_SERVICE_AREA_DAYS_ROUTE, item);
};

export const getTicketServiceAreaDayById = (id: string) => {
  return GlobalAxios.get<ITicketServiceAreaDay>(
    TICKET_SERVICE_AREA_DAYS_ROUTE + id
  );
};

export const updateTicketServiceAreaDayById = ({
  item,
  id,
}: {
  item: INewTicketServiceAreaDay;
  id: string;
}) => {
  return GlobalAxios.put(TICKET_SERVICE_AREA_DAYS_ROUTE + id, item);
};
