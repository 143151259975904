import { Tooltip, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ICellDataProps } from "./models";

const CellData = ({ value }: ICellDataProps) => {
  const textElementRef = useRef<HTMLInputElement | null>(null);
  const [hoverStatus, setHover] = useState(false);

  const compareSize = () => {
    if (!textElementRef.current) return;
    const compare =
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth;

    setHover(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
    return () => {
      window.removeEventListener("resize", compareSize);
    };
  }, [value]);

  return (
    <Tooltip title={value} disableHoverListener={!hoverStatus}>
      <Typography ref={textElementRef} variant="body2" noWrap>
        {value}
      </Typography>
    </Tooltip>
  );
};

export default CellData;
