import { GlobalAxios } from "../axios/axios-config";
import { IBusConfig, IBusConfigs } from "../models/bus-configs";

const BUS_CONFIGS_ROUTE = process.env.REACT_APP_4UBUS + "/api/v1/4uBusConfigs/";

export const createBusConfig = (item: IBusConfig) => {
  return GlobalAxios.post(BUS_CONFIGS_ROUTE, item);
};

export const getBusConfigById = (id: string) => {
  return GlobalAxios.get<IBusConfigs>(BUS_CONFIGS_ROUTE + id);
};

export const updateBusConfigById = ({
  item,
  id,
}: {
  item: IBusConfig;
  id: string;
}) => {
  return GlobalAxios.put(BUS_CONFIGS_ROUTE + id, item);
};
