import { Grid } from "@mui/material";
import { memo, useCallback } from "react";
import { TextField } from "../../../../TextField/TextField";
import { useFormContext } from "@4uhub/lib4uhub";
import { useTemplateWhats } from "../../../../../../store/contexts/TemplateWhatsContext";
import { ICallActionProps } from "./models";
import { AutoCompleteCountry } from "../../../../../UI/Inputs/AutoCompleteCountry/AutoCompleteCountry";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

const translationPath = "components.callAction.";

export const CallAction = memo(({ id, index }: ICallActionProps) => {
  const { t } = useTranslation();

  const { control } = useFormContext();

  const currentCountry = useWatch({
    name: `ctaButtons.${index}.country`,
    control,
  });

  const { updateCtaHandler } = useTemplateWhats();

  const getTextedValue = useCallback(
    (text: string) => {
      updateCtaHandler(id, "text", text);
    },
    [id, updateCtaHandler]
  );

  return (
    <>
      <Grid item xs={12} sm={3}>
        <TextField
          name={`ctaButtons.${index}.text`}
          label={t(translationPath + "text")}
          fullWidth
          size="small"
          maxLength={25}
          onChangeValue={getTextedValue}
          sx={(t) => ({
            background: `linear-gradient(${t.palette.background.default} 40px, transparent 40px)`,
            borderRadius: 1,
          })}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <AutoCompleteCountry
          name={`ctaButtons.${index}.country`}
          label={t(translationPath + "country")}
          getOptionLabel={(option) => option.code + " +" + option.ddiCode}
          fullWidth
          clearIcon={false}
          size="small"
          sx={(t) => ({
            background: `linear-gradient(${t.palette.background.default} 40px, transparent 40px)`,
            borderRadius: 1,
          })}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          name={`ctaButtons.${index}.payload`}
          label={t(translationPath + "payload")}
          fullWidth
          maxLength={20}
          onChangeValue={(text) => updateCtaHandler(id, "payload", text)}
          size="small"
          mask={currentCountry ? `${currentCountry.phoneMask}` : undefined}
          sx={(t) => ({
            background: `linear-gradient(${t.palette.background.default} 40px, transparent 40px)`,
            borderRadius: 1,
          })}
        />
      </Grid>
    </>
  );
});
