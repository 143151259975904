import { memo } from "react";
import { formatDuration, intervalToDuration } from "date-fns";
import { useTranslation } from "react-i18next";

import Data from "../ScheduleDetail/Data";
import useDate from "../../../hooks/useDate";
import { ICalendar } from "../model";

interface IFixedData {
  data: ICalendar;
}

const FixedData: React.FC<IFixedData> = ({ data }) => {
  const { localeFormat, locale } = useDate();

  const { t } = useTranslation();

  const duration = formatDuration(
    intervalToDuration({
      start: new Date(data.calendarStartDate),
      end: new Date(data.calendarEndDate),
    }),
    { locale }
  );

  return (
    <>
      <Data
        label={t("components.schedule.newSchedule.date")}
        value={localeFormat(new Date(data.calendarStartDate), "P")}
      />
      <Data
        label={t("components.schedule.newSchedule.hour")}
        value={localeFormat(new Date(data.calendarStartDate), "p")}
      />
      <Data
        label={t("components.schedule.newSchedule.professional")}
        value={data.descriptionSite}
      />
      <Data
        label={t("components.schedule.newSchedule.type")}
        value={data.tasyScheduleTypeName}
      />
      <Data
        label={t("components.schedule.newSchedule.duration")}
        value={duration}
      />
    </>
  );
};

export default memo(FixedData);
