import { useCallback, useEffect, useState } from "react";
import { AutoComplete, ISelectType, useFetch } from "@4uhub/lib4uhub";
import MainApiService from "../../../../services/mainApi.service";

const GENERICS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const service = new MainApiService<ISelectType>(GENERICS_ROUTE);

const LegalEntityGenericSelect = ({
  name,
  identifier,
  label,
}: {
  name: string;
  identifier: string;
  label: string;
}) => {
  const [list, setList] = useState<ISelectType[]>([]);
  const { sendRequest } = useFetch(service.list);

  const onLoadList = useCallback(async () => {
    const { data } = await sendRequest({ identifier: identifier });
    if (data) {
      setList(data);
    }
  }, [sendRequest, identifier]);

  useEffect(() => {
    onLoadList();
  }, [onLoadList]);

  return (
    <AutoComplete
      getOptionLabel={(option) => {
        return option.name;
      }}
      size="small"
      label={label}
      name={name}
      options={list}
    />
  );
};

export default LegalEntityGenericSelect;
