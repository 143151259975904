import { GlobalAxios } from "../axios/axios-config";
import { ICompany, IGetCompany } from "../models/companies";

const COMPANY_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Company";

export class CompaniesService {
  create = (item: ICompany) => {
    return GlobalAxios.post(COMPANY_ROUTE, item);
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<IGetCompany>(COMPANY_ROUTE + "/" + id);
  };

  updateItemById = ({ item, id }: { item: ICompany; id: string }) => {
    return GlobalAxios.put(COMPANY_ROUTE + "/" + id, item);
  };
}

export default CompaniesService;
