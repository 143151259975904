import { Button, useFetch, useNotificationContext } from "@4uhub/lib4uhub";
import { Popover, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { cancelNotificationBatch } from "../../../../../services/notification.service";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router";

interface INotificationBatchCancelProps {
  anchorEl: HTMLButtonElement | null;
  open: boolean;
  handleClose: () => void;
  statusCode: string;
}

const translationPath = "components.notificationBatchActions.";

const NotificationBatchCancel = ({
  anchorEl,
  open,
  handleClose,
  statusCode,
}: INotificationBatchCancelProps) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { setMessage } = useNotificationContext();
  const navigate = useNavigate();

  const { sendRequest: cancelBatch, loading: cancelLoading } = useFetch(
    cancelNotificationBatch
  );

  const cancelBatchHandler = useCallback(async () => {
    if (id) {
      const { data, success } = await cancelBatch(id);
      if (data && success) {
        setMessage({
          message: `${t(translationPath + "batch_canceled")}`,
          type: "success",
        });
        navigate(-1);
      }
    }
  }, [cancelBatch, navigate, id, setMessage, t]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <Stack width={300} p={2}>
        <Typography variant="body2" mb={2}>
          {t(translationPath + "cancel_popover_title")}
        </Typography>
        <Button
          loading={cancelLoading}
          variant="contained"
          color="error"
          size="small"
          onClick={cancelBatchHandler}
          disabled={statusCode === "4"}
        >
          {t(translationPath + "cancel")}
        </Button>
      </Stack>
    </Popover>
  );
};

export default NotificationBatchCancel;
