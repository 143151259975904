import { useCallback, useState } from "react";
import { INationalHolidays } from "../models/holidays";
import axios from "axios";

const useNationalHolidays = () => {
  const [holidays, setHolidays] = useState<INationalHolidays[]>([]);
  const [loading, setLoading] = useState(false);

  const onLoadHolidays = useCallback((selectedYear: number) => {
    setLoading(true);
    axios
      .get<INationalHolidays[]>(
        `https://brasilapi.com.br/api/feriados/v1/${selectedYear}`
      )
      .then((response) => {
        try {
          const data = response.data;
          setHolidays(data.map((d) => ({ ...d, checked: true })));
        } catch (error) {}
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { holidays, loading, onLoadHolidays, setHolidays };
};

export default useNationalHolidays;
