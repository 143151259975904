import { GlobalAxios } from "./../axios/axios-config";
import {
  IGetWhatsappTemplate,
  IResendWhatsappTemplate,
  IUpdateWhatsappTemplate,
  IWhatsappTemplate,
} from "../models/whatsapp-template";

const WHATSAPP_TEMPLATE_ROUTE =
  process.env.REACT_APP_AUTOMATIONS + "/api/v1/TicketAutomationTemplate";

export const createWhatsappTemplate = (item: IWhatsappTemplate) => {
  return GlobalAxios.post(WHATSAPP_TEMPLATE_ROUTE, item);
};

export const getWhatsappTemplateById = (id: string) => {
  return GlobalAxios.get<IGetWhatsappTemplate>(
    WHATSAPP_TEMPLATE_ROUTE + "/" + id
  );
};

export const updateWhatsappTemplateById = ({
  item,
  id,
}: {
  item: IUpdateWhatsappTemplate;
  id: string;
}) => {
  return GlobalAxios.put(WHATSAPP_TEMPLATE_ROUTE + "/" + id, item);
};

export const resendWhatsappTemplate = ({
  item,
  id,
}: {
  item: IResendWhatsappTemplate;
  id: string;
}) => {
  return GlobalAxios.put(WHATSAPP_TEMPLATE_ROUTE + "/ResendMeta/" + id, item);
};
