import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RedirectPage } from "../../../components/RedirectPage/RedirectPage";
import useJobs from "../../../hooks/useJobs";

const translationPath = "page.jobs.";

const JobsPage = () => {
  const { openJobs } = useJobs();
  const { t } = useTranslation();

  useEffect(() => {
    openJobs();
  }, [openJobs]);

  return (
    <RedirectPage
      title={t(translationPath + "title")}
      subtitle={t(translationPath + "subtitle")}
      buttonLabel={t(translationPath + "button")}
      onClick={openJobs}
    />
  );
};

export default JobsPage;
