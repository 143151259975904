import { ReactElement, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useWatch } from "react-hook-form";

import Dates from "./Dates";
import Section from "./Secion";
import { Grid } from "@mui/material";
import EntitySelect from "./EntitySelect";
import GrantorSelect from "./GrantorSelect";
import { FormInput, useFormContext } from "@4uhub/lib4uhub";
import SituationSelect from "./SituationSelect";
import MainContractSelect from "./MainContractSelect";
import SalesContractTypeSelect from "./ContractTypeSelect";
import ContractClassificationSelect from "./ContractClassificationSelect";

import { buildTPath } from "../../advantages-and-benefits-club.utils";
import { TAdvantagesAndBenefitsClubForm } from "../../advantage-and-benefits-club.schema";

const Fields = (): ReactElement => {
  const { id } = useParams();
  const { t } = useTranslation();

  const translate = (field: string) => t(buildTPath(field));

  const { control, setValue } =
    useFormContext<TAdvantagesAndBenefitsClubForm>();
  const renewalPeriodMonths = useWatch({
    control,
    name: "renewalPeriodMonths",
  });

  useEffect(() => {
    if (id) return;

    setValue("renewalPeriodMonths", "12");
  }, [setValue, id]);

  const validateRenewalPeriodMonths = useCallback(() => {
    if (!renewalPeriodMonths) {
      setValue("renewalPeriodMonths", "12");
    }
  }, [renewalPeriodMonths, setValue]);

  return (
    <Grid container spacing={2}>
      <Section label={translate("contratation_data")} />

      <Grid item xs={12} sm={6}>
        <SituationSelect />
      </Grid>

      <Grid item xs={12} sm={6}>
        <GrantorSelect />
      </Grid>

      <Grid item xs={12} sm={6}>
        <MainContractSelect />
      </Grid>

      <Grid item xs={12} sm={6}>
        <SalesContractTypeSelect />
      </Grid>

      <Grid item xs={12} sm={6}>
        <ContractClassificationSelect />
      </Grid>

      <Dates />

      <Grid item xs={12} sm={8}>
        <FormInput
          name="contractTitle"
          size="small"
          label={translate("contract_title")}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormInput
          name="renewalPeriodMonths"
          size="small"
          defaultValue="12"
          fullWidth
          onBlur={validateRenewalPeriodMonths}
          label={translate("renewal_period")}
        />
      </Grid>

      <EntitySelect />

      {/* <Section label={translate("additional_data")} /> */}
    </Grid>
  );
};

export default Fields;
