import { Chip, Stack, Tooltip, Typography } from "@mui/material";
import { TChipsColors } from "../../models/extensions-store";
import React from "react";

interface IExtensionsPriceProps {
  color: TChipsColors;
  chipLabel: string;
  variant: "filled" | "outlined";
  label?: string;
  icon?: React.ReactNode;
  tooltipTitle?: string;
}

const ExtensionsPrice = ({
  color,
  chipLabel,
  variant,
  label,
  icon,
  tooltipTitle,
}: IExtensionsPriceProps) => {
  return (
    <Tooltip title={tooltipTitle ? tooltipTitle : undefined}>
      <Stack
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"start"}
        gap={1}
      >
        {icon && icon}
        {label && <Typography>{label}</Typography>}
        <Chip
          sx={{ width: "fit-content" }}
          color={color}
          label={chipLabel}
          size="small"
          variant={variant}
        />
      </Stack>
    </Tooltip>
  );
};

export default ExtensionsPrice;
