import React, { memo, useState } from "react";
import { Stack } from "@mui/material";
import { Button, useFormContext } from "@4uhub/lib4uhub";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useTranslation } from "react-i18next";

import { TContentForm } from "../../ContentSchema";
import ContentVideoModal from "./ContentVideoModal";

const translation_path = "components.content.";

interface IContentVideosProps {
  index: number;
}

const ContentVideos: React.FC<IContentVideosProps> = ({ index: mainIndex }) => {
  const [open, setOpen] = useState(false);

  const { formState } = useFormContext<TContentForm>();

  const { t } = useTranslation();

  const hasError = !!formState.errors?.contents?.[mainIndex]?.contentVideos;

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
        <Button
          onClick={() => setOpen(true)}
          variant="contained"
          startIcon={<YouTubeIcon />}
        >
          {t(translation_path + "videos")}
        </Button>
        {hasError && <ErrorOutlineIcon color="error" />}
      </Stack>
      <ContentVideoModal mainIndex={mainIndex} onClose={onClose} open={open} />
    </>
  );
};

export default memo(ContentVideos);
