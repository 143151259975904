import {
  FormContainer,
  IInputFile,
  ISelectType,
  Treturn,
  useDomain,
  useFetch,
} from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { useCallback, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { NonEmptyArray } from "@4uhub/lib4uhub/dist/types/models";
import ContentBasicData from "../../../../components/Content/ContentForm/ContentBasicData";
import ContentFiles from "../../../../components/Content/ContentForm/ContentFiles/ContentFiles";
import Contents from "../../../../components/Content/ContentForm/ContentsArray/Contents";
import {
  TContentForm,
  contentSchema,
} from "../../../../components/Content/ContentForm/ContentSchema";
import { IGetContentMediaFile } from "../../../../models/content";
import {
  createContent,
  getContentById,
  updateContentById,
} from "../../../../services/content.service";
import MainApiService from "../../../../services/mainApi.service";

const LANGUAGES_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Language/";
const service = new MainApiService<ISelectType>(LANGUAGES_ROUTE);

const translationPath = "page.register.news.";

const News = () => {
  const { t } = useTranslation();

  const [contentMediaFilesId, setContentMediaFilesId] = useState<string[]>([]);

  const [allLanguages, setAllLanguages] = useState<ISelectType[]>([]);

  const [contentMediaFiles, setContentMediaFiles] = useState<
    IGetContentMediaFile[]
  >([]);

  const { privileges } = useDomain();

  const hasContentApprover = privileges.find(
    (priv) => priv.domainName === "contentapprover" || priv.domainName === "all"
  );

  let deleteFilesList: string[] = [];

  const onDeleFileHandler = (file: IInputFile) => {
    const selectedFile = file;
    if (selectedFile.id.length !== 0) {
      deleteFilesList.push(selectedFile.id);
    }
  };

  const { sendRequest: loadLanguages } = useFetch(service.list);

  const loadLanguagesHandler = useCallback(async () => {
    const { data, success } = await loadLanguages(undefined);
    if (data && success) {
      setAllLanguages(data);
    }
  }, [loadLanguages]);

  useLayoutEffect(() => {
    loadLanguagesHandler();
  }, [loadLanguagesHandler]);

  const { sendRequest: save, loading: sLoading } = useFetch(createContent);

  const { sendRequest: update, loading: uLoading } =
    useFetch(updateContentById);

  const { sendRequest: get, loading: gLoading } = useFetch(getContentById);

  const saveHandler = (v: TContentForm) =>
    save({
      ...v,
      contentTypeCode: "1",
      deleteMediaFilesIds: deleteFilesList,
      isPublished: hasContentApprover ? v.isPublished : false,
      siteApps: v.siteApps.map((sa) => sa.id),
      contentMediaFiles: v.contentMediaFiles.map((media) => ({
        mediaFilesId: media.file.id,
        captions: media.captions
          .filter((cap) => cap.caption)
          .map((cap) => ({
            languageId: cap.language ? cap.language : undefined,
            caption: cap.caption ? cap.caption : undefined,
          })),
      })),
      contents: v.contents.map((content) => ({
        ...content,
        contentTags: content.contentTags.map((tag) => ({ name: tag })),
        languageId: content.language ? content.language.id : "",
      })),
      enable: true,
      publicationDate: v.publicationDate.toISOString(),
      readingTime: +v.readingTime,
    });

  const updateHandler = (v: TContentForm, id: string) => {
    const updatedFiles = v.contentMediaFiles.filter(
      (media) => !contentMediaFilesId.includes(media.file.id)
    );
    const updatedCaptions = v.contentMediaFiles.filter((contentMedia) =>
      contentMediaFiles.some((c) => {
        if (contentMedia.file.id === c.id) {
          return contentMedia.captions.map((cap) =>
            c.contentMediaFileCaptions.some(
              (oldCap) => oldCap.caption !== cap.caption
            )
          );
        }
        return false;
      })
    );

    return update({
      id: id,
      item: {
        ...v,
        contentTypeCode: "1",
        deleteMediaFilesIds: deleteFilesList,
        isPublished: hasContentApprover ? v.isPublished : false,
        siteApps: v.siteApps.map((sa) => sa.id),
        contentMediaFiles:
          updatedFiles.length !== 0
            ? updatedFiles.map((media) => ({
                mediaFilesId: media.file.id,
                captions: media.captions
                  .filter((c) => c.caption && c.caption.length !== 0)
                  .map((cap) => ({
                    languageId: cap.language ? cap.language : undefined,
                    caption: cap.caption ? cap.caption : undefined,
                  })),
              }))
            : [],
        updateCaptionMediaFile:
          updatedCaptions.length !== 0
            ? updatedCaptions.map((newCaps) => ({
                captionsMediaFile: newCaps.captions
                  .filter((c) => c.caption && c.caption.length !== 0)
                  .map((c) => ({
                    languageId: c.language ? c.language : undefined,
                    caption: c.caption ? c.caption : undefined,
                  })),
                contentMediaFilesId: newCaps.file.id,
              }))
            : undefined,
        contents: v.contents.map((content) => ({
          ...content,
          contentTags: content.contentTags.map((tag) => ({ name: tag })),
          languageId: content.language ? content.language.id : "",
        })),
        enable: true,
        publicationDate: v.publicationDate.toISOString(),
        readingTime: +v.readingTime,
      },
    });
  };

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TContentForm>> => {
      const { data, success } = await get(id);

      if (data) {
        setContentMediaFiles(data.contentMediaFiles);

        const newData: TContentForm = {
          ...data,
          isPublished: data.contentStatus.code === "2" ? true : false,
          publicationDate: new Date(data.publicationDate),
          highlightsStart: data.highlightsStart
            ? new Date(data.highlightsStart)
            : null,
          highlightsEnd: data.highlightsEnd
            ? new Date(data.highlightsEnd)
            : null,
          readingTime: data.readingTime.toString(),
          contents: data.contents
            .sort((a, b) =>
              a.language.name < b.language.name
                ? -1
                : a.language.name > b.language.name
                ? 1
                : 0
            )
            .map((content) => ({
              ...content,
              contentTags: content.contentTags.map(
                (tag) => tag.name
              ) as NonEmptyArray<string>,
            })),
          contentMediaFiles: data.contentMediaFiles.map((media) => ({
            file: {
              id: media.id,
              cdnDomain: media.cdnDomain,
              fileName: media.fileName,
              filePath: media.filePath,
              fileSize: media.fileSize,
              isPublic: media.isPublic,
              mediaTypeId: media.mediaType.id,
              mimeType: media.mimeType,
            },
            captions: [],
          })),
        };
        const filesId = newData.contentMediaFiles.map(
          (media) => media.file?.id || ""
        );
        if (filesId) setContentMediaFilesId(filesId);
        return { data: newData, success };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TContentForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "news")}
      schema={contentSchema}
      subtitle={t(translationPath + "new_news")}
      subtitleWatchField={"contents.0.title"}
      defaultValues={{
        publicationDate: new Date(),
      }}
      fields={() => (
        <Grid container spacing={2}>
          <ContentBasicData />
          <Contents allLanguages={allLanguages} />
          <ContentFiles
            onDeleteFileHandler={onDeleFileHandler}
            allLanguages={allLanguages}
            contentMediaFiles={contentMediaFiles}
          />
        </Grid>
      )}
    />
  );
};

export default News;
