import { useFormContext } from "@4uhub/lib4uhub";
import { memo, useEffect } from "react";
import { useWatch } from "react-hook-form";
import { TBodyField } from "./models";
import { Box, Grid } from "@mui/material";
import { WhatsappSection } from "../../WhatsappTemplate/WhatsappSection/WhatsappSection";
import { useFormMessage } from "../../../store/contexts/FormMessageContext";
import { BodyFieldControlled } from "./BodyFieldControlled/BodyFieldControlled";
import { Preview } from "./Preview/Preview";

// const translationPath = "chatBot.page.templates.whatsapp.";

export const BodyField = memo(
  ({
    name,
    label,
    automationId,
    showVariables = true,
    whatsappSection,
    ...otherProps
  }: TBodyField) => {
    // const { t } = useTranslation();

    const { control } = useFormContext();

    const { updateBasicData } = useFormMessage();

    const textValue = useWatch({
      name: name ?? "body",
      control,
    });

    useEffect(() => {
      if (textValue) {
        updateBasicData(name ?? "body", textValue);
      } else {
        updateBasicData(name ?? "body", "");
      }
    }, [textValue, name, updateBasicData]);

    if (whatsappSection) {
      return (
        <Grid item xs={12} sm={12}>
          <WhatsappSection
            title={whatsappSection.title}
            subtitle={whatsappSection.subtitle}
          >
            <Box sx={{ display: "flex", alignItems: "start", gap: 2 }}>
              <BodyFieldControlled
                name={name}
                label={label}
                automationId={automationId}
                showVariables={showVariables}
                {...otherProps}
              />
              <Preview text={textValue ?? ""} />
            </Box>
          </WhatsappSection>
        </Grid>
      );
    }

    return (
      <Grid
        item
        xs={12}
        sm={12}
        sx={{ display: "flex", alignItems: "start", gap: 2 }}
      >
        <BodyFieldControlled
          name={name}
          label={label}
          automationId={automationId}
          showVariables={showVariables}
          {...otherProps}
        />
        <Preview text={textValue ?? ""} />
      </Grid>
    );
  }
);
