import { Box } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { IVariablesExamplesProps } from "./models";
import { VariablesContainer } from "./VariablesContainer";
import { VariableField } from "./VariableField/VariableField";
import { useTemplateWhats } from "../../../../../store/contexts/TemplateWhatsContext";
import { useWatch } from "react-hook-form";
import { useFormContext } from "@4uhub/lib4uhub";
import { TWhatsappForm } from "../../../../../pages/4uChatbot/Templates/Whatsapp/WhatsappForm/wathsappSchema";

export const VariablesExamples = memo(
  ({ variables }: IVariablesExamplesProps) => {
    const [labelWidth, setLabelWidth] = useState(0);

    const { control } = useFormContext<TWhatsappForm>();

    const bodyExamples = useWatch({
      name: "bodyExamples",
      control,
    });

    const { fetchVariables } = useTemplateWhats();

    useEffect(() => {
      setLabelWidth(() => {
        let length = 0;
        for (let i = 0; i < variables.length; i++) {
          if (variables[i].length > length) length = variables[i].length;
        }
        return length * 8;
      });
    }, [variables]);

    useEffect(() => {
      if (!bodyExamples) return;
      fetchVariables(bodyExamples.map((v) => v.example));
    }, [fetchVariables, bodyExamples]);

    return (
      <VariablesContainer>
        <Box sx={{ pt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
          {variables &&
            variables.length > 0 &&
            variables.map((variable, index) => (
              <VariableField
                variable={variable}
                index={index}
                labelWidth={labelWidth}
                key={variable + index}
              />
            ))}
        </Box>
      </VariablesContainer>
    );
  }
);
