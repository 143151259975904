import {
  IActionMenuItems,
  useFetch,
  useNotificationContext,
} from "@4uhub/lib4uhub";
import { IEstablishments } from "../../../models/establishments";

import SyncIcon from "@mui/icons-material/Sync";
import SettingsIcon from "@mui/icons-material/Settings";
import DomainIcon from "@mui/icons-material/Domain";
import TuneIcon from "@mui/icons-material/Tune";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import MedicalInformationRoundedIcon from "@mui/icons-material/MedicalInformationRounded";
import ExtensionIcon from "@mui/icons-material/Extension";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import MonitorHeartRoundedIcon from "@mui/icons-material/MonitorHeartRounded";
import HandshakeIcon from "@mui/icons-material/Handshake";
import PsychologyRoundedIcon from "@mui/icons-material/PsychologyRounded";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";

import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { IBeneficiarySync, IEstablishmentSync } from "../../../models/sync";
import MainApiService from "../../../services/mainApi.service";
import { IModalCollector, TModalType } from "../../../models/collectors";
import { GridRowId } from "@mui/x-data-grid";
import useJobs from "../../../hooks/useJobs";

const TRANSLATION_PATH = "page.register.establishments.";

const SYNC_ROUTE =
  process.env.REACT_APP_COLLECTOR + "/api/v1/Establishment/Queue";
const SYNC_BENEFICIARIES_ROUTE =
  process.env.REACT_APP_COLLECTOR +
  "/api/v1/IndividualBeneficiaries/SyncAllBeneficiariesFromEstablishment";
const syncService = new MainApiService<IEstablishmentSync>(SYNC_ROUTE);
const syncBeneficiariesService = new MainApiService<IBeneficiarySync>(
  SYNC_BENEFICIARIES_ROUTE
);

interface IEstablishmentsRowActionsProps {
  setOpen: (value: SetStateAction<boolean>) => void;
  setModalCollector: Dispatch<SetStateAction<IModalCollector | undefined>>;
  setIsLoading: (isLoading: boolean) => void;
}

const EstablishmentsRowActions = ({
  setOpen,
  setModalCollector,
  setIsLoading,
}: IEstablishmentsRowActionsProps): IActionMenuItems<IEstablishments>[] => {
  const { t } = useTranslation();
  const { setMessage } = useNotificationContext();
  const { openJobs } = useJobs();

  const { sendRequest: sync, loading } = useFetch(syncService.create);
  const { sendRequest: syncBeneficiaries, loading: sLoading } = useFetch(
    syncBeneficiariesService.create
  );

  useEffect(() => {
    if (loading || sLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [loading, sLoading, setIsLoading]);

  const handleSync = useCallback(
    async (establishmentIds: string) => {
      const data = await sync({ establishmentIds: [establishmentIds] });
      if (data) {
        setMessage({
          message: `${t(TRANSLATION_PATH + "synced")}`,
          type: "success",
          notificationKey: new Date().getMilliseconds(),
        });
      }
    },
    [sync, setMessage, t]
  );

  const handleSyncBeneficiaries = useCallback(
    async (establishmentIds: string) => {
      const data = await syncBeneficiaries({
        establishmentId: establishmentIds,
      });
      if (data) {
        setMessage({
          message: `${t(TRANSLATION_PATH + "synced")}`,
          type: "success",
          notificationKey: new Date().getMilliseconds(),
        });
      }
    },
    [syncBeneficiaries, setMessage, t]
  );

  const onOpenHandler = (type: TModalType, establishmentId: GridRowId) => {
    setModalCollector({
      modalType: type,
      establishmentId: establishmentId.toString(),
    });
    setOpen(true);
  };

  return [
    {
      action: (params) => {
        handleSync(params.id.toString());
      },
      icon: <SyncIcon />,
      text: `${t(TRANSLATION_PATH + "sync")}`,
    },
    {
      action: (params) => {
        onOpenHandler("establishment", params.id);
      },
      icon: <DomainIcon />,
      text: `${t(TRANSLATION_PATH + "see_establishments")}`,
    },
    {
      action: (params) => {
        onOpenHandler("params", params.id);
      },
      icon: <TuneIcon />,
      text: `${t(TRANSLATION_PATH + "see_params")}`,
    },
    {
      action: (params) => {
        onOpenHandler("generics", params.id);
      },
      icon: <SettingsIcon />,
      text: `${t(TRANSLATION_PATH + "see_generics")}`,
    },
    {
      action: (params) => {
        onOpenHandler("extensions", params.id);
      },
      icon: <ExtensionIcon />,
      text: `${t(TRANSLATION_PATH + "see_extensions")}`,
    },
    {
      action: (params) => {
        onOpenHandler("health", params.id);
      },
      icon: <MedicalInformationRoundedIcon />,
      text: `${t(TRANSLATION_PATH + "see_health")}`,
      divider: true,
    },
    {
      action: (params) => {
        onOpenHandler("medic_specialties", params.id);
      },
      icon: <MonitorHeartRoundedIcon />,
      text: `${t(TRANSLATION_PATH + "see_medic_specialties_on_premise")}`,
    },
    {
      action: (params) => {
        onOpenHandler("medical_practice_areas", params.id);
      },
      icon: <MedicalServicesIcon />,
      text: `${t(TRANSLATION_PATH + "see_medical_practice_areas")}`,
    },
    {
      action: (params) => {
        onOpenHandler("provider_types", params.id);
      },
      icon: <HandshakeIcon />,
      text: `${t(TRANSLATION_PATH + "see_provider_types")}`,
    },
    {
      action: (params) => {
        onOpenHandler("professional_councils", params.id);
      },
      icon: <PsychologyRoundedIcon />,
      text: `${t(TRANSLATION_PATH + "see_professional_councils")}`,
    },
    {
      action: (params) => {
        handleSyncBeneficiaries(params.id.toString());
      },
      divider: true,
      icon: <PeopleAltRoundedIcon />,
      text: `${t(TRANSLATION_PATH + "sync_beneficiaries")}`,
    },
    {
      action: (params) => {
        openJobs(params.id.toString());
      },
      divider: true,
      icon: <ManageHistoryIcon />,
      text: `${t(TRANSLATION_PATH + "jobs")}`,
    },
  ];
};

export default EstablishmentsRowActions;
