import { Stack, SxProps, Theme, Typography } from "@mui/material";
import { memo } from "react";

interface IRowProps {
  label: string;
  value: string;
  sx?: SxProps<Theme>;
}

const Row: React.FC<IRowProps> = memo(({ label, value, sx }) => {
  return (
    <Stack gap={1}>
      <Typography variant="body2">{label}</Typography>
      <Typography fontWeight={600} sx={sx}>
        {value}
      </Typography>
    </Stack>
  );
});

export default memo(Row);
