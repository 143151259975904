import {
  AutoComplete,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";
import { TTicketAutoFinishRuleForm } from "./TicketAutoFinishRuleSchema";
import { useCallback, useEffect, useState } from "react";
import { ticketChannelStep } from "../../../../../../services/ticketChannelStep.service";

const translationPath = "page.settings.crm.ticketAutoFinishRules.";

const TicketChannelStep = () => {
  const { t } = useTranslation();

  const { control } = useFormContext<TTicketAutoFinishRuleForm>();

  const [ticketChannelSteps, setTicketChannelSteps] = useState<ISelectType[]>(
    []
  );

  const { sendRequest } = useFetch(ticketChannelStep);

  const ticketChannel = useWatch({
    name: "ticketChannel",
    control,
  });

  const fetchTicketChannelSteps = useCallback(
    async (ticketChannelId: string) => {
      const { data, success } = await sendRequest(ticketChannelId);
      if (data && success) {
        setTicketChannelSteps(data);
      }
    },
    [sendRequest]
  );

  useEffect(() => {
    if (ticketChannel) {
      fetchTicketChannelSteps(ticketChannel.id);
    }
  }, [fetchTicketChannelSteps, ticketChannel]);

  return (
    <Grid item xs={12} sm={6}>
      <AutoComplete
        getOptionLabel={(option) => {
          return option.name;
        }}
        size="small"
        label={t(translationPath + "ticket_channel_step")}
        name="ticketChannelStep"
        options={ticketChannelSteps}
      />
    </Grid>
  );
};

export default TicketChannelStep;
