import { AutoComplete, ISelectType, useFetch } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SitesAppsService from "../../../../../services/sitesApps.service";
import { SiteAppCopyBtn } from "./SiteAppCopyBtn/SiteAppCopyBtn";
import { ISiteAppList } from "../../../../../models/tickets-service";

const extensionService = new SitesAppsService();

const translationPath = "page.evaluation.forms.";

export const SiteAppExtension = memo(() => {
  const { t } = useTranslation();

  const [siteAppExtensions, setSiteAppExtensions] = useState<ISelectType[]>([]);

  const [siteApps, setSiteApps] = useState<ISiteAppList[]>([]);

  const { sendRequest } = useFetch(extensionService.siteAppExtensionList);

  const fetchSiteAppExtensions = useCallback(async () => {
    const { data, success } = await sendRequest({ ExtensionCode: "22" });
    if (data && success) {
      setSiteApps(data);
      setSiteAppExtensions(
        data.map((d) => ({ id: d.id, name: d.siteApp.name }))
      );
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchSiteAppExtensions();
  }, [fetchSiteAppExtensions]);

  return (
    <Grid item xs={12} sm={12} sx={{ display: "flex", alignItems: "center" }}>
      <AutoComplete
        params={{ ExtensionCode: "20" }}
        name="siteAppExtension"
        label={t(translationPath + "extension") || "4uHub Platform Extension"}
        getOptionLabel={(option) => option.name}
        options={siteAppExtensions}
        size="small"
        fullWidth
      />
      <SiteAppCopyBtn siteApps={siteApps} />
    </Grid>
  );
});
