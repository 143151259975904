import { ILanguageType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import { ILanguage, ILanguagesAll } from "../models/language";
import { ISearchParams, ISearchReturn } from "../models/requests";
import { TLanguagesForm } from "../pages/globals/languages/LanguagesForm/LanguagesSchema";

const BASE_URL = process.env.REACT_APP_MAIN;

const LANGUAGES_ROUTE = BASE_URL + "/api/v1/Language/";

export class LanguagesService {
  create = (item: ILanguage) => {
    return GlobalAxios.post(LANGUAGES_ROUTE, item);
  };

  getAll = (serchParams?: ISearchParams) => {
    return GlobalAxios.get<ISearchReturn<ILanguage[]>>(LANGUAGES_ROUTE, {
      params: serchParams,
    });
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<TLanguagesForm>(LANGUAGES_ROUTE + id);
  };

  updateItemById = ({ item, id }: { item: ILanguage; id: string }) => {
    return GlobalAxios.put(LANGUAGES_ROUTE + id, item);
  };

  deleteItemById = (id: string) => {
    return GlobalAxios.delete(LANGUAGES_ROUTE + id);
  };

  all = () => {
    return GlobalAxios.get<ILanguagesAll[]>(LANGUAGES_ROUTE + "all");
  };

  list = () => {
    return GlobalAxios.get<ILanguageType[]>(LANGUAGES_ROUTE + "list");
  };
}

export default LanguagesService;
