import MainApiService from "../../../../services/mainApi.service";

import { ISalesContractClassification } from "../../../../models/sales-contract-classification";
import { TContractClassificationForm } from "./ContractClassificationsForm/contractClassificationSchema";

export const SALES_CONTRACT_CLASSIFICATION_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/salesContractClassification/";

export const salesContractService = new MainApiService<ISalesContractClassification>(SALES_CONTRACT_CLASSIFICATION_ROUTE);
export const salesContractFormService = new MainApiService<TContractClassificationForm>(SALES_CONTRACT_CLASSIFICATION_ROUTE);

export const translationPath = "page.4uSalesContract.registers.contract_classifications.";

export const buildTPath = (field: string): string => `${translationPath}${field}`;