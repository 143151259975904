import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Chip } from "@mui/material";
import {  Table } from "@4uhub/lib4uhub";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";

import { IContractTerminationReason } from "../../../../models/contract-termination-reason";
import { contractTerminationService, buildTPath } from "./contract-termination-reason.utils";

const ContractTerminationReasonPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columns: GridColDef<IContractTerminationReason>[] = [
    {
      field: "name",
      headerName: t(buildTPath('name'))!,
    },
    {
      field: "enable",
      maxWidth: 100,
      headerName: t(buildTPath("status"))!,
      renderCell: ({ value }) => (
        <Chip
            size="small"
            color={value ? "success" : "error"}
            label={value ? t(buildTPath("active"))
                : t(buildTPath("inactive"))
            }
        />
      )
    },
  ];

  const handleRowClick = ({ id }: GridRowParams<IContractTerminationReason>) => {
    navigate(`${id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  return (
    <Table<IContractTerminationReason>
      showDefaultMacroFilters={false}
      service={contractTerminationService}
      columns={columns}
      title={t(buildTPath('title'))}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={t(buildTPath("search"))}
      addButtonLabel={t(buildTPath("add"))!}
      confirmOptions={{
        title: t(buildTPath("confirm.title")),
        description: t(buildTPath("confirm.description")),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default ContractTerminationReasonPage;
