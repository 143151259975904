import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

// Schema
import {
  AutoCompleteMulti,
  FormContainer,
  FormInput,
  IInputFile,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import { useCallback, useState } from "react";
import { workspacesList } from "../../../../../../services/ticketWorkspace.service";
import ProtocolDefaultMediaFiles from "./ProtocolDefaultMediaFiles";
import {
  createProtocolDefault,
  getProtocolDefaultById,
  updateProtocolDefaultById,
} from "../../../../../../services/protocolDefault.service";
import {
  TProtocolDefaultForm,
  protocolDefaultSchema,
} from "./ProtocolDefaultSchema";
import ProtocolMessage from "./ProtocolMessage";

const translationPath = "page.settings.crm.protocolsDefault.";

const ProtocolDefault = () => {
  const { t } = useTranslation();

  const [ticketDefaultDocMediaFilesId, setTicketDefaultDocMediaFilesId] =
    useState<string[]>([]);

  let deleteFilesList: string[] = [];

  const onDeleFileHandler = (file: IInputFile) => {
    const selectedFile = file;
    if (selectedFile.id.length !== 0) {
      deleteFilesList.push(selectedFile.id);
    }
  };

  const { sendRequest: save, loading: sLoading } = useFetch(
    createProtocolDefault
  );

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateProtocolDefaultById
  );

  const { sendRequest: get, loading: gLoading } = useFetch(
    getProtocolDefaultById
  );

  const handleGet = useCallback(
    async (id: string): Promise<Treturn<TProtocolDefaultForm>> => {
      const { data, success } = await get(id);
      if (data) {
        const filesId = data.ticketDefaultDocMediaFiles.map(
          (media) => media.id || ""
        );
        if (filesId) setTicketDefaultDocMediaFilesId(filesId);
        return {
          data: {
            ...data,
            ticketWorkspaces: data.ticketsWorkspaces,
            ticketDefaultDocMediaFiles: data.ticketDefaultDocMediaFiles.map(
              (media) => ({
                file: {
                  ...media,
                },
                description: media.description,
              })
            ),
          },
          success: true,
        };
      }
      return { data, success };
    },
    [get]
  );

  const handleSave = (v: TProtocolDefaultForm) => {
    return save({
      ...v,
      ticketDefaultDocMediaFiles: v.ticketDefaultDocMediaFiles
        ? v.ticketDefaultDocMediaFiles.map((media) => ({
            mediaFileId: media.file.id,
            description: media.description,
          }))
        : undefined,
      deleteMediaFilesIds: deleteFilesList,
      ticketWorkspacesId: v.ticketWorkspaces.map((workspace) => workspace.id),
    });
  };

  const handleUpdate = (v: TProtocolDefaultForm, id: string) => {
    const updatedFiles = v.ticketDefaultDocMediaFiles
      ? v.ticketDefaultDocMediaFiles.filter((media) =>
          ticketDefaultDocMediaFilesId.includes(media.file.id)
        )
      : undefined;
    const newFiles = v.ticketDefaultDocMediaFiles
      ? v.ticketDefaultDocMediaFiles.filter(
          (media) => !ticketDefaultDocMediaFilesId.includes(media.file.id)
        )
      : undefined;

    return update({
      item: {
        ...v,
        id,
        ticketDefaultMediaFilesToUpdate: updatedFiles
          ? updatedFiles.map((media) => ({
              id: media.file.id,
              description: media.description,
            }))
          : undefined,
        ticketDefaultDocMediaFiles: newFiles
          ? newFiles.map((media) => ({
              mediaFileId: media.file.id,
              description: media.description,
            }))
          : undefined,
        deleteMediaFilesIds: deleteFilesList,
        ticketWorkspacesId: v.ticketWorkspaces.map((workspace) => workspace.id),
      },
      id: id,
    });
  };

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TProtocolDefaultForm>
      saveFunction={handleSave}
      updateFunction={handleUpdate}
      getFunction={handleGet}
      loading={loading}
      title={t(translationPath + "protocol_default")}
      schema={protocolDefaultSchema}
      subtitle={t(translationPath + "new_protocol_default")}
      subtitleWatchField={"title"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            <SwitchInput name="enable" label={t(translationPath + "enable")} />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="title"
              label={t(translationPath + "title")}
              fullWidth
              autoFocus
            />
          </Grid>

          <ProtocolMessage />

          <Grid item xs={12} sm={12}>
            <AutoCompleteMulti
              name="ticketWorkspaces"
              label={t(translationPath + "workspaces")}
              size="small"
              request={workspacesList}
            />
          </Grid>

          <ProtocolDefaultMediaFiles onDeleteFileHandler={onDeleFileHandler} />
        </Grid>
      )}
    />
  );
};

export default ProtocolDefault;
