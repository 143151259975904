import { z } from "zod";
import { buildTPath } from "../beneficiary-classifications.utils";

export const beneficiaryClassificationSchema = z.object({
  id: z.string().nullish(),
  name: z.string().min(1, buildTPath("errors.name")),
  enable: z.boolean(),
});

export type TBeneficiaryClassificationForm = z.infer<
  typeof beneficiaryClassificationSchema
>;
