import { FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useCallback, useState } from "react";

import {
  TExtensionSettingForm,
  extensionSettingSchema,
} from "./ExtensionSettingSchema";
import { useParams } from "react-router-dom";
import ExtensionSettingsService from "../../../../services/extensionSettings.service";
import { FormContainer, FormInput, Treturn, useFetch } from "@4uhub/lib4uhub";
import { SwitchInput } from "../../../../components/UI/Inputs/Switch";

const translationPath = "page.extensionSettings.";

const extensionSettingsService = new ExtensionSettingsService();

const ExtensionSetting = () => {
  const { t } = useTranslation();
  const [saveConfig, setSaveConfig] = useState(false);
  const [siteAppName, setSiteAppName] = useState<string>();
  const [extensionName, setExtensionName] = useState<string>();
  const urlParams = useParams();

  const { sendRequest: save, loading: sLoading } = useFetch(
    extensionSettingsService.create
  );
  const { sendRequest: update, loading: uLoading } = useFetch(
    extensionSettingsService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    extensionSettingsService.getItemById
  );

  const saveHandler = (v: TExtensionSettingForm) =>
    save({
      ...v,
      identifier: v.identifier,
      property: v.property,
      value: v.value,
      description: v.description,
      siteAppId: !saveConfig ? urlParams.siteAppId : undefined,
      extensionId: urlParams.extensionId || "",
    });

  const updateHandler = (v: TExtensionSettingForm, id: string) =>
    update({
      item: {
        ...v,
        identifier: v.identifier,
        property: v.property,
        description: v.description,
        siteAppId: !saveConfig ? urlParams.siteAppId : undefined,
        value: v.value,
        extensionId: urlParams.extensionId || "",
      },
      id: id,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TExtensionSettingForm>> => {
      const { data, success } = await get(id);
      if (!data) {
        return { data, success };
      }

      if (!data.siteApp) {
        setSaveConfig(true);
      } else {
        setSiteAppName(data.siteApp.name);
        setExtensionName(data.extension.name);
        setSaveConfig(false);
      }

      return {
        data: {
          ...data,
          id: data.id,
          identifier: data.identifier,
          property: data.property,
          value: data.value,
          description: data.description,
        },
        success: true,
      };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TExtensionSettingForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "extension_config")}
      schema={extensionSettingSchema}
      subtitle={t(translationPath + "new_extension_config")}
      subtitleWatchField={"identifier"}
      fields={() => (
        <Grid container spacing={2}>
          {siteAppName && (
            <Grid item xs={12} sm={8} mb={2}>
              <Typography fontSize={18}>
                {t(translationPath + "extension_settings", {
                  siteApp: siteAppName,
                  extension: extensionName,
                })}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} sm={siteAppName ? 4 : 12} textAlign={"end"}>
            <SwitchInput
              name="isPublic"
              label={t(translationPath + "is_public")}
              defaultValue={false}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="identifier"
              fullWidth
              label={t(translationPath + "identifier") || "Identifier"}
              autoFocus
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControlLabel
              checked={saveConfig}
              control={
                <Switch onChange={(e) => setSaveConfig(e.target.checked)} />
              }
              label={
                t(translationPath + "save_in_establishment") ||
                "Salvar configurações para todos os sites e apps do estabelecimento"
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="property"
              fullWidth
              label={t(translationPath + "property") || "Property"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="value"
              fullWidth
              label={t(translationPath + "value") || "Value"}
            />
          </Grid>

          <Grid item xs={12}>
            <FormInput
              size="small"
              multiline
              name="description"
              fullWidth
              minRows={4}
              label={t("page.register.generics.description") || "Description"}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default ExtensionSetting;
