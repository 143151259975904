import { z } from "zod";

const translationPath = "page.4uBus.register.restrictionRules.errors.";

const actionTypeSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "action_type" }
);

const restrictionRuleTypeSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
    title: z.string().optional().nullable(),
    description: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "restriction_rule_type" }
);

export const restrictionRuleSchema = z.object({
  id: z.string().optional(),
  enable: z.boolean(),
  value: z.string().min(1, translationPath + "value"),
  description: z.string().min(1, translationPath + "description"),
  restrictionRuleType: restrictionRuleTypeSchema,
  actionType: actionTypeSchema,
});

export type TRestrictionRuleForm = z.infer<typeof restrictionRuleSchema>;
