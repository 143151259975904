import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import CopyIcon from "@mui/icons-material/CopyAll";
import { useTranslation } from "react-i18next";
import { memo } from "react";

interface IInsuranceDataProps {
  insuranceName: string;
  insuranceCardNumber?: string;
}

const InsuranceData: React.FC<IInsuranceDataProps> = ({
  insuranceCardNumber,
  insuranceName,
}) => {
  const { t } = useTranslation();

  const copyHandler = () => {
    navigator.clipboard.writeText(String(insuranceCardNumber));
  };

  return (
    <Stack
      sx={(t) => ({
        border: `1px solid ${t.palette.divider}`,
        padding: t.spacing(1),
        borderRadius: t.shape.borderRadius / 10,
        flexDirection: "row",
        justifyContent: "space-between",
      })}
    >
      <Stack>
        <Typography variant="caption">
          {t("components.schedule.schedulePopover.insurance")}
        </Typography>
        <Typography variant="body2">{insuranceName}</Typography>
        {insuranceCardNumber && (
          <Typography variant="body2">{insuranceCardNumber}</Typography>
        )}
      </Stack>
      {insuranceCardNumber && (
        <Tooltip
          title={t("components.schedule.schedulePopover.copyCardNumber")}
        >
          <IconButton size="small" onClick={copyHandler}>
            <CopyIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};

export default memo(InsuranceData);
