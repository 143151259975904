import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { IExtensionDefaultSettings } from "../../../../models/extension";
import ExtensionsGeneralService from "../../../../services/extensionsGeneral.service";
import { useCallback, useMemo, useState } from "react";
import { Table } from "@4uhub/lib4uhub";

const TRANSLATION_PATH = "page.register.extensions.defaultSettings.";

const EXTENSIONS_DEFAULT_CONFIG_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/ExtensionDefaultConfig";

const extensionsDefaultConfigService =
  new ExtensionsGeneralService<IExtensionDefaultSettings>(
    EXTENSIONS_DEFAULT_CONFIG_ROUTE
  );

const DefaultSettingsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { extId } = useParams();
  const [defaultSettings, setDefaultSettings] = useState<
    IExtensionDefaultSettings[]
  >([]);

  const columns: GridColDef<IExtensionDefaultSettings>[] = [
    {
      field: "identifier",
      headerName: t(TRANSLATION_PATH + "identifier") || "Identifier",
    },
    {
      field: "value",
      headerName: t(TRANSLATION_PATH + "value") || "Value",
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IExtensionDefaultSettings>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const props = useMemo(
    () => ({
      ExtensionId: extId,
    }),
    [extId]
  );

  const onLoadSiteAppData = useCallback((data: IExtensionDefaultSettings[]) => {
    setDefaultSettings(data);
  }, []);
  return (
    <Table<IExtensionDefaultSettings>
      onFetchData={onLoadSiteAppData}
      showDefaultMacroFilters={false}
      columns={columns}
      title={t(TRANSLATION_PATH + "default_settings", {
        extension:
          defaultSettings.length !== 0 ? defaultSettings[0].extension.name : "",
      })}
      searchInput
      backButton
      service={extensionsDefaultConfigService}
      serviceProps={props}
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={
        t(TRANSLATION_PATH + "search_identifier") || "Search for an identifier"
      }
      addButtonLabel={
        t(TRANSLATION_PATH + "add_default_setting") || "Add Extension"
      }
      confirmOptions={{
        title: t(TRANSLATION_PATH + "confirm.title"),
        description: t(TRANSLATION_PATH + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default DefaultSettingsPage;
