import {
  AccessRegister,
  AutoComplete,
  ISelectType,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import { TContentForm } from "../ContentSchema";

const translation_path = "components.content.";

const ContentsLanguage = ({
  name,
  index,
  loadDefaultLang,
  allLanguages,
}: {
  name: string;
  index: number;
  loadDefaultLang: (lang: ISelectType) => void;
  allLanguages: ISelectType[];
}) => {
  const { control } = useFormContext<TContentForm>();
  const [languageOptions, setLanguageOptions] = useState<ISelectType[]>([]);
  const { t } = useTranslation();

  const selectedLanguage = useWatch({
    name: "contents",
    control,
  });

  const loadLanguageOptions = useCallback(async () => {
    const selectedLang = selectedLanguage.find(
      (lang, indexLang) => indexLang === index
    );
    const filteredOptions = allLanguages.filter(
      (objeto2) =>
        !selectedLanguage.some((objeto1) => objeto1.language?.id === objeto2.id)
    );
    loadDefaultLang(filteredOptions[0]);
    if (selectedLang?.language && filteredOptions.length !== 0) {
      setLanguageOptions([
        ...filteredOptions,
        { id: selectedLang.language.id, name: selectedLang.language.name },
      ]);
    } else {
      setLanguageOptions(filteredOptions);
    }
  }, [loadDefaultLang, selectedLanguage, index, allLanguages]);

  useEffect(() => {
    loadLanguageOptions();
  }, [loadLanguageOptions]);

  return (
    <Grid item xs={12} sm={6}>
      <AccessRegister
        redirectTo="dashboard/globals/languages"
        domainName="language"
        inputValue={
          selectedLanguage[index].language
            ? [
                {
                  id: selectedLanguage[index].language!.id,
                  name: selectedLanguage[index].language!.name,
                },
              ]
            : []
        }
        tooltip={t(translation_path + "language_tooltip")}
      >
        <AutoComplete
          label={t(translation_path + "language") || "Language"}
          name={name}
          size="small"
          getOptionLabel={(option) => {
            return option.name;
          }}
          options={languageOptions}
          filterSelectedOptions
        />
      </AccessRegister>
    </Grid>
  );
};

export default ContentsLanguage;
