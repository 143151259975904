import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import {
  IChannelClassification,
  IChannelClassificationPayload,
} from "../models/channel-classification";

export const serviceURL =
  process.env.REACT_APP_MAIN + "/api/v1/SalesChannelClassification";

export const createChannelClassification = (
  payload: IChannelClassificationPayload
) => {
  return GlobalAxios.post(serviceURL, payload);
};

export const updateChannelClassification = ({
  id,
  item,
}: {
  id: string;
  item: IChannelClassificationPayload;
}) => {
  return GlobalAxios.put(`${serviceURL}/${id}`, item);
};

export const findChannelClassificationById = (id: string) => {
  return GlobalAxios.get<IChannelClassification>(`${serviceURL}/${id}`);
};

export const listChannelsClassifications = () => {
  return GlobalAxios.get<ISelectType[]>(`${serviceURL}/list`);
};

export const removeChannelClassification = (id: string) => {
  return GlobalAxios.delete(`${serviceURL}/${id}`);
};

export const listSalesChannelClassifications = () => {
  return GlobalAxios.get<ISelectType[]>(`${serviceURL}/list`);
};
