import { z } from "zod";
import { EMAIL_VALIDATOR } from "../../../../../utils/Validators/regexValidators";

const translationPath = "page.register.faq.errors.";

const siteAppSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const languageSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "language" }
);

export const faqSchema = z
  .object({
    id: z.string().optional(),
    enable: z.boolean(),
    name: z.string().min(1, translationPath + "name"),
    whatsappPhoneNumber: z.string().nullable(),
    mail: z.string().nullable(),
    language: languageSchema,
    displayOrder: z.string().min(1, translationPath + "display_order"),
    siteApp: z
      .array(siteAppSchema)
      .refine((val) => val.length !== 0, translationPath + "site_app"),
  })
  .superRefine((val, ctx) => {
    if (!val.whatsappPhoneNumber && !val.mail) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["whatsappPhoneNumber"],
        message: translationPath + "phone_email",
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["mail"],
        message: translationPath + "phone_email",
      });
    }
    if (val.mail) {
      if (!EMAIL_VALIDATOR.test(val.mail)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["mail"],
          message: translationPath + "mail",
        });
      }
    }
  });

export type TFaqForm = z.infer<typeof faqSchema>;
