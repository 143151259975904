import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { memo, useMemo } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CopyIcon from "@mui/icons-material/CopyAll";
import { stringToColor } from "@4uhub/lib4uhub";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { EcalendarStatusCode, ICalendar } from "../model";
import CancelSchedule from "./CancelSchedule/CancelSchedule";
import useDate from "../../../hooks/useDate";
import InsuranceData from "./InsuranceData";

interface IEventPopver {
  event: ICalendar;
  onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
}

const SchedulePopover: React.FC<IEventPopver> = ({ event, onClose }) => {
  const { t, localeFormat } = useDate();

  const copyHandler = () => {
    navigator.clipboard.writeText(String(event.identifier));
  };

  const onCloseHandler = () => {
    onClose && onClose({}, "escapeKeyDown");
  };

  const startDate = useMemo(
    () => new Date(event.calendarStartDate),
    [event.calendarStartDate]
  );

  const endDate = useMemo(
    () => new Date(event.calendarEndDate),
    [event.calendarEndDate]
  );

  const isCofirmed =
    event.calendarStatusCode === EcalendarStatusCode.CONFIMED ||
    event.calendarStatusCode === EcalendarStatusCode.CONFIMED2;

  return (
    <Box p={2} minWidth={300}>
      <Stack flexDirection={"row"} justifyContent={"flex-end"}>
        <Tooltip title={t("components.schedule.schedulePopover.copy")}>
          <IconButton size="small" onClick={copyHandler}>
            <CopyIcon fontSize="small" />
          </IconButton>
        </Tooltip>

        <CancelSchedule data={event} />
        <IconButton size="small" onClick={onCloseHandler}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Stack>
      <Stack direction={"row"} alignItems={"flex-start"} gap={1}>
        <Box
          sx={{
            width: 10,
            height: 10,
            backgroundColor: stringToColor(event.descriptionSite),
            borderRadius: 0.4,
            marginTop: 3,
          }}
        />

        <Stack gap={1}>
          <Typography variant="h6">{event.pacientName}</Typography>
          <Typography variant="body2">
            {localeFormat(startDate, "PPPP")} ⋅ {localeFormat(startDate, "p")} -{" "}
            {localeFormat(endDate, "p")}
          </Typography>
          <Typography variant="body2">{event.descriptionSite}</Typography>
          {event.procedureName && (
            <Typography variant="body2">{event.procedureName}</Typography>
          )}
          <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
            {isCofirmed && (
              <CheckCircleOutlineIcon sx={{ fontSize: 16 }} color="success" />
            )}
            <Typography variant="body2">{event.calendarStatusName}</Typography>
          </Stack>

          <InsuranceData
            insuranceName={event.insuranceName}
            insuranceCardNumber={event.insuranceCardNumber}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default memo(SchedulePopover);
