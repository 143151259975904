import { Box, Chip, Typography } from "@mui/material";
import { memo } from "react";
import { IFormSectionProps } from "./models";

export const FormSection = memo(
  ({ title, subtitle, chipLabel }: IFormSectionProps) => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Typography variant="subtitle2" fontWeight="bold">
            {title}
          </Typography>
          {chipLabel && chipLabel.length > 0 && (
            <Chip size="small" label={chipLabel} sx={{ fontSize: 12 }} />
          )}
        </Box>
        {subtitle && subtitle.length > 0 && (
          <Typography
            variant="body2"
            sx={(t) => ({
              color: t.palette.grey[t.palette.mode === "light" ? 600 : 400],
            })}
          >
            {subtitle}
          </Typography>
        )}
      </Box>
    );
  }
);
