import { z } from "zod";

const translationPath = "page.register.extensions.priceRules.errors.";

const selectTypeSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional(),
    code: z.string().optional(),
    identifier: z.string().optional(),
    description: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "type" }
);

export const PriceRuleSchema = z.object({
  id: z.string().optional(),
  typePricing: selectTypeSchema,
  initialDate: z.date({
    invalid_type_error: translationPath + "initial_date",
  }),
  endDate: z.date({
    invalid_type_error: translationPath + "end_date",
  }),
  value: z.string().nonempty(translationPath + "value"),
});

export type TPriceRuleForm = z.infer<typeof PriceRuleSchema>;
