import { Typography } from "@mui/material";
import { memo, useCallback, useMemo } from "react";

import HourPosition from "./HourPosition";
import { IGroupedEvents } from "./models";
import useCalendar from "./CalendarContext/useCalendar";
import { ICalendar } from "../model";
import { stringToColor } from "@4uhub/lib4uhub";
import useDate from "../../../hooks/useDate";

interface IAvaiableTimeProps {
  data: ICalendar;
  containerHeigth: number;
  dayIndex: number;
  index: number;
  date: Date;
  eventGroup: IGroupedEvents<ICalendar>;
}

const AvaiableTime: React.FC<IAvaiableTimeProps> = ({
  containerHeigth,
  data,
  index,
  dayIndex,
  eventGroup,
  date,
}) => {
  const { onAvaiableTimeClick } = useCalendar();

  const { localeFormat } = useDate();

  const onClickHandler = useCallback(() => {
    onAvaiableTimeClick && onAvaiableTimeClick(data);
  }, [onAvaiableTimeClick, data]);

  const startDate = useMemo(
    () => new Date(data.calendarStartDate),
    [data.calendarStartDate]
  );
  const endDate = useMemo(
    () => new Date(data.calendarEndDate),
    [data.calendarEndDate]
  );

  return (
    <HourPosition
      containerHeigth={containerHeigth}
      onClick={onClickHandler}
      startDate={startDate}
      endDate={endDate}
      dayIndex={dayIndex}
      date={date}
      index={index}
      eventGroup={eventGroup}
      sx={[
        (t) => {
          const color =
            t.palette.mode === "dark"
              ? t.palette.grey[800]
              : t.palette.grey[200];

          return {
            display: "flex",
            backgroundColor: color,
            color: t.palette.getContrastText(color),
            borderRadius: 1,
            border: `1px solid ${
              stringToColor(data.descriptionSite) ||
              t.palette.background.default
            }`,
            paddingX: t.spacing(1),
            "&:hover": {
              boxShadow: "0px 0px 12px 5px rgba(120,120,120,1)",
              cursor: "pointer",
              zIndex: 99999,
              minWidth: "fit-content",
            },
          };
        },
      ]}
    >
      <Typography
        fontSize={12}
        textOverflow={"ellipsis"}
        overflow={"hidden"}
        whiteSpace={"nowrap"}
      >
        {localeFormat(startDate, "p")} - {localeFormat(endDate, "p")}{" "}
        {data.descriptionSite}
      </Typography>
    </HourPosition>
  );
};

export default memo(AvaiableTime);
