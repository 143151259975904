import { IconButton, Tooltip } from "@mui/material";
import { memo, useCallback, useMemo, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Modal, useFormContext, useNotificationContext } from "@4uhub/lib4uhub";
import { TFormsForm } from "../../formSchema";
import { useWatch } from "react-hook-form";
import { ISiteAppCopyBtnProps } from "./models";
import { DomainsList } from "./DomainsList/DomainsList";
import { useTranslation } from "react-i18next";

const translationPath = "page.evaluation.forms.domains.";

export const SiteAppCopyBtn = memo(({ siteApps }: ISiteAppCopyBtnProps) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const { setMessage } = useNotificationContext();

  const closeHandler = () => setOpen(false);

  const { control } = useFormContext<TFormsForm>();

  const siteApp = useWatch({
    name: "siteAppExtension",
    control,
  });

  const domainsList = useMemo(
    () =>
      siteApp
        ? siteApps
            .find((sa) => sa.id === siteApp.id)
            ?.siteApp.siteAppDomains.map((d) => d.domain) || []
        : [],
    [siteApp, siteApps]
  );

  const onClickHandler = useCallback(() => {
    if (domainsList.length === 1) {
      const domain = domainsList[0];
      const link =
        domain[domain.length - 1] === "/"
          ? domain + "evaluationForm/{{id_pesquisa}}"
          : domain + "/evaluationForm/{{id_pesquisa}}";
      navigator.clipboard.writeText(link);
      setMessage({
        message: t(translationPath + "copied_successfully"),
        type: "success",
      });
      return;
    }
    setOpen(true);
  }, [domainsList, setMessage, t]);

  if (!siteApp) return null;

  return (
    <>
      <Modal
        open={open}
        onClose={closeHandler}
        title={`${t(translationPath + "domains")}`}
      >
        <DomainsList domains={domainsList} />
      </Modal>
      <Tooltip title={t(translationPath + "tooltip")}>
        <IconButton size="small" onClick={onClickHandler}>
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
});
