import { GlobalAxios } from "../axios/axios-config";
import {
  ICreateDashBoardParams,
  IDashboard,
  IDashboardEstablishmentComponent,
  IListComponent,
  IListIDashboard,
} from "../models/dashboard";

const BASE_URL = process.env.REACT_APP_MAIN;

const DASH_BOARD_PANEL_ROUTE = BASE_URL + "/api/v1/Dashboard";

const DASH_BOARD_PANEL_VIEW_ROUTE = BASE_URL + "/api/v1/Dashboard/View";

export interface IGetDashboardsParams {
  languageId: string;
  DashboardPanelId: string;
  DashboardGroupTypeCode: string;
  LabelFilter: string;
  YearFilter: string;
}

export class DashBoardService {
  create = (params: ICreateDashBoardParams) => {
    return GlobalAxios.post(DASH_BOARD_PANEL_ROUTE, params);
  };

  get = (params: IGetDashboardsParams) => {
    return GlobalAxios.get<IDashboard[]>(DASH_BOARD_PANEL_ROUTE, {
      params,
    });
  };

  getView = (params: IGetDashboardsParams) => {
    return GlobalAxios.get<IDashboard[]>(DASH_BOARD_PANEL_VIEW_ROUTE, {
      params,
    });
  };

  getById = (id: string) => {
    return GlobalAxios.get<IDashboard>(DASH_BOARD_PANEL_ROUTE + "/" + id);
  };

  list = (languageId: string) => {
    return GlobalAxios.get<IListIDashboard[]>(
      DASH_BOARD_PANEL_ROUTE + "/list",
      {
        params: { languageId },
      }
    );
  };

  listComponents = (params: { languageId: string; DashboardId: string }) => {
    return GlobalAxios.get<IListComponent[]>(
      DASH_BOARD_PANEL_ROUTE + "/Components/List",
      {
        params,
      }
    );
  };

  addComponent = (params: {
    dashboardEstablishmentId: string;
    dashboardComponentId: string;
    languageId: string;
  }) => {
    return GlobalAxios.post<IDashboardEstablishmentComponent>(
      DASH_BOARD_PANEL_ROUTE + "/AddComponent",
      params
    );
  };

  deleteComponent = (dashboardComponentId: string) => {
    return GlobalAxios.delete(
      DASH_BOARD_PANEL_ROUTE + "/DeleteComponent/" + dashboardComponentId
    );
  };

  updateComponentEnable = (params: { id: string; enable: boolean }) => {
    return GlobalAxios.put(
      DASH_BOARD_PANEL_ROUTE + "/UpdateEnableComponent/" + params.id,
      params
    );
  };

  deleteDashboard = (dashboardId: string) => {
    return GlobalAxios.delete(DASH_BOARD_PANEL_ROUTE + "/" + dashboardId);
  };

  updateComponentsOrder = (params: {
    dashboardEstablishmentId: string;
    dashboardEstablishmentComponentIds: (string | undefined)[];
  }) => {
    return GlobalAxios.put(
      DASH_BOARD_PANEL_ROUTE +
        "/UpdateDashBoardComponentOrder/" +
        params.dashboardEstablishmentId,
      params
    );
  };
}

export default DashBoardService;
