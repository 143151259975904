import { Grid } from "@mui/material";
import { memo, useCallback } from "react";
import { TextField } from "../../../../TextField/TextField";
import { useTemplateWhats } from "../../../../../../store/contexts/TemplateWhatsContext";
import { IWhatsappCallActionProps } from "./models";
import { useTranslation } from "react-i18next";

const translationPath = "components.callAction.";

export const WhatsappCallAction = memo(
  ({ id, index }: IWhatsappCallActionProps) => {
    const { t } = useTranslation();

    const { updateCtaHandler } = useTemplateWhats();

    const getTextedValue = useCallback(
      (text: string) => {
        updateCtaHandler(id, "text", text);
      },
      [id, updateCtaHandler]
    );

    return (
      <Grid item xs={12} sm={9}>
        <TextField
          name={`ctaButtons.${index}.text`}
          label={t(translationPath + "text")}
          fullWidth
          size="small"
          maxLength={25}
          onChangeValue={getTextedValue}
          sx={(t) => ({
            background: `linear-gradient(${t.palette.background.default} 40px, transparent 40px)`,
            borderRadius: 1,
          })}
        />
      </Grid>
    );
  }
);
