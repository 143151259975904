import { Stack } from "@mui/material";
import { memo, useCallback, useRef } from "react";
import { Loading } from "@4uhub/lib4uhub";

import Header from "./Header/Header";
import CalendarBody from "./CalendarBody";
import useElementSize from "../../../hooks/useElementSize";
import { ECalendarMode } from "./models";
import CalendarProvider from "./CalendarContext/CalendarProvider";
import { ICalendar } from "../model";
import { useSchedule } from "../ScheduleProvider";

interface TCalendarProps {
  onAvaiableTimeClick?: (avaiableTime: ICalendar) => void;
  renderPopOver: (
    event: ICalendar,
    onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void
  ) => React.ReactNode;
}

const Calendar = (props: TCalendarProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const { height } = useElementSize(ref);

  const { setFilter, filter, loading, ...otherProps } = useSchedule();

  const onModeChangeHandler = useCallback(
    (m: ECalendarMode) => {
      setFilter({ mode: m });
    },
    [setFilter]
  );

  return (
    <CalendarProvider
      mode={filter.mode}
      onModeChange={onModeChangeHandler}
      {...props}
      {...otherProps}
    >
      <Stack height={"100%"} width={"100%"} position={"relative"}>
        {loading && <Loading sx={{ backgroundColor: "rgb(60 58 58 / 30%)" }} />}
        <Header headerRef={ref} />
        <CalendarBody headerHeigth={height} />
      </Stack>
    </CalendarProvider>
  );
};

export default memo(Calendar) as typeof Calendar;
