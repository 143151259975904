import { GlobalAxios } from "../axios/axios-config";

const BASE_CACHE_URL = process.env.REACT_APP_IDENTIY + "/api/v1/Cache";

export class CacheService {
  cleanAllCache = () => {
    return GlobalAxios.delete(BASE_CACHE_URL);
  };

  cleanCacheByKey = (key: string) => {
    return GlobalAxios.delete(BASE_CACHE_URL + "/key/" + key);
  };

  getCacheByKey = (key: string) => {
    return GlobalAxios.get(BASE_CACHE_URL + "/keys/" + key);
  };
}

export default CacheService;
