import { z } from "zod";

const translationPath = "page.4uHcp.hcp.healthPrograms.errors.";

const language = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional(),
  },
  { invalid_type_error: translationPath + "language" }
);

const healthProgramLanguageSchema = z.object({
  name: z.string().min(1, translationPath + "name"),
  description: z.string().optional().nullable(),
  language: language,
});

const healthProgramType = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.number().optional(),
  },
  { invalid_type_error: translationPath + "health_program_type" }
);

const siteAppSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional(),
});

const workGroupSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.number().optional(),
});

export const healthProgramSchema = z.object({
  id: z.string().optional(),
  healthProgramType: healthProgramType,
  siteApps: z.array(siteAppSchema).min(1, translationPath + "site_apps"),
  healthProgramWorkGroups: z.array(workGroupSchema).optional().nullable(),
  healthProgramLanguages: z
    .array(healthProgramLanguageSchema)
    .min(1, translationPath + "languages"),
});

export type THealthProgramForm = z.infer<typeof healthProgramSchema>;
