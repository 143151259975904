import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../../../services/mainApi.service";
import { Table } from "@4uhub/lib4uhub";
import { IGetServiceDay } from "../../../../../models/service-day";
import { IProtocolGenerate } from "../../../../../models/protocol-generate";

const SERVICE_DAY_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketServiceDays/";
const serviceDayService = new MainApiService<IGetServiceDay>(SERVICE_DAY_ROUTE);

const translationPath = "chatBot.page.service.serviceDay.";
const daysOfWeekTranslation = "components.daysOfWeek.";

const ServiceDayPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { eI } = useParams();

  const columns: GridColDef<IGetServiceDay>[] = [
    {
      field: "ticketService",
      headerName: t(translationPath + "ticket_service") || "Ticket Service",
      renderCell: (params) => params.value.name,
    },
    {
      field: "dayOfWeek",
      headerName: t(translationPath + "day_of_week") || "Day of week",
      renderCell: (params) =>
        t(daysOfWeekTranslation + params.value.toLowerCase()),
    },
    {
      field: "startTimeTimeOnly",
      headerName: t(translationPath + "start_time") || "Start time",
      renderCell: (params) =>
        params.value ? params.value.substring(0, 5) : "-",
    },
    {
      field: "endTimeTimeOnly",
      headerName: t(translationPath + "end_time") || "End time",
      renderCell: (params) =>
        params.value ? params.value.substring(0, 5) : "-",
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IProtocolGenerate>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<IGetServiceDay>
      showDefaultMacroFilters={false}
      service={serviceDayService}
      columns={columns}
      title={t(translationPath + "service_days")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      backButton
      backButtonUrl={`/e/${eI}/dashboard/settings`}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for opening hours"
      }
      addButtonLabel={
        t(translationPath + "add_service_day") || "Add Protocol Generate Rule"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default ServiceDayPage;
