import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  AutoCompleteUncontrolled,
  ISelectType,
  Table,
  useFetch,
} from "@4uhub/lib4uhub";

import MainApiService from "../../../../services/mainApi.service";

import { IProfileTasyBus } from "../../../../models/profile-tasy-bus";
import { useCallback, useEffect, useMemo, useState } from "react";
import { listRestrictionRuleExtensions } from "../../../../services/restrictionRuleType.service";
import { Chip } from "@mui/material";

const PROFILE_TASY_BUS_ROUTE =
  process.env.REACT_APP_4UBUS + "/api/v1/RestrictionRuleProfile/";
const profileTasyBusService = new MainApiService<IProfileTasyBus>(
  PROFILE_TASY_BUS_ROUTE
);

const translationPath = "page.4uBus.register.profileTasyBus.";

const ProfileTasyBusPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { sendRequest } = useFetch(listRestrictionRuleExtensions);

  const [extensions, setExtensions] = useState<ISelectType[]>([]);

  const fetchExtensions = useCallback(async () => {
    const { data, success } = await sendRequest(null);
    if (data && success) {
      setExtensions(data);
      setSelectedExtension(data[0]);
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchExtensions();
  }, [fetchExtensions]);

  const [selectedExtension, setSelectedExtension] =
    useState<ISelectType | null>(null);

  const columns: GridColDef<IProfileTasyBus>[] = [
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
    },
    {
      field: "isGlobal",
      headerName: t(translationPath + "is_global") || "Global Profile",
      maxWidth: 150,
      renderCell: (params) => (
        <Chip
          size="small"
          label={
            params.value
              ? t(translationPath + "global")
              : t(translationPath + "not_global")
          }
          color={params.value ? "success" : "error"}
        />
      ),
    },
    {
      field: "enable",
      headerName: t(translationPath + "status") || "Status",
      maxWidth: 150,
      renderCell: (params) => (
        <Chip
          size="small"
          label={
            params.value
              ? t(translationPath + "active")
              : t(translationPath + "inactive")
          }
          color={params.value ? "success" : "error"}
        />
      ),
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IProfileTasyBus>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const props = useMemo(() => {
    return {
      ExtensionCode: selectedExtension?.code,
    };
  }, [selectedExtension]);

  return (
    <Table<IProfileTasyBus>
      showDefaultMacroFilters={false}
      service={selectedExtension ? profileTasyBusService : undefined}
      columns={columns}
      title={t(translationPath + "profile_tasy_bus")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      serviceProps={props}
      macroFilters={() => (
        <AutoCompleteUncontrolled
          label={t(translationPath + "extension") || "Extension"}
          onValueSelected={setSelectedExtension}
          value={selectedExtension}
          options={extensions}
          clearable={false}
          size="small"
          fullWidth
          sx={{ minWidth: 300 }}
        />
      )}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a profile tasy bus"
      }
      addButtonLabel={
        t(translationPath + "add_profile_tasy_bus") || "Add Profile Tasy Bus"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default ProfileTasyBusPage;
