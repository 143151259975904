import { NoPaginationApiService } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";

export class NoPaginationApi<T extends any> extends NoPaginationApiService<T> {
  constructor(rp: string) {
    super(rp, GlobalAxios);
  }
}

export default NoPaginationApi;
