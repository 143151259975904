import { useContext } from "react";
import { CalendarContext } from "./CalendarContext";

const useCalendar = () => {
  const context = useContext(CalendarContext);

  if (context === undefined) {
    throw new Error("use Calendar must be usede with CalendarProvider");
  }

  return context;
};

export default useCalendar;
