import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  AutoComplete,
  AutoCompleteMulti,
  FormContainer,
  ISelectType,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import MainApi from "../../../../../services/mainApi.service";
import { useCallback, useEffect, useState } from "react";
import {
  createHealthProgram,
  getHealthProgramById,
  updateHealthProgramById,
} from "../../../../../services/healthProgram.service";
import { THealthProgramForm, healthProgramSchema } from "./HealthProgramSchema";
import HealthProgramLanguages from "./HealthProgramLanguages";
import HealthProgramApplications from "./HealthProgramApplications";

const translationPath = "page.4uHcp.hcp.healthPrograms.";

const HEALTH_PROGRAM_TYPE_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/HealthProgramType/";
const healthProgramTypeService = new MainApi<ISelectType>(
  HEALTH_PROGRAM_TYPE_ROUTE
);

const WORK_GROUPS_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/HealthProgramWorkGroup/";
const workGroupsService = new MainApi<ISelectType>(WORK_GROUPS_ROUTE);

const LANGUAGE_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Language/";
const languageService = new MainApi<ISelectType>(LANGUAGE_ROUTE);

const HealthProgram = () => {
  const { t } = useTranslation();

  const [allLanguages, setAllLanguages] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(languageService.list);

  const fetchLanguages = useCallback(async () => {
    const { data, success } = await sendRequest(undefined);
    if (data && success) {
      setAllLanguages(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchLanguages();
  }, [fetchLanguages]);

  const { sendRequest: save, loading: sLoading } =
    useFetch(createHealthProgram);

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateHealthProgramById
  );

  const { sendRequest: get, loading: gLoading } =
    useFetch(getHealthProgramById);

  const saveHandler = (v: THealthProgramForm) =>
    save({
      ...v,
      healthProgramTypeId: v.healthProgramType.id,
      healthProgramLanguages: v.healthProgramLanguages.map((lang) => ({
        ...lang,
        languageId: lang.language.id,
        description: lang.description || null,
      })),
      healthProgramWorkGroupIds: v.healthProgramWorkGroups
        ? v.healthProgramWorkGroups.map((group) => group.id)
        : [],
      siteAppIds: v.siteApps.map((app) => app.id),
    });

  const updateHandler = (v: THealthProgramForm, id: string) =>
    update({
      item: {
        ...v,
        healthProgramTypeId: v.healthProgramType.id,
        healthProgramLanguages: v.healthProgramLanguages.map((lang) => ({
          ...lang,
          languageId: lang.language.id,
          description: lang.description || null,
        })),
        healthProgramWorkGroupIds: v.healthProgramWorkGroups
          ? v.healthProgramWorkGroups.map((group) => group.id)
          : [],
        siteAppIds: v.siteApps.map((app) => app.id),
      },
      id: id,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<THealthProgramForm>> => {
      const { data, success } = await get(id);

      if (data) {
        const newData: THealthProgramForm = {
          ...data,
          healthProgramLanguages: data.healthProgramLanguages.map(
            (workGroupLang) => ({
              language: workGroupLang.language,
              name: workGroupLang.name,
              description: workGroupLang.description || "",
            })
          ),
          siteApps: data.siteApps.map((app) => ({
            id: app.id,
            name: app.name,
          })),
          healthProgramType: {
            id: data.healthProgramType.id,
            name: data.healthProgramType.name,
          },
          healthProgramWorkGroups: data.healthProgramWorkGroups
            ? data.healthProgramWorkGroups.map((group) => ({
                id: group.id,
                name: group.name,
              }))
            : null,
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  if (allLanguages.length === 0) return null;

  return (
    <FormContainer<THealthProgramForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "health_program")}
      schema={healthProgramSchema}
      subtitle={t(translationPath + "new_health_program")}
      subtitleWatchField={"healthProgramType.name"}
      defaultValues={{
        healthProgramLanguages: [
          {
            name: "",
            description: "",
            language: allLanguages[0],
          },
        ],
      }}
      fields={(type) => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <AutoComplete
              getOptionLabel={(option) => {
                return option.name;
              }}
              size="small"
              label={
                t(translationPath + "health_program_type") ||
                "Health Program Type"
              }
              name="healthProgramType"
              request={healthProgramTypeService.list}
              disable={type === "update"}
            />
          </Grid>

          <HealthProgramApplications />

          <Grid item xs={12} sm={12}>
            <AutoCompleteMulti
              size="small"
              label={t(translationPath + "work_groups") || "Work Groups"}
              name="healthProgramWorkGroups"
              request={workGroupsService.list}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <HealthProgramLanguages allLanguages={allLanguages} />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default HealthProgram;
