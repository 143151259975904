import { GlobalAxios } from "../axios/axios-config";
import {
  IExternalProcessType,
  IExternalProcessTypes,
} from "../models/external-process-type";

const EXTERNAL_PROCESS_TYPE_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketExternalProcessType/";

export const createExternalProcessType = (item: IExternalProcessType) => {
  return GlobalAxios.post(EXTERNAL_PROCESS_TYPE_ROUTE, item);
};

export const getExternalProcessTypeById = (id: string) => {
  return GlobalAxios.get<IExternalProcessTypes>(
    EXTERNAL_PROCESS_TYPE_ROUTE + id
  );
};

export const updateExternalProcessTypeById = ({
  item,
  id,
}: {
  item: IExternalProcessType;
  id: string;
}) => {
  return GlobalAxios.put(EXTERNAL_PROCESS_TYPE_ROUTE + id, item);
};
