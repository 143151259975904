import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

// Schema
import {
  TParamDefaultEstablishmentForm,
  paramDefaultEstablishmentSchema,
} from "./ParamDefaultEstablishmentSchema";
import { useCallback, useState } from "react";
import MainApiService from "../../../../../../services/mainApi.service";
import {
  AutoComplete,
  FormContainer,
  FormInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import ParamDefaultEstablishmentService from "../../../../../../services/paramDefaultEstablishment.service";
import { IGeneric } from "../../../../../../models/generics";

const paramDefaultEstablishmentService = new ParamDefaultEstablishmentService();

const ESTABLISHMENTS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const establishmentsListService = new MainApiService<IGeneric>(
  ESTABLISHMENTS_ROUTE
);

const translationPath = "page.register.paramDefaultEstablishment.";

const Country = () => {
  const { t } = useTranslation();
  const [description, setDescription] = useState<string>();

  const { sendRequest: save, loading: sLoading } = useFetch(
    paramDefaultEstablishmentService.create
  );
  const { sendRequest: update, loading: uLoading } = useFetch(
    paramDefaultEstablishmentService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    paramDefaultEstablishmentService.getItemById
  );

  const saveHandler = (v: TParamDefaultEstablishmentForm) =>
    save({
      ...v,
      establishmentParamTypeId: v.establishmentParamType.id,
    });

  const updateHandler = (v: TParamDefaultEstablishmentForm, id: string) =>
    update({
      item: {
        ...v,
        establishmentParamTypeId: v.establishmentParamType.id,
      },
      id: id,
    });

  const handleGet = useCallback(
    async (id: string): Promise<Treturn<TParamDefaultEstablishmentForm>> => {
      const { data, success } = await get(id);

      setDescription(data?.establishmentParamType.description);

      if (success) {
        return {
          data,
          success,
        };
      }

      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TParamDefaultEstablishmentForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={handleGet}
      loading={loading}
      title={t(translationPath + "param_default")}
      schema={paramDefaultEstablishmentSchema}
      subtitle={t(translationPath + "new_param_default")}
      subtitleWatchField={"establishmentParamType.name"}
      fields={(type) => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <AutoComplete
              disable={type === "update" ? true : false}
              params={{ identifier: "EstablishmentParamType" }}
              autoFocus
              onValueChange={(e) => {
                setDescription(e?.description);
              }}
              getOptionLabel={(option: any) => {
                return option.name;
              }}
              renderOption={(props, option) => {
                let render: string = option.name;

                if (option.code) {
                  render = `${option.name} (${option.code})`;
                }
                return (
                  <li {...props} key={option.id}>
                    {render}
                  </li>
                );
              }}
              size="small"
              label={
                t(translationPath + "establishment_param_type") ||
                "Establishment Param Type"
              }
              name="establishmentParamType"
              request={establishmentsListService.list}
            />
          </Grid>

          {description && (
            <Grid item xs={12}>
              <Typography
                sx={(theme) => ({
                  marginLeft: theme.spacing(1),
                })}
                variant="subtitle2"
              >
                {description}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="value"
              fullWidth
              label={t(translationPath + "value") || "Value"}
              multiline
              minRows={4}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="description"
              fullWidth
              label={t(translationPath + "description") || "Description"}
              multiline
              minRows={4}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default Country;
