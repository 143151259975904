import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTemplateWhats } from "../../../../../store/contexts/TemplateWhatsContext";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { WhatsappChat } from "@4uhub/lib4uhub";

const translationPath = "chatBot.page.templates.whatsapp.";

export const WhatsappMessagePreview = memo(() => {
  const { t } = useTranslation();

  const {
    templateWhatsBasicData,
    ctaButtons,
    customButtons,
    buttonsList,
    showAllOptions,
    variables,
    closeAllOptions,
    openAllOptions,
  } = useTemplateWhats();

  const { title, body, footer } = templateWhatsBasicData;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  if (matches) return null;

  return (
    <Grid
      item
      container
      sm={matches ? 12 : 3}
      sx={(t) => ({
        display: "flex",
        flexDirection: "column",
        backgroundColor: t.palette.grey[t.palette.mode === "light" ? 200 : 800],
        borderRadius: 2,
        boxShadow: "0px 0px 10px -6px rgba(66,66,66,0.6)",
        position: "relative",
      })}
    >
      <Box
        sx={(t) => ({
          padding: 2,
          paddingLeft: 4,
          width: "100%",
          height: "fit-content",
          backgroundColor:
            t.palette.mode === "light"
              ? t.palette.background.default
              : t.palette.grey[900],
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
        })}
      >
        <Typography fontWeight="bold">
          {t(translationPath + "message_preview")}
        </Typography>
      </Box>
      <Box sx={{ position: "sticky", top: 118 }}>
        <WhatsappChat
          title={title}
          body={body}
          footer={footer}
          ctaButtons={ctaButtons}
          customButtons={customButtons}
          buttonsList={buttonsList}
          showAllOptions={showAllOptions}
          onCloseAllOptions={closeAllOptions}
          openAllOptions={openAllOptions}
          variables={variables}
        />
      </Box>
    </Grid>
  );
});
