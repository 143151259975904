import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import { FormInput, useFormContext } from "@4uhub/lib4uhub";

import { useEffect } from "react";
import { useWatch } from "react-hook-form";
import { TRuleForm } from "./ruleSchema";

const translationPath = "page.settings.4uBus.externalExamPrescription.rules.";

const ExceptionFields = () => {
  const { t } = useTranslation();
  const { setValue, control } = useFormContext<TRuleForm>();

  const isExceptions = useWatch({ control, name: "isExceptions" });

  useEffect(() => {
    if (isExceptions) {
      setValue("sectorCode", null);
      setValue("execPhysicianCode", null);
    }
  }, [setValue, isExceptions]);

  if (isExceptions) return null;

  return (
    <>
      <Grid item xs={12} sm={6}>
        <FormInput
          size="small"
          name="sectorCode"
          fullWidth
          label={t(translationPath + "sector_code")}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormInput
          size="small"
          name="execPhysicianCode"
          fullWidth
          label={t(translationPath + "exec_physician_code")}
        />
      </Grid>
    </>
  );
};

export default ExceptionFields;
