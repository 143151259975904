import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback } from "react";
import {
  TSettingsCacheKeyForm,
  settingsCacheKeySchema,
} from "./SettingsCacheKeySchema";
import {
  Form,
  FormInput,
  useFetch,
  useNotificationContext,
} from "@4uhub/lib4uhub";
import CacheService from "../../../../../../services/cache.service";

const translationPath = "components.settingsCache.settingsCacheKey.";

const service = new CacheService();

const SettingsCacheKey = () => {
  const { t } = useTranslation();
  const methods = useForm<TSettingsCacheKeyForm>({
    resolver: zodResolver(settingsCacheKeySchema),
  });

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const { sendRequest, loading } = useFetch(service.cleanCacheByKey);
  const { handleSubmit, reset } = methods;

  const { setMessage } = useNotificationContext();
  const onSubmitHandler = useCallback(
    async (values: TSettingsCacheKeyForm) => {
      const data = await sendRequest(values.key);
      if (data) {
        setMessage({
          message: `${t(translationPath + "clean_key_success", {
            key: values.key,
          })}`,
          type: "success",
          notificationKey: new Date().getMilliseconds(),
        });

        reset();
      }
    },
    [sendRequest, setMessage, t, reset]
  );

  return (
    <Stack
      display={"flex"}
      flexDirection={!matches ? "row" : "column"}
      alignItems={!matches ? "center" : "end"}
      justifyContent={!matches ? "space-between" : undefined}
      gap={!matches ? 4 : 0}
    >
      <Stack>
        <Typography variant="subtitle1" fontWeight={"bold"}>
          {t(translationPath + "clean_key_cache")}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={(theme) => ({
            color:
              theme.palette.mode === "light"
                ? theme.palette.grey[600]
                : theme.palette.grey[400],
          })}
        >
          {t(translationPath + "clean_key_description")}
        </Typography>
      </Stack>
      <Form {...methods}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitHandler)}
          sx={{
            mt: !matches ? 0 : 2,
            width: !matches ? 700 : "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Grid container columnSpacing={!matches ? 2 : 0}>
            <Grid item xs={8} sm={9} md={9} lg={9}>
              <FormInput
                size="small"
                name="key"
                label={t(translationPath + "clean_key")}
                fullWidth
              />
            </Grid>
            <Grid item xs={4} sm={3} md={3} lg={3} textAlign={"end"}>
              <Button
                variant="contained"
                disabled={loading ? true : false}
                type="submit"
              >
                {loading && (
                  <Stack direction={"row"} spacing={2}>
                    <CircularProgress size={25} sx={{ marginInline: 2 }} />
                  </Stack>
                )}
                {!loading && t(translationPath + "clean_key_button")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Form>
    </Stack>
  );
};

export default SettingsCacheKey;
