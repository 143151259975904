import { useNotificationContext } from "@4uhub/lib4uhub";
import { Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const translationPath = "page.4uBus.register.accessTokens.";

interface IAccessTokenInformationsProps {
  token: string;
  createdDate: string;
  name: string;
}

const AccessTokenInformations = ({
  token,
  createdDate,
  name,
}: IAccessTokenInformationsProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { setMessage } = useNotificationContext();

  const copyTokenHandler = (
    event: React.MouseEvent<HTMLButtonElement> | undefined,
    text: string
  ) => {
    event?.stopPropagation();
    navigator.clipboard.writeText(text);
    setMessage({
      message: t(translationPath + "copied_token"),
      type: "success",
    });
  };

  return (
    <>
      <Grid item xs={12} sm={6} mb={1}>
        <Stack width={"100%"}>
          <Typography
            sx={(t) => ({
              color:
                t.palette.mode === "light"
                  ? t.palette.grey["600"]
                  : t.palette.grey["400"],
            })}
          >
            {t(translationPath + "token_public")}:
          </Typography>
          <Stack flexDirection={"row"} alignItems={"center"}>
            <Tooltip title={token}>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {token}
              </Typography>
            </Tooltip>
            <Tooltip title={t(translationPath + "copy")}>
              <IconButton
                size="small"
                onClick={(e) => copyTokenHandler(e, token)}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6} mb={1}>
        <Stack width={"100%"} flexDirection={"column"} gap={0.5}>
          <Typography
            sx={(t) => ({
              color:
                t.palette.mode === "light"
                  ? t.palette.grey["600"]
                  : t.palette.grey["400"],
            })}
          >
            {t(translationPath + "created")}:
          </Typography>
          <Typography
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {new Date(createdDate)
              .toLocaleString(language)
              .replaceAll(",", "")
              .slice(0, -3)}
          </Typography>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6} mb={1}>
        <Stack width={"100%"}>
          <Typography
            sx={(t) => ({
              color:
                t.palette.mode === "light"
                  ? t.palette.grey["600"]
                  : t.palette.grey["400"],
            })}
          >
            {t(translationPath + "name")}:
          </Typography>
          <Stack flexDirection={"row"} alignItems={"center"}>
            <Typography>{name}</Typography>
          </Stack>
        </Stack>
      </Grid>
    </>
  );
};

export default AccessTokenInformations;
