import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  DateInput,
  FormContainer,
  FormInput,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import { useCallback, useState } from "react";
import FormTextEditor from "../../../../components/UI/TextEditor/FormTextEditor";
import { TFormsForm, formSchema } from "./formSchema";
import {
  createEvaluationForm,
  getEvaluationFormById,
  updateEvaluationFormById,
} from "../../../../services/evaluationForm.service";
import { SiteAppExtension } from "./SiteAppExtension/SiteAppExtension";
import { IQuestionList } from "../../../../models/questions";
import { SelectQuestions } from "./SelectQuestions/SelectQuestions";

const translationPath = "page.evaluation.forms.";

const Form = () => {
  const { t } = useTranslation();

  const [defaultQuestions, setDefaultQuestions] = useState<IQuestionList[]>([]);

  const { sendRequest: save, loading: sLoading } =
    useFetch(createEvaluationForm);

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateEvaluationFormById
  );

  const { sendRequest: get, loading: gLoading } = useFetch(
    getEvaluationFormById
  );

  const saveHandler = (v: TFormsForm) =>
    save({
      ...v,
      questions: v.evaluationFormQuestions.map((q) => ({
        questionId: q.id,
        displayOrder: Number(q.displayOrder),
      })),
      siteAppExtensionId: v.siteAppExtension.id,
      initialDate: v.initialDate.toISOString().substring(0, 10),
      endDate: v.endDate.toISOString().substring(0, 10),
    });

  const updateHandler = (v: TFormsForm, id: string) =>
    update({
      item: {
        ...v,
        questions: v.evaluationFormQuestions.map((q) => ({
          questionId: q.id,
          displayOrder: Number(q.displayOrder),
        })),
        siteAppExtensionId: v.siteAppExtension.id,
        initialDate: v.initialDate.toISOString().substring(0, 10),
        endDate: v.endDate.toISOString().substring(0, 10),
      },
      id: id,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TFormsForm>> => {
      const { data } = await get(id);
      if (data) {
        setDefaultQuestions(
          data.evaluationFormQuestions.map((q) => ({
            ...q,
            id: q.question.id,
            displayOrder: q.displayOrder.toString(),
            questionEvaluationType: q.question.questionEvaluationType,
            title: q.question.title,
            description: "",
            integrationId: "",
          }))
        );

        return {
          data: {
            ...data,
            integrationId: data.integrationId || "",
            evaluationFormQuestions: data.evaluationFormQuestions.map((q) => ({
              id: q.question.id,
              title: q.question.title,
              type: q.question.questionEvaluationType,
              displayOrder: q.displayOrder.toString(),
            })),
            siteAppExtension: {
              id: data.siteAppExtension.id,
              name: data.siteAppExtension.siteApp.name,
            },
            initialDate: new Date(data.initialDate),
            endDate: new Date(data.endDate),
          },
          success: true,
        };
      }
      return {
        data: null,
        success: false,
      };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TFormsForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "form")}
      schema={formSchema}
      subtitle={t(translationPath + "new_form")}
      subtitleWatchField={"title"}
      fields={() => (
        <Grid container spacing={2} mb={10}>
          <Grid item xs={12} sm={12} textAlign="end">
            <SwitchInput
              name="enable"
              label={t(translationPath + "enable") || "Enable"}
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <FormInput
              size="small"
              name="title"
              fullWidth
              label={t(translationPath + "title") || "Title"}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <FormInput
              size="small"
              name="integrationId"
              fullWidth
              label={
                t(translationPath + "integration_id") || "Integration Code"
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DateInput
              name="initialDate"
              label={t(translationPath + "initial_date")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DateInput name="endDate" label={t(translationPath + "end_date")} />
          </Grid>

          <SiteAppExtension />

          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="description"
              fullWidth
              label={t(translationPath + "description") || "Description"}
              multiline
              minRows={4}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            sx={{ display: "flex", flexDirection: "column", gap: 1 }}
          >
            <Typography>{t(translationPath + "visual_description")}</Typography>
            <FormTextEditor
              name="visualDescription"
              menuBarProps={{
                fontSize: true,
                selectColor: false,
                textFormatting: true,
                markFormatting: false,
              }}
            />
          </Grid>

          <SelectQuestions defaultQuestions={defaultQuestions} />
        </Grid>
      )}
    />
  );
};

export default Form;
