import { FormInput, useFormContext } from "@4uhub/lib4uhub";
import { Grid, InputAdornment, Typography } from "@mui/material";
import { memo, useEffect } from "react";
import { TTextFieldProps } from "./models";
import { useWatch } from "react-hook-form";
import { useTemplateWhats } from "../../../store/contexts/TemplateWhatsContext";

export const TextField = memo(
  ({
    name,
    label,
    maxLength,
    mask,
    onChangeValue,
    ...otherProps
  }: TTextFieldProps) => {
    const { control } = useFormContext();

    const { updateBasicData } = useTemplateWhats();

    const textValue = useWatch({
      name,
      control,
    });

    useEffect(() => {
      if (textValue) {
        updateBasicData(name, textValue);
        if (onChangeValue) onChangeValue(textValue);
      } else {
        updateBasicData(name, "");
        if (onChangeValue) onChangeValue("");
      }
    }, [name, textValue, updateBasicData, onChangeValue]);

    return (
      <Grid item xs={12} sm={12}>
        <FormInput
          {...otherProps}
          size="small"
          name={name}
          fullWidth
          label={label}
          inputProps={{ maxLength }}
          mask={mask}
          InputProps={
            maxLength
              ? {
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={
                        otherProps.multiline
                          ? { marginBottom: 2, alignSelf: "end" }
                          : undefined
                      }
                    >
                      <Typography
                        variant="body2"
                        sx={(t) => ({
                          color:
                            t.palette.grey[
                              t.palette.mode === "light" ? 400 : 600
                            ],
                        })}
                      >
                        {textValue ? textValue.length : 0}/{maxLength}
                      </Typography>
                    </InputAdornment>
                  ),
                }
              : undefined
          }
          {...otherProps}
        />
      </Grid>
    );
  }
);
