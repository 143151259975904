import { useCallback } from "react";
import { tokenService } from "../services/token.service";
import { useParams } from "react-router-dom";

const useJobs = () => {
  const { eI } = useParams();
  const openJobs = useCallback(
    (establishmentId?: string) => {
      const token = tokenService.getAccessTokens();
      const establishmentToken = token.find((t) => t.code === Number(eI));
      if (establishmentToken && !establishmentId) {
        window.open(
          `${process.env.REACT_APP_JOBS}/api/v1/Auth?token=${establishmentToken.token}`,
          "_blank"
        );
      }
      if (establishmentToken && establishmentId) {
        window.open(
          `${process.env.REACT_APP_JOBS}/api/v1/Auth/Premisse?token=${establishmentToken.token}&establishmentId=${establishmentId}`
        );
      }
    },
    [eI]
  );

  return {
    openJobs,
  };
};

export default useJobs;
