import { z } from "zod";

const translationPath = "page.register.holidays.errors.";

const holidayTypeSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "holiday_type" }
);

export const holidaySchema = z.object({
  id: z.string().optional(),
  name: z.string().nonempty(translationPath + "name"),
  description: z.string().nonempty(translationPath + "description"),
  date: z.date({
    invalid_type_error: translationPath + "date",
  }),
  holidayType: holidayTypeSchema,
});

export type THolidayForm = z.infer<typeof holidaySchema>;
