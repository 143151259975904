import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import {
  IContractProduct,
  IContractProductPayload,
} from "../models/contract-product";

const serviceURL = process.env.REACT_APP_MAIN + "/api/v1/SalesProductCtr";

export const createContractProduct = (payload: IContractProductPayload) => {
  return GlobalAxios.post(serviceURL, payload);
};

export const updateContractProduct = ({
  id,
  item,
}: {
  item: IContractProductPayload;
  id: string;
}) => {
  return GlobalAxios.put(`${serviceURL}/${id}`, item);
};

export const findContractProductById = (id: string) => {
  return GlobalAxios.get<IContractProduct>(`${serviceURL}/${id}`);
};

export const listContractProducts = () => {
  return GlobalAxios.get<ISelectType[]>(`${serviceURL}/list`);
};

export const removeContractProduct = (id: string) => {
  return GlobalAxios.delete(`${serviceURL}/${id}`);
};

export const approveSalesProduct = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Approve/${id}`);
};
export const inactivateSalesProduct = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Inactivate/${id}`);
};
export const reactivateSalesProduct = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Reactivate/${id}`);
};
export const suspendSalesProduct = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Suspend/${id}`);
};
export const cancelSalesProduct = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Cancel/${id}`);
};
