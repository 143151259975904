import { Box } from "@mui/material";
import { memo } from "react";
import { useTemplateWhats } from "../../../../../store/contexts/TemplateWhatsContext";
import { WhatsappChat } from "@4uhub/lib4uhub";

export const WhatsappPreviewModal = memo(() => {
  const {
    ctaButtons,
    customButtons,
    showAllOptions,
    buttonsList,
    templateWhatsBasicData,
    variables,
    openAllOptions,
    closeAllOptions,
  } = useTemplateWhats();

  const { title, body, footer } = templateWhatsBasicData;

  return (
    <Box sx={{ position: "sticky", top: 118 }}>
      <WhatsappChat
        title={title}
        body={body}
        footer={footer}
        ctaButtons={ctaButtons}
        customButtons={customButtons}
        buttonsList={buttonsList}
        showAllOptions={showAllOptions}
        onCloseAllOptions={closeAllOptions}
        openAllOptions={openAllOptions}
        variables={variables}
      />
    </Box>
  );
});
