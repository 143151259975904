import {
  createContext,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import {
  IDashboard,
  IDashboardEstablishmentComponent,
} from "../../models/dashboard";
import { IComponent } from "../../components/Dashboards/DashBoards/DashBoardsEditAndReorderComponents/Components";

interface IDashBoardContextProps {
  dashboard: IDashboard;
  addComponent: (component: IDashboardEstablishmentComponent) => void;
  removeComponent: (id: string) => void;
  onDragComponents: (components: IComponent[]) => void;
  updateComponentEnable: (id: string, enable: boolean) => void;
}

const DashBoardContext = createContext<IDashBoardContextProps | undefined>(
  undefined
);

interface IDashBoardProviderProps {
  children: React.ReactNode;
  dashboard: IDashboard;
}

export const DashBoardProvider: React.FC<IDashBoardProviderProps> = memo(
  ({ children, dashboard: defaultDashboard }) => {
    const [dashboard, setDashboard] = useState<IDashboard>(defaultDashboard);

    const addComponent = useCallback(
      (component: IDashboardEstablishmentComponent) => {
        setDashboard((d) => ({
          ...d,
          dashboardEstablishmentComponents: [
            ...d.dashboardEstablishmentComponents,
            component,
          ],
        }));
      },
      []
    );

    const removeComponent = useCallback((id: string) => {
      setDashboard((d) => {
        const filtered = d.dashboardEstablishmentComponents.filter(
          (dE) => dE.id !== id
        );
        return {
          ...d,
          dashboardEstablishmentComponents: filtered,
        };
      });
    }, []);

    const updateComponentEnable = useCallback((id: string, enable: boolean) => {
      setDashboard((d) => {
        return {
          ...d,
          dashboardEstablishmentComponents:
            d.dashboardEstablishmentComponents.map((dashboardComponent) => {
              if (dashboardComponent.id === id) {
                dashboardComponent.enable = enable;
              }

              return dashboardComponent;
            }),
        };
      });
    }, []);

    const onDragComponents = useCallback((components: IComponent[]) => {
      setDashboard((d) => {
        const changed = d.dashboardEstablishmentComponents.map(
          (dashboardEstablishmentComponent) => {
            const componentOrder = components.find(
              (c) =>
                c.dashBoardComponentId === dashboardEstablishmentComponent.id
            )?.order;

            if (componentOrder) {
              dashboardEstablishmentComponent.displayOrder = componentOrder;
            }

            return dashboardEstablishmentComponent;
          }
        );

        return {
          ...d,
          dashboardEstablishmentComponents: changed,
        };
      });
    }, []);

    const value = useMemo(
      () => ({
        dashboard,
        addComponent,
        removeComponent,
        onDragComponents,
        updateComponentEnable
      }),
      [dashboard, addComponent, removeComponent, onDragComponents,updateComponentEnable]
    );

    return (
      <DashBoardContext.Provider value={value}>
        {children}
      </DashBoardContext.Provider>
    );
  }
);

export const useDashboard = () => {
  const context = useContext(DashBoardContext);

  if (!context) {
    throw new Error("useDashboard must be used with DashBoardProvider");
  }

  return context;
};
