import {
  AutoComplete,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { listTimeClassification } from "../../../../../../../services/ticketAutomationTimeClassification.service";
import { useAutomationRules } from "../../../../../../../store/contexts/AutomationRulesContext";
import { TTimeRuleForm } from "./timeRuleSchema";

const translationPath = "components.timeRules.";

export const TimeClassification = memo(() => {
  const { t } = useTranslation();

  const { setValue } = useFormContext<TTimeRuleForm>();

  const { automation } = useAutomationRules();

  const [, setCurrentAutomation] = useState<ISelectType | null>(automation);

  const [options, setOptions] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(listTimeClassification);

  const fetchTimeClassifications = useCallback(async () => {
    if (!automation) return;
    const { data, success } = await sendRequest({
      TicketAutomationId: automation.id,
    });
    if (data && success) {
      setOptions(
        data.map((d) => ({ id: d.id, name: d.timeClassification.name }))
      );
    }
  }, [sendRequest, automation]);

  useEffect(() => {
    fetchTimeClassifications();
  }, [fetchTimeClassifications]);

  useEffect(() => {
    setCurrentAutomation((oldState) => {
      if (oldState !== automation) {
        setValue("timeClassification", null);
      }
      return automation;
    });
  }, [automation, setValue]);

  return (
    <Grid item xs={12} sm={5}>
      <AutoComplete
        name="timeClassification"
        label={
          t(translationPath + "time_classification") || "Time Classification"
        }
        getOptionLabel={(option) => option.name}
        size="small"
        fullWidth
        options={options}
      />
    </Grid>
  );
});
