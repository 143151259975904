import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useCallback, useState } from "react";
import { authService } from "../../services/auth.service";
import ForgetPasswordModal from "./ForgetPasswordModal/ForgetPasswordModal";
import {
  Form,
  FormInput,
  Modal,
  useFetch,
  useNotificationContext,
} from "@4uhub/lib4uhub";
import { useNavigate } from "react-router-dom";

const ForgetPasswordForm = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const { setMessage } = useNotificationContext();
  const navigate = useNavigate();

  const forgetPasswordFormSchema = z.object({
    login: z
      .string()
      .min(1, `${t("components.forgetPasswordForm.login_required")}`),
  });

  type TForgetPasswordForm = z.infer<typeof forgetPasswordFormSchema>;

  const methods = useForm<TForgetPasswordForm>({
    resolver: zodResolver(forgetPasswordFormSchema),
  });

  const { handleSubmit } = methods;

  const { sendRequest: sendEmail, loading } = useFetch(authService.sendEmail);

  const onSubmitHandler = useCallback(
    async (login: TForgetPasswordForm) => {
      const { success } = await sendEmail({
        userName: login.login,
        softwareCode: "1",
      });
      if (success) {
        setMessage({
          message: `${t(
            "components.forgetPasswordForm.notification_email_sent"
          )}`,
          type: "success",
          notificationKey: new Date().getMilliseconds(),
        });
        navigate(`/reset-password/${login.login}`);
        setEmail(login.login);
      }
    },
    [sendEmail, t, setMessage, navigate]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <ForgetPasswordModal email={email} />
      </Modal>
      <Form {...methods}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitHandler)}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Typography variant="h6" align="center" mb={3}>
            {t("components.forgetPasswordForm.title")}
          </Typography>
          <Typography variant="subtitle2" align="center" mb={3}>
            {t("components.forgetPasswordForm.subtitle")}
          </Typography>
          <FormInput
            size="small"
            autoFocus={true}
            name={"login"}
            fullWidth
            label={t("components.forgetPasswordForm.login")}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            disabled={loading ? true : false}
            type="submit"
          >
            {loading && (
              <Stack direction={"row"} spacing={2}>
                <CircularProgress size={18} />
                <Typography align="center" pt={0.5} variant="body2">
                  {t("components.forgetPasswordForm.sending")}
                </Typography>
              </Stack>
            )}
            {!loading && t("components.forgetPasswordForm.button")}
          </Button>
        </Box>
      </Form>
    </>
  );
};

export default ForgetPasswordForm;
