import { Box, BoxProps } from "@mui/material";
import {
  add,
  differenceInMinutes,
  endOfDay,
  isSameDay,
  startOfDay,
} from "date-fns";
import { CSSProperties, memo, useMemo } from "react";
import { IGroupedEvents, ITime } from "./models";

type IHourPosition = {
  children: React.ReactNode;
  startDate: Date;
  endDate: Date;
  containerHeigth: number;
  dayIndex: number;
  index: number;
  eventGroup?: IGroupedEvents<ITime>;
  date: Date;
} & BoxProps;

export const TOTAL_MINUTES = 24 * 60;

export const MIN_MINUTES_HEIGHT = 15

const HourPosition: React.FC<IHourPosition> = ({
  children,
  endDate,
  startDate,
  containerHeigth,
  dayIndex,
  eventGroup,
  index,
  date,
  ...boxProps
}) => {
  const eventStyle = useMemo(() => {
    let formatedEndDate = endDate;

    let formatedStartDate = startDate;

    if (isSameDay(endDate, add(date, { days: 1 }))) {
      formatedEndDate = endOfDay(startDate);
    }

    if (isSameDay(startDate, add(date, { days: -1 }))) {
      formatedStartDate = startOfDay(endDate);
    }

    let minutes_passed = differenceInMinutes(
      formatedStartDate,
      startOfDay(date)
    );

    let event_duration = differenceInMinutes(
      formatedEndDate,
      formatedStartDate
    );

    if (event_duration < MIN_MINUTES_HEIGHT) {
      event_duration = MIN_MINUTES_HEIGHT;
    }

    const percentage = minutes_passed / TOTAL_MINUTES;

    const topPosition = percentage * containerHeigth;

    const eventHeigth = (event_duration / TOTAL_MINUTES) * containerHeigth;

    const time_count = eventGroup?.times.length || 1;

    let width_percentage = time_count === 1 ? 1 : (1 / time_count) * 1.7;

    const leftTreshHould = (dayIndex === 0 ? 43 : 0) + 1;

    const isLast = index === time_count - 1;

    if (isLast) {
      width_percentage = 1 / time_count;
    }

    let flexStyle: CSSProperties = {
      justifyContent: "center",
      flexDirection: "column",
    };

    if (eventHeigth < 45) {
      flexStyle = {
        flexDirection: "row",
        alignItems: "center",
        gap: 1,
      };
    }

    const styles = {
      width: `calc((100% - ${leftTreshHould}px) * ${width_percentage})`,
      top: topPosition,
      height: eventHeigth - 1,
      zIndex: 2 + index,
      ...flexStyle,
    };

    if (isLast) {
      return { ...styles, right: 0 };
    }

    return {
      ...styles,
      left: `calc(${leftTreshHould}px + 100% * ${(1 / time_count) * index})`,
    };
  }, [startDate, endDate, containerHeigth, dayIndex, eventGroup, index, date]);

  return (
    <Box
      {...boxProps}
      sx={[
        (t) => ({
          position: "absolute",
        }),
        eventStyle,
        ...(Array.isArray(boxProps.sx) ? boxProps.sx : [boxProps.sx]),
      ]}
    >
      {children}
    </Box>
  );
};

export default memo(HourPosition);
