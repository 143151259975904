import { Stack } from "@mui/material";
import { memo } from "react";

import DayLabel from "./DayLabel";
import SourceTitle from "./SourceTitle";
import ZHour from "./ZHour";

interface IHederProps {
  date: Date;
  showZHour?: boolean;
  showDay?: boolean;
  sourceTitle?: string;
  sourceAvatar?: string;
  sourceColor?: string;
}


export const DAY_HEADER_HEIGHT = 40

const Header: React.FC<IHederProps> = ({
  date,
  showZHour = false,
  showDay = true,
  sourceTitle,
  sourceAvatar,
  sourceColor,
}) => {
  return (
    <Stack
      direction={"row"}
      alignItems={"flex-end"}
      justifyContent={"space-between"}
      sx={(t) => ({
        borderBottom: `1px solid ${t.palette.divider}`,
        position: "sticky",
        top: 0,
        backgroundColor: t.palette.background.default,
        zIndex: 999,
      })}
      height={DAY_HEADER_HEIGHT}
    >
      <Stack direction={"row"} alignItems={"flex-end"}>
        <ZHour date={date} showZHour={showZHour} />
        {showDay && <DayLabel date={date} />}
      </Stack>
      {sourceTitle && (
        <SourceTitle
          sourceTitle={sourceTitle}
          sourceAvatar={sourceAvatar}
          sourceColor={sourceColor}
        />
      )}
    </Stack>
  );
};

export default memo(Header);
