import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  AutoComplete,
  AutoCompleteMulti,
  FormContainer,
  FormInput,
  ISelectType,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import { busUsersList } from "../../../../../services/accessToken.service";
import { useCallback, useEffect, useState } from "react";

import { listRestrictionRuleExtensions } from "../../../../../services/restrictionRuleType.service";
import {
  createProfileTasyBus,
  getProfileTasyBusById,
  updateProfileTasyBusById,
} from "../../../../../services/profileTasyBus.service";
import {
  TProfileTasyBusForm,
  profileTasyBusSchema,
} from "./ProfileTasyBusSchema";
import RestrictionRuleTable from "../RestrictionRule/RestrictionRuleTable";
import { useNavigate, useParams } from "react-router-dom";
import FormInformation from "../../../../../components/FormInformation/FormInformation";

const translationPath = "page.4uBus.register.profileTasyBus.";

const ProfileTasyBus = () => {
  const { t } = useTranslation();

  const { eI } = useParams();

  const navigate = useNavigate();

  const [extension, setExtension] = useState<ISelectType>();

  const [users, setUsers] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(busUsersList);

  const fetchUsers = useCallback(async () => {
    const { data, success } = await sendRequest(null);
    if (data && success) {
      setUsers(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const { sendRequest: save, loading: sLoading } =
    useFetch(createProfileTasyBus);

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateProfileTasyBusById
  );

  const { sendRequest: get, loading: gLoading } = useFetch(
    getProfileTasyBusById
  );

  const saveHandler = useCallback(
    async (v: TProfileTasyBusForm) => {
      const { data, success } = await save({
        ...v,
        extensionId: v.extension.id,
        userIds: v.users ? v.users.map((user) => user.id) : undefined,
      });
      if (data && success) {
        navigate(`/e/${eI}/4uBus/register/profileTasy/${data.id}/edit`);
      }

      return { data, success };
    },
    [save, navigate, eI]
  );

  const updateHandler = useCallback(
    async (v: TProfileTasyBusForm, id: string) => {
      const { data, success } = await update({
        item: {
          ...v,
          extensionId: v.extension.id,
          userIds: v.users ? v.users.map((user) => user.id) : undefined,
        },
        id: id,
      });

      if (data && success) {
        navigate(`/e/${eI}/4uBus/register/profileTasy/`);
      }

      return { data, success };
    },
    [update, navigate, eI]
  );

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TProfileTasyBusForm>> => {
      const { data, success } = await get(id);

      if (data) {
        setExtension(data.extension);

        const newData: TProfileTasyBusForm = {
          ...data,
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TProfileTasyBusForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "profile_tasy_bus")}
      schema={profileTasyBusSchema}
      subtitle={t(translationPath + "new_profile_tasy_bus")}
      subtitleWatchField={"name"}
      redirectOnSave={false}
      backUrl={`/e/${eI}/4uBus/register/profileTasy`}
      fields={(type) => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            <SwitchInput
              name="isGlobal"
              label={t(translationPath + "is_global")}
            />
            <SwitchInput name="enable" label={t(translationPath + "enable")} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="name"
              fullWidth
              autoFocus
              label={t(translationPath + "name") || "Name"}
            />
          </Grid>

          {type === "create" && (
            <Grid item xs={12} sm={6}>
              <AutoComplete
                name="extension"
                label={t(translationPath + "extension") || "Extension"}
                getOptionLabel={(option) => option.name}
                size="small"
                fullWidth
                request={listRestrictionRuleExtensions}
              />
            </Grid>
          )}

          {type === "update" && extension && (
            <Grid item xs={12} sm={6}>
              <FormInformation
                minHeight={40}
                data={extension.name}
                label={t(translationPath + "extension") || "Extension"}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={12}>
            <AutoCompleteMulti
              name="users"
              label={t(translationPath + "users") || "Users"}
              getOptionLabel={(option) => option.name}
              size="small"
              fullWidth
              options={users}
            />
          </Grid>

          {type === "update" && extension && (
            <RestrictionRuleTable extensionCode={extension.code ?? "0"} />
          )}
        </Grid>
      )}
    />
  );
};

export default ProfileTasyBus;
