import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IUsersSuccessProps {
  email: string;
}

const UsersResetSuccess = ({ email }: IUsersSuccessProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Stack>
        <Typography variant="h6" textAlign={"center"}>
          {t("page.register.users.reset_password.email_sent")}
        </Typography>
      </Stack>
      <Typography variant="h6" textAlign={"center"}>
        {email}
      </Typography>
    </>
  );
};

export default UsersResetSuccess;
