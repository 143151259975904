import { Stack, Typography } from "@mui/material";
import { memo } from "react";

interface IDataProps {
  label: string;
  value: string;
}

const Data: React.FC<IDataProps> = ({ label, value }) => {
  return (
    <Stack gap={1}>
      <Typography sx={[(t) => t.typography.body1, { fontWeight: 600 }]}>
        {label}
      </Typography>
      <Typography sx={(t) => t.typography.body2}>{value}</Typography>
    </Stack>
  );
};

export default memo(Data);
