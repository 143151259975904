import { useCallback, useEffect, useState } from "react";
import {
  IGenericsCollector,
  IModalCollector,
} from "../../../../models/collectors";
import { useTranslation } from "react-i18next";
import CollectorService from "../../../../services/collector.service";
import NoData from "../../../../components/Logs/NoData/NoData";
import CustomLoading from "../../../../components/CustomLoading";
import OnPremiseHeader from "./OnPremiseHeader";
import PrettyJson from "./PrettyJson";
import { useFetch } from "@4uhub/lib4uhub";

const GENERICS_COLLECTOR_ROUTE =
  process.env.REACT_APP_COLLECTOR + "/api/v1/EstablishmentExtension/Collector/";
const genericsService = new CollectorService<IGenericsCollector>(
  GENERICS_COLLECTOR_ROUTE
);

const translationPath = "components.onPremiseModal.generics.";

const ExtensionsOnPremise = ({
  modalCollector,
}: {
  modalCollector: IModalCollector | undefined;
}) => {
  const { t } = useTranslation();
  const [collector, setCollector] = useState<IGenericsCollector[]>([]);

  const { sendRequest, loading } = useFetch(genericsService.getAll);

  const loadCollector = useCallback(async () => {
    if (modalCollector) {
      const { data } = await sendRequest(modalCollector.establishmentId);
      if (data) {
        setCollector(data);
      }
    }
  }, [sendRequest, modalCollector]);

  useEffect(() => {
    loadCollector();
  }, [loadCollector]);

  return (
    <>
      {loading && <CustomLoading />}
      <OnPremiseHeader>
        {collector.length > 0 && <PrettyJson>{collector}</PrettyJson>}
        {!loading && collector.length === 0 && (
          <NoData message={t(translationPath + "no_data")} />
        )}
      </OnPremiseHeader>
    </>
  );
};

export default ExtensionsOnPremise;
