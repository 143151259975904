import { IconButton, Popover, Tooltip } from "@mui/material";
import { useState } from "react";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { useTranslation } from "react-i18next";

import Components from "./Components";

const DashBoardsEditAndReorderComponents = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = open ? "dashboard-menu-popover" : undefined;

  return (
    <>
      <Tooltip title={t("components.panel.select_tooltip")}>
        <IconButton size="small" aria-describedby={id} onClick={handleClick}>
          <FormatListBulletedIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Components />
      </Popover>
    </>
  );
};

export default DashBoardsEditAndReorderComponents;
