import { Navigate, Outlet, RouteObject } from "react-router-dom";
import { Apps, ControlAccess, getEstablishmentCode } from "@4uhub/lib4uhub";

import RootPage from "../pages/rootPage";
import ErrorPage from "../pages/errorPage/ErrorPage";
import LoginPage from "../pages/auth/loginPage/LoginPage";

import ForgetPasswordPage from "../pages/auth/forgetPassword/ForgetPasswordPage";
import ResetPasswordPage from "../pages/auth/resetPassword/ResetPasswordPage";
import ExemplesPage from "../pages/ExemplesPage";
import NoAccessPage from "../pages/NoAccessPage";

import { tokenService } from "../services/token.service";
import dashboardRoutes from "./dashboard";

import { Typography } from "@mui/material";
import { AppWrapper } from "../store/appContex";
import chatBotRoutes from "./chatBot";
import siteApp from "./siteApp";
import hcp from "./hcp";
import bus from "./bus";
import sched from "./sched";
import salesContract from "./salesContract";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <Navigate to={`/e/${getEstablishmentCode()}`} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/e/:eI",
    errorElement: <ErrorPage />,
    element: (
      <ControlAccess
        canAccess={() => tokenService.hasAccessTokens()}
        redirectUrl="/login"
      >
        <RootPage />
      </ControlAccess>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="dashboard" />,
      },
      {
        path: "dashboard",
        element: (
          <AppWrapper name={Apps.APP_4UHUB}>
            <Outlet />
          </AppWrapper>
        ),
        children: dashboardRoutes,
      },
      {
        path: "chatbot",
        element: (
          <AppWrapper name={Apps.APP_4UCHATBOT}>
            <Outlet />
          </AppWrapper>
        ),
        children: chatBotRoutes,
      },
      {
        path: "telemed",
        element: (
          <AppWrapper name={Apps.APP_4UTELEMED}>
            <Outlet />
          </AppWrapper>
        ),
        children: [
          {
            index: true,
            element: <Typography>Telemed</Typography>,
          },
        ],
      },
      {
        path: "4uBus",
        element: (
          <AppWrapper name={Apps.APP_4UBUS}>
            <Outlet />
          </AppWrapper>
        ),
        children: bus,
      },
      {
        path: "4uSiteApp",
        element: (
          <AppWrapper name={Apps.APP_4USITEAPP}>
            <Outlet />
          </AppWrapper>
        ),
        children: siteApp,
      },
      {
        path: "4uHcp",
        element: (
          <AppWrapper name={Apps.APP_4UHCP}>
            <Outlet />
          </AppWrapper>
        ),
        children: hcp,
      },
      {
        path: "4uSched",
        element: (
          <AppWrapper name={Apps.APP_4USCHED}>
            <Outlet />
          </AppWrapper>
        ),
        children: sched,
      },
      {
        path: "4uSalesContract",
        element: (
          <AppWrapper name={Apps.APP_4USALESCONTRACT}>
            <Outlet />
          </AppWrapper>
        ),
        children: salesContract,
      },
      {
        path: "no-access",
        element: <NoAccessPage />,
      },
      {
        path: "exemples",
        element: <ExemplesPage />,
      },
    ],
  },
  {
    path: "login",
    element: (
      <ControlAccess
        canAccess={() => !tokenService.hasAccessTokens()}
        redirectUrl="/"
      >
        <LoginPage />
      </ControlAccess>
    ),
  },
  {
    path: "forget-password",
    element: <ForgetPasswordPage />,
  },
  {
    path: "reset-password/:userName",
    element: <ResetPasswordPage />,
  },
];

export default routes;
