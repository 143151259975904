import { GlobalAxios } from "./../axios/axios-config";
import { ISearchParams, ISearchReturn } from "../models/requests";
import { ICardTemplate } from "../models/card-template";
import { ISelectType } from "@4uhub/lib4uhub";
import { TCardTemplateForm } from "../pages/settings/Settings/SettingsSiteApp/CardsTemplates/CardTemplateForm/CardTemplateSchema";

const CARDS_TEMPLATES_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/EstablishmentParamModelCard/";

export class CardTemplateService {
  create = (item: ICardTemplate) => {
    return GlobalAxios.post(CARDS_TEMPLATES_ROUTE, item);
  };

  getAll = (serchParams?: ISearchParams) => {
    return GlobalAxios.get<ISearchReturn<TCardTemplateForm>>(
      CARDS_TEMPLATES_ROUTE,
      {
        params: serchParams,
      }
    );
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<TCardTemplateForm>(CARDS_TEMPLATES_ROUTE + id);
  };

  updateItemById = ({ item, id }: { item: ICardTemplate; id: string }) => {
    return GlobalAxios.put(CARDS_TEMPLATES_ROUTE + id, item);
  };

  deleteItemById = (id: string) => {
    return GlobalAxios.delete(CARDS_TEMPLATES_ROUTE + id);
  };

  list = (params?: any) => {
    return GlobalAxios.get<ISelectType[]>(CARDS_TEMPLATES_ROUTE + "list", {
      params: params,
    });
  };
}

export default CardTemplateService;
