import { GlobalAxios } from "./../axios/axios-config";
import { IWhatsappConfig } from "../models/ticket-service-whatsapp";
import { TTicketServiceWhatsForm } from "../pages/settings/Settings/SettingsCrm/ticketService/TicketServiceWhatsForm/TicketServiceWhatsSchema";

const TICKET_SERVICE_WHATS_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketServiceWhatsAppConfig/";

export class TicketServWhatsService {
  create = (item: IWhatsappConfig) => {
    return GlobalAxios.post(TICKET_SERVICE_WHATS_ROUTE, item);
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<TTicketServiceWhatsForm>(
      TICKET_SERVICE_WHATS_ROUTE + id
    );
  };

  updateItemById = ({ item, id }: { item: IWhatsappConfig; id: string }) => {
    return GlobalAxios.put(TICKET_SERVICE_WHATS_ROUTE + id, item);
  };

  getWhatsConfig = (id: string) => {
    return GlobalAxios.get<IWhatsappConfig>(
      TICKET_SERVICE_WHATS_ROUTE + "TicketService/" + id
    );
  };
}

export default TicketServWhatsService;
