import { GlobalAxios } from "../axios/axios-config";
import { TProvinceForm } from "../pages/globals/provinces/ProvinceForm/ProvinceSchema";

interface IProvince {
  id?: string;
  code: string;
  name: string;
  enable: boolean;
  countryId: string;
}

const PROVINCE_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Province/";

export class ProvinceService {
  create = (item: IProvince) => {
    return GlobalAxios.post(PROVINCE_ROUTE, item);
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<TProvinceForm>(PROVINCE_ROUTE + id);
  };

  updateItemById = ({ item, id }: { item: IProvince; id: string }) => {
    return GlobalAxios.put(PROVINCE_ROUTE + id, item);
  };

  list = (id: string) => {
    return GlobalAxios.get<IProvince>(PROVINCE_ROUTE + id + "/list");
  };
}

export default ProvinceService;
