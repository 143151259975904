import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import {
  IContractAttachmentType,
  IContractAttachmentTypePayload,
} from "../models/contract-attachment-type";

const serviceURL =
  process.env.REACT_APP_MAIN + "/api/v1/SalesContractAttachmentType";

export const createContractAttachmentType = (
  payload: IContractAttachmentTypePayload
) => {
  return GlobalAxios.post(serviceURL, payload);
};

export const updateContractAttachmentType = ({
  id,
  item,
}: {
  id: string;
  item: IContractAttachmentTypePayload;
}) => {
  return GlobalAxios.put(`${serviceURL}/${id}`, item);
};

export const findContractAttachmentTypeById = (id: string) => {
  return GlobalAxios.get<IContractAttachmentType>(`${serviceURL}/${id}`);
};

export const listContractAttachmentType = () => {
  return GlobalAxios.get<ISelectType[]>(`${serviceURL}/list`);
};

export const removeContractAttachmentType = (id: string) => {
  return GlobalAxios.delete(`${serviceURL}/${id}`);
};
