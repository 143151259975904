import { z } from "zod";

const translationPath = "page.register.paramDefaultEstablishment.errors.";

const establishmentParamTypeSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
    description: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "param_type" }
);

export const paramDefaultEstablishmentSchema = z.object({
  id: z.string().optional(),
  establishmentParamType: establishmentParamTypeSchema,
  value: z.string().nonempty(translationPath + "value"),
  description: z.string().nonempty(translationPath + "description"),
});

export type TParamDefaultEstablishmentForm = z.infer<
  typeof paramDefaultEstablishmentSchema
>;
