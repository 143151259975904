import {
  AccessRegister,
  AutoComplete,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { AxiosResponse } from "axios";
import { TDocumentsForm } from "./DocumentsSchema";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";

const translation_path = "page.register.documents.create_document.";

const DocumentLanguage = ({
  name,
  index,
  service,
  loadDefaultLang,
}: {
  name: string;
  index: number;
  service: (params?: any) => Promise<AxiosResponse<ISelectType[]>>;
  loadDefaultLang: (lang: ISelectType) => void;
}) => {
  const { control } = useFormContext<TDocumentsForm>();
  const [languageOptions, setLanguageOptions] = useState<ISelectType[]>([]);
  const { t } = useTranslation();

  const { sendRequest } = useFetch(service);

  const selectedLanguage = useWatch({
    name: "docAcceptanceDocuments",
    control,
  });

  const loadLanguageOptions = useCallback(async () => {
    const { data, success } = await sendRequest(undefined);
    if (data && success) {
      const selectedLang = selectedLanguage.find(
        (lang, indexLang) => indexLang === index
      );
      const filteredOptions = data.filter(
        (objeto2) =>
          !selectedLanguage.some(
            (objeto1) => objeto1.language?.id === objeto2.id
          )
      );
      loadDefaultLang(filteredOptions[0]);
      if (selectedLang?.language && filteredOptions.length !== 0) {
        setLanguageOptions([...filteredOptions, selectedLang.language]);
      } else {
        setLanguageOptions(filteredOptions);
      }
    }
  }, [sendRequest, loadDefaultLang, selectedLanguage, index]);

  useEffect(() => {
    loadLanguageOptions();
  }, [loadLanguageOptions]);

  return (
    <Grid item xs={12} sm={6}>
      <AccessRegister
        redirectTo="dashboard/globals/languages"
        domainName="language"
        inputValue={
          selectedLanguage[index].language
            ? [
                {
                  id: selectedLanguage[index].language!.id,
                  name: selectedLanguage[index].language!.name,
                },
              ]
            : []
        }
        tooltip={t(translation_path + "language_tooltip")}
      >
        <AutoComplete
          label={t(translation_path + "language") || "Language"}
          name={name}
          size="small"
          getOptionLabel={(option) => {
            return option.name;
          }}
          options={languageOptions}
          filterSelectedOptions
        />
      </AccessRegister>
    </Grid>
  );
};

export default DocumentLanguage;
