import { FormCheckBox, useFormContext } from "@4uhub/lib4uhub";
import { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TTicketServiceAreaForm } from "./TicketServiceAreaSchema";
import { useWatch } from "react-hook-form";

const translationPath = "chatBot.page.service.ticketServiceArea.";

export const EnableSpecialTime = memo(
  ({ onIsSpecialTime }: { onIsSpecialTime: (value: boolean) => void }) => {
    const { t } = useTranslation();

    const { control, setValue } = useFormContext<TTicketServiceAreaForm>();

    const enableAllTheTime = useWatch({
      name: "enableAllTheTime",
      control,
    });

    const enableSpecialTime = useWatch({
      name: "enableSpecialTime",
      control,
    });

    useEffect(() => {
      if (enableAllTheTime) {
        setValue("enableSpecialTime", false);
      }
    }, [enableAllTheTime, setValue]);

    useEffect(() => {
      onIsSpecialTime(enableSpecialTime);
    }, [onIsSpecialTime, enableSpecialTime]);

    return (
      <FormCheckBox
        name="enableSpecialTime"
        label={`${t(translationPath + "enable_special_time")}`}
      />
    );
  }
);
