import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import DocumentsApiService from "../../../../../services/documents.service";
import { IDocumentVersion } from "../../../../../models/documents";
import EditorComponent from "../../../../../components/UI/TextEditor/Editor";
import { format } from "date-fns";
import { TabPanel, useFetch } from "@4uhub/lib4uhub";

const docListService = new DocumentsApiService();
const translationPath = "page.register.documents.document_modal.";

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const DocumentsModal: React.FC<{
  open: boolean;
  onClose: () => void;
  versionId: string;
}> = ({ open, onClose, versionId }) => {
  const [document, setDocument] = useState<IDocumentVersion>();
  const [value, setValue] = useState(0);
  const { t } = useTranslation();

  const { sendRequest: loadVersion } = useFetch(docListService.getItemById);

  useEffect(() => {
    const loadRequest = async () => {
      const { data } = await loadVersion(versionId);

      if (data) {
        setDocument(data);
      }
    };

    loadRequest();
  }, [loadVersion, versionId]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Grid
        container
        justifyContent="flex-end"
        sx={{ marginBottom: "10px", marginTop: "10px" }}
      >
        <Grid item xs={12}>
          <Typography variant="h5">
            {t(translationPath + "version")}: {document?.version}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      {!document && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "50%",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {document && (
        <Grid container spacing={2} sx={{ marginTop: "5px" }}>
          <Grid item container direction="column" xs={12}>
            <Grid item>
              <Typography
                variant="subtitle2"
                color={(theme) => theme.palette.text.secondary}
              >
                {t(translationPath + "version_date") || "Version date"}:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                {format(new Date(document?.versionDate), "dd/MM/yyyy HH:mm:ss")}
              </Typography>
            </Grid>
          </Grid>

          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                {document?.docAcceptanceDocuments.map((doc, index) => (
                  <Tab label={doc.name} {...a11yProps(index)} key={index} />
                ))}
              </Tabs>
            </Box>
            {document?.docAcceptanceDocuments.map((doc, index) => (
              <TabPanel value={value} index={index} key={index}>
                <Grid item container direction="column" xs={12}>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      color={(theme) => theme.palette.text.secondary}
                    >
                      {t(translationPath + "name") || "Name"}:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      id="modal-modal-description"
                    >
                      {doc.name}
                    </Typography>
                  </Grid>
                  {Object.entries(doc.language).map(([key, value]: any) => (
                    <Grid key={key}>
                      {key === "name" && (
                        <>
                          <Grid item>
                            <Typography
                              variant="subtitle2"
                              color={(theme) => theme.palette.text.secondary}
                            >
                              {t(translationPath + "language") || "Language"}:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="subtitle1"
                              id="modal-modal-description"
                            >
                              {value}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  ))}
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      color={(theme) => theme.palette.text.secondary}
                    >
                      {t(translationPath + "content") || "Content"}:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <EditorComponent
                      value={doc.content}
                      readOnly
                      maxHeight="360px"
                    />
                  </Grid>
                </Grid>
              </TabPanel>
            ))}
          </Box>
        </Grid>
      )}
    </Box>
  );
};

export default DocumentsModal;
