import { SwitchInput, useFormContext } from "@4uhub/lib4uhub";
import { Grid, Typography } from "@mui/material";
import { memo, useEffect } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BoardList } from "../../../BasicData/BoardList/BoardList";
import { TMessageForm } from "../messageSchema";

const translationPath = "components.messages.";

export const Actions = memo(() => {
  const { t } = useTranslation();

  const { control, setValue } = useFormContext<TMessageForm>();

  const moveTicket = useWatch({ name: "moveTicket", control });

  useEffect(() => {
    if (!moveTicket) {
      setValue("ticketChannelWhenNot", null);
      setValue("ticketChannelStepWhenNot", null);
    }
  }, [setValue, moveTicket]);

  return (
    <Grid
      container
      spacing={2}
      sx={(t) => ({
        mt: 5,
        pr: 3,
        pl: 1,
        py: 3,
        border: `1px solid ${t.palette.divider}`,
        borderRadius: 2,
      })}
    >
      <Grid item sm={12}>
        <Typography fontWeight="bold">
          {t(`${translationPath}actions`)}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <SwitchInput
          name="moveTicket"
          label={t(`${translationPath}move_ticket`)}
        />
      </Grid>
      {moveTicket && (
        <BoardList
          boardFieldName="ticketChannelWhenNot"
          listFieldName="ticketChannelStepWhenNot"
        />
      )}
    </Grid>
  );
});
