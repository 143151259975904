import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Chip } from "@mui/material";
import {
  OverflowTooltip,
  Table,
  useFetch,
  useNotificationContext,
} from "@4uhub/lib4uhub";
import MainApi from "../../../../services/mainApi.service";
import { IGetSitesApps } from "../../../../models/site-app";
import { ITicketAutomationTemplate } from "../../../../models/ticket-automation";
import ReplayIcon from "@mui/icons-material/Replay";
import { resendWhatsappTemplate } from "../../../../services/whatsappTemplate.service";
import { useCallback, useMemo, useState } from "react";

const TICKET_AUTOMATION_ROUTE =
  process.env.REACT_APP_AUTOMATIONS + "/api/v1/TicketAutomationTemplate/";
const ticketAutomationService = new MainApi<ITicketAutomationTemplate>(
  TICKET_AUTOMATION_ROUTE
);

const translationPath = "chatBot.page.templates.whatsapp.";

const WhatsappPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [refresh, setRefresh] = useState(0);

  const { setMessage } = useNotificationContext();

  const { sendRequest, loading } = useFetch(resendWhatsappTemplate);

  const resendWhatsappTemplateHandler = useCallback(
    async (params: GridRowParams<ITicketAutomationTemplate>) => {
      const { data, success } = await sendRequest({
        item: {
          ticketAutomationTemplateId: params.row.id,
          ticketAutomationTemplateMetasId:
            params.row.ticketAutomationTemplateMetas[
              params.row.ticketAutomationTemplateMetas.length - 1
            ].id,
        },
        id: params.row.id,
      });
      if (data && success) {
        setRefresh((prevState) => prevState + 1);
        setMessage({
          message: t(translationPath + "resend_success"),
          type: "success",
        });
      }
    },
    [sendRequest, setMessage, t]
  );

  const columns: GridColDef<ITicketAutomationTemplate>[] = [
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
    },
    {
      field: "ticketAutomation",
      headerName: t(translationPath + "automation") || "Automation",
      renderCell: (params) =>
        params.value ? (
          <OverflowTooltip
            text={params.value.name}
            tooltip={params.value.name}
            textTransform="none"
            variant="body2"
          />
        ) : (
          "-"
        ),
    },
    {
      field: "status",
      headerName:
        t(translationPath + "automation_status") || "Automation Status",
      maxWidth: 200,
      renderCell: (params) =>
        params.value ? (
          <OverflowTooltip
            text={params.value.name}
            tooltip={params.value.name}
            textTransform="none"
            variant="body2"
          />
        ) : (
          "-"
        ),
    },
    {
      field: "enable",
      headerName: t(translationPath + "status") || "Status",
      maxWidth: 120,
      renderCell: (params) => (
        <Chip
          size="small"
          label={
            params.value
              ? t(translationPath + "active")
              : t(translationPath + "inactive")
          }
          color={params.value ? "success" : "error"}
        />
      ),
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IGetSitesApps>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const props = useMemo(() => {
    return {
      refresh: refresh,
    };
  }, [refresh]);

  return (
    <Table<ITicketAutomationTemplate>
      showDefaultMacroFilters={false}
      service={ticketAutomationService}
      columns={columns}
      title={t(translationPath + "whatsapp_templates")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      serviceProps={props}
      pageSizeOptions={[5, 10, 20]}
      syncing={loading}
      rowActions={(params) =>
        params.row.status.code === "5"
          ? [
              {
                icon: <ReplayIcon fontSize="small" />,
                text: t(translationPath + "resend"),
                action: resendWhatsappTemplateHandler,
              },
            ]
          : []
      }
      searchInputPlaceHolder={
        t(translationPath + "search") || "Searh for Whatsapp template"
      }
      addButtonLabel={
        t(translationPath + "add_whatsapp_template") || "Add Whatsapp Template"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default WhatsappPage;
