import React from "react";
import { Stack } from "@mui/material";
import { IMenuActionsProps } from "./models";

const MenuActions: React.FC<IMenuActionsProps> = ({ children }) => {
  return (
    <Stack
      direction={{ xs: "row", sm: "row", lg: "column" }}
      alignItems={"center"}
      spacing={1}
    >
      {children}
    </Stack>
  );
};

export default MenuActions;
