import { useTranslation } from "react-i18next";
import SyncSection from "../SyncSection/SyncSection";
import { useFetch, useNotificationContext } from "@4uhub/lib4uhub";
import { useCallback, useState } from "react";
import IntegratedDataModal from "../../../../../../components/IntegratedData/IntegratedDataModal";
import {
  getIntegratedRelationship,
  syncRelationshipNow,
} from "../../../../../../services/relationship.service";
import { ISyncProps } from "../models";

const translationPath = "components.syncRelationship.";

const SyncRelationship = ({ syncAllLoading }: ISyncProps) => {
  const { t } = useTranslation();

  const { setMessage } = useNotificationContext();

  const [open, setOpen] = useState(false);

  const { sendRequest, loading } = useFetch(syncRelationshipNow);

  const syncHandler = useCallback(async () => {
    const { success } = await sendRequest(undefined);
    if (success) {
      setMessage({
        message: t(translationPath + "synced"),
        type: "success",
      });
    }
  }, [sendRequest, setMessage, t]);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  return (
    <>
      <IntegratedDataModal
        open={open}
        onClose={closeModal}
        title={t(translationPath + "modal_title")}
        service={getIntegratedRelationship}
      />
      <SyncSection
        title={t(translationPath + "title")}
        description={t(translationPath + "description")}
        loading={loading}
        onSync={syncHandler}
        onViewData={openModal}
        disable={syncAllLoading}
      />
    </>
  );
};

export default SyncRelationship;
