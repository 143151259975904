import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import {
  IGetTicketService,
  INewTicketService,
} from "../models/tickets-service";

const TICKET_SERVICE_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketServices/";

const TICKET_SERVICE_LIST =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketServiceType/";

export class TicketServService {
  create = (item: INewTicketService) => {
    return GlobalAxios.post(TICKET_SERVICE_ROUTE, item);
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<IGetTicketService>(TICKET_SERVICE_ROUTE + id);
  };

  updateItemById = ({ item, id }: { item: INewTicketService; id: string }) => {
    return GlobalAxios.put(TICKET_SERVICE_ROUTE + id, item);
  };

  list = (params?: any) => {
    return GlobalAxios.get<ISelectType[]>(TICKET_SERVICE_LIST + "list", {
      params: params,
    });
  };
}

export default TicketServService;
