import { useCallback, useMemo, useState } from "react";

const usePopover = <T extends Element>(id: string) => {
  const [anchorEl, setAnchorEl] = useState<T | null>(null);

  const handleClick = useCallback((event: React.MouseEvent<T>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const popOverId = useMemo(() => (open ? id : undefined), [open, id]);

  return { handleClick, id: popOverId, open, handleClose, anchorEl };
};

export default usePopover;
