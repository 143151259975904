import { Box, Stack, SxProps, Theme, Typography } from "@mui/material";
import { isToday } from "date-fns";
import { memo, useCallback } from "react";

import { ECalendarMode } from "../../models";
import useCalendar from "../../CalendarContext/useCalendar";
import useDate from "../../../../../hooks/useDate";

interface IDayLabelProps {
  date: Date;
  sx?: SxProps<Theme>;
}

const DayLabel: React.FC<IDayLabelProps> = ({ date, sx }) => {
  const { goToDay } = useCalendar();

  const onClickHandler = useCallback(() => {
    goToDay(date, ECalendarMode.DAY);
  }, [date, goToDay]);

  const { localeFormat } = useDate();

  return (
    <Stack m={1} sx={sx} flexDirection={"row"} alignItems={"center"} gap={2}>
      <Box
        onClick={onClickHandler}
        sx={(t) => ({
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: isToday(date) ? t.palette.primary.main : undefined,
          borderRadius: 10,
          height: 30,
          color: isToday(date)
            ? t.palette.primary.contrastText
            : t.palette.text.primary,
          width: 30,
          "&:hover": {
            opacity: 0.9,
            cursor: "pointer",
            backgroundColor:
              t.palette.mode === "dark"
                ? t.palette.grey[900]
                : t.palette.grey[300],
          },
        })}
      >
        <Typography>{localeFormat(date, "d")}</Typography>
      </Box>
      <Typography>{localeFormat(date, "eee.")}</Typography>
    </Stack>
  );
};

export default memo(DayLabel);
