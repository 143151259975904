import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";

import ProfilesService from "../../../../services/profiles.service";
import RolesInherited from "./RolesInherited";
import { TUserForm } from "./UserSchema";

import AutoCompleteRoles from "../../../../components/UI/Inputs/AutoCompleteRoles";
import RolesService from "../../../../services/roles.service";
import {
  AccessRegister,
  AutoCompleteMulti,
  EstablishmentAutoComplete,
  IEstablishment,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import EstablishmentsService from "../../../../services/establishments.service";
import { TFormType } from "../../../../models/form";
import { useParams } from "react-router-dom";

const ROLES_ROUTE =
  process.env.REACT_APP_IDENTIY + "/api/v1/RoleEstablishments/";
const rolesService = new RolesService(ROLES_ROUTE);

const profilesService = new ProfilesService();

const establishmentListService = new EstablishmentsService();

const UserConfigsData = ({ type }: { type: TFormType }) => {
  const { t } = useTranslation();

  const { eI } = useParams();

  const [profilesOptions, setProfilesOptions] = useState<ISelectType[]>([]);

  const [establishmentsList, setEstablishmentsList] = useState<
    IEstablishment[]
  >([]);

  const { sendRequest } = useFetch(profilesService.list);

  const { sendRequest: getEstablishments } = useFetch(
    establishmentListService.masterList
  );

  const fetchEstablishments = useCallback(async () => {
    const { data, success } = await getEstablishments(null);
    if (data && success) {
      setEstablishmentsList(data);
    }
  }, [getEstablishments]);

  const loadProfilesOptions = useCallback(async () => {
    const { data } = await sendRequest(null);
    if (data) {
      setProfilesOptions(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    loadProfilesOptions();

    fetchEstablishments();
  }, [loadProfilesOptions, fetchEstablishments]);

  const { control, setValue } = useFormContext<TUserForm>();

  const establishments = useWatch({
    name: "establishments",
    control,
  });

  const roles = useWatch({
    name: "roles",
    control,
  });

  useEffect(() => {
    if (type === "create") {
      if (!eI) return;

      const currentEstablishment = establishmentsList.find(
        (establishment) => establishment.tenantSequential === +eI
      );

      if (currentEstablishment) {
        setValue("establishments", [currentEstablishment]);
      }
    }
  }, [type, setValue, establishmentsList, eI]);

  return (
    <>
      <Grid item xs={12}>
        <AccessRegister
          redirectTo="dashboard/admSystem/establishments"
          domainName="establishment"
          inputValue={establishments}
          tooltip={t("page.register.users.establishments_tooltip")}
        >
          <EstablishmentAutoComplete
            multiple
            name={"establishments"}
            label={
              t("page.register.users.establishments_access") ||
              "Establishments that the user can access"
            }
            establishments={establishmentsList}
            disable={type === "create"}
            disableEstablishmentOption
          />
        </AccessRegister>
      </Grid>

      <Grid item xs={12}>
        <AutoCompleteMulti
          size="small"
          label={t("page.register.users.user_roles") || "User roles"}
          trigger={["roles", "profiles"]}
          name={"profiles"}
          options={profilesOptions}
        />
      </Grid>

      <Grid item xs={12}>
        <AccessRegister
          redirectTo="dashboard/admSystem/roles"
          domainName="role"
          inputValue={
            roles?.map((role) => ({ id: role.id, name: role.name })) || []
          }
          tooltip={t("page.register.users.roles_tooltip")}
        >
          <AutoCompleteRoles
            size="small"
            label={t("page.register.users.user_profiles") || "User profiles"}
            name={"roles"}
            request={rolesService.list}
          />
        </AccessRegister>
      </Grid>
      <RolesInherited profilesOptions={profilesOptions} />
    </>
  );
};

export default UserConfigsData;
