import { zodResolver } from "@hookform/resolvers/zod";
import { Box } from "@mui/material";
import {  useForm } from "react-hook-form";
import { AutoComplete, Form } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

import { ISiteAppEstablishment } from "../../../models/users";
import UserResetButtons from "./UserResetButtons";
import { TUserResetForm, userResetSchema } from "./UserResetSchema";

interface IUserResetFormProps {
  isAdmin: boolean;
  loading: boolean;
  options: ISiteAppEstablishment[];
  onCancel: () => void;
  onResetHandler: () => Promise<void>;
}

const UserResetForm = ({
  isAdmin,
  loading,
  options,
  onCancel,
  onResetHandler,
}: IUserResetFormProps) => {
  const { t } = useTranslation();
  const methods = useForm<TUserResetForm>({
    resolver: zodResolver(userResetSchema),
  });

  const { handleSubmit } = methods;

  return (
    <Form {...methods}>
      <Box
        autoComplete="off"
        component="form"
        onSubmit={handleSubmit(onResetHandler)}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AutoComplete
          getOptionLabel={(option) => {
            return option.name;
          }}
          size="small"
          label={t("page.register.users.reset_password.site")}
          name="site"
          options={options}
          sx={{ marginBlock: 4 }}
        />
        <UserResetButtons
          loading={loading}
          onCancel={onCancel}
          isAdmin={isAdmin}
        />
      </Box>
    </Form>
  );
};

export default UserResetForm;
