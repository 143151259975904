import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import { BackButton, useConfig } from "@4uhub/lib4uhub";

import useWindowDimensions from "../../../hooks/useWindowDimensions";
import DashBoardModal from "../../../components/Dashboards/DashBoards/Modal/DashBoardsModal";
import { PanelProvider } from "../../../store/contexts/DashBoardPannelContext";
import PanelHeader from "../../../components/Dashboards/Panel/PanelHeader";
import Layout from "../../../components/Dashboards/Panel/Layout";
import DashBoardPanelService from "../../../services/dashboardpanel.service";

const service = new DashBoardPanelService();

interface IPanelProps {
  isView?: boolean;
}
const Panel: React.FC<IPanelProps> = ({ isView = false }) => {
  const { panelId } = useParams();

  const [open, setOpen] = useState(false);

  const {
    configuration: {
      language: { id: languageId },
    },
  } = useConfig();

  const { width } = useWindowDimensions();

  const panelWidth = useMemo(() => width - 356, [width]);

  const onClickHandler = useCallback(() => {
    setOpen(true);
  }, []);

  const handlerClose = useCallback(() => {
    setOpen(false);
  }, []);

  if (!panelId) {
    return null;
  }

  return (
    <PanelProvider
      panelId={panelId}
      isView={isView}
      languageId={languageId}
      requestById={isView ? service.getViewById : service.getById}
    >
      <BackButton />
      <Stack
        gap={2}
        sx={(t) => ({
          ".react-grid-item.react-grid-placeholder": {
            backgroundColor: t.palette.primary.main,
          },
          ".react-grid-item.react-draggable-dragging": {
            boxShadow: "21px 16px 15px -3px rgba(0,0,0,0.1);",
          },
          ".react-resizable-handle::after": {
            borderColor: t.palette.grey[500],
          },
          height: "calc(100% - 36.5px)",
          overflow: "hidden",
        })}
      >
        <PanelHeader onClick={onClickHandler} />

        <DashBoardModal open={open} onClose={handlerClose} />

        <Layout width={panelWidth} />
      </Stack>
    </PanelProvider>
  );
};

export default Panel;
