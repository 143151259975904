import { useTranslation } from "react-i18next";

import { AutoComplete } from "@4uhub/lib4uhub";

import { listProductsCtrClassifications } from "../../../../../../../services/salesProductCtrClassification.service";

const translationsPath = "page.4uSalesContract.registers.contractProducts.";

const SalesProductCtrClassificationSelect = () => {
  const { t } = useTranslation();

  return (
    <AutoComplete
      name="salesProductCtrClassification"
      size="small"
      label={t(translationsPath + "products.sales_product_ctr_classification")}
      getOptionLabel={({ name }) => name}
      request={listProductsCtrClassifications}
    />
  );
};

export default SalesProductCtrClassificationSelect;
