import { IChannelClassification } from "../../../../models/channel-classification";
import MainApi from "../../../../services/mainApi.service";

const serviceURL =
  process.env.REACT_APP_MAIN + "/api/v1/SalesChannelClassification/";

const translationPath =
  "page.4uSalesContract.registers.channelClassifications.";

export const channelClassificationsService =
  new MainApi<IChannelClassification>(serviceURL);

export const buildTPath = (field: string) => translationPath + field;
