import { Button } from "@4uhub/lib4uhub";
import { Stack, Typography } from "@mui/material";
import { memo } from "react";
import { IRedirectPageProps } from "./models";

export const RedirectPage = memo(
  ({ title, subtitle, buttonLabel, onClick }: IRedirectPageProps) => {
    return (
      <Stack
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={4}
      >
        <Stack textAlign={"center"}>
          <Typography variant="h5">{title}</Typography>
          <Typography
            variant="subtitle1"
            sx={(theme) => ({
              color:
                theme.palette.mode === "light"
                  ? theme.palette.grey[600]
                  : theme.palette.grey[400],
            })}
          >
            {subtitle}
          </Typography>
        </Stack>
        <Button onClick={onClick} variant="contained">
          {buttonLabel}
        </Button>
      </Stack>
    );
  }
);
