import {
  Autocomplete,
  Chip,
  TextField,
} from "@mui/material";
import { stringToColor, useFetch } from "@4uhub/lib4uhub";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { IProfessional } from "../model";
import { ScheduleAppointmentService } from "../Schedule.service";
import SelectAll from "./SelectAll";

interface IProfessionalProps {
  value: string[];
  onChange: (value: string[]) => void;
  speciality: string | null;
}

const Professional: React.FC<IProfessionalProps> = ({
  onChange,
  value,
  speciality,
}) => {
  const [professional, setProfessional] = useState<IProfessional[]>([]);

  const [selectAll, setSelectAll] = useState<boolean>(false);

  const { t } = useTranslation();

  const { sendRequest, loading } = useFetch(
    ScheduleAppointmentService.getProfessionals
  );

  const fetch = useCallback(async () => {
    setProfessional([]);
    setSelectAll(false)
    if (!speciality) return;
    const { data, success } = await sendRequest({
      MedicalSpecialtyCode: speciality,
    });

    if (success && data) {
      setProfessional(data);
    }
  }, [sendRequest, speciality]);

  const valuee = useMemo(
    () => professional.filter((p) => value.includes(String(p.identifier))),
    [professional, value]
  );

  const handleToggleSelectAll = () => {
    setSelectAll((prev) => {
      if (!prev) onChange(professional.map((n) => String(n.identifier)));
      else onChange([]);

      return !prev;
    });
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Autocomplete
      multiple
      disabled={!speciality}
      value={valuee}
      onChange={(event: any, newValue) => {
        onChange(newValue.map((n) => String(n.identifier)));
      }}
      getOptionKey={(o) => o.identifier}
      options={professional}
      loading={loading}
      loadingText={t("components.schedule.menu.professionals.loading")}
      noOptionsText={t("components.schedule.menu.professionals.noOptionsText")}
      fullWidth
      size="small"
      PaperComponent={(paperProps) => (
        <SelectAll
          {...paperProps}
          selected={selectAll}
          toggleAll={handleToggleSelectAll}
        />
      )}
      getOptionLabel={(option) => option.descriptionSite}
      filterSelectedOptions
      disableClearable
      renderTags={(value: readonly IProfessional[], getTagProps) =>
        value.map((option: IProfessional, index: number) => {
          const color = stringToColor(option.descriptionSite);
          return (
            <Chip
              size="small"
              label={option.descriptionSite}
              sx={(t) => ({
                background: color,
                color: t.palette.getContrastText(color),
              })}
              {...getTagProps({ index })}
            />
          );
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("components.schedule.menu.professionals.label")}
        />
      )}
    />
  );
};

export default memo(Professional);
