import { useState } from "react";

import { IconButton, Stack, useMediaQuery, useTheme } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "../../Drawer";
import { Apps } from "@4uhub/lib4uhub";
import { useApp } from "../../../store/appContex";
import MenuItemList from "../MenuItemList/MenuItemList";
import UserAvatar from "../UserAvatar/UserAvatar";
import MenuSecondaryItemList from "../MenuSecondaryItemList/MenuSecondaryItemList";
import DynamicLogo from "../SubMenu/DynamicLogo";

export const IconMenu = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { app } = useApp();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const closeHandler = () => setOpenDrawer(false);
  const openHandler = () => setOpenDrawer(true);

  const isDashboard = app === Apps.APP_4UHUB;

  return (
    <>
      <Drawer
        open={openDrawer}
        toggleDetail={closeHandler}
        anchor={"left"}
        alignClose="end"
      >
        <Stack sx={{ pb: 2 }} flexDirection={"row"} gap={1}>
          <DynamicLogo />
        </Stack>
        <Stack
          sx={(theme) => ({
            height: "100%",
            width: "100%",
            [theme.breakpoints.down("lg")]: {
              height: "calc(100vh - 7rem)",
            },
          })}
          pt={{ lg: 15, sm: 0 }}
          direction="column"
          alignItems={"flex-start"}
          justifyContent={"space-between"}
        >
          <MenuItemList activeAccordion={matches} onClick={closeHandler} />

          {matches ? (
            <Stack spacing={2} padding={2} width={matches ? "100%" : undefined}>
              {isDashboard && <MenuSecondaryItemList onClick={closeHandler} />}
              <UserAvatar />
            </Stack>
          ) : (
            <>
              {isDashboard && <MenuSecondaryItemList onClick={closeHandler} />}
              <UserAvatar />
            </>
          )}
        </Stack>
      </Drawer>
      <Stack
        direction={{ xs: "row", sm: "row", lg: "column" }}
        alignItems="center"
        spacing={2}
        mt={(theme) => ({
          [theme.breakpoints.up("lg")]: 7,
        })}
      >
        <IconButton
          color="primary"
          aria-label="toggle menu"
          onClick={matches ? openHandler : undefined}
        >
          <MenuIcon />
        </IconButton>
      </Stack>
    </>
  );
};
