import { useMemo } from "react";
import {
  Autocomplete,
  Box,
  Chip,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { memo, useCallback, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ISelectCountry, TFormAutoCompleteProps } from "./models";
import { useDebounce, useFetch, useFormContext } from "@4uhub/lib4uhub";
import { countriesList } from "../../../../services/country.service";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (option: ISelectCountry) =>
    option.name + option.code + option.ddiCode,
});

export const AutoCompleteCountry = memo(
  ({
    name,
    options,
    params,
    disable,
    filterSelectedOptions = false,
    clearIcon = true,
    defaultValue,
    getOptionLabel,
    onValueChange,
    SearchAsYouTypeParams,
    isOptionEqualToValue = (o, v) => {
      return o.id === v.id;
    },
    renderOption = (props, option) => {
      return (
        <li {...props} key={option.id}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography>{option.code + " +" + option.ddiCode}</Typography>
            <Typography
              variant="body2"
              sx={(t) => ({
                color: t.palette.grey[t.palette.mode === "light" ? 600 : 400],
              })}
            >
              {option.name}
            </Typography>
          </Box>
        </li>
      );
    },
    ...otherProps
  }: TFormAutoCompleteProps) => {
    const { control } = useFormContext();

    const [realOptions, setOptions] = useState<ISelectCountry[]>(options || []);

    const { loading, sendRequest } = useFetch(countriesList);

    const { t } = useTranslation();

    const memoizedParams = useMemo(() => params, [params]);

    const [value, setValue] = useState<string>("");

    const searchQuery = useDebounce(value, 500);

    const filterRealOptions = useCallback(() => {
      if (!searchQuery || searchQuery.length === 0) return realOptions;

      return realOptions.filter(
        (option) =>
          option.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          option.code.toLowerCase().includes(searchQuery.toLowerCase()) ||
          option.ddiCode === searchQuery
      );
    }, [searchQuery, realOptions]);

    const loadRequest = useCallback(
      async (parameters?: any) => {
        const { data, success } = await sendRequest(parameters);
        if (data && success) {
          setOptions(data);
        }
      },
      [sendRequest]
    );

    useEffect(() => {
      if (options) return;
      if (SearchAsYouTypeParams) {
        if (searchQuery !== "" && searchQuery.length > 2) {
          loadRequest(SearchAsYouTypeParams(searchQuery));
        }
      }
    }, [searchQuery, options, SearchAsYouTypeParams, loadRequest]);

    useEffect(() => {
      if (options) return;
      if (!SearchAsYouTypeParams) {
        loadRequest(memoizedParams);
      }
    }, [loadRequest, SearchAsYouTypeParams, memoizedParams, options]);

    return (
      <Controller
        render={({ fieldState: { error }, field }) => {
          const { onChange, value, ref } = field;
          return (
            <Autocomplete
              value={value}
              loading={loading}
              disabled={disable ? true : false}
              noOptionsText={t("Lib4uhub.components.autocomplete.noOptions")}
              renderOption={renderOption}
              isOptionEqualToValue={isOptionEqualToValue}
              filterSelectedOptions={filterSelectedOptions}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={option.name}
                    color="primary"
                    size="small"
                    {...getTagProps({ index })}
                  />
                ))
              }
              filterOptions={memoizedParams ? filterOptions : (x) => x}
              options={
                options
                  ? options.filter((option) =>
                      option.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    )
                  : filterRealOptions()
              }
              getOptionLabel={getOptionLabel}
              onChange={(event: any, newValue) => {
                if (onValueChange) onValueChange(newValue);
                onChange(newValue);
              }}
              clearIcon={clearIcon ? <ClearIcon fontSize="small" /> : null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...otherProps}
                  error={!!error}
                  onChange={(e) => setValue(e.target.value)}
                  inputRef={ref}
                  helperText={
                    error
                      ? t(error?.message || "")
                      : SearchAsYouTypeParams
                      ? t("Lib4uhub.components.autocomplete.hint")
                      : ""
                  }
                  fullWidth
                />
              )}
            />
          );
        }}
        defaultValue={defaultValue ?? null}
        name={name}
        control={control}
      />
    );
  }
);
