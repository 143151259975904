import { Box, IconButton, Stack, Typography } from "@mui/material";
import { memo } from "react";
import DragHandleIcon from "@mui/icons-material/DragHandle";

import { IDashboard } from "../../../models/dashboard";
import DashBoardsEditAndReorderComponents from "./DashBoardsEditAndReorderComponents/DashBoardMenu";
import { DashBoardProvider } from "../../../store/contexts/DashBoardContext";
import ComponentsList from "./DashboardComponent/ComponentsList";
import { usePanel } from "../../../store/contexts/DashBoardPannelContext";
import DashboardMenu from "./DashboardMenu/DashboardMenu";
import { useRole } from "@4uhub/lib4uhub";

interface IDashBoardProps {
  data: IDashboard;
}

const DashBoard: React.FC<IDashBoardProps> = ({ data }) => {
  const { isView } = usePanel();

  const updatePermission = useRole(["Update"]);
  const deletePermission = useRole(["Delete"]);

  return (
    <DashBoardProvider dashboard={data}>
      <Box
        sx={(t) => ({
          width: "100%",
          height: "100%",
          padding: t.spacing(2),
          backgroundColor:
            t.palette.mode === "dark"
              ? t.palette.grey[900]
              : t.palette.grey[200],
          borderRadius: t.shape.borderRadius / 10,
          overflow: "hidden",
        })}
      >
        <Stack gap={2}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            {!isView && updatePermission && (
              <IconButton
                className="myDraggable"
                size="small"
                sx={{
                  cursor: "grab",
                  "&:active": {
                    cursor: "grabbing",
                  },
                }}
              >
                <DragHandleIcon fontSize="small" />
              </IconButton>
            )}

            <Typography>{data.name}</Typography>

            <Stack direction={"row"} gap={2} alignItems={"center"}>
              {!isView && updatePermission && (
                <DashBoardsEditAndReorderComponents />
              )}
              {!isView && deletePermission && <DashboardMenu />}
            </Stack>
          </Stack>
          <ComponentsList width={data.width} />
        </Stack>
      </Box>
    </DashBoardProvider>
  );
};

export default memo(DashBoard);
