import { Stack, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  BackButton,
  Button,
  Form,
  QPFQuickRegisterForm,
} from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

import { TScheduleSchema, scheduleSchema } from "./schema";
import { ICalendar } from "../model";
import Insurance from "./Insurance/Insurance";
import { useScheduleData } from "./useScheduleData";
import FixedData from "./FixedData";

const NewSchedule = () => {
  const { state }: { state?: ICalendar } = useLocation();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { scheduleConsultation, loading } = useScheduleData(() => navigate(-1));

  const methods = useForm<TScheduleSchema>({
    resolver: zodResolver(scheduleSchema),
    defaultValues: state
      ? {
          identifer: state.identifier,
          type: state.tasyScheduleTypeCode.toString(),
        }
      : undefined,
  });

  if (!state) {
    return <Typography>Identificador não encontrado</Typography>;
  }

  return (
    <Stack
      sx={(t) => ({
        [t.breakpoints.up("lg")]: { width: "40%" },
        height: "100%",
      })}
      gap={3}
      alignItems={"start"}
    >
      <BackButton />

      <Typography sx={(t) => t.typography.h5}>
        {t("components.schedule.newSchedule.title")}
      </Typography>

      <Stack
        direction={"row"}
        gap={10}
        rowGap={5}
        flexWrap={"wrap"}
        padding={2}
      >
        {state && <FixedData data={state} />}

        <Stack width={"100%"} gap={5}>
          <Form {...methods}>
            <QPFQuickRegisterForm
              name={"patient"}
              label={t(
                "components.schedule.newSchedule.insurance.individual.label"
              )}
            />
            <Insurance />
          </Form>
        </Stack>

        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          gap={3}
          width={"100%"}
        >
          <Button
            variant="contained"
            onClick={methods.handleSubmit(scheduleConsultation)}
            loading={loading}
          >
            {t("components.schedule.newSchedule.button")}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default NewSchedule;
