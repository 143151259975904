import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

// Schema
import { FormContainer, FormInput, useFetch } from "@4uhub/lib4uhub";
import {
  createExternalProcessType,
  getExternalProcessTypeById,
  updateExternalProcessTypeById,
} from "../../../../../../services/externalProcessType.service";
import {
  externalProcessTypeSchema,
  TExternalProcessTypeForm,
} from "./externalProcessTypeSchema";

const translationPath = "page.settings.crm.externalProcessType.";

const ExternalProcessType = () => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(
    createExternalProcessType
  );

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateExternalProcessTypeById
  );

  const { sendRequest: get, loading: gLoading } = useFetch(
    getExternalProcessTypeById
  );

  const handleUpdate = (v: TExternalProcessTypeForm, id: string) => {
    return update({
      item: v,
      id: id,
    });
  };

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TExternalProcessTypeForm>
      saveFunction={save}
      updateFunction={handleUpdate}
      getFunction={get}
      loading={loading}
      title={t(translationPath + "external_process_type")}
      schema={externalProcessTypeSchema}
      subtitle={t(translationPath + "new_external_process_type")}
      subtitleWatchField={"name"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="name"
              label={t(translationPath + "name")}
              fullWidth
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default ExternalProcessType;
