import { z } from "zod";

const translationPath = "page.register.neighborhoods.errors.";

const citySchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "city" }
);

export const neighborhoodSchema = z.object({
  id: z.string().optional(),
  city: citySchema,
  code: z.string().optional().nullable(),
  name: z.string().nonempty(translationPath + "name"),
  enable: z.boolean(),
});

export type TNeighborhoodForm = z.infer<typeof neighborhoodSchema>;
