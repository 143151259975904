import { Box, Chip, Typography } from "@mui/material";
import { memo } from "react";
import { IWhatsappSectionProps } from "./models";

export const WhatsappSection = memo(
  ({ title, subtitle, chipLabel, children }: IWhatsappSectionProps) => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography variant="subtitle2" fontWeight="bold">
              {title}
            </Typography>
            {chipLabel && chipLabel.length > 0 && (
              <Chip size="small" label={chipLabel} sx={{ fontSize: 12 }} />
            )}
          </Box>
          <Typography
            variant="body2"
            sx={(t) => ({
              color: t.palette.grey[t.palette.mode === "light" ? 600 : 400],
            })}
          >
            {subtitle}
          </Typography>
        </Box>
        {children}
      </Box>
    );
  }
);
