import FolderOff from "@mui/icons-material/FolderOff";
import { Grid, Typography } from "@mui/material";

const NoData = ({ message }: { message: string }) => {
  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        mt={2}
      >
        <Grid item>
          <FolderOff fontSize="large" />
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">{message}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default NoData;
