import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import { ISearchParams, ISearchReturn } from "../models/requests";
import { IUserGet, IUsers, IUsersList } from "../models/users";

const BASE_URL = process.env.REACT_APP_IDENTIY;

const USERS_ROUTE = BASE_URL + "/api/v1/Users/";

const USERS_NOTIFICATION_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/Notification/GetFilterUsers";

export class UsersService {
  create = (item: IUsers) => {
    return GlobalAxios.post(USERS_ROUTE, item);
  };

  getAll = (serchParams?: ISearchParams) => {
    return GlobalAxios.get<ISearchReturn<any>>(USERS_ROUTE, {
      params: serchParams,
    });
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<IUserGet>(USERS_ROUTE + id);
  };

  updateItemById = ({ item, id }: { item: IUsers; id: string }) => {
    return GlobalAxios.put(USERS_ROUTE + id, item);
  };

  deleteItemById = (id: string) => {
    return GlobalAxios.delete(USERS_ROUTE + id);
  };

  list = (params?: any) => {
    return GlobalAxios.get<ISelectType[]>(USERS_ROUTE + "list", {
      params: params,
    });
  };

  listUsers = (params?: any) => {
    return GlobalAxios.get<IUsersList[]>(USERS_ROUTE + "list", {
      params: params,
    });
  };

  listNotificationUsers = (params?: any) => {
    return GlobalAxios.get<IUsersList[]>(USERS_NOTIFICATION_ROUTE, {
      params: params,
    });
  };
}

export default UsersService;
