import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ISettingsGeneral } from "../../models/settings";

interface ISettingsGeneralSlice {
  settings: ISettingsGeneral;
}

const SETTING_GENERAL_INITIAL_STRUCTURE: ISettingsGeneralSlice = {
  settings: {
    apiGeocodingKey: "",
    bankBancosUrl: "",
    bankBaseUrl: "",
    bankCooperativasUrl: "",
    cepCacheTime: 0,
    correiosAddressesUrl: "",
    correiosAuthenticationUrl: "",
    correiosLocationsUrl: "",
    correiosPageSize: 0,
    correiosTokenAccessCode: "",
    correiosTokenPostCard: "",
    correiosTokenUser: "",
    correiosUrl: "",
  },
};

export const slice = createSlice({
  name: "settings-general",
  initialState: SETTING_GENERAL_INITIAL_STRUCTURE,
  reducers: {
    setSettingsGeneral(
      state: ISettingsGeneralSlice,
      action: PayloadAction<ISettingsGeneral>
    ) {
      return { ...state, settings: action.payload };
    },
  },
});

export const settingsGeneralSliceActions = slice.actions;

export default slice.reducer;
