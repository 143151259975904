import { IProductContractClassification } from "../../../../models/contract-product-classification";
import MainApi from "../../../../services/mainApi.service";

const serviceURL =
  process.env.REACT_APP_MAIN + "/api/v1/SalesProductCtrClassification/";

export const translationsPath =
  "page.4uSalesContract.registers.contractProductClassifications.";

export const buildTPath = (field: string) => `${translationsPath}${field}`;

export const contractProductClassificationService =
  new MainApi<IProductContractClassification>(serviceURL);
