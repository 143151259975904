import MainApiService from "../../../../services/mainApi.service";

import { 
    IContractTerminationReason, 
    ICreateContractTerminationReason,
} from "../../../../models/contract-termination-reason";

export const endpoint = process.env.REACT_APP_MAIN + "/api/v1/SalesContractTerminationReason/";

export const contractTerminationService = 
    new MainApiService<IContractTerminationReason>(endpoint);

export const contractTerminationFormService = 
    new MainApiService<ICreateContractTerminationReason>(endpoint);

export const translationPath =
  "page.4uSalesContract.registers.contractTerminationReason.";

export const buildTPath = (field: string): string => `${translationPath}${field}`;
