import {
  Box,
  InputAdornment,
  TextField,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Grid,
  Stack,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import ExtensionIcon from "@mui/icons-material/Extension";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { useTranslation } from "react-i18next";
import { IExtensionsStoreHeader } from "../../models/extensions-store";

const translationPath = "components.extensionsStoreHeader.";

const ExtensionsStoreHeader = ({
  extensionsOption,
  toggleExtensionsOption,
  onSearch,
}: IExtensionsStoreHeader) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      mt={5}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"end"}
    >
      <Grid container gap={matches ? 2 : 0}>
        <Grid item sm={10} xs={12}>
          <Stack
            mb={2}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={1}
          >
            {extensionsOption && (
              <Button
                startIcon={<ArrowBackIosIcon />}
                size="small"
                onClick={toggleExtensionsOption}
              >
                {t(translationPath + "back")}
              </Button>
            )}
            <Typography variant="h5">
              {extensionsOption
                ? t(translationPath + "my_extensions")
                : t(translationPath + "title")}
            </Typography>
          </Stack>
          <TextField
            label={t(translationPath + "search")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
            sx={{ width: "100%", maxWidth: 400 }}
            onChange={(e) => onSearch(e.target.value)}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          display={"flex"}
          justifyContent={"end"}
          alignItems={"end"}
        >
          <Button
            variant="contained"
            startIcon={extensionsOption ? <AddIcon /> : <ExtensionIcon />}
            onClick={toggleExtensionsOption}
          >
            {extensionsOption && t(translationPath + "add_extensions")}
            {!extensionsOption && t(translationPath + "my_extensions")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ExtensionsStoreHeader;
