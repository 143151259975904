import { Autocomplete, TextField } from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useFetch } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

import { ISpeciality } from "../model";
import { ScheduleAppointmentService } from "../Schedule.service";

interface ISpecialityProps {
  value: string | null;
  onChange: (value: string | null) => void;
}

const Speciality: React.FC<ISpecialityProps> = ({ value, onChange }) => {
  const [inputValue, setInputValue] = useState("");

  const [specialitys, setSpecialtys] = useState<ISpeciality[]>([]);

  const { t } = useTranslation();

  const { sendRequest } = useFetch(ScheduleAppointmentService.getSpecialitys);

  const fetch = useCallback(async () => {
    const { data, success } = await sendRequest(null);

    if (success && data) {
      setSpecialtys(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const valuee = useMemo(
    () => specialitys.find((s) => s.integrationId === value),
    [value, specialitys]
  );

  return (
    <Autocomplete
      fullWidth
      value={valuee ?? null}
      onChange={(event: any, newValue) => {
        onChange(newValue?.integrationId || null);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      getOptionLabel={({ name }) => name}
      options={specialitys}
      size="small"
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("components.schedule.menu.specialitys")}
        />
      )}
    />
  );
};

export default memo(Speciality);
