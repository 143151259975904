import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import { ILegalEntityList, ILegalEntityPost } from "../models/legalEntitiy";
import { ISearchParams, ISearchReturn } from "../models/requests";
import { TLegalEntityForm } from "../pages/registers/legalEntity/LegalEntityForm/LegalEntitySchema";

const BASE_URL = process.env.REACT_APP_MAIN;

const LEGAL_ENTITY_ROUTE = BASE_URL + "/api/v1/LegalEntity/";

export class LegalEntityService {
  create = (item: ILegalEntityPost) => {
    return GlobalAxios.post(LEGAL_ENTITY_ROUTE, item);
  };

  getAll = (serchParams?: ISearchParams) => {
    return GlobalAxios.get<ISearchReturn<ILegalEntityList>>(
      LEGAL_ENTITY_ROUTE,
      {
        params: serchParams,
      }
    );
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<TLegalEntityForm>(LEGAL_ENTITY_ROUTE + id);
  };

  updateItemById = ({ item, id }: { item: ILegalEntityPost; id: string }) => {
    return GlobalAxios.put(LEGAL_ENTITY_ROUTE + id, item);
  };

  deleteItemById = (id: string) => {
    return GlobalAxios.delete(LEGAL_ENTITY_ROUTE + id);
  };

  list = (params?: any) => {
    return GlobalAxios.get<ISelectType[]>(LEGAL_ENTITY_ROUTE + "list", {
      params,
    });
  };
}

export default LegalEntityService;
