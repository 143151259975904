import { ThemeOptions } from "@mui/material";
import { PaletteColor } from "./models/theme";

const primaryColor: PaletteColor = {
  main: "#2d398f",
  light: "#5760A5",
  dark: "#1F2764",
  contrastText: "#FFFFFF",
};

const themeOptions = (mode: "light" | "dark"): ThemeOptions => {
  return {
    palette: {
      mode: mode,
      primary: primaryColor,
      contrastThreshold: 4.5,
      background: {
        default: mode === "dark" ? "#121212" : "#FFFFFF",
      },
    },
    spacing: 5,
    shape: {
      borderRadius: 8,
    },
    typography: {
      fontFamily: ["Soleil", "sans-serif"].join(","),
      button: {
        textTransform: "none",
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
              backgroundColor: mode === "dark" ? "#161616" : "#F2F2F6",
              borderRadius: 8,
              // scrollbarWidth: 'thin'
              width: ".5rem",
            },
            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
              borderRadius: 8,
              backgroundColor: mode === "dark" ? "#5e5e5e" : "#dbdbdb",
            },
            "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
              backgroundColor: mode === "dark" ? "#161616" : "#F2F2F6",
            },
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h1: "h2",
            h2: "h2",
            h3: "h2",
            h4: "h2",
            h5: "h2",
            h6: "h2",
            subtitle1: "span",
            subtitle2: "span",
            body1: "span",
            body2: "span",
          },
        },
      },
    },
  };
};

export default themeOptions;
