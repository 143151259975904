import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";

const RELATIONSHIP_TYPE_ROUTE =
  process.env.REACT_APP_4UBUS + "/api/v1/SetRelationshipTypeSync/";

const RELATIONSHIP_ROUTE =
  process.env.REACT_APP_4UBUS + "/api/v1/EstablishmentSetRelationshipSync/";

export const syncRelationshipTypeNow = () => {
  return GlobalAxios.post(RELATIONSHIP_TYPE_ROUTE + "Sync/Now");
};

export const getIntegratedRelationshipType = () => {
  return GlobalAxios.get<ISelectType>(RELATIONSHIP_TYPE_ROUTE + "list");
};

export const syncRelationshipNow = () => {
  return GlobalAxios.post(RELATIONSHIP_ROUTE + "Sync/Now");
};

export const getIntegratedRelationship = () => {
  return GlobalAxios.get<ISelectType>(RELATIONSHIP_ROUTE + "List");
};
