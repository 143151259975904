import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { Table } from "@4uhub/lib4uhub";

import MainApiService from "../../../../services/mainApi.service";
import { IMyApplications } from "../../../../models/my_applications";
import { Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";

const MY_APPS_ROUTE =
  process.env.REACT_APP_MAIN +
  "/api/v1/SiteApp/GetAllMySiteAppsWithPagination/";
const myAppsService = new MainApiService<IMyApplications>(MY_APPS_ROUTE);

const translationPath = "page.4uSiteApp.applications.myApplications.";

const MyApplicationsPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const columns: GridColDef<IMyApplications>[] = [
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
    },
    {
      field: "siteAppType",
      headerName: t(translationPath + "type") || "Type",
      renderCell: (params) => {
        return params.value ? params.value.name : "-";
      },
    },
    {
      field: "siteAppStatus",
      headerName: t(translationPath + "status") || "Status",
      maxWidth: 150,
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            color={params.value ? "success" : "error"}
            label={
              params.value
                ? t(translationPath + "active")
                : t(translationPath + "inactive")
            }
          />
        );
      },
    },
  ];

  const handleRowClick = (params: GridRowParams<IMyApplications>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<IMyApplications>
      showDefaultMacroFilters={false}
      service={myAppsService}
      columns={columns}
      title={t(translationPath + "my_applications")}
      searchInput
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      onRowClick={handleRowClick}
      showButton={false}
      showDeleteButton={false}
      defaultActions={false}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for an application"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default MyApplicationsPage;
