import { useCallback } from "react";

import Fields from "./Fields/Fields";
import { FormContainer, FormTabs, useFetch } from "@4uhub/lib4uhub";

import {
  contractProductSchema,
  TContractProductForm,
} from "./contract-product.schema";
import {
  createContractProduct,
  findContractProductById,
  updateContractProduct,
} from "../../../../../../services/salesProductCtr.service";
import { IContractProductPayload } from "../../../../../../models/contract-product";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ContractProductHistoriesPage } from "../../../ContractProductHistories/ContractProductHistoriesPage";
import { SalesContractProvider } from "../../../../../../store/contexts/SalesContractContext";
import { ModalForm } from "../../../../../../components/ModalForm/ModalForm";
import { RenderForms } from "../RenderForms/RenderForms";
import { SalesProductCtrPriceListPage } from "../../../SalesProductCtrPriceList/SalesProductCtrPriceListPage";

const translationsPath = "page.4uSalesContract.registers.contractProducts.";
const ContractProduct = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { sendRequest: create, loading: isSaving } = useFetch(
    createContractProduct
  );
  const { sendRequest: update, loading: isUpdating } = useFetch(
    updateContractProduct
  );
  const { sendRequest: findById, loading: isFetching } = useFetch(
    findContractProductById
  );

  const handleFormValues = ({
    salesContractType,
    salesGrantors,
    salesProductCtrClassification,
    integrationId,
    situation,
    ...rest
  }: TContractProductForm) => {
    const payload: IContractProductPayload = {
      ...rest,
      salesContractTypeId: salesContractType?.id,
      salesProductCtrClassificationId: salesProductCtrClassification?.id,
      salesGrantorIds: salesGrantors?.map(({ id }) => id),
      situationId: situation?.id as string,
      integrationId: integrationId!,
    };

    return payload;
  };

  const getHandler = useCallback(
    async (id: string) => {
      const { data, success } = await findById(id);

      if (data && success) {
        const response: any = {
          ...data,
          salesContractType: (data as any)?.salecContractType,
        };

        return { success: true, data: response };
      }

      return { data, success };
    },
    [findById]
  );

  const createHandler = (values: TContractProductForm) =>
    create(handleFormValues(values));

  const updateHandler = (values: TContractProductForm, id: string) =>
    update({
      id,
      item: handleFormValues({ ...values, id } as any),
    });

  const redirectHandle = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const isLoading = isSaving || isUpdating || isFetching;

  return (
    <SalesContractProvider>
      <ModalForm>
        <RenderForms />
      </ModalForm>
      <FormContainer<TContractProductForm>
        title={t(translationsPath + "products.title")}
        subtitle={t(translationsPath + "products.new_product")}
        subtitleWatchField={"name"}
        redirectOnSave={false}
        getFunction={getHandler}
        saveFunction={createHandler}
        updateFunction={updateHandler}
        onSuccess={redirectHandle}
        loading={isLoading}
        schema={contractProductSchema}
        fields={(type) => (
          <FormTabs
            tabs={[
              {
                label: t(translationsPath + "tabs.products"),
                component: <Fields type={type} />,
              },
              {
                label: t(translationsPath + "tabs.history"),
                component: <ContractProductHistoriesPage />,
                disabled: !id,
              },
              {
                label: t(translationsPath + "tabs.priceList"),
                component: <SalesProductCtrPriceListPage />,
              },
            ]}
          />
        )}
      />
    </SalesContractProvider>
  );
};

export default ContractProduct;
