import axios, { InternalAxiosRequestConfig } from "axios";
import { clearEstablishmentCode } from "@4uhub/lib4uhub";
import { tokenService } from "../services/token.service";
import { removePermissions, removeUserName } from "../services/user.service";
import { getEIFromUrl, getEstToken } from "../services/establishment.service";
import { authService } from "../services/auth.service";
import { getDomain } from "../services/domain.service";
import i18n from "../i18n";

const softwareCode = 1;

export const GlobalAxios = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    client_id: process.env.CLIENT_ID,
  },
  data: { softwareCode },
});

GlobalAxios.interceptors.request.use((config) => {
  config.headers.Domain = getDomain();

  // Add extension code do calendar routes
  if(config.url?.startsWith(process.env.REACT_APP_CALENDAR!)){
    config.headers.ExtensionCode = 25
  }

  return config;
});

//Adicionando idioma atual nas requisições
GlobalAxios.interceptors.request.use((config) => {
  config.headers["Accept-Language"] = i18n.language;

  return config;
});

GlobalAxios.interceptors.request.use(function (config) {
  let token = getEstToken();

  if (token) {
    config.headers.Authorization = "Bearer " + token.token;
  }

  return config;
});

export const InterceptorRefresh = async (
  config: InternalAxiosRequestConfig<any>
) => {
  if (!tokenService.hasRefreshTokens()) {
    return config;
  }

  const establishmentcode = getEIFromUrl();

  let token = tokenService
    .getAccessTokens()
    .find((t) => t.code === establishmentcode);

  const tokens = await refreshToken(token?.establishmentId);

  if (tokens && establishmentcode !== undefined) {
    tokenService.saveTokens(
      tokens.access_token,
      tokens.refresh_token,
      establishmentcode,
      token?.establishmentId
    );
    config.headers.Authorization = "Bearer " + tokens.access_token;
  }

  return config;
};

export const removeUserData = () => {
  tokenService.removeAllTokens();
  removePermissions();
  clearEstablishmentCode();
  removeUserName();
};

const refreshToken = async (establishmentId?: string) => {
  const response = await authService.refresh_token(establishmentId);
  return response.data;
};
