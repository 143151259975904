import { Grid } from "@mui/material";
import { eachHourOfInterval, endOfDay } from "date-fns";
import { memo, useMemo } from "react";

import Hour from "./Hour";
import Header from "./Header/Header";
import AvaiableTime from "../AvaiableTime";
import Event from "../Event/Event";
import ActualHour from "./ActualHour";
import { EcalendarStatusCode, ICalendar } from "../../model";
import { IGroupedEvents } from "../models";

interface DayProps {
  date: Date;
  index: number;
  containerHeigth: number;
  schedules: IGroupedEvents<ICalendar>[];
  showDay?: boolean;
  sourceTitle?: string;
  sourceAvatar?: string;
  sourceColor?: string;
}

const Day: React.FC<DayProps> = ({
  date,
  index,
  containerHeigth,
  showDay,
  sourceAvatar,
  sourceColor,
  schedules,
  sourceTitle,
}) => {
  const hours = useMemo(
    () =>
      eachHourOfInterval({
        start: date,
        end: endOfDay(date),
      }),
    [date]
  );

  if (containerHeigth === 1) {
    return null;
  }

  return (
    <Grid item xs={1} minWidth={index === 0 ? 263 : 220}>
      <Header
        date={date}
        showZHour={index === 0}
        showDay={showDay}
        sourceAvatar={sourceAvatar}
        sourceColor={sourceColor}
        sourceTitle={sourceTitle}
      />

      <Grid container position={"relative"}>
        {schedules.map((g) =>
          g.times.map((event, i) => {
            if (event.calendarStatusCode === EcalendarStatusCode.FREE) {
              return (
                <AvaiableTime
                  eventGroup={g}
                  date={date}
                  dayIndex={index}
                  key={event.identifier}
                  containerHeigth={containerHeigth}
                  data={event}
                  index={i}
                />
              );
            }

            return (
              <Event
                data={event}
                dayIndex={index}
                date={date}
                index={i}
                eventGroup={g}
                containerHeigth={containerHeigth}
                key={event.identifier}
              />
            );
          })
        )}

        <ActualHour
          containerHeigth={containerHeigth}
          date={date}
          showDot={showDay}
          dayIndex={index}
        />

        {hours.map((h, i) => {
          const key = h.getTime()
          return (
            <Hour
              hour={h}
              showHourLabel={i > 0}
              showHour={index === 0}
              key={key}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};

export default memo(Day);
