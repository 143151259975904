import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import MainApiService from "../../../../services/mainApi.service";
import { useCallback } from "react";
import { TCountryForm, countrySchema } from "./CountrySchema";
import {
  FormContainer,
  FormInput,
  Information,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";

const COUNTRY_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Country/";

const genericService = new MainApiService<TCountryForm>(COUNTRY_ROUTE);

const Country = () => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(
    genericService.create
  );
  const { sendRequest: update, loading: uLoading } = useFetch(
    genericService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    genericService.getItemById
  );

  const updateHandler = (v: TCountryForm, id: string) =>
    update({ item: v, id: id });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TCountryForm>> => {
      const { data, success } = await get(id);
      if (data) {
        const getData: TCountryForm = {
          ...data,
          ddiCode: data.ddiCode ?? "",
          phoneMask: data.phoneMask ?? "",
        };

        return { data: getData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TCountryForm>
      saveFunction={save}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t("page.register.countries.country")}
      schema={countrySchema}
      subtitle={t("page.register.countries.new_country")}
      subtitleWatchField={"name"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            <SwitchInput
              name="enable"
              label={t("page.register.countries.enable") || "Enable"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="name"
              fullWidth
              label={t("page.register.countries.name") || "Name"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="code"
              fullWidth
              label={t("page.register.countries.code") || "Code"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="ddiCode"
              fullWidth
              label={t("page.register.countries.ddi_code") || "DDI Code"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Information info={t("page.register.countries.phone_mask_help")}>
              <FormInput
                size="small"
                name="phoneMask"
                fullWidth
                label={t("page.register.countries.phone_mask") || "Phone Mask"}
              />
            </Information>
          </Grid>
        </Grid>
      )}
    />
  );
};

export default Country;
