import { useCallback, useEffect, useState } from "react";
import {
  IProfile,
  ISelectType,
} from "@4uhub/lib4uhub";

const useFlatMap = ({ data }: { data: any[] }) => {
  const [filteredOptions, setFilteredOptions] = useState<ISelectType[]>([]);

  const loadFilteredOptions = useCallback(() => {
    if (data) {
      const filteredProfiles = data.flatMap(
        (profiles: IProfile) => profiles.roles || []
      );
      const filterObjects: ISelectType[] = filteredProfiles.reduce(
        (unique: ISelectType[], item: ISelectType) => {
          if (!unique.some((uniqueItem) => uniqueItem.id === item.id)) {
            unique.push(item);
          }
          return unique;
        },
        []
      );
      setFilteredOptions(filterObjects);
    }
  }, [data]);

  useEffect(() => {
    loadFilteredOptions();
  }, [loadFilteredOptions]);

  return filteredOptions;
};

export default useFlatMap;
