import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import LanguagesService from "../../../../services/languages.service";
import { useWatch } from "react-hook-form";
import { TEstablishmentForm } from "./EstablishmentSchema";
import {
  AccessRegister,
  AutoCompleteMulti,
  useFormContext,
} from "@4uhub/lib4uhub";

const languageService = new LanguagesService();

const EstablishmentLanguages = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<TEstablishmentForm>();

  const languages = useWatch({
    name: "languages",
    control,
  });

  return (
    <Grid item xs={12} sm={6}>
      <AccessRegister
        redirectTo="dashboard/globals/languages"
        domainName="language"
        inputValue={
          languages
            ? languages.map((lang) => ({ id: lang.id, name: lang.name }))
            : []
        }
        tooltip={t("page.register.establishments.languages_tooltip")}
      >
        <AutoCompleteMulti
          size="small"
          label={t("page.register.establishments.languages") || "Languages"}
          name={"languages"}
          request={languageService.all}
        />
      </AccessRegister>
    </Grid>
  );
};

export default EstablishmentLanguages;
