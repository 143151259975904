import { stringToColor } from "@4uhub/lib4uhub";
import { Avatar, Stack, Tooltip, Typography } from "@mui/material";

interface ISourceTitle {
  sourceTitle: string;
  sourceColor?: string;
  sourceAvatar?: string;
}

const MAX_LINES = 1;

const SourceTitle: React.FC<ISourceTitle> = ({
  sourceTitle,
  sourceAvatar,
  sourceColor,
}) => {
  return (
    <Stack alignItems={"flex-end"} p={1} flexDirection={"row"} gap={1}>
      <Tooltip title={sourceTitle}>
        <Typography
          alignSelf={"flex-end"}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: MAX_LINES,
            WebkitBoxOrient: "vertical",
          }}
        >
          {sourceTitle}
        </Typography>
      </Tooltip>
      {sourceAvatar && (
        <Avatar
          sx={{
            bgcolor: sourceColor || stringToColor(sourceTitle),
            width: 30,
            height: 30,
          }}
          alt={sourceTitle}
          src={sourceAvatar}
        />
      )}
    </Stack>
  );
};

export default SourceTitle;
