import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import { INewQuestionAnswer } from "../models/faqs";
import { TQuestionAnswerForm } from "../pages/4uSiteApp/Sac/Faq/QuestionsAnswers/QuestionAnswerForm/QuestionAnswerSchema";

const QUESTION_ANSWER_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/FaqQuestion/";

export class QuestionAnswerService {
  create = (item: INewQuestionAnswer) => {
    return GlobalAxios.post(QUESTION_ANSWER_ROUTE, item);
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<TQuestionAnswerForm>(QUESTION_ANSWER_ROUTE + id);
  };

  updateItemById = ({ item, id }: { item: INewQuestionAnswer; id: string }) => {
    return GlobalAxios.put(QUESTION_ANSWER_ROUTE + id, item);
  };

  list = (id: string) => {
    return GlobalAxios.get<ISelectType>(QUESTION_ANSWER_ROUTE + id + "/list");
  };
}

export default QuestionAnswerService;
