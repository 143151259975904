import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

interface ITicketServiceMessageDataProps {
  label: string;
  value: string;
}

const TicketServiceMessageData = ({
  label,
  value,
}: ITicketServiceMessageDataProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack
      sx={{
        minHeight: 40,
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        gap: matches ? 1 : 0,
      }}
    >
      <Typography
        sx={(theme) => ({
          color:
            theme.palette.mode === "light"
              ? theme.palette.grey[600]
              : theme.palette.grey[400],
        })}
        lineHeight={1}
      >
        {label}:
      </Typography>
      <Typography sx={{ wordBreak: "break-all" }}>{value}</Typography>
    </Stack>
  );
};

export default TicketServiceMessageData;
