import { z } from "zod";

const translationPath = "chatBot.page.service.protocolGenerateRule.errors.";

const fieldTypeSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional(),
  },
  { invalid_type_error: "O tipo é obrigatório" }
);

const positionSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional(),
  },
  { invalid_type_error: "O tipo é obrigatório" }
);

const protocolRuleDetailSchema = z
  .object({
    position: positionSchema.optional(),
    ticketProtocolRuleFieldType: fieldTypeSchema.optional(),
    fixedCode: z.string().nullable(),
    mask: z.string().nullable(),
  })
  .superRefine((val, ctx) => {
    if (
      (val.ticketProtocolRuleFieldType?.code === "5" && !val.fixedCode) ||
      val.fixedCode?.length === 0
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["fixedCode"],
        message: translationPath + "fixed_code",
      });
    }
  });

export const protocolGenerateSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, translationPath + "name"),
  enable: z.boolean(),
  initialDate: z.date({
    invalid_type_error: translationPath + "initial_date",
  }),
  endDate: z.date({
    invalid_type_error: translationPath + "end_date",
  }),
  ticketProtocolRuleDetails: z
    .array(protocolRuleDetailSchema)
    .refine(
      (val) => val.length !== 0,
      translationPath + "protocol_rule_details"
    ),
});
export type TProtocolGenerateForm = z.infer<typeof protocolGenerateSchema>;
