import { DomainProvider } from "@4uhub/lib4uhub";
import { RouteObject } from "react-router-dom";

import IpmTasyInternal from "../pages/4uBus/Logs/IpmTasyInternal/IpmTasyInternal";
import IpmTasy from "../pages/4uBus/Logs/LogTasyIpm/IpmTasy";
import TasyIpm from "../pages/4uBus/Logs/LogTasyIpm/TasyIpm";
import TasyCalendarsLogsPage from "../pages/4uBus/Logs/TasyCalendars/TasyCalendarsLogsPage";
import TasyIpmInternal from "../pages/4uBus/Logs/TasyIpmInternal/TasyIpmInternal";
import AccessToken from "../pages/4uBus/Register/AccessToken/AccessTokenForm/AccessToken";
import AccessTokenPage from "../pages/4uBus/Register/AccessToken/AccessTokenPage";
import ProfileTasyBus from "../pages/4uBus/Register/ProfileTasyBus/ProfileTasyBusForm/ProfileTasyBus";
import ProfileTasyBusPage from "../pages/4uBus/Register/ProfileTasyBus/ProfileTasyBusPage";
import RestrictionRule from "../pages/4uBus/Register/ProfileTasyBus/RestrictionRule/RestrictionRuleForm/RestrictionRule";
import RelationShipForm from "../pages/4uBus/Relationships/RelationShipForm/RelationShipForm";
import RelationShipPage from "../pages/4uBus/Relationships/RelationShipPage";
import Schedule from "../pages/4uBus/Restrictions/Schedules/Schedule/Schedule";
import SchedulesPage from "../pages/4uBus/Restrictions/Schedules/SchedulesPage";
import RobotsExecutionsPage from "../pages/4uBus/Robots/List/Executions/RobotsExecutionsPage";
import RobotsListPage from "../pages/4uBus/Robots/List/RobotsListPage";
import WelcomePage from "../pages/welcomePage";

const bus: RouteObject[] = [
  {
    index: true,
    element: <WelcomePage />,
  },
  {
    path: "register/accessTokens",
    element: (
      <DomainProvider value={{ domainName: "4ubususers" }}>
        <AccessTokenPage />
      </DomainProvider>
    ),
  },
  {
    path: "register/accessTokens/new",
    element: (
      <DomainProvider value={{ domainName: "4ubususers" }} actions={["Create"]}>
        <AccessToken />
      </DomainProvider>
    ),
  },
  {
    path: "register/accessTokens/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "4ubususers" }}>
        <AccessToken />
      </DomainProvider>
    ),
  },
  {
    path: "register/profileTasy",
    element: (
      <DomainProvider value={{ domainName: "restrictionruleprofile" }}>
        <ProfileTasyBusPage />
      </DomainProvider>
    ),
  },
  {
    path: "register/profileTasy/new",
    element: (
      <DomainProvider
        value={{ domainName: "restrictionruleprofile" }}
        actions={["Create"]}
      >
        <ProfileTasyBus />
      </DomainProvider>
    ),
  },
  {
    path: "register/profileTasy/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "restrictionruleprofile" }}>
        <ProfileTasyBus />
      </DomainProvider>
    ),
  },
  {
    path: "register/restrictionRule/:profileTasyId/:extensionCode/new",
    element: (
      <DomainProvider value={{ domainName: "restrictionruleprofile" }}>
        <RestrictionRule />
      </DomainProvider>
    ),
  },
  {
    path: "register/restrictionRule/:profileTasyId/:extensionCode/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "restrictionruleprofile" }}>
        <RestrictionRule />
      </DomainProvider>
    ),
  },
  {
    path: "setRelationships/:name/:extension",
    element: (
      <DomainProvider value={{ domainName: "establishmentsetrelationship" }}>
        <RelationShipPage />
      </DomainProvider>
    ),
  },
  {
    path: "setRelationships/:name/:extension/new",
    element: (
      <DomainProvider value={{ domainName: "establishmentsetrelationship" }}>
        <RelationShipForm />
      </DomainProvider>
    ),
  },
  {
    path: "setRelationships/:name/:extension/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "establishmentsetrelationship" }}>
        <RelationShipForm />
      </DomainProvider>
    ),
  },
  {
    path: "restriction/rule/:extension",
    element: (
      <DomainProvider value={{ domainName: "restrictionrule" }}>
        <SchedulesPage />
      </DomainProvider>
    ),
  },
  {
    path: "restriction/rule/:extension/new",
    element: (
      <DomainProvider
        value={{ domainName: "restrictionrule" }}
        actions={["Create"]}
      >
        <Schedule />
      </DomainProvider>
    ),
  },
  {
    path: "restriction/rule/:extension/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "restrictionrule" }}>
        <Schedule />
      </DomainProvider>
    ),
  },
  {
    path: "logs/ipmxtasyinternal",
    element: (
      <DomainProvider value={{ domainName: "4ubuslogsipm" }}>
        <IpmTasyInternal />
      </DomainProvider>
    ),
  },
  {
    path: "logs/tasyxipminternal",
    element: (
      <DomainProvider value={{ domainName: "4ubuslogsipm" }}>
        <TasyIpmInternal />
      </DomainProvider>
    ),
  },
  {
    path: "logs/tasyxipm",
    element: (
      <DomainProvider value={{ domainName: "tasyipm" }}>
        <TasyIpm />
      </DomainProvider>
    ),
  },
  {
    path: "logs/ipmxtasy",
    element: (
      <DomainProvider value={{ domainName: "ipmtasy" }}>
        <IpmTasy />
      </DomainProvider>
    ),
  },

  {
    path: "logs/tasyCalendar",
    element: (
      <DomainProvider value={{ domainName: "forubuscchedctrllogs" }}>
        <TasyCalendarsLogsPage />
      </DomainProvider>
    ),
  },

  {
    path: "robots/list",
    element: (
      <DomainProvider value={{ domainName: "robot" }}>
        <RobotsListPage />
      </DomainProvider>
    ),
  },
  {
    path: "robots/list/robot/:id",
    element: (
      <DomainProvider value={{ domainName: "robot" }}>
        <RobotsExecutionsPage />
      </DomainProvider>
    ),
  },
];

export default bus;
