import { GlobalAxios } from "../axios/axios-config";
import { ISearchParams, ISearchReturn } from "../models/requests";
import { IDashBoardPanel } from "../models/dashboardpanel";

const BASE_URL = process.env.REACT_APP_MAIN;

const DASH_BOARD_PANEL_ROUTE = BASE_URL + "/api/v1/DashBoardPanel/";

const GET_DASHBOARD_VIEW_ID_ROUTE =
  BASE_URL + "/api/v1/DashboardPanel/GetViewById";

export interface IUpdateDashBoardRequest {
  dashboardEstablishmentId: string;
  height: string;
  width: string;
  xPosition: string;
  yPosition: string;
}

interface IUpdateLayoutRequestParams {
  id: string;
  updateDashboardsRequest: IUpdateDashBoardRequest[];
}

export class DashBoardPanelService {
  create = (name: string) => {
    return GlobalAxios.post(DASH_BOARD_PANEL_ROUTE, { name });
  };

  getAll = (serchParams?: ISearchParams) => {
    return GlobalAxios.get<ISearchReturn<IDashBoardPanel[]>>(
      DASH_BOARD_PANEL_ROUTE,
      {
        params: serchParams,
      }
    );
  };

  getById = (id: string) => {
    return GlobalAxios.get<IDashBoardPanel>(DASH_BOARD_PANEL_ROUTE + id);
  };

  getViewById = (id: string) => {
    return GlobalAxios.get<IDashBoardPanel>(
      GET_DASHBOARD_VIEW_ID_ROUTE + "/" + id
    );
  };

  update = ({ id, item }: { item: { name: string }; id: string }) => {
    return GlobalAxios.put(DASH_BOARD_PANEL_ROUTE + id, {
      id,
      name: item.name,
    });
  };

  updateLayout = (params: IUpdateLayoutRequestParams) => {
    return GlobalAxios.put(
      DASH_BOARD_PANEL_ROUTE + params.id + "/UpdateDashboards",
      params
    );
  };
}

export default DashBoardPanelService;
