import { AutoComplete, ISelectType, useFormContext } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TProtocolGenerateForm } from "./ProtocolGenerateSchema";
import { useEffect } from "react";

const translationPath = "chatBot.page.service.protocolGenerateRule.";

const ProtocolRuleFieldType = ({
  fieldIndex,
  ticketProtocolRuleFieldList,
  canShowFixedCode,
  onLoadFieldType,
}: {
  fieldIndex: number;
  ticketProtocolRuleFieldList: ISelectType[];
  canShowFixedCode: boolean;
  onLoadFieldType: (type: string) => void;
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext<TProtocolGenerateForm>();

  const fieldType = useWatch({
    name: `ticketProtocolRuleDetails.${fieldIndex}.ticketProtocolRuleFieldType`,
    control,
  });

  useEffect(() => {
    if (fieldType) {
      if (fieldType.code) onLoadFieldType(fieldType.code);
    }
  }, [onLoadFieldType, fieldType]);

  return (
    <Grid item xs={12} sm={canShowFixedCode ? 3 : 6}>
      <AutoComplete
        getOptionLabel={(option) => {
          return option.name;
        }}
        size="small"
        label={t(translationPath + "field_type")}
        name={`ticketProtocolRuleDetails.${fieldIndex}.ticketProtocolRuleFieldType`}
        options={ticketProtocolRuleFieldList}
      />
    </Grid>
  );
};

export default ProtocolRuleFieldType;
