import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { stringToColor, useFetch } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

import { IGroupExam } from "../model";
import { ScheduleAppointmentService } from "../Schedule.service";
import SelectAll from "./SelectAll";

interface IExamProps {
  value: string[];
  onChange: (value: string[]) => void;
}

const Exam: React.FC<IExamProps> = ({ onChange, value }) => {
  const [exams, setExams] = useState<IGroupExam[]>([]);

  const [selectAll, setSelectAll] = useState<boolean>(false);

  const { sendRequest } = useFetch(ScheduleAppointmentService.getExamGroups);

  const { t } = useTranslation();

  const fetch = useCallback(async () => {
    const { data, success } = await sendRequest(null);

    if (success && data) {
      setExams(data);
    }
  }, [sendRequest]);

  const handleToggleSelectAll = () => {
    setSelectAll((prev) => {
      if (!prev) onChange(exams.map((n) => String(n.groupingIdentifier)));
      else onChange([]);

      return !prev;
    });
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  const valuee = useMemo(
    () => exams.filter((e) => value.includes(String(e.groupingIdentifier))),
    [exams, value]
  );

  return (
    <Autocomplete
      multiple
      value={valuee}
      onChange={(event: any, newValue) => {
        onChange(newValue.map((n) => String(n.groupingIdentifier)));
      }}
      options={exams}
      fullWidth
      size="small"
      getOptionLabel={(option) => option.groupingName}
      filterSelectedOptions
      disableClearable
      renderInput={(params) => (
        <TextField {...params} label={t("components.schedule.menu.exams")} />
      )}
      PaperComponent={(paperProps) => (
        <SelectAll
          {...paperProps}
          selected={selectAll}
          toggleAll={handleToggleSelectAll}
        />
      )}
      renderTags={(value: readonly IGroupExam[], getTagProps) =>
        value.map((option: IGroupExam, index: number) => {
          const color = stringToColor(option.groupingName);

          return (
            <Chip
              variant="outlined"
              size="small"
              label={option.groupingName}
              sx={(t) => ({
                background: color,
                color: t.palette.getContrastText(color),
              })}
              {...getTagProps({ index })}
            />
          );
        })
      }
    />
  );
};

export default memo(Exam);
