import { useCallback, useMemo, useState } from "react";
import { useFetch } from "@4uhub/lib4uhub";

import { ScheduleAppointmentService } from "../Schedule.service";
import { TScheduleSchema } from "./schema";

export const useScheduleData = (onSuccess?: () => void) => {
  const [loading, setLoading] = useState(false);


  const { sendRequest: SchedConsultation, error: consultationError } = useFetch(
    ScheduleAppointmentService.calendarConsultationScheduling
  );

  const { sendRequest: SchedExam, error: examError } = useFetch(
    ScheduleAppointmentService.calendarExamScheduling
  );

  const scheduleConsultation = useCallback(
    async (v: TScheduleSchema) => {
      setLoading(true);
      const { success } = await (v.type === "3"
        ? SchedConsultation
        : SchedExam)({
        identifier: v.identifer,
        insuranceCode: v.healthInsurance.id,
        pacientIndividualIdentifier: v.patient.identifier,
      });

      if (success) {
        onSuccess && onSuccess();
      }

      setLoading(false);
    },
    [SchedConsultation, SchedExam,  onSuccess]
  );

  const error = useMemo(
    () => consultationError || examError,
    [consultationError, examError]
  );

  return {
    scheduleConsultation,
    loading,
    error,
  };
};
