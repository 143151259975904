import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { FormContainer, Treturn, useFetch } from "@4uhub/lib4uhub";
import Fields from "./Fields";

import {
  createPrescriptionGenerationRule,
  getPrescriptionGenerationRuleById,
  updatePrescriptionGenerationRuleById,
} from "../../../../../../../services/prescriptionGenerationRule.service";
import { ruleSchema, TRuleForm } from "./ruleSchema";

const translationPath = "page.settings.4uBus.externalExamPrescription.rules.";

const Rule = () => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(
    createPrescriptionGenerationRule
  );
  const { sendRequest: update, loading: uLoading } = useFetch(
    updatePrescriptionGenerationRuleById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    getPrescriptionGenerationRuleById
  );

  const updateHandler = (v: TRuleForm, id: string) =>
    update({
      item: {
        ...v,
        initialDate: v.initialDate.toISOString(),
        endDate: v.endDate.toISOString(),
        sectorCode: v.sectorCode ? v.sectorCode : undefined,
        patientInsuranceCode:
          v.patientInsuranceCode !== null &&
          v.patientInsuranceCode !== undefined
            ? v.patientInsuranceCode
            : "0",
        execPhysicianCode:
          v.execPhysicianCode !== null && v.execPhysicianCode !== undefined
            ? v.execPhysicianCode
            : undefined,
      },
      id: id,
    });

  const saveHandler = (v: TRuleForm) =>
    save({
      ...v,
      initialDate: v.initialDate.toISOString(),
      endDate: v.endDate.toISOString(),
      sectorCode: v.sectorCode ? v.sectorCode : undefined,
      patientInsuranceCode:
        v.patientInsuranceCode !== null && v.patientInsuranceCode !== undefined
          ? v.patientInsuranceCode
          : "0",
      execPhysicianCode:
        v.execPhysicianCode !== null && v.execPhysicianCode !== undefined
          ? v.execPhysicianCode
          : undefined,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TRuleForm>> => {
      const { data, success } = await get(id);

      if (data) {
        const newData: TRuleForm = {
          ...data,
          identifier: data.identifier ? data.identifier.toString() : "",
          initialDate: new Date(data.initialDate),
          endDate: new Date(data.endDate),
          patientInsuranceCode: data.patientInsuranceCode
            ? data.patientInsuranceCode.toString()
            : "",
        };
        return { data: newData, success };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TRuleForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "rule")}
      schema={ruleSchema}
      subtitle={t(translationPath + "new_rule")}
      subtitleWatchField={"medicalProcedureTypeCode"}
      fields={() => <Fields />}
    />
  );
};

export default Rule;
