import { RefObject, useEffect, useState } from "react";

interface ISize {
  width: number;
  height: number;
  clientHeight: number
}

const useElementSize = <T extends HTMLElement>(elementRef: RefObject<T>) => {
  const [size, setSize] = useState<ISize>({ height: 1, width: 1,clientHeight:1 });

  useEffect(() => {
    if (!elementRef?.current) return;
    const resizeObserver = new ResizeObserver((entrys) => {
      setSize({
        width: elementRef.current?.scrollWidth || 1,
        height: elementRef.current?.scrollHeight || 1,
        clientHeight: elementRef.current?.clientHeight|| 1
      });
    });
    resizeObserver.observe(elementRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [elementRef]);

  return size;
};

export default useElementSize;
