import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../../axios/axios-config";
import {
  IAvaiableDate,
  IAvaiableDates,
  IAvaiableSchedule,
  ICalendarCancellationParams,
  ICancelationReason,
  IExamAvaiableDatesParams,
  IGetAll,
  IGetConsultationAvaiableDatesParams,
  IGetConsultationAvaiableScheduleParams,
  IGetExamAvaiableScheduleParams,
  IGroupExam,
  IIGetAllCalendarParams,
  IIGetCalendarByDateParams,
  IIScheduleConsultationParams,
  IProfessional,
  IProfessionalSearchParams,
  ISpeciality,
} from "./model";

export class ScheduleAppointmentService {
  public static getTypes = () => {
    return GlobalAxios.get<ISelectType[]>(
      process.env.REACT_APP_MAIN + "/api/v1/Generic/list",
      {
        params: {
          Identifier: "TasySchedulingType",
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  public static getPeriodOptions = () => {
    return GlobalAxios.get<ISelectType[]>(
      process.env.REACT_APP_MAIN + "/api/v1/Generic/list",
      {
        params: {
          Identifier: "ScheduleShiftCode",
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  public static getSituationOptions = () => {
    return GlobalAxios.get<ISelectType[]>(
      process.env.REACT_APP_MAIN + "/api/v1/Generic/list",
      {
        params: {
          Identifier: "TasySituationCalendar",
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  public static getSpecialitys = () => {
    return GlobalAxios.get<ISpeciality[]>(
      process.env.REACT_APP_CALENDAR + "/api/v1/MedicalSpecialty/List"
    );
  };

  public static getProfessionals = (params: IProfessionalSearchParams) => {
    return GlobalAxios.get<IProfessional[]>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarConsultationRegister",
      { params }
    );
  };

  public static getExamGroups = () => {
    return GlobalAxios.get<IGroupExam[]>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarExamGroupingRegister"
    );
  };

  public static CalendarAllConsultationExamAvailabilityByDate = (
    params: IIGetCalendarByDateParams
  ) => {
    return GlobalAxios.get<IAvaiableDate[]>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarAllConsultationExamAvailabilityByDate/Schedules",
      {
        params,
        paramsSerializer: {
          indexes: null,
        },
      }
    );
  };

  public static CalendarAllConsultationExam = (
    params: IIGetAllCalendarParams
  ) => {
    return GlobalAxios.get<IGetAll>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarAllConsultationExam",
      {
        params,
        paramsSerializer: {
          indexes: null,
        },
      }
    );
  };

  public static getCalendarExamCancellationReason = () => {
    return GlobalAxios.get<ICancelationReason[]>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarExamCancellationReason"
    );
  };

  public static getCalendarConsultationCancellationReason = () => {
    return GlobalAxios.get<ICancelationReason[]>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarConsultationCancellationReason"
    );
  };

  public static calendarConsultationScheduleCancellation = (
    params: ICalendarCancellationParams
  ) => {
    return GlobalAxios.put<{ id: string }>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarConsultationScheduleCancellation",
      params
    );
  };

  public static calendarExamScheduleCancellation = (
    params: ICalendarCancellationParams
  ) => {
    return GlobalAxios.put<{ id: string }>(
      process.env.REACT_APP_CALENDAR +
        "api/v1/tasy/CalendarExamScheduleCancellation",
      params
    );
  };

  public static calendarConsultationScheduling = (
    params: IIScheduleConsultationParams
  ) => {
    return GlobalAxios.put<{ id: string }>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarConsultationScheduling",
      params,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  public static calendarExamScheduling = (
    params: IIScheduleConsultationParams
  ) => {
    return GlobalAxios.put<{ id: string }>(
      process.env.REACT_APP_CALENDAR + "/api/v1/tasy/CalendarExamScheduling",
      params,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  public static getConsultationAvaiableDate = (
    params: IGetConsultationAvaiableDatesParams
  ) => {
    return GlobalAxios.get<IAvaiableDates>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarConsultationAvailable/Dates",
      {
        params,
        paramsSerializer: {
          indexes: null,
        },
      }
    );
  };

  public static getConsultationAvaiableSchedules = (
    params: IGetConsultationAvaiableScheduleParams
  ) => {
    return GlobalAxios.get<IAvaiableSchedule[]>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarConsultationAvailable/Schedules",
      {
        params,
        paramsSerializer: {
          indexes: null,
        },
      }
    );
  };

  public static getExamAvaiableDate = (params: IExamAvaiableDatesParams) => {
    return GlobalAxios.get<IAvaiableDates>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarExamAvailable/Dates",
      {
        params,
        paramsSerializer: {
          indexes: null,
        },
      }
    );
  };

  public static getExamAvaiableSchedules = (
    params: IGetExamAvaiableScheduleParams
  ) => {
    return GlobalAxios.get<IAvaiableSchedule[]>(
      process.env.REACT_APP_CALENDAR +
        "/api/v1/tasy/CalendarExamAvailable/Schedules",
      {
        params,
        paramsSerializer: {
          indexes: null,
        },
      }
    );
  };
}
