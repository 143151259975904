import { GlobalAxios } from "../axios/axios-config";
import { IImportParams } from "../models/health-programs-imports";

const HEALTH_PROGRAM_IMPORT_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/HealthProgramImports/";

export const createImport = (item: IImportParams) => {
  const formData = new FormData();
  formData.append("File", item.File);
  formData.append("ImportPersonName", item.ImportPersonName);
  formData.append("HealthProgramId", item.HealthProgramId);
  if (item.OriginOfDemand)
    formData.append("OriginOfDemand", item.OriginOfDemand);

  return GlobalAxios.post(HEALTH_PROGRAM_IMPORT_ROUTE + "Import", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
