import { AutoComplete, ISelectType, useFetch } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { listTicketAutomationMessage } from "../../../../../../../services/ticketAutomationMessage.service";
import { useAutomationRules } from "../../../../../../../store/contexts/AutomationRulesContext";

const translationPath = "components.automationMessages.";

export const SelectAutomationMessage = memo(() => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<ISelectType[]>([]);

  const { automation } = useAutomationRules();

  const { sendRequest } = useFetch(listTicketAutomationMessage);

  const fetchAutomationMessages = useCallback(async () => {
    if (!automation) return;
    const { data, success } = await sendRequest({
      TicketAutomationId: automation.id,
    });
    if (data && success) {
      setOptions(
        data.map((d) => ({
          id: d.id,
          name: d.ticketAutomationMessageType.name,
        }))
      );
    }
  }, [sendRequest, automation]);

  useEffect(() => {
    fetchAutomationMessages();
  }, [fetchAutomationMessages]);

  return (
    <Grid item xs={12} sm={12}>
      <AutoComplete
        name="ticketAutomationMessage"
        label={t(translationPath + "automation_message_type")}
        getOptionLabel={(option) => option.name}
        size="small"
        fullWidth
        autoFocus
        options={options}
      />
    </Grid>
  );
});
