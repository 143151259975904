import { GlobalAxios } from "./../axios/axios-config";
import { ISelectType } from "@4uhub/lib4uhub";
import { INewTicketServiceArea } from "../models/ticket-service-area";
import { TTicketServiceAreaForm } from "../pages/settings/Settings/SettingsCrm/TicketServiceArea/TicketServiceAreaForm/TicketServiceAreaSchema";

const TICKET_SERVICE_AREA_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketServiceAreas/";

export class TicketServAreaService {
  create = (item: INewTicketServiceArea) => {
    return GlobalAxios.post(TICKET_SERVICE_AREA_ROUTE, item);
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<TTicketServiceAreaForm>(
      TICKET_SERVICE_AREA_ROUTE + id
    );
  };

  updateItemById = ({
    item,
    id,
  }: {
    item: INewTicketServiceArea;
    id: string;
  }) => {
    return GlobalAxios.put(TICKET_SERVICE_AREA_ROUTE + id, item);
  };

  list = (params?: any) => {
    return GlobalAxios.get<ISelectType[]>(TICKET_SERVICE_AREA_ROUTE + "list", {
      params: params,
    });
  };
}

export default TicketServAreaService;
