import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../services/mainApi.service";
import { IGetNeighborhoods } from "../../../models/neighborhoods";
import { Table } from "@4uhub/lib4uhub";

const NEIGHBORHOOD_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Neighborhood";
const neighborhoodService = new MainApiService<IGetNeighborhoods>(
  NEIGHBORHOOD_ROUTE
);

const translationPath = "page.register.neighborhoods.";

const NeighborhoodsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columns: GridColDef<IGetNeighborhoods>[] = [
    {
      field: "code",
      headerName: t(translationPath + "code") || "Code",
      maxWidth: 150,
    },
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
    },
    {
      field: "city",
      headerName: t(translationPath + "city") || "City",
      renderCell: (params) => params.value.name,
    },
  ];

  const handleRowClick = (params: GridRowParams<IGetNeighborhoods>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  return (
    <Table<IGetNeighborhoods>
      showDefaultMacroFilters={false}
      service={neighborhoodService}
      columns={columns}
      title={t(translationPath + "neighborhoods")}
      searchInput
      onAdd={handleAdd}
      showDelete={false}
      defaultActions={false}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20, 100]}
      searchInputPlaceHolder={
        t(translationPath + "search_neighborhoods") ||
        "Searh for a neighborhood"
      }
      addButtonLabel={
        t(translationPath + "add_neighborhood") || "Add Neighborhood"
      }
    />
  );
};

export default NeighborhoodsPage;
