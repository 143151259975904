import { FormInput } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const translationPath = "page.assessments.questions.";

export const QuestionDescription = memo(() => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={12}>
      <FormInput
        size="small"
        name="description"
        fullWidth
        label={t(translationPath + "description") || "Description"}
        multiline
        minRows={4}
      />
    </Grid>
  );
});
