import { useCallback, useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  AutoComplete,
  FormContainer,
  FormInput,
  IInputFile,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";

// Schema
import MainApiService from "../../../../services/mainApi.service";
import { IGeneric } from "../../../../models/generics";
import { TRegisterForm, registerSchema } from "./RegisterSchema";
import SitesAppsService from "../../../../services/sitesApps.service";

import SiteAppFiles from "./SiteAppFile/SiteAppFiles";
import SiteAppDomains from "./SiteAppDomains/SiteAppDomains";
import SiteAppType from "./SiteAppType";
import { IGetSiteAppDomain } from "../../../../models/site-app";

const siteAppService = new SitesAppsService();

const GENERIC_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const genericService = new MainApiService<IGeneric>(GENERIC_ROUTE);

const translationPath = "page.register.applications.";

const Register = () => {
  const { t } = useTranslation();
  const [siteAppFilesId, setSiteAppFilesId] = useState<string[]>();
  const [siteAppDomain, setSiteAppDomain] = useState<IGetSiteAppDomain>();
  let deleteFilesList: string[] = [];

  const onDeleFileHandler = (file: IInputFile) => {
    const selectedFile = file;
    if (selectedFile.id.length !== 0) {
      deleteFilesList.push(selectedFile.id);
    }
  };

  const { sendRequest: save, loading: sLoading } = useFetch(
    siteAppService.create
  );
  const { sendRequest: update, loading: uLoading } = useFetch(
    siteAppService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    siteAppService.getItemById
  );

  const saveHandler = (v: TRegisterForm) => {
    const saveFilesIds = v.siteAppMediaFiles.filter((media) =>
      media.file ? !siteAppFilesId?.includes(media.file.id!) : ""
    );

    return save({
      name: v.name,
      description: v.description,
      frontConfig: v.frontConfig,
      mediaFilesIds: saveFilesIds.map((media) =>
        media.file ? media.file.id! : ""
      ),
      siteAppDomains:
        v.type.code !== "1"
          ? []
          : v.domains?.map((d) => ({
              siteAppDomainTypeId: d.siteAppDomainType
                ? d.siteAppDomainType.id
                : "",
              domain: d.domain ? d.domain : "",
            })),
      siteAppStatusId: v.status.id,
      siteAppTypeId: v.type.id,
      deleteMediaFilesIds: deleteFilesList,
    });
  };

  const updateHandler = (v: TRegisterForm, id: string) => {
    const updateFilesIds = v.siteAppMediaFiles.filter((media) =>
      media.file ? !siteAppFilesId?.includes(media.file.id!) : ""
    );

    return update({
      item: {
        ...v,
        id: id,
        mediaFilesIds: updateFilesIds.map((media) =>
          media.file ? media.file.id! : ""
        ),
        siteAppDomains:
          v.type.code !== "1"
            ? []
            : v.domains?.map((d) => ({
                siteAppDomainTypeId: d.siteAppDomainType
                  ? d.siteAppDomainType.id
                  : "",
                domain: d.domain ? d.domain : "",
              })),
        siteAppStatusId: v.status.id,
        siteAppTypeId: v.type.id,
        deleteMediaFilesIds: deleteFilesList,
      },
      id: id,
    });
  };

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TRegisterForm>> => {
      const { data, success } = await get(id);

      if (success && data) {
        if (data.siteAppDomains) {
          setSiteAppDomain(data.siteAppDomains[0]);
        }
        const newData: TRegisterForm = {
          type: data.siteAppType,
          description: data.description,
          frontConfig: data.frontConfig,
          name: data.name,
          domains: data.siteAppDomains?.map((d) => ({
            siteAppDomainType: {
              id: d.siteAppDomainType.id,
              name: d.siteAppDomainType.value,
              code: d.siteAppDomainType.code,
            },
            domain: d.domain,
            id: d.id,
          })),
          siteAppMediaFiles: data.siteAppMediaFiles.map((media) => ({
            mediaType: media.mediaType,
            file: {
              id: media.id,
              fileName: media.fileName,
              fileSize: media.fileSize,
              isPublic: media.isPublic,
              mimeType: media.mimeType,
              cdnDomain: media.cdnDomain,
              filePath: media.filePath,
            },
          })),
          status: data.siteAppStatus,
        };
        const filesId = newData.siteAppMediaFiles.map(
          (media) => media.file?.id || ""
        );
        if (filesId) setSiteAppFilesId(filesId);
        return {
          data: newData,
          success: true,
        };
      }
      return {
        data: null,
        success: false,
      };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TRegisterForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "application")}
      schema={registerSchema}
      subtitle={t(translationPath + "new_application")}
      subtitleWatchField={"name"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="name"
              fullWidth
              label={t(translationPath + "name") || "Name"}
              autoFocus
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <AutoComplete
              params={{ identifier: "siteAppStatus" }}
              getOptionLabel={(option: any) => {
                return option.name;
              }}
              size="small"
              label={t(translationPath + "status") || "Status"}
              name="status"
              request={genericService.list}
            />
          </Grid>

          <SiteAppType />

          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="frontConfig"
              fullWidth
              label={
                t(translationPath + "front_config") || "Theme Configurations"
              }
              multiline
              minRows={4}
            />
          </Grid>

          <Grid item xs={12}>
            <FormInput
              size="small"
              multiline
              name="description"
              fullWidth
              minRows={4}
              label={t("page.register.generics.description") || "Description"}
            />
          </Grid>

          <SiteAppDomains siteAppDomain={siteAppDomain} />

          <SiteAppFiles onDeleteFileHandler={onDeleFileHandler} />
        </Grid>
      )}
    />
  );
};

export default Register;
