import { Stack } from "@mui/material";
import { useAppSelector } from "../../store/store";
import SettingsMenuItem from "./SettingsMenuItem";
import NoData from "../Logs/NoData/NoData";
import { useTranslation } from "react-i18next";

const translationPath = "components.searchResults.";

const SearchResults = ({
  search,
  parentMenus,
}: {
  search: string;
  parentMenus: string[];
}) => {
  const { t } = useTranslation();
  const menus = useAppSelector((state) => state.menu.items);

  const allParentMenus = menus.filter((menu) =>
    parentMenus.some((p) =>
      menu.parent ? menu.parent.description === p : null
    )
  );

  const selectedSubmenu = (submenuDescription: string) => {
    return menus.filter((menu) =>
      menu.parent ? menu.parent.description === submenuDescription : null
    );
  };

  const searchedParentMenus = allParentMenus.filter((parent) =>
    parent.name.toLowerCase().includes(search.toLowerCase())
  );

  const submenus = menus.filter((menu) =>
    menu.parent
      ? menu.parent.description === "Configurações Gerais do Sistema" ||
        menu.parent.description === "ChatBot"
      : null
  );

  const searchedSubmenus = submenus.filter((parent) =>
    parent.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Stack flexDirection={"column"} mt={3}>
      {searchedParentMenus.length === 0 && searchedSubmenus.length === 0 && (
        <NoData message={t(translationPath + "no_data")} />
      )}
      {searchedSubmenus.length !== 0 &&
        searchedSubmenus.map((menu) => (
          <SettingsMenuItem
            description={menu.description || ""}
            icon={menu.iconClass || null}
            link={menu.link}
            name={menu.name}
            key={menu.id}
          />
        ))}
      {searchedParentMenus.length !== 0 &&
        searchedParentMenus.map((menu) => (
          <SettingsMenuItem
            description={menu.description || ""}
            icon={menu.iconClass || null}
            link={menu.link}
            name={menu.name}
            submenuDescription={
              menu.description === "Configurações Gerais do Sistema"
                ? "Configurações Gerais do Sistema"
                : menu.description === "ChatBot"
                ? "ChatBot"
                : undefined
            }
            submenu={selectedSubmenu(menu.description || "")}
            key={menu.id}
          />
        ))}
    </Stack>
  );
};

export default SearchResults;
