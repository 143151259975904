import { Box, Typography } from "@mui/material";
import { MouseEvent, RefObject, memo } from "react";
import { stringToColor } from "@4uhub/lib4uhub";
import LockIcon from "@mui/icons-material/Lock";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import usePopover from "../../../../hooks/usePopover";
import EventPopover from "../Event/EventPopover";
import { EcalendarStatusCode, ICalendar } from "../../model";
import useDate from "../../../../hooks/useDate";
import useCalendar from "../CalendarContext/useCalendar";

interface IEventProps {
  data: ICalendar;
  eventRef?: RefObject<HTMLDivElement>;
}

const Event: React.FC<IEventProps> = ({ data, eventRef }) => {
  const props = usePopover(String(data.identifier));

  const { onAvaiableTimeClick } = useCalendar();

  const isAvaiableTime = data.calendarStatusCode === EcalendarStatusCode.FREE;

  const isBlocked = data.calendarStatusCode === EcalendarStatusCode.BLOCKED;

  const isCofirmed =
  data.calendarStatusCode === EcalendarStatusCode.CONFIMED ||
  data.calendarStatusCode === EcalendarStatusCode.CONFIMED2;

  const color = stringToColor(data.descriptionSite);

  const onClickHandler = (event: MouseEvent) => {
    if (isAvaiableTime) {
      onAvaiableTimeClick && onAvaiableTimeClick(data);
    } else {
      props.handleClick(event);
    }
  };

  const { localeFormat } = useDate();

  return (
    <>
      <Box
        width={"100%"}
        onClick={onClickHandler}
        sx={[
          {
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            alignItems: "center",
            gap: 1,
            "&:hover": {
              opacity: 0.9,
              cursor: "pointer",
            },
            padding: 1,
            borderRadius: 1,
          },
          isAvaiableTime &&
            ((t) => ({
              border: `1px solid ${color}`,
            })),

          !isAvaiableTime &&
            ((t) => ({
              backgroundColor: color,
              color: t.palette.getContrastText(color),
            })),

          isBlocked &&
            ((t) => {
              const pColor = t.palette.augmentColor({
                color: { main: color },
              }).light;

              return {
                background: `repeating-linear-gradient( 45deg, ${color}, ${color} 5px, ${pColor} 5px, ${pColor} 15px )`,
              };
            }),
        ]}
      >
        {isBlocked && <LockIcon sx={{ fontSize: 16 }} />}
        {isCofirmed && <CheckCircleOutlineIcon sx={{ fontSize: 16 }} />}
        <Typography
          color={(t) => t.palette.getContrastText(color)}
          fontSize={12}
          whiteSpace={"nowrap"}
        >
          {localeFormat(new Date(data.calendarStartDate), "p")}
        </Typography>
        <Typography
          fontSize={12}
          whiteSpace={"nowrap"}
          textOverflow={"ellipsis"}
          overflow={"hidden"}
        >
          {data.pacientName}
        </Typography>
      </Box>
      <EventPopover
        popoverProps={{ ...props, onClose: props.handleClose }}
        event={data}
      />
    </>
  );
};

export default memo(Event);
