import { ReactElement, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";

import { Grid } from "@mui/material";

import { useFormContext, RadioGroupInput, IRadio } from "@4uhub/lib4uhub";

import { buildTPath } from "../../../sales-channel.utils";
import { TSalesChannelForm } from "../../salesChannel.schema";

export type TEntity = "QPF" | "QPJ";

export type TForm = TSalesChannelForm & { entity: TEntity };

const Checkboxes = (): ReactElement => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<TForm>();
  const entity = useWatch({ control, name: "entity", defaultValue: "QPF" });

  const radios: IRadio<TEntity>[] = [
    {
      label: t(buildTPath("individual_person")),
      value: "QPF",
    },
    { label: t(buildTPath("legal_entity")), value: "QPJ" },
  ];

  const setToIndividualPerson = useCallback(() => {
    setValue("entity", "QPF");

    setValue("legalEntity", null);
  }, [setValue]);

  const setToLegalEntity = useCallback(() => {
    setValue("entity", "QPJ");

    setValue("individual", null);
  }, [setValue]);

  useEffect(() => {
    if (entity !== "QPJ") return;

    setToLegalEntity();
  }, [entity, setToLegalEntity]);

  useEffect(() => {
    if (entity !== "QPF") return;

    setToIndividualPerson();
  }, [entity, setToIndividualPerson]);

  return (
    <Grid item xs={12} lg={5}>
      <RadioGroupInput
        inline
        disableBorder
        name="entity"
        groupLabel=""
        radios={radios}
      />
    </Grid>
  );
};

export default Checkboxes;
