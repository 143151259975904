import { Grid, Typography } from "@mui/material";
import { memo } from "react";

interface IDayLabelProps {
  onClick: () => void;
  isToday?: boolean;
  isSameMonth?: boolean;
  label: string;
}

const DayLabel: React.FC<IDayLabelProps> = ({
  onClick,
  isToday,
  isSameMonth,
  label,
}) => {
  return (
    <Grid
      item
      onClick={onClick}
      sx={(t) => ({
        backgroundColor: isToday ? t.palette.primary.main : "transparent",
        width: 30,
        height: 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 10,
        color: isToday
          ? t.palette.primary.contrastText
          : isSameMonth
          ? t.palette.text.primary
          : t.palette.text.disabled,
        alignSelf: "center",
        "&:hover": {
          opacity: 0.9,
          cursor: "pointer",
          backgroundColor:
            t.palette.mode === "dark"
              ? t.palette.grey[900]
              : t.palette.grey[300],
        },
      })}
    >
      <Typography>{label}</Typography>
    </Grid>
  );
};

export default memo(DayLabel);
