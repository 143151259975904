import { IconButton, Tooltip } from "@mui/material";
import ExtensionUninstallPopper from "./ExtensionUninstallPopover";
import { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";

const translationPath = "components.extensionsStoreDetail.";

interface IExtensionUninstallButtonProps {
  extensionId: string;
  siteAppId: string;
  onRealoadExtensionsList: () => void;
  toggleDetail: (value: boolean) => void;
}

const ExtensionUninstallButton = ({
  extensionId,
  siteAppId,
  onRealoadExtensionsList,
  toggleDetail,
}: IExtensionUninstallButtonProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onClose = () => setOpen(false);

  return (
    <>
      <ExtensionUninstallPopper
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        extensionId={extensionId}
        siteAppId={siteAppId}
        onRealoadExtensionsList={onRealoadExtensionsList}
        toggleDetail={toggleDetail}
      />
      <Tooltip title={t(translationPath + "tooltip_uninstall")}>
        <IconButton
          size="small"
          color="primary"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            setOpen(true);
          }}
        >
          <CancelIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default ExtensionUninstallButton;
