import { SelectTreeUncontrolled } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo } from "react";
import { GlobalAxios } from "../../../../../axios/axios-config";
import { useTranslation } from "react-i18next";
import { ISelectCategoriesProps } from "./models";

const CATEGORIES_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/QuestionCategories";

const translationPath = "components.selectCategories.";

export const SelectCategories = memo(
  ({ onGetNodes }: ISelectCategoriesProps) => {
    const { t } = useTranslation();

    return (
      <Grid item xs={12} sm={6}>
        <SelectTreeUncontrolled
          size="small"
          fullWidth
          multiple
          autoFocus
          route={CATEGORIES_ROUTE}
          listRoute={CATEGORIES_ROUTE + "/ListAutoRelated"}
          axios={GlobalAxios}
          getNodes={onGetNodes}
          selectTreeUncontrolledProps={{
            label: t(translationPath + "label"),
            noOptionsText: t(translationPath + "no_options_text"),
            optionsButtonLabel: t(translationPath + "options_button_label"),
            drawerProps: {
              title: t(translationPath + "drawer_props.title"),
              searchPlaceholder: t(
                translationPath + "drawer_props.search_placeholder"
              ),
              createButtonLabel: t(
                translationPath + "drawer_props.create_button_label"
              ),
              applyButtonLabel: t(
                translationPath + "drawer_props.apply_button_label"
              ),
              inputLabel: t(translationPath + "drawer_props.input_label"),
              deleteConfirm: {
                title: t(translationPath + "drawer_props.delete_confirm.title"),
                message: t(
                  translationPath + "drawer_props.delete_confirm.message"
                ),
              },
            },
          }}
        />
      </Grid>
    );
  }
);
