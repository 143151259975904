import { useTranslation } from "react-i18next";

import Fields from "./Fields";
import { FormContainer, useFetch } from "@4uhub/lib4uhub";

import {
  contractAttachmentTypesSchema,
  TcontractAttachmentTypesReasonForm,
} from "./ContractAttachmentType.schema";
import { buildTPath } from "../contract-attachment-type.utils";
import {
  createContractAttachmentType,
  findContractAttachmentTypeById,
  updateContractAttachmentType,
} from "../../../../../services/contractAttachmentType.service";

const ContractAttachmentType = () => {
  const { t } = useTranslation();

  const { sendRequest: create, loading: isSaving } = useFetch(
    createContractAttachmentType
  );
  const { sendRequest: update, loading: isUpdating } = useFetch(
    updateContractAttachmentType
  );
  const { sendRequest: findById, loading: isFetching } = useFetch(
    findContractAttachmentTypeById
  );

  const handleFormValues = ({
    salesContractType: { id },
    ...rest
  }: TcontractAttachmentTypesReasonForm) => ({
    ...rest,
    salesContractTypeId: id,
  });

  const createHandler = (values: TcontractAttachmentTypesReasonForm) =>
    create(handleFormValues(values));

  const updateHandler = (
    values: TcontractAttachmentTypesReasonForm,
    id: string
  ) =>
    update({
      id,
      item: handleFormValues({ ...values, id }),
    });

  const isLoading = isSaving || isUpdating || isFetching;

  return (
    <FormContainer<TcontractAttachmentTypesReasonForm>
      saveFunction={createHandler}
      updateFunction={updateHandler}
      getFunction={findById}
      loading={isLoading}
      title={t(buildTPath("title"))}
      schema={contractAttachmentTypesSchema}
      subtitle={t(buildTPath("new"))}
      subtitleWatchField="name"
      fields={() => <Fields />}
    />
  );
};

export default ContractAttachmentType;
