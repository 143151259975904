import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { IFormInformationProps } from "./Models";

const FormInformation = ({ label, data, minHeight }: IFormInformationProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      mb={2}
      minHeight={minHeight}
      display={"flex"}
      flexDirection={matches ? "column" : "row"}
      alignItems={matches ? "start" : "center"}
    >
      <Typography
        mr={matches ? 0 : 2}
        sx={(theme) => ({
          color:
            theme.palette.mode === "light"
              ? theme.palette.grey[600]
              : theme.palette.grey[200],
        })}
      >
        {label}:
      </Typography>
      <Typography
        sx={{
          maxWidth: matches ? 190 : undefined,
          overflowWrap: "break-word",
        }}
      >
        {data}
      </Typography>
    </Box>
  );
};

export default FormInformation;
