import { Modal } from "@4uhub/lib4uhub";
import { IIntegratedDataModalProps } from "./models";
import IntegratedData from "./IntegratedData";

const IntegratedDataModal = ({
  open,
  onClose,
  title,
  service,
}: IIntegratedDataModalProps) => {
  return (
    <Modal open={open} onClose={onClose} title={title}>
      <IntegratedData service={service} />
    </Modal>
  );
};

export default IntegratedDataModal;
