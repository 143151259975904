import { useCallback, useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  AutoComplete,
  FormContainer,
  FormInput,
  IInputFile,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";

// Schema
import MainApiService from "../../../../services/mainApi.service";
import { IGeneric } from "../../../../models/generics";
import SitesAppsService from "../../../../services/sitesApps.service";

import { IGetSiteAppDomain } from "../../../../models/site-app";
import {
  TRegisterForm,
  registerSchema,
} from "../../../applications/Register/RegisterForm/RegisterSchema";
import SiteAppType from "../../../applications/Register/RegisterForm/SiteAppType";
import SiteAppDomains from "../../../applications/Register/RegisterForm/SiteAppDomains/SiteAppDomains";
import SiteAppFiles from "../../../applications/Register/RegisterForm/SiteAppFile/SiteAppFiles";

const siteAppService = new SitesAppsService();

const GENERIC_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const genericService = new MainApiService<IGeneric>(GENERIC_ROUTE);

const translationPath = "page.register.applications.";

const MyApplication = () => {
  const { t } = useTranslation();
  const [siteAppDomain, setSiteAppDomain] = useState<IGetSiteAppDomain>();
  let deleteFilesList: string[] = [];

  const onDeleFileHandler = (file: IInputFile) => {
    const selectedFile = file;
    if (selectedFile.id.length !== 0) {
      deleteFilesList.push(selectedFile.id);
    }
  };

  const { sendRequest: get, loading: gLoading } = useFetch(
    siteAppService.getItemById
  );

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TRegisterForm>> => {
      const { data, success } = await get(id);

      if (success && data) {
        if (data.siteAppDomains) {
          setSiteAppDomain(data.siteAppDomains[0]);
        }
        const newData: TRegisterForm = {
          type: data.siteAppType,
          description: data.description,
          frontConfig: data.frontConfig,
          name: data.name,
          domains: data.siteAppDomains?.map((d) => ({
            siteAppDomainType: {
              id: d.siteAppDomainType.id,
              name: d.siteAppDomainType.value,
              code: d.siteAppDomainType.code,
            },
            domain: d.domain,
            id: d.id,
          })),
          siteAppMediaFiles: data.siteAppMediaFiles.map((media) => ({
            mediaType: media.mediaType,
            file: {
              id: media.id,
              fileName: media.fileName,
              fileSize: media.fileSize,
              isPublic: media.isPublic,
              mimeType: media.mimeType,
              cdnDomain: media.cdnDomain,
              filePath: media.filePath,
            },
          })),
          status: data.siteAppStatus,
        };
        return {
          data: newData,
          success: true,
        };
      }
      return {
        data: null,
        success: false,
      };
    },
    [get]
  );

  const loading = gLoading;

  return (
    <FormContainer<TRegisterForm>
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "application")}
      schema={registerSchema}
      subtitle={t(translationPath + "new_application")}
      subtitleWatchField={"name"}
      actionsBar={false}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="name"
              fullWidth
              label={t(translationPath + "name") || "Name"}
              disabled
              sx={(t) => ({
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: t.palette.text.primary,
                },
              })}
              autoFocus
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <AutoComplete
              params={{ identifier: "siteAppStatus" }}
              getOptionLabel={(option: any) => {
                return option.name;
              }}
              size="small"
              label={t(translationPath + "status") || "Status"}
              name="status"
              request={genericService.list}
              disable
              sx={(t) => ({
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: t.palette.text.primary,
                },
              })}
            />
          </Grid>

          <SiteAppType disable />

          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="frontConfig"
              fullWidth
              label={
                t(translationPath + "front_config") || "Theme Configurations"
              }
              multiline
              minRows={4}
              disabled
              sx={(t) => ({
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: t.palette.text.primary,
                },
              })}
            />
          </Grid>

          <Grid item xs={12}>
            <FormInput
              size="small"
              multiline
              name="description"
              fullWidth
              minRows={4}
              label={t("page.register.generics.description") || "Description"}
              disabled
              sx={(t) => ({
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: t.palette.text.primary,
                },
              })}
            />
          </Grid>

          <SiteAppDomains siteAppDomain={siteAppDomain} disable />

          <SiteAppFiles onDeleteFileHandler={onDeleFileHandler} disable />
        </Grid>
      )}
    />
  );
};

export default MyApplication;
