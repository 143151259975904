import { GlobalAxios } from "./../axios/axios-config";
import { ISettingsGeneral } from "../models/settings";

const SETTINGS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/GeneralSettings";

export class SettingsService {
  getSettingsGeneral = () => {
    return GlobalAxios.get<ISettingsGeneral>(SETTINGS_ROUTE);
  };

  updateSettingsGeneral = ({ item }: { item: ISettingsGeneral }) => {
    return GlobalAxios.put(SETTINGS_ROUTE, item);
  };
}

export default SettingsService;
