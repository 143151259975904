import LoginForm, {
  TLoginFormData,
} from "../../../components/LoginForm/LoginForm";
import CardContainer from "../../../components/CardContainer/CardContainer";
import { useCallback } from "react";
import { tokenService } from "../../../services/token.service";
import { useNavigate } from "react-router-dom";
import { authService } from "../../../services/auth.service";
import { saveUserName } from "../../../services/user.service";
import { useFetch } from "@4uhub/lib4uhub";

const LoginPage = () => {
  const navigate = useNavigate();
  const { sendRequest, loading } = useFetch(authService.login);

  const onSubmitHandler = useCallback(
    async (values: TLoginFormData) => {
      const { data } = await sendRequest(values);
      if (data) {
        saveUserName(values.username);
        tokenService.saveTokens(
          data.access_token,
          data.refresh_token,
          data.establishmentTenantSequential
        );
        navigate(`/e/${data.establishmentTenantSequential}`, { replace: true });
      }
    },
    [sendRequest, navigate]
  );

  return (
    <CardContainer>
      <LoginForm onSubmit={onSubmitHandler} loading={loading} />
    </CardContainer>
  );
};

export default LoginPage;
