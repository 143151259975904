import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "@4uhub/lib4uhub";
import EditIcon from "@mui/icons-material/Edit";

import MainApiService from "../../../services/mainApi.service";
import { IDashBoardPanel } from "../../../models/dashboardpanel";

const GENERICS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/DashboardPanel";

const DASHBOARD_VIEW_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/DashboardPanel/GetViewWithPagination";

const TRANSLATION_PATH = "page.panels.";

const genericService = new MainApiService<IDashBoardPanel>(GENERICS_ROUTE);
const dashboardViewService = new MainApiService<IDashBoardPanel>(
  DASHBOARD_VIEW_ROUTE
);

interface IDashBoardPanelsPageProps {
  isView?: boolean;
}

const DashBoardPanelsPage: React.FC<IDashBoardPanelsPageProps> = ({
  isView = false,
}) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const columns: GridColDef<IDashBoardPanel>[] = useMemo(
    () => [
      {
        field: "name",
        headerName: t(TRANSLATION_PATH + "name") + "",
      },
    ],
    [t]
  );

  const handleEdit = (params: GridRowParams<IDashBoardPanel>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const handleRowClick = (params: GridRowParams<IDashBoardPanel>) => {
    navigate(`panel/${params.id.toString()}`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  return (
    <Table<IDashBoardPanel>
      showDefaultMacroFilters={false}
      showDeleteButton={!isView}
      showButton={!isView}
      defaultActions={!isView}
      service={isView ? dashboardViewService : genericService}
      columns={columns}
      title={t(TRANSLATION_PATH + "title")}
      searchInput
      rowActions={
        !isView
          ? [
              {
                text: t(TRANSLATION_PATH + "edit"),
                action: handleEdit,
                icon: <EditIcon />,
              },
            ]
          : undefined
      }
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={t(TRANSLATION_PATH + "search")}
      addButtonLabel={t(TRANSLATION_PATH + "add") + ""}
      confirmOptions={{
        title: t(TRANSLATION_PATH + "confirm.title"),
        description: t(TRANSLATION_PATH + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
      onAdd={handleAdd}
      onRowClick={handleRowClick}
    />
  );
};

export default DashBoardPanelsPage;
