import { FormCheckBox, useFormContext } from "@4uhub/lib4uhub";
import { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TTicketServiceAreaForm } from "./TicketServiceAreaSchema";
import { useWatch } from "react-hook-form";

const translationPath = "chatBot.page.service.ticketServiceArea.";

export const EnableAllTheTime = memo(() => {
  const { t } = useTranslation();

  const { control, setValue } = useFormContext<TTicketServiceAreaForm>();

  const enableSpecialTime = useWatch({
    name: "enableSpecialTime",
    control,
  });

  useEffect(() => {
    if (enableSpecialTime) {
      setValue("enableAllTheTime", false);
    }
  }, [enableSpecialTime, setValue]);

  return (
    <FormCheckBox
      name="enableAllTheTime"
      label={`${t(translationPath + "enable_all_the_time")}`}
    />
  );
});
