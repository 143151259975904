import { useTranslation } from "react-i18next";

import { Box, Grid } from "@mui/material";
import { FormInput, useFormContext } from "@4uhub/lib4uhub";

import EntitySelect from "./EntitySelect";
import SalesChannelTypeSelect from "./SaleChannelTypeSelect";
import SalesChannelClasificationSelect from "./SalesChannelClassificationSelect";

import { buildTPath } from "../../sales-channel.utils";
import FormInformation from "../../../../../../components/FormInformation/FormInformation";
import { TSalesChannelForm } from "../salesChannel.schema";
import { TFormType } from "@4uhub/lib4uhub/dist/types/components/Form/FormContainer/models";

const Fields = ({ type }: { type: TFormType }) => {
  const { t } = useTranslation();

  const translate = (field: string) => t(buildTPath(field));
  const { getValues } = useFormContext<TSalesChannelForm>();

  const code = getValues("code");
  const situation = getValues("situation");

  return (
    <Box
      sx={(t) => ({
        p: 1,
        px: 7,
        width: "100%",
        height: "calc(100vh - 18rem)",
        overflow: "auto",
        backgroundColor: t.palette.background.default,
        borderRadius: 1,
      })}
    >
      <Grid
        container
        spacing={2}
        sx={(t) => ({
          py: 6,
        })}
      >
        {type !== "create" && situation && (
          <Grid item xs={12} sm={6}>
            {" "}
            <FormInformation
              label={translate("situation")}
              data={situation.name?.toString() ?? ""}
              minHeight={40}
            />
          </Grid>
        )}
        {type !== "create" && code && (
          <Grid item xs={12} sm={6}>
            <FormInformation
              label={translate("code")}
              data={code}
              minHeight={40}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <FormInput
            size="small"
            name="title"
            fullWidth
            label={translate("title_placeholder")}
          />
        </Grid>

        <EntitySelect />

        <Grid item xs={12} sm={6}>
          <SalesChannelClasificationSelect />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SalesChannelTypeSelect />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Fields;
