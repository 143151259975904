import { memo, useCallback, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Button, Form, FormInput, Modal, useFetch } from "@4uhub/lib4uhub";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";

import { TCancel, cancelSchema } from "./schema";
import { ScheduleAppointmentService } from "../../Schedule.service";
import { ETasyScheduleTypeCode, ICalendar } from "../../model";
import Reason from "./Reason";
import { useSchedule } from "../../ScheduleProvider";
import useDate from "../../../../hooks/useDate";

const keys = cancelSchema.keyof().enum;

interface ICancelSchedule {
  data: ICalendar;
}

const CancelSchedule: React.FC<ICancelSchedule> = ({
  data: {
    descriptionSite,
    calendarDate,
    identifier,
    calendarIdentifier,
    tasyScheduleTypeCode,
    pacientName,
  },
}) => {
  const [open, setOpen] = useState(false);

  const { t, localeFormat } = useDate();

  const { fetch } = useSchedule();

  const methods = useForm<TCancel>({
    resolver: zodResolver(cancelSchema),
  });

  const { sendRequest: cancelExam, loading } = useFetch(
    ScheduleAppointmentService.calendarExamScheduleCancellation
  );
  const { sendRequest: cancelConsultation, loading: consultationLoading } =
    useFetch(
      ScheduleAppointmentService.calendarConsultationScheduleCancellation
    );

  const onClickHandler = () => {
    setOpen(true);
  };

  const onCloseHandler = useCallback(() => {
    setOpen(false);
  }, []);

  const onSubmit = useCallback(
    async (v: TCancel) => {
      const { success } = await (String(tasyScheduleTypeCode) ===
        ETasyScheduleTypeCode.CONSULTATION
        ? cancelConsultation
        : cancelExam)({
        calendarDate: calendarDate,
        cancellationReasonCode: v.reason.code,
        cancellationReasonDescription: v.justification,
        identifier: String(identifier),
        identifierCalendar: String(calendarIdentifier),
      });

      if (success) {
        onCloseHandler();
        fetch();
      }
    },
    [
      calendarDate,
      identifier,
      calendarIdentifier,
      tasyScheduleTypeCode,
      onCloseHandler,
      cancelConsultation,
      cancelExam,
      fetch,
    ]
  );

  return (
    <>
      <Modal onClose={onCloseHandler} open={open}>
        <Form {...methods}>
          <Stack gap={2}>
            <Typography variant="h5">
              {t("components.schedule.schedulePopover.cancel")}
            </Typography>
            <Typography variant="body2">
              {t("components.schedule.schedulePopover.cancelDescription", {
                pacient: pacientName,
                doctor: descriptionSite,
                date: localeFormat(new Date(calendarDate), "PPPP p"),
              })}
            </Typography>
            <Reason code={String(tasyScheduleTypeCode)} />
            <FormInput
              name={keys.justification}
              label={t(
                "components.schedule.schedulePopover.justification.label"
              )}
              size="small"
              placeholder={
                t(
                  "components.schedule.schedulePopover.justification.placeholder"
                ) + ""
              }
              multiline
              fullWidth
              minRows={4}
            />
            <Stack flexDirection={"row"} justifyContent={"flex-end"} gap={1}>
              <Button onClick={onCloseHandler}>
                {t("components.schedule.schedulePopover.cancelButton")}
              </Button>
              <Button
                variant="contained"
                onClick={methods.handleSubmit(onSubmit)}
                loading={consultationLoading || loading}
              >
                {t("components.schedule.schedulePopover.confirm")}
              </Button>
            </Stack>
          </Stack>
        </Form>
      </Modal>

      <Tooltip title={t("components.schedule.schedulePopover.cancel")}>
        <IconButton size="small" onClick={onClickHandler}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default memo(CancelSchedule);
