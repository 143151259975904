import { Chip, Grid, Stack, Typography } from "@mui/material";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import {  useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IProfile, ISelectType, useFormContext } from "@4uhub/lib4uhub";

import useFlatMap from "../../../../hooks/useFlatMap";

const RolesInherited = ({
  profilesOptions,
}: {
  profilesOptions: ISelectType[];
}) => {
  const { t } = useTranslation();
  const [rolesFiltered, setRolesFiltered] = useState<ISelectType[]>([]);

  const { control } = useFormContext();

  const [rolesInherited, setRolesInherited] = useState<ISelectType[]>([]);

  const selectedProfiles = useWatch({
    name: "profiles",
    control,
  });

  const filterObjects = useFlatMap({ data: profilesOptions });
  const filterSelectedObjects = useFlatMap({ data: rolesFiltered });

  const loadRoles = useCallback(
    (
      rolesOptions: IProfile[] | ISelectType[],
      filterSelectedObjects: ISelectType[],
      setState: (value: SetStateAction<ISelectType[]>) => void
    ) => {
      const filteredRolesInherited = rolesOptions.filter((elem) => {
        return filterSelectedObjects.some((ele: any) => {
          return ele.id === elem.id && ele.id === elem.id;
        });
      });
      setState(filteredRolesInherited);
    },
    []
  );

  useEffect(() => {
    if (selectedProfiles) {
      loadRoles(profilesOptions, selectedProfiles, setRolesFiltered);
    }
  }, [loadRoles, profilesOptions, selectedProfiles, setRolesFiltered]);

  useEffect(() => {
    loadRoles(filterObjects, filterSelectedObjects, setRolesInherited);
  }, [filterObjects, filterSelectedObjects, loadRoles]);

  return (
    <Grid item xs={12} mb={8}>
      <Typography variant="subtitle1">
        {t("page.register.users.roles_inherited") ||
          "Profiles that the user has inherited based on the roles they have"}
      </Typography>
      <Stack
        display={"flex"}
        flexDirection={"row"}
        flexWrap={"wrap"}
        alignItems={"center"}
        gap={1}
        mt={1}
      >
        {rolesInherited.map((role) => (
          <Chip size="small" key={role.id} label={role.name} />
        ))}
        {rolesInherited.length === 0 && (
          <Typography variant="body2">
            {t("page.register.users.none_roles_inherited") ||
              "No inherited profile found"}
          </Typography>
        )}
      </Stack>
    </Grid>
  );
};

export default RolesInherited;
