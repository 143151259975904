import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import {
  DatePicker,
  DatePickerSlotsComponents,
  DateView,
  LocalizationProvider,
  DatePickerSlotsComponentsProps,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import pt from "date-fns/locale/pt-BR";
import es from "date-fns/locale/es";
import en from "date-fns/locale/en-US";

import { useCallback, useMemo, useState } from "react";
import { UncapitalizeObjectKeys } from "@mui/x-date-pickers/internals";
import {
  format,
  isToday,
  isBefore,
  getWeek,
  subDays,
  startOfWeek,
  endOfWeek,
} from "date-fns";
import { useTranslation } from "react-i18next";

import {
  EDashboardGroupTypeCode,
  usePanel,
} from "../../../../store/contexts/DashBoardPannelContext";
import { DayWeek } from "./WeekDay";

const DATE_FORMAT = "dd/MM/yyyy";

const Filter = () => {
  const {
    filter,
    setFilter,
    panel: { created },
  } = usePanel();

  const [hoveredDay, setHoveredDay] = useState<Date | null>(null);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  let views: DateView[] = useMemo(() => ["day", "month", "year"], []);

  let slots:
    | UncapitalizeObjectKeys<DatePickerSlotsComponents<Date>>
    | undefined;
  let slotProps: DatePickerSlotsComponentsProps<Date> = useMemo(
    () => ({
      textField: { size: "small", disabled: true },
    }),
    []
  );

  const handleChange = (event: SelectChangeEvent) => {
    const code = event.target.value as EDashboardGroupTypeCode;
    setFilter((f) => ({ ...f, DashboardGroupTypeCode: code }));
  };

  const onChangeHandler = useCallback(
    (value: Date | null) => {
      if (!value) return;

      let labelFilter = format(value, DATE_FORMAT);

      let yearFilter = String(value.getFullYear());

      switch (filter.DashboardGroupTypeCode) {
        case EDashboardGroupTypeCode.WEEK:
          labelFilter = String(getWeek(value));
          break;
        case EDashboardGroupTypeCode.MONTH:
          labelFilter = String(value.getMonth());
          break;
        case EDashboardGroupTypeCode.YEAR:
          labelFilter = yearFilter;
          break;
        default:
          break;
      }

      setFilter((f) => ({
        ...f,
        LabelFilter: labelFilter,
        YearFilter: yearFilter,
        value: value,
      }));
    },
    [filter, setFilter]
  );

  const handleShouldDisableMonth = useCallback(
    (month: Date) => {
      const createdD = subDays(new Date(created), 1);

      if (month.getFullYear() > createdD.getFullYear()) {
        return false;
      }

      return month.getMonth() < createdD.getMonth();
    },
    [created]
  );

  const handleShouldDisableYear = useCallback(
    (year: Date) => {
      const createdY = subDays(new Date(created), 1).getFullYear();

      return year.getFullYear() < createdY;
    },
    [created]
  );

  const handleShouldDisableDay = useCallback(
    (day: Date) => {
      const createdD = subDays(new Date(created), 1);
      return isToday(day) || isBefore(day, createdD);
    },
    [created]
  );

  switch (filter.DashboardGroupTypeCode) {
    case EDashboardGroupTypeCode.WEEK:
      slots = { day: DayWeek };
      slotProps = {
        ...slotProps,
        day: (ownerState) =>
          ({
            selectedDay: filter.value,
            hoveredDay,
            onPointerEnter: () => setHoveredDay(ownerState.day),
            onPointerLeave: () => setHoveredDay(null),
          } as any),
        textField: {
          size: "small",
          disabled: true,
          inputProps: {
            value: `${format(
              startOfWeek(filter.value),
              DATE_FORMAT
            )} - ${format(endOfWeek(filter.value), DATE_FORMAT)}`,
          },
        },
      };
      break;
    case EDashboardGroupTypeCode.MONTH:
      views = ["month", "year"];
      break;
    case EDashboardGroupTypeCode.YEAR:
      views = ["year"];
      break;
    default:
      break;
  }

  const adapterLocale = useMemo(() => {
    switch (language) {
      case "en-US": {
        return en;
      }
      case "es": {
        return es;
      }
      default: {
        return pt;
      }
    }
  },[language]);

  return (
    <Stack direction={"row"} gap={2}>
      <FormControl size="small">
        <Select
          labelId="date-type-select"
          id="demo-simple-select"
          value={filter.DashboardGroupTypeCode}
          onChange={handleChange}
        >
          <MenuItem value={EDashboardGroupTypeCode.DAY}>
            {t("components.panel.filter.day")}
          </MenuItem>
          <MenuItem value={EDashboardGroupTypeCode.WEEK}>
            {t("components.panel.filter.week")}
          </MenuItem>
          <MenuItem value={EDashboardGroupTypeCode.MONTH}>
            {t("components.panel.filter.month")}
          </MenuItem>
          <MenuItem value={EDashboardGroupTypeCode.YEAR}>
            {t("components.panel.filter.year")}
          </MenuItem>
        </Select>
      </FormControl>

      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
        <DatePicker
          disableFuture
          views={views}
          value={filter.value}
          slotProps={slotProps}
          disableHighlightToday
          slots={slots}
          onAccept={onChangeHandler}
          shouldDisableMonth={handleShouldDisableMonth}
          shouldDisableYear={handleShouldDisableYear}
          shouldDisableDate={handleShouldDisableDay}
        />
      </LocalizationProvider>
    </Stack>
  );
};
export default Filter;
