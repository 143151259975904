import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { FormContainer, FormInput, Treturn, useFetch } from "@4uhub/lib4uhub";
import {
  TTicketServiceWhatsForm,
  ticketServiceWhatsSchema,
} from "./TicketServiceWhatsSchema";
import { useParams } from "react-router-dom";
import { useCallback, useState } from "react";
import TicketServWhatsService from "../../../../../../services/ticketServiceWhats.service";
import WhatsappApiConfigs from "../../../../../../components/WhatsConfigData/WhatsappApiConfigs";

const ticketServiceWhats = new TicketServWhatsService();

const translationPath = "chatBot.page.register.ticketServiceWhats.";

const TicketServiceWhats = () => {
  const { t } = useTranslation();
  const { ticketServiceId } = useParams();
  const [verifyToken, setVerifyToken] = useState("");

  const { sendRequest: save, loading: sLoading } = useFetch(
    ticketServiceWhats.create
  );
  const { sendRequest: update, loading: uLoading } = useFetch(
    ticketServiceWhats.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    ticketServiceWhats.getItemById
  );

  const handleSave = (v: TTicketServiceWhatsForm) => {
    return save({
      ...v,
      ticketServiceId: ticketServiceId || "",
    });
  };

  const updateHandler = (v: TTicketServiceWhatsForm, id: string) => {
    return update({
      item: {
        ...v,
        ticketServiceId: ticketServiceId || "",
      },
      id: id,
    });
  };

  const handleGet = useCallback(
    async (id: string): Promise<Treturn<TTicketServiceWhatsForm>> => {
      const { data, success } = await get(id);
      if (data) {
        if (data.ticketService) {
          setVerifyToken(data.ticketService.verifyToken);
        }
        return {
          data: data,
          success: true,
        };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TTicketServiceWhatsForm>
      saveFunction={handleSave}
      updateFunction={updateHandler}
      getFunction={handleGet}
      loading={loading}
      title={t(translationPath + "ticket_service_whats")}
      schema={ticketServiceWhatsSchema}
      subtitle={t(translationPath + "new_ticket_service_whats")}
      subtitleWatchField={"whatsAppBusinessPhoneNumberId"}
      fields={() => (
        <>
          {verifyToken && (
            <Grid container mb={8}>
              <WhatsappApiConfigs verifyToken={verifyToken} />
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormInput
                size="small"
                name="whatsAppBusinessPhoneNumberId"
                fullWidth
                label={
                  t(translationPath + "business_phone_number") ||
                  "Whatsapp Business Phone Number Id"
                }
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormInput
                size="small"
                name="whatsAppBusinessAccountId"
                fullWidth
                label={
                  t(translationPath + "business_account") ||
                  "Whatsapp Business Account Id"
                }
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormInput
                size="small"
                name="whatsAppBusinessId"
                fullWidth
                label={
                  t(translationPath + "whatsapp_business") ||
                  "Whatsapp Business Id"
                }
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormInput
                size="small"
                name="accessToken"
                fullWidth
                label={t(translationPath + "access_token") || "Access Token"}
              />
            </Grid>
          </Grid>
        </>
      )}
    />
  );
};

export default TicketServiceWhats;
