import { Box, Stack } from "@mui/material";
import HourPosition from "../HourPosition";
import { add } from "date-fns/esm";
import { memo, useEffect, useRef, useState } from "react";
import { isSameDay } from "date-fns";

interface IActualHour {
  containerHeigth: number;
  date: Date;
  dayIndex: number;
  showDot?: boolean;
}

const ActualHour: React.FC<IActualHour> = ({
  containerHeigth,
  date,
  dayIndex,
  showDot = true,
}) => {
  const [today, setToday] = useState(new Date());
  const myRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timeUpdate = setInterval(() => {
      setToday(new Date());
    }, 60000);

    return () => clearInterval(timeUpdate);
  }, []);

  if (!isSameDay(today, date)) {
    return null;
  }


  return (
    <HourPosition
      startDate={today}
      date={date}
      endDate={add(today, { minutes: 3 })}
      sx={{ zIndex: 99 }}
      containerHeigth={containerHeigth}
      dayIndex={dayIndex}
      index={0}
    >
      <Stack
        ref={myRef}
        direction={"row"}
        height={"100%"}
        width={"100%"}
        alignItems={"center"}
      >
        {showDot && (
          <Box
            sx={{
              width: 15,
              height: 15,
              backgroundColor: "red",
              borderRadius: 10,
              marginLeft: -2,
            }}
          />
        )}

        <Box
          sx={{
            backgroundColor: "red",
            width: "100%",
            height: "1px",
          }}
        />
      </Stack>
    </HourPosition>
  );
};

export default memo(ActualHour);
