import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  AutoComplete,
  FormContainer,
  FormInput,
  ISelectType,
  SwitchInput,
  useFetch,
} from "@4uhub/lib4uhub";
import MainApi from "../../../../../services/mainApi.service";
import { useLocation, useParams } from "react-router";
import { useAppSelector } from "../../../../../store/store";
import {
  createSchedule,
  getScheduleById,
  updateScheduleById,
} from "../../../../../services/restrictionSchedule.service";
import { TScheduleForm, scheduleSchema } from "./ScheduleSchema";

const translationPath = "page.4uBus.restrictions.schedule.";

const RESTRICTION_RULE_TYPE_ROUTE =
  process.env.REACT_APP_4UBUS + "/api/v1/RestrictionRuleType/";
const restrictionRuleTypeService = new MainApi<ISelectType>(
  RESTRICTION_RULE_TYPE_ROUTE
);

const Schedule = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const currentPage = useAppSelector((state) =>
    state.menu.items.find(
      (menu) =>
        menu.link === `/${pathname.split("/").slice(4).slice(0, 3).join("/")}`
    )
  );

  const { extension } = useParams();

  const { sendRequest: save, loading: sLoading } = useFetch(createSchedule);
  const { sendRequest: update, loading: uLoading } =
    useFetch(updateScheduleById);
  const { sendRequest: get, loading: gLoading } = useFetch(getScheduleById);

  const saveHandler = (v: TScheduleForm) =>
    save({
      ...v,
      restrictionRuleTypeId: v.restrictionRuleType.id,
    });

  const updateHandler = (v: TScheduleForm, id: string) =>
    update({
      item: {
        ...v,
        restrictionRuleTypeId: v.restrictionRuleType.id,
      },
      id: id,
    });

  const loading = sLoading || uLoading || gLoading;

  const title = currentPage ? currentPage.name : t(translationPath + "rule");

  return (
    <FormContainer<TScheduleForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={get}
      loading={loading}
      title={title}
      schema={scheduleSchema}
      subtitle={t(translationPath + "new_schedule")}
      subtitleWatchField={"value"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            <SwitchInput
              name="enable"
              label={t(translationPath + "enable") || "Enable"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="value"
              fullWidth
              label={t(translationPath + "value") || "Value"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <AutoComplete
              params={{ ExtensionCode: extension }}
              name="restrictionRuleType"
              label={t(translationPath + "restriction_rule_type")}
              getOptionLabel={(option) => {
                return option.name;
              }}
              size="small"
              request={restrictionRuleTypeService.list}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="description"
              fullWidth
              label={t(translationPath + "description") || "Description"}
              multiline
              minRows={4}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default Schedule;
