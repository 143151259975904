import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../../services/mainApi.service";
import { useCallback } from "react";
import CompaniesService from "../../../../services/companies.service";
import { TCompanyForm, companySchema } from "./CompanySchema";
import { AutoComplete, FormContainer, FormInput, ISelectType, SwitchInput, useFetch } from "@4uhub/lib4uhub";

const companyService = new CompaniesService();

const COMPANY_GROUP_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/CompanyGroup/";
const companyGroupService = new MainApiService<ISelectType>(
  COMPANY_GROUP_ROUTE
);

const translationPath = "page.register.companies.";

const Company = () => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(
    companyService.create
  );
  const { sendRequest: update, loading: uLoading } = useFetch(
    companyService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    companyService.getItemById
  );

  const saveHandler = (v: TCompanyForm) =>
    save({
      ...v,
      companyGroupId: v.companyGroup.id,
    });

  const updateHandler = (v: TCompanyForm, id: string) =>
    update({
      item: {
        ...v,
        companyGroupId: v.companyGroup.id,
      },
      id: id,
    });

  const getHandler = useCallback(
    async (id: string) => {
      const data = get(id);
      return data;
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TCompanyForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "company")}
      schema={companySchema}
      subtitle={t(translationPath + "new_company")}
      subtitleWatchField={"name"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            <SwitchInput
              name="enable"
              label={t(translationPath + "enable") || "Enable"}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <FormInput
              size="small"
              name="code"
              type="number"
              fullWidth
              label={t(translationPath + "code") || "Code"}
            />
          </Grid>

          <Grid item xs={12} sm={5}>
            <FormInput
              size="small"
              name="name"
              fullWidth
              label={t(translationPath + "name") || "Name"}
            />
          </Grid>

          <Grid item xs={12} sm={5}>
            <AutoComplete
              getOptionLabel={(option: any) => {
                return option.name;
              }}
              size="small"
              label={t(translationPath + "company_group") || "Companies Group"}
              name="companyGroup"
              request={companyGroupService.list}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default Company;
