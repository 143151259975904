import { GlobalAxios } from "../axios/axios-config";

export interface IDocsVersion {
  id: string;
  version: string;
  versionDate: string;
  blocked: boolean;
  enable: boolean;
}

const BASE_URL = process.env.REACT_APP_MAIN;

const LAST_VERSION_ROUTE = BASE_URL + "/api/v1/DocAcceptance/";

export class DocsVersionApiService {
  getItemById = (id: string) => {
    return GlobalAxios.get<IDocsVersion>(
      LAST_VERSION_ROUTE + id + "/GetLastDocumentVersion"
    );
  };

  list = (id: string) => {
    return GlobalAxios.get<IDocsVersion>(LAST_VERSION_ROUTE + id + "/list");
  };
}

export default DocsVersionApiService;
