import { memo } from "react";
import { Grid } from "@mui/material";
import { FormInput, InputFileForm } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../../../../../../axios/axios-config";
import { useTranslation } from "react-i18next";
import { IProtocolDefaultMediaFileProps } from "./models";

const FILE_ROUTE = process.env.REACT_APP_FILEMANAGER + "/api/v1/Upload";
const FILE_SIGN_ROUTE = process.env.REACT_APP_FILEMANAGER + "/api/v1/Signed";

const translationPath = "page.settings.crm.protocolsDefault.";

const ProtocolMediaFile: React.FC<IProtocolDefaultMediaFileProps> = ({
  index,
  onFileSelected,
}) => {
  const { t } = useTranslation();

    const fileExtensions = [
      ".jpg",
      ".jpeg",
      ".png",
      ".webp",
      ".mpeg",
      ".mp3",
      ".mp4",
      ".pdf",
      ".xml",
      ".doc",
      ".docx",
      ".csv",
      ".html",
      ".pptx",
      ".ppt",
      ".txt",
    ];

    return (
      <>
        <Grid item xs={12} sm={12}>
          <InputFileForm
            name={`ticketDefaultDocMediaFiles.${index}.file`}
            axios={GlobalAxios}
            fileRoute={FILE_ROUTE}
            signRoute={FILE_SIGN_ROUTE}
            fileInputProps={{
              accept: fileExtensions.join(", "),
              label: `${t(translationPath + "file")}`,
              maxFileSize: 500,
              mediaTypeCode: "1",
              mediaTypeIdentifier: "TicketDefaultDocMediaType",
              parentName: "TicketDefaultDoc",
              type: "Private",
              onFileSelected: onFileSelected,
              inputProps: {
                size: "small",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormInput
            size="small"
            name={`ticketDefaultDocMediaFiles.${index}.description`}
            label={t(translationPath + "description")}
            fullWidth
            multiline
            minRows={2}
          />
        </Grid>
      </>
    );
};

export default memo(ProtocolMediaFile);
