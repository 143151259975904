import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";
import LegalEntitySelect from "./LegalEntitySelect";
import { FormInput, SwitchInput } from "@4uhub/lib4uhub";
import EstablishmentsSelect from "./EstablishmentsSelect";

import { buildTPath } from "../../../grantors.utils";

const GrantorFields = () => {
    const { t } = useTranslation();

    return <>
        <Grid container direction="column" spacing={5}>
          <Grid item xs={12} sm={12} textAlign="end">
            <SwitchInput
              name="enable"
              label={t(buildTPath("enabled", "Enabled"))}
            />
          </Grid>

          <Grid item xs={12} sm={5}>
            <FormInput
              size="small"
              name="name"
              fullWidth
              label={t(buildTPath("name", "Name"))}
            />
          </Grid>

          <Grid item xs={12} sm={5}>
            <LegalEntitySelect />
          </Grid>

          <Grid item xs={12} sm={5}>
            <EstablishmentsSelect />
          </Grid>
        </Grid>
    </>
};

export default GrantorFields;