import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useCallback, useEffect, useState } from "react";
import { IGetSiteAppDomain } from "../../../../models/site-app";
import { useTranslation } from "react-i18next";
import { useFormContext, useNotificationContext } from "@4uhub/lib4uhub";

const translationPath = "components.appDomain.";

const AppDomain = ({
  siteAppDomain,
  siteAppCode,
}: {
  siteAppDomain: IGetSiteAppDomain;
  siteAppCode: string;
}) => {
  const { t } = useTranslation();
  const { setMessage } = useNotificationContext();
  const [domain, setDomain] = useState("");
  const { setValue } = useFormContext();

  const onLoadDomain = useCallback(() => {
    const siteAppDomainType = `domains.0.siteAppDomainType`;
    const domainName = `domains.0.domain`;
    if (siteAppCode === "2" || siteAppCode === "3" || siteAppCode === "4") {
      setDomain(siteAppDomain.domain);
      setValue(siteAppDomainType, siteAppDomain.siteAppDomainType);
      setValue(domainName, siteAppDomain.domain);
    }
  }, [siteAppDomain, siteAppCode, setValue]);

  useEffect(() => {
    onLoadDomain();
  }, [onLoadDomain]);

  const onCopyToClipboardHandler = () => {
    const copyText = domain;
    navigator.clipboard.writeText(copyText);
    setMessage({
      message: "Domínio copiado com sucesso",
      notificationKey: new Date().getMilliseconds(),
      type: "success",
    });
  };

  return (
    <Stack flexDirection={"row"} alignItems={"start"} spacing={1}>
      {domain && (
        <>
          <Typography variant="h6" mr={2}>
            {t(translationPath + "domain")}: {domain}
          </Typography>
          <Tooltip title={t(translationPath + "title")}>
            <IconButton size="small" onClick={onCopyToClipboardHandler}>
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Stack>
  );
};

export default AppDomain;
