import {
  AutoCompleteUncontrolled,
  Button,
  ISelectType,
  Modal,
} from "@4uhub/lib4uhub";
import { memo, useCallback, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import { IAddVariablesButtonProps } from "./models";
import { useTranslation } from "react-i18next";

const translationPath = "components.addVariablesButton.";

export const AddVariablesButton = memo(
  ({ inputRef, options, onAddVariable }: IAddVariablesButtonProps) => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const [variable, setVariable] = useState<ISelectType | null>(null);

    const openHandler = () => setOpen(true);
    const closeHandler = useCallback(() => {
      setVariable(null);
      setOpen(false);
    }, []);

    const addHandler = useCallback(() => {
      if (!variable) return;
      onAddVariable(variable.name);
      closeHandler();
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }, [variable, inputRef, onAddVariable, closeHandler]);

    return (
      <>
        <Modal
          open={open}
          onClose={closeHandler}
          title={`${t(translationPath + "variables")}`}
        >
          <Box pt={2}>
            <AutoCompleteUncontrolled
              label={t(translationPath + "variable")}
              value={variable}
              onValueSelected={setVariable}
              options={options}
              size="small"
              fullWidth
            />
            <Box sx={{ pt: 2, width: "100%", display: "flex", gap: 2 }}>
              <Button fullWidth variant="outlined" onClick={closeHandler}>
                {t(translationPath + "cancel")}
              </Button>
              <Button
                fullWidth
                variant="contained"
                disabled={!variable}
                onClick={addHandler}
              >
                {t(translationPath + "add")}
              </Button>
            </Box>
          </Box>
        </Modal>
        <Button
          startIcon={<AddIcon />}
          color="inherit"
          disabled={!options || options.length === 0}
          onClick={openHandler}
        >
          {t(translationPath + "add_variables")}
        </Button>
      </>
    );
  }
);
