import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid } from "@mui/material";

import { useAppSelector } from "../../../store/store";
import { IMenuRoute } from "../../../services/menuRoute.service";

import CardMenu from "../../../components/CardMenu/CardMenu";
import AditionalSettingsHeader from "./AditionalSettingsHeader";

const AditionalSettingsPage = () => {
  const [search, setSearch] = useState<string>("");
  const [menu, setMenu] = useState<IMenuRoute[]>([]);

  const menuItems: IMenuRoute[] = useAppSelector((state) => state.menu.items);

  const aditionalSettingsPageId = menuItems.find(
    (menu) => menu.link === "/admSystem/aditionalSettings"
  )?.id;

  const filterMenu = useCallback(() => {
    if (aditionalSettingsPageId) {
      setMenu(
        menuItems.filter((menu) => menu.parentId === aditionalSettingsPageId)
      );
    }
  }, [aditionalSettingsPageId, menuItems]);

  useEffect(() => {
    filterMenu();
  }, [filterMenu]);

  const onSearch = (value: string) => {
    setSearch(value.toLowerCase());
  };

  const filteredMenu = useMemo(() => {
    return menu.filter((menu) => menu.name.toLowerCase().includes(search));
  }, [menu, search]);

  return (
    <Box width={"100%"} display={"flex"} flexDirection={"column"}>
      <AditionalSettingsHeader onSearch={onSearch} />
      <Grid
        container
        spacing={4}
        height={"100%"}
        width={"100%"}
        flexWrap={"wrap"}
        sx={(theme) => ({
          [theme.breakpoints.up("md")]: {
            paddingRight: 30,
          },
        })}
      >
        {filteredMenu.map((item) => (
          <Grid key={item.id} item xl={2} lg={3} md={3} sm={4} xs={12}>
            <CardMenu menu={item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AditionalSettingsPage;
