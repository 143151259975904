import { useTranslation } from "react-i18next";

import { AutoComplete } from "@4uhub/lib4uhub";
import { buildTPath } from "../../sales-channel.utils";
import { listSalesChannelClassifications } from "../../../../../../services/salesChannelClassification.service";

const SalesChannelClasificationSelect = () => {
  const { t } = useTranslation();

  return (
    <AutoComplete
      getOptionLabel={({ name }) => name}
      size="small"
      label={t(buildTPath("classification"))}
      name="salesChannelClassification"
      request={listSalesChannelClassifications}
    />
  );
};

export default SalesChannelClasificationSelect;
