import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Table, useFetch } from "@4uhub/lib4uhub";
import { Box, Typography } from "@mui/material";
import {
  GridCheckCircleIcon,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import { format } from "date-fns";
import { enUS, ptBR } from "date-fns/locale";

import { approveSalesProductPriceList } from "../../../../services/contractProductPriceList.service";

import { memo, useCallback, useMemo } from "react";
import { IContractProductPriceList } from "../../../../models/sales-product-price-list";
import MainApi from "../../../../services/mainApi.service";
import { useSalesContract } from "../../../../store/contexts/SalesContractContext";
const translationsPath =
  "page.4uSalesContract.registers.salesProductCtrPriceList.";

const serviceURL =
  process.env.REACT_APP_MAIN + "/api/v1/SalesProductCtrPriceList/";
const service = new MainApi<IContractProductPriceList>(serviceURL);

export type TRowClick = GridRowParams<IContractProductPriceList>;

export const SalesProductCtrPriceListPage = memo(() => {
  const { onOpen, selectPropId, onRefresh, selectTabName, refresh } =
    useSalesContract();

  const { sendRequest: approve } = useFetch(approveSalesProductPriceList);

  const { id } = useParams();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const translate = (field: string) => t(translationsPath + field);

  const columns: GridColDef<IContractProductPriceList>[] = [
    {
      field: "name",
      headerName: translate("name"),
      renderCell: ({ value }) => (
        <Typography variant="body2">{value}</Typography>
      ),
    },
    {
      field: "code",
      headerName: translate("code"),
      renderCell: ({ value }) => (
        <Typography variant="body2">{value}</Typography>
      ),
    },
    {
      field: "initialDate",
      headerName: translate("initial_date"),
      renderCell: (params) => {
        if (!params.value) return "-";
        const date = new Date(params.value);
        date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
        return format(date, "P", {
          locale: language === "pt-BR" ? ptBR : enUS,
        });
      },
    },
    {
      field: "endDate",
      headerName: translate("end_date"),
      renderCell: (params) => {
        if (!params.value) return "-";
        const date = new Date(params.value);
        date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
        return format(date, "P", {
          locale: language === "pt-BR" ? ptBR : enUS,
        });
      },
    },
    {
      field: "approvalDate",
      headerName: translate("approval_date"),
      renderCell: (params) => {
        if (!params.value) return "-";
        const date = new Date(params.value);
        date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
        return format(date, "P", {
          locale: language === "pt-BR" ? ptBR : enUS,
        });
      },
    },
  ];

  const handleAdd = () => {
    onOpen();
    selectTabName("priceList");
  };

  const rowClickHandler = useCallback(
    (params: GridRowParams<IContractProductPriceList>) => {
      selectPropId(params.row.id);
      onOpen();
      selectTabName("priceList");
    },
    [selectPropId, onOpen, selectTabName]
  );

  const onApproveProductPriceList = useCallback(
    async (id: string) => {
      const { success } = await approve(id);
      if (success) {
        onRefresh();
      }
    },
    [approve, onRefresh]
  );

  const props = useMemo(() => {
    return { SalesProductCtrId: id, refresh };
  }, [id, refresh]);

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      <Box height={600}>
        <Table<IContractProductPriceList>
          searchInput
          showDefaultMacroFilters={false}
          columns={columns}
          service={service}
          onAdd={handleAdd}
          onRowClick={rowClickHandler}
          serviceProps={props}
          title={translate("title")}
          defaultPageSize={20}
          pageSizeOptions={[5, 10, 20]}
          addButtonLabel={translate("add")}
          searchInputPlaceHolder={translate("search")}
          rowActions={(params) => [
            params.row.approvalDate === null
              ? {
                  action: (params) => {
                    onApproveProductPriceList(params.row.id);
                  },
                  icon: <GridCheckCircleIcon fontSize="small" />,
                  text: `${t(translationsPath + "approve")}`,
                }
              : null,
          ]}
          confirmOptions={{
            title: translate("confirm.title"),
            description: translate("confirm.description"),
            confirmationButtonProps: { variant: "contained" },
          }}
        />
      </Box>
    </Box>
  );
});
