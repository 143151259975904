import { useTranslation } from "react-i18next";
import { ptBR, enUS } from "date-fns/locale";
import { useCallback, useMemo } from "react";
import { format } from "date-fns";

const useDate = () => {
  const props = useTranslation();

  const locale = useMemo(
    () => (props.i18n.language === "pt-BR" ? ptBR : enUS),
    [props.i18n.language]
  );

  const localeFormat = useCallback(
    (date: Date, pFormat: string) => {
      return format(date, pFormat, { locale });
    },
    [locale]
  );

  return { ...props, locale, localeFormat };
};

export default useDate;
