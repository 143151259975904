import { Box, CircularProgress } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useFetch } from "@4uhub/lib4uhub";
import PrettyJson from "../../pages/admSystem/establishments/OnPremise/PrettyJson";
import NoData from "../Logs/NoData/NoData";
import { IIntegratedDataProps } from "./models";
import { useTranslation } from "react-i18next";

const translationPath = "components.integratedData.";

const IntegratedData = ({ service }: IIntegratedDataProps) => {
  const { t } = useTranslation();

  const [integratedData, setIntegratedData] = useState<any>();

  const { sendRequest, loading } = useFetch(service);

  const fetchData = useCallback(async () => {
    const { data, success } = await sendRequest(undefined);
    if (data && success) {
      if (data.length !== 0) {
        setIntegratedData(data);
      }
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return (
      <Box
        height={150}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (integratedData) {
    return <PrettyJson>{integratedData}</PrettyJson>;
  }

  return (
    <Box py={10}>
      <NoData message={t(translationPath + "no_data")} />
    </Box>
  );
};

export default IntegratedData;
