import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {  Table } from "@4uhub/lib4uhub";
import { ISalesGrantor } from "../../../../models/sales-grantors";
import { Chip } from "@mui/material";
import { grantorsService, buildTPath } from "../../grantors.utils";

const GrantorsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columns: GridColDef<ISalesGrantor>[] = [
    {
      field: "name",
      headerName: t(buildTPath('name', 'Name'))!,
    },
    {
      field: "enable",
      maxWidth: 100,
      headerName: t(buildTPath("status", 'Status'))!,
      renderCell: ({ value }) => (
        <Chip
            size="small"
            color={value ? "success" : "error"}
            label={value ? t(buildTPath("active"))
                : t(buildTPath("inactive"))
            }
        />
      )
    },
  ];

  const handleRowClick = ({ id }: GridRowParams<ISalesGrantor>) => {
    navigate(`${id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  return (
    <Table<ISalesGrantor>
      showDefaultMacroFilters={false}
      service={grantorsService}
      columns={columns}
      title={t(buildTPath('grantors'))}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={
        t(buildTPath("search_grantors", "Searh for a grantor")) 
      }
      addButtonLabel={t(buildTPath("add_grantor", "Add Grantor"))!}
      confirmOptions={{
        title: t(buildTPath("confirm.title")),
        description: t(buildTPath("confirm.description")),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default GrantorsPage;
