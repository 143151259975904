import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RedirectPage } from "../../../../components/RedirectPage/RedirectPage";

const translationPath = "chatBot.page.flows.serviceWhatsapp.";

const ServiceWhatsappPage = () => {
  const { t } = useTranslation();

  const redirectHandler = useCallback(() => {
    const url =
      process.env.REACT_APP_ENVIRONMENT === "production"
        ? "https://4uchatbot.4uhub.com.br/"
        : "https://typebot.dev.4uhub.com.br/";
    window.open(url, "_blank");
  }, []);

  useEffect(() => {
    redirectHandler();
  }, [redirectHandler]);

  return (
    <RedirectPage
      title={t(translationPath + "title")}
      subtitle={t(translationPath + "subtitle")}
      buttonLabel={t(translationPath + "button")}
      onClick={redirectHandler}
    />
  );
};

export default ServiceWhatsappPage;
