import { z } from "zod";

const translationPath = "page.4uBus.register.profileTasyBus.errors.";

const userSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const extensionSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
    title: z.string().optional().nullable(),
    description: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "extension" }
);

export const profileTasyBusSchema = z.object({
  id: z.string().optional(),
  isGlobal: z.boolean(),
  enable: z.boolean(),
  name: z.string().min(1, translationPath + "name"),
  extension: extensionSchema,
  users: z.array(userSchema).optional(),
});

export type TProfileTasyBusForm = z.infer<typeof profileTasyBusSchema>;
