import { z } from "zod";

const translationPath = "page.register.companyGroups.errors.";

const filterRestrictionType = z.object(
  {
    id: z.string(),
    identifier: z.string().optional().nullable(),
    code: z.string().optional().nullable(),
    value: z.string().optional().nullable(),
    description: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "filter_restriction_type" }
);

export const companyGroupSchema = z.object({
  id: z.string().optional(),
  name: z.string().nonempty(translationPath + "name"),
  code: z.string().nonempty(translationPath + "code"),
  filterRestrictionType: filterRestrictionType,
  enable: z.boolean(),
  isMain: z.boolean(),
});

export type TCompanyGroupForm = z.infer<typeof companyGroupSchema>;
