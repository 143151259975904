import { Box } from "@mui/material";
import { memo } from "react";
import { IDomainsListProps } from "./models";
import { DomainItem } from "./DomainItem";

export const DomainsList = memo(({ domains }: IDomainsListProps) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {domains.map((domain) => (
        <DomainItem domain={domain} key={domain} />
      ))}
    </Box>
  );
});
