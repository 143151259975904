import { GlobalAxios } from "./../axios/axios-config";
import { ICity, IGetCity } from "../models/cities";

const CITY_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/City/";

export class CityService {
  create = (item: ICity) => {
    return GlobalAxios.post(CITY_ROUTE, item);
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<IGetCity>(CITY_ROUTE + id);
  };

  updateItemById = ({ item, id }: { item: ICity; id: string }) => {
    return GlobalAxios.put(CITY_ROUTE + id, item);
  };

  list = (params?: any) => {
    return GlobalAxios.get<IGetCity[]>(CITY_ROUTE + "list", {
      params: params,
    });
  };
}

export default CityService;
