import { Box, Divider, useMediaQuery, useTheme } from "@mui/material";
import SettingsPageContainer from "../../../../../components/Settings/SettingsPageContainer";
import SyncEstablishment from "./SyncEstablishment/SyncEstablishment";
import { useTranslation } from "react-i18next";
import SyncGeneric from "./SyncGeneric/SyncGeneric";
import SyncUsers from "./SyncUsers/SyncUsers";
import SyncPrivilege from "./SyncPrivilege/SyncPrivilege";
import SyncEstablishmentParam from "./SyncEstablishmentParam/SyncEstablishmentParam";
import SyncRelationshipType from "./SyncRelationshipType/SyncRelationshipType";
import SyncRelationship from "./SyncRelationship/SyncRelationship";
import SyncExtension from "./SyncExtension/SyncExtension";
import { useFetch, useNotificationContext } from "@4uhub/lib4uhub";
import { syncAll } from "../../../../../services/sync.service";
import { useCallback } from "react";

const translationPath = "page.4uBus.sync.";

const SyncPage = () => {
  const { t } = useTranslation();

  const { setMessage } = useNotificationContext();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { sendRequest, loading } = useFetch(syncAll);

  const syncAllHandler = useCallback(async () => {
    const { data, success } = await sendRequest(null);
    if (data && success) {
      setMessage({
        message: t(translationPath + "synced_all"),
        type: "success",
      });
    }
  }, [sendRequest, setMessage, t]);

  return (
    <SettingsPageContainer
      title={t(translationPath + "sync")}
      width={!matches ? "80%" : "100%"}
      action={{
        label: t(translationPath + "sync_all"),
        loading: loading,
        onClick: syncAllHandler,
      }}
    >
      <Box mt={5} width={!matches ? "80%" : "100%"}>
        <SyncEstablishment syncAllLoading={loading} />
        <Divider sx={{ marginBlock: 4 }} />
        <SyncGeneric syncAllLoading={loading} />
        <Divider sx={{ marginBlock: 4 }} />
        <SyncUsers syncAllLoading={loading} />
        <Divider sx={{ marginBlock: 4 }} />
        <SyncPrivilege syncAllLoading={loading} />
        <Divider sx={{ marginBlock: 4 }} />
        <SyncEstablishmentParam syncAllLoading={loading} />
        <Divider sx={{ marginBlock: 4 }} />
        <SyncRelationshipType syncAllLoading={loading} />
        <Divider sx={{ marginBlock: 4 }} />
        <SyncRelationship syncAllLoading={loading} />
        <Divider sx={{ marginBlock: 4 }} />
        <SyncExtension syncAllLoading={loading} />
      </Box>
    </SettingsPageContainer>
  );
};

export default SyncPage;
