import { useTranslation } from "react-i18next";

import { AutoCompleteMulti } from "@4uhub/lib4uhub";
import { listSalesGrantors } from "../../../../../../../services/salesGrantor.service";

const translationsPath = "page.4uSalesContract.registers.contractProducts.";
const SalesGrantorsMultiSelect = () => {
  const { t } = useTranslation();

  return (
    <AutoCompleteMulti
      name="salesGrantors"
      size="small"
      label={t(translationsPath + "products.sales_grantors")}
      getOptionLabel={({ name }) => name}
      request={listSalesGrantors}
    />
  );
};

export default SalesGrantorsMultiSelect;
