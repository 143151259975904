import { useTranslation } from "react-i18next";

import Fields from "./Fields";
import { FormContainer, useFetch } from "@4uhub/lib4uhub";

import { buildTPath } from "../beneficiary-termination-reason.utils";
import {
  TBeneficiaryTerminationReasonForm,
  beneficiaryTerminationReasonSchema,
} from "./beneficiaryTerminationReason.schema";
import {
  createBeneficiaryTerminationReason,
  findBeneficiaryTerminationReasonById,
  updateBeneficiaryTerminationReason,
} from "../../../../../services/beneficiaryTerminationReason.service";

const BeneficiaryTerminationReason = () => {
  const { t } = useTranslation();

  const { sendRequest: create, loading: isSaving } = useFetch(
    createBeneficiaryTerminationReason
  );
  const { sendRequest: update, loading: isUpdating } = useFetch(
    updateBeneficiaryTerminationReason
  );
  const { sendRequest: findById, loading: isFetching } = useFetch(
    findBeneficiaryTerminationReasonById
  );

  const updateHandler = (
    values: TBeneficiaryTerminationReasonForm,
    id: string
  ) => update({ id, item: { ...values, id } });

  const isLoading = isSaving || isUpdating || isFetching;

  return (
    <FormContainer<TBeneficiaryTerminationReasonForm>
      saveFunction={create}
      getFunction={findById}
      updateFunction={updateHandler}
      loading={isLoading}
      title={t(buildTPath("title"))}
      schema={beneficiaryTerminationReasonSchema}
      subtitle={t(buildTPath("new"))}
      subtitleWatchField="name"
      fields={() => <Fields />}
    />
  );
};

export default BeneficiaryTerminationReason;
