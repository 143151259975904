import { Grid } from "@mui/material";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  AutoComplete,
  FormArray,
  QPFQuickRegisterForm,
  useFormContext,
} from "@4uhub/lib4uhub";
import { memo } from "react";

import { TIndividualForm } from "./IndividualSchema";

import { listAllKinshipDegree } from "../../../../services/kinshipDegree.service";

const translation_path = "page.register.individuals.";

const FamilyGroup = () => {
  const { t } = useTranslation();
  const { control, formState } = useFormContext<TIndividualForm>();

  const familyGroupArray = useFieldArray<TIndividualForm, "familyGroup", "key">(
    {
      control,
      name: "familyGroup",
      keyName: "key",
    }
  );

  return (
    <Grid item xs={12}>
      <FormArray
        title={t(translation_path + "family_group") || "Family Group"}
        name="familyGroup"
        errorMessage={formState.errors?.contacts?.message}
        addButtonLabel={
          t(translation_path + "add_family_group") || "Add Family Group"
        }
        formArray={familyGroupArray}
        appendValue={{}}
        rowAlign="start"
        fieldsObject={(index) => {
          const individual = `familyGroup.${index}.individual`;
          const kinshipDegree = `familyGroup.${index}.kinshipDegree`;

          return (
            <>
              <Grid item xs={12} sm={6}>
                <QPFQuickRegisterForm
                  name={individual}
                  label={t(translation_path + "family_individual")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <AutoComplete
                  name={kinshipDegree}
                  label={t(translation_path + "kinship_degree")}
                  size="small"
                  getOptionLabel={(option) => option.name}
                  request={listAllKinshipDegree}
                />
              </Grid>
            </>
          );
        }}
      />
    </Grid>
  );
};

export default memo(FamilyGroup);
