import { FormTransferList, ISelectType } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { privilegesList } from "../../../../../services/busPrivileges.service";

interface IAccessTokenPrivilegesProps {
  extensionsOptions: ISelectType[];
  loading: boolean;
}

const translationPath = "page.4uBus.register.accessTokens.";

const AccessTokenPrivileges = ({
  extensionsOptions,
  loading,
}: IAccessTokenPrivilegesProps) => {
  const { t } = useTranslation();

  if (loading) return null;

  return (
    <Grid item xs={12} sm={12}>
      <FormTransferList
        request={extensionsOptions.length !== 0 ? privilegesList : undefined}
        name="privileges"
        noSoftware={true}
        subfilter={true}
        selectFilterOptions={extensionsOptions}
        defaultSelectFilterValue={extensionsOptions[0]}
        selectFilterSearchBy="code"
        selectFilterLabel={t(translationPath + "extension") || "Extension"}
        selectFilterClearable={false}
      />
    </Grid>
  );
};

export default memo(AccessTokenPrivileges);
