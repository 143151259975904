import { GlobalAxios } from "../axios/axios-config";
import { IGetFaq, INewFaq } from "../models/faqs";
import { ILegalEntityList } from "../models/legalEntitiy";
import { ISearchParams, ISearchReturn } from "../models/requests";

const LEGAL_ENTITY_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Faq/";

export class FaqService {
  create = (item: INewFaq) => {
    return GlobalAxios.post(LEGAL_ENTITY_ROUTE, item);
  };

  getAll = (serchParams?: ISearchParams) => {
    return GlobalAxios.get<ISearchReturn<ILegalEntityList>>(
      LEGAL_ENTITY_ROUTE,
      {
        params: serchParams,
      }
    );
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<IGetFaq>(LEGAL_ENTITY_ROUTE + id);
  };

  updateItemById = ({ item, id }: { item: INewFaq; id: string }) => {
    return GlobalAxios.put(LEGAL_ENTITY_ROUTE + id, item);
  };

  deleteItemById = (id: string) => {
    return GlobalAxios.delete(LEGAL_ENTITY_ROUTE + id);
  };
}

export default FaqService;
