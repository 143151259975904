import {
  ILoginUser,
  IResendEmail,
  IUsersRefreshTokenPost,
  IUsersTokenPost,
  IUsersTokenPostResponse,
  TForgotPassword,
  TResetPassword,
} from "../models/user";
import axios from "axios";
import { tokenService } from "./token.service";
import { GlobalAxios } from "../axios/axios-config";
import { getUserName } from "./user.service";
import { getDomain } from "./domain.service";
import { ITokenByToken, jsonToFormData } from "@4uhub/lib4uhub";
import i18n from "../i18n";

const BASE_URL = process.env.REACT_APP_IDENTIY;

let headers = {
  Authorization: `Basic ${btoa(getDomain())}`,
};

axios.interceptors.request.use((config) => {
  config.headers["Accept-Language"] = i18n.language;

  return config;
});

const login = (user: ILoginUser) => {
  const grant_type = "password";

  const request: IUsersTokenPost = {
    password: user.password,
    username: user.username,
    grant_type,
    scope: "1",
  };

  return GlobalAxios.post<IUsersTokenPostResponse>(
    BASE_URL + "/api/v1/Users/Token",
    jsonToFormData(request),
    {
      headers: headers,
    }
  );
};

const refresh_token = (establishmentId?: string) => {
  const grant_type = "refresh_token";
  const refresh_token = tokenService.getTokens().refresh_token;

  const request: IUsersRefreshTokenPost = {
    refresh_token: refresh_token || "",
    username: getUserName() || "",
    establishment_id: establishmentId || "",
    grant_type,
  };

  return axios.post<IUsersTokenPostResponse>(
    BASE_URL + "/api/v1/Users/Refresh",
    jsonToFormData(request),
    {
      headers: headers,
    }
  );
};

const tokenByToken = ({ establishmentId, token }: ITokenByToken) => {
  return GlobalAxios.post<IUsersTokenPostResponse>(
    BASE_URL + "/api/v1/Users/TokenByToken",
    {
      establishmentId,
    },
    token
      ? {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      : {}
  );
};

const sendEmail = (param: TForgotPassword) => {
  return axios.post<string>(
    BASE_URL + "/api/v1/Users/ForgotPassword",
    { userName: param.userName, softwareCode: param.softwareCode },
    {
      headers: headers,
    }
  );
};

const resetPassword = (params: TResetPassword) => {
  return axios.post(BASE_URL + "/api/v1/Users/ResetPassword", params, {
    headers: headers,
  });
};

const resendEmail = (params: IResendEmail) => {
  return axios.post(
    BASE_URL + "/api/v1/Users/ResendEmailConfirmation",
    params,
    {
      headers: headers,
    }
  );
};

export const authService = {
  login,
  refresh_token,
  sendEmail,
  resetPassword,
  tokenByToken,
  resendEmail,
};
