import { IconButton, Stack } from "@mui/material";
import { memo } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import useCalendar from "../CalendarContext/useCalendar";


const ControlButtons = () => {
  const { next, previous } = useCalendar();

  return (
    <Stack flexDirection={"row"} gap={2}>
      <IconButton onClick={previous}>
        <ChevronLeftIcon />
      </IconButton>
      <IconButton onClick={next}>
        <ChevronRightIcon />
      </IconButton>
    </Stack>
  );
};

export default memo(ControlButtons);
