import { FormColorPicker, useFormContext } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TTicketServiceForm } from "../TicketServiceSchema";
const translationPath = "chatBot.page.register.ticketService.";

export const BadgeColorFields = memo(() => {
  const { t } = useTranslation();
  const { control } = useFormContext<TTicketServiceForm>();
  const showBadgeNameColor = useWatch({ name: "showBadgeNameColor", control });

  if (!showBadgeNameColor) return null;
  return (
    <>
      <Grid item xs={12} sm={6}>
        <FormColorPicker
          name="badgeBackgroundNameColor"
          label={`${t(translationPath + "badge_background_name_color")}`}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormColorPicker
          name="badgeNameColor"
          label={`${t(translationPath + "badge_name_color")}`}
        />
      </Grid>
    </>
  );
});
