import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError,
} from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { removeUserData } from "../../axios/axios-config";

const HOME_PATH = "/";
const LOGIN_PATH = "/login";

const ErrorPage = () => {
  const error = useRouteError();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goHome = () => navigate(HOME_PATH);

  const goToLogin = useCallback(() => {
    navigate(LOGIN_PATH);
    removeUserData()
  }, [navigate]);

  let message = t("page.not_found.page_not_found");
  let buttonLabel = t("page.not_found.back_to_begin");

  if (isRouteErrorResponse(error)) {
    const error207 = error.status === 207;
    if (error207) {
      message = t("page.not_found.server_not_responding");
      buttonLabel = t("page.not_found.try_again");
    }
    return (
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <main>
          <Stack spacing={1}>
            <Typography variant="h2">{error.status}</Typography>
            <Typography variant="h4">{message}</Typography>
            <Typography variant="body1">{error.data}</Typography>
            <Button variant="contained" onClick={goHome}>
              {buttonLabel}
            </Button>
            {error207 && (
              <Button onClick={goToLogin}>
                {t("page.not_found.back_to_login")}
              </Button>
            )}
          </Stack>
        </main>
      </Box>
    );
  }
  return <div>{t("page.not_found.something_went_wrong")}</div>;
};

export default ErrorPage;
