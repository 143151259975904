import { Grid } from "@mui/material";
import { useFieldArray, useWatch } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";

import MainApiService from "../../../../../services/mainApi.service";
import { IGeneric } from "../../../../../models/generics";
import { TRegisterForm } from "../RegisterSchema";
import {
  FormArray,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { IGetSiteAppDomain } from "../../../../../models/site-app";
import AppDomain from "../AppDomain";
import SiteAppDomain from "../SiteAppDomain";

const GENERICS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const service = new MainApiService<IGeneric>(GENERICS_ROUTE);

const translationPath = "page.register.applications.";

const SiteAppDomains = ({
  siteAppDomain,
  disable = false,
}: {
  siteAppDomain?: IGetSiteAppDomain;
  disable?: boolean;
}) => {
  const { control, formState } = useFormContext<TRegisterForm>();
  const { t } = useTranslation();
  const [domainTypes, setDomainTypes] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(service.list);

  const loadDomainTypes = useCallback(async () => {
    const { data, success } = await sendRequest({
      identifier: "SiteAppDomainType",
    });
    if (data && success) {
      setDomainTypes(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    loadDomainTypes();
  }, [loadDomainTypes]);

  const domainsFieldArray = useFieldArray<TRegisterForm>({
    control,
    name: "domains",
  });

  const type = useWatch({
    name: "type",
    control,
  });

  const showAppDomain =
    (type && type.code === "2") ||
    (type && type.code === "3") ||
    (type && type.code === "4");

  const isSiteType = type && type.code === "1";

  return (
    <Grid item xs={12}>
      {siteAppDomain && showAppDomain && (
        <AppDomain
          siteAppDomain={siteAppDomain!}
          siteAppCode={type && type.code ? type.code : ""}
        />
      )}
      {isSiteType && (
        <FormArray
          title={t(translationPath + "domains")}
          name="domains"
          errorMessage={formState.errors?.domains?.message}
          addButtonLabel={t(translationPath + "add_domain") || "Add Domain"}
          formArray={domainsFieldArray}
          showAddButton={disable ? false : true}
          showDeleteButton={disable ? false : true}
          appendValue={{
            domain: "",
            siteAppDomainType: isSiteType ? domainTypes[0] : null,
          }}
          fieldsObject={(index) => {
            const siteAppDomainType = `domains.${index}.siteAppDomainType`;
            const domain = `domains.${index}.domain`;
            return (
              <SiteAppDomain
                domain={domain}
                domainType={siteAppDomainType}
                domainTypes={domainTypes}
                disable={disable}
              />
            );
          }}
        />
      )}
    </Grid>
  );
};

export default SiteAppDomains;
