import { Apps } from "@4uhub/lib4uhub";
import React, { createContext, useContext, useEffect, useState } from "react";

interface IAppContextProps {
  app?: Apps;
  setApp: React.Dispatch<React.SetStateAction<Apps | undefined>>;
}

const AppContext = createContext<IAppContextProps>({
  setApp: () => {},
});

interface IAppProviderProps {
  children: JSX.Element;
}

export const AppProvider: React.FC<IAppProviderProps> = ({ children }) => {
  const [app, setApp] = useState<Apps>();

  return (
    <AppContext.Provider value={{ app, setApp }}>
      {children}
    </AppContext.Provider>
  );
};

interface IAppWrapper {
  name: Apps;
  children: JSX.Element;
}

export const AppWrapper: React.FC<IAppWrapper> = ({ name, children }) => {
  const { setApp } = useApp();

  useEffect(() => {
    setApp(name);
  }, [setApp, name]);

  return children;
};

export const useApp = () => {
  const context = useContext(AppContext);
  return context;
};
