import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { FormContainer, FormInput, useFetch } from "@4uhub/lib4uhub";
import {
  createBusConfig,
  getBusConfigById,
  updateBusConfigById,
} from "../../../../../../services/busConfigs.service";
import { TConfigForm, configSchema } from "./ConfigSchema";

const translationPath = "page.settings.4uBus.configs.";

const Config = () => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(createBusConfig);
  const { sendRequest: update, loading: uLoading } =
    useFetch(updateBusConfigById);
  const { sendRequest: get, loading: gLoading } = useFetch(getBusConfigById);

  const updateHandler = (v: TConfigForm, id: string) =>
    update({
      item: {
        ...v,
      },
      id: id,
    });

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TConfigForm>
      saveFunction={save}
      updateFunction={updateHandler}
      getFunction={get}
      loading={loading}
      title={t(translationPath + "config")}
      schema={configSchema}
      subtitle={t(translationPath + "new_config")}
      subtitleWatchField={"host4uBus"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="host4uBus"
              fullWidth
              label={t(translationPath + "host_4ubus") || "Host 4uBus"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="hostAws"
              fullWidth
              label={t(translationPath + "host_aws") || "Host AWS"}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default Config;
