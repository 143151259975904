import { createContext } from "react";
import { ECalendarMode, IBaseCalendar } from "../models";
import { ICalendar } from "../../model";

interface ICalendarContext extends IBaseCalendar {
  mode: ECalendarMode;
  direction: number;
  goToToday: () => void;
  next: () => void;
  previous: () => void;
  getActualDate: () => string;
  onModeChange: (m: ECalendarMode) => void;
  goToDay: (date: Date, mode?: ECalendarMode) => void;
  onEventClick?: (event: ICalendar) => void;
}

export const CalendarContext = createContext<ICalendarContext | undefined>(
  undefined
);
