import { memo, useMemo, useState } from "react";
import {
  TButtonsList,
  TemplateWhatsProvider,
} from "../../../../../store/contexts/TemplateWhatsContext";
import { Divider, Grid, useMediaQuery, useTheme } from "@mui/material";
import { WhatsappTemplateData } from "./whatsappTemplateData";
import { Button, ISelectType, Modal, useFormContext } from "@4uhub/lib4uhub";
import { WhatsappPreviewModal } from "./WhatsappPreviewModal";
import { WhatsappBasicData } from "./WhatsappBasicData";
import { WhatsappSection } from "../../../../../components/WhatsappTemplate/WhatsappSection/WhatsappSection";
import { ButtonsField } from "../../../../../components/WhatsappTemplate/Buttons/ButtonsField";
import { WhatsappMessagePreview } from "./WhatsappMessagePreview";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTranslation } from "react-i18next";

const translationPath = "chatBot.page.templates.whatsapp.";

export const WhatsappFields = memo(
  ({
    body,
    buttonsOrder,
    status,
    onGetButtonsOrder,
  }: {
    body: string;
    buttonsOrder: TButtonsList[];
    status?: ISelectType;
    onGetButtonsOrder: (list: TButtonsList[]) => void;
  }) => {
    const { t } = useTranslation();

    const { getValues } = useFormContext();

    const [open, setOpen] = useState(false);

    const openPreviewHandler = () => setOpen(true);
    const closePreviewHandler = () => setOpen(false);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("lg"));

    const ctaButtons = useMemo(() => getValues("ctaButtons"), [getValues]);

    const customButtons = useMemo(
      () => getValues("customButtons"),
      [getValues]
    );

    return (
      <TemplateWhatsProvider
        buttonsOrder={buttonsOrder}
        onGetButtonsOrder={onGetButtonsOrder}
        defaultCtaButtons={ctaButtons}
        defaultCustomButtons={customButtons}
        defaultbody={body}
      >
        <Grid container mb={10}>
          <WhatsappTemplateData status={status} />

          <Divider sx={{ my: 4, width: "100%" }} />

          <Grid
            item
            container
            spacing={2}
            xs={12}
            lg={12}
            xl={9}
            paddingRight={matches ? 0 : 2}
          >
            <Modal
              open={open}
              onClose={closePreviewHandler}
              title={`${t(translationPath + "message_preview")}`}
            >
              <WhatsappPreviewModal />
            </Modal>

            {matches && (
              <Grid item xs={12} sm={12} textAlign={"end"}>
                <Button
                  variant="contained"
                  startIcon={<VisibilityIcon />}
                  onClick={openPreviewHandler}
                >
                  {t(translationPath + "preview_button")}
                </Button>
              </Grid>
            )}

            <WhatsappBasicData />

            <Grid item xs={12} sm={12}>
              <WhatsappSection
                title={t(translationPath + "buttons")}
                subtitle={t(translationPath + "buttons_sub")}
                chipLabel={`${t(translationPath + "optional")}`}
              >
                <ButtonsField />
              </WhatsappSection>
            </Grid>
          </Grid>

          <WhatsappMessagePreview />
        </Grid>
      </TemplateWhatsProvider>
    );
  }
);
