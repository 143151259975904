import {
  AutoCompleteMulti,
  DateInput,
  FormInput,
  ISelectType,
  SwitchInput,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Box, Grid, Typography } from "@mui/material";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import MainApi from "../../../../../../../services/mainApi.service";
import { useAutomationRules } from "../../../../../../../store/contexts/AutomationRulesContext";
import { Actions } from "./Actions/Actions";
import { TMessageForm } from "./messageSchema";
import { SelectEstablishment } from "./SelectEstablishment";
import { SelectEvaluationForm } from "./SelectEvaluationForm";

const TASY_SCHEDULE_RECORDS_ROUTE =
  process.env.REACT_APP_AUTOMATIONS + "/api/v1/TasyScheduleRecord/";
const tasyScheduleRecordsService = new MainApi<ISelectType>(
  TASY_SCHEDULE_RECORDS_ROUTE
);

const TASY_SCHEDULE_CLASSIFICATIONS_ROUTE =
  process.env.REACT_APP_AUTOMATIONS + "/api/v1/TasyScheduleClassification/";
const tasyScheduleClassificationsService = new MainApi<ISelectType>(
  TASY_SCHEDULE_CLASSIFICATIONS_ROUTE
);

const TASY_SCHEDULE_TYPES_ROUTE =
  process.env.REACT_APP_AUTOMATIONS + "/api/v1/TasyScheduleType/";
const tasyScheduleTypesService = new MainApi<ISelectType>(
  TASY_SCHEDULE_TYPES_ROUTE
);

const TASY_HEALTH_INSURANCES_ROUTE =
  process.env.REACT_APP_AUTOMATIONS + "/api/v1/TasyHealthInsurance/";
const tasyHealthInsurancesService = new MainApi<ISelectType>(
  TASY_HEALTH_INSURANCES_ROUTE
);

const translationPath = "components.messages.";

export const MessageFields = memo(() => {
  const { t } = useTranslation();

  const { automation } = useAutomationRules();

  const { formState } = useFormContext<TMessageForm>();

  const automationCode = useMemo(
    () => (automation && automation.code ? automation.code : null),
    [automation]
  );

  const exceptionError = !!formState?.errors?.exception;

  const tasyScheduleTypeIntegrationId = useMemo(() => {
    if (!automationCode) return;

    switch (automationCode) {
      case "1":
      case "2":
      case "9":
        return 3;
      case "4":
        return 1;
      case "5":
      case "6":
      case "10":
        return 2;
      default:
        return null;
    }
  }, [automationCode]);

  const params = useMemo(() => {
    return { tasyScheduleTypeIntegrationId };
  }, [tasyScheduleTypeIntegrationId]);

  return (
    <Box sx={{ pl: 2 }}>
      <Grid
        container
        spacing={2}
        sx={(t) => ({
          pr: 3,
          pl: 1,
          py: 3,
          border: `1px solid ${
            exceptionError ? t.palette.error.main : t.palette.divider
          }`,
          borderRadius: 2,
        })}
      >
        <Grid item sm={12}>
          <Typography fontWeight="bold">{t(translationPath + "if")}</Typography>
        </Grid>

        <Grid item sm={12} textAlign={"end"}>
          <SwitchInput name="enable" label={t(translationPath + "enable")} />
          <SwitchInput
            name="exception"
            label={t(translationPath + "exception")}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormInput
            size="small"
            name="name"
            fullWidth
            label={t(translationPath + "name") || "Name"}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DateInput
            name="initialDate"
            label={t(translationPath + "initial_date")}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DateInput name="endDate" label={t(translationPath + "end_date")} />
        </Grid>

        {exceptionError && (
          <Grid item xs={12} sm={12}>
            <Typography color="error" variant="body2">
              {t(`${translationPath}warning`)}
            </Typography>
          </Grid>
        )}

        {automation &&
          ["1", "2", "3", "4", "5", "6", "9", "10"].includes(
            String(automationCode)
          ) && <SelectEstablishment automationCode={String(automationCode)} />}

        {automation && ["9", "10"].includes(String(automationCode)) && (
          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="qtyPacientSent"
              fullWidth
              label={
                t(translationPath + "qty_pacient_sent") ||
                "Number of patients to send"
              }
            />
          </Grid>
        )}

        {automationCode &&
          ["1", "2", "4", "5", "6", "9", "10"].includes(
            String(automationCode)
          ) && (
            <Grid item xs={12} sm={12}>
              <AutoCompleteMulti
                params={params}
                fullWidth
                name="tasyScheduleRecords"
                label={t(translationPath + "schedule_records")}
                getOptionLabel={(option) => option.name}
                size="small"
                request={tasyScheduleRecordsService.list}
              />
            </Grid>
          )}

        {automationCode &&
          ["1", "2", "5", "6", "9", "10"].includes(String(automationCode)) && (
            <Grid item xs={12} sm={12}>
              <AutoCompleteMulti
                params={params}
                name="tasyScheduleClassifications"
                label={t(translationPath + "schedule_classifications")}
                getOptionLabel={(option) => option.name}
                size="small"
                fullWidth
                request={tasyScheduleClassificationsService.list}
              />
            </Grid>
          )}

        {automationCode && automationCode === "3" && (
          <Grid item xs={12} sm={12}>
            <AutoCompleteMulti
              name="tasyScheduleTypes"
              label={t(translationPath + "schedule_types")}
              getOptionLabel={(option) => option.name}
              size="small"
              fullWidth
              request={tasyScheduleTypesService.list}
            />
          </Grid>
        )}

        {automation &&
          ["1", "2", "3", "4", "5", "6", "9", "10"].includes(
            String(automationCode)
          ) && (
            <Grid item xs={12} sm={12}>
              <AutoCompleteMulti
                name="tasyHealthInsurances"
                label={t(translationPath + "schedule_health_insurances")}
                getOptionLabel={(option) => option.name}
                size="small"
                fullWidth
                request={tasyHealthInsurancesService.list}
              />
            </Grid>
          )}
      </Grid>

      <Grid
        container
        spacing={2}
        sx={(t) => ({
          mt: 5,
          pr: 3,
          pl: 1,
          py: 3,
          border: `1px solid ${t.palette.divider}`,
          borderRadius: 2,
        })}
      >
        <Grid item sm={12}>
          <Typography fontWeight="bold">
            {t(translationPath + "then")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <SwitchInput name="send" label={t(translationPath + "send")} />
        </Grid>
        <SelectEvaluationForm />
      </Grid>

      {automation && ["1", "5"].includes(String(automationCode)) && <Actions />}
    </Box>
  );
});
