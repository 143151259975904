import {
  FormArray,
  FormInput,
  ISelectType,
  useFormContext,
} from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import { useFieldArray } from "react-hook-form";
import { Grid, Typography } from "@mui/material";
import WorkGroupLang from "./WorkGroupLang";
import { useState } from "react";
import { TWorkGroupForm } from "./WorkGroupSchema";

const translationPath = "page.4uHcp.hcp.workGroups.";

interface IWorkGroupLanguagesProps {
  allLanguages: ISelectType[];
}

const WorkGroupLanguages = ({ allLanguages }: IWorkGroupLanguagesProps) => {
  const { t } = useTranslation();

  const { control, formState } = useFormContext<TWorkGroupForm>();

  const [defaultLang, setDefaultLang] = useState<ISelectType>();

  const workGroupLanguagesArray = useFieldArray<
    TWorkGroupForm,
    "healthProgramWorkGroupLanguages",
    "key"
  >({
    control,
    name: "healthProgramWorkGroupLanguages",
    keyName: "key",
  });

  return (
    <>
      {formState.errors?.healthProgramWorkGroupLanguages &&
        workGroupLanguagesArray.fields.length === 0 && (
          <Typography variant="body2" color={"error"}>
            {formState.errors?.healthProgramWorkGroupLanguages?.root?.message
              ? t(
                  `${formState.errors?.healthProgramWorkGroupLanguages?.root?.message}`
                )
              : t(
                  `${formState.errors?.healthProgramWorkGroupLanguages?.message}`
                )}
          </Typography>
        )}
      <FormArray
        title={""}
        name="healthProgramWorkGroupLanguages"
        addButtonLabel={t(translationPath + "add_language") || "Add Language"}
        formArray={workGroupLanguagesArray}
        showAddButton={
          workGroupLanguagesArray.fields.length !== 0 && !defaultLang
            ? false
            : true
        }
        appendValue={{
          name: "",
          description: "",
          language: defaultLang ? defaultLang : allLanguages[0],
        }}
        fieldsObject={(index) => {
          const name = `healthProgramWorkGroupLanguages.${index}.name`;
          const description = `healthProgramWorkGroupLanguages.${index}.description`;
          const language = `healthProgramWorkGroupLanguages.${index}.language`;

          return (
            <>
              <Grid item xs={12} sm={6}>
                <FormInput
                  name={name}
                  size="small"
                  label={t(translationPath + "name")}
                  fullWidth
                />
              </Grid>

              <WorkGroupLang
                index={index}
                name={language}
                allLanguages={allLanguages}
                loadDefaultLang={setDefaultLang}
              />

              <Grid item sm={12}>
                <FormInput
                  name={description}
                  size="small"
                  label={t(translationPath + "description")}
                  fullWidth
                  multiline
                  minRows={4}
                />
              </Grid>
            </>
          );
        }}
      />
    </>
  );
};

export default WorkGroupLanguages;
