import { RouteObject } from "react-router-dom";
import Schedule from "../pages/Schedule/Schedule";
import NewSchedule from "../pages/Schedule/NewSchedule/NewSchedule";
import WelcomePage from "../pages/welcomePage";

const sched: RouteObject[] = [
  {
    index: true,
    element: <WelcomePage/>,
  },
  {
    path: "calendar/tasyCalendar",
    element: <Schedule />,
  },
  {
    path: "calendar/tasyCalendar/new/:id",
    element: <NewSchedule />,
  },
];

export default sched;
