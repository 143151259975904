import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectYears, useFetch } from "@4uhub/lib4uhub";

import useNationalHolidays from "../../hooks/useNationalHolidays";
import HolidaysService from "../../services/holidays.service";
import { INationalHolidays } from "../../models/holidays";
import HolidaysList from "./HolidaysList";

const holidaysService = new HolidaysService();

const translationPath = "components.nationalHolidays.";

const NationalHolidays = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const actualYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(actualYear);
  const { holidays, onLoadHolidays, loading, setHolidays } =
    useNationalHolidays();

  const { sendRequest: save, loading: importLoading } = useFetch(
    holidaysService.create
  );

  useEffect(() => {
    onLoadHolidays(selectedYear);
  }, [onLoadHolidays, selectedYear]);

  const formatDate = (date: string) => {
    const dateString = date;
    const dateParts = dateString.split("-");
    const dateFormated = new Date(
      +dateParts[0],
      +dateParts[1] - 1,
      +dateParts[2]
    );
    return dateFormated;
  };

  const saveHoliday = useCallback(
    async (holiday: INationalHolidays) => {
      const { success } = await save({
        date: new Date(formatDate(holiday.date)).toISOString(),
        description: holiday.name,
        name: holiday.name,
        holidayTypeCode: "1",
      });
      if (!importLoading && success) {
        onClose();
      }
    },
    [onClose, save, importLoading]
  );

  const importHolidays = useCallback(async () => {
    holidays
      .filter((holiday) => holiday.checked === true)
      .map((holiday) => {
        return saveHoliday(holiday);
      });
  }, [holidays, saveHoliday]);

  return (
    <Box>
      <Typography variant="h6" mb={4}>
        {t(translationPath + "import_holidays")}
      </Typography>
      <Stack mb={4}>
        <SelectYears
          initialYear={actualYear}
          label="Selecione um ano"
          lastYear={actualYear + 4}
          onChangeHandler={(e) => setSelectedYear(+e.target.value)}
          value={selectedYear}
        />
      </Stack>
      <HolidaysList
        holidays={holidays}
        setHolidays={setHolidays}
        loading={loading}
        formatDate={formatDate}
      />
      <Button
        fullWidth
        size="small"
        variant="contained"
        sx={{ mt: 4 }}
        onClick={importHolidays}
        disabled={
          importLoading || !holidays.some((holiday) => holiday.checked === true)
        }
      >
        {importLoading && <CircularProgress size={20} />}
        {!importLoading && t(translationPath + "import_holidays")}
      </Button>
    </Box>
  );
};

export default NationalHolidays;
