import { memo } from "react";
import { BodyField } from "./BodyField/BodyField";
import { FormMessageProvider } from "../../store/contexts/FormMessageContext";
import { IFormMessageProps } from "./models";
import { useFormContext } from "@4uhub/lib4uhub";

export const FormMessage = memo(
  ({ name, label, automationId, showVariables = true }: IFormMessageProps) => {
    const { getValues } = useFormContext();

    return (
      <FormMessageProvider defaultbody={getValues(`${name}`) || ""}>
        <BodyField
          automationId={automationId ?? ""}
          name={name}
          label={label}
          showVariables={showVariables}
        />
      </FormMessageProvider>
    );
  }
);
