import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const NoAccessPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams();


  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <main style={{ width: !matches ? "50%" : "100%" }}>
        <Stack spacing={1} alignItems={"center"}>
          <Typography variant="h5" mb={2}>
            {t("page.noAccess.message")}
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate(`/e/${urlParams.eI}/`)}
          >
            {t("page.noAccess.button")}
          </Button>
        </Stack>
      </main>
    </Box>
  );
};

export default NoAccessPage;
