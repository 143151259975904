import { Controller } from "react-hook-form";
import { Box } from "@mui/material";
import { useFormContext } from "@4uhub/lib4uhub";

import SelectIcon from "./SelectIcon";
import { IItem, ISelectIconForm } from "./model";
import { genericMemo } from "../SubFormArray/genericMemo";
import FormErrorMessage from "../FormErrorMessage";

const SelectIconForm = <T extends IItem>({
  name,
  options,
  label,
  multi,
  addIcon,
}: ISelectIconForm<T>) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <Box>
            <SelectIcon
              options={options}
              onChange={onChange}
              multi={multi}
              value={value}
              addIcon={addIcon}
              label={label}
            />
            {error?.message && <FormErrorMessage message={error.message} />}
          </Box>
        );
      }}
    />
  );
};

export default genericMemo(SelectIconForm);
