import { Stack } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { Loading, Modal, useFetch } from "@4uhub/lib4uhub";

import DashBoardService from "../../../../services/dashboard.service";
import { IListIDashboard } from "../../../../models/dashboard";
import DashBoardModalItem from "./DashboardModalItem";
import { usePanel } from "../../../../store/contexts/DashBoardPannelContext";
import { MIN_HEIGTH, MIN_WIDTH } from "../../Panel/Layout";
import { useTranslation } from "react-i18next";

interface IDashBoardModalProps {
  open: boolean;
  onClose: () => void;
}

const service = new DashBoardService();

const DashBoardModal: React.FC<IDashBoardModalProps> = ({ open, onClose }) => {
  const { sendRequest, loading } = useFetch(service.list);

  const { sendRequest: create } = useFetch(service.create);

  const {t} = useTranslation()

  const [dashboards, setDashboards] = useState<IListIDashboard[]>([]);

  const {
    languageId,
    panel: { id: dashBoardPanelId },
    onAddDashboard,
  } = usePanel();

  const fetchDashBoards = useCallback(async () => {
    const { data, success } = await sendRequest(languageId);

    if (data && success) {
      setDashboards(data);
    }
  }, [sendRequest, languageId]);

  useEffect(() => {
    if (open) {
      fetchDashBoards();
    }
  }, [open, fetchDashBoards]);

  const onClickHandler = useCallback(
    async (id: string) => {
      const { data, success } = await create({
        dashboardId: id,
        dashboardPanelId: dashBoardPanelId,
        languageId: languageId,
        height: String(MIN_HEIGTH),
        width: String(MIN_WIDTH),
        xPosition: "0",
        yPosition: "0",
      });

      if (data && success) {
        onClose();
        onAddDashboard(data);
      }
    },
    [dashBoardPanelId,languageId, onClose, create, onAddDashboard]
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      maxDialogWidth="sm"
      title={t("components.panel.add_dashboard") + ''}
    >
      {loading && <Loading sx={{ position: "relative" }} size={50} />}
      {!loading && (
        <Stack maxHeight={300} overflow={"auto"}>
          {dashboards.map((d) => (
            <DashBoardModalItem data={d} onClick={onClickHandler} key={d.id}/>
          ))}
        </Stack>
      )}
    </Modal>
  );
};

export default memo(DashBoardModal);
