import ReactPlayer from "react-player";
import { TContentForm } from "../../ContentSchema";
import { memo } from "react";
import { Control, useWatch } from "react-hook-form";
import { Box } from "@mui/material";

interface IVideoPreviewProps {
  index: number;
  mainIndex: number;
  control: Control<TContentForm>;
}

const VideoPreview: React.FC<IVideoPreviewProps> = ({
  index,
  mainIndex,
  control,
}) => {
  const url = useWatch({
    control,
    name: `contents.${mainIndex}.contentVideos.${index}.link`,
  });

  if (!url) {
    return null;
  }

  return (
    <Box sx={{ aspectRatio: "16/9", borderRadius: 1, overflow: "hidden" }}>
      {url && (
        <ReactPlayer
          url={url}
          controls={true}
          width="100%"
          height="100%"
          light
        />
      )}
    </Box>
  );
};

export default memo(VideoPreview);
