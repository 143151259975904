import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../services/mainApi.service";
import { IGetCompany } from "../../../models/companies";
import { Table } from "@4uhub/lib4uhub";

const COMPANIES_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Company/";
const companiesService = new MainApiService<IGetCompany>(COMPANIES_ROUTE);

const translationPath = "page.register.companies.";

const CompaniesPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columns: GridColDef<IGetCompany>[] = [
    {
      field: "code",
      headerName: t(translationPath + "code") || "Code",
      maxWidth: 100,
    },
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
    },
    {
      field: "companyGroup",
      headerName: t(translationPath + "company_group") || "Company Group",
      renderCell: (params) => params.value.name,
    },
  ];

  const handleRowClick = (params: GridRowParams<IGetCompany>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  return (
    <Table<IGetCompany>
      showDefaultMacroFilters={false}
      service={companiesService}
      columns={columns}
      title={t(translationPath + "companies")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={
        t(translationPath + "search_company") || "Searh for a company"
      }
      addButtonLabel={t(translationPath + "add_company") || "Add Company"}
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default CompaniesPage;
