import { z } from "zod";

const translationPath = "components.settingsGeneral.errors.";

export const settingsCorreiosSchema = z.object({
  correiosUrl: z.string().min(1, translationPath + "correios_url"),
  correiosTokenUser: z.string().min(1, translationPath + "correios_token_user"),
  correiosTokenAccessCode: z.string().min(1, translationPath + "access_token"),
  correiosTokenPostCard: z.string().min(1, translationPath + "post_card"),
  correiosAuthenticationUrl: z
    .string()
    .min(1, translationPath + "correios_authentication"),
  correiosLocationsUrl: z
    .string()
    .min(1, translationPath + "correios_locations"),
  correiosAddressesUrl: z
    .string()
    .min(1, translationPath + "correios_addresses"),
  cepCacheTime: z.string().min(1, translationPath + "cep_cache_time"),
  correiosPageSize: z.string().min(1, translationPath + "cep_cache_time"),
});

export type TSettingsCorreiosForm = z.infer<typeof settingsCorreiosSchema>;
