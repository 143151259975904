import { useTranslation } from "react-i18next";

import { AutoComplete } from "@4uhub/lib4uhub";

import { listContractTypes } from "../../../../../../../services/salesContractType.service";

const translationsPath = "page.4uSalesContract.registers.contractProducts.";
const SalesContractTypeSelect = () => {
  const { t } = useTranslation();

  return (
    <AutoComplete
      name="salesContractType"
      label={t(translationsPath + "products.sales_contract_type")}
      size="small"
      getOptionLabel={({ name }) => name}
      request={listContractTypes}
    />
  );
};

export default SalesContractTypeSelect;
