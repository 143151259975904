import { Grid } from "@mui/material";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { GlobalAxios } from "../../../../../axios/axios-config";
import { ISiteAppFileValueProps } from "./models";
import { InputFileForm, useFormContext } from "@4uhub/lib4uhub";

const FILE_ROUTE = process.env.REACT_APP_FILEMANAGER + "/api/v1/Upload";

const SiteAppFileValue: React.FC<ISiteAppFileValueProps> = ({
  index,
  onFileSelected,
  disable = false,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const mediaTypeCode = useWatch({
    name: `siteAppMediaFiles.${index}.mediaType.code`,
    control,
  });

  return (
    <Grid item xs={12} sm={8}>
      <InputFileForm
        name={`siteAppMediaFiles.${index}.file`}
        axios={GlobalAxios}
        fileRoute={FILE_ROUTE}
        fileInputProps={{
          accept: ".jpg, ,jpeg, .png, .webp",
          label: `${t("page.register.extensions.file")}`,
          maxFileSize: 2,
          mediaTypeCode: mediaTypeCode,
          mediaTypeIdentifier: "SiteAppMediaType",
          parentName: "SiteApp",
          type: "Public",
          onFileSelected: onFileSelected,
          inputProps: {
            size: "small",
          },
        }}
        disable={disable}
      />
    </Grid>
  );
};

export default SiteAppFileValue;
