import { GlobalAxios } from "../axios/axios-config";
import {
  IHealthProgramType,
  INewHealthProgramType,
} from "../models/health-programs";

const HEALTH_PROGRAM_TYPE_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/HealthProgramType/";

export const createHealthProgramType = (item: INewHealthProgramType) => {
  return GlobalAxios.post(HEALTH_PROGRAM_TYPE_ROUTE, item);
};

export const getHealthProgramTypeById = (id: string) => {
  return GlobalAxios.get<IHealthProgramType>(HEALTH_PROGRAM_TYPE_ROUTE + id);
};

export const updateHealthProgramTypeById = ({
  item,
  id,
}: {
  item: INewHealthProgramType;
  id: string;
}) => {
  return GlobalAxios.put(HEALTH_PROGRAM_TYPE_ROUTE + id, item);
};
