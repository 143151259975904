import { GlobalAxios } from "../axios/axios-config";
import { TLog, TParams } from "../models/logs";

const BASE_URL = process.env.REACT_APP_MAIN;

const getLogs = (params: TParams) => {
  return GlobalAxios.get<TLog[]>(
    BASE_URL + "/api/v1/AuditEvents",
    { params: params }
  );
};

export const logsService = {
  getLogs,
};
