import { Grid } from "@mui/material";
import { useFieldArray } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { TDocumentsForm } from "./DocumentsSchema";
import { AxiosResponse } from "axios";

import {
  FormArray,
  FormInput,
  ISelectType,
  useFormContext,
} from "@4uhub/lib4uhub";
import FormTextEditor from "../../../../../components/UI/TextEditor/FormTextEditor";
import DocumentLanguage from "./DocumentLanguage";
import { useCallback, useState } from "react";

const translation_path = "page.register.documents.create_document.";

interface IDocumentFormProps {
  service: (params?: any) => Promise<AxiosResponse<ISelectType[]>>;
  defaultLanguage?: ISelectType[];
}

const DocumentForm: React.FC<IDocumentFormProps> = ({
  service,
  defaultLanguage,
}) => {
  const { control, formState } = useFormContext<TDocumentsForm>();
  const { t } = useTranslation();
  const [defaultLang, setDefaultLang] = useState<ISelectType>();

  const documentsFieldArray = useFieldArray<TDocumentsForm>({
    control,
    name: "docAcceptanceDocuments",
  });

  const loadDefaultLang = useCallback((lang: ISelectType) => {
    setDefaultLang(lang);
  }, []);

  return (
    <Grid item xs={12}>
      <FormArray
        title={t(translation_path + "new_document")}
        name="docAcceptanceDocuments"
        showAddButton={!defaultLang ? false : true}
        errorMessage={formState.errors?.docAcceptanceDocuments?.message}
        addButtonLabel={
          t(translation_path + "add_new_language") || "Add new language"
        }
        formArray={documentsFieldArray}
        appendValue={{
          language: defaultLang ? defaultLang : null,
          content: "",
          name: "",
        }}
        fieldsObject={(index) => {
          const language = `docAcceptanceDocuments.${index}.language`;
          const content = `docAcceptanceDocuments.${index}.content`;
          const name = `docAcceptanceDocuments.${index}.name`;

          return (
            <>
              <DocumentLanguage
                index={index}
                name={language}
                service={service}
                loadDefaultLang={loadDefaultLang}
              />
              <Grid item xs={12} sm={6}>
                <FormInput
                  size="small"
                  name={name}
                  label={t(translation_path + "name") || "Name"}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormTextEditor name={content} />
              </Grid>
            </>
          );
        }}
      />
    </Grid>
  );
};

export default DocumentForm;
