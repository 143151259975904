import { useFetch } from "@4uhub/lib4uhub";
import { Autocomplete, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { IYear } from "../../models/holidays";
import HolidaysService from "../../services/holidays.service";
import { useTranslation } from "react-i18next";

const yearsService = new HolidaysService();

const translationPath = "components.yearSelect.";

interface IYearSelectProps {
  onSelectYear: (year: string) => void;
  maxWidth?: string | number;
}

const YearSelect = ({ onSelectYear, maxWidth }: IYearSelectProps) => {
  const { t } = useTranslation();

  const [yearsList, setYearsList] = useState<IYear[]>([]);
  const { sendRequest } = useFetch(yearsService.yearsList);

  const loadYearsList = useCallback(async () => {
    const { data, success } = await sendRequest(undefined);
    if (data && success) {
      setYearsList(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    loadYearsList();
  }, [loadYearsList]);

  return (
    <Autocomplete
      size="small"
      fullWidth
      onChange={(e, v) => {
        if (v) {
          onSelectYear(v);
        } else {
          onSelectYear("");
        }
      }}
      renderInput={(params) => (
        <TextField {...params} label={t(translationPath + "label")} fullWidth />
      )}
      options={yearsList.map((y) => y.year)}
      sx={{ maxWidth: maxWidth ?? 150 }}
    />
  );
};

export default YearSelect;
