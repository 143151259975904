import { DomainProvider } from "@4uhub/lib4uhub";
import { RouteObject } from "react-router-dom";

import WelcomePage from "../pages/welcomePage";

import Grantor from "../pages/4uSalesContract/Register/Grantors/GrantorForm/Grantor";
import GrantorsPage from "../pages/4uSalesContract/Register/Grantors/GrantorsPage";

import ContractAttachmentType from "../pages/4uSalesContract/Register/ContractAttachmentTypes/ContractAttachmentTypesForm/ContractAttachmentType";
import ContractAttachmentTypesPage from "../pages/4uSalesContract/Register/ContractAttachmentTypes/ContractAttachmentTypesPage";

import ContractTerminalReason from "../pages/4uSalesContract/Register/ContractTerminationReason/ContractTerminalReasonForm/ContractTerminationReason";
import ContractTerminationReasonPage from "../pages/4uSalesContract/Register/ContractTerminationReason/ContractTerminationReasonPage";

import ChannelClassification from "../pages/4uSalesContract/Register/ChannelClassifications/ChannelClassificationsForm/ChannelClassification";
import ChannelClassificationsPage from "../pages/4uSalesContract/Register/ChannelClassifications/ChannelClassificationsPage";

import ContractProductClassification from "../pages/4uSalesContract/Register/ContractProductClassification/ContractProductClassificationForm/ContractProductClassification";
import ContractProductClassificationPage from "../pages/4uSalesContract/Register/ContractProductClassification/ContractProductClassificationPage";

import BeneficiaryClassification from "../pages/4uSalesContract/Register/BeneficiaryClassifications/BeneficiaryClassificationsForm/BeneficiaryClassification";
import BeneficiaryClassificationsPage from "../pages/4uSalesContract/Register/BeneficiaryClassifications/BeneficiaryClassificationsPage";

import ContractProduct from "../pages/4uSalesContract/Register/ContractProducts/ContractProductsForm/ContractProduct/ContractProduct";
import ContractProductsPage from "../pages/4uSalesContract/Register/ContractProducts/ContractProductsPage";

import BeneficiaryTerminationReason from "../pages/4uSalesContract/Register/BeneficiaryTerminationReason/BeneficiaryTerminationReasonForm/BeneficiaryTerminationReason";
import BeneficiaryTerminationReasonPage from "../pages/4uSalesContract/Register/BeneficiaryTerminationReason/BeneficiaryTerminationReasonPage";

import ContractClassification from "../pages/4uSalesContract/Register/ContractClassifications/ContractClassificationsForm/ContractClassification";
import ContractClassificationsPage from "../pages/4uSalesContract/Register/ContractClassifications/ContractClassificationsPage";

import BeneficiaryCancellationReason from "../pages/4uSalesContract/Register/BeneficiaryCancellationsReason/BeneficiaryCancellationReasonForm/BeneficiaryCancellationReason";
import BeneficiaryCancellationReasonPage from "../pages/4uSalesContract/Register/BeneficiaryCancellationsReason/BeneficiaryCancellationsReason";
import BeneficiarySuspensionReasonPage from "../pages/4uSalesContract/Register/BeneficiarySuspensionsReason/BeneficiarySuspensionsReason";
import BeneficiarySuspensionReason from "../pages/4uSalesContract/Register/BeneficiarySuspensionsReason/BeneficiarySuspensionsReasonForm/BeneficiarySuspensionReason";
import SalesChannelPage from "../pages/4uSalesContract/Register/SalesChannel/SalesChannelPage";

import ContractsPage from "../pages/4uSalesContract/Contracts/ContractsPage";
import ContractTabs from "../pages/4uSalesContract/Contracts/Tabs";
import SalesChannel from "../pages/4uSalesContract/Register/SalesChannel/SalesChannelForm/SalesChannel";

const salesContractRoutes: RouteObject[] = [
  {
    index: true,
    element: <WelcomePage />,
  },
  {
    path: "register/salesGrantors",
    element: (
      <DomainProvider value={{ domainName: "salesgrantor" }}>
        <GrantorsPage />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesGrantors/new",
    element: (
      <DomainProvider
        value={{ domainName: "salesgrantor" }}
        actions={["Create"]}
      >
        <Grantor />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesGrantors/:id/edit",
    element: (
      <DomainProvider
        value={{ domainName: "salesgrantor" }}
        actions={["Update"]}
      >
        <Grantor />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesContractAttachmentTypes",
    element: (
      <DomainProvider value={{ domainName: "salescontractattachmenttype" }}>
        <ContractAttachmentTypesPage />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesContractAttachmentTypes/new",
    element: (
      <DomainProvider
        value={{ domainName: "salescontractattachmenttype" }}
        actions={["Create"]}
      >
        <ContractAttachmentType />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesContractAttachmentTypes/:id/edit",
    element: (
      <DomainProvider
        value={{ domainName: "salescontractattachmenttype" }}
        actions={["Update"]}
      >
        <ContractAttachmentType />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesContractTerminationReason",
    element: (
      <DomainProvider value={{ domainName: "salescontractterminationreason" }}>
        <ContractTerminationReasonPage />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesContractTerminationReason/new",
    element: (
      <DomainProvider
        value={{ domainName: "salescontractterminationreason" }}
        actions={["Create"]}
      >
        <ContractTerminalReason />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesContractTerminationReason/:id/edit",
    element: (
      <DomainProvider
        value={{ domainName: "salescontractterminationreason" }}
        actions={["Update"]}
      >
        <ContractTerminalReason />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesContractClassifications",
    element: (
      <DomainProvider value={{ domainName: "salescontractclassification" }}>
        <ContractClassificationsPage />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesContractClassifications/new",
    element: (
      <DomainProvider
        value={{ domainName: "salescontractclassification" }}
        actions={["Create"]}
      >
        <ContractClassification />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesContractClassifications/:id/edit",
    element: (
      <DomainProvider
        value={{ domainName: "salescontractclassification" }}
        actions={["Update"]}
      >
        <ContractClassification />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesContractBeneficiaryClassifications",
    element: (
      <DomainProvider
        value={{ domainName: "salescontractbeneficiaryclassification" }}
      >
        <BeneficiaryClassificationsPage />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesContractBeneficiaryClassifications/new",
    element: (
      <DomainProvider
        value={{ domainName: "salescontractbeneficiaryclassification" }}
        actions={["Create"]}
      >
        <BeneficiaryClassification />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesContractBeneficiaryClassifications/:id/edit",
    element: (
      <DomainProvider
        value={{ domainName: "salescontractbeneficiaryclassification" }}
        actions={["Update"]}
      >
        <BeneficiaryClassification />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesProductCtrClassifications",
    element: (
      <DomainProvider value={{ domainName: "salesproductctrclassification" }}>
        <ContractProductClassificationPage />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesProductCtrClassifications/new",
    element: (
      <DomainProvider
        value={{ domainName: "salesproductctrclassification" }}
        actions={["Create"]}
      >
        <ContractProductClassification />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesProductCtrClassifications/:id/edit",
    element: (
      <DomainProvider
        value={{ domainName: "salesproductctrclassification" }}
        actions={["Update"]}
      >
        <ContractProductClassification />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesChannelClassifications",
    element: (
      <DomainProvider value={{ domainName: "saleschannelclassification" }}>
        <ChannelClassificationsPage />
      </DomainProvider>
    ),
  },
  {
    path: "salesContract/SalesContractClubAbs",
    element: (
      <DomainProvider value={{ domainName: "salescontract" }}>
        <ContractsPage />
      </DomainProvider>
    ),
  },
  {
    path: "salesContract/SalesContractClubAbs/new",
    element: (
      <DomainProvider
        value={{ domainName: "salescontract" }}
        actions={["Create"]}
      >
        <ContractTabs />
      </DomainProvider>
    ),
  },
  {
    path: "salesContract/SalesContractClubAbs/:id/edit",
    element: (
      <DomainProvider
        value={{ domainName: "salescontract" }}
        actions={["Update"]}
      >
        <ContractTabs />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesChannelClassifications/new",
    element: (
      <DomainProvider
        value={{ domainName: "saleschannelclassification" }}
        actions={["Create"]}
      >
        <ChannelClassification />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesChannelClassifications/:id/edit",
    element: (
      <DomainProvider
        value={{ domainName: "saleschannelclassification" }}
        actions={["Update"]}
      >
        <ChannelClassification />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesProductCtrs",
    element: (
      <DomainProvider value={{ domainName: "salesproductctr" }}>
        <ContractProductsPage />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesProductCtrs/new",
    element: (
      <DomainProvider
        value={{ domainName: "salesproductctr" }}
        actions={["Create"]}
      >
        <ContractProduct />
      </DomainProvider>
    ),
  },

  {
    path: "register/salesProductCtrs/:id/edit",
    element: (
      <DomainProvider
        value={{ domainName: "salesproductctr" }}
        actions={["Update"]}
      >
        <ContractProduct />
      </DomainProvider>
    ),
  },
  {
    path: "register/beneficiaryContractReasonOfTerminations",
    element: (
      <DomainProvider
        value={{ domainName: "beneficiarycontractreasonoftermination" }}
      >
        <BeneficiaryTerminationReasonPage />
      </DomainProvider>
    ),
  },
  {
    path: "register/beneficiaryContractReasonOfTerminations/new",
    element: (
      <DomainProvider
        value={{ domainName: "beneficiarycontractreasonoftermination" }}
        actions={["Create"]}
      >
        <BeneficiaryTerminationReason />
      </DomainProvider>
    ),
  },
  {
    path: "register/beneficiaryContractReasonOfTerminations/:id/edit",
    element: (
      <DomainProvider
        value={{ domainName: "beneficiarycontractreasonoftermination" }}
        actions={["Update"]}
      >
        <BeneficiaryTerminationReason />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesChannels",
    element: (
      <DomainProvider value={{ domainName: "saleschannel" }}>
        <SalesChannelPage />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesChannels/new",
    element: (
      <DomainProvider
        value={{ domainName: "saleschannel" }}
        actions={["Create"]}
      >
        <SalesChannel />
      </DomainProvider>
    ),
  },
  {
    path: "register/salesChannels/:id/edit",
    element: (
      <DomainProvider
        value={{ domainName: "saleschannel" }}
        actions={["Update"]}
      >
        <SalesChannel />
      </DomainProvider>
    ),
  },
  {
    path: "register/beneficiaryContractReasonOfCancellations",
    element: (
      <DomainProvider
        value={{ domainName: "beneficiarycontractreasonofcancellation" }}
      >
        <BeneficiaryCancellationReasonPage />
      </DomainProvider>
    ),
  },
  {
    path: "register/beneficiaryContractReasonOfCancellations/new",
    element: (
      <DomainProvider
        value={{ domainName: "beneficiarycontractreasonofcancellation" }}
        actions={["Create"]}
      >
        <BeneficiaryCancellationReason />
      </DomainProvider>
    ),
  },
  {
    path: "register/beneficiaryContractReasonOfCancellations/:id/edit",
    element: (
      <DomainProvider
        value={{ domainName: "beneficiarycontractreasonofcancellation" }}
        actions={["Update"]}
      >
        <BeneficiaryCancellationReason />
      </DomainProvider>
    ),
  },
  {
    path: "register/beneficiaryContractReasonOfSuspensions",
    element: (
      <DomainProvider
        value={{ domainName: "beneficiarycontractteasonoofsuspension" }}
      >
        <BeneficiarySuspensionReasonPage />
      </DomainProvider>
    ),
  },
  {
    path: "register/beneficiaryContractReasonOfSuspensions/new",
    element: (
      <DomainProvider
        value={{ domainName: "beneficiarycontractteasonoofsuspension" }}
        actions={["Create"]}
      >
        <BeneficiarySuspensionReason />
      </DomainProvider>
    ),
  },
  {
    path: "register/beneficiaryContractReasonOfSuspensions/:id/edit",
    element: (
      <DomainProvider
        value={{ domainName: "beneficiarycontractteasonoofsuspension" }}
        actions={["Update"]}
      >
        <BeneficiarySuspensionReason />
      </DomainProvider>
    ),
  },
];

export default salesContractRoutes;
