import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LogsTable from "../LogsTable/LogsTable";
import { TLog } from "../../../models/logs";
import { useTranslation } from "react-i18next";

const LogsAccordions: React.FC<{ logs: TLog[] }> = ({ logs }) => {
  const { t } = useTranslation();

  // Example values for test ------------->
  // const testeChange: TChange[] = [
  //   {
  //     columnName: "Teste",
  //     originalValue: {
  //       Value1: "value",
  //       Value2:
  //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //       Value3: "Outro valor para testar como fica",
  //       Value4: 15651651655,
  //     },
  //     newValue: {
  //       Value1: "value",
  //       Value2:
  //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //       Value3: "Outro valor para testar como fica",
  //       Value4: 15651651655,
  //     },
  //   },
  // ];

  return (
    <Grid
      container
      sx={{
        padding: "4px",
      }}
    >
      {logs.map((log) => (
        <Accordion sx={{ width: "100%" }} key={log.endDate}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container>
              <Grid container item xs={10} spacing={1}>
                <Grid item>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {t("components.logs_accordions.user")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>{log.environment.userName}</Typography>
                </Grid>
              </Grid>
              <Grid container item xs={2} spacing={1}>
                <Grid item>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {t("components.logs_accordions.duration")}
                  </Typography>
                  <Chip sx={{ marginLeft: "5px" }} label={log.duration} />
                </Grid>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <LogsTable changes={log.entityFrameworkEvent.entries[0].changes} />
          </AccordionDetails>
        </Accordion>
      ))}
    </Grid>
  );
};

export default LogsAccordions;
