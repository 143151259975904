import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";

export interface IMenuRoute {
  id: string;
  name: string;
  description?: string;
  className?: string | null;
  iconClass?: string | null;
  link: string;
  params?: string | null;
  target?: string;
  order: number;
  parentId: string | null;
  parent: ISelectType;
  domainId?: string | null;
  softwareId?: string;
}

const BASE_URL = process.env.REACT_APP_IDENTIY;
const pathRoute = BASE_URL + "/api/v1/Users/Me/Menus/";

export class MenuRouteService {
  list = (softwareCode: string) => {
    return GlobalAxios.get<IMenuRoute[]>(pathRoute + softwareCode);
  };
}

export default MenuRouteService;
