import { Grid, Typography } from "@mui/material";
import {
  IGetUsers,
  IResetPassword,
  ISiteAppEstablishment,
} from "../../../models/users";
import { useTranslation } from "react-i18next";
import { useFetch } from "@4uhub/lib4uhub";
import MainApiService from "../../../services/mainApi.service";
import { useCallback, useEffect, useState } from "react";
import UserResetButtons from "./UserResetButtons";
import { TUserResetForm } from "./UserResetSchema";
import UserResetForm from "./UserResetForm";

interface IUsersResetModal {
  user: IGetUsers;
  onCancel: () => void;
  onCloseHandler: () => void;
  onOpenHandler: () => void;
}

const translationPath = "page.register.users.reset_password.";

const RESET_PASSWORD =
  process.env.REACT_APP_IDENTIY + "/api/v1/Users/ForgotUserPassword";
const resetSerivce = new MainApiService<IResetPassword>(RESET_PASSWORD);

const UsersResetModal = ({
  user,
  onCancel,
  onCloseHandler,
  onOpenHandler,
}: IUsersResetModal) => {
  const { t } = useTranslation();
  const { sendRequest: resetPassword, loading } = useFetch(resetSerivce.create);
  const [siteApps, setSiteApps] = useState<ISiteAppEstablishment[]>([]);

  const onResetHandler = useCallback(
    async (selectedSiteApp?: TUserResetForm) => {
      if (user.id) {
        const data = await resetPassword({
          id: user.id,
          softwareCode: !user.isAdmin ? "1" : "",
          siteAppId: !user.isAdmin ? selectedSiteApp?.site.id : undefined,
        });
        if (data) {
          onCloseHandler();
          onOpenHandler();
        }
      }
    },
    [resetPassword, user, onOpenHandler, onCloseHandler]
  );

  useEffect(() => {
    const idSet = new Set();
    const siteAppsFiltered = user.establishments
      ?.flatMap((e) => e.siteApps)
      .filter((item) => {
        if (item.siteAppType.code === "1") {
          if (!idSet.has(item.id)) {
            idSet.add(item.id);
            return true;
          }
          return false;
        }
        return false;
      });
    setSiteApps(siteAppsFiltered || []);
  }, [user.establishments]);

  return (
    <>
      <Grid container maxWidth={500} justifyContent={"center"} spacing={2}>
        <Grid item sm={10} display={"flex"} justifyContent={"center"}>
          <Typography variant="h6" textAlign={"center"} lineHeight={1.3}>
            {!user.isAdmin &&
              t(translationPath + "title_form", { username: user.userName })}
            {user.isAdmin &&
              t(translationPath + "title", { username: user.userName })}
          </Typography>
        </Grid>
        {!user.isAdmin && siteApps && (
          <UserResetForm
            isAdmin={false}
            loading={loading}
            onCancel={onCancel}
            onResetHandler={onResetHandler}
            options={siteApps}
          />
        )}
        {user.isAdmin && (
          <UserResetButtons
            isAdmin={true}
            loading={loading}
            onCancel={onCancel}
            onClickFunction={onResetHandler}
          />
        )}
      </Grid>
    </>
  );
};

export default UsersResetModal;
