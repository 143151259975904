import { useTranslation } from "react-i18next";

import Fields from "./Fields";
import { FormContainer, Treturn, useFetch } from "@4uhub/lib4uhub";

import {
  contractTerminationReasonSchema,
  TcontractTerminationReasonForm,
} from "./contract-termination-reason.schema";
import {
  buildTPath,
  contractTerminationFormService,
} from "../contract-termination-reason.utils";

const ContractClassification = () => {
  const { t } = useTranslation();

  const { sendRequest: create, loading: isSaving } = useFetch(
    contractTerminationFormService.create
  );
  const { sendRequest: update, loading: isUpdating } = useFetch(
    contractTerminationFormService.updateItemById
  );
  const { sendRequest: findById, loading: isFetching } = useFetch(
    contractTerminationFormService.getItemById
  );

  const handleFormValues = ({
    salesContractType: { id },
    ...rest
  }: TcontractTerminationReasonForm) => ({ ...rest, salesContractTypeId: id });

  const createHandler = (values: TcontractTerminationReasonForm) =>
    create(handleFormValues(values));

  const updateHandler = (values: TcontractTerminationReasonForm, id: string) =>
    update({
      id,
      item: handleFormValues({
        ...values,
        id,
      } as TcontractTerminationReasonForm),
    });

  const isLoading = isSaving || isUpdating || isFetching;

  return (
    <FormContainer<TcontractTerminationReasonForm>
      saveFunction={createHandler}
      updateFunction={updateHandler}
      getFunction={
        findById as (
          id: string
        ) => Promise<Treturn<TcontractTerminationReasonForm>>
      }
      loading={isLoading}
      title={t(buildTPath("title"))}
      schema={contractTerminationReasonSchema}
      subtitle={t(buildTPath("new"))}
      subtitleWatchField="name"
      fields={() => <Fields />}
    />
  );
};

export default ContractClassification;
