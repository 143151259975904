import { Box, Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IDocsVersion } from "../../../../../services/docsVersion.service";
import { useNavigate } from "react-router-dom";
import { useRole } from "@4uhub/lib4uhub";

const translationPath = "page.register.documents.docsVersion.";

interface IDocumentsVersion {
  lastVersion: IDocsVersion;
  docTypeSelected: string;
}

const DocumentsVersion = ({
  lastVersion,
  docTypeSelected,
}: IDocumentsVersion) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const createPermission = useRole(["Create"]);
  const updatePermission = useRole(["Update"]);

  const editHandler = () => {
    navigate(`${lastVersion.id}/edit`);
  };

  const createHandler = () => {
    navigate(`${lastVersion.id}/new`);
  };

  return (
    <Box sx={{ minWidth: "100%", marginTop: 3 }}>
      <Typography variant="subtitle1">
        {t(translationPath + "last_version") || "Last version"}:
      </Typography>
      <Grid
        container
        justifyContent="space-between"
        alignContent="center"
        sx={(theme) => ({
          backgroundColor:
            theme.palette.mode === "dark"
              ? theme.palette.grey[900]
              : theme.palette.grey[200],
          p: 2,
          borderRadius: 1,
        })}
      >
        <Grid item alignItems="center" display={"flex"}>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {lastVersion?.version}
          </Typography>
        </Grid>
        <Grid item>
          <Grid item container gap={1}>
            <Grid item>
              {createPermission && (
                <Button variant="contained" onClick={createHandler}>
                  {t(translationPath + "new_version") || "New version"}
                </Button>
              )}
            </Grid>
            <Grid item>
              {!lastVersion?.blocked && updatePermission && (
                <Button variant="outlined" onClick={editHandler}>
                  {t(translationPath + "edit_version") || "Edit"}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DocumentsVersion;
