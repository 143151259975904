import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

// Schema
import { THolidayForm, holidaySchema } from "./HolidaySchema";
import MainApiService from "../../../../../../services/mainApi.service";
import { useCallback } from "react";
import {
  AutoComplete,
  DateInput,
  FormContainer,
  FormInput,
  ISelectType,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import HolidaysService from "../../../../../../services/holidays.service";

const holidaysService = new HolidaysService();

const HOLIDAY_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";

const holidayTypeService = new MainApiService<ISelectType>(HOLIDAY_ROUTE);

const translationPath = "page.register.holidays.";

const Holiday = () => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(
    holidaysService.create
  );
  const { sendRequest: update, loading: uLoading } = useFetch(
    holidaysService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    holidaysService.getItemById
  );

  const handleSave = (v: THolidayForm) => {
    return save({
      ...v,
      holidayTypeId: v.holidayType.id,
      date: v.date.toISOString(),
    });
  };

  const updateHandler = (v: THolidayForm, id: string) =>
    update({
      item: {
        ...v,
        holidayTypeId: v.holidayType.id,
        date: v.date.toISOString(),
      },
      id: id,
    });

  const handleGet = useCallback(
    async (id: string): Promise<Treturn<THolidayForm>> => {
      const { data } = await get(id);
      if (data) {
        return {
          data: {
            ...data,
            date: new Date(data.date),
          },
          success: true,
        };
      }
      return {
        data: null,
        success: false,
      };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<THolidayForm>
      saveFunction={handleSave}
      updateFunction={updateHandler}
      getFunction={handleGet}
      loading={loading}
      title={t(translationPath + "holiday")}
      schema={holidaySchema}
      subtitle={t(translationPath + "new_holiday")}
      subtitleWatchField={"name"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <AutoComplete
              params={{ identifier: "HolidayType" }}
              getOptionLabel={(option) => {
                return option.name;
              }}
              size="small"
              label={t(translationPath + "holiday_type")}
              name="holidayType"
              request={holidayTypeService.list}
              autoFocus
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <DateInput
              name={"date"}
              label={t(translationPath + "date") || "Date"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="name"
              fullWidth
              label={t(translationPath + "name") || "Name"}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="description"
              fullWidth
              label={t(translationPath + "description") || "Description"}
              multiline
              minRows={4}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default Holiday;
