import { IProfile } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import { ISearchParams, ISearchReturn } from "../models/requests";
import { IRole } from "../models/roles";
import { TRolesForm } from "../pages/admSystem/roles/RolesForm/RolesSchema";

export const ALL_APP_DOMAIN = "all";
export class RolesService {
  route_path: string;

  constructor(rp: string) {
    this.route_path = rp;
  }

  create = (item: IRole) => {
    return GlobalAxios.post(this.route_path, item);
  };

  getAll = (serchParams?: ISearchParams) => {
    return GlobalAxios.get<ISearchReturn<TRolesForm>>(this.route_path, {
      params: serchParams,
    });
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<TRolesForm>(this.route_path + id);
  };

  updateItemById = ({ item, id }: { item: IRole; id: string }) => {
    return GlobalAxios.put(this.route_path + id, item);
  };

  deleteItemById = (id: string) => {
    return GlobalAxios.delete(this.route_path + id);
  };

  list = (params?: any) => {
    return GlobalAxios.get<IProfile[]>(this.route_path + "list", {
      params: params,
    });
  };
}

export default RolesService;
