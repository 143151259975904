import { AutoComplete, ISelectType, useFormContext } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { TWorkGroupTypeForm } from "./WorkGroupTypeSchema";
import { useWatch } from "react-hook-form";

const translationPath = "page.settings.preventMedicine.workGroupTypes.";

const WorkGroupTypeLang = ({
  index,
  name,
  allLanguages,
  loadDefaultLang,
}: {
  index: number;
  name: string;
  allLanguages: ISelectType[];
  loadDefaultLang: (lang: ISelectType) => void;
}) => {
  const { t } = useTranslation();

  const { control } = useFormContext<TWorkGroupTypeForm>();

  const [languageOptions, setLanguageOptions] = useState<ISelectType[]>([]);

  const selectedLanguage = useWatch({
    name: "healthProgramWorkGroupTypeLanguages",
    control,
  });

  const loadLanguageOptions = useCallback(async () => {
    const selectedLang = selectedLanguage.find(
      (lang, indexLang) => indexLang === index
    );
    const filteredOptions = allLanguages.filter(
      (objeto2) =>
        !selectedLanguage.some((objeto1) => objeto1.language?.id === objeto2.id)
    );
    loadDefaultLang(filteredOptions[0]);
    if (selectedLang?.language && filteredOptions.length !== 0) {
      setLanguageOptions([
        ...filteredOptions,
        { id: selectedLang.language.id, name: selectedLang.language.name },
      ]);
    } else {
      setLanguageOptions(filteredOptions);
    }
  }, [loadDefaultLang, selectedLanguage, index, allLanguages]);

  useEffect(() => {
    loadLanguageOptions();
  }, [loadLanguageOptions]);

  return (
    <Grid item xs={12} sm={6}>
      <AutoComplete
        getOptionLabel={(option) => {
          return option.name;
        }}
        size="small"
        label={t(translationPath + "language")}
        name={name}
        options={languageOptions}
      />
    </Grid>
  );
};

export default WorkGroupTypeLang;
