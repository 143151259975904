import { z } from "zod";

const translationPath = "page.4uBus.restrictions.schedule.errors.";

const restrictionRuleTypeSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "restriction_rule_type" }
);

export const scheduleSchema = z.object({
  id: z.string().optional(),
  enable: z.boolean(),
  restrictionRuleType: restrictionRuleTypeSchema,
  value: z.string().min(1, translationPath + "value"),
  description: z.string().min(1, translationPath + "description"),
});

export type TScheduleForm = z.infer<typeof scheduleSchema>;
