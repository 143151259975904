import { Box } from "@mui/material";

const OnPremiseHeader = ({ children }: { children: any }) => {
  return (
    <Box maxHeight={700} sx={{ overflowY: "auto", paddingRight: 4 }}>
      {children}
    </Box>
  );
};

export default OnPremiseHeader;
