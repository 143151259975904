import { GlobalAxios } from "../axios/axios-config";
import {
  IGetParamDefaultEstablishment,
  INewParamDefaultEstablishment,
} from "../models/param-default-establishment";

const PARAM_DEFAULT_ESTABLISHMENT_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/ParamDefaultEstablishment/";

export class ParamDefaultEstablishmentService {
  create = (item: INewParamDefaultEstablishment) => {
    return GlobalAxios.post(PARAM_DEFAULT_ESTABLISHMENT_ROUTE, item);
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<IGetParamDefaultEstablishment>(
      PARAM_DEFAULT_ESTABLISHMENT_ROUTE + id
    );
  };

  updateItemById = ({
    item,
    id,
  }: {
    item: INewParamDefaultEstablishment;
    id: string;
  }) => {
    return GlobalAxios.put(PARAM_DEFAULT_ESTABLISHMENT_ROUTE + id, item);
  };
}

export default ParamDefaultEstablishmentService;
