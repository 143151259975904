import { useNavigate, useParams } from "react-router-dom";
import { Apps } from "@4uhub/lib4uhub";
import { useApp } from "../../../store/appContex";

interface IDynamicLogoProps {}

const DynamicLogo: React.FC<IDynamicLogoProps> = () => {
  const navigate = useNavigate();
  const { eI } = useParams();

  const { app } = useApp();

  let src = "/logo_4uHub.png";
  let path = `dashboard`;

  switch (app) {
    case Apps.APP_4UCHATBOT:
      src = "/4uhubApps/4uChatBot.png";
      path = `chatbot`;
      break;
    case Apps.APP_4UTELEMED:
      src = "/4uhubApps/4uTelemed.png";
      path = `telemed`;
      break;
    case Apps.APP_4USITEAPP:
      src = "/4uhubApps/logo_4uSiteApp.png";
      path = `4uSiteApp`;
      break;
    case Apps.APP_4UBUS:
      src = "/4uhubApps/4uBus.png";
      path = `4uBus`;
      break;
    case Apps.APP_4UHCP:
      src = "/4uhubApps/4uHcp.png";
      path = `4uHcp`;
      break;
    case Apps.APP_4USCHED:
      src = "/4uhubApps/4uSched.png";
      path = `4uSched`;
      break;
    case Apps.APP_4USALESCONTRACT:
      src = "/4uhubApps/4uSalesContract.png";
      path = "4uSalesContract";
      break;
  }

  return (
    <img
      src={src}
      alt="4uhubu mini icon"
      style={{ cursor: "pointer", width: "120px", objectFit: "contain" }}
      onClick={() => navigate(`/e/${eI}/${path}`)}
    />
  );
};

export default DynamicLogo;
