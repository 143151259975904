import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  FormContainer,
  FormEmojiTextInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import {
  TTicketServiceMessageForm,
  ticketServiceMessageSchema,
} from "./TicketServiceMessageSchema";
import { useCallback, useState } from "react";
import TicketServMessageService from "../../../../../../services/ticketServiceMessage.service";
import TicketServiceMessageData from "../../../../../../components/TicketServiceMessage/TicketServiceMessageData";

const ticketServMessageService = new TicketServMessageService();

const translationPath = "chatBot.page.service.ticketServiceMessage.";

const TicketServiceMessage = () => {
  const { t } = useTranslation();
  const [messageType, setMessageType] = useState("");
  const [ticketService, setTicketService] = useState("");

  const { sendRequest: update, loading: uLoading } = useFetch(
    ticketServMessageService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    ticketServMessageService.getItemById
  );

  const handleUpdate = (v: TTicketServiceMessageForm, id: string) => {
    return update({
      item: {
        ...v,
        ticketServiceId: v.ticketService.id,
      },
      id: id,
    });
  };

  const handleGet = useCallback(
    async (id: string): Promise<Treturn<TTicketServiceMessageForm>> => {
      const data = await get(id);
      if (data.data?.messageType) {
        setMessageType(data.data.messageType);
        setTicketService(data.data.ticketService.name);
      }
      return data;
    },
    [get]
  );

  const loading = uLoading || gLoading;

  return (
    <FormContainer<TTicketServiceMessageForm>
      updateFunction={handleUpdate}
      getFunction={handleGet}
      loading={loading}
      title={t(translationPath + "ticket_service_message")}
      schema={ticketServiceMessageSchema}
      subtitle={t(translationPath + "ticket_service_message")}
      subtitleWatchField={"ticketService.name"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TicketServiceMessageData
              label={t(translationPath + "ticket_service")}
              value={ticketService}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TicketServiceMessageData
              label={t(translationPath + "message_type")}
              value={messageType}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormEmojiTextInput
              size="small"
              name="message"
              label={t(translationPath + "message")}
              autoFocus
              fullWidth
              multiline
              minRows={4}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default TicketServiceMessage;
