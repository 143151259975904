import { Grid } from "@mui/material";

import { useTranslation } from "react-i18next";
import { memo, useCallback, useEffect, useState } from "react";
import {
  AccessRegister,
  EstablishmentAutoComplete,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";

import EstablishmentsService from "../../../../services/establishments.service";
import { useWatch } from "react-hook-form";
import { TRolesForm } from "./RolesSchema";
import { IEstablishment } from "@4uhub/lib4uhub";

const translation_path = "components.roles_establishments.";

const establishmentListService = new EstablishmentsService();

const RolesEstablishments: React.FC = () => {
  const { t } = useTranslation();
  const [establishmentsList, setEstablishmentsList] = useState<
    IEstablishment[]
  >([]);
  const { control } = useFormContext<TRolesForm>();

  const { sendRequest: listEstablishments } = useFetch(
    establishmentListService.list
  );

  const loadEstablishments = useCallback(async () => {
    const { data } = await listEstablishments(null);
    if (data) {
      setEstablishmentsList(data);
    }
  }, [listEstablishments]);

  useEffect(() => {
    loadEstablishments();
  }, [loadEstablishments]);

  const establishments = useWatch({
    name: "establishments",
    control,
  });

  return (
    <Grid item xs={12}>
      <AccessRegister
        redirectTo="dashboard/admSystem/establishments"
        domainName="establishment"
        inputValue={
          establishments
            ? establishments.map((establishment) => ({
                id: establishment.id,
                name: establishment.name,
              }))
            : []
        }
        tooltip={t(translation_path + "establishments_tooltip")}
      >
        <EstablishmentAutoComplete
          multiple
          name="establishments"
          label={t(translation_path + "label")}
          establishments={establishmentsList}
        />
      </AccessRegister>
    </Grid>
  );
};

export default memo(RolesEstablishments);
