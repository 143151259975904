import { z } from "zod";

const siteAppTypeSchema = z.object({
  id: z.string(),
  identifier: z.string(),
  code: z.string(),
  value: z.string(),
  description: z.string().optional().nullable(),
});

const siteAppSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    siteAppType: siteAppTypeSchema,
  },
  { invalid_type_error: "page.register.users.reset_password.site_error" }
);

export const userResetSchema = z.object({
  site: siteAppSchema,
});

export type TUserResetForm = z.infer<typeof userResetSchema>;
