import { Box, Typography } from "@mui/material";
import { memo, useMemo } from "react";
import { stringToColor } from "@4uhub/lib4uhub";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LockIcon from "@mui/icons-material/Lock";

import EventPopover from "./EventPopover";
import { IGroupedEvents } from "../models";
import HourPosition from "../HourPosition";
import usePopover from "../../../../hooks/usePopover";
import { EcalendarStatusCode, ICalendar } from "../../model";
import useDate from "../../../../hooks/useDate";

interface IEventProps {
  data: ICalendar;
  containerHeigth: number;
  dayIndex: number;
  index: number;
  eventGroup: IGroupedEvents<ICalendar>;
  date: Date;
}

const Event: React.FC<IEventProps> = ({
  data,
  containerHeigth,
  dayIndex,
  eventGroup,
  index,
  date,
}) => {
  const props = usePopover(String(data.identifier));

  const { localeFormat } = useDate();

  const startDate = useMemo(
    () => new Date(data.calendarStartDate),
    [data.calendarStartDate]
  );
  const endDate = useMemo(
    () => new Date(data.calendarEndDate),
    [data.calendarEndDate]
  );

  const color = useMemo(
    () => stringToColor(data.descriptionSite),
    [data.descriptionSite]
  );

  const isCofirmed =
    data.calendarStatusCode === EcalendarStatusCode.CONFIMED ||
    data.calendarStatusCode === EcalendarStatusCode.CONFIMED2;

  const isBlocked = data.calendarStatusCode === EcalendarStatusCode.BLOCKED;

  return (
    <>
      <HourPosition
        startDate={startDate}
        endDate={endDate}
        containerHeigth={containerHeigth}
        dayIndex={dayIndex}
        date={date}
        index={index}
        eventGroup={eventGroup}
        onClick={props.handleClick}
        sx={[
          (t) => ({
            display: "flex",
            backgroundColor: color || t.palette.primary.main,
            color: color
              ? t.palette.getContrastText(color)
              : t.palette.primary.contrastText,
            borderRadius: 1,
            border: `1px solid ${t.palette.background.default}`,
            paddingX: t.spacing(1),
            "&:hover": {
              boxShadow: "0px 0px 12px 5px rgba(120,120,120,1)",
              cursor: "pointer",
              zIndex: 99999,
              minWidth: "fit-content",
            },
            overflow: "hidden",
          }),
          (t) => {
            if (!isBlocked) return null;

            const pColor = t.palette.augmentColor({
              color: { main: color },
            }).light;

            return {
              background: `repeating-linear-gradient( 45deg, ${color}, ${color} 5px, ${pColor} 5px, ${pColor} 15px )`,
            };
          },
        ]}
      >
        {isCofirmed && (
          <Box
            sx={(t) => ({
              backgroundColor: t.palette.success.light,
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
              marginLeft: "-5px",
              padding: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            <CheckCircleOutlineIcon sx={{ fontSize: 16 }} />
          </Box>
        )}

        {isBlocked && <LockIcon sx={{ fontSize: 16 }} />}

        <Typography
          fontSize={12}
          textOverflow={"ellipsis"}
          overflow={"hidden"}
          whiteSpace={"nowrap"}
        >
          {localeFormat(startDate, "p")} - {localeFormat(endDate, "p")}
        </Typography>
        <Typography
          fontSize={12}
          textOverflow={"ellipsis"}
          overflow={"hidden"}
          whiteSpace={"nowrap"}
        >
          {data.pacientName}
        </Typography>
      </HourPosition>
      <EventPopover
        popoverProps={{ ...props, onClose: props.handleClose }}
        event={data}
      />
    </>
  );
};

export default memo(Event);
