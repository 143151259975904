import { SearchInput } from "@4uhub/lib4uhub";
import { Stack, useMediaQuery, useTheme } from "@mui/material";

const SettingsSearch = ({
  onSearch,
}: {
  onSearch: (texted: string) => void;
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Stack
      height={matches ? 40 : undefined}
      flexDirection={matches ? "row" : "column"}
      alignItems={"center"}
    >
      <Stack width={matches ? "32%" : "100%"}>
        <SearchInput
          onSearch={(texted) => {
            onSearch(texted);
          }}
          placeholder="Busque por uma configuração"
          cleanButton
        />
      </Stack>
    </Stack>
  );
};

export default SettingsSearch;
