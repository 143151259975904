import { Grid } from "@mui/material";
import { useWatch } from "react-hook-form";

import { TLegalEntityForm } from "../pages/registers/legalEntity/LegalEntityForm/LegalEntitySchema";

import { FormInput, useFormContext } from "@4uhub/lib4uhub";

interface isForeigner {
  name: string;
  label: string;
}

const Foreigner: React.FC<isForeigner> = ({ label, name }) => {
  const { control } = useFormContext<TLegalEntityForm>();

  const isForeigner = useWatch({ control, name: "foreigner" });

  if (!isForeigner) {
    return null;
  }

  return (
    <Grid item xs={12} sm={4}>
      <FormInput size="small" name={name} label={label} fullWidth />
    </Grid>
  );
};

export default Foreigner;
