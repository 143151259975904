import IMask from "imask";

export const CPFMask = IMask.createMask({
  mask: "000.000.000-00",
});


export const CNPJMask = IMask.createMask({
  mask: "00.000.000/0000-00",
});
