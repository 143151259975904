import { RouteObject } from "react-router-dom";
import WelcomePage from "../pages/welcomePage";
import { DomainProvider } from "@4uhub/lib4uhub";
import BannersPage from "../pages/4uSiteApp/Communication/Banners/BannersPage";
import Banner from "../pages/4uSiteApp/Communication/Banners/BannerForm/Banner";
import DocumentsPage from "../pages/4uSiteApp/SecurityAndPrivacy/TermsAndPolicies/DocumentsPage";
import Documents from "../pages/4uSiteApp/SecurityAndPrivacy/TermsAndPolicies/DocumentsForm/Documents";
import NotificationsPage from "../pages/4uSiteApp/Communication/Notifications/NotificationsPage";
import Notification from "../pages/4uSiteApp/Communication/Notifications/NotificationForm/Notification";
import NewsPage from "../pages/4uSiteApp/Communication/News/NewsPage";
import FaqPage from "../pages/4uSiteApp/Sac/Faq/FaqPage";
import Faq from "../pages/4uSiteApp/Sac/Faq/FaqForm/Faq";
import QuestionsAnswersPage from "../pages/4uSiteApp/Sac/Faq/QuestionsAnswers/QuestionsAnswersPage";
import QuestionAnswer from "../pages/4uSiteApp/Sac/Faq/QuestionsAnswers/QuestionAnswerForm/QuestionAnswer";
import MyApplicationsPage from "../pages/4uSiteApp/Applications/MyApplications/MyApplicationsPage";
import MyApplication from "../pages/4uSiteApp/Applications/MyApplications/MyApplication";
import News from "../pages/4uSiteApp/Communication/News/News";

const siteApp: RouteObject[] = [
  {
    index: true,
    element: <WelcomePage />,
  },
  {
    path: "applications/myApplications",
    element: (
      <DomainProvider value={{ domainName: "siteapponlyread" }}>
        <MyApplicationsPage />
      </DomainProvider>
    ),
  },
  {
    path: "applications/myApplications/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "siteapponlyread" }}>
        <MyApplication />
      </DomainProvider>
    ),
  },
  {
    path: "communication/banners",
    element: (
      <DomainProvider value={{ domainName: "banner" }}>
        <BannersPage />
      </DomainProvider>
    ),
  },
  {
    path: "communication/banners/new",
    element: (
      <DomainProvider value={{ domainName: "banner" }} actions={["Create"]}>
        <Banner />
      </DomainProvider>
    ),
  },
  {
    path: "communication/banners/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "banner" }} actions={["Read"]}>
        <Banner />
      </DomainProvider>
    ),
  },
  {
    path: "communication/notifications",
    element: (
      <DomainProvider value={{ domainName: "notificationadm" }}>
        <NotificationsPage />
      </DomainProvider>
    ),
  },
  {
    path: "communication/notifications/new",
    element: (
      <DomainProvider
        value={{ domainName: "notificationadm" }}
        actions={["Create"]}
      >
        <Notification />
      </DomainProvider>
    ),
  },
  {
    path: "communication/notifications/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "notificationadm" }}>
        <Notification />
      </DomainProvider>
    ),
  },
  {
    path: "communication/news",
    element: (
      <DomainProvider value={{ domainName: "content" }}>
        <NewsPage />
      </DomainProvider>
    ),
  },
  {
    path: "communication/news/new",
    element: (
      <DomainProvider value={{ domainName: "content" }} actions={["Create"]}>
        <News />
      </DomainProvider>
    ),
  },
  {
    path: "communication/news/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "content" }} actions={["Read"]}>
        <News />
      </DomainProvider>
    ),
  },
  {
    path: "privacyAndSecurity/termsAndPolicies",
    element: (
      <DomainProvider value={{ domainName: "docacceptance" }}>
        <DocumentsPage />
      </DomainProvider>
    ),
  },
  {
    path: "privacyAndSecurity/termsAndPolicies/:docId/new",
    element: (
      <DomainProvider
        value={{ domainName: "docacceptance" }}
        actions={["Create"]}
      >
        <Documents />
      </DomainProvider>
    ),
  },
  {
    path: "privacyAndSecurity/termsAndPolicies/:id/edit",
    element: (
      <DomainProvider
        value={{ domainName: "docacceptance" }}
        actions={["Read"]}
      >
        <Documents />
      </DomainProvider>
    ),
  },
  {
    path: "sac/faq",
    element: (
      <DomainProvider value={{ domainName: "faq" }}>
        <FaqPage />
      </DomainProvider>
    ),
  },
  {
    path: "sac/faq/new",
    element: (
      <DomainProvider value={{ domainName: "faq" }}>
        <Faq />
      </DomainProvider>
    ),
  },
  {
    path: "sac/faq/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "faq" }}>
        <Faq />
      </DomainProvider>
    ),
  },
  {
    path: "sac/faq/:faqId/questions-answers",
    element: (
      <DomainProvider value={{ domainName: "faq" }}>
        <QuestionsAnswersPage />
      </DomainProvider>
    ),
  },
  {
    path: "sac/faq/:faqId/questions-answers/new",
    element: (
      <DomainProvider value={{ domainName: "faq" }}>
        <QuestionAnswer />
      </DomainProvider>
    ),
  },
  {
    path: "sac/faq/:faqId/questions-answers/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "faq" }}>
        <QuestionAnswer />
      </DomainProvider>
    ),
  },
];

export default siteApp;
