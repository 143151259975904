import { useTranslation } from "react-i18next";

import Fields from "./Fields";
import { FormContainer, useFetch } from "@4uhub/lib4uhub";

import { buildTPath } from "../beneficiary-classifications.utils";
import {
  beneficiaryClassificationSchema,
  TBeneficiaryClassificationForm,
} from "./beneficiary-classification.schema";
import {
  createBeneficiaryClassification,
  findBeneficiaryClassificationById,
  updateBeneficiaryClassification,
} from "../../../../../services/salesContractBeneficiaryClassification.service";

const BeneficiaryClassification = () => {
  const { t } = useTranslation();

  const { sendRequest: create, loading: isSaving } = useFetch(
    createBeneficiaryClassification
  );
  const { sendRequest: update, loading: isUpdating } = useFetch(
    updateBeneficiaryClassification
  );
  const { sendRequest: findById, loading: isFetching } = useFetch(
    findBeneficiaryClassificationById
  );

  const handleFormValues = ({ ...rest }: TBeneficiaryClassificationForm) => ({
    ...rest,
  });

  const createHandler = (values: TBeneficiaryClassificationForm) =>
    create(handleFormValues(values));

  const updateHandler = (values: TBeneficiaryClassificationForm, id: string) =>
    update({ id, item: handleFormValues({ ...values, id }) });

  const isLoading = isSaving || isUpdating || isFetching;

  return (
    <FormContainer<TBeneficiaryClassificationForm>
      saveFunction={createHandler}
      updateFunction={updateHandler}
      getFunction={findById}
      loading={isLoading}
      title={t(buildTPath("title"))}
      schema={beneficiaryClassificationSchema}
      subtitle={t(buildTPath("new"))}
      subtitleWatchField="name"
      fields={() => <Fields />}
    />
  );
};

export default BeneficiaryClassification;
