import { DatePickerUncontrolled, Table, useFetch } from "@4uhub/lib4uhub";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

import { Stack } from "@mui/material";
import { format, subMonths } from "date-fns";
import { enUS, ptBR } from "date-fns/locale";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IRobotExecutionList } from "../../../../../models/robots-list";
import { getRobotsLogsExecution } from "../../../../../services/robotsList.service";

const translationPath = "page.4uBus.robots.list.executions.";

const RobotsExecutionsPage = () => {
  const { id } = useParams();

  const [rows, setRows] = useState<IRobotExecutionList[]>([]);

  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const [startDate, setStartDate] = useState<Date | null>(
    endDate ? subMonths(endDate, 1) : null
  );

  const { sendRequest } = useFetch(getRobotsLogsExecution);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const columns: GridColDef<IRobotExecutionList>[] = [
    {
      field: "jobName",
      headerName: t(translationPath + "job_name") || "Job Name",
    },
    {
      field: "executionStatus",
      headerName: t(translationPath + "execution_status") || "Execution Status",
      maxWidth: 300,
    },
    {
      field: "startDate",
      headerName: t(translationPath + "start_date") || "Start Date",
      maxWidth: 130,
      renderCell: (params) => {
        const date = new Date(params.value);
        return format(date, "Pp", {
          locale: language === "pt-BR" ? ptBR : enUS,
        }).replaceAll(",", "");
      },
    },
    {
      field: "endDate",
      headerName: t(translationPath + "end_date") || "End Date",
      maxWidth: 130,
      renderCell: (params) => {
        const date = new Date(params.value);
        return format(date, "Pp", {
          locale: language === "pt-BR" ? ptBR : enUS,
        }).replaceAll(",", "");
      },
    },
  ];

  const fetchRobotsList = useCallback(async () => {
    if (!id) return;
    if (!startDate || !endDate) return;
    if (startDate > endDate) return;
    const { data, success } = await sendRequest({
      Id: id,
      InitialDate: startDate?.toISOString().substring(0, 10),
      EndDate: endDate?.toISOString().substring(0, 10),
    });
    if (data && success) {
      setRows(data);
    }
  }, [sendRequest, startDate, endDate, id]);

  useEffect(() => {
    fetchRobotsList();
  }, [fetchRobotsList]);

  useEffect(() => {
    if (!startDate || !endDate) return;
    if (startDate > endDate) {
      setStartDate(subMonths(endDate, 1));
    }
  }, [startDate, endDate]);

  return (
    <Table<IRobotExecutionList>
      showDefaultMacroFilters={false}
      columns={columns}
      title={t(translationPath + "executions_robots")}
      rows={rows}
      searchInput={false}
      showButton={false}
      showDeleteButton={false}
      defaultActions={false}
      backButton
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      macroFilters={() => {
        return (
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
            }}
          >
            <DatePickerUncontrolled
              value={startDate}
              onChange={setStartDate}
              label={`${t(translationPath + "start_date")}`}
              isClearable={false}
            />
            <DatePickerUncontrolled
              value={endDate}
              onChange={setEndDate}
              label={`${t(translationPath + "end_date")}`}
              isClearable={false}
            />
          </Stack>
        );
      }}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a robot"
      }
    />
  );
};

export default RobotsExecutionsPage;
