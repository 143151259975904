import { ISalesContractClassification } from "../models/sales-contract-classification";
import MainApiService from "./mainApi.service";
import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import {
  IProductContractClassification,
  IProductContractClassificationPayload,
} from "../models/contract-product-classification";

export const SALES_CONTRACT_CLASSIFICATION_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/salesContractClassification/";

export const salesContractClassificationService =
  new MainApiService<ISalesContractClassification>(
    SALES_CONTRACT_CLASSIFICATION_ROUTE
  );


const serviceURL =
  process.env.REACT_APP_MAIN + "/api/v1/SalesContractClassification";

interface UpdateContent {
  id: string;
  item: Partial<IProductContractClassificationPayload>;
}

export const createContractProductClassification = (
  payload: IProductContractClassificationPayload
) => {
  return GlobalAxios.post(serviceURL, payload);
};

export const updateContractProductClassification = ({
  id,
  item,
}: UpdateContent) => {
  return GlobalAxios.put(`${serviceURL}/${id}`, item);
};

export const findContractProductClassificationById = (id: string) => {
  return GlobalAxios.get<IProductContractClassification>(`${serviceURL}/${id}`);
};

export const listContractProductsClassification = () => {
  return GlobalAxios.get<ISelectType[]>(`${serviceURL}/list`);
};

export const removeContractProductClassification = (id: string) => {
  return GlobalAxios.delete<boolean>(`${serviceURL}/${id}`);
};
