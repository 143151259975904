import { AutoComplete, ISelectType, useFetch } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import TicketServAreaService from "../../../../../../services/ticketServiceArea.service";

const translationPath = "chatBot.page.service.ticketServiceArea.";
const ticketServiceArea = new TicketServAreaService();

const TicketServiceAreaField = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [serviceAreaOptions, setServiceAreaOptions] = useState<ISelectType[]>(
    []
  );

  const { sendRequest: sendTicketServiceAreaRequest } = useFetch(
    ticketServiceArea.list
  );

  const loadServiceAreaOptions = useCallback(async () => {
    const { data, success } = await sendTicketServiceAreaRequest(undefined);
    if (data && success) {
      if (id) {
        setServiceAreaOptions(data.filter((d) => d.id !== id));
      } else {
        setServiceAreaOptions(data);
      }
    }
  }, [sendTicketServiceAreaRequest, id]);

  useEffect(() => {
    loadServiceAreaOptions();
  }, [loadServiceAreaOptions]);

  return (
    <Grid item xs={12} sm={6}>
      <AutoComplete
        getOptionLabel={(option) => {
          return option.name;
        }}
        size="small"
        label={t(translationPath + "ticket_service_area_parent")}
        name="ticketServiceArea"
        options={serviceAreaOptions}
      />
    </Grid>
  );
};

export default memo(TicketServiceAreaField);
