import { Badge, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "@4uhub/lib4uhub";
import { useNavigate } from "react-router-dom";

import { ISecondaryMenuItem } from "../../../models/menu";

const MenuSecondaryItem: React.FC<ISecondaryMenuItem> = ({
  link,
  name,
  icon,
  id,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleExtension = () => {
    navigate(link);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Typography variant="h6">
          {t("components.menu.menu_secondary_item.modal_title")}
        </Typography>
        <Typography variant="subtitle1">
          {t("components.menu.menu_secondary_item.modal_content")}
        </Typography>
      </Modal>
      <Badge
        badgeContent={id !== "extensions_store" ? "DEV" : undefined}
        color="primary"
        key={id}
      >
        <Button
          startIcon={icon}
          color="inherit"
          onClick={id !== "extensions_store" ? handleOpen : handleExtension}
        >
          {name}
        </Button>
      </Badge>
    </>
  );
};
export default MenuSecondaryItem;
