import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table } from "@4uhub/lib4uhub";
import { Chip } from "@mui/material";
import MainApi from "../../../../services/mainApi.service";
import { IBeneficiaryCancellationReason } from "../../../../models/beneficiary-cancellation-reason";
import { buildTPath } from "./beneficiaryCancellationReason.utils";

const serviceURL =
  process.env.REACT_APP_MAIN +
  "/api/v1/BeneficiaryContractReasonOfCancellation/";

const service = new MainApi<IBeneficiaryCancellationReason>(serviceURL);

const BeneficiaryCancellationReasonPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const translate = (path: string) => t(buildTPath(path));

  const columns: GridColDef<IBeneficiaryCancellationReason>[] = [
    {
      field: "name",
      headerName: translate("name"),
    },
    {
      field: "enable",
      maxWidth: 100,
      headerName: translate("status"),
      renderCell: ({ value }) => (
        <Chip
          size="small"
          color={value ? "success" : "error"}
          label={value ? translate("active") : translate("inactive")}
        />
      ),
    },
  ];

  const handleRowClick = ({
    id,
  }: GridRowParams<IBeneficiaryCancellationReason>) => {
    navigate(`${id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  return (
    <Table<IBeneficiaryCancellationReason>
      showDefaultMacroFilters={false}
      service={service}
      columns={columns}
      title={translate("title")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={translate("search")}
      addButtonLabel={translate("add")}
      confirmOptions={{
        title: translate("confirm.title"),
        description: translate("confirm.description"),
        confirmationButtonProps: { variant: "contained" },
      }}
    />
  );
};

export default BeneficiaryCancellationReasonPage;
