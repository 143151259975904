import { Grid } from "@mui/material";
import { FormContainer, FormInput, Treturn, useFetch } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";

import {
  TDashBoardPanelForm,
  dashboardPanelSchema,
} from "./DashboardPanelSchema";
import DashBoardPanelService from "../../../../services/dashboardpanel.service";

const TRANSLATION_PATH = "page.edit_panel.";

const dashboardpanelService = new DashBoardPanelService();

const DashBoardPanel = () => {
  const { t } = useTranslation();

  const { sendRequest: update, loading: uLoading } = useFetch(
    dashboardpanelService.update
  );

  const { sendRequest: get, loading: gLoading } = useFetch(
    dashboardpanelService.getById
  );

  const { sendRequest: save, loading: sLoading } = useFetch(
    dashboardpanelService.create
  );

  const updateFunctionHandler = useCallback(
    (v: TDashBoardPanelForm, id: string) =>
      update({
        item: {
          ...v,
        },
        id: id,
      }),
    [update]
  );

  const getFunctionHandler = useCallback(
    async (id: string): Promise<Treturn<TDashBoardPanelForm>> => {
      const { data } = await get(id);
      if (data) {
        return { data, success: true };
      }
      return {
        data: null,
        success: false,
      };
    },
    [get]
  );

  const saveFunctionHandler = useCallback(
    (v: TDashBoardPanelForm) => {
      return save(v.name);
    },
    [save]
  );

  const loading = useMemo(
    () => uLoading || gLoading || sLoading,
    [uLoading, gLoading, sLoading]
  );

  return (
    <FormContainer<TDashBoardPanelForm>
      updateFunction={updateFunctionHandler}
      saveFunction={saveFunctionHandler}
      getFunction={getFunctionHandler}
      loading={loading}
      title={t(TRANSLATION_PATH + 'title')}
      schema={dashboardPanelSchema}
      subtitle={t(TRANSLATION_PATH + 'subtitle')}
      subtitleWatchField={"name"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormInput
              size="small"
              autoFocus={true}
              name="name"
              label={t(TRANSLATION_PATH + 'name')}
              fullWidth
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default DashBoardPanel;
