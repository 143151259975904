import { GlobalAxios } from "../axios/axios-config";
import { ISearchParams, ISearchReturn } from "../models/requests";
import axios from "axios";
import { IGetSitesApps, ISitesApps } from "../models/site-app";
import { ISelectType } from "@4uhub/lib4uhub";
import { ISiteAppList } from "../models/tickets-service";

const SITE_APP_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/SiteApp";

const SITEAPP_EXTENSION_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/SiteAppExtension/";

export class SitesAppsService {
  create = (item: ISitesApps) => {
    return GlobalAxios.post(SITE_APP_ROUTE, item);
  };

  getAll = (serchParams?: ISearchParams) => {
    return axios.get<ISearchReturn<IGetSitesApps>>(SITE_APP_ROUTE, {
      params: serchParams,
    });
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<IGetSitesApps>(SITE_APP_ROUTE + "/" + id);
  };

  updateItemById = ({ item, id }: { item: ISitesApps; id: string }) => {
    return GlobalAxios.put(SITE_APP_ROUTE + "/" + id, item);
  };

  deleteItemById = (id: string) => {
    return GlobalAxios.delete(SITE_APP_ROUTE + id);
  };

  list = (params?: any) => {
    return GlobalAxios.get<ISelectType[]>(SITE_APP_ROUTE + "list", {
      params: params,
    });
  };

  siteAppExtensionList = (params?: any) => {
    return GlobalAxios.get<ISiteAppList[]>(SITEAPP_EXTENSION_ROUTE + "list", {
      params: params,
    });
  };
}

export default SitesAppsService;
