import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { ISelectType, useFormContext } from "@4uhub/lib4uhub";
import { useFieldArray } from "react-hook-form";

export interface ICustomButton {
  id: string;
  order: number;
  text: string;
}

export interface ICtaButton {
  id: string;
  order: number;
  actionType: ISelectType;
  text: string;
  payload: string;
}

export interface ITemplateWhatsBasicData {
  title: string;
  body: string;
  footer: string;
}

export type TButtonsList = "1" | "2";

interface ITemplateWhatsContextProps {
  customButtons: ICustomButton[];
  ctaButtons: ICtaButton[];
  buttonsList: TButtonsList[];
  templateWhatsBasicData: ITemplateWhatsBasicData;
  showAllOptions: boolean;
  isFixedData: boolean;
  variables: string[];
  addCtaHandler: (ctaButton: ICtaButton) => void;
  updateCtaHandler: (id: string, fieldName: string, value: string) => void;
  deleteCtaHandler: (id: string, index: number) => void;
  reorderCtaButtons: (list: ICtaButton[]) => void;
  addCustomButtonHandler: (customButton: ICustomButton) => void;
  updateCustomButtonHandler: (
    id: string,
    fieldName: string,
    value: string
  ) => void;
  deleteCustomButtonHandler: (id: string) => void;
  reorderCustomButtons: (list: ICustomButton[]) => void;
  updateBasicData: (fieldName: string, value: string) => void;
  toggleButtonsList: (item: TButtonsList) => void;
  openAllOptions: () => void;
  closeAllOptions: () => void;
  fixDataHandler: (value: boolean) => void;
  setFixedCustomButton: (value: ICustomButton, index: number) => void;
  removeFixedCustomButton: (index: number) => void;
  fetchVariables: (values: string[]) => void;
  setFixedCtaButton: (value: ICtaButton, index: number) => void;
}

const TemplateWhatsContext = createContext<
  ITemplateWhatsContextProps | undefined
>(undefined);

interface ITemplateWhatsProviderProps {
  defaultCtaButtons?: ICtaButton[];
  defaultCustomButtons?: ICustomButton[];
  defaultbody: string;
  buttonsOrder: TButtonsList[];
  children: React.ReactNode;
  onGetButtonsOrder: (list: TButtonsList[]) => void;
}

export const TemplateWhatsProvider: React.FC<ITemplateWhatsProviderProps> =
  memo(
    ({
      defaultCtaButtons,
      defaultCustomButtons,
      buttonsOrder,
      defaultbody,
      children,
      onGetButtonsOrder,
    }) => {
      const { control, setValue, unregister } = useFormContext();

      const { remove } = useFieldArray({ name: "ctaButtons", control });

      const [variables, setVariables] = useState<string[]>([]);

      const [customButtons, setCustomButtons] = useState<ICustomButton[]>(
        defaultCustomButtons || []
      );

      const [ctaButtons, setCtaButtons] = useState<ICtaButton[]>(
        defaultCtaButtons
          ? defaultCtaButtons.filter((btn) => btn.actionType.id !== "0")
          : []
      );

      const [buttonsList, setButtonsList] = useState<TButtonsList[]>(
        buttonsOrder && buttonsOrder.length > 0 ? buttonsOrder : ["1", "2"]
      );

      const [showAllOptions, setShowAllOptions] = useState<boolean>(false);

      const [isFixedData, setIsFixedData] = useState<boolean>(true);

      const fetchVariables = useCallback((values: string[]) => {
        setVariables(values);
      }, []);

      const fixDataHandler = useCallback((value: boolean) => {
        setIsFixedData(value);
      }, []);

      const openAllOptions = useCallback(() => {
        setShowAllOptions(true);
      }, []);

      const closeAllOptions = useCallback(() => {
        setShowAllOptions(false);
      }, []);

      const toggleButtonsList = useCallback(
        (item: TButtonsList) => {
          const index = buttonsList.findIndex((button) => button === item);
          if (index === 0 || index === 1) {
            setButtonsList((prevList) => {
              const newList = [...prevList];
              [newList[0], newList[1]] = [newList[1], newList[0]];
              return newList;
            });
          }
        },
        [buttonsList]
      );

      const [templateWhatsBasicData, setTemplateWhatsBasicData] =
        useState<ITemplateWhatsBasicData>({
          title: "",
          body: defaultbody,
          footer: "",
        });

      const updateBasicData = useCallback(
        (fieldName: string, value: string) => {
          setTemplateWhatsBasicData((prev) => ({
            ...prev,
            [fieldName]: value,
          }));
        },
        []
      );

      const addCustomButtonHandler = useCallback(
        (customButton: ICustomButton) => {
          setCustomButtons((prev) => [...prev, customButton]);
        },
        []
      );

      const updateCustomButtonHandler = useCallback(
        (id: string, fieldName: string, value: string) => {
          setCustomButtons((prev) => {
            const updatedCustomButtons = prev.map((customButton) => {
              if (customButton.id === id) {
                return {
                  ...customButton,
                  [fieldName]: value,
                };
              }

              return customButton;
            });

            return updatedCustomButtons;
          });
        },
        []
      );

      const deleteCustomButtonHandler = useCallback((id: string) => {
        setCustomButtons((prev) =>
          prev.filter((ctaButton) => ctaButton.id !== id)
        );
      }, []);

      const reorderCustomButtons = useCallback((list: ICustomButton[]) => {
        setCustomButtons(list);
      }, []);

      const addCtaHandler = useCallback((ctaButton: ICtaButton) => {
        setCtaButtons((prev) => [...prev, ctaButton]);
      }, []);

      const updateCtaHandler = useCallback(
        (id: string, fieldName: string, value: string) => {
          setCtaButtons((prev) => {
            const updatedCtaButtons = prev.map((ctaButton) => {
              if (ctaButton.id === id) {
                return {
                  ...ctaButton,
                  [fieldName]: value,
                };
              }

              return ctaButton;
            });

            return updatedCtaButtons;
          });
        },
        []
      );

      const deleteCtaHandler = useCallback(
        (id: string, index: number) => {
          setCtaButtons((prev) =>
            prev.filter((ctaButton) => ctaButton.id !== id)
          );
          remove(index);
        },
        [remove]
      );

      const reorderCtaButtons = useCallback((list: ICtaButton[]) => {
        setCtaButtons(list);
      }, []);

      const setFixedCtaButton = useCallback(
        (value: ICtaButton, index: number) => {
          setValue(`ctaButtons.${index}`, value);
          addCtaHandler(value);
        },
        [setValue, addCtaHandler]
      );

      const setFixedCustomButton = useCallback(
        (value: ICustomButton, index: number) => {
          setValue(`customButtons.${index}`, value);
          addCustomButtonHandler(value);
        },
        [setValue, addCustomButtonHandler]
      );

      const removeFixedCustomButton = useCallback(
        (index: number) => {
          if (!customButtons || customButtons.length === 0) return;
          unregister(`customButtons.${index}`);
          deleteCustomButtonHandler(customButtons[index].id);
        },
        [unregister, deleteCustomButtonHandler, customButtons]
      );

      useEffect(() => {
        onGetButtonsOrder(buttonsList);
      }, [onGetButtonsOrder, buttonsList]);

      const value = useMemo(
        () => ({
          customButtons,
          ctaButtons,
          buttonsList,
          templateWhatsBasicData,
          showAllOptions,
          isFixedData,
          variables,
          addCtaHandler,
          updateCtaHandler,
          deleteCtaHandler,
          reorderCtaButtons,
          addCustomButtonHandler,
          updateCustomButtonHandler,
          deleteCustomButtonHandler,
          reorderCustomButtons,
          updateBasicData,
          toggleButtonsList,
          openAllOptions,
          closeAllOptions,
          fixDataHandler,
          setFixedCustomButton,
          setFixedCtaButton,
          removeFixedCustomButton,
          fetchVariables,
        }),
        [
          customButtons,
          ctaButtons,
          buttonsList,
          templateWhatsBasicData,
          showAllOptions,
          isFixedData,
          variables,
          addCtaHandler,
          updateCtaHandler,
          deleteCtaHandler,
          reorderCtaButtons,
          addCustomButtonHandler,
          updateCustomButtonHandler,
          deleteCustomButtonHandler,
          reorderCustomButtons,
          updateBasicData,
          toggleButtonsList,
          openAllOptions,
          closeAllOptions,
          fixDataHandler,
          setFixedCustomButton,
          setFixedCtaButton,
          removeFixedCustomButton,
          fetchVariables,
        ]
      );

      return (
        <TemplateWhatsContext.Provider value={value}>
          {children}
        </TemplateWhatsContext.Provider>
      );
    }
  );

export const useTemplateWhats = () => {
  const context = useContext(TemplateWhatsContext);

  if (!context) {
    throw new Error("useTemplateWhats must be used with TemplateWhatsProvider");
  }

  return context;
};
