import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import WhatsConfigData from "./WhatsConfigData";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import VerifyToken from "./VerifyToken";

const translationPath = "chatBot.page.register.ticketServiceWhats.";

const WhatsappApiConfigs = ({ verifyToken }: { verifyToken: string }) => {
  const { t } = useTranslation();
  const { ticketServiceId } = useParams();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      item
      sm={12}
      pl={2}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"start"}
      sx={(theme) => ({
        width: "100%",
        maxWidth: matches ? 300 : undefined,
        backgroundColor:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        borderRadius: 1,
      })}
    >
      <Box
        display={"flex"}
        flexDirection={matches ? "column" : "row"}
        alignItems={matches ? "start" : "center"}
      >
        <Typography
          mr={matches ? 0 : 2}
          sx={(theme) => ({
            color:
              theme.palette.mode === "light"
                ? theme.palette.grey[600]
                : theme.palette.grey[200],
          })}
        >
          {t(translationPath + "webhook_link")}:
        </Typography>
        {process.env.REACT_APP_ENVIRONMENT?.includes("development") && (
          <WhatsConfigData
            tooltipTilte={t(translationPath + "webhook_link_tooltip")}
            data={`https://api.dev.4uhub.com.br/whatsapp/api/v1/Webhook/
              ${ticketServiceId}`}
          />
        )}
        {process.env.REACT_APP_ENVIRONMENT === "homolog" && (
          <WhatsConfigData
            tooltipTilte={t(translationPath + "webhook_link_tooltip")}
            data={`https://whatsapp.hml.4uhub.com.br/api/v1/Webhook/
              ${ticketServiceId}`}
          />
        )}
        {process.env.REACT_APP_ENVIRONMENT === "production" && (
          <WhatsConfigData
            tooltipTilte={t(translationPath + "webhook_link_tooltip")}
            data={`https://whatsapp.4uhub.com.br/api/v1/Webhook/
              ${ticketServiceId}`}
          />
        )}
      </Box>
      {verifyToken.length !== 0 && <VerifyToken verifyToken={verifyToken} />}
    </Grid>
  );
};

export default WhatsappApiConfigs;
