import { Theme } from "@emotion/react";
import { Stack, SxProps, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface INotFoundProps {
  message?: string;
  style?: SxProps<Theme>;
}
const NotFound: React.FC<INotFoundProps> = ({ message, style }) => {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        ...style,
      }}
    >
      <img src={"/illustrations/no-search-result.png"} alt="a broken telescope" />
      {message && (
        <Typography sx={(t) => ({ ...t.typography.h5, marginLeft: -15 })}>
          {t(message)}
        </Typography>
      )}
    </Stack>
  );
};

export default NotFound;
