import { z } from "zod";

const translationPath = "page.register.banks.errors.";

export const bankSchema = z.object({
  id: z.string().optional(),
  shortName: z.string().optional().nullable(),
  name: z.string().min(1, translationPath + "name"),
  ispbCode: z.string().min(1, translationPath + "ispb_code"),
  bacenCode: z.string().optional().nullable(),
  ibgeCityCode: z.string().optional().nullable(),
});

export type TBankForm = z.infer<typeof bankSchema>;
