import { GlobalAxios } from "../axios/axios-config";
import {
  IGetNotificationById,
  INewNotification,
} from "../models/notifications";

const NOTIFICATION_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Notification/";

export const createNotification = (item: INewNotification) => {
  return GlobalAxios.post(NOTIFICATION_ROUTE, item);
};

export const getNotificationById = (id: string) => {
  return GlobalAxios.get<IGetNotificationById>(NOTIFICATION_ROUTE + id);
};

export const updateNotificationById = ({
  item,
  id,
}: {
  item: INewNotification;
  id: string;
}) => {
  return GlobalAxios.put(NOTIFICATION_ROUTE + id, item);
};

export const generateNotificationBatch = (id: string) => {
  return GlobalAxios.put(
    NOTIFICATION_ROUTE + "GenerateNotificationBatch/" + id,
    { id: id }
  );
};

export const cancelNotificationBatch = (id: string) => {
  return GlobalAxios.put(NOTIFICATION_ROUTE + "CancelNotificationBatch/" + id, {
    id: id,
  });
};
