import AssistantIcon from "@mui/icons-material/Assistant";
import { IconButton, Tooltip } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const AssistentButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t("components.scheduleAssistant.button")}>
      <IconButton onClick={onClick}>
        <AssistantIcon />
      </IconButton>
    </Tooltip>
  );
};
export default memo(AssistentButton);
