import { GlobalAxios } from "../axios/axios-config";
import { IWorkGroupType } from "../models/work-group-type";

const WORK_GROUP_TYPES_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/HealthProgramWorkGroupType/";

export const createWorkGroupType = (item: IWorkGroupType) => {
  return GlobalAxios.post(WORK_GROUP_TYPES_ROUTE, item);
};

export const getWorkGroupTypeById = (id: string) => {
  return GlobalAxios.get<any>(WORK_GROUP_TYPES_ROUTE + id);
};

export const updateWorkGroupTypeById = ({
  item,
  id,
}: {
  item: IWorkGroupType;
  id: string;
}) => {
  return GlobalAxios.put(WORK_GROUP_TYPES_ROUTE + id, item);
};
