import { z } from "zod";

const translationPath =
  "page.settings.preventMedicine.healthProgramTypes.errors.";

const language = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional(),
  },
  { invalid_type_error: translationPath + "language" }
);

const healthProgramTypeLanguageSchema = z
  .object({
    name: z.string().min(1, translationPath + "name"),
    description: z.string().optional().nullable(),
    language: language,
  })
  .superRefine((val, ctx) => {
    if (val.description) {
      if (val.description.length > 500) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: translationPath + "description",
          path: ["description"],
        });
      }
    }
  });

export const healthProgramTypeSchema = z.object({
  id: z.string().optional(),
  enable: z.boolean().optional(),
  healthProgramTypeLanguages: z.array(healthProgramTypeLanguageSchema),
});

export type THealthProgramTypeForm = z.infer<typeof healthProgramTypeSchema>;
