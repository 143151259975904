const es = {
  translations: {
    home: {
      message: "¡Hola Mundo!",
    },
    page: {
      panels: {
        title: "Paneles",
        edit: "Para editar",
        search: "Buscar un panel",
        add: "Agregar panel",
        confirm: {
          title: "Eliminar panel?",
          description:
            "Esto eliminará permanentemente el panel, sus paneles y todos los datos.",
        },
        name: "Nombre",
      },
      edit_panel: {
        title: "Panel",
        subtitle: "Nuevo panel",
        name: "Nombre",
      },
      under_construction: {
        title: "Bajo construcción",
        subtitle:
          "  Esta sección está en construcción, mientras no esté terminada visite una de las otras secciones",
        button: " Visite el registro de genéricos",
      },
      login: {
        login: "Acceso",
        accessing: "Accediendo",
        welcome: "Bienvenido de nuevo",
        rememberme: "Acuérdate de mí",
        forget_password: "¿Olvidaste tu contraseña?",
        username: "CPF",
        password: "Contraseña",
      },
      not_found: {
        page_not_found: "¡Uy! ",
        server_not_responding: "Servidor no responde",
        try_again: "Intentar otra vez",
        back_to_begin: "De vuelta a casa",
        back_to_login: "Atrás para iniciar sesión",
        something_went_wrong: "Algo salió mal",
      },
      dashboard: {
        dashboard: "¡Muy bienvenido!",
        account_balance: "Saldo de la cuenta",
        refresh_button: "Actualizar",
        idle: "Inactivo",
        active: "Activo",
      },
      register: {
        generics: {
          identifier: "Identificador",
          code: "Código",
          value: "Valor",
          generics: "Genéricos",
          generic: "Genérico",
          description: "Descripción",
          delete_generics: "Eliminar genéricos",
          new_generic: "Nuevo Genérico",
          search_identifier: "Buscar un identificador",
          edit_generic: "Editar genérico",
          confirm: {
            title: "¿Eliminar genérico?",
            description: "Esto eliminará permanentemente el genérico",
            cancellationText: "Cancelar",
            confirmationText: "si, eliminar",
          },
          errors: {
            identifier: "El identificador es obligatorio",
            code: "El código es obligatorio",
            value: "El valor es obligatorio",
          },
        },
        roles: {
          name: "Nombre",
          establishments: "Establecimientos",
          full_name: "Nombre completo",
          privileges: "Privilegios",
          roles: "Perfiles",
          profiles: "roles",
          enable: "Permitir",
          roles_profile: "Roles que tendrán este perfil vinculado",
          delete_role: "Borrar perfil",
          search_role: "Buscar por un perfil",
          description: "Descripción",
          new_role: "Nuevo perfil",
          software: "Software",
          select_software: "Seleccionar un software",
          config_access: "Configuración de acceso al perfil",
          confirm: {
            title: "¿Borrar perfil?",
            description: "Esto eliminará permanentemente el perfil.",
          },
          errors: {
            name: "El nombre es obligatorio",
            description: "La descripción es obligatoria",
            establishments: "Al menos un establecimiento es obligatorio",
            privilege: "Al menos un privilegio es obligatorio",
            software: "El software es obligatorio",
          },
        },
        legalPerson: {
          new_legalPerson: "Nueva Entidad Jurídica",
          legalPersons: "Entidades legales",
          delete_legalPersons: "Eliminar entidades legales",
          search_legalPerson:
            "Búsqueda de documento, razón social o nombre de fantasía",
          add_legalPerson: "Agregar entidad legal",
          document: "Documento",
          corporateName: "Nombre de empresa",
          tradingName: "Nombre de fantasía",
          contacts: "Contactos",
          taxRegime: "Régimen Tributario",
          fiscalContributor: "contribuyente fiscal",
          stateRegistration: "Inscripción estadual",
          municipalInscription: "inscripción municipal",
          foreigner: "Extranjero",
          country: "País",
          contactType: "Tipo de Contacto",
          addContact: "Agregar contacto",
          addresses: "direcciones",
          add_address: "Añadir dirección",
          addressType: "Tipo de dirección",
          zip_code: "Código postal",
          city: "Ciudad:",
          province: "Estado:",
          addressDescription: "Calle",
          establishmentId: "Establecimiento",
          neighborhood: "Vecindario",
          number: "Número",
          complement: "Complementar",
          establishment_tooltip: "Ver establecimientos",
          confirm: {
            title: "¿Eliminar entidad legal?",
            description: "Esto eliminará permanentemente la entidad legal. ",
            title_plural: "¿Eliminar entidades legales?",
            description_plural:
              "Esto eliminará permanentemente las entidades legales.",
          },
          errors: {
            document: {
              nomempty: "El documento es obligatorio.",
              regex: "Número CNPJ no válido",
            },
            corporateName: "El nombre de la empresa es obligatorio",
            country: "El país es obligatorio para los extranjeros.",
            establishment: "El establecimiento es obligatorio.",
            fiscal_contribuitor: "El contribuyente fiscal es obligatorio.",
            tax_regime: "El régimen fiscal es obligatorio.",
            contact: {
              contactTypeId: "El tipo de contacto es obligatorio",
              phone: "Numero de telefono invalido",
              email: "Email inválido",
              site: "Sitio inválido",
              other: "El valor es obligatorio",
            },
            address: {
              zipCode: "CEP no válido",
              addressTypeId: "Seleccione un tipo",
              city: "La ciudad es obligatoria",
              province: "El estado es obligatorio",
              addressDescription: "La calle es obligatoria",
              neighborhood: "El vecindario es obligatorio",
              number: "Número invalido",
            },
          },
        },
        languages: {
          languages: "Idiomas",
          new_language: "Nuevo idioma",
          edit_language: "Editar idioma",
          search_language: "Buscar un idioma",
          name: "Nombre",
          code: "Código",
          establishments: "Establecimientos",
          establishment_tooltip: "Ver establecimientos",
          confirm: {
            title: "¿Eliminar idioma?",
            description: "Esto eliminará permanentemente el idioma.",
          },
          errors: {
            name: "El nombre es obligatorio",
            establishments: "El establecimiento es obligatorio.",
          },
        },
        users: {
          users: "Usuarios",
          user: "Usuario",
          new_user: "Nuevo Usuario",
          username: "Acceso",
          full_name: "Nombre completo",
          user_profiles: "Perfiles de usuario",
          user_roles: "Roles del usuario",
          name: "Nombre",
          enable: "Permitir",
          document: "Documento",
          email: "Correo electrónico",
          password: "Contraseña",
          individual: "Individual",
          resend_mail: "Reenviar email",
          email_confirmed_label: "Status del correo electrónico",
          email_confirmed_tag: "Confirmado",
          email_not_confirmed_tag: "No confirmado",
          main_establishments:
            "Establecimiento principal con el que el usuario tiene un vínculo jurídico",
          config: "Configuración de acceso de usuario",
          establishments_access:
            "Establecimientos a los que el usuario puede acceder",
          establishments_tooltip: "Ver establecimientos",
          establishment_tooltip: "Ver establecimiento",
          search_user: "Buscar un usuario",
          add_user: "Agregar usuario",
          roles_inherited:
            "Perfiles que el usuario ha heredado en función de los roles que tiene",
          none_roles_inherited: "No se encontró ningún perfil heredado",
          roles_tooltip: "Ver perfiles",
          email_not_confirmed:
            "Correo electrónico no confirmado por el usuario",
          email_confirmation_date: "Fecha de confirmación por email",
          tooltip_main_estabishment:
            "Por defecto, en el momento de la creación, el establecimiento actual se define como el establecimiento principal",
          reset_password: {
            button: "Restablecer la contraseña",
            title:
              "¿Está seguro de que desea restablecer la contraseña del usuario? {{username}}?",
            title_form: "Reiniciar la contraseña del usuario {{username}}",
            site: "Site",
            site_error: "El site es obligatorio",
            sending: "Enviando",
            email_sent: "Enviamos un enlace de alteración da senha para:",
            confirm: "Reiniciar",
            cancel: "Cancelar",
          },
          errors: {
            username: "El CPF es obligatorio",
            password: "La contraseña es obligatoria",
            individual: "El individuo es obligatorio",
            email: "El correo electrónico es obligatorio",
            establishments: "Seleccione al menos un establecimiento",
            establishment_standard:
              "El establecimiento principal con el que el usuario tiene un vínculo legal es obligatorio",
            roles_profiles:
              "Se debe seleccionar al menos un perfil de usuario o una función de usuario",
          },
          confirm: {
            title: "¿Borrar usuario?",
            description: "Esto eliminará permanentemente al usuario.",
          },
        },
        establishmentParams: {
          establishmentParams: "Parametros generales",
          value: "Valor",
          description: "Descripción",
          public: "Público",
          establishmentParamTypeId: "tipo de parámetro",
          add_establishmentParam: "Agregar parámetro general",
          search_param: "Buscar un parámetro",
          new_param: "Nuevo parámetro",
          confirm: {
            title: "¿Borrar el parametro general?",
            description: "Esto eliminará permanentemente el parametro general.",
          },
          errors: {
            value: "El valor es obligatorio",
            description: "La descripción es obligatoria",
            establishmentParamTypeId: "El tipo de parámetro es obligatorio",
          },
        },
        individuals: {
          individuals: "Individuos",
          individual: "Individual",
          new_individual: "nuevo individuo",
          search_individuals: "Buscar a un individuo",
          add_individual: "Agregar individuo",
          foreigner: "Extranjero",
          identity_card: "Tarjeta de identificación",
          name: "Nombre",
          document: "Documento",
          birth_date: "Fecha de nacimiento",
          contacts: "Contactos",
          add_contact: "Agregar contacto",
          contact_type: "Tipo de Contacto",
          addresses: "direcciones",
          add_address: "Agregar dirección",
          address_type: "Tipo de dirección",
          zip_code: "Código postal",
          city: "Ciudad",
          province: "Estado",
          country: "País",
          address_description: "Calle",
          neighborhood: "Vecindario",
          number: "Número",
          complement: "Complementar",
          gender: "Género",
          family_group: "Grupo familiar",
          add_family_group: "Añadir Grupo Familiar",
          family_individual: "Individual",
          kinship_degree: "Grado de Parentesco",
          imports: "Importaciones",
          confirm: {
            title: "¿Eliminar individuo?",
            description: "Esto eliminará permanentemente a la persona.",
          },
          errors: {
            name: "El nombre es obligatorio",
            document: "El documento es obligatorio.",
            document_regex: "Debe ser un documento válido",
            birth_date: "La fecha de nacimiento es obligatoria",
            country: "El país es obligatorio",
            gender: "El género es obligatorio",
            contact: {
              contactTypeId: "El tipo de contacto es obligatorio",
              phone: "Numero de telefono invalido",
              email: "Email inválido",
              site: "Sitio inválido",
              other: "El valor es obligatorio",
            },
            address: {
              addressTypeId: "El tipo de dirección es obligatorio",
              zipCode: "El código postal es obligatorio",
              city: "La ciudad es obligatoria",
              provincy: "El estado es obligatorio",
              addressDescription: "La calle es obligatoria",
              neighborhood: "El vecindario es obligatorio",
              number: "El número es obligatorio",
            },
            family_individual: "El individuo es obligatorio",
            kinship_degree: "El grado de parentesco es obligatorio",
          },
        },
        individualsImports: {
          imports: "Importaciones",
          import: "Importación",
          search: "Buscar una importación",
          new_import: "Nueva importación",
          file_name: "Nombre del archivo",
          normalized_file_name: "Nombre del archivo normalizado",
          status: "Status",
          add_import: "Añadir importación",
          view_individuals: "Ver Individuos",
          import_individuals: "Importar Individuos",
          import_successfully: "Importación realizada con éxito",
          download_model: "Descargar modelo",
          search_imports: "Buscar una importación",
        },
        importList: {
          imports_individuals: "Importar Individuos",
          name: "Nombre",
          document: "Documento",
          status: "Status",
          birth_date: "Fecha de nacimiento",
          gender: "Género",
          foreigner: "Extranjero",
          yes: "Sí",
          no: "No",
          cns: "CNS",
          address: "Dirección",
          number: "Número",
          neighborhood: "Vecindario",
          complement: "Complementar",
          city: "Ciudad",
          zip_code: "Código postal",
          country: "País",
          neighborhood_code: "Código de vecindario",
          city_ibge_code: "Código IBGE de la ciudad",
          identity_card: "Tarjeta de identificación",
          email: "Email",
          phone: "Teléfono",
          view: "Ver más",
        },
        documents: {
          documents: "Términos y políticas",
          new_document: "Nuevo documento",
          version: "Versión",
          date: "Fecha",
          document: "Documento",
          add_document: "Agregar documento",
          doc_type: "Tipo de Documento",
          select_doc_type: "Seleccionar tipo de documento",
          no_docs_selected: "No se seleccionó ningún tipo de documento",
          no_docs_version: "El tipo de documento no tiene ninguna versión",
          see_document: "ver documento",
          table_title: "Historial de versiones",
          export_csv: "Exportar CSV",
          docsVersion: {
            last_version: "Última versión",
            new_version: "Nueva versión",
            edit_version: "Editar",
          },
          create_document: {
            new_document: "Documento",
            add_new_language: "Añadir nuevo idioma",
            version: "Versión",
            version_date: "Fecha de la versión",
            add_document: "Agregar documento",
            language: "Idioma",
            name: "Nombre",
            content: "Contenido",
            enable: "Permitir",
            language_tooltip: "Ver idioma",
          },
          document_modal: {
            version: "Versión",
            version_date: "Fecha de la versión",
            name: "Nombre",
            language: "Idioma",
            content: "Contenido",
          },
          errors: {
            version: "La versión es obligatoria",
            version_regex: "La versión debe ser un número.",
            language: "El idioma es obligatorio",
            name: "El nombre es obligatorio",
            content: "El contenido es obligatorio",
          },
        },
        establishments: {
          establishments: "Establecimientos",
          establishment: "Establecimiento",
          new_establishment: "Nuevo establecimiento",
          name: "Nombre",
          ansCode: "Código de operador en ANS",
          integration: "Código de establecimiento del cliente",
          domain_admin: "Dominio del panel de administración",
          collector_host: "Anfitrión del coleccionista",
          parent_host: "Nube de recopiladores de host",
          real_time_host: "Anfitrión en tiempo real",
          search_establishment: "Buscar un nombre",
          add_establishment: "Añadir establecimiento",
          company: "Compañía",
          enable: "Permitir",
          config_on_premise: "Configuración del entorno local",
          languages: "Idiomas",
          sync: "Sincronizar entorno on premise",
          sync_beneficiaries: "Sincronizar beneficiarios",
          see_establishments: "Ver Establecimiento on premise",
          see_params: "Ver Parámetros on premise",
          see_generics: "Ver Genéricos on premise",
          see_health: "Planes de salud sincronizados",
          see_medic_specialties_on_premise: "Ver especialidades médicas",
          see_medical_practice_areas: "Ver áreas de práctica médica",
          see_provider_types: "Ver tipos de proveedores",
          see_professional_councils: "Ver consejos profesionales",
          see_extensions: "Ver extensiones on premise",
          synced: "Sincronizado con éxito",
          jobs: "Jobs",
          crmDomain: "Dominio de CRM",
          languages_tooltip: "Ver idiomas",
          extensions_on_premise: "Extensiones on premise",
          generics_on_premise: "Genéricos on premise",
          params_on_premise: "Parámetros on premise",
          establishment_on_premise: "Establecimiento on premise",
          health_on_premise: "Planes de salud sincronizados",
          medic_specialties_on_premise: "Especialidades médicas",
          provider_types_on_premise: "Tipos de proveedores",
          professional_councils_on_premise: "Consejos profesionales",
          medical_practice_areas_on_premise: "Áreas de práctica médica",
          domain: "Dominio",
          filemanager_host: "Host del filemanager en la nube",
          softwares: "Aplicaciones",
          legal_entity: "Persona Jurídica",
          confirm: {
            title: "¿Eliminar establecimiento?",
            description: "Esto eliminará permanentemente el establecimiento.",
          },
          errors: {
            name: "El nombre es obligatorio",
            company: "La empresa es obligatoria",
            languages: "Seleccione al menos un idioma",
            integration:
              "El código de establecimiento en el cliente debe ser mayor a 1",
            domain_admin:
              "El dominio del panel de administración es obligatorio",
            collector_https:
              "El host recopilador debe comenzar con 'http://' o 'https://'",
            parent_https:
              "La nube de recopiladores de host debe comenzar con 'http://' o 'https://'",
            real_time_https:
              "El host en tiempo real debe comenzar con 'http://' o 'https://'",
            domain_admin_https:
              "El dominio del panel de administración debe comenzar con 'http://' o 'https://'",
            filemanager_host_https:
              "El host del filemanager en la nube debe comenzar con 'http://' o 'https://'",
            ans_code: "El código de operador en ANS es obligatorio",
            filemanager_host:
              "El host del filemanager en la nube es obligatorio",
            parent_host: "La nube de recopiladores de host es obligatoria",
            collector_host: "El Anfitrión del coleccionista es obligatorio",
            real_time_host: "El anfitrión en tiempo real es obligatorio",
          },
        },
        countries: {
          countries: "Países",
          country: "País",
          search_country: "Buscar un nombre",
          add_country: "Añadir país",
          new_country: "Nuevo país",
          name: "Nombre",
          code: "Código",
          enable: "Permitir",
          ddi_code: "Código DDI",
          phone_mask: "Máscara de teléfono",
          phone_mask_help:
            "La máscara debe enviarse como por ejemplo: (00)000000000, donde el 'cero' representa los números de teléfono",
          confirm: {
            title: "¿Eliminar país?",
            description: "Esto eliminará permanentemente el país.",
          },
          errors: {
            code: "El código es obligatorio",
            name: "El nombre es obligatorio",
          },
        },
        cities: {
          cities: "Ciudades",
          city: "Ciudad",
          new_city: "Ciudad Nueva",
          name: "Nombre",
          provincy: "estado",
          enable: "Permitir",
          ibge_code: "Código IBGE",
          correio_code: "Código postal",
          search_city: "Buscar una ciudad",
          add_city: "Agregar ciudad",
          errors: {
            name: "El nombre es obligatorio",
            ibge_code: "El código IBGE es obligatorio",
            correio_code: "El código postal es obligatorio",
            provincy: "El estado es obligatorio",
          },
        },
        neighborhoods: {
          neighborhoods: "Barrios",
          neighborhood: "Barrio",
          new_neighborhood: "Nuevo barrio",
          search_neighborhoods: "Buscar un barrio",
          add_neighborhood: "Añadir barrio",
          name: "Nombre",
          code: "Código de Correios",
          city: "Ciudad",
          enable: "Permitir",
          errors: {
            name: "El nombre es obligatorio",
            city: "La ciudad es obligatoria",
          },
        },
        companies: {
          companies: "Compañías",
          company: "Empresa",
          search_company: "Buscar una empresa",
          add_company: "Añadir empresa",
          new_company: "Nueva empresa",
          name: "Nombre",
          code: "Código",
          enable: "Permitir",
          company_group: "Grupo de Negocios",
          confirm: {
            title: "¿Eliminar empresa?",
            description: "Esto eliminará permanentemente la empresa",
          },
          errors: {
            code: "El código es obligatorio",
            name: "El nombre es obligatório",
            company_group: "El grupo de negocios es obligatorio",
          },
        },
        provinces: {
          provinces: "Estados",
          province: "Estado",
          search_province: "Buscar un estado",
          add_province: "Agregar estado",
          new_province: "Nuevo estado",
          name: "Nombre",
          code: "Código",
          enable: "Permitir",
          country: "País",
          confirm: {
            title: "¿Eliminar estado?",
            description: "Esto eliminará permanentemente el estado.",
          },
          errors: {
            code: "El código es obligatorio",
            name: "El nombre es obligatorio",
            country: "El país es obligatorio",
          },
        },
        banks: {
          banks: "Bancos",
          bank: "Banco",
          search_bank: "Buscar un banco",
          add_bank: "Añadir banco",
          new_bank: "Nuevo banco",
          name: "Nombre",
          short_name: "Nombre corto",
          ispb_code: "Código ISPB",
          bacen_code: "Código Bacen",
          ibge_city_code: "Código IBGE de la Ciudad",
          confirm: {
            title: "¿Eliminar banco?",
            description: "Esto eliminará permanentemente el banco.",
          },
          errors: {
            name: "El nombre es obligatorio",
            ispb_code: "El código ISPB es obligatorio",
          },
        },
        applications: {
          applications: "Aplicaciones",
          application: "Aplicación",
          new_application: "Nuevo Aplicación",
          add_application: "Agregar Aplicación",
          search: "Buscar una Aplicación",
          name: "Nombre",
          type: "Tipo",
          status: "Status",
          domains: "Dominios",
          domain: "Dominio",
          domain_type: "Tipo de dominio",
          add_domain: "Agregar dominio",
          front_config: "Configuración del tema JSON",
          confirm: {
            title: "¿Borrar aplicación?",
            description: "Esto eliminará permanentemente la Aplicación",
          },
          banners: {
            banners: "Banners",
            banner: "Banner",
            new_banner: "Nuevo banner",
            search: "Buscar un banner",
            add_banner: "Añadir banner",
            name: "Nombre",
            title: "Título",
            alternative_text: "Texto alternativo",
            display_order: "Orden de visualización",
            language: "Idioma",
            site_apps: "Sites o Apps",
            banner_locals: "Ubicaciones donde se mostrará el banner",
            active: "Activo",
            inactive: "Inactivo",
            enable: "Permitir",
            status: "Status",
            media_file: "Archivo multimedia",
            application: "Aplicación",
            targetingLink: "Enlace de redirección",
            banner_local: "Ubicación de visualización del banner",
            confirm: {
              title: "¿Borrar site el banner?",
              description: "Esto eliminará permanentemente el banner",
            },
            errors: {
              name: "El nombre es obligatorio",
              targetingLink: "El enlace no es válido",
              title: "El título es obligatorio",
              alternative_text: "El texto alternativo es obligatorio",
              display_order: "La orden de visualización es obligatoria",
              language: "El idioma es obligatorio",
              site_apps: "Al menos un site o app es obligatorio",
              banner_locals: "Al menos una ubicación es obligatoria",
              file: "El archivo es obligatorio",
            },
            recommendedSize:
              "Dimensiones recomendadas para desktop {{desktopWidth}}x{{desktopHeight}} píxeles. Dimensiones recomendadas para la aplicación {{appWidth}}x{{appHeight}} píxeles.",
          },
          errors: {
            name: "El nombre es obligatorio",
            type: "El tipo es obligatorio",
            status_type: "El status es obligatorio",
            domain_type: "El tipo de dominio es obligatorio",
            front_config: "El configuración del tema JSON es obligatória",
            description: "La descripción es obligatoria",
            file: "El archivo es obligatorio",
            domain: "El dominio es obligatorio",
            domains: "Al menos un dominio es obligatorio",
          },
        },
        faq: {
          faqs: "Áreas de FAQ",
          faq: "Área de FAQ",
          new_faq: "Nueva área de FAQ",
          add_faq: "Añadir área de FAQ",
          search: "Buscar una área de FAQ",
          question_answer: "Pregunta y respuesta",
          active: "Activo",
          inactive: "Inactivo",
          status: "Status",
          name: "Nombre",
          enable: "Permitir",
          whatsapp_phone_number: "Numero de telefono Whatsapp",
          mail: "Email",
          site_app: "Sites o Apps",
          questions_answers: "Preguntas y Respuestas",
          display_order: "Orden de visualización",
          confirm: {
            title: "¿Borrar el FAQ?",
            description: "Esto eliminará permanentemente el FAQ.",
          },
          errors: {
            name: "El nombre es obligatorio",
            site_app: "Al menos un site o app es obligatorio",
            phone_email:
              "El numero de telefono Whatsapp o email es obligatorio",
            mail: "Proporcione un email válido",
            display_order: "La orden de visualización es obligatoria",
          },
        },
        questionsAnswers: {
          questions_answers: "Preguntas y Respuestas",
          question_answer: "Pregunta y Respuesta",
          search: "Buscar una pregunta y respuesta",
          add_questions_answers: "Añadir una pregunta y respuesta",
          new_question_answer: "Nueva pregunta y respuesta",
          display_order: "Orden de visualización",
          language: "Idioma",
          question: "Pregunta",
          answer: "Respuesta",
          enable: "Permitir",
          name: "Nombre",
          status: "Status",
          active: "Activo",
          inactive: "Inactivo",
          was_useful_count: "Recuento 'Fue útil'",
          confirm: {
            title: "¿Borrar la pergunta y respuesta?",
            description: "Esto eliminará permanentemente el FAQ.",
          },
          errors: {
            display_order: "La orden de visualización es obligatoria",
            question: "La pregunta es obligatoria",
            answer: "La respuesta es obligatoria",
            language: "El idioma es obligatorio",
          },
        },
        news: {
          all_news: "Noticias",
          news: "Noticias",
          new_news: "Nuevas noticias",
          add_news: "Añadir noticias",
          search: "Buscar noticias",
        },
        notifications: {
          notifications: "Notificaciones",
          notification: "Notificación",
          add_notification: "Añadir notificaciones",
          new_notification: "Nueva notificación",
          type: "Tipo de notificación",
          status: "Estado de notificación",
          title: "Título",
          content: "Contenido",
          search: "Buscar una notificación",
          site_app: "Sites o Apps",
          language: "Idioma",
          language_tooltip: "Ver idioma",
          contents: "Contenido del lote de notificaciones",
          add_new_content: "Añadir contenido",
          filters: "Filtros por lotes de notificaciones",
          add_new_filter: "Añadir nuevo filtro",
          minimum_age: "Edad mínima",
          maximum_age: "Edad máxima",
          payer: "Pagador",
          holder: "Poseedor",
          both: "Ambos",
          is_payer: "Es un pagador",
          is_not_payer: "No es un pagador",
          is_holder: "Es un titular",
          is_not_holder: "No es un titular",
          profiles: "Perfiles",
          gender: "Género",
          users: "Usuarios",
          start_publish: "Inicio de publicación",
          finish_publish: "Fin de publicación",
          registered: "Registrado",
          generating: "Generando",
          generated: "Generado",
          canceled: "Cancelado",
          error: "Error al generar",
          confirm: {
            title: "¿Borrar la notificación?",
            description: "Esto eliminará permanentemente la notificación.",
          },
          errors: {
            type: "El tipo de notificación es obligatorio",
            site_app: "Al menos un site o app es obligatorio",
            contents: "Al menos un contenido es obligatorio",
            language: "El idioma es obligatorio",
            title: "El título es obligatorio",
            content: "El contenido es obligatorio",
            notification_batch_filter:
              "Si los campos 'Pagador' y 'Titular' tienen el valor 'Ambos', al menos uno de los campos siguientes es obligatorio",
          },
        },
        extensions: {
          extensions: "Extensiones",
          search_name: "Buscar un nombre",
          extension: "Extensión",
          new_extension: "Nueva extensión",
          presentation_order: "Orden de presentación",
          software: "Software",
          name: "Nombre",
          title: "Título",
          description: "Descripción",
          tags: "Tags",
          files: "Archivos",
          file: "Archivo",
          media_files: "Archivos multimedia",
          add_media_file: "Agregar archivo",
          media_type_code: "Tipo de medio",
          extension_dependencies: "Tiene dependencia de extensiones.",
          extension_targets: "Disponible para tipos de aplicaciones",
          enable: "Permitir",
          status: "Status",
          active: "Activo",
          inactive: "Inactivo",
          actions: {
            default_settings: "Configuración por defecto",
            price_rules: "Reglas de precio",
          },
          defaultSettings: {
            default_settings:
              "Configuración predeterminada para: {{extension}}",
            default_setting: "Configuración predeterminada",
            identifier: "Identificador",
            property: "Propiedad",
            value: "Valor",
            description: "Descripción",
            search_identifier: "Buscar identificador",
            add_default_setting: "Agregar configuración predeterminada",
            new_default_setting: "Nueva configuración predeterminada",
            is_public:
              "La configuración de extensión predeterminada es pública",
            errors: {
              identifier: "El identificador es obligatorio",
              value: "El valor es obligatorio",
              property: "La propiedad es obligatoria",
              description: "La descripción es obligatoria",
            },
            confirm: {
              title: "¿Borrar configuración por defecto?",
              description:
                "Esto eliminará permanentemente la configuración por defecto.",
            },
          },
          priceRules: {
            price_rules: "Reglas de precio para: {{extension}}",
            price_rule: "Regla de precio",
            search_type: "Buscar un tipo",
            add_price_rule: "Agregar regla de precio",
            type: "Tipo",
            initial_date: "Inicio del trimestre",
            end_date: "Fin de validez",
            value: "Valor",
            errors: {
              type: "El tipo es obligatorio",
              initial_date: "El inicio del trimestre es obligatorio",
              end_date: "El fin de validez es obligatorio",
              value: "El valor es obligatorio",
            },
            confirm: {
              title: "¿Borrar regla de precio?",
              description: "Esto eliminará permanentemente la regla de precio.",
            },
          },
          errors: {
            display_order: "El orden de presentación es obligatorio",
            software: "El software es obligatorio",
            name: "El nombre es obligatorio",
            title: "El título es obligatorio",
            tags: "Al menos una tag es obligatoria",
            file: "El archivo es obligatorio",
            file_type:
              "Sólo se admiten tipos de archivos .jpg, .jpeg, .png y .webp",
            file_size: "El tamaño máximo permitido es 5MB",
            extension_targets: "Al menos una aplicación es obrigatoria",
          },
        },
        cardsTemplates: {
          cards_templates: "Plantillas de Tarjetas",
          card_template: "Plantilla de Tarjeta",
          search: "Buscar una tarjeta",
          new_card_template: "Nueva Plantilla de Tarjeta",
          add_card_template: "Agregar Plantilla de Tarjeta",
          initial_date: "Inicio del trimestre",
          end_date: "Fin de los términos",
          health_plan: "Plan de salud",
          enable: "Permitir",
          establishment_param_type:
            "Tipo de parámetro de establecimiento directo",
          establishment_param_type_back:
            "Tipo de parámetro de establecimiento para espalda",
          confirm: {
            title: "¿Eliminar plantilla de tarjeta?",
            description:
              "Esto eliminará permanentemente la plantilla de tarjeta",
          },
          errors: {
            initial_date: "El inicio del trimestr es obligatorio",
            end_date: "El fin de los términos es obligatorio",
            health_plan: "El plan de salud es obligatorio",
            establishment_param_type:
              "El tipo de parámetro de establecimiento directo es obligatorio",
            establishment_param_type_back:
              "El tipo de parámetro de establecimiento para espalda es obligatorio",
          },
        },
        companyGroups: {
          company_groups: "Grupos Empresariales",
          company_group: "Grupo de negocios",
          new_company_group: "Nuevo grupo de negocios",
          search_company_groups: "Buscar un grupo de negocios",
          add_company_group: "Añadir grupo de negocios",
          name: "Nombre",
          code: "Código",
          is_main: "Principal",
          enable: "Permitir",
          filter_restriction_type: "Tipo de restricción de filtro",
          confirm: {
            title: "¿Eliminar grupo de negocios?",
            description: "Esto eliminará permanentemente el grupo de negocios",
          },
          errors: {
            name: "El nombre es obligatorio",
            code: "El código es obligatorio",
            filter_restriction_type:
              "El tipo de restricción de filtro es obligatorio",
          },
        },
        paramDefaultEstablishment: {
          param_default_establishment: "Parámetros de establecimiento estándar",
          param_default: "Parámetro de establecimiento estándar",
          new_param_default:
            "Nuevo parámetro predeterminado de establecimiento",
          search_param: "Buscar un parámetro",
          add_param: "Añadir parámetro predeterminado",
          establishment_param_type: "Tipo de parámetro del establecimiento",
          value: "Valor",
          description: "Descripción",
          confirm: {
            title: "¿Eliminar parámetro de establecimiento estándar?",
            description:
              "Esto eliminará permanentemente el parámetro de establecimiento estándar",
          },
          errors: {
            param_type:
              "El tipo de parámetro del establecimiento es obligatorio",
            value: "El valor es obligatorio",
            description: "La descripción es obligatoria",
          },
        },
        holidays: {
          holidays: "Vacaciones",
          holiday: "Día festivo",
          new_holiday: "Nuveo día festivo",
          search_holiday: "Buscar unas vacaciones",
          add_holiday: "Añadir vacaciones",
          name: "Nombre",
          description: "Descripción",
          holiday_type: "Tipo de vacaciones",
          date: "Fecha",
          import_holidays: "Importar feriados nacionales",
          filter: "Filtrar",
          confirm: {
            title: "¿Eliminar vacaciones?",
            description: "Esto eliminará permanentemente el día festivo.",
          },
          errors: {
            name: "El nombre es obligatorio",
            description: "La descripción es obligatoria",
            holiday_type: "El tipo de caciones es obligatorio",
            date: "La fecha es obligatoria",
          },
        },
      },
      additionalSettings: {
        additionalSettings: "Ajustes adicionales",
        search: "Buscar una configuración",
      },
      extensionsStore: {
        title: "Explora el máximo potencial de tu plan",
        subtitle:
          "Consulta las extensiones disponibles para tu plan y obtén una solución aún más completa para tu negocio.",
        no_data: "¡No se encontraron extensiones!",
        no_data_install:
          "Verifique sus extensiones ya instaladas. No hay nuevas extensiones para mostrar.",
        no_data_installed:
          "Consulta lo que hay disponible en la tienda. No hay extensiones instaladas para mostrar.",
        installed_successfully: "Extensión instalada exitosamente",
      },
      noAccess: {
        message:
          "¡Ups! ¡Parece que no tienes permiso para acceder a esta página!",
        button: "volver arriba",
      },
      settings: {
        settings: "Ajustes",
        general: "General",
        cache: "Cache",
        "4uBus": {
          configs: {
            configs: "Configuración general",
            config: "Configuración general",
            new_config: "Nueva configuración general",
            search: "Buscar una configuración general",
            add_config: "Añadir configuración general",
            host_4ubus: "Host de 4uBus",
            host_aws: "Host de AWS",
            establishment: "Establecimiento",
            confirm: {
              title: "¿Eliminar la configuración general?",
              description:
                "Esto eliminará permanentemente la configuración general.",
            },
            errors: {
              host_4ubus: "El host de 4uBus es obligatorio",
              host_aws: "El host de AWS es obligatorio",
              host_4ubus_https:
                "El host de 4uBus debe comenzar con 'http://' o 'https://'",
              host_aws_https:
                "El host de AWS debe comenzar con 'http://' o 'https://'",
            },
          },
          ipm: {
            generalSettings: {
              general_settings: "IPM configuración general",
              general_setting: "IPM configuración general",
              new_general_setting: "Nueva configuración general",
              search: "Buscar una configuración general",
              add_general_setting: "Añadir configuración general",
              host: "Host",
              establishment: "Estabelecimento",
              ipm_user: "Usuario IPM",
              ipm_password: "Contraseña IPM",
              token_4ubus: "Token de 4uBus",
              token_copy: "Copiar token",
              token_copied: "Token de 4uBus copiado exitosamente",
              itens_per_batch: "Artículos por lote",
              total_batches: "Lotes totales",
              heart_beat_time_minutes:
                "Tiempo de verificación por lotes en minutos",
              establishment_codes:
                "Código de establecimientos de envío (separados por coma)",
              confirm: {
                title: "¿Eliminar la configuración general?",
                description:
                  "Esto eliminará permanentemente la configuración general.",
              },
              errors: {
                host: "El host es obligatorio",
                ipm_user: "El usuario IPM es obligatorio",
                ipm_password: "La contraseña IPM es obligatoria",
                token_4ubus: "El token de 4uBus es obligatorio",
                host_https: "El host debe comenzar con 'http://' o 'https://'",
                itens_per_batch: "Los artículos por lote son obligatorios",
                total_batches: "El número total de lotes es obligatorio",
                heart_beat_time_minutes:
                  "El tiempo de verificación por lotes en minutos es obligatorio",
                establishment_codes:
                  "El código de establecimientos de envío es obligatorio",
              },
            },
          },
          zScan: {
            examSendRule: {
              exam_send_rules: "Reglas de envío de exámenes",
              exam_send_rule: "Regla de envío de exámenes",
              add_exam_send_rule: "Añadir regla de envío de exámenes",
              new_exam_send_rule: "Nueva regla de envío de exámenes",
              target_machine: "Máquina de destino",
              proc_classification_integration_id:
                "ID de clasificación del procedimiento",
              initial_date: "Fecha de inicio",
              end_date: "Fecha final",
              enable: "Permitir",
              status: "Status",
              active: "Activo",
              inactive: "Inactivo",
              errors: {
                target_machine: "La máquina de destino es obligatoria",
                proc_classification_integration_id:
                  "El ID de clasificación del procedimiento es obligatorio",
                initial_date: "La fecha de inicio es obligatoria",
                end_date: "La fecha final es obligatoria",
                initial_date_bigger: "La fecha de inicio no puede ser mayor",
              },
              confirm: {
                title: "¿Eliminar la regla de envío de exámenes?",
                description:
                  "Esto eliminará permanentemente la regla de envío de exámenes",
              },
            },
          },
          externalExamPrescription: {
            rules: {
              rules: "Reglas de Generación de Recetas",
              rule: "Regla de Generación de Recetas",
              new_rule: "Nueva regla de generación de recetas",
              add_rule: "Añadir regla de generación de recetas",
              search: "Buscar una regla de generación de recetas",
              medical_procedure_type_code:
                "Código de Tipo de Procedimiento Médico",
              sector_code: "Código de Sector",
              exec_physician_code: "Código de Médico Ejecutivo",
              patient_insurance_code: "Código de Seguro del Paciente",
              initial_date: "Fecha de Inicio",
              end_date: "Fecha Final",
              is_exception: "Es Excepción",
              yes: "Sí",
              no: "No",
              if: "Si",
              then: "Entonces",
              exception: "Excepción (No genera prescripción)",
              errors: {
                initial_date: "La fecha de inicio es obligatoria",
                end_date: "La fecha final es obligatoria",
                initial_date_bigger:
                  "La fecha de inicio no puede ser mayor que la fecha final",
                medical_procedure_type_code:
                  "El código de tipo de procedimiento médico es obligatorio",
                sector_code: "El código del médico ejecutor es obligatorio",
                execPhysicianCode: "O código do médico executor é obrigatório",
                max_medical_procedure_type_code:
                  "El código de tipo de procedimiento médico no puede tener más de 3 caracteres",
                max_sector_code:
                  "El código de sector no puede tener más de 5 caracteres",
                max_exec_physician_code:
                  "El código del médico ejecutor no puede tener más de 10 caracteres",
                max_patient_insurance_code:
                  "El código de seguro del paciente no puede tener más de 5 caracteres",
              },
              confirm: {
                title: "¿Eliminar la regla de generación de recetas?",
                description:
                  "Esto eliminará permanentemente la regla de generación de recetas",
              },
            },
          },
        },
        crm: {
          ticketAutoFinishRules: {
            ticket_auto_finish_rules: "Reglas de Finalización de Ticket (Card)",
            ticket_auto_finish_rule: "Regla de Finalización de Ticket (Card)",
            add_ticket_auto_finish_rule:
              "Añadir Regla de Finalización de Ticket (Card)",
            new_ticket_auto_finish_rule:
              "Nueva regla de finalización de ticket (card)",
            search: "Buscar una regla de finalización de ticket (card)",
            message: "Mensaje",
            minutes: "Minutos",
            initial_date: "Fecha de inicio",
            end_date: "Fecha final",
            finish_rule: "Finalizar",
            finish: "Finalizado",
            finished: "Sí",
            not_finished: "No",
            ticket_type: "Tipo de ticket (card)",
            ticket_channel: "Marco",
            ticket_channel_step: "Lista do marco",
            ticket_workspace: "Área de trabajo",
            message_helper:
              "La variable {{protocolNumber}} será reemplazada por el número de protocolo asignado al ticket (card)",
            confirm: {
              title: "¿Eliminar la regla de finalización de ticket (card)?",
              description:
                "Esto eliminará permanentemente la regla de finalización de ticket (card).",
            },
            errors: {
              minutes: "Los minutos son obligatorios",
              max_minutes: "El valor no puede ser mayor que 720",
              ticket_type: "El tipo de ticket (card) es obligatorio",
              ticket_channel: "El marco es obligatorio",
              initial_date: "La fecha de inicio es obligatoria",
              end_date: "La fecha final es obligatoria",
              end_date_less:
                "La fecha final no puede ser inferior a la fecha de inicio.",
              message: "La mensaje es obligatoria",
              ticket_workspace: "La área de trabajo es obligatoria",
              ticket_channel_step: "La lista do marco es obligatoria",
            },
          },
          protocolsDefault: {
            protocols_default: "Protocolos de Orientación Estándar",
            protocol_default: "Protocolo de Orientación Estándar",
            new_protocol_default: "Nuevo protocolo de orientación estándar",
            add_protocol_default: "Añadir protocolo de orientación estándar",
            search: "Buscar un protocolo de orientación estándar",
            title: "Título",
            message: "Mensaje",
            workspaces: "Áreas de trabajo",
            file: "Archivo",
            description: "Descrición",
            media_files: "Archivos multimedia",
            add_media_file: "Añadir archivo",
            enable: "Permitir",
            status: "Status",
            active: "Activo",
            inactive: "Inactivo",
            confirm: {
              title: "¿Eliminar el protocolo de orientación estándar?",
              description:
                "Esto eliminará permanentemente el protocolo de orientación estándar.",
            },
            errors: {
              title: "El título es obligatorio",
              message: "La mensaje es obligatoria",
              workspaces: "Al menos una área de trabajo es obligatoria",
              description: "La descripción es obligatoria",
              file: "El archivo es obligatorio",
              media_files: "Al menos un archivo es obligatorio",
              min_description: "La descrición es obligatoria",
              max_description:
                "La descripción no puede tener más de 1000 caracteres",
            },
          },
          events: {
            events: "Eventos",
            event: "Evento",
            new_event: "Nuevo evento",
            add_event: "Añadir evento",
            search: "Buscar un evento",
            name: "Nombre",
            event_type: "Tipo de evento",
            code: "Código",
            enable: "Permitir",
            status: "Status",
            active: "Activo",
            inactive: "Inactivo",
            manage_event_types: "Gerenciar tipos de evento",
            ticket_automations: "Automatizaciones",
            confirm: {
              title: "¿Eliminar el evento?",
              description: "Esto eliminará permanentemente el evento",
            },
            errors: {
              name: "El nombre es obligatorio",
              event_type: "El tipo de evento es obligatorio",
              code: "El código es obligatorio",
            },
          },
          eventTypes: {
            event_types: "Tipos de Evento",
            event_type: "Tipo de Evento",
            new_event_type: "Nuevo tipo de evento",
            add_event_type: "Añadir tipo de evento",
            search: "Buscar un tipo de evento",
            name: "Nombre",
            enable: "Permitir",
            code: "Código",
            status: "Status",
            active: "Activo",
            inactive: "Inactivo",
            confirm: {
              title: "¿Eliminar el tipo de evento?",
              description: "Esto eliminará permanentemente el tipo de evento",
            },
            errors: {
              name: "El nombre es obligatorio",
            },
          },
          externalProcessType: {
            external_process_types: "Tipos de Procesos Externos",
            external_process_type: "Tipo de Proceso Externo",
            add_external_process_type: "Añadir tipo de proceso externo",
            new_external_process_type: "Nuevo tipo de proceso externo",
            search: "Buscar un tipo de proceso externo",
            name: "Nombre",
            confirm: {
              title: "¿Eliminar el tipo de proceso externo?",
              description:
                "Esto eliminará permanentemente el tipo de proceso externo",
            },
            errors: {
              name: "El nombre es obligatorio",
            },
          },
        },
        preventMedicine: {
          workGroupTypes: {
            work_group_types: "Tipos de Grupo de Trabajo",
            work_group_type: "Tipo de Grupo de Trabajo",
            new_work_group_type: "Nuevo tipo de grupo de trabajo",
            add_work_group_type: "Añadir tipo de grupo de trabajo",
            enable: "Permitir",
            status: "Status",
            active: "Activo",
            inactive: "Inactivo",
            languages: "Idiomas",
            language: "Idioma",
            add_language: "Añadir idioma",
            name: "Nombre",
            description: "Descripción",
            search: "Buscar un grupo de trabajo",
            confirm: {
              title: "¿Eliminar el grupo de trabajo?",
              description:
                "Esto eliminará permanentemente el grupo de trabajo.",
            },
            errors: {
              name: "El nombre es obligatorio",
              language: "El idioma es obligatorio",
            },
          },
          healthProgramTypes: {
            health_program_types: "Tipos de Programas de Salud",
            health_program_type: "Tipo de Programa de Salud",
            new_health_program_type: "Nuevo tipo de programa de salud",
            add_health_program_type: "Añadir Tipo de Programa de Salud",
            search: "Buscar un tipo de programa de salud",
            name: "Nombre",
            language: "Idioma",
            description: "Descripción",
            enable: "Permitir",
            status: "Status",
            active: "Activo",
            inactive: "Inactivo",
            characters: "caracteres",
            add_language: "Añadir idioma",
            confirm: {
              title: "¿Eliminar el tipo de programa de salud?",
              description:
                "Esto eliminará permanentemente el tipo de programa de salud.",
            },
            errors: {
              name: "El nombre es obligatorio",
              description:
                "La descripción no puede tener más de 500 caracteres.",
            },
          },
        },
        generalSettings: {
          healthChecks: {
            health_checks: "Health Checks de Microservicios 4uHub",
            health_check: "Health Check de Microservicios 4uHub",
            new_health_check: "Novo health check de microservicios 4uHub",
            add_health_check: "Adicionar Health Check de Microservicios 4uHub",
            search: "Buscar un health check de microservicios 4uHub",
            name: "Nombre",
            uri: "URI",
            establishment: "Establecimiento",
            payload: "Payload de falla",
            restored_payload: "Restaurar payload",
            webhook_url: "URL de Webhook",
            enable: "Enable",
            status: "Status",
            active: "Active",
            inactive: "Inactive",
            confirm: {
              title: "¿Eliminar el health check de microservicios 4uHub?",
              description:
                "Esto eliminará permanentemente el health check de microservicios 4uHub.",
            },
            errors: {
              name: "El nombre es obligatorio",
              uri: "El URI es obligatorio",
              payload: "El payload de falla es obligatorio",
              restored_payload: "El restaurar payload de falla es obligatorio",
              webhook_url: "La URL de Webhook es obligatoria",
              webhook_url_http:
                "La URL de Webhook debe comenzar con 'http://' o 'https://'",
              establishment: "El establecimiento es obligatorio",
            },
          },
        },
        schedule: {
          scheduleCheckIn: {
            schedule_check_in: "Check In",
            add_schedule_check_in: "Añadir check in",
            new_schedule_check_in: "Nuevo check in",
            meters_approximation: "Aproximación en metros",
            time: "Tiempo en minutos",
            meters: "m.",
            min: "min.",
            latitude: "Latitud",
            longitude: "Longitud",
            help_time:
              "Ingrese la hora en minutos antes de la cita, cuando será posible registrarse para una cita",
            help_meters:
              "Por favor, informe la distancia aproximada en metros antes de la cita y podrá registrarse para concertar una cita",
            confirm: {
              title: "¿Eliminar el check in?",
              description: "Esto eliminará permanentemente el check in.",
            },
            errors: {
              meters_approximation: "La aproximación en metros es obligatoria",
              time: "El tiempo en minutos es obligatorio",
              latitude: "La latitud es obligatoria",
              longitude: "La longitud es obligatoria",
            },
          },
          scheduleConfirmation: {
            schedule_confirmation: "Confirmación de Agenda",
            add_schedule_confirmation: "Añadir confirmación de agenda",
            new_schedule_confirmation: "Nueva confirmación de agenda",
            time: "Tiempo en minutos",
            min: "min.",
            help: "Ingrese el tiempo en minutos antes de la cita, lo que le permitirá confirmar una cita",
            confirm: {
              title: "¿Eliminar la confirmación de agenda?",
              description:
                "Esto eliminará permanentemente la confirmación de agenda",
            },
            errors: {
              time: "El tiempo en minutos es obligatorio",
            },
          },
        },
        system: {
          executeSql: {
            execute_sql: "Ejecutar SQL",
            execute_successfully: "SQL ejecutado con éxito",
            clean: "Limpiar",
            execute: "Ejecutar",
            errors: {
              sql: "El código SQL es obligatorio",
            },
          },
        },
      },
      extensionSettings: {
        extension_settings:
          "Configuración de extensión para '{{extension}}' en '{{siteApp}}'",
        add_extension_config: "Agregar configuración a la extensión.",
        extension_config: "Configuración de extensión",
        new_extension_config: "Nueva configuración de extensión",
        search_config: "Buscar una configuración",
        identifier: "Identificador",
        property: "Propiedad",
        value: "Valor",
        description: "Descripción",
        is_public: "La configuración de la extensión es pública",
        save_in_establishment:
          "Guarde la configuración para todos los sitios web y aplicaciones de comerciantes",
        confirm: {
          title: "¿Eliminar la configuración de la extensión?",
          description:
            "Esto eliminará permanentemente la configuración de la extensión.",
        },
        errors: {
          identifier: "El identificador es obligatorio",
          property: "La propiedad es obligatoria",
          value: "El valor es obligatorio",
          description: "La descripción es obligatoria",
        },
      },
      jobs: {
        title: "Has sido redirigido al panel de Empleos.",
        subtitle: "Para acceder nuevamente haga clic en el botón de abajo",
        button: "Acceso",
      },
      "4uHcp": {
        hcp: {
          healthPrograms: {
            health_programs: "Programas de Salud",
            health_program: "Programa de Salud",
            new_health_program: "Nuevo programa de salud",
            search: "Buscar un programa de salud",
            add_health_program: "Añadir programa de salud",
            languages: "Idiomas",
            health_program_type: "Tipo de programa de salud",
            applications: "Aplicaciones",
            work_groups: "Grupos de trabajo",
            add_language: "Añadir idioma",
            name: "Nombre",
            language: "Idioma",
            description: "Descripción",
            participants: "Participantes",
            imports: "Importaciones",
            fundraising: "Capturas",
            confirm: {
              title: "¿Eliminar programa de salud?",
              description:
                "Esto eliminará permanentemente el programa de salud",
            },
            errors: {
              health_program_type:
                "El tipo de programa de salud es obligatorio",
              site_apps: "Al menos una aplicación es obligatoria",
              languages: "Al menos um idioma es obligatorio",
              language: "El idioma es obligatorio",
              name: "El nombre es obligatorio",
            },
          },
          healthProgramsContentRules: {
            health_programs_content_rules:
              "Reglas de Contenido de Programas de Salud",
            health_program_content_rule:
              "Regla de Contenido de Programa de Salud",
            new_health_program_content_rule:
              "Nueva regla de contenido de programa de salud",
            search: "Buscar una regla de contenido de programa de salud",
            add_health_program_content_rule:
              "Añadir regla de contenido de programa de salud",
            name: "Nombre",
            health_program: "Programa de salud",
            initial_date: "Fecha de inicio",
            end_date: "Fecha de fin",
            contents: "Contenido de la regla",
            confirm: {
              title: "¿Eliminar regla de contenido de programa de salud?",
              description:
                "Esto eliminará permanentemente la regla de contenido de programa de salud",
            },
            errors: {
              name: "El nombre es obligatorio",
              health_program: "El programa de salud es obligatorio",
              initial_date: "La fecha de inicio es obligatoria",
              end_date: "La fecha de fin es obligatoria",
              initial_date_greater:
                "La fecha de fin no puede ser anterior a la fecha de inicio",
            },
          },
          rulesContents: {
            rules_contents: "Contenidos de Regla",
            rule_content: "Contenido de Regla",
            new_rule_content: "Nuevo contenido de regla",
            search: "Buscar un contenido de regla",
            add_rule_content: "Añadir contenido de regla",
            title: "Título",
            publication_date: "Fecha de publicación",
            content_status: "Status de contenido",
            published: "Publicado",
            not_published: "No publicado",
            status: "Status",
            active: "Activo",
            inactive: "Inactivo",
            confirm: {
              title: "¿Eliminar contenido de regla?",
              description:
                "Esto eliminará permanentemente el contenido de regla",
            },
          },
          workGroups: {
            work_groups: "Grupos de Trabajo",
            work_group: "Grupo de Trabajo",
            new_work_group: "Nuevo grupo de trabajo",
            add_work_group: "Añadir grupo de trabajo",
            search: "Buscar un grupo de trabajo",
            name: "Nombre",
            languages: "Idiomas",
            add_language: "Añadir idioma",
            language: "Idioma",
            description: "Descripción",
            work_group_type: "Tipo de grupo de trabajo",
            work_group_members: "Miembros del grupo de trabajo",
            confirm: {
              title: "¿Eliminar grupo de trabajo?",
              description: "Esto eliminará permanentemente el grupo de trabajo",
            },
            errors: {
              work_group_type: "El tipo de grupo de trabajo es obligatorio",
              languages: "Al menos un idioma es obligatorio.",
              name: "El nombre es obligatorio",
              language: "El idioma es obligatorio",
            },
          },
          participants: {
            participants: "Participantes",
            participant: "Participante",
            new_participant: "Nuevo participante",
            add_participant: "Añadir participante",
            search: "Buscar un participante",
            name: "Nombre",
            document: "CPF",
            status: "Estado",
            history: "Histórico",
            active: "Activo",
            formerMember: "Ex membro",
            membersHistory: {
              title: "Histórico",
              initialDate: "Fecha de entrada",
              endDate: "Fecha de salida",
              noEndDate: "Sin fecha final",
            },
            confirm: {
              title: "¿Eliminar participante?",
              description: "Esto eliminará permanentemente el participante",
            },
          },
          fundraising: {
            fundraising: "Capturas",
            healthProgram: "Programa de salud",
            captation: "Captura",
            new_captation: "Nueva captura",
            search: "Buscar una captura",
            name: "Nombre",
            document: "CPF",
            health_program: "Programa de salud",
            captation_type: "Tipo de captura",
            captation_status: "Status de captura",
            approve_captation: "Aprobar captura",
            approved_successfully: "Captura aprobada exitosamente",
            disapprove_captation: "Desaprobar captura",
            disapproved_successfully: "Captura desaprobada exitosamente",
            denial_reason: "Razón (opcional)",
            cancel: "Cancelar",
            disapprove: "Desaprobar",
          },
          healthProgramImports: {
            imports: "Importaciones",
            healthProgram: "Programa de salud",
            import: "Importación",
            add_import: "Añadir importación",
            new_import: "Nueva importación",
            search: "Buscar una importación",
            file_name: "Nombre del archivo",
            import_person_name: "Nombre del importador",
            origin_of_demand: "Origen de la demanda",
            error_message: "Mensaje de error",
            status: "Status",
            download_template: "Descargar plantilla",
            health_program: "Programa de salud",
            errors: {
              file: "El archivo es obligatorio",
              import_person_name: "El nombre del importador es obligatorio",
              health_program: "El programa de salud es obligatorio",
            },
          },
        },
      },
      "4uBus": {
        register: {
          accessTokens: {
            access_tokens: "Tokens de acceso",
            access_token: "Token de acceso",
            new_access_token: "Nuevo token de acceso",
            add_access_token: "Añadir token de acceso",
            search: "Buscar un token de acceso",
            name: "Nombre",
            privileges: "Privilegios",
            token_public: "Token público",
            copied_token: "Token público copiado exitosamente",
            copy: "Copiar token público",
            created: "Fecha de creación",
            individual: "Individual",
            legal_entity: "Entidad legal",
            software: "Software",
            justification: "Justificación",
            description: "Descripción",
            is_integration: "Token de integración",
            restriction_rule_profiles: "Perfiles de reglas de restricción",
            extension: "Extensión",
            profiles: "Perfiles",
            no_extensions_found:
              "No es posible registrar un token de acceso, ya que no tiene instalada ninguna extensión de terminal de bus.",
            confirm: {
              title: "¿Eliminar el token de acceso?",
              description: "Esto eliminará permanentemente el token de acceso",
            },
            errors: {
              name: "El nombre es obligatorio",
              privileges: "Al menos un privilegio es obligatorio",
            },
          },
          profileTasyBus: {
            profile_tasy_bus: "Perfil - Autobús Tasy",
            search: "Buscar un perfil",
            add_profile_tasy_bus: "Añadir perfil",
            new_profile_tasy_bus: "Nuevo perfil",
            name: "Nombre",
            extension: "Extensión",
            is_global: "Perfil Global",
            enable: "Permitir",
            users: "Usuarios",
            global: "Sí",
            not_global: "No",
            status: "Status",
            active: "Activo",
            inactive: "Inactivo",
            confirm: {
              title: "¿Eliminar el perfil?",
              description:
                "Esto eliminará permanentemente el perfil, todas las reglas de restricción vinculadas a ese perfil se eliminarán y todos los usuarios vinculados se desvincularán.",
            },
            errors: {
              name: "El nombre es obligatorio",
              extension: "La extensión es obligatoria",
            },
          },
          restrictionRules: {
            restriction_rules: "Reglas de Restricción",
            restriction_rule: "Regla de Restricción",
            search: "Buscar una regla de restricción",
            add_restriction_rule: "Añadir regla de restricción",
            new_restriction_rule: "Nueva regla de restricción",
            status: "Status",
            active: "Activo",
            inactive: "Inactivo",
            enable: "Permitir",
            restriction_rule_type: "Tipo de regla de restricción",
            action_type: "Tipo de acción",
            value: "Valor",
            description: "Descripción",
            confirm: {
              title: "¿Eliminar la regla de restricción?",
              description:
                "Esto eliminará permanentemente la regla de restricción",
            },
            errors: {
              restriction_rule_type:
                "El tipo de regla de restricción es obligatorio",
              action_type: "El tipo de acción es obligatorio",
              value: "El valor es obligatorio",
              description: "La descripción es obligatoria",
            },
          },
        },
        relationships: {
          relationship: "Relación",
          search: "Buscar una relación",
          add_relationship: "Añadir relación",
          relationship_type: "Tipo de relación",
          direction_type: "Tipo de segmentación",
          origin_name: "Nombre de origen",
          origin_value: "Valor de origen",
          destiny_name: "Nombre del destino",
          destiny_value: "Valor objetivo",
          enable: "Habilitar",
          default: "Estándar",
          status: "Status",
          active: "Activo",
          inactive: "Inactivo",
          is_default: "Sí",
          is_not_default: "No",
          confirm: {
            title: "¿Eliminar la relación?",
            description: "Esto eliminará permanentemente la relación",
          },
          errors: {
            relationship_type: "El tipo de relación es obligatorio",
            direction_type: "El tipo de segmentación es obligatorio",
          },
        },
        restrictions: {
          schedule: {
            schedule: "Cronograma",
            add_schedule: "Añadir cronograma",
            search: "Buscar una cronograma",
            status: "Status",
            active: "Activo",
            inactive: "Inactivo",
            new_schedule: "Nuevo cronograma",
            enable: "Permitir",
            value: "Valor",
            restriction_rule_type: "Tipo de regla de restricción",
            description: "Descripción",
            confirm: {
              title: "¿Eliminar el cronograma?",
              description: "Esto eliminará permanentemente el cronograma",
            },
            errors: {
              value: "El valor es obligatorio",
              restriction_rule_type:
                "El tipo de regla de restricción es obligatorio",
              description: "La descripción es obligatoria",
            },
          },
        },
        logs: {
          ipmXTasy: {
            ipmxtasy: "IPM - Recibir - IPM x Tasy",
            status: "Status",
            message_status: "Mensaje del status",
            created: "Fecha de creación",
            tasy_individual_identifier: "Cd. persona",
            service_number_search: "Número de servicio",
            service_number: "Número de servicio (IPM)",
            service_number_tasy: "Número de servicio (Tasy)",
            lab_exam_quantity: "Cant Ex Lab",
            exam_nlab: "Examen NLab",
            medicines: "Medicamentos",
            allergy: "Alergia",
            has_evolution: "Tiene evolución",
            evolution: "Sí",
            no_evolution: "No",
            search: "Buscar por status",
            view_logs: "Ver logs",
            logs: "Logs",
          },
          logTasyImp: {
            status: "Estado",
            message_status: "Mensaje de estado",
            created: "Fecha de creación",
            qtyEmrLabExamResult: "Prueba de laboratorio QTD",
            qtyEmrNonLaboratoryTests: "Prueba de laboratorio QTD",
            qtyEvolution: "Evolution Qtd",
            qtyAllergy: "Alergias qtd",
            qtyMedicineInUse: "Medicamentos QTD",
            ipmtasy: "IPMxTasy",
            tasyipm: "TasyxIPM",
            TasyEncounterIntegrationId: "Número de Servicio Tasy",
            TasyIndividualIntegrationId: "Código de Persona Tasy",
            IpmEncounterIntegrationId: "Número de Servicio Ipm",
            IpmIndividualIntegrationId: "Código de Persona Ipm",
          },

          tasyXIpm: {
            tasyxipm: "IPM - Enviar - Tasy x IPM",
            status: "Status",
            message_status: "Mensaje del status",
            created: "Fecha de creación",
            individual_identifier: "Código de persona (Tasy)",
            service_number_search: "Número de servicio",
            service_number: "Número de servicio (Tasy)",
            search: "Buscar por status",
            view_logs: "Ver logs",
            logs: "Logs",
            allergies: "Alergias",
            occurrences: "Ocurrencias",
            exams_lab: "Exámenes Lab",
            exams_n_lab: "Exámenes N Lab",
            medicines: "Medicamentos",
          },
          tasyCalendar: {
            title: "Cronograma del Tasy",
            type_code: "Código",
            created: "Creado en",
            identifier: "Identificador",
            calendar_identifier: "Identificador de calendario",
            calendar_date: "Fecha del calendario",
            status: "Status",
            individual_identifier: "Identificador individual",
            last_modified: "Última modificación",
          },
        },
        sync: {
          sync: "Sincronización",
          sync_all: "Sincronizar todo",
          synced_all: "Sincronización completada exitosamente",
        },
        robots: {
          list: {
            list: "Lista de Robots",
            search: "Buscar un robot",
            name: "Nombre",
            description: "Descripción",
            code: "Código",
            started: "Comenzó",
            last_success: "Último éxito",
            last_error: "Último error",
            last_error_message: "Último mensaje de error",
            status: "Status",
            active: "Activo",
            inactive: "Inactivo",
            executions_robots: "Ejecuciones de Robots",
            executions: {
              executions_robots: "Ejecuciones de Robots",
              job_name: "Nombre del job",
              start_date: "Fecha de inicio",
              end_date: "Fecha de fin",
              execution_status: "Status de ejecución",
            },
          },
        },
      },
      "4uSiteApp": {
        applications: {
          myApplications: {
            my_applications: "Mis aplicaciónes",
            search: "Buscar una aplicación",
            name: "Nombre",
            type: "Tipo",
            status: "Status",
            active: "Activo",
            inactive: "Inactivo",
          },
        },
      },
      "4uSalesContract": {
        registers: {
          grantors: {
            active: "Activo",
            inactive: "Inactivo",
            name: "Nombre",
            status: "Status",
            grantors: "Otorgantes",
            add_grantor: "Agregar Otorgante",
            enabled: "Permitir",
            establishments: "Establecimientos",
            new_grantor: "Nuevo Otorgante",
            legal_entity: "Persona Jurídica",
            search_grantors: "Buscar un Otorgante",
            confirm: {
              title: "¿Eliminar otorgante?",
              description: "Esto eliminará permanentemente al otorgante",
            },
            errors: {
              name: "El nombre es obligatorio",
              establishment: "El establecimiento es obligatorio",
              legal_entity: "La persona jurídica es obligatoria",
            },
          },
          contractAttachmentType: {
            title: "Tipo de Adjunto de Contrato",
            search: "Buscar un Tipo de Adjunto de Contrato",
            add: "Agregar Tipo de Adjunto de Contrato",
            new: "Nuevo Tipo de Adjunto de Contrato",
            active: "Activo",
            inactive: "Inactivo",
            contract_type: "Tipo de Contrato",
            enabled: "Permitir",
            name: "Nombre",
            status: "Estado",
            confirm: {
              title: "¿Eliminar tipo de adjunto de contrato?",
              description:
                "Esto eliminará permanentemente el tipo de adjunto de contrato",
            },
            errors: {
              name: "El nombre es obligatorio",
              contract_type: "El tipo de contrato es obligatorio",
            },
          },
          contractTerminationReason: {
            title: "Motivo de Cancelación de Contrato",
            search: "Buscar Motivo de Cancelación de Contrato",
            add: "Agregar Motivo de Cancelación de Contrato",
            new: "Nuevo Motivo de Cancelación de Contrato",
            active: "Activo",
            inactive: "Inactivo",
            contract_type: "Tipo de Contrato",
            enabled: "Permitir",
            name: "Nombre",
            status: "Estado",
            confirm: {
              title: "¿Eliminar motivo de cancelación de contrato?",
              description:
                "Esto eliminará permanentemente el motivo de cancelación de contrato",
            },
            errors: {
              name: "El nombre es obligatorio",
              contract_type: "El tipo de contrato es obligatorio",
            },
          },
          contract_classifications: {
            name: "Nombre",
            status: "Estado",
            active: "Activo",
            inactive: "Inactivo",
            enabled: "Permitir",
            contract_type: "Tipo de Contrato",
            contract_classification: "Clasificación de Contrato",
            search_contract_classification:
              "Buscar una Clasificación de Contrato",
            add_contract_classification: "Agregar Clasificación de Contrato",
            new_contract_classification: "Nueva Clasificación de Contrato",
            confirm: {
              title: "¿Eliminar clasificación de contrato?",
              description:
                "Esto eliminará permanentemente la clasificación de contrato",
            },
            errors: {
              name: "El nombre es obligatorio",
              contract_type: "El tipo de contrato es obligatorio",
            },
          },
          baseContracts: {
            title: "Club de Beneficios y Ventajas",
            search: "Buscar Club de Beneficios y Ventajas",
            add: "Agregar Club de Beneficios y Ventajas",
            contratation_date: "Fecha de Contratación",
            termination_date: "Fecha de Finalización",
            situation_status: "Estado de la Situación",
            contract_classification: "Clasificación del Contrato",
            name: "Nombre",
            status: "Estado",
            approved: "Aprobado",
            canceled: "Cancelado",
            pending: "En Revisión",
            terminated: "Rescindido",
            confirm: {
              title: "¿Eliminar el club de beneficios y ventajas?",
              description:
                "Esto eliminará permanentemente el club de beneficios y ventajas",
            },
            advantagesAndBenefitsClub: {
              title: "Club de Ventajas y Beneficios",
              advantages_and_benefits_club_contract:
                "Contratos del Club de Ventajas y Beneficios",
              grantor: "Otorgante",
              contract_classification: "Clasificación del Contrato",
              contract_number: "Número de Contrato",
              integration_code: "Código de Integración",
              contract_title: "Título del Contrato",
              renewal_period: "Período de Renovación (meses)",
              main_contract: "Contrato Principal",
              situation: "Situación",
              legal_entity: "Persona Jurídica",
              individual_person: "Persona Física",
              contratation_data: "Datos de la Contratación",
              contract_type: "Tipo de Contrato",
              contratation_date: "Fecha de la Contratación",
              termination_date: "Fecha de Terminación",
              approval_date: "Fecha de Aprobación",
              cancelation_date: "Fecha de Cancelación",
              reactivation_date: "Fecha de Reactivación",
              additional_data:
                "Datos Adicionales para el Club de Ventajas y Beneficios",
              errors: {
                contract_date: "La fecha de contratación es obligatoria",
                contract_type: "El Tipo de Contrato es obligatorio",
                individual_person: "La persona física es obligatoria",
                legal_entity: "La persona jurídica es obligatoria",
                renew_months: "El Período de Renovación es obligatorio",
                grantor: "El Otorgante es obligatorio",
                situation: "La situación es obligatoria",
                contract_classification:
                  "La Clasificación de Contrato es obligatoria",
                contract_title: "El Título del contrato es obligatorio",
              },
            },
          },
          beneficiaryClassification: {
            title: "Clasificación de Beneficiarios",
            new: "Nueva Clasificación de Beneficiarios",
            add: "Agregar Clasificación de Beneficiarios",
            search: "Buscar Clasificación de Beneficiarios",
            name: "Nombre",
            status: "Estado",
            enable: "Permitir",
            active: "Activo",
            inactive: "Inactivo",
            confirm: {
              title: "¿Eliminar clasificación de beneficiarios?",
              description:
                "Esto eliminará permanentemente la clasificación de beneficiarios",
            },
            errors: {
              name: "El nombre es obligatorio",
            },
          },
          contractProductClassifications: {
            title: "Clasificación de Productos",
            search: "Buscar una Clasificación de Productos",
            name: "Nombre",
            status: "Estado",
            active: "Activo",
            inactive: "Inactivo",
            enabled: "Habilitado",
            add: "Agregar Clasificación de Producto",
            new: "Nueva Clasificación de Producto",
            contract_type: "Tipo de Contrato",
            confirm: {
              title: "¿Eliminar clasificación de producto?",
              description:
                "Esto eliminará permanentemente la clasificación de producto",
            },
            errors: {
              name: "El nombre es obligatorio",
              contract_type: "El tipo de contrato es obligatorio",
            },
          },
          channelClassifications: {
            title: "Clasificación del Canal de Ventas",
            search: "Buscar Clasificación del Canal de Ventas",
            add: "Agregar Clasificación del Canal de Ventas",
            new: "Nueva Clasificación del Canal de Ventas",
            name: "Nombre",
            code: "Código",
            enable: "Permitir",
            status: "Estado",
            active: "Activo",
            inactive: "Inactivo",
            confirm: {
              title: "Eliminar Clasificación del Canal de Ventas",
              description:
                "Esto eliminará permanentemente la clasificación del canal de ventas",
            },
            errors: {
              name: "El nombre es obligatorio",
              status: "El estado es obligatorio",
            },
          },
          salesChannel: {
            title: "Canal de Ventas",
            search: "Buscar Canal de Ventas",
            add: "Agregar Canal de Ventas",
            new: "Nuevo Canal de Ventas",
            legal_entity: "Persona Jurídica",
            individual_person: "Persona Física",
            title_placeholder: "Título",
            name: "Nombre",
            code: "Código",
            active: "Activo",
            inactive: "Inactivo",
            suspension: "Suspender",
            inactivation: "Inactivo",
            reactivation: "Reactivar",
            approve: "Aprobar",
            contract_type: "Tipo de Contrato",
            enabled: "Permitir",
            reactivation_date: "Fecha de Reactivación",
            inactivation_date: "Fecha de Inactivación",
            suspension_date: "Fecha de Suspensión",
            approval_date: "Fecha de Aprobación",
            situation: "Situación",
            classification: "Clasificación",
            sales_channel_type: "Tipo de Canal de Ventas",
            confirm: {
              title: "¿Eliminar canal de ventas?",
              description: "Esto eliminará permanentemente el canal de ventas",
            },
            errors: {
              title: "El Título es obligatorio",
              situation: "La situación es obligatoria",
              sales_channel_classification: "La Clasificación es obligatoria",
              sales_channel_type: "El Tipo de Canal de Ventas es obligatorio",
              individual_person: "La Persona Física es obligatoria",
              legal_entity: "La Persona Jurídica es obligatoria",
            },
          },
          contractProducts: {
            tabs: {
              products: "Productos",
              price: "Precio",
              history: "Historial",
              priceList: "Listas de Precios",
            },
            products: {
              title: "Productos",
              situation: "Situación",
              search: "Buscar Productos",
              new_product: "Nuevo Producto",
              add: "Agregar Producto",
              contract_title: "Título del Contrato",
              name: "Nombre",
              active: "Activo",
              inactive: "Inactivo",
              approved: "Aprobado",
              canceled: "Cancelado",
              suspended: "Suspendido",
              reactivation_date: "Fecha de Reactivación",
              inactivation_date: "Fecha de Inactivación",
              suspension_date: "Fecha de Suspensión",
              approval_date: "Fecha de Aprobación",
              cancelation_date: "Fecha de cancelación",
              suspension: "Suspender",
              inactivation: "Inactivo",
              reactivation: "Reactivar",
              approve: "Aprobar",
              cancelation: "Cancelar",
              fantasy_name: "Nombre Fantasía",
              sales_contract_type: "Tipo de Contrato",
              integration_code: "Código de Integración",
              sales_product_ctr_classification: "Clasificación del Producto",
              sales_grantors: "Otorgantes",
              code: "Código",
              confirm: {
                title: "¿Eliminar producto?",
                description: "Esto eliminará permanentemente el producto",
              },
              errors: {
                name: "El Nombre es obligatorio",
                fantasy_name: "El Nombre Fantasía es obligatorio",
                sales_grantors: "El Otorgante es obligatorio",
                sales_product_ctr_classification:
                  "La Clasificación del Producto es obligatoria",
                sales_contract_type: "El Tipo de Contrato es obligatorio",
              },
            },
          },
          salesProductCtrPriceList: {
            title: "Listas de Precios",
            price_list: "Lista de Precio",
            search: "Buscar por lista de precios",
            add: "Agregar Lista de Precios",
            name: "Nombre",
            initial_date: "Fecha Inicial",
            end_date: "Fecha Final",
            approval_date: "Fecha de Aprobación",
            approve: "Aprobar",
            code: "Código",
            new_price_list: "Nueva Lista de Precios",
            add_new_item: "Agregar Nuevo Ítem",
            degree_of_entitlement: "Grado de Elegibilidad",
            initial_age: "Edad Inicial",
            end_age: "Edad Final",
            items: "Ítems",
            price: "Precio",
            confirm: {
              title: "¿Eliminar Lista de Precios?",
              description: "Esto eliminará permanentemente la Lista de Precios",
            },
            errors: {
              name: "El nombre es obligatorio",
              initial_date: "La fecha inicial es obligatoria",
              end_date: "La fecha final es obligatoria",
              code: "El código es obligatorio",
              price: "El precio es obligatorio",
              degree_of_entitlement: "El grado de elegibilidad es obligatorio",
              sales_product_ctr_price_list_items:
                "Al menos un ítem es obligatorio",
            },
          },
          salesProductCtrHistories: {
            title: "Historia",
            search: "Buscar Historia",
            add: "Agregar Historia",
            contract_title: "Historia",
            new_history: "Nueva Historia",
            titleForm: "Título",
            history: "Historia",
            approval_date: "Fecha de Aprobación",
            history_date: "Fecha de Historia",
            approve: "Aprobar",
            confirm: {
              title: "¿Eliminar Historia?",
              description: "Esto eliminará permanentemente la Historia",
            },
            errors: {
              title: "El Titulo es obligatorio",
              history: "El História es obligatorio",
              historyDate: "La Fecha de Historia es obligatorio",
            },
          },
          beneficiaryTerminationReasons: {
            title: "Motivo de Terminación de Beneficiarios",
            search: "Buscar Motivo de Terminación de Beneficiarios",
            add: "Agregar Motivo de Terminación de Beneficiarios",
            new: "Nuevo Motivo de Terminación de Beneficiarios",
            name: "Nombre",
            status: "Estado",
            active: "Activo",
            inactive: "Inactivo",
            enabled: "Permitir",
            confirm: {
              title: "¿Eliminar motivo de terminación de beneficiarios?",
              description:
                "Esto eliminará permanentemente el motivo de terminación de beneficiarios",
            },
            errors: {
              name: "El Nombre es obligatorio",
            },
          },
          beneficiaryCancellationsReason: {
            title: "Motivo de Cancelación del Beneficiario",
            search: "Buscar por Motivo de Cancelación del Beneficiario",
            add: "Agregar Motivo de Cancelación del Beneficiario",
            new: "Nuevo Motivo de Cancelación del Beneficiario",
            name: "Nombre",
            status: "Estado",
            inactive: "Inactivo",
            active: "Activo",
            enable: "Permitir",
            confirm: {
              title: "¿Eliminar el motivo de cancelación del beneficiario?",
              description:
                "Esto eliminará permanentemente el motivo de cancelación del beneficiario",
            },
          },
          beneficiarySuspensionsReason: {
            title: "Motivo de Suspensión del Beneficiario",
            search: "Buscar por Motivo de Suspensión del Beneficiario",
            add: "Agregar Motivo de Suspensión del Beneficiario",
            new: "Nuevo Motivo de Suspensión del Beneficiario",
            enable: "Permitir",
            name: "Nombre",
            status: "Estado",
            active: "Activo",
            inactive: "Inactivo",
            confirm: {
              title: "¿Eliminar el motivo de suspensión del beneficiario?",
              description:
                "Esto eliminará permanentemente el motivo de suspensión del beneficiario",
            },
            errors: {
              name: "El nombre es obligatorio",
            },
          },
        },
      },
      assessments: {
        questions: {
          questions: "Preguntas",
          question: "Pregunta",
          new_question: "Nueva pregunta",
          add_question: "Adicionar questão",
          search: "Buscar una pregunta",
          question_evaluation_type: "Tipo de evaluación de pregunta",
          integration_id: "Id de integración",
          title: "Título",
          description: "Descripción",
          question_category: "Categoría de pregunta",
          created: "Fecha de creación",
          required_justification: "Requiere justificación",
          required_justification_less_than:
            "Requerir justificación a continuación",
          required_justification_min_chars: "Número mínimo de caracteres",
          required_justification_message: "Texto de justificación",
          category: {
            label: "Categoría",
            no_options_text: "No hay categorías seleccionadas",
            options_button_label: "Buscar categoría",
            drawer_props: {
              title: "Selecciona categoría",
              search_placeholder: "Buscar categorías",
              create_button_label: "Crear categoría",
              apply_button_label: "Aplicar categoría",
              input_label: "Nombre de la categoría",
              delete_confirm: {
                title: "Eliminar categoría",
                message:
                  "Esto eliminará la categoría y todas las categorías relacionadas.",
              },
            },
          },
          confirm: {
            title: "¿Eliminar la pregunta?",
            description: "Esto eliminará permanentemente la pregunta",
          },
          errors: {
            integration_id: "El id de integración es obligatorio",
            title: "El título es obligatorio",
            description: "La descripción es obligatoria",
            question_evaluation_type:
              "El tipo de evaluación de pregunta es obligatorio",
            question_category: "La categoría de pregunta es obligatoria",
            required_justification_less_than:
              "El 'requerir justificación a continuación' es obligatorio",
            required_justification_min_chars:
              "El número mínimo de caracteres es obligatorio",
            required_justification_message:
              "El texto de justificación es obligatorio",
          },
        },
      },
      logs: {
        healthChecks: {
          health_checks: "Health Checks",
          name: "Nombre",
          data: "Datos",
          created: "Fecha de creación",
          search: "Buscar un registro de controles de salud",
        },
        logRegisterError: {
          errorMessage: "Mensaje de error",
          document: "Documento",
          title: "Errores de registro",
          created: "Fecha",
          confirm: {
            confirm: {
              title: "Eliminar registro de errores?",
              description: "Esto eliminará permanentemente el registro",
            },
          },
          placeholder: "Buscar algunos CPF",
        },
      },
      evaluation: {
        forms: {
          forms: "Formularios",
          form: "Formulario",
          new_form: "Nuevo formulario",
          search: "Buscar un formulario",
          add_form: "Añadir formulario",
          title: "Título",
          description: "Descripción",
          status: "Status",
          active: "Atcivo",
          inactive: "Inactivo",
          extension: "Aplicación/Site/App",
          initial_date: "Inicio del trimestre",
          end_date: "Fin de validez",
          domains: {
            domains: "Dominios",
            tooltip:
              "Copie el link para compartir formulario para usarlo en plantillas",
            copied_successfully: "Link copiado exitosamente",
          },
          select_questions: {
            questions: "Preguntas",
            display_order: "Orden",
            title: "Título",
            type: "Tipo",
            add_button: "Añadir preguntas",
            modal_title: "Seleccionar preguntas",
            modal_add_button: "Añadir preguntas",
            modal_remove_button: "Eliminar preguntas",
            search: "Buscar preguntas",
          },
          confirm: {
            title: "¿Eliminar el formulario?",
            description: "Esto eliminará permanentemente el formulario",
          },
          errors: {
            type: "El tipo es obligatorio",
            title: "El título es obligatorio",
            site_app_extension: "La aplicación/site/app es obligatoria",
            initial_date: "La fecha de inicio del trimestre es obligatoria",
            end_date: "La fecha de fin de validez es obligatoria",
            bigger_date:
              "La fecha de inicio del trimestre no puede ser mayor que la fecha de fin de validez",
            description: "La descripción es obligatoria",
            questions: "Al menos una pregunta es obligatoria",
            order: "La orden debe ser mayor o igual a 1",
            duplicate_order: "No se permiten pedidos duplicados",
            visual_description: "La descripción visual es obligatoria",
          },
        },
        logs: {
          title: "Registros de integración",
          evaluationForm: "Forma",
          ticket: "Ticket (tarjeta)",
          attempts: "Intentos",
          answerDate: "Fecha de respuesta",
          encounterIntegrationId: "Número de servicio",
          errorMessage: "Mensaje de error",
          copy: "copiar",
        },
      },
    },
    components: {
      content: {
        contents: "Contenido",
        add_new_video: "Añadir video",
        add_new_content: "Añadir contenido",
        content_type: "Tipo de noticia",
        content_status: "Status de noticias",
        enable: "Permitir",
        reading_time: "Tiempo de leer",
        reading_time_info: "Tiempo en minutos",
        site_app: "Aplicaciones",
        title: "Título",
        language: "Idioma",
        language_tooltip: "Ver idioma",
        summary: "Resumen",
        publication_date: "Fecha de publicación",
        content_tags: "Tags de noticias",
        is_published: "Publicar",
        status: "Status",
        published: "Publicado",
        not_published: "No publicado",
        enabled: "Activo",
        inactive: "Inactivo",
        videos: "Videos",
        link: "Enlace",
        link_helper: "Agregar un enlace de YouTube",
        highlight_end: "Destacado acabado en",
        highlight_start: "Destacado iniciado en",
        confirm: {
          title: "¿Borrar la noticia?",
          description: "Esto eliminará permanentemente la noticia",
        },
        errors: {
          reading_time: "El tiempo de leer es obligatorio",
          site_apps: "Al menos una aplicación es obligatorio",
          language: "El idioma es obligatorio",
          title: "El titulo es obligatorio",
          tags: "Al menos una tag de noticias es obligatoria",
          summary: "El resumen es obligatorio",
          content: "El contenido es obligatorio",
          contents: "Al menos un contenido es obligatorio",
          file: "El archivo es obligatorio",
          link: "El enlace no es válido.Agregue un enlace de YouTube",
          highlight_end:
            "La fecha de finalización no puede ser mayor que la fecha de inicio",
        },
      },
      schedule: {
        selectAll: "Seleccionar todo",
        seeMore: "Ver más {{quantity}}",
        day: "Es",
        week: "Semana",
        month: "Mẽs",
        today: "Hoy",
        newSchedule: {
          required: "Campo obligatorio",
          date: "Datos",
          hour: "Tiempo",
          professional: "Profesional",
          type: "Tipo de programación",
          duration: "Duración",
          button: "Para programar",
          title: "Nuevo programación",
          insurance: {
            add: "Agregar acuerdo",
            edit: "Editar acuerdo",
            validate: "Validez de la tarjeta",
            code: "Número de tarjeta",
            number: {
              label: "Número de tarjeta (opcional)",
              error: "El número es obligatorio",
            },
            validity: {
              label: "Validez de tarjeta (opcional)",
              error: "La validez es obligatoria",
            },
            individual: {
              label: "Paciente",
              error: "El paciente es obligatorio",
            },
            insurance: {
              label: "Seguro de salud",
              error: "El acuerdo es obligatorio",
            },
          },
        },
        menu: {
          source: {
            both: "Todo",
          },
          exams: "Pruebas",
          subtitle: {
            title: "Leyenda",
            someFree: "Hay gratis",
            full: "todosOcupados",
            allFree: "Todo gratis",
          },
          period: "período",
          situation: "Situación",
          both: "ambas",
          specialitys: "especialidades",
          professionals: {
            label: "Profesionales",
            noOptionsText: "No se encontró un profesional",
            loading: "Cargando...",
          },
        },

        schedulePopover: {
          copy: "copiarIdentificador",
          copyCardNumber: "Copiar número de tarjeta",
          insurance: "Seguro de salud",
          cancel: "Horario de cancelación",
          confirm: "Confirmar cancelación",
          cancelButton: "cancelar",
          justification: {
            label: "Justificación",
            placeholder: "Ingrese una justificación",
            error: "La justificación es obligatoria",
          },
          reason: {
            label: "Razón",
            placeholder: "Seleccione una razón",
            error: "La razón es obligatoria",
          },
          cancelDescription:
            "Cancelar consulta {{pacient}} con {{doctor}} en {{date}}",
        },
      },
      scheduleAssistant: {
        button: "Asistente de programación",
        insurance: {
          title: "Mis acuerdos",
          subtitle: "Administre sus acuerdos y los del grupo familiar",
          add: "Para agregar",
          individual: "Individual",
          insuranceName: "Acuerdo",
          cardNumber: "numero de tarjeta",
          cardValidity: "Validez",
          delete: {
            title: "Eliminar un acuerdo?",
            description: "¡Esta acción es permanente!",
            confirmationText: "Si, eliminar",
            cancellationText: "Cancelar",
          },
          addInsurance: {
            edit: "Editar acuerdo",
            add: "Agregar acuerdo",
            buttonSave: "Ahorrar",
            buttonEdit: "Para editar",
            number: {
              label: "Número de tarjeta (opcional)",
              error: "El número es obligatorio",
            },
            validity: {
              label: "Validez de tarjeta (opcional)",
              error: "La validez es obligatoria",
            },
            individual: {
              label: "Paciente",
              error: "El paciente es obligatorio",
            },
            insurance: {
              label: "Seguro de salud",
              error: "El acuerdo es obligatorio",
            },
          },
        },
        newSchedule: {
          success: "Programación registrada exitosa",
          sched: "Para programar",
          next: "próximo",
          back: "Volver",
          notAvaiableModal: {
            button: "De acuerdo",
            description:
              "El tiempo elegido ya no está disponible, seleccione otra vez.",
          },
          finish: "finalizar",
          steps: {
            patient: {
              title: "Seleccione al paciente",
              error: "El paciente es obligatorio",
            },
            insurance: {
              title: "Seleccione el Acuerdo",
              error: "El acuerdo es obligatorio",
            },
            search: {
              title: "Buscar una agenda",
              specialityError: "La especialidad es obligatoria",
              periodError: "El período es obligatorio",
              examError: "El examen es obligatorio",
            },
            schedule: {
              title: "Seleccione una fecha y hora",
              error: "El tiempo es obligatorio",
            },
            confirm: "confirmarDatos",
          },
          insurance: "Seguro de salud",
          addInsurance: "Agregar acuerdo",
          addFamilyGroup: "Agregar familiar",
          search: {
            from: "Desde",
            examGroup: "Examen",
            professional: "Profesional (opcional)",
            speciality: "Especialidad",
            type: "Tipo de programación",
            period: "Período",
          },
          schedule: {
            freeDays: "Días con fechas disponibles",
          },
          confirm: {
            descriptionSite: "Profesional",
            patient: "paciente",
            insurance: "Seguro de salud",
            schedule: "Planificación",
          },
        },
      },
      panel: {
        add_dashboard: "Agregar tablero",
        select: "Seleccione y ordene el componentes",
        helper_text: "El tablero debe tener al menos un componente",
        no_data: "Sin datos",
        delete_dashboard: {
          delete: "Deletar dashboard",
          title: "Deletar dashboard {{ name }}",
          description:
            "Esto eliminará permanentemente sus paneles y sus datos.",
          cancel: "Cancelar",
          confirm: "Si, eliminar",
        },
        not_found: "Panel no encontrado",
        no_dashboards:
          "No se encuentra el tablero, ¡agregue su primer tablero!",
        select_tooltip: "Seleccionar y ordenar componentes",
        filter: {
          day: "Día",
          week: "Semana",
          month: "Mes",
          year: "Todavía",
        },
        helper: {
          title: "Información de datos del tablero",
          first:
            "Los registros diarios se crean al final de cada día, debido a esto el día de la creación de un tablero de tablero, no tiene datos.",
          second:
            "Los registros semanales se crean el domingo cuando termina.El <1>primer día de la semana siguiente a los consultados. </1>",
          third:
            "Los registros mensuales se crean el <1>primer día del mes siguiente a los consultados. </1> ",
          fourth:
            "Los registros anuales se crean el <1>primer día del año siguiente a los consultados. </1> ",
        },
      },
      selectBool: {
        true: "Verdadeiro",
        false: "Falso",
      },
      menu: {
        home: {
          home: "Inicio",
          begin: "Resumen",
          dashboard: "Dolor",
        },
        myDashboards: {
          myDashboards: "Mis Paneles",
          view: "Visualización",
          dashboards: "Paneles",
        },
        people: {
          people: "Gente",
          individuals: "Persona Física",
          legalEntities: "Entidad Legal",
        },
        registers: {
          beneficiaries: "Beneficiarios",
          doctors: "Medicos",
          userProfiles: "Perfiles de Usuario",
          client: "Cliente",
          templates: "Plantillas",
          integrations: "Integraciones",
          profile_roles: "Roles de Perfil",
          documents: "Términos y Políticas",
          holidays: "Vacaciones",
        },
        admSystem: {
          admSystem: "Adm. Sistema",
          users: "Usuarios",
          establishmentParams: "Parametros Generales",
          roles: "Perfil",
          establishments: "Establecimientos",
          aditionalSettings: {
            aditionalSettings: "Ajustes Adicionales",
            cardsTemplates: "Plantillas de Tarjetas",
          },
          companygroups: "Grupos Empresariales",
          companies: "Compañías",
        },
        globals: {
          globals: "Global",
          languages: "Idiomas",
          menus: "Menús",
          generics: "Genérico",
          countries: "Países",
          extensions: "Extensiones",
          cities: "Ciudades",
          neighborhoods: "Barrios",
          provinces: "Estados",
          banks: "Bancos",
          jobs: "Jobs",
          paramsDefaultEstablishment: "Parámetros de Establecimiento Estándar",
        },
        menu_secondary_item: {
          modal_title: "En desarrollo",
          modal_content:
            "Seguimos trabajando en el desarrollo de esta pantalla, pronto tendremos novedades.",
        },
        secondary_menu: {
          suport: "Soporte",
          configs: "Configuraciones",
          extensions_store: "Tienda de extensiones",
        },
        applications: {
          applications: "Aplicaciones",
          register: "Registro",
        },
        "4uChatBot": {
          templates: {
            templates: "Templates",
            whatsapp: "WhatsApp",
          },
          createAutomations: {
            createAutomations: "Automatizaciones",
            automationRules: "Reglas de Automatización",
          },
          fluxos: {
            fluxos: "Corrientes",
            serviceWhatsapp: "Servicio WhatsApp",
          },
        },
        evaluation: {
          evaluation: "Evaluaciones",
          questions: "Preguntas",
          evaluationForms: "Formularios",
          evaluationLogs: "Registros de integración",
        },
        chatBot: {
          register: "Registro",
          ticket_service: "Servicios de tickets(cards)",
          service: "Servicio",
          ticket_service_area: "Vía de servício",
          ticket_protocol_rule: "Reglas de generación de protocolos",
          ticket_service_day: "Horario de atención",
          ticket_service_message: "Mensajes",
        },
        logs: {
          logs: "Logs",
          healthcheck: "Health Checks",
          logRegisterError: "Errores de registro",
        },
        settings: {
          system: {
            system: "Sistema",
            generalSettings: {
              generalSettings: "Ajustes globales",
              correios: "Correios",
              centralBank: "Banco central",
              geolocalization: "Geolocalización",
            },
            cache: "Cache",
            paramsDefaultEstablishment:
              "Parámetros de establecimiento estándar",
            executeSql: "Ejecutar SQL",
          },
          generalSettings: {
            generalSettings: "Configuración general",
            holidays: "Vacaciones",
            establishmentParams: "Parámetros de establecimiento",
            healthChecks: "Health Checks de Microservicios 4uHub",
          },
          applications: {
            applications: "Aplicaciones",
            cardsTemplates: "Plantillas de tarjetas",
          },
          crm: {
            crm: "CRM",
            chatBot: {
              chatBot: "ChatBot",
              ticketServiceArea: "Áreas de servicio",
              ticketServiceMessage: "Mensajes",
            },
            ticketProtocolRule: "Reglas de generación de protocolos",
            ticketService: "Servicio de tickets(cards)",
            ticketServiceDay: "Horario de atención",
            ticketAutoFinishRule: "Reglas de finalización de ticket(card)",
            ticketDefaultDocs: "Protocolos de Orientación Estándar",
            events: "Eventos",
            externalProcessType: "Tipos de Procesos Externos",
          },
          chatBot: {
            chatBot: "ChatBot",
            ticketServiceArea: "Áreas de servicio",
            ticketServiceMessage: "Mensajes",
            ticketServiceDay: "Horario de atención",
          },
          "4uChatBot": {
            templates: {
              templates: "Templates",
              whatsapp: "WhatsApp",
            },
          },
          hcp: {
            hcp: "4uHcP",
            healthProgramTypes: "Tipos de programas de salud",
            workGroupTypes: "Tipos de grupos de trabajo",
          },
          "4uBus": {
            "4uBus": "4uBus",
            ipm: {
              ipm: "IPM",
              generalSettings: "Configuración general",
            },
            globals: {
              globals: "Configuración general",
              sync: "Sincronización",
            },
            zscan: {
              zscan: "ZScan",
              examSendRule: "Regla de envío de exámene",
            },
            externalExamPrescription: {
              externalExamPrescription:
                "Generación de Prescripción de Solicitud de Examen Externo",
              rules: "Reglas de Generación de Recetas",
            },
          },
          "4uSched": {
            "4uSched": "4uSched",
            tasyCalendar: {
              tasyCalendar: "Tasy - Consultas y exámenes",
              checkIn: "Check in",
              confirmation: "Confirmación de Agenda",
            },
          },
        },
        "4uSiteApp": {
          applications: {
            applications: "Aplicaciones",
            register: "Registro",
            myApplications: "Mis aplicaciones",
          },
          communication: {
            communication: "Comunicación",
            banners: "Banners",
            notifications: "Notificaciones",
            news: "Noticias",
          },
          privacyAndSecurity: {
            privacyAndSecurity: "Seguridad y Privacidad",
            termsAndPolicies: "Términos y políticas",
          },
          sac: {
            sac: "SAC y Defensor del Pueblo",
            faq: "FAQ",
          },
        },
        "4uHcP": {
          structure: {
            structure: "Estructura de programas",
            healthPrograms: "Programas de salud",
            workGroups: "Grupos de trabajo",
          },
          content: {
            content: "Contenido",
            contentRules: "Reglas de contenido",
          },
          captation: {
            captation: "Captura",
            captations: "Captaciones",
            imports: "Importar",
          },
        },
        "4ubus": {
          register: {
            register: "Registro",
            accessTokens: "Tokens de acceso",
            profileTasy: "Perfil - Autobús Tasy",
          },
          setRelationships: {
            setRelationships: "Relaciones",
            ipm: "IPM x TASY",
            "4uHub": "Tasy - Call Center",
            zscanEvo: "ZScan Evo",
          },
          restriction: {
            restriction: "Restricciones",
            rule: "Cronograma",
          },
          logs: {
            logs: "Logs",
            tasyCalendar: "Calendario del Tasy",
            ipmxtasyinternal: "IPM - Recibir - IPM x Tasy (Interno)",
            tasyxipminternal: "IPM - Enviar - Tasy x IPM (Interno)",
            ipmtasy: "IPM - Recibir - IPM x Tasy",
            tasyipm: "IPM - Enviar - Tasy x IPM",
          },
          robots: {
            robots: "Robots",
            list: "Lista",
          },
        },
        "4uSalesContract": {
          register: {
            register: "Registros",
            salesGrantors: "Otorgantes",
            salesContractAttachmentTypes: "Tipo de Anexo de Contrato",
            salesContractClassifications: "Clasificación de Contrato",
            salesProductCtrClassifications: "Clasificación de Productos",
            salesChannelClassifications: "Clasificación del Canal de Ventas",
            salesChannels: "Canal de Ventas",
            salesProductCtrs: "Producto",
            salesContractTerminationReason: "Motivo Cancelación Contrato",
            beneficiaryContractReasonOfTerminations:
              "Motivo de Terminación del Contrato de Beneficiario",
            salesContractBeneficiaryClassifications:
              "Clasificación de Beneficiario",
            beneficiaryContractReasonOfCancellations:
              "Motivo de Cancelación del Beneficiario",
            beneficiaryContractReasonOfSuspensions:
              "Motivo de Suspensión del Beneficiario",
          },
        },
      },
      logs_accordions: {
        user: "Usuario:",
        duration: "Duración",
      },
      logs_table: {
        name: "Nombre",
        original_value: "valores originales",
        new_value: "Nuevos valores",
        button_see_more: "Ver más",
      },
      logs_no_data: {
        message: "¡Datos no encontrados!",
      },
      roles: {
        title: "Perfil",
        name: "Nombre",
        establishment: "Establecimiento",
        search_name: "Buscar un nombre",
        new_role: "Nuevo perfil",
      },
      roles_basic_data: {
        name: "Nombre",
        description: "Descripción",
      },
      roles_establishments: {
        title: "Establecimientos",
        add_establishments: "Añadir nuevo establecimiento",
        name: "El nombre es obligatorio",
        label: "Establecimiento(s) que pueden utilizar este perfil",
        establishmentsTypeId: "Seleccione un establecimiento",
        establishments_tooltip: "Ver establecimientos",
      },
      roles_privileges: {
        title: "Privilegios",
        add_privileges: "Agregar nuevo privilegio",
      },
      private: {
        permission: "¡Uy! ",
      },
      forgetPasswordForm: {
        title: "¿Has olvidado tu contraseña?",
        subtitle:
          "Ingrese su nombre de usuario a continuación y le enviaremos un código a su correo electrónico para restablecer su contraseña.",
        button: "Enviar",
        sending: "Enviando",
        login: "CPF",
        login_required: "¡CPF es requerido!",
        notification_email_sent: "Correo electrónico enviado con éxito!",
        back_login: "Atrás para iniciar sesión",
      },
      forgetPasswordModal: {
        title: "¡Instrucciones enviadas!",
        message:
          "Hemos enviado instrucciones para restablecer la contraseña a su correo electrónico, verifique todas las bandejas de entrada y las carpetas de correo no deseado.",
        close: "Cerca",
      },
      resetPasswordForm: {
        title: "Nueva contraseña",
        subtitle:
          "Ingresa el código enviado a tu correo electrónico. Si no lo encuentras, revisa tu área de spam.",
        password: "Contraseña",
        confirm_password: "Confirmar Contraseña",
        confirmation_code: "Código de confirmación",
        sending: "Enviando",
        send: "Enviar",
        new_password_required: "Se requiere contraseña.",
        confirm_new_password_required: "Se requiere confirmar la contraseña.",
        passwords_match: "Las contraseñas no coinciden.",
        confirmation_code_required: "El código de confirmación es obligatorio",
        notification_password_success: "¡Contraseña cambiada con éxito!",
      },
      noRowsMessage: {
        no_permission: "No tienes permiso para ver la información.",
        no_data: "No tienes información registrada",
      },
      onPremiseModal: {
        establishment: {
          establishment_on_premise: "Establecimiento on premise",
          no_data: "No se encontró ningún establecimiento",
        },
        params: {
          params_on_premise: "Parámetros on premise",
          no_data: "No se encontró ningún parámetro",
        },
        generics: {
          generics_on_premise: "Genéricos on premise",
          no_data: "No se encontró ningún genérico",
        },
        health: {
          health_on_premise: "Planes de salud sincronizados",
          no_data: "No se encontró ningún plano de salud",
        },
        medicSpecialties: {
          no_data: "No se encontraron especialidades médicas",
        },
        providerTypes: {
          no_data: "No se encontraron tipos de proveedores",
        },
        professionalCouncils: {
          no_data: "No se encontró asesoramiento profesional.",
        },
        medicalPracticeAreas: {
          no_data: "No se encontraron áreas de práctica médica",
        },
      },
      cardMenu: {
        button: "Ver más",
      },
      extensionsStoreHeader: {
        title: "Tienda de extensiones",
        search: "Buscar por una extensión o tag",
        add_extensions: "Agregar extensiones",
        my_extensions: "Mi extensiones",
        back: "Volver",
      },
      extensionsCard: {
        details: "Detalles",
        install: "Instalar",
        installing: "Instalación",
        installed: "Instalado",
        free: "Gratis",
        select: "Seleccione un sitio web",
        tooltip_install: "Instalación",
        tooltip_month: "Mensualidad",
        site_app: "Sites o Apps",
        errors: {
          site_app: "Seleccione al menos un site o app para instalar",
        },
      },
      extensionsStoreDetail: {
        title: "Detalles de la extensión",
        free: "Gratis",
        installed: "Instalado",
        about: "Sobre la extensión",
        available_for: "Disponible para",
        install: "Instalar",
        settings: "Ajustes",
        configure: "Configurar en",
        tooltip_install: "Instalar",
        tooltip_month: "Mensualidad",
        tooltip_uninstall: "Desinstalar",
        tooltip_settings: "Ajustes",
        tags: "Tags",
        dependencies: "Dependencias",
      },
      extensionInstallPopper: {
        title: "¿Realmente quieres instalar la extensión?",
        cancel: "Cancelar",
        install: "Instalar",
      },
      extensionUninstallPopper: {
        title: "¿Realmente quieres desinstalar la extensión?",
        cancel: "Cancelar",
        uninstall: "Desinstalar",
        uninstalling: "Desinstalar",
      },
      extensionSettings: {
        title: "Configurar extensión",
        identifier: "Identificador",
        property: "Propiedad",
        value: "Valor",
        description: "Descripción",
      },
      settingsCorreios: {
        url_token: "Token de URL",
        user_token: "Token de Usuario",
        access_token: "Token de Código de Acceso",
        post_card: "Tarjeta Postal",
      },
      settingsGeneral: {
        user_token: "correiosTokenUser",
        access_token: "correiosTokenAccessCode",
        post_card: "correiosTokenPostCard",
        correios_url: "URL de los Correios",
        correios_token_user: "correiosTokenUser",
        correios_authentication: "correiosAuthenticationUrl",
        correios_locations: "correiosLocationsUrl",
        correios_addresses: "correiosAddressesUrl",
        cep_cache_time: "cepCacheTime",
        correios_page_size: "correiosPageSize",
        bank_base: "bankBaseUrl",
        bank_cooperativas: "bankCooperativasUrl",
        bank_bancos: "bankBancosUrl",
        geolocation: "Geolocalización",
        api_geocoding_key: "apiGeocodingKey",
        save: "Ahorrar",
        saving: "Ahorro",
        success: "Configuración general actualizada con éxito",
        errors: {
          user_token: "El correiosTokenUser es obligatorio",
          access_token: "El correiosTokenAccessCode es obligatorio",
          post_card: "El correiosTokenPostCard es obligatorip",
          correios_url: "La URL de los Correios es obligatoria",
          correios_token_user: "El correiosTokenUser es obligatorio",
          correios_authentication:
            "El correiosAuthenticationUrl es obligatorio",
          correios_locations: "El correiosLocationsUrl es obligatorio",
          correios_addresses: "El correiosAddressesUrl es obligatorio",
          cep_cache_time: "El cepCacheTime es obligatorio",
          bank_base: "El bankBaseUrl es obligatorio",
          bank_cooperativas: "El bankCooperativasUrl es obligatorio",
          bank_bancos: "El bankBancosUrl es obligatorio",
          correios_page_size: "El correiosPageSize es obligatorio",
          api_geocoding_key: "El apiGeocodingKey es obligatorio",
        },
      },
      settingsCache: {
        settingsCacheTotal: {
          clean_all_cache: "Borrado completo de caché (excepto Hangfire)",
          clean_all_description:
            "Limpia toda la caché de la plataforma, a excepción de las gestionadas por HangFire, nuestro gestor de trabajos.",
          clean_all_button: "Limpiar",
          clean_all_success: "Caché borrado con éxito",
        },
        settingsCacheKey: {
          clean_key_cache: "Borrado de caché por clave (acepta clave parcial)",
          clean_key_description:
            "Borra el caché según la clave ingresada. También acepta proporcionar una clave parcial. Ejemplo: si se especifica EF_, se borrará toda la caché de Entity Framework de la plataforma. Si se informa a Hangfire*, se borrará todo el caché relacionado con nuestro administrador de trabajos.",
          clean_key: "Clave",
          clean_key_button: "Limpiar",
          clean_key_success:
            "Caché borrado exitosamente para la clave '{{key}}'",
          errors: {
            cache_key: "La clave es obligatoria.",
          },
        },
        searchCacheKey: {
          search_cache_key: "Búsqueda de caché por clave",
          search_cache_description:
            "Busca en el caché según la clave dada. También acepta proporcionar una clave parcial. Ejemplo: si se especifica *EF_*, recuperará todo el caché de Entity Framework de la plataforma. Si se informa a *HANGFIRE*, recuperará todo el caché relacionado con nuestro administrador de trabajos.",
          search: "Buscar",
          search_key: "Clave",
          results: "Resultados de la búsqueda de clave",
          no_data: "No se encontró información",
          errors: {
            cache_key: "La clave es obligatoria.",
          },
        },
      },
      neighborhood: {
        neighborhood: "Vecindario",
        other: "No encontré mi barrio y quiero ingresar a uno nuevo",
        cep: "Ingrese un código postal primero",
      },
      addressContent: {
        cep_first: "Ingrese un código postal primero",
      },
      appDomain: {
        domain: "Dominio",
        title: "Copiar dominio",
      },
      nationalHolidays: {
        import_holidays: "Importar feriados nacionales",
        select_all: "Seleccionar todo",
      },
      cepInput: {
        zip_code: "CEP",
        helper_text: "Ingrese un código postal para buscar",
        city: "Ciudad",
        province: "Estado",
      },
      daysOfWeek: {
        sunday: "Domingo",
        monday: "Lunes",
        tuesday: "Martes",
        wednesday: "Miércoles",
        thursday: "Jueves",
        friday: "Viernes",
        saturday: "Sábado",
      },
      notificationInformations: {
        status: "Status",
        is_published: "Status de publicación",
        start_publish: "Inicio de publicación",
        finish_publish: "Fin de publicación",
        published: "Publicado",
        not_published: "No publicado",
      },
      notificationBatchActions: {
        generate: "Generar lote",
        cancel: "Cancelar lote",
        batch_generated: "Lote generado exitosamente",
        batch_canceled: "Lote cancelado exitosamente",
        cancel_popover_title:
          "Al cancelar el lote, ya no será posible generar lotes para esta notificación.",
      },
      searchResults: {
        no_data: "Ningún resultado encontrado",
      },
      yearSelect: {
        label: "Filtrar por año",
      },
      syncSection: {
        sync: "Sincronizar",
        view_data: "Ver datos integrados",
      },
      syncEstablishment: {
        title: "Establecimiento",
        description:
          "Sincronizar los datos del Establecimiento con el entorno 4uBus",
        synced: "Datos del Establecimiento sincronizados exitosamente",
        modal_title: "Datos integrados del establecimiento",
      },
      syncGeneric: {
        title: "Genérico",
        description: "Sincronizar los datos del Genérico con el entorno 4uBus",
        synced: "Datos del Genérico sincronizados exitosamente",
        modal_title: "Datos integrados del genérico",
      },
      syncUsers: {
        title: "Usuario",
        description: "Sincronizar los datos del Usuario con el entorno 4uBus",
        synced: "Datos del Usuario sincronizados exitosamente",
        modal_title: "Datos integrados del usuario",
      },
      syncPrivilege: {
        title: "Privilegio",
        description:
          "Sincronizar los datos del Privilegio con el entorno 4uBus",
        synced: "Datos del Privilegio sincronizados exitosamente",
        modal_title: "Datos integrados del privilegio",
      },
      syncEstablishmentParam: {
        title: "Parámetro de establecimiento",
        description:
          "Sincronizar los datos del Parámetro de establecimiento con el entorno 4uBus",
        synced:
          "Datos del Parámetro de establecimiento sincronizados exitosamente",
        modal_title: "Datos integrados del parámetro de establecimiento",
      },
      syncRelationshipType: {
        title: "Tipo de relación",
        description:
          "Sincronizar los datos del Tipo de relación con el entorno 4uBus",
        synced: "Datos del Tipo de relación sincronizados exitosamente",
        modal_title: "Datos integrados del tipo de relación",
      },
      syncRelationship: {
        title: "Relación",
        description:
          "Sincronizar los datos de la Relación con el entorno 4uBus",
        synced: "Datos de la Relación sincronizados exitosamente",
        modal_title: "Datos integrados de la relación",
      },
      syncExtension: {
        title: "Extensión",
        description:
          "Sincronizar los datos de la Extensión con el entorno 4uBus",
        synced: "Datos de la Extensión sincronizados exitosamente",
        modal_title: "Datos integrados de la extensión",
      },
      integratedData: {
        no_data: "No se encontraron datos integrados",
      },
      filtersActions: {
        filter: "Filtrar",
        clean: "Limpiar filtros",
        refresh: "Actualizar",
      },
      callToActionsButtons: {
        call_to_action: "Llamada a la acción",
        change_buttons_order: "Cambiar el orden de agrupación de botones",
      },
      quickAnswerButtons: {
        quick_aswer: "Respuesta rápida",
        change_buttons_order: "Cambiar el orden de agrupación de botones",
      },
      buttonSetting: {
        action_type: "Tipo de acción",
      },
      accessSiteAction: {
        static: "Estático",
        dynamic: "Dinámica",
        text: "Botón de texto",
        url_type: "Tipo de URL",
        payload: "URL del site",
        url_placeholder: "https://www.exemplo.com",
        url_tooltip:
          "Agregar una variable crea un enlace personalizado para que el cliente vea su información. Sólo se puede agregar una variable al final de una URL.",
      },
      callAction: {
        text: "Botón de texto",
        country: "País",
        payload: "Teléfono",
      },
      copyOfferCodeAction: {
        text: "Botón de texto",
        info: {
          title: "Agregar código de oferta de muestra",
          subtitle:
            "Para ayudarnos a analizar su plantilla de mensaje, agregue un código de oferta de ejemplo.",
        },
        payload: "Ofrece código",
      },
      selectWhatsButtons: {
        tooltip_limit:
          "Se alcanzó el límite de botones. Para agregar otro tipo de botón, elimine primero un botón",
        generate_automatically:
          "Los botones se determinan automáticamente según la automatización seleccionada",
        add_button: "Añadir un botón",
        quick_answer_buttons: "Botones de respuesta rápida",
        call_to_action_buttons: "Botones de llamada a la acción",
        help: "Si agrega más de 3 botones, aparecerán en una lista.",
      },
      whatsButtonsOptions: {
        personalized: {
          name: "Personalizado",
        },
        access_site: {
          name: "Acceder al sitio",
          description: "2 botones máximo",
        },
        call: {
          name: "Llamar",
          description: "1 botón máximo",
        },
        call_whats: {
          name: "Llamar por Whatsapp",
          description: "1 botón máximo",
        },
        copy_offer_code: {
          name: "Copiar código de oferta",
          description: "1 botón máximo",
        },
      },
      customButton: {
        text: "Botón de texto",
      },
      addVariablesButton: {
        variables: "Variables",
        variable: "Variable",
        cancel: "Cancelar",
        add: "Añadir",
        add_variables: "Añadir variables",
      },
      variablesContainer: {
        title: "Ejemplo de contenido corporal.",
        description:
          "Para ayudarnos a analizar su plantilla de mensaje, incluya un ejemplo de cada variable en el cuerpo del texto. No utilice información real del cliente. La API de la nube alojada por Meta analiza modelos y parámetros variables para proteger la seguridad e integridad de nuestros servicios.",
      },
      variableField: {
        label: "Insertar contenido a ",
      },
      bodyInput: {
        error: "Todas las variables deben ser válidas",
      },
      timeRules: {
        add_time_rule: "Añadir regla de tiempo",
        time_classification: "Clasificación del Tiempo",
        type: "Tipo",
        value: "Valor",
        search: "Buscar una regla de tiempo",
        confirm: {
          title: "¿Eliminar la regla de tiempo?",
          description: "Esto eliminará permanentemente la regla de tiempo",
        },
        errors: {
          type: "El tipo es obligatorio",
          time_classification: "La clasificación del tiempo es obligatoria",
          value: "El valor es obligatorio",
        },
      },
      automationMessages: {
        automation_message: "Mensaje de Automatización",
        new_automation_message: "Nueva mensaje de automatización",
        add_automation_message: "Añadir mensaje de automatización",
        search: "Buscar una mensaje de automatización",
        automation_message_type: "Tipo de mensaje de automatización",
        message: "Mensaje",
        confirm: {
          title: "¿Eliminar la mensaje de automatización?",
          description:
            "Esto eliminará permanentemente la mensaje de automatización",
        },
        errors: {
          automation_message_type:
            "El tipo de mensaje de automatización es obligatorio",
          message: "La mensaje es obligatoria",
        },
      },
      messages: {
        message: "Mensaje",
        new_message: "Nueva mensaje",
        add_message: "Añadir mensaje",
        search: "Buscar una mensaje",
        name: "Nombre",
        enable: "Permitir",
        exception: "Excepción",
        send: "Enviar",
        initial_date: "Fecha de inicio",
        end_date: "Fecha de finalización",
        evaluation_form: "Formulario",
        establishment: "Establecimiento",
        schedule_records: "Agendas",
        schedule_classifications: "Clasificaciones de programación",
        schedule_types: "Tipo de agenda",
        schedule_health_insurances: "Seguro de salud",
        if: "Si",
        then: "Entonces",
        move_ticket:
          "Mueva el ticket(card) si la persona responde otra cosa que no sea SÍ",
        warning:
          "Al habilitar la excepción, al menos uno de los campos siguientes es obligatorio.",
        qty_pacient_sent: "Número de pacientes a enviar",
        confirm: {
          title: "¿Eliminar la mensaje?",
          description: "Esto eliminará permanentemente la mensaje",
          qty_pacient_sent: "El número de pacientes a enviar es obligatorio",
        },
        errors: {
          name: "El nombre es obligatorio",
          evaluation_form: "El formulario es obligatorio",
          initial_date: "La fecha de inicio es obligatoria",
          end_date: "La fecha de finalización es obligatoria",
          initial_date_bigger:
            "La fecha de inicio no puede ser mayor que la fecha de fin",
        },
      },
      boardList: {
        board: "Marco",
        list: "Lista",
      },
      ticketServiceAreaDays: {
        ticket_service_area_days: "Días de servicio",
        ticket_service_area_day: "Día de servicio",
        add_ticket_service_area_day: "Añadir día de servicio",
        new_ticket_service_area_day: "Nuevo día de servicio",
        edit_ticket_service_area_day: "Editar día de servicio",
        day_of_week: "Día de la semana",
        initial_time: "Hora de inicio",
        end_time: "Hora de finalización",
        enable: "Permitir",
        search: "Buscar un día de servicio",
        confirm: {
          title: "¿Eliminar el día de servicio?",
          description: "Esto eliminará permanentemente el día de servicio",
        },
        errors: {
          day_of_week: "El día de la semana es obligatorio",
          initial_time: "La hora de inicio es obligatoria",
          end_time: "La hora de finalización es obligatoria",
          initial_time_bigger:
            "La hora de inicio no puede ser mayor que la hora de finalización",
        },
      },
      selectCategories: {
        label: "Seleccionar categorías",
        no_options_text: "No hay categorías seleccionadas",
        options_button_label: "Buscar categorías",
        drawer_props: {
          title: "Seleccionar Categorías",
          create_button_label: "Crear nueva categoría",
          input_label: "Nombre de la categoría",
          apply_button_label: "Seleccionar categorías",
        },
      },
    },
    chatBot: {
      page: {
        register: {
          ticketService: {
            ticket_service: "Servicio de tickets(cards)",
            new_ticket_service: "Nuevo servicio de tickets(cards)",
            enable: "Permitir",
            name: "Nombre",
            ticket_service_type: "Tipo de servicio de tickets(cards)",
            search: "Buscar un servicio de tickets(cards)",
            add_ticket_service: "Añadir servicio de tickets(cards)",
            site_app_extensions: "Sitios o Apps",
            ticket_channel_initial: "Marco de inicio",
            whats_settings: "Configuración de Whatsapp",
            typebot_settings: "Configuración de TypeBot",
            config_title: "Configuración",
            show_message_disable_area:
              "Mostrar un mensaje que indique que algunas opciones no están disponibles porque está fuera del horario comercial y requiere un asistente cara a cara",
            show_badge_name_color:
              "¿Mostrar globo con el nombre del servicio en el tickets(cards)?",
            badge_background_name_color:
              "Selecciona el color de fondo del globo",
            badge_name_color: "Seleccionar color de texto del globo",
            ticket_message_received_rvent: "Lista de mensajes",
            confirm: {
              title: "¿Eliminar el servicio de tickets(cards)?",
              description:
                "Esto eliminará permanentemente el servicio de tickets(cards).",
            },
            errors: {
              name: "El nombre es obligatorio",
              ticket_service_type:
                "El tipo de servicio de tickets(cards) es obligatorio",
              site_app_extensions: "Al menos uno sitio o app es obligatorio",
              ticket_channel_initial: "El marco de inicio es obligatorio",
              badge_background_name_color:
                "El color de fondo del globo es obligatorio",
              badge_name_color: "El color del texto del globo es obligatorio",
            },
          },
          ticketServiceWhats: {
            ticket_service_whats: "Configuración de Whatsapp",
            new_ticket_service_whats: "Nueva Configuración de Whatsapp",
            business_phone_number:
              "Id del número de teléfono de Whatsapp Business",
            business_account: "Id de cuenta Whtsapp Business",
            whatsapp_business: "Id de App Whatsapp Business",
            access_token: "Token de Acceso",
            webhook_link: "Link de Webhook",
            webhook_link_tooltip: "Copiar link",
            verify_token: "Token de Verificación",
            verify_token_tooltip: "Copiar Token de Verificación",
            errors: {
              business_phone_number:
                "El id del número de teléfono de Whatsapp Business es obligatorio",
              business_account:
                "El id de cuenta Whtsapp Business es obligatorio",
              whatsapp_business:
                "El id de App Whatsapp Business es obligatorio",
              access_token: "El token de acceso es obligatorio",
            },
          },
          ticketServiceTypebot: {
            ticket_service_typebot: "Configuraciones de TypeBot",
            new_ticket_service_typebot: "Nueva configuración de TypeBot",
            typebot_viewer_host: "Visualizador del Host de TypeBot",
            errors: {
              viewer_host: "El visualizador del host de typebot es obligatorio",
            },
          },
        },
        service: {
          protocolGenerateRule: {
            protocol_generate_rules: "Reglas de generación de protocolos",
            protocol_generate_rule: "Regla de generación de protocolos",
            new_protocol_generate_rule:
              "Nueva regla de generación de protocolos",
            name: "Nombre de la regla",
            enable: "Permitir",
            initial_date: "Inicio del trimestre",
            end_date: "Fin de validez",
            search: "Buscar una regla de generación de protocolo",
            add_protocol_generate_rule:
              "Añadir regla de generación de protocolo",
            protocol_rule_details:
              "Detalles de la regla de generación de protocolo",
            add_protocol_rule_details:
              "Añadir detalle de regla de generación de protocolo",
            order: "Orden",
            field_type: "Tipo",
            fixed_code: "Valor fijo",
            mask: "Mascarilla",
            mask_information:
              "La máscara se utiliza para formatear el valor del campo '{{type}}' en el protocolo, en los casos en que la longitud es menor que el límite aceptado.",
            mask_no_information:
              "La máscara se utiliza para formatear el valor del campo 'tipo' en el protocolo, en los casos en que la longitud es menor que el límite aceptado.",
            confirm: {
              title: "¿Eliminar regla de generación de protocolos?",
              description:
                "Esto eliminará permanentemente la regla de generación de protocolos",
            },
            errors: {
              name: "El nombre de la regla es obligatorio",
              initial_date: "El inicio del trimestre es obligatorio",
              end_date: "El fin de validez es obligatorio",
              fixed_code: "El Valor fijo es obligatorio",
              protocol_rule_details:
                "Al menos un detalle de la regla de generación de protocolo es obligatorio",
            },
          },
          ticketServiceArea: {
            ticket_service_areas: "Áreas de servicio",
            ticket_service_area: "Vía de servício",
            search: "Buscar un área de servicio",
            add_ticket_service_area: "Añadir área de servicio",
            new_ticket_service_area: "Nueva vía de servício",
            ticket_service_area_parent: "Área de Servicio para Padres",
            ticket_event: "Evento Desencadenante",
            ticket_event_type: "Tipo de Evento",
            name: "Nombre",
            code: "Código",
            enable: "Permitir",
            identification: "Requiere identificación",
            display_order: "Orden de Visualización",
            summary: "Resumen",
            ticket_services: "Servicio de Tickets(Cards)",
            ticket_channel: "Marco para Transferir",
            ticket_default_doc: "Protocolo Predeterminado",
            enable_all_the_time: "Servicio 24/7",
            enable_special_time: "Horario especial",
            service_type: "Tipo de Servicio",
            status: "Status",
            active: "Activo",
            inactive: "Inactivo",
            yes: "Sí",
            no: "No",
            actions: "Comportamiento",
            time: "Tiempo",
            confirm: {
              title: "¿Eliminar la área de servicio?",
              description:
                "Esto eliminará permanentemente la área de servicio.",
            },
            errors: {
              name: "El nombre es obligatorio",
              display_order: "La orden de visualización es obligatoria",
              code: "El código es obligatorio",
              ticket_services: "El servicio de tickets(cards) es obligatorio",
              summary: "El resumen es obligatorio",
              summary_characteres:
                "El resumen no puede tener más de 72 caracteres",
              name_characteres:
                "El nombre no puede tener más de 24 caracteres.",
            },
          },
          ticketServiceMessage: {
            ticket_service_messages: "Mensajes",
            ticket_service_message: "Mensaje",
            search: "Buscar una mensaje",
            message: "Mensaje",
            message_type: "Tipo de mensaje",
            ticket_service: "Servicio de ticket(card)",
            errors: {
              message: "La mensaje es obligatoria",
              message_type: "El tipo de mensaje es obligatorio",
              ticket_service: "El servicio de ticket(card) es obligatorio",
            },
          },
          serviceDay: {
            service_days: "Horario de apertura",
            service_day: "Horario de atención",
            new_service_day: "Nuevo horario de apertura",
            search: "Buscar un horario de atención",
            add_service_day: "Añadir horario de atención",
            day_of_week: "Dia de la semana",
            start_time: "Hora de inicio",
            end_time: "Hora de cierre",
            ticket_service: "Servicio de ticket(card)",
            enable: "Permitir",
            confirm: {
              title: "¿Eliminar horario de atención?",
              description:
                "Esto eliminará permanentemente el horario de atención",
            },
            errors: {
              day_of_week: "El dia de la semana es obligatorio",
              ticket_service: "El servicio de ticket(card) es obligatorio",
              start_time: "La hora de inicio es obligatoria",
              end_time: "La hora de cierre es obligatoria",
            },
          },
        },
        templates: {
          whatsapp: {
            whatsapp_templates: "Templates del Whatsapp",
            whatsapp_template: "Template del Whatsapp",
            new_whatsapp_template: "Nuevo template del Whatsapp",
            search: "Buscar un template del Whatsapp",
            add_whatsapp_template: "Añadir template del Whatsapp",
            name: "Nombre",
            enable: "Permitir",
            automation: "Automatización",
            ticket_service: "Servicio de ticket(card)",
            no_ticket_service:
              "Debes tener al menos un servicio de tickets(cards) registrado para poder registrar una plantilla de Whatsapp",
            template_meta_name: "Nombre del template en Meta",
            automation_status: "Status de automatización",
            status: "Status",
            active: "Activo",
            inactive: "Inactivo",
            message_preview: "Preview del mensaje",
            preview_button: "Ver preview del template",
            optional: "Opcional",
            header: "Encabezamiento",
            header_sub:
              "Agregue un título o elija qué tipo de medio utilizará para este encabezado.",
            title: "Título",
            body: "Cuerpo",
            body_sub: "Ingrese el texto de su mensaje.",
            body_placeholder: "Ingresa el cuerpo de tu mensaje...",
            footer: "Zócalo",
            footer_sub:
              "Agregue una pequeña línea de texto en la parte inferior de su plantilla de mensaje.",
            buttons: "Botones",
            buttons_sub:
              "Cree botones para que los clientes respondan a su mensaje o tomen medidas.",
            resend: "Reenviar",
            resend_success: "Template reenviado exitosamente",
            whatsappTemplateAutomation: {
              title: "Alterar automatización?",
              description:
                "Cambiar la automatización hará que se borren todos los campos",
              cancel: "Cancelar",
              change: "Alterar",
            },
            confirm: {
              title: "¿Eliminar el template del Whatsapp?",
              description:
                "Esto eliminará permanentemente el template del Whatsapp",
            },
            errors: {
              name: "El nombre es obligatorio",
              ticket_service: "El servicio de ticket(card) es obligatorio",
              ticket_automation: "La automatización es obligatoria",
              body: "El cuerpo debe tener al menos 1 carácter.",
              body_max: "El cuerpo debe tener un máximo de 1024 caracteres.",
              custom_button_text:
                "El texto del botón debe tener al menos 1 carácter",
              custom_button_text_max:
                "El texto del botón debe tener un máximo de 25 caracteres",
              url_type: "El tipo de URL es obligatorio",
              country: "El país es obligatorio",
              text: "El texto del botón debe tener al menos 1 carácter",
              text_max:
                "El texto del botón debe tener un máximo de 25 caracteres",
              payload: "Campo obligatorio",
              example: "El ejemplo es obligatorio",
            },
          },
        },
        automations: {
          automationRules: {
            automation_rules: "Reglas de Automatización",
            automation_rule: "Regla de Automatización",
            new_automation_rule: "Nueva regla de automatización",
            search: "Buscar una regla de automatización",
            add_automation_rule: "Añadir regla de automatización",
            name: "Nombre",
            automation: "Automatización",
            created_by: "Creado por",
            status: "Status",
            active: "Activo",
            inactive: "Inactivo",
            enable: "Permitir",
            event: "Evento",
            ticket_service: "Servicio de ticket(card)",
            initial_date: "Data de inicio",
            end_date: "Data de fin",
            whatsapp_template: "Template de WhatsApp",
            robot: "Robot",
            attendant: "Atendente",
            pendent: "Envío Pendiente",
            when_error: "Cuando hay un error al iniciar template",
            board: "Tablero",
            list: "Lista",
            time_rule: "Regla de tiempo",
            automation_message: "Mensaje de automatización",
            messages: "Mensajes",
            created_successfully: "Regla de automatización creada con éxito",
            confirm: {
              title: "¿Eliminar la regla de automatización?",
              description:
                "Esto eliminará permanentemente la regla de automatización",
            },
            errors: {
              name: "El nombre es obligatorio",
              ticket_automation: "La automatización es obligatoria",
              ticket_event: "El evento es obligatorio",
              ticket_service: "El servicio de ticket(card) es obligatorio",
              initial_date: "La fecha de inicio es obligatoria",
              end_date: "La fecha de fin es obligatoria",
              ticket_automation_template:
                "El template de WhatsApp es obligatorio",
              ticket_channel_step_robot: "La lista del robot es obligatoria",
              ticket_channel_step_attendant:
                "La lista del atendente es obligatoria",
              ticket_channel_step_pendent:
                "La lista de envío pendiente es obligatoria",
              initial_date_bigger:
                "La fecha de inicio no puede ser mayor que la fecha de fin",
            },
          },
        },
        flows: {
          serviceWhatsapp: {
            title: "Has sido redirigido al administrador de flujo.",
            subtitle: "Para acceder nuevamente haga clic en el botón de abajo",
            button: "Acceso",
          },
        },
      },
    },
  },
};

export default es;
