import { z } from "zod";

const translationPath = "page.register.notifications.errors.";

const typeSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "type" }
);

const siteAppSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const languageSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const notificationBatchContentSchema = z
  .object({
    language: languageSchema.nullable(),
    title: z.string().min(1, translationPath + "title"),
    content: z.string().min(1, translationPath + "content"),
  })
  .superRefine((val, ctx) => {
    if (!val.language) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["language"],
        message: translationPath + "language",
      });
    }
  });

const genderSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const profileSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const userSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const notificationBatchFilterSchema = z.object({
  gender: genderSchema.nullable(),
  minimumAge: z.string(),
  maximumAge: z.string(),
  isPayer: z.string(),
  isHolder: z.string(),
  profiles: z.array(profileSchema),
  users: z.array(userSchema),
});

export const notificationSchema = z
  .object({
    id: z.string().optional(),
    type: typeSchema,
    siteApps: z.array(siteAppSchema).min(1, translationPath + "site_app"),
    notificationBatchContents: z
      .array(notificationBatchContentSchema)
      .min(1, translationPath + "contents"),
    notificationBatchFilters: z
      .array(notificationBatchFilterSchema)
      .min(1, "Ao menos um filtro é obrigatório"),
  })
  .superRefine((val, ctx) => {
    val.notificationBatchFilters.map((filter, i) => {
      if (filter.isHolder === "both" && filter.isPayer === "both") {
        if (
          !filter.gender &&
          !filter.maximumAge &&
          !filter.minimumAge &&
          filter.profiles.length === 0 &&
          filter.users.length === 0
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["notificationBatchFilters", i],
            message: translationPath + "notification_batch_filter",
          });
          return false;
        }
        return true;
      }
      return true;
    });
  });

export type TNotificationForm = z.infer<typeof notificationSchema>;
