import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import { IHoliday, IHolidays, IYear } from "../models/holidays";

const HOLIDAYS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Holidays";

export class HolidaysService {
  create = (item: IHoliday) => {
    return GlobalAxios.post(HOLIDAYS_ROUTE, item);
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<IHolidays>(HOLIDAYS_ROUTE + "/" + id);
  };

  updateItemById = ({ item, id }: { item: IHoliday; id: string }) => {
    return GlobalAxios.put(HOLIDAYS_ROUTE + "/" + id, item);
  };

  deleteItemById = (id: string) => {
    return GlobalAxios.delete(HOLIDAYS_ROUTE + id);
  };

  list = (params?: any) => {
    return GlobalAxios.get<ISelectType[]>(HOLIDAYS_ROUTE + "list", {
      params: params,
    });
  };

  yearsList = () => {
    return GlobalAxios.get<IYear[]>(HOLIDAYS_ROUTE + "/years/list");
  };
}

export default HolidaysService;
