import { Loading, useFetch } from "@4uhub/lib4uhub";
import {
  Dispatch,
  SetStateAction,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { format, subDays } from "date-fns";
import ReactGridLayout from "react-grid-layout";

import { IDashBoardPanel } from "../../models/dashboardpanel";
import DashBoardPanelService, {
  IUpdateDashBoardRequest,
} from "../../services/dashboardpanel.service";
import { IDashboard } from "../../models/dashboard";
import DashBoardService from "../../services/dashboard.service";
import NotFound from "../../components/NotFound";
import { AxiosResponse } from "axios";

interface IDashBoardPanelContextProps {
  panel: IDashBoardPanel;
  languageId: string;
  dashboards: IDashboard[];
  dashBoardsLoading: boolean;
  filter: IDashBoardFilter;
  isView: boolean;
  setFilter: Dispatch<SetStateAction<IDashBoardFilter>>;
  onLayoutChange: (layout: ReactGridLayout.Layout[]) => void;
  onAddDashboard: (dashboard: IDashboard) => void;
  onDeleteDashboard: (dashboardId: string) => void;
}

const DashBoardPanelContext = createContext<
  IDashBoardPanelContextProps | undefined
>(undefined);

interface IPanelProviderProps {
  children: React.ReactNode;
  panelId: string;
  isView?: boolean;
  languageId: string;
  requestById?: (id: string) => Promise<AxiosResponse<IDashBoardPanel, any>>;
}

export enum EDashboardGroupTypeCode {
  DAY = "1",
  WEEK = "2",
  MONTH = "3",
  YEAR = "4",
}
interface IDashBoardFilter {
  DashboardGroupTypeCode: EDashboardGroupTypeCode;
  LabelFilter: string;
  YearFilter: string;
  value: Date;
}

const TODAY = new Date();

const INITIAL_FILTER = {
  DashboardGroupTypeCode: EDashboardGroupTypeCode.DAY,
  YearFilter: String(TODAY.getFullYear()),
  LabelFilter: format(subDays(TODAY, 1), "dd/MM/yyyy"),
  value: subDays(TODAY, 1),
};

const service = new DashBoardPanelService();

const dashboardService = new DashBoardService();

export const PanelProvider: React.FC<IPanelProviderProps> = memo(
  ({ children, panelId, isView = false, languageId, requestById }) => {
    const [panel, setPanel] = useState<IDashBoardPanel>();

    const [dashboards, setDashboards] = useState<IDashboard[]>([]);

    const [filter, setFilter] = useState<IDashBoardFilter>(INITIAL_FILTER);

    const { sendRequest, loading } = useFetch(
      requestById ? requestById : service.getById
    );

    const { sendRequest: getDashboards, loading: dashBoardsLoading } = useFetch(
      isView ? dashboardService.getView : dashboardService.get
    );

    const { sendRequest: updateLayoutRequest } = useFetch(service.updateLayout);

    const fetchPanel = useCallback(async () => {
      const { data, success } = await sendRequest(panelId);

      if (success && data) {
        if (typeof data === "boolean") {
          return;
        }
        setPanel(data);
      }
    }, [panelId, sendRequest]);

    const fetchDashboards = useCallback(async () => {
      if (!languageId || !panel?.id) return;

      const { data, success } = await getDashboards({
        DashboardPanelId: panel.id,
        languageId: languageId,
        ...filter,
      });

      if (success && data) {
        setDashboards(data);
      }
    }, [getDashboards, panel?.id, languageId, filter]);

    const onLayoutChange = useCallback(
      (layout: ReactGridLayout.Layout[]) => {
        if (!panel) return;

        const dashboards: IUpdateDashBoardRequest[] = layout.map((p) => ({
          dashboardEstablishmentId: String(p.i),
          height: String(p.h),
          width: String(p.w),
          xPosition: String(p.x),
          yPosition: String(p.y),
        }));

        setDashboards((ds) =>
          ds.map((d) => {
            const aux = layout.find((l) => l.i === d.id);

            if (aux) {
              d.width = aux.w;
              d.height = aux.h;
              d.xPosition = aux.x;
              d.yPosition = aux.y;
            }

            return d;
          })
        );

        updateLayoutRequest({
          id: panel.id,
          updateDashboardsRequest: dashboards,
        });
      },
      [updateLayoutRequest, panel]
    );

    const onAddDashboard = useCallback((dashboard: IDashboard) => {
      setDashboards((d) => [dashboard, ...d]);
    }, []);

    const onDeleteDashboard = useCallback((dashboardId: string) => {
      setDashboards((dashboards) =>
        dashboards.filter((d) => d.id !== dashboardId)
      );
    }, []);

    useEffect(() => {
      fetchPanel();
    }, [fetchPanel]);

    useEffect(() => {
      fetchDashboards();
    }, [fetchDashboards]);

    const value = useMemo(
      () => ({
        panel: panel!,
        languageId,
        dashboards,
        isView,
        dashBoardsLoading,
        filter,
        setFilter,
        onDeleteDashboard,
        onLayoutChange,
        onAddDashboard,
      }),
      [
        panel,
        languageId,
        dashboards,
        isView,
        dashBoardsLoading,
        filter,
        setFilter,
        onDeleteDashboard,
        onLayoutChange,
        onAddDashboard,
      ]
    );

    if (!panel) {
      return <NotFound message="components.panel.not_found" />;
    }

    if (loading) {
      return <Loading sx={{ position: "relative" }} />;
    }

    return (
      <DashBoardPanelContext.Provider value={value}>
        {children}
      </DashBoardPanelContext.Provider>
    );
  }
);

export const usePanel = () => {
  const context = useContext(DashBoardPanelContext);

  if (!context) {
    throw new Error("usePanel must be used with PanelProvider");
  }

  return context;
};
