import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table } from "@4uhub/lib4uhub";
import { Chip } from "@mui/material";
import { useMemo } from "react";

import MainApiService from "../../../../services/mainApi.service";

import { useAppSelector } from "../../../../store/store";
import CellData from "../../../../components/UI/CellData/CellData";
import { IRestrictionSchedules } from "../../../../models/restriction-rules";

const RESTRICTION_RULE =
  process.env.REACT_APP_4UBUS + "/api/v1/RestrictionRule/";

const accessTokenService = new MainApiService<IRestrictionSchedules>(
  RESTRICTION_RULE
);

const translationPath = "page.4uBus.restrictions.schedule.";

const SchedulesPage = () => {
  const { pathname } = useLocation();

  const currentPage = useAppSelector((state) =>
    state.menu.items.find(
      (menu) => menu.link === `/${pathname.split("/").slice(4).join("/")}`
    )
  );

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { extension } = useParams();

  const columns: GridColDef<IRestrictionSchedules>[] = [
    {
      field: "restrictionRuleType",
      headerName:
        t(translationPath + "restriction_rule_type") || "Restriction Rule Type",
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        } else {
          return <CellData value={params.value.name} />;
        }
      },
    },
    {
      field: "value",
      headerName: t(translationPath + "value") || "Value",
      renderCell: (params) => {
        return params.value ? params.value : "-";
      },
    },
    {
      field: "enable",
      headerName: t(translationPath + "status") || "Status",
      maxWidth: 150,
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            label={
              params.value
                ? t(translationPath + "active")
                : t(translationPath + "inactive")
            }
            color={params.value ? "success" : "error"}
          />
        );
      },
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IRestrictionSchedules>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const props = useMemo(() => {
    return {
      ExtensionCode: extension,
    };
  }, [extension]);

  return (
    <Table<IRestrictionSchedules>
      showDefaultMacroFilters={false}
      service={accessTokenService}
      columns={columns}
      title={
        currentPage ? currentPage.name : t(translationPath + "relationship")
      }
      searchInput={false}
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      serviceProps={props}
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a schedule"
      }
      addButtonLabel={t(translationPath + "add_schedule") || "Add schedule"}
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default SchedulesPage;
