import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

// Schema
import MainApiService from "../../../../../../services/mainApi.service";
import { TCardTemplateForm, cardTemplateSchema } from "./CardTemplateSchema";
import { useCallback, useEffect, useState } from "react";
import {
  AutoComplete,
  DateInput,
  FormContainer,
  ISelectType,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import CardTemplateService from "../../../../../../services/cardTemplate.service";

const cardsTemplatesService = new CardTemplateService();

const HEALTH_PLAN_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/HealthPlan/";
const healthPlanService = new MainApiService<ISelectType[]>(HEALTH_PLAN_ROUTE);

const ESTABLISHMENT_PARAM_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const establishmentParamService = new MainApiService<ISelectType>(
  ESTABLISHMENT_PARAM_ROUTE
);

const translationPath = "page.register.cardsTemplates.";

const CardTemplate = () => {
  const { t } = useTranslation();
  const [establishmentParamList, setEstablishmentParamList] = useState<
    ISelectType[]
  >([]);

  const { sendRequest: save, loading: sLoading } = useFetch(
    cardsTemplatesService.create
  );

  const { sendRequest: update, loading: uLoading } = useFetch(
    cardsTemplatesService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    cardsTemplatesService.getItemById
  );

  const { sendRequest: getEstablishmentParam } = useFetch(
    establishmentParamService.list
  );

  const onLoadEstablishmentParam = useCallback(async () => {
    const { data } = await getEstablishmentParam({
      identifier: "establishmentParamType",
    });
    if (data) {
      setEstablishmentParamList(data);
    }
  }, [getEstablishmentParam]);

  useEffect(() => {
    onLoadEstablishmentParam();
  }, [onLoadEstablishmentParam]);

  const handleGet = useCallback(
    async (id: string): Promise<Treturn<TCardTemplateForm>> => {
      const { data, success } = await get(id);
      if (data) {
        return {
          data: {
            ...data,
            initialDate: new Date(data?.initialDate),
            endDate: new Date(data?.endDate),
          },
          success: true,
        };
      }
      return { data, success };
    },
    [get]
  );

  const handleSave = (v: TCardTemplateForm) => {
    return save({
      initialDate: v.initialDate.toISOString(),
      endDate: v.endDate.toISOString(),
      enable: v.enable,
      healthPlanId: v.healthPlan.id,
      establishmentParamTypeId: v.establishmentParamType.id,
      establishmentParamTypeBackId: v.establishmentParamTypeBack.id,
    });
  };

  const handleUpdate = (v: TCardTemplateForm, id: string) => {
    return update({
      item: {
        ...v,
        initialDate: v.initialDate.toISOString(),
        endDate: v.endDate.toISOString(),
        enable: v.enable,
        healthPlanId: v.healthPlan.id,
        establishmentParamTypeId: v.establishmentParamType.id,
        establishmentParamTypeBackId: v.establishmentParamTypeBack.id,
      },
      id: id,
    });
  };

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TCardTemplateForm>
      saveFunction={handleSave}
      updateFunction={handleUpdate}
      getFunction={handleGet}
      loading={loading}
      title={t(translationPath + "card_template")}
      schema={cardTemplateSchema}
      subtitle={t(translationPath + "new_card_template")}
      subtitleWatchField={"healthPlan.name"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            <SwitchInput name="enable" label={t(translationPath + "enable")} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DateInput
              name="initialDate"
              label={t(translationPath + "initial_date")}
              initialDate={new Date()}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DateInput
              name="endDate"
              label={t(translationPath + "end_date")}
              autoFocus
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <AutoComplete
              getOptionLabel={(option) => {
                return `${option.name} (${option.code})`;
              }}
              renderOption={(props, option) => {
                let render: string = option.name;

                if (option.code) {
                  render = `${option.name} (${option.code})`;
                }
                return (
                  <li {...props} key={option.id}>
                    {render}
                  </li>
                );
              }}
              size="small"
              label={t(translationPath + "health_plan")}
              name="healthPlan"
              request={healthPlanService.list}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <AutoComplete
              getOptionLabel={(option) => {
                return option.name;
              }}
              size="small"
              label={t(translationPath + "establishment_param_type")}
              name="establishmentParamType"
              options={establishmentParamList}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <AutoComplete
              getOptionLabel={(option) => {
                return option.name;
              }}
              size="small"
              label={t(translationPath + "establishment_param_type_back")}
              name="establishmentParamTypeBack"
              options={establishmentParamList}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default CardTemplate;
