import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
} from "@mui/material";
import { Button, FormArray, FormInput, useFormContext } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import { useFieldArray } from "react-hook-form";
import { memo } from "react";

import VideoPreview from "./VideoPreview";
import { TContentForm } from "../../ContentSchema";

const translation_path = "components.content.";

interface IContentVideoModalProps {
  mainIndex: number;
  onClose: () => void;
  open: boolean;
}

const ContentVideoModal: React.FC<IContentVideoModalProps> = ({
  mainIndex,
  onClose,
  open,
}) => {
  const { control, trigger } = useFormContext<TContentForm>();

  const { t } = useTranslation();

  const contentsFieldArray = useFieldArray({
    control,
    name: `contents.${mainIndex}.contentVideos`,
  });

  const onClickSaveHandler = async () => {
    const response = await trigger(`contents.${mainIndex}.contentVideos`);
    if (response) {
      onClose();
    }
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {t(translation_path + "videos")}
        </Stack>
      </DialogTitle>
      <DialogContent>
        <FormArray
          title=""
          addButtonLabel={
            t(translation_path + "add_new_video") || "Add new video"
          }
          name="contentVideos"
          showAddButton={contentsFieldArray.fields.length < 5}
          formArray={contentsFieldArray}
          appendValue={{ link: "", name: "Video" }}
          fieldsObject={(index) => {
            const link = `contents.${mainIndex}.contentVideos.${index}.link`;

            return (
              <Grid
                item
                xs={12}
                sx={(t) => ({
                  padding: 2,
                  borderRadius: 1,
                })}
              >
                <Stack gap={2}>
                  <FormInput
                    size="small"
                    onBlur={() =>
                      trigger(
                        `contents.${mainIndex}.contentVideos.${index}.link`
                      )
                    }
                    name={link}
                    label={t(translation_path + "link")}
                    fullWidth
                    helperText={t(translation_path + "link_helper")}
                  />
                  <VideoPreview
                    index={index}
                    mainIndex={mainIndex}
                    control={control}
                  />
                </Stack>
              </Grid>
            );
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClickSaveHandler}>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(ContentVideoModal);
