import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";

import { Table, useFetch } from "@4uhub/lib4uhub";

import { ISaleChannel } from "../../../../models/sale-channel";
import SituationChip from "./SituationChip";
import { useCallback, useMemo, useState } from "react";
import MainApi from "../../../../services/mainApi.service";
import {
  approveSalesChannel,
  inactivateSalesChannel,
  reactivateSalesChannel,
  suspendSalesChannel,
} from "../../../../services/saleChannel.service";

const serviceURL = process.env.REACT_APP_MAIN + "/api/v1/SalesChannel/";
export const salesChannelService = new MainApi<ISaleChannel>(serviceURL);

const translationsPath = "page.4uSalesContract.registers.salesChannel.";

const SalesChannelPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(0);

  const translate = (field: string) => t(translationsPath + field);

  const { sendRequest: approve } = useFetch(approveSalesChannel);
  const { sendRequest: inactivate } = useFetch(inactivateSalesChannel);
  const { sendRequest: reactivate } = useFetch(reactivateSalesChannel);
  const { sendRequest: suspend } = useFetch(suspendSalesChannel);

  const columns: GridColDef<ISaleChannel>[] = [
    {
      field: "code",
      headerName: translate("code"),
    },
    {
      field: "titleChannel",
      headerName: translate("name"),
    },
    {
      field: "reactivationDate",
      headerName: translate("reactivation_date"),
      renderCell: (params) => {
        if (params.value) {
          const dateParts = params.value.split("-");
          const newDate =
            dateParts[2] + "/" + dateParts[1] + "/" + dateParts[0];
          return newDate;
        }
        return "-";
      },
    },
    {
      field: "inactivationDate",
      headerName: translate("inactivation_date"),
      renderCell: (params) => {
        if (params.value) {
          const dateParts = params.value.split("-");
          const newDate =
            dateParts[2] + "/" + dateParts[1] + "/" + dateParts[0];
          return newDate;
        }
        return "-";
      },
    },
    {
      field: "suspensionDate",
      headerName: translate("suspension_date"),
      renderCell: (params) => {
        if (params.value) {
          const dateParts = params.value.split("-");
          const newDate =
            dateParts[2] + "/" + dateParts[1] + "/" + dateParts[0];
          return newDate;
        }
        return "-";
      },
    },
    {
      field: "approvalDate",
      headerName: translate("approval_date"),
      renderCell: (params) => {
        if (params.value) {
          const dateParts = params.value.split("-");
          const newDate =
            dateParts[2] + "/" + dateParts[1] + "/" + dateParts[0];
          return newDate;
        }
        return "-";
      },
    },
    {
      field: "situation",
      maxWidth: 100,
      headerName: translate("situation"),
      renderCell: ({ value }) => (
        <SituationChip name={value?.name} code={value?.code} />
      ),
    },
  ];

  const handleRowClick = ({ id }: GridRowParams<ISaleChannel>) => {
    navigate(`${id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };
  const onApproveSalesChannel = useCallback(
    async (id: string) => {
      const { success } = await approve(id);
      if (success) {
        setRefresh((prevState) => (prevState += 1));
      }
    },
    [approve, setRefresh]
  );
  const onInactivateSalesChannel = useCallback(
    async (id: string) => {
      const { success } = await inactivate(id);
      if (success) {
        setRefresh((prevState) => (prevState += 1));
      }
    },
    [inactivate, setRefresh]
  );
  const onReactivationSalesChannel = useCallback(
    async (id: string) => {
      const { success } = await reactivate(id);
      if (success) {
        setRefresh((prevState) => (prevState += 1));
      }
    },
    [reactivate, setRefresh]
  );
  const onSuspensionSalesChannel = useCallback(
    async (id: string) => {
      const { success } = await suspend(id);
      if (success) {
        setRefresh((prevState) => (prevState += 1));
      }
    },
    [suspend, setRefresh]
  );
  const props = useMemo(() => {
    return { refresh };
  }, [refresh]);

  return (
    <Table<ISaleChannel>
      showDefaultMacroFilters={false}
      service={salesChannelService}
      columns={columns}
      serviceProps={props}
      title={translate("title")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={t(translate("search"))}
      addButtonLabel={translate("add")!}
      rowActions={(params) => [
        params.row.situation.code === "1" && params.row.approvalDate === null
          ? {
              action: (params) => {
                onApproveSalesChannel(params.row.id);
              },
              icon: <CheckCircleIcon fontSize="small" />,
              text: `${t(translationsPath + "approve")}`,
            }
          : null,
        params.row.inactivationDate === null &&
        (params.row.situation.code === "1" || params.row.situation.code === "4")
          ? {
              action: (params) => {
                onInactivateSalesChannel(params.row.id);
              },
              icon: <DoDisturbOnIcon fontSize="small" />,
              text: `${t(translationsPath + "inactivation")}`,
            }
          : null,
        params.row.situation.code === "3" && params.row.reactivationDate == null
          ? {
              action: (params) => {
                onReactivationSalesChannel(params.row.id);
              },
              icon: <PlayCircleFilledIcon fontSize="small" />,
              text: `${t(translationsPath + "reactivation")}`,
            }
          : null,
        params.row.suspensionDate === null &&
        (params.row.situation.code === "1" || params.row.situation.code === "4")
          ? {
              action: (params) => {
                onSuspensionSalesChannel(params.row.id);
              },
              icon: <PauseCircleFilledIcon fontSize="small" />,
              text: `${t(translationsPath + "suspension")}`,
            }
          : null,
      ]}
      confirmOptions={{
        title: translate("confirm.title"),
        description: translate("confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default SalesChannelPage;
