import { z } from "zod";

const translationPath = "page.settings.schedule.scheduleConfirmation.errors.";

export const scheduleConfirmationSchema = z.object({
  id: z.string().optional(),
  time: z.string().min(1, translationPath + "time"),
});

export type TScheduleConfirmationForm = z.infer<
  typeof scheduleConfirmationSchema
>;
