import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useFetch, useNotificationContext } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import DownloadIcon from "@mui/icons-material/Download";

import ExtensionInstallPopper from "./ExtensionInstallPopper";
import { IExtensionInstall } from "../../models/extensions-store";
import MainApiService from "../../services/mainApi.service";

const translationPath = "components.extensionsStoreDetail.";

const EXTENSION_INSTALL_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/SiteAppExtension/InstallExtension";
const extensionInstallService = new MainApiService<IExtensionInstall>(
  EXTENSION_INSTALL_ROUTE
);

interface IExtensionDetailInstall {
  siteAppId: string;
  extensionId: string;
  isDrawerOpen: boolean;
  onRealoadExtensionsList: () => void;
  toggleDetail: (open: boolean) => void;
  onToggleExtensionsOption: () => void;
}

const ExtensionDetailInstall = ({
  siteAppId,
  extensionId,
  isDrawerOpen,
  onRealoadExtensionsList,
  onToggleExtensionsOption,
  toggleDetail,
}: IExtensionDetailInstall) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const { sendRequest, loading } = useFetch(extensionInstallService.create);
  const { setMessage } = useNotificationContext();

  const onInstallExtension = useCallback(
    async (values: IExtensionInstall) => {
      const { data, success } = await sendRequest({
        siteAppIds: values.siteAppIds,
        extensionId: values.extensionId,
      });
      if (data && success) {
        onRealoadExtensionsList();
        toggleDetail(false);
        onToggleExtensionsOption();
        setMessage({
          notificationKey: new Date().getMilliseconds(),
          message: `${t("page.extensionsStore.installed_successfully")}`,
          type: "success",
        });
      }
    },
    [
      sendRequest,
      t,
      onRealoadExtensionsList,
      toggleDetail,
      onToggleExtensionsOption,
      setMessage,
    ]
  );

  useEffect(() => {
    if (!isDrawerOpen) {
      setOpen(false);
    }
  }, [isDrawerOpen]);

  const onClose = () => setOpen(false);

  return (
    <>
      <ExtensionInstallPopper
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        onConfirm={() => {
          onClose();
          onInstallExtension({
            siteAppIds: [siteAppId],
            extensionId: extensionId,
          });
        }}
      />
      <Tooltip title={t(translationPath + "tooltip_install")}>
        <IconButton
          size="small"
          color="primary"
          disabled={loading}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            setOpen(true);
          }}
        >
          {loading && <CircularProgress size={18} />}
          {!loading && <DownloadIcon />}
        </IconButton>
      </Tooltip>
    </>
  );
};

export default ExtensionDetailInstall;
