import { GlobalAxios } from "./../axios/axios-config";
import { ICep } from "../models/external";

const CEP_ROUTE = process.env.REACT_APP_EXTERNAL + "/api/v1/Locations/";

export class ExternalService {
  getCep = (cep: string) => {
    return GlobalAxios.get<ICep>(CEP_ROUTE + cep);
  };
}

export default ExternalService;
