import {
  AutoCompleteMulti,
  DomainProvider,
  FormInput,
  ISelectType,
  SwitchInput,
  useFetch,
} from "@4uhub/lib4uhub";
import { Grid, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";
import SitesAppsService from "../../../../../services/sitesApps.service";
import { useCallback, useEffect, useState } from "react";
import { DateTime } from "../../../../../components/DateTime";

const siteAppExtensionService = new SitesAppsService();

const translation_path = "components.content.";

const RuleBasicData = () => {
  const { t } = useTranslation();

  const [siteAppOptions, setSiteAppOptions] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(
    siteAppExtensionService.siteAppExtensionList
  );

  const loadSiteApp = useCallback(async () => {
    const { data, success } = await sendRequest({ ExtensionCode: "18" });

    if (data && success) {
      setSiteAppOptions(
        data.map((item) => ({ id: item.siteApp.id, name: item.siteApp.name }))
      );
    }
  }, [sendRequest]);

  useEffect(() => {
    loadSiteApp();
  }, [loadSiteApp]);

  return (
    <>
      <Grid item xs={12} sm={12} textAlign={"end"}>
        <DomainProvider
          value={{ domainName: "healthprogramcontentapprover" }}
          actions={["All"]}
          redirect={false}
        >
          <SwitchInput
            name="isPublished"
            label={t(translation_path + "is_published")}
          />
        </DomainProvider>
        <SwitchInput name="enable" label={t(translation_path + "enable")} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormInput
          size="small"
          name="readingTime"
          fullWidth
          label={t(translation_path + "reading_time") || "Reading Time"}
          type={"number"}
          InputProps={{
            endAdornment: <InputAdornment position="end">min.</InputAdornment>,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <DateTime
          label={t(translation_path + "publication_date") || "Publication Date"}
          name="publicationDate"
          initialDate={new Date()}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <AutoCompleteMulti
          size="small"
          label={t(translation_path + "site_app")}
          name="siteApps"
          options={siteAppOptions}
        />
      </Grid>
    </>
  );
};

export default RuleBasicData;
