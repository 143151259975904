import { Divider, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

// Schema
import { TUserForm, userSchema } from "./UserSchema";
import UsersService from "../../../../services/users.service";
import { useCallback, useEffect, useState } from "react";
import UserBasicData from "./UserBasicData";
import EstablishmentsService from "../../../../services/establishments.service";
import UserConfigsData from "./UserConfigsData";
import { IUserGet } from "../../../../models/users";
import { FormContainer, Treturn, useFetch } from "@4uhub/lib4uhub";
import { IEstablishment } from "@4uhub/lib4uhub";

const usersService = new UsersService();

const establishmentListService = new EstablishmentsService();

const User = () => {
  const { t } = useTranslation();
  const [individual, setIndividual] = useState<IUserGet>();

  const [establishmentsList, setEstablishmentsList] = useState<
    IEstablishment[]
  >([]);

  const { sendRequest: listEstablishments } = useFetch(
    establishmentListService.list
  );

  const { sendRequest: save, loading: sLoading } = useFetch(
    usersService.create
  );
  const { sendRequest: update, loading: uLoading } = useFetch(
    usersService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    usersService.getItemById
  );

  const loadEstablishments = useCallback(async () => {
    const { data } = await listEstablishments(null);
    if (data) {
      setEstablishmentsList(data);
    }
  }, [listEstablishments]);

  useEffect(() => {
    loadEstablishments();
  }, [loadEstablishments]);

  const handleSave = (v: TUserForm) => {
    return save({
      enable: v.enable,
      userName: v.userName,
      email: v.email,
      password: v.password!,
      profileIds: v.profiles?.map((item) => item.id),
      roleIds: v.roles?.map((item) => item.id),
      establishmentsId: v.establishments.map((item) => item.id),
      establishmentStandardId: v.establishmentStandard.id,
      individualId: v.individual!.id,
    });
  };

  const handleUpdate = (v: TUserForm, id: string) => {
    return update({
      item: {
        ...v,
        password: v.password!,
        profileIds: v.profiles?.map((item) => item.id),
        roleIds: v.roles?.map((item) => item.id),
        establishmentsId: v.establishments.map((item) => item.id),
        establishmentStandardId: v.establishmentStandard.id,
      },
      id: id,
    });
  };

  const handleGet = useCallback(
    async (id: string): Promise<Treturn<TUserForm>> => {
      const { data } = await get(id);

      if (data) {
        if (!data.individual)
          return {
            success: true,
            data: { ...data, individual: null, profiles: [] },
          };
        setIndividual(data);
        return {
          data: {
            ...data,
            individual: {
              document: data.individual.code,
              id: data.individual.id,
              individualName: data.individual.name,
            },
            profiles: data.individual.profiles,
          },
          success: true,
        };
      }
      return {
        data: null,
        success: false,
      };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TUserForm>
      saveFunction={handleSave}
      updateFunction={handleUpdate}
      getFunction={handleGet}
      loading={loading}
      title={t("page.register.users.users")}
      schema={userSchema}
      subtitle={t("page.register.users.new_user")}
      subtitleWatchField={"userName"}
      fields={(type) => (
        <Grid container spacing={2}>
          <UserBasicData
            type={type}
            user={individual}
            establishmentsList={establishmentsList}
          />

          <Grid item xs={12} sm={12} mt={2}>
            <Divider sx={{ width: "100%", marginBottom: 2 }} />
            <Typography variant="h6">
              {t("page.register.users.config")}
            </Typography>
          </Grid>

          <UserConfigsData type={type} />
        </Grid>
      )}
    />
  );
};

export default User;
