import { memo, useCallback, useEffect, useState } from "react";
import { TQuestionForm } from "./QuestionSchema";
import { useFetch, useFormContext } from "@4uhub/lib4uhub";
import RadioGroupInput from "../../../../components/RadioGroup/RadioGroupInput";
import { Grid } from "@mui/material";
import { listAlternatives } from "../../../../services/question.service";
import { IQuestionAlternatives } from "../../../../models/questions";
import { useParams } from "react-router-dom";

export const QuestionAlternatives = memo(() => {
  const [questionAlternatives, setQuestionAlternatives] = useState<
    IQuestionAlternatives[]
  >([]);

  const { id } = useParams();

  const { getValues } = useFormContext<TQuestionForm>();

  const alternatives = getValues("questionAlternatives");

  const evaluationType = getValues("questionEvaluationType");

  const { sendRequest } = useFetch(listAlternatives);

  const fetchAlternatives = useCallback(async () => {
    const { data, success } = await sendRequest({
      QuestionEvaluationTypeId: evaluationType ? evaluationType.id : "",
    });
    if (data && success) {
      setQuestionAlternatives(data);
    }
  }, [sendRequest, evaluationType]);

  useEffect(() => {
    if (id) {
      if (!alternatives || alternatives.length === 0) return;
      setQuestionAlternatives(
        alternatives.map((alt) => ({ ...alt, justificationLabel: "", id: id }))
      );
    } else {
      if (evaluationType) {
        fetchAlternatives();
      }
    }
  }, [id, alternatives, evaluationType, fetchAlternatives]);

  return (
    <Grid item sm={12}>
      {questionAlternatives.length !== 0 && (
        <RadioGroupInput
          name={"questionAlternatives"}
          groupLabel={"Alternativas"}
          disableRadios
          radios={questionAlternatives
            .sort((a, b) =>
              a.nrOption > b.nrOption ? 1 : a.nrOption < b.nrOption ? -1 : 0
            )
            .map((alternative) => ({
              label: alternative.nrOption.toString(),
              value: alternative.nrOption.toString(),
            }))}
        />
      )}
    </Grid>
  );
});
