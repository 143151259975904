import { z } from "zod";

const translationPath = "page.assessments.questions.errors.";

const questionCategorySchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    parentId: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "question_category" }
);

const questionOptionTypeSchema = z
  .object({
    id: z.string().optional(),
    isRequiredJustification: z.boolean(),
    requiredJustificationLessThan: z.string().optional(),
    requiredJustificationMinChars: z.string().optional(),
    requiredJustificationMessage: z.string().optional().nullable(),
  })
  .superRefine((val, ctx) => {
    if (val.isRequiredJustification) {
      if (!val.requiredJustificationLessThan) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: translationPath + "required_justification_less_than",
          path: ["requiredJustificationLessThan"],
        });
      }
      if (!val.requiredJustificationMessage) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: translationPath + "required_justification_message",
          path: ["requiredJustificationMessage"],
        });
      }
      if (!val.requiredJustificationMinChars) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: translationPath + "required_justification_min_chars",
          path: ["requiredJustificationMinChars"],
        });
      }
    }
  });

const questionAlternativeSchema = z.object({
  id: z.string().optional(),
  questionEvaluationTypeAlternativeId: z.string(),
  nrOption: z.number(),
  displayOrder: z.number(),
  integrationId: z.string().nullable(),
});

const questionEvaluationTypeSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

export const questionSchema = z.object({
  id: z.string().optional(),
  questionCategory: questionCategorySchema,
  questionOptionType: questionOptionTypeSchema.optional(),
  questionAlternatives: z.array(questionAlternativeSchema).optional(),
  questionEvaluationType: questionEvaluationTypeSchema,
  title: z.string().min(1, translationPath + "title"),
  description: z.string().min(1, translationPath + "description"),
  integrationId: z.string().min(1, translationPath + "integration_id"),
});

export type TQuestionForm = z.infer<typeof questionSchema>;
