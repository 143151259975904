import { z } from "zod";

const translationPath = "page.4uBus.register.accessTokens.errors.";

const privilegesSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().optional().nullable(),
  domain: z.string().optional().nullable(),
  method: z.string().optional().nullable(),
  path: z.string().optional().nullable(),
});

const softwareSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const legalEntitySchema = z.object({
  id: z.string(),
  corporateName: z.string(),
  document:  z.string().optional()
});

const individualSchema = z.object({
  id: z.string(),
  individualName: z.string(),
  document: z.string().optional(),
});

const restrictionRuleProfileSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

export const accessTokenSchema = z.object({
  id: z.string().optional(),
  isIntegration: z.boolean(),
  name: z.string().min(1, translationPath + "name"),
  privileges: z.array(privilegesSchema).min(1, translationPath + "privileges"),
  description: z.string().optional().nullable(),
  justification: z.string().optional().nullable(),
  software: softwareSchema.optional().nullable(),
  legalEntity: legalEntitySchema.optional().nullable(),
  individual: individualSchema.optional().nullable(),
  restrictionRuleProfiles: z
    .array(restrictionRuleProfileSchema)
    .optional()
    .nullable(),
});

export type TAccessTokenForm = z.infer<typeof accessTokenSchema>;
