import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

// Schema
import { TGenericForm, genericSchema } from "./GenericSchema";
import { MainApi } from "../../../../services/mainApi.service";
import { useCallback, useState } from "react";
import { FormContainer, FormInput, Treturn, useFetch } from "@4uhub/lib4uhub";

const GENERICS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";

const genericService = new MainApi<TGenericForm>(GENERICS_ROUTE);

const Generic = () => {
  const { t } = useTranslation();
  const [generic, setGeneric] = useState<TGenericForm>();

  const { sendRequest: update, loading: uLoading } = useFetch(
    genericService.updateItemById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    genericService.getItemById
  );

  const updateHandler = (v: TGenericForm, id: string) =>
    update({ item: v, id: id });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TGenericForm>> => {
      const { data, success } = await get(id);

      if (data) {
        setGeneric(data);
        return {
          data,
          success: true,
        };
      }

      return {
        data,
        success,
      };
    },
    [get]
  );

  const loading = uLoading || gLoading;

  return (
    <FormContainer<TGenericForm>
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t("page.register.generics.generic")}
      schema={genericSchema}
      subtitle={t("page.register.generics.edit_generic")}
      subtitleWatchField={"value"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} display={"flex"} alignItems={"center"}>
            <div style={{ display: "flex", gap: 10 }}>
              <Typography color={(theme) => theme.palette.text.secondary}>
                {t("page.register.generics.identifier") || "Identifier"}:
              </Typography>
              <Typography>{generic?.identifier}</Typography>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} display={"flex"} alignItems={"center"}>
            <div style={{ display: "flex", gap: 10 }}>
              <Typography color={(theme) => theme.palette.text.secondary}>
                {t("page.register.generics.code") || "Code"}:
              </Typography>
              <Typography>{generic?.code}</Typography>
            </div>
          </Grid>

          <Grid item xs={12}>
            <FormInput
              size="small"
              name="value"
              fullWidth
              label={t("page.register.generics.value") || "Value"}
            />
          </Grid>

          <Grid item xs={12}>
            <FormInput
              size="small"
              multiline
              name="description"
              fullWidth
              minRows={4}
              label={t("page.register.generics.description") || "Description"}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default Generic;
