import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainApiService, {
  MainApi,
} from "../../../../../services/mainApi.service";
import {
  AutoCompleteUncontrolled,
  ISelectType,
  Modal,
  Table,
} from "@4uhub/lib4uhub";
import { useCallback, useEffect, useMemo, useState } from "react";
import { EventTypesModal } from "../../../../../components/Events/EventTypes/EventTypesModal";
import { EventType } from "../../../../../components/Events/EventTypes/EventTypeForm/EventType";
import { EventsContainer } from "../../../../../components/Events/EventsContainer";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { IEvents } from "../../../../../models/event";
import { Chip, Stack } from "@mui/material";

const EVENT_ROUTE = process.env.REACT_APP_TICKETS + "/api/v1/TicketEvent/";
const eventService = new MainApiService<IEvents>(EVENT_ROUTE);

const EVENT_TYPES_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketEventType/";
const eventTypesService = new MainApi<ISelectType>(EVENT_TYPES_ROUTE);

const translationPath = "page.settings.crm.events.";

const EventsPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { eI } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedEventType, setSelectedEventType] =
    useState<ISelectType | null>(null);

  const [open, setOpen] = useState(false);

  const [gridMode, setGridMode] = useState(true);

  const columns: GridColDef<IEvents>[] = [
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
    },
    {
      field: "ticketEventType",
      headerName: t(translationPath + "event_type") || "Event Type",
      renderCell: (params) => (params.value ? params.value.name : "-"),
    },
    {
      field: "code",
      headerName: t(translationPath + "code") || "Code",
      renderCell: (params) => (params.value ? params.value.name : "-"),
      maxWidth: 150,
    },
    {
      field: "enable",
      headerName: t(translationPath + "status") || "Status",
      maxWidth: 150,
      renderCell: (params) => (
        <Chip
          size="small"
          label={
            params.value
              ? t(translationPath + "active")
              : t(translationPath + "inactive")
          }
          color={params.value ? "success" : "error"}
        />
      ),
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IEvents>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const changeToFormMode = useCallback(() => setGridMode(false), []);

  const changeToGridMode = useCallback(() => setGridMode(true), []);

  const openModalHandler = useCallback(() => setOpen(true), []);

  const closeModalHandler = () => {
    setOpen(false);
    changeToGridMode();
    if (searchParams.has("id")) {
      searchParams.delete("id");
      setSearchParams();
    }
  };

  const props = useMemo(() => {
    return {
      TicketEventTypeId: selectedEventType ? selectedEventType.id : undefined,
    };
  }, [selectedEventType]);

  useEffect(() => {
    if (searchParams.has("id")) {
      openModalHandler();
      changeToFormMode();
    }
  }, [searchParams, openModalHandler, changeToFormMode]);

  return (
    <>
      <Modal
        title={`${t(translationPath + "manage_event_types")}`}
        open={open}
        onClose={closeModalHandler}
        maxDialogWidth="lg"
      >
        <EventsContainer>
          <>
            {gridMode && (
              <EventTypesModal changeToFormMode={changeToFormMode} />
            )}
            {!gridMode && <EventType changeToGridMode={changeToGridMode} />}
          </>
        </EventsContainer>
      </Modal>
      <Table<IEvents>
        showDefaultMacroFilters={false}
        service={eventService}
        columns={columns}
        title={t(translationPath + "events")}
        searchInput
        onAdd={handleAdd}
        onRowClick={handleRowClick}
        defaultPageSize={20}
        disableFilterParams
        pageSizeOptions={[5, 10, 20]}
        serviceProps={props}
        macroFilters={() => (
          <Stack width={300}>
            <AutoCompleteUncontrolled
              label={t(translationPath + "event_type")}
              onValueSelected={setSelectedEventType}
              value={selectedEventType}
              size="small"
              fullWidth
              service={eventTypesService.list}
            />
          </Stack>
        )}
        tableActionsMenu={[
          {
            action: openModalHandler,
            text: t(translationPath + "manage_event_types"),
            icon: <EventNoteIcon />,
          },
        ]}
        backButton
        backButtonUrl={`/e/${eI}/dashboard/settings`}
        searchInputPlaceHolder={
          t(translationPath + "search") || "Search for an event"
        }
        addButtonLabel={t(translationPath + "add_event") || "Add Event"}
        confirmOptions={{
          title: t(translationPath + "confirm.title"),
          description: t(translationPath + "confirm.description"),
          confirmationButtonProps: {
            variant: "contained",
          },
        }}
      />
    </>
  );
};

export default EventsPage;
