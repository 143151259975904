import {
  Box,
  Drawer,
  Grid,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { memo, useCallback } from "react";

import { useSchedule } from "../ScheduleProvider";
import Professional from "./Professional";
import Situation from "./Situation";
import Period from "./Period";
import Legend from "./Legend";
import CalendarSource from "./CalendarSource";
import { ECalendarSource } from "../model";
import Speciality from "./Speciality";
import Calendar from "./Calendar";
import Exam from "./Exam";
import { MENU_WIDTH } from "../Schedule";

const Menu = () => {
  const {
    date,
    onDateChange,
    filter,
    setFilter,
    allAvaiableColor,
    fullDayColor,
    someAvaiableColor,
    open,
    setOpen,
  } = useSchedule();

  const { breakpoints } = useTheme();

  const downMd = useMediaQuery(breakpoints.down("md"));

  const handleSpecialityChange = useCallback(
    (value: string | null) => {
      if (value) {
        setFilter({
          speciality: value,
          professionals: filter.professionals.filter((p) => p === value),
        });
      } else {
        setFilter({ speciality: value, professionals: [] });
      }
    },
    [setFilter, filter]
  );

  const onSourceChange = useCallback(
    (v: ECalendarSource) => {
      if (v === ECalendarSource.CONSULT) {
        setFilter({ source: v, exams: [] });
        return;
      }

      if (v === ECalendarSource.EXAM) {
        setFilter({ source: v, professionals: [], speciality: null });
        return;
      }

      setFilter({ source: v });
    },
    [setFilter]
  );

  const handleExamChange = useCallback(
    (v: string[]) => {
      setFilter({ exams: v });
    },
    [setFilter]
  );

  const handleProfessionalChange = useCallback(
    (v: string[]) => setFilter({ professionals: v }),
    [setFilter]
  );

  const handleSituationChange = useCallback(
    (v: string | null) => setFilter({ situation: v }),
    [setFilter]
  );

  const handlePeriodChange = useCallback(
    (v: string) => setFilter({ period: v }),
    [setFilter]
  );

  const content = (
    <Grid
      item
      height={"100%"}
      width={MENU_WIDTH}
      py={2}
      sx={(t) => ({ borderLeft: `1px solid ${t.palette.divider}` })}
      overflow={"auto"}
    >
      <Grid container width={"100%"} gap={2}>
        <CalendarSource value={filter.source} onChange={onSourceChange} />
        <Calendar
          onDateChange={onDateChange}
          filter={filter}
          value={date}
          allAvaiableColor={allAvaiableColor}
          fullDayColor={fullDayColor}
          someAvaiableColor={someAvaiableColor}
        />
        <Stack gap={2} px={4} width={"100%"}>
          {(filter.source === ECalendarSource.CONSULT ||
            filter.source === ECalendarSource.BOTH) && (
            <Speciality
              value={filter.speciality}
              onChange={handleSpecialityChange}
            />
          )}

          {(filter.source === ECalendarSource.EXAM ||
            filter.source === ECalendarSource.BOTH) && (
            <Exam onChange={handleExamChange} value={filter.exams} />
          )}

          {(filter.source === ECalendarSource.CONSULT ||
            filter.source === ECalendarSource.BOTH) && (
            <Professional
              onChange={handleProfessionalChange}
              value={filter.professionals}
              speciality={filter.speciality}
            />
          )}

          <Stack direction={"row"} gap={1}>
            <Situation
              onChange={handleSituationChange}
              value={filter.situation}
            />
            <Period onChange={handlePeriodChange} value={filter.period} />
          </Stack>
          <Legend
            allAvaiableColor={allAvaiableColor}
            fullDayColor={fullDayColor}
            someAvaiableColor={someAvaiableColor}
          />
        </Stack>
      </Grid>
    </Grid>
  );

  if (downMd) {
    return (
      <Drawer open={open} onClose={() => setOpen(false)} anchor="right">
        <Box sx={{ width: MENU_WIDTH + 10 }}>{content}</Box>
      </Drawer>
    );
  }

  return content;
};

export default memo(Menu);
