import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../../../services/mainApi.service";
import { OverflowTooltip, Table } from "@4uhub/lib4uhub";
import { IProtocolsDefault } from "../../../../../models/protocols-default";
import { Chip } from "@mui/material";

const PROTOCOLS_DEFAULT_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketDefaultDoc/";
const protocolGenerateService = new MainApiService<IProtocolsDefault>(
  PROTOCOLS_DEFAULT_ROUTE
);

const translationPath = "page.settings.crm.protocolsDefault.";

const ProtocolsDefaultPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { eI } = useParams();

  const columns: GridColDef<IProtocolsDefault>[] = [
    {
      field: "title",
      headerName: t(translationPath + "title") || "Title",
      renderCell: (params) => {
        if (!params.value) return "-";
        return (
          <OverflowTooltip
            text={params.value}
            tooltip={params.value}
            variant="body2"
          />
        );
      },
    },
    {
      field: "message",
      headerName: t(translationPath + "message") || "Message",
      renderCell: (params) => {
        if (!params.value) return "-";
        return (
          <OverflowTooltip
            text={params.value}
            tooltip={params.value}
            variant="body2"
          />
        );
      },
    },
    {
      field: "enable",
      headerName: t(translationPath + "status") || "Status",
      maxWidth: 150,
      renderCell: (params) => (
        <Chip
          size="small"
          label={
            params.value
              ? t(translationPath + "active")
              : t(translationPath + "inactive")
          }
          color={params.value ? "success" : "error"}
        />
      ),
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IProtocolsDefault>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<IProtocolsDefault>
      showDefaultMacroFilters={false}
      service={protocolGenerateService}
      columns={columns}
      title={t(translationPath + "protocols_default")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      backButton
      backButtonUrl={`/e/${eI}/dashboard/settings`}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a protocol default"
      }
      addButtonLabel={
        t(translationPath + "add_protocol_default") || "Add protocol default"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default ProtocolsDefaultPage;
