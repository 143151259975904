import {
  AutoComplete,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSection } from "../../../../../../../components/FormSection/FormSection";
import MainApi from "../../../../../../../services/mainApi.service";
import { ticketChannelStepsByTicketChannel } from "../../../../../../../services/ticketChannelStep.service";

const TICKET_CHANNEL_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketChannels/";
const ticketChannelService = new MainApi<ISelectType>(TICKET_CHANNEL_ROUTE);

const translationPath = "components.boardList.";

interface IBoardListProps {
  label?: string;
  boardFieldName: string;
  listFieldName: string;
}

export const BoardList = memo(
  ({ label, boardFieldName, listFieldName }: IBoardListProps) => {
    const { t } = useTranslation();

    const [listOptions, setListOptions] = useState<ISelectType[]>([]);

    const { sendRequest } = useFetch(ticketChannelStepsByTicketChannel);

    const { control, setValue } = useFormContext();

    const selectedBoard = useWatch({
      name: boardFieldName,
      control,
    });

    const fetchListOptions = useCallback(async () => {
      if (!selectedBoard) {
        setListOptions([]);
        return;
      }
      const { data, success } = await sendRequest({
        id: selectedBoard.id,
        all: true,
      });
      if (data && success) {
        setListOptions(data.map((item) => ({ id: item.id, name: item.name })));
      }
    }, [sendRequest, selectedBoard]);

    useEffect(() => {
      fetchListOptions();
    }, [fetchListOptions]);

    const cleanListHandler = useCallback(() => {
      setValue(listFieldName, null);
    }, [setValue, listFieldName]);

    return (
      <>
        {label && (
          <Grid item xs={12} sm={12}>
            <FormSection title={label} />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <AutoComplete
            name={boardFieldName}
            label={t(translationPath + "board")}
            getOptionLabel={(option) => option.name}
            onValueChange={cleanListHandler}
            size="small"
            fullWidth
            request={ticketChannelService.list}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutoComplete
            name={listFieldName}
            label={t(translationPath + "list")}
            getOptionLabel={(option) => option.name}
            size="small"
            fullWidth
            options={listOptions}
          />
        </Grid>
      </>
    );
  }
);
