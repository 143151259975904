import { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Stack } from "@mui/system";
import { FormTabs, ITab } from "@4uhub/lib4uhub";

import AdvantagesAndBenefitsClubPage from "../AdvantagesAndBenefitsClub/AdvantagesAndBenefitsClubForm/AdvantagesAndBenefitsClub";
import { buildTPath as buildBenefitsClubTPath } from "../AdvantagesAndBenefitsClub/advantages-and-benefits-club.utils";

const Tabs = (): ReactElement => {
  const { t } = useTranslation();

  const tabs = useMemo(
    (): ITab[] => [
      {
        label: t(buildBenefitsClubTPath("title")),
        component: <AdvantagesAndBenefitsClubPage />,
      },
    ],
    [t]
  );

  return (
    <Stack height="100%">
      <FormTabs tabs={tabs} />
    </Stack>
  );
};

export default Tabs;
