import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AutoCompleteMulti, ISelectType, useFetch } from "@4uhub/lib4uhub";

import { buildTPath } from "../../../grantors.utils";
import MainApiService from "../../../../../services/mainApi.service";

const ESTABLISHMENT_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Establishment/";

const service = new MainApiService<ISelectType>(ESTABLISHMENT_ROUTE);

const EstablishmentsSelect = () => {
    const [options, setOptions] = useState<any>([]);

    const { t } = useTranslation()
    const { sendRequest: listEstablishments } = useFetch(service.list);

    const getEstablishments = useCallback(async () => {
        const { data, success } = await listEstablishments({});

        if(data && success) setOptions(data)
    }, [listEstablishments]);

    useEffect(() => {
        getEstablishments();
    }, [getEstablishments]);

    return (
        <AutoCompleteMulti
            getOptionLabel={({ name }) => name}
            size="small"
            multiline
            label={t(buildTPath("establishments"))}
            name="establishments"
            options={options}
        />
    )
};

export default EstablishmentsSelect;