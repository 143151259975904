import { useTranslation } from "react-i18next";

import Fields from "./Fields";
import { FormContainer, useFetch } from "@4uhub/lib4uhub";

import {
  beneficiarySuspensionsReasonSchema,
  TBeneficiarySuspensionReasonForm,
} from "./beneficiarySuspensionReason.schema";
import { buildTPath } from "../beneficiarySuspensionsReason.utils";
import {
  createBeneficiarySuspensionReason,
  findBeneficiarySuspensionReasonById,
  updateBeneficiarySuspensionReason,
} from "../../../../../services/beneficiarySuspensionsReason.service";

const BeneficiarySuspensionReason = () => {
  const { t } = useTranslation();

  const translate = (path: string) => t(buildTPath(path));

  const { sendRequest: create, loading: isSaving } = useFetch(
    createBeneficiarySuspensionReason
  );
  const { sendRequest: update, loading: isUpdating } = useFetch(
    updateBeneficiarySuspensionReason
  );
  const { sendRequest: findById, loading: isFetching } = useFetch(
    findBeneficiarySuspensionReasonById
  );

  const updateHandler = (
    values: TBeneficiarySuspensionReasonForm,
    id: string
  ) => update({ id, item: { ...values, id } });

  const isLoading = isSaving || isUpdating || isFetching;

  return (
    <FormContainer<TBeneficiarySuspensionReasonForm>
      saveFunction={create}
      updateFunction={updateHandler}
      getFunction={findById}
      loading={isLoading}
      title={translate("title")}
      schema={beneficiarySuspensionsReasonSchema}
      subtitle={translate("new")}
      subtitleWatchField="name"
      fields={() => <Fields />}
    />
  );
};

export default BeneficiarySuspensionReason;
