import { z } from "zod";
import { buildTPath } from "../sales-channel.utils";

const getTranslation = (field: string) => buildTPath(`errors.${field}`);

const legalEntitySchema = z.object({
  id: z.string(),
  name: z.string().optional().nullable(),
  code: z.string().optional().nullable(),
});

const individualSchema = z.object({
  identifier: z.string(),
  name: z.string().optional().nullable(),
  code: z.string().optional().nullable(),
});

const situationSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: getTranslation("situation") }
);

const classificationSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
    code: z.any().optional().nullable(),
  },
  { invalid_type_error: getTranslation("sales_channel_classification") }
);

const salesChannelTypeSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: getTranslation("sales_channel_type") }
);

export const salesChannelSchema = z
  .object({
    id: z.string().optional(),
    title: z.string().min(1, getTranslation("title")),
    code: z.string().nullish(),
    entity: z.enum(["QPF", "QPJ"]).optional(),
    situation: situationSchema.nullish(),
    salesChannelType: salesChannelTypeSchema,
    salesChannelClassification: classificationSchema,
    individual: individualSchema.optional().nullish(),
    legalEntity: legalEntitySchema.optional().nullish(),
  })
  .superRefine((value, context) => {
    const { entity, individual, legalEntity } = value;

    if (entity === "QPF" && !individual) {
      context.addIssue({
        code: "custom",
        path: ["individual"],
        message: getTranslation("individual_person"),
      });
    } else if (entity === "QPJ" && !legalEntity) {
      context.addIssue({
        code: "custom",
        path: ["legalEntity"],
        message: getTranslation("legal_entity"),
      });
    }

    value.entity = undefined;
  });

export type TSalesChannelForm = z.infer<typeof salesChannelSchema>;
