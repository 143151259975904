import {
  AutoComplete,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAutomationRules } from "../../../../../../../store/contexts/AutomationRulesContext";
import { TTimeRuleForm } from "./timeRuleSchema";
import MainApi from "../../../../../../../services/mainApi.service";

const translationPath = "components.timeRules.";

const GENERICS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const genericService = new MainApi<ISelectType>(GENERICS_ROUTE);

export const TypeofTimeFraction = memo(() => {
  const { t } = useTranslation();

  const { setValue } = useFormContext<TTimeRuleForm>();

  const { automation } = useAutomationRules();

  const [, setCurrentAutomation] = useState<ISelectType | null>(automation);

  const [options, setOptions] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(genericService.list);

  const fetchTypeofTimeFractions = useCallback(async () => {
    if (!automation) return;
    const { data, success } = await sendRequest({
      Identifier: "TypeofTimeFraction",
    });
    if (data && success) {
      let options = data;
      if (["9", "10"].includes(String(automation.code))) {
        options = options.filter((i) => i.code === "1");
      }
      setOptions(options.map((d) => ({ id: d.id, name: d.name })));
    }
  }, [sendRequest, automation]);

  useEffect(() => {
    fetchTypeofTimeFractions();
  }, [fetchTypeofTimeFractions]);

  useEffect(() => {
    setCurrentAutomation((oldState) => {
      if (oldState !== automation) {
        setValue("type", null);
      }
      return automation;
    });
  }, [automation, setValue]);

  return (
    <Grid item xs={12} sm={5}>
      <AutoComplete
        name="type"
        label={t(translationPath + "type") || "Type"}
        getOptionLabel={(option) => option.name}
        size="small"
        fullWidth
        options={options}
      />
    </Grid>
  );
});
