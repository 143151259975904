import { z } from "zod";
import { buildTPath } from "../beneficiaryCancellationReason.utils";

export const beneficiaryCancellationReasonSchema = z.object({
  id: z.string().nullish(),
  name: z.string().min(1, buildTPath("errors.name")),
  enable: z.boolean(),
});

export type TBeneficiaryCancellationReasonForm = z.infer<
  typeof beneficiaryCancellationReasonSchema
>;
