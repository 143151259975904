import { GlobalAxios } from "../axios/axios-config";
import {
  IContractProductPriceList,
  INewContractProductPriceList,
} from "../models/sales-product-price-list";

const SALES_PRODUCT_PRICE_LIST_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/SalesProductCtrPriceList/";

export const createContracProductPriceList = (
  item: INewContractProductPriceList
) => {
  return GlobalAxios.post(SALES_PRODUCT_PRICE_LIST_ROUTE, item);
};

export const getContracProductPriceListById = (id: string) => {
  return GlobalAxios.get<IContractProductPriceList>(
    SALES_PRODUCT_PRICE_LIST_ROUTE + id
  );
};

export const updateContracProductPriceListById = ({
  item,
  id,
}: {
  item: INewContractProductPriceList;
  id: string;
}) => {
  return GlobalAxios.put(SALES_PRODUCT_PRICE_LIST_ROUTE + id, item);
};

export const approveSalesProductPriceList = (id: string) => {
  return GlobalAxios.put(`${SALES_PRODUCT_PRICE_LIST_ROUTE}ApprovalDate/${id}`);
};
