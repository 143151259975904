import { GlobalAxios } from "./../axios/axios-config";
import { ICompanyGroup, IGetCompanyGroups } from "../models/company-groups";

const COMPANY_GROUP_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/CompanyGroup";

export class CompanyGroupsService {
  create = (item: ICompanyGroup) => {
    return GlobalAxios.post(COMPANY_GROUP_ROUTE, item);
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<IGetCompanyGroups>(COMPANY_GROUP_ROUTE + "/" + id);
  };

  updateItemById = ({ item, id }: { item: ICompanyGroup; id: string }) => {
    return GlobalAxios.put(COMPANY_GROUP_ROUTE + "/" + id, item);
  };
}

export default CompanyGroupsService;
