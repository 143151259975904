import { z } from "zod";

const translationPath = "chatBot.page.service.ticketServiceMessage.errors.";

const ticketServiceSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional(),
  },
  { invalid_type_error: translationPath + "ticket_service" }
);

export const ticketServiceMessageSchema = z.object({
  id: z.string().optional(),
  message: z.string().min(1, translationPath + "message"),
  messageType: z.string().min(1, translationPath + "message_type"),
  ticketService: ticketServiceSchema,
});
export type TTicketServiceMessageForm = z.infer<
  typeof ticketServiceMessageSchema
>;
