import { z } from "zod";

const translationPath = "page.settings.crm.eventTypes.errors.";

export const eventTypeSchema = z.object({
  id: z.string().optional(),
  enable: z.boolean(),
  name: z.string().min(1, translationPath + "name"),
});

export type TEventTypeForm = z.infer<typeof eventTypeSchema>;
