import { useTranslation } from "react-i18next";

import { AutoComplete } from "@4uhub/lib4uhub";

import { buildTPath } from "../../advantages-and-benefits-club.utils";
import { listContractTypes } from "../../../../../../services/salesContractType.service";

const SalesContractTypeSelect = () => {
  const { t } = useTranslation();

  const translate = (field: string) => t(buildTPath(field));

  return (
    <AutoComplete
      name="salesContractType"
      size="small"
      label={translate("contract_type")}
      getOptionLabel={({ name }) => name}
      request={listContractTypes}
    />
  );
};

export default SalesContractTypeSelect;
