import { GlobalAxios } from "../axios/axios-config";
import { IRestrictionSchedule } from "../models/restriction-rules";
import { TScheduleForm } from "../pages/4uBus/Restrictions/Schedules/Schedule/ScheduleSchema";

const RULE_ROUTE = process.env.REACT_APP_4UBUS + "/api/v1/RestrictionRule/";

export const createSchedule = (item: IRestrictionSchedule) => {
  return GlobalAxios.post(RULE_ROUTE, item);
};

export const getScheduleById = (id: string) => {
  return GlobalAxios.get<TScheduleForm>(RULE_ROUTE + id);
};

export const updateScheduleById = ({
  item,
  id,
}: {
  item: IRestrictionSchedule;
  id: string;
}) => {
  return GlobalAxios.put(RULE_ROUTE + id, item);
};
