import { GlobalAxios } from "./../axios/axios-config";
import {
  INewTicketAutomationMessageRule,
  ITicketAutomationMessageRules,
} from "../models/automation-message-rules";

const TICKET_SERVICE_WHATS_ROUTE =
  process.env.REACT_APP_AUTOMATIONS + "/api/v1/TicketAutomationMessageRules/";

export const createTicketAutomationMessageRule = (
  item: INewTicketAutomationMessageRule
) => {
  return GlobalAxios.post(TICKET_SERVICE_WHATS_ROUTE, item);
};

export const getTicketAutomationMessageRuleById = (id: string) => {
  return GlobalAxios.get<ITicketAutomationMessageRules>(
    TICKET_SERVICE_WHATS_ROUTE + id
  );
};

export const updateTicketAutomationMessageRuleById = ({
  item,
  id,
}: {
  item: INewTicketAutomationMessageRule;
  id: string;
}) => {
  return GlobalAxios.put(TICKET_SERVICE_WHATS_ROUTE + id, item);
};
