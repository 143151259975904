import React from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { ISwitchInput } from "./models";
import { useFormContext } from "@4uhub/lib4uhub";
import { Controller } from "react-hook-form";

export const SwitchInput = ({
  label,
  name,
  defaultValue = true,
}: ISwitchInput) => {
  const { control } = useFormContext();
  return (
    <Controller
      render={({ field }) => {
        const { onChange, value } = field;
        return (
          <FormControlLabel
            control={
              <Switch
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
              />
            }
            label={label}
            {...field}
          />
        );
      }}
      defaultValue={defaultValue}
      name={name}
      control={control}
    />
  );
};
