import { ReactElement } from "react";

import { Grid } from "@mui/material";
import { FormSection } from "../../../../../../components/FormSection/FormSection";

const Section = ({ label }: { label: string }): ReactElement => {
  return (
    <Grid item p={2} width="100%" boxSizing="border-box">
      <FormSection title={label} />
    </Grid>
  );
};

export default Section;
