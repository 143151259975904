import { GlobalAxios } from "../axios/axios-config";
import {
  IRobotExecutionList,
  IRobotList,
  IRobotLogsExecutionParams,
} from "../models/robots-list";

const ROBOTS_LIST_ROUTE = process.env.REACT_APP_4UBUS + "/api/v1/Robot";

export const getRobotsLogsList = () => {
  return GlobalAxios.get<IRobotList[]>(ROBOTS_LIST_ROUTE);
};

export const getRobotsLogsExecution = (params: IRobotLogsExecutionParams) => {
  return GlobalAxios.get<IRobotExecutionList[]>(
    ROBOTS_LIST_ROUTE + "Execution",
    {
      params,
    }
  );
};
