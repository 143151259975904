import { Grid } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import Calendar from "./Calendar/Calendar";
import { ScheduleProvider } from "./ScheduleProvider";
import Menu from "./Menu/Menu";
import { ICalendar } from "./model";
import { CALENDAR_COLORS } from "./data";
import SchedulePopover from "./SchedulePopover/SchedulePopover";

interface ISource {
  identifier: string;
  name: string;
  schedules: ICalendar[];
}

export type TTeste = ISource[];

export const MENU_WIDTH = 300;

const Schedule = () => {
  const navigate = useNavigate();

  const onAvaiableTimeClickHandler = useCallback(
    (avaiableTime: ICalendar) => {
      navigate(`new/${avaiableTime.identifier}`, { state: avaiableTime });
    },
    [navigate]
  );

  return (
    <ScheduleProvider {...CALENDAR_COLORS}>
      <Grid container direction={"row"} height={"100%"}>
        <Grid
          item
          height={"100%"}
          sx={(t) => ({
            width: "100%",
            [t.breakpoints.up("md")]: {
              width: `calc(100% - ${MENU_WIDTH}px)`,
            },
          })}
        >
          <Calendar
            onAvaiableTimeClick={onAvaiableTimeClickHandler}
            renderPopOver={(event, onClose) => (
              <SchedulePopover event={event} onClose={onClose} />
            )}
            {...CALENDAR_COLORS}
          />
        </Grid>
        <Menu />
      </Grid>
    </ScheduleProvider>
  );
};

export default Schedule;
