import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Modal, RenderCellTooltip, Table } from "@4uhub/lib4uhub";

import { useCallback, useMemo, useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";

import MainApiService from "../../../services/mainApi.service";
import { IEstablishments } from "../../../models/establishments";
import { IModalCollector } from "../../../models/collectors";
import OnPremiseModal from "./OnPremise/OnPremiseModal";
import EstablishmentsRowActions from "./EstablishmentsRowActions";

const GENERICS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Establishment/";
const TRANSLATION_PATH = "page.register.establishments.";

const genericService = new MainApiService<IEstablishments>(GENERICS_ROUTE);

const EstablishmentsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [modalCollector, setModalCollector] = useState<IModalCollector>();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const columns: GridColDef<IEstablishments>[] = useMemo(
    () => [
      {
        field: "name",
        headerName: t(TRANSLATION_PATH + "name") || "Name",
      },
      {
        field: "domain",
        headerName: t(TRANSLATION_PATH + "domain") || "Domain",
        renderCell: RenderCellTooltip,
      },
      {
        field: "collectorHost",
        headerName: t(TRANSLATION_PATH + "collector_host") || "Collector host",
        renderCell: RenderCellTooltip,
      },
      {
        field: "parentHost",
        headerName: t(TRANSLATION_PATH + "parent_host") || "Parent host",
        renderCell: RenderCellTooltip,
      },
      {
        field: "realTimeHost",
        headerName: t(TRANSLATION_PATH + "real_time_host") || "Real time host",
        renderCell: RenderCellTooltip,
      },
      {
        field: "integrationId",
        headerName: t(TRANSLATION_PATH + "integration") || "Integration",
        renderCell: RenderCellTooltip,
        maxWidth: 90,
      },
    ],
    [t]
  );

  const handleRowClick = (params: GridRowParams<IEstablishments>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  const onCloseHandler = () => setOpen(false);

  const onIsLoading = useCallback((isLoading: boolean) => {
    setIsLoading(isLoading);
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseHandler}
        maxDialogWidth={matches ? "xs" : "sm"}
        title={`${t(
          TRANSLATION_PATH + modalCollector?.modalType + "_on_premise"
        )}`}
      >
        <OnPremiseModal modalCollector={modalCollector} />
      </Modal>
      <Table<IEstablishments>
        showDefaultMacroFilters={false}
        service={genericService}
        columns={columns}
        title={t(TRANSLATION_PATH + "establishments")}
        syncing={isLoading}
        searchInput
        onAdd={handleAdd}
        showDeleteButton={false}
        onRowClick={handleRowClick}
        defaultPageSize={20}
        pageSizeOptions={[5, 10, 20]}
        rowActions={EstablishmentsRowActions({
          setModalCollector: setModalCollector,
          setOpen: setOpen,
          setIsLoading: onIsLoading,
        })}
        searchInputPlaceHolder={
          t(TRANSLATION_PATH + "search_establishment") || "Searh for a name"
        }
        addButtonLabel={
          t(TRANSLATION_PATH + "add_establishment") || "Add Establishment"
        }
        confirmOptions={{
          title: t(TRANSLATION_PATH + "confirm.title"),
          description: t(TRANSLATION_PATH + "confirm.description"),
          confirmationButtonProps: {
            variant: "contained",
          },
        }}
      />
    </>
  );
};

export default EstablishmentsPage;
