import {
  AutoComplete,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import MainApi from "../../../../../../services/mainApi.service";
import { useWatch } from "react-hook-form";
import { TAutomationRuleForm } from "../automationRuleSchema";
import { useTranslation } from "react-i18next";

const TICKET_EVENT_ROUTE =
  process.env.REACT_APP_AUTOMATIONS + "/api/v1/TicketEvent/";
const ticketEventService = new MainApi<ISelectType>(TICKET_EVENT_ROUTE);

const translationPath = "chatBot.page.automations.automationRules.";

export const SelectTicketEvent = memo(() => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<ISelectType[]>([]);

  const { control } = useFormContext<TAutomationRuleForm>();

  const selectedAutomation = useWatch({
    name: "ticketAutomation",
    control,
  });

  const { sendRequest } = useFetch(ticketEventService.list);

  const fetchTicketEvents = useCallback(async () => {
    if (!selectedAutomation) return;
    const { data, success } = await sendRequest({
      TicketAutomationId: selectedAutomation.id,
    });
    if (data && success) {
      setOptions(data);
    }
  }, [sendRequest, selectedAutomation]);

  useEffect(() => {
    fetchTicketEvents();
  }, [fetchTicketEvents]);

  return (
    <Grid item xs={12} sm={6}>
      <AutoComplete
        name="ticketEvent"
        label={t(translationPath + "event")}
        getOptionLabel={(option) => option.name}
        size="small"
        fullWidth
        options={options}
      />
    </Grid>
  );
});
