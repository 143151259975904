import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  DateInput,
  FormContainer,
  FormInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import { memo, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  createContracProductPriceList,
  getContracProductPriceListById,
  updateContracProductPriceListById,
} from "../../../../../services/contractProductPriceList.service";
import { useSalesContract } from "../../../../../store/contexts/SalesContractContext";
import {
  TContractProductPriceListForm,
  contractProductPriceListSchema,
} from "./contractProductPriceListSchema";
import { IContracProductProps } from "./models";
import { SalesProductCtrPriceListItems } from "./SalesProductCtrPriceListItems/SalesProductCtrPriceListItems";

const translationPath =
  "page.4uSalesContract.registers.salesProductCtrPriceList.";

export const SalesProductCtrPriceList = memo(({ id }: IContracProductProps) => {
  const { id: salesProductCtrId } = useParams();
  const { onClose, onRefresh } = useSalesContract();
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(
    createContracProductPriceList
  );

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateContracProductPriceListById
  );

  const { sendRequest: get, loading: gLoading } = useFetch(
    getContracProductPriceListById
  );

  const updateHandler = (v: TContractProductPriceListForm, id: string) => {
    return update({
      item: {
        id: id,
        name: v.name,
        code: v.code,
        initialDate: v.initialDate.toISOString().substring(0, 10),
        endDate: v.endDate.toISOString().substring(0, 10),
        salesProductCtrId: salesProductCtrId ?? "",
        salesProductCtrPriceListItems: v.salesProductCtrPriceListItems
          ? v.salesProductCtrPriceListItems.map((listItem) => ({
              price: parseFloat(listItem.price),
              degreeOfEntitlementId: listItem.degreeOfEntitlement
                ? listItem.degreeOfEntitlement.id
                : "",
              initalAge: listItem.initalAge
                ? parseInt(listItem.initalAge)
                : null,
              endAge: listItem.endAge ? parseInt(listItem.endAge) : null,
            }))
          : null,
      },
      id: id,
    });
  };

  const saveHandler = (v: TContractProductPriceListForm) =>
    save({
      name: v.name,
      code: v.code,
      initialDate: v.initialDate.toISOString().substring(0, 10),
      endDate: v.endDate.toISOString().substring(0, 10),
      salesProductCtrId: salesProductCtrId ?? "",
      salesProductCtrPriceListItems: v.salesProductCtrPriceListItems.map(
        (listItem) => ({
          price: parseFloat(listItem.price),
          degreeOfEntitlementId: listItem.degreeOfEntitlement
            ? listItem.degreeOfEntitlement.id
            : "",
          initalAge: listItem.initalAge ? parseInt(listItem.initalAge) : null,
          endAge: listItem.endAge ? parseInt(listItem.endAge) : null,
        })
      ),
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TContractProductPriceListForm>> => {
      const { data, success } = await get(id);

      if (data) {
        const newData: TContractProductPriceListForm = {
          ...data,
          name: data.name,
          code: data.code,
          initialDate: new Date(`${data.initialDate}T00:00:00`),
          endDate: new Date(`${data.endDate}T00:00:00`),
          salesProductCtrPriceListItems: data.salesProductCtrPriceListItems.map(
            (listItem) => ({
              ...listItem,
              endAge: listItem.endAge ? listItem.endAge.toString() : "",
              initalAge: listItem.initalAge
                ? listItem.initalAge.toString()
                : "",
              price: listItem.price ? listItem.price.toString() : "",
            })
          ),
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const successHandle = () => {
    onClose();
    onRefresh();
  };
  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TContractProductPriceListForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "price_list")}
      schema={contractProductPriceListSchema}
      subtitle={t(translationPath + "new_price_list")}
      subtitleWatchField={"name"}
      showBackButton={false}
      onSuccess={successHandle}
      redirectOnSave={false}
      backButtonOnClick={onClose}
      isModalForm={true}
      propId={id}
      marginBottom={0}
      customHeight={"fit-content"}
      formActionsOnBottom={false}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="name"
              fullWidth
              label={t(translationPath + "name") || "Name"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              name="code"
              label={t(translationPath + "code") || "Code"}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateInput
              name={"initialDate"}
              label={t(translationPath + "initial_date") || "Initial Date"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateInput
              name={"endDate"}
              label={t(translationPath + "end_date") || "End Date"}
            />
          </Grid>

          <SalesProductCtrPriceListItems />
        </Grid>
      )}
    />
  );
});
