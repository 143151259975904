import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../../store/store";
import { menuSliceActions } from "../../../store/slices/menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SubMenu from "../SubMenu/SubMenu";
import { IMenuRoute } from "../../../services/menuRoute.service";
import useCoordinatesConjuctions from "../../../hooks/useCoordinatesConjunctions";

interface IMenuItemProps {
  activeAccordion: boolean;
  onClick?: () => void;
  item: IMenuRoute;
}

const MenuItem: React.FC<IMenuItemProps> = ({
  activeAccordion,
  item,
  onClick,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const dispatch = useDispatch();
  const activeItem = useAppSelector((state) => state.menu.openItem);
  const open = item.id === activeItem?.id;

  const itemName = useCoordinatesConjuctions(item.name);

  const styles: Record<string, SxProps<Theme> | undefined> = {
    MenuItem: {
      padding: "10px",
      paddingRight: "20px",
      width: open ? "110%" : "100%",
      position: "relative",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: !open ? theme.palette.action.hover : "none",
      },

      backgroundColor: open
        ? theme.palette.mode === "dark"
          ? "#242424"
          : "#FAFAFA"
        : "transparent",
      borderRadius: theme.shape.borderRadius - 7,
    },
    container: {
      width: "100%",
    },
    dot: {
      width: "10px",
      height: "10px",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.primary.main,
    },
  };

  const onClickHandler = () => {
    dispatch(menuSliceActions.setOpenItem(item));
  };

  if (activeAccordion) {
    return (
      <Accordion
        sx={{
          width: "100%",
          boxShadow: "none",
        }}
        expanded={open}
        disableGutters={matches}
      >
        <AccordionSummary
          onClick={onClickHandler}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{itemName}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <SubMenu accordion={true} onClick={onClick} />
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Box onClick={onClickHandler} sx={styles.container}>
      <Stack
        sx={styles.MenuItem}
        direction={"row"}
        spacing={1}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography fontWeight={"bold"} >
          {itemName}
        </Typography>
        {open && <Box sx={styles.dot}></Box>}
      </Stack>
    </Box>
  );
};
export default MenuItem;
