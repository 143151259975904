import { z } from "zod";

const translation_path = "page.register.generics.errors.";

export const genericSchema = z.object({
  identifier: z.string().nonempty(translation_path + "identifier"),
  code: z.string().nonempty(translation_path + "code"),
  value: z.string().nonempty(translation_path + "value"),
  description: z.string().optional().nullable(),
  id: z.string().optional(),
});

export type TGenericForm = z.infer<typeof genericSchema>;
