import {
  FormArray,
  FormInput,
  ISelectType,
  useFormContext,
} from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import { useFieldArray } from "react-hook-form";
import { Grid } from "@mui/material";
import WorkGroupTypeLang from "./WorkGroupTypeLang";
import { useState } from "react";
import { TWorkGroupTypeForm } from "./WorkGroupTypeSchema";

const translationPath = "page.settings.preventMedicine.workGroupTypes.";

interface IWorkGroupTypeLanguagesProps {
  allLanguages: ISelectType[];
}

const WorkGroupTypeLanguages = ({
  allLanguages,
}: IWorkGroupTypeLanguagesProps) => {
  const { t } = useTranslation();

  const { control, formState } = useFormContext<TWorkGroupTypeForm>();

  const [defaultLang, setDefaultLang] = useState<ISelectType>();

  const workGroupTyleLanguagesArray = useFieldArray<
    TWorkGroupTypeForm,
    "healthProgramWorkGroupTypeLanguages",
    "key"
  >({
    control,
    name: "healthProgramWorkGroupTypeLanguages",
    keyName: "key",
  });

  return (
    <FormArray
      title={""}
      name="healthProgramWorkGroupTypeLanguages"
      errorMessage={
        formState.errors?.healthProgramWorkGroupTypeLanguages?.message
      }
      addButtonLabel={t(translationPath + "add_language") || "Add Language"}
      formArray={workGroupTyleLanguagesArray}
      showAddButton={
        workGroupTyleLanguagesArray.fields.length !== 0 && !defaultLang
          ? false
          : true
      }
      appendValue={{
        name: "",
        description: "",
        language: defaultLang ? defaultLang : allLanguages[0],
      }}
      fieldsObject={(index) => {
        const name = `healthProgramWorkGroupTypeLanguages.${index}.name`;
        const description = `healthProgramWorkGroupTypeLanguages.${index}.description`;
        const language = `healthProgramWorkGroupTypeLanguages.${index}.language`;

        return (
          <>
            <Grid item xs={12} sm={6}>
              <FormInput
                name={name}
                size="small"
                label={t(translationPath + "name")}
                fullWidth
              />
            </Grid>

            <WorkGroupTypeLang
              index={index}
              name={language}
              allLanguages={allLanguages}
              loadDefaultLang={setDefaultLang}
            />

            <Grid item sm={12}>
              <FormInput
                name={description}
                size="small"
                label={t(translationPath + "description")}
                fullWidth
                multiline
                minRows={4}
              />
            </Grid>
          </>
        );
      }}
    />
  );
};

export default WorkGroupTypeLanguages;
