import {
  Box,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { memo, useCallback, useMemo } from "react";
import AddIcon from "@mui/icons-material/Add";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import { useTemplateWhats } from "../../../../store/contexts/TemplateWhatsContext";
import { ISelectType, useFormContext } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

const translationPath = "components.selectWhatsButtons.";

export const BUTTONS_OPTIONS: ISelectType[] = [
  {
    id: "0",
    name: "placeholder",
  },
  {
    id: "1",
    name: "components.whatsButtonsOptions.personalized.name",
    code: "1",
    description: "",
    value: "QUICK_REPLY",
  },
  {
    id: "2",
    name: "components.whatsButtonsOptions.access_site.name",
    code: "2",
    description: "components.whatsButtonsOptions.access_site.description",
    value: "URL",
  },
  {
    id: "3",
    name: "components.whatsButtonsOptions.call.name",
    code: "2",
    description: "components.whatsButtonsOptions.call.description",
    value: "PHONE_NUMBER",
  },
  {
    id: "4",
    name: "components.whatsButtonsOptions.call_whats.name",
    code: "2",
    description: "components.whatsButtonsOptions.call_whats.description",
    value: "VOICE_CALL",
  },
  {
    id: "5",
    name: "components.whatsButtonsOptions.copy_offer_code.name",
    code: "2",
    description: "components.whatsButtonsOptions.copy_offer_code.description",
    value: "COPY_CODE",
  },
];

export const SelectButtons = memo(() => {
  const { t } = useTranslation();

  const options = useMemo(() => BUTTONS_OPTIONS, []);

  const {
    isFixedData,
    ctaButtons,
    customButtons,
    addCtaHandler,
    addCustomButtonHandler,
  } = useTemplateWhats();

  const { setValue } = useFormContext();

  const buttonsLength = ctaButtons.length + customButtons.length;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const accessSiteButtons = ctaButtons.filter(
    (btn) => btn.actionType.id === "2"
  );

  const callButtons = !!ctaButtons.find(
    (btn) => btn.actionType.id === "3" || btn.actionType.id === "4"
  );

  const copyOfferButton = !!ctaButtons.find((btn) => btn.actionType.id === "5");

  const onChangeHandler = useCallback(
    (event: SelectChangeEvent<string>) => {
      const {
        target: { value },
      } = event;

      const selectedType = options.find((opt) => opt.id === value);

      if (!selectedType) return;
      if (selectedType.id === "1") {
        setValue(
          `customButtons.${customButtons.length}.order`,
          customButtons.length + 1
        );
        setValue(
          `customButtons.${customButtons.length}.id`,
          new Date().toISOString()
        );
        addCustomButtonHandler({
          id: new Date().toISOString(),
          order: customButtons.length + 1,
          text: "",
        });
      } else {
        setValue(
          `ctaButtons.${ctaButtons.length}.order`,
          ctaButtons.length + 1
        );
        addCtaHandler({
          actionType: selectedType,
          order: ctaButtons.length + 1,
          id: new Date().toISOString(),
          text: selectedType.id === "5" ? "Copiar código da oferta" : "",
          payload: "",
        });
      }
    },
    [
      addCtaHandler,
      addCustomButtonHandler,
      setValue,
      ctaButtons,
      customButtons,
      options,
    ]
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Tooltip
        title={
          isFixedData
            ? t(translationPath + "generate_automatically")
            : t(translationPath + "tooltip_limit")
        }
        disableHoverListener={!isFixedData && buttonsLength < 10}
        disableFocusListener={!isFixedData && buttonsLength < 10}
      >
        <Select
          value={options[0].id}
          placeholder={`${t(translationPath + "add_button")}`}
          size="small"
          startAdornment={<AddIcon color="inherit" fontSize="small" />}
          sx={{ width: matches ? "100%" : 300 }}
          onChange={onChangeHandler}
          disabled={buttonsLength === 10 || isFixedData}
          renderValue={() => {
            return (
              <Typography pl={2} color={"inherit"} variant="body2">
                {t(translationPath + "add_button")}
              </Typography>
            );
          }}
        >
          <MenuItem
            key={options[0].id}
            value={options[0].id}
            sx={{ display: "none" }}
          >
            {t(options[0].name)}
          </MenuItem>
          <ListSubheader
            sx={(t) => ({
              color: t.palette.text.primary,
              fontSize: 14,
              fontWeight: "bold",
            })}
          >
            {t(translationPath + "quick_answer_buttons")}
          </ListSubheader>
          {options
            .filter((option) => option.code === "1")
            .map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {t(option.name)}
              </MenuItem>
            ))}
          <ListSubheader
            sx={(t) => ({
              color: t.palette.text.primary,
              fontSize: 14,
              fontWeight: "bold",
            })}
          >
            {t(translationPath + "call_to_action_buttons")}
          </ListSubheader>
          {options
            .filter((option) => option.code === "2" && option.id !== "4")
            .map((option) => (
              <MenuItem
                key={option.id}
                value={option.id}
                disabled={
                  (option.id === "2" && accessSiteButtons.length === 2) ||
                  ((option.id === "3" || option.id === "4") && callButtons) ||
                  (option.id === "5" && copyOfferButton)
                }
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Typography variant="body1">{t(option.name)}</Typography>
                <Typography
                  variant="body2"
                  sx={(t) => ({
                    color:
                      t.palette.grey[t.palette.mode === "light" ? 600 : 400],
                  })}
                >
                  {t(option.description || "")}
                </Typography>
              </MenuItem>
            ))}
        </Select>
      </Tooltip>
      <Box sx={{ mt: 2, display: "flex", alignItems: "center", gap: 1 }}>
        <EmojiObjectsIcon color="inherit" fontSize="small" />
        <Typography
          variant="body2"
          sx={(t) => ({
            color: t.palette.grey[t.palette.mode === "light" ? 600 : 400],
          })}
        >
          {t(translationPath + "help")}
        </Typography>
      </Box>
    </Box>
  );
});
