import {
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";

const translationPath = "page.additionalSettings.";

const AditionalSettingsHeader = ({
  onSearch,
}: {
  onSearch: (value: string) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        position: "relative",
        height: "100%",
        overflow: "hidden",
        paddingTop: 10,
      }}
    >
      <Typography variant="h5" mb={2}>
        {t(translationPath + "additionalSettings")}
      </Typography>
      <Stack direction={"row"} spacing={1} mb={5}>
        <Box
          sx={{
            width: "30%",
            minWidth: "200px",
          }}
        >
          <TextField
            size="small"
            fullWidth
            placeholder={`${t(translationPath + "search")}`}
            onChange={(e) => onSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default AditionalSettingsHeader;
