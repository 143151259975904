import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";

const translationPath = "components.forgetPasswordModal.";

type TForgetPasswordModal = {
  email: string;
};

const ForgetPasswordModal: React.FC<TForgetPasswordModal> = ({ email }) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogTitle>
        {t(translationPath + "title") || "Instructions Sent"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {t(translationPath + "message")}
        </DialogContentText>
      </DialogContent>
    </>
  );
};

export default ForgetPasswordModal;
