import { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Chip, ChipOwnProps } from "@mui/material";
import { buildTPath } from "./contracts.utils";

type TCode = "1" | "2" | "3" | "4";

const SituationChip = ({ code }: { code: TCode }): ReactElement => {
  const { t } = useTranslation();

  const translate = (path: string): string => t(buildTPath(path));

  const color = useMemo((): ChipOwnProps["color"] => {
    switch (code) {
      case "2":
        return "success";
      case "3":
        return "error";
      case "4":
        return "warning";
      default:
        return;
    }
  }, [code]);

  const label = () => {
    switch (code) {
      case "1":
        return translate("pending");
      case "2":
        return translate("approved");
      case "3":
        return translate("canceled");
      case "4":
        return translate("terminated");
    }
  };

  return <Chip size="small" color={color} label={label()} />;
};

export default SituationChip;
