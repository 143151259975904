import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../services/mainApi.service";
import {
  OverflowTooltip,
  SelectTreeUncontrolled,
  Table,
} from "@4uhub/lib4uhub";
import { IQuestions } from "../../../models/questions";
import { format } from "date-fns";
import { ptBR, enUS } from "date-fns/locale";
import { useCallback, useMemo, useState } from "react";
import { ITreeNode } from "../../../models/nodes";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { GlobalAxios } from "../../../axios/axios-config";

const SITE_APP_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Question/";
const siteAppService = new MainApiService<IQuestions>(SITE_APP_ROUTE);

const CATEGORIES_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/QuestionCategories";

const translationPath = "page.assessments.questions.";

const QuestionsPage = () => {
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [categories, setCategories] = useState<ITreeNode[]>([]);

  const columns: GridColDef<IQuestions>[] = [
    {
      field: "title",
      headerName: t(translationPath + "title") || "Title",
      renderCell: (params) =>
        params.value ? (
          <OverflowTooltip
            text={params.value}
            tooltip={params.value}
            textTransform="none"
            variant="body2"
          />
        ) : (
          "-"
        ),
    },
    {
      field: "description",
      headerName: t(translationPath + "description") || "Description",
      renderCell: (params) =>
        params.value ? (
          <OverflowTooltip
            text={params.value}
            tooltip={params.value}
            textTransform="none"
            variant="body2"
          />
        ) : (
          "-"
        ),
    },
    {
      field: "questionCategory",
      headerName:
        t(translationPath + "question_category") || "Question category",
      renderCell: (params) => (params.value ? params.value.name : "-"),
    },
    {
      field: "created",
      headerName: t(translationPath + "created") || "Created",
      renderCell: (params) => {
        if (!params.value) return "-";

        const initialDate = new Date(params.value);
        return format(initialDate, "Pp", {
          locale: language === "pt-BR" ? ptBR : enUS,
        }).replaceAll(",", "");
      },
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IQuestions>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const getNodes = useCallback((value: ITreeNode[]) => {
    setCategories(value);
  }, []);

  const params = useMemo(() => {
    return {
      QuestionCategoriesIds: categories.map((category) => category.id),
    };
  }, [categories]);

  return (
    <Table<IQuestions>
      showDefaultMacroFilters={false}
      service={siteAppService}
      columns={columns}
      title={t(translationPath + "questions")}
      searchInput
      onAdd={handleAdd}
      serviceProps={params}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      macroFilters={() => (
        <Stack
          sx={{
            pt: matches ? 2 : undefined,
            width: matches ? 320 : 500,
          }}
        >
          <SelectTreeUncontrolled
            size="small"
            fullWidth
            multiple
            limitTags={2}
            route={CATEGORIES_ROUTE}
            listRoute={CATEGORIES_ROUTE + "/ListAutoRelated"}
            axios={GlobalAxios}
            getNodes={getNodes}
            selectTreeUncontrolledProps={{
              label: t(translationPath + "category.label"),
              noOptionsText: t(translationPath + "category.no_options_text"),
              optionsButtonLabel: t(
                translationPath + "category.options_button_label"
              ),
              drawerProps: {
                title: t(translationPath + "category.drawer_props.title"),
                searchPlaceholder: t(
                  translationPath + "category.drawer_props.search_placeholder"
                ),
                createButtonLabel: t(
                  translationPath + "category.drawer_props.create_button_label"
                ),
                applyButtonLabel: t(
                  translationPath + "category.drawer_props.apply_button_label"
                ),
                inputLabel: t(
                  translationPath + "category.drawer_props.input_label"
                ),
                deleteConfirm: {
                  title: t(
                    translationPath +
                      "category.drawer_props.delete_confirm.title"
                  ),
                  message: t(
                    translationPath +
                      "category.drawer_props.delete_confirm.message"
                  ),
                },
              },
            }}
          />
        </Stack>
      )}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Searh for a question"
      }
      addButtonLabel={t(translationPath + "add_question") || "Add Question"}
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default QuestionsPage;
