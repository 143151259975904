import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { IBannerFileValueProps } from "./models";
import { InputFileForm } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../../../../../../axios/axios-config";

const FILE_ROUTE = process.env.REACT_APP_FILEMANAGER + "/api/v1/Upload";

const BannerFileValue: React.FC<IBannerFileValueProps> = ({
  index,
  onFileSelected,
}) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={12}>
      <InputFileForm
        name={`bannerMediaFiles.${index}.file`}
        axios={GlobalAxios}
        fileRoute={FILE_ROUTE}
        fileInputProps={{
          accept: ".jpg, ,jpeg, .png, .webp",
          label: `${t("page.register.extensions.file")}`,
          maxFileSize: 2,
          mediaTypeCode: "1",
          mediaTypeIdentifier: "BannerMediaType",
          parentName: "Banner",
          type: "Public",
          onFileSelected: onFileSelected,
          inputProps: {
            size: "small",
          },
        }}
      />
    </Grid>
  );
};

export default BannerFileValue;
