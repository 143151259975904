import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SettingsCacheContainer from "../SettingsCacheKey/SettingsCacheContainer";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useState } from "react";
import {
  TSearchCacheKeyForm,
  searchCacheKeySchema,
} from "./SearchCacheKeySchema";
import SearchCacheKeyModal from "./SearchCacheKeyModal";
import { Form, FormInput, Modal, useFetch } from "@4uhub/lib4uhub";
import CacheService from "../../../../../../services/cache.service";

const translationPath = "components.settingsCache.searchCacheKey.";
const service = new CacheService();

const SearchCacheKey = () => {
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const [key, setKey] = useState("");
  const { t } = useTranslation();
  const methods = useForm<TSearchCacheKeyForm>({
    resolver: zodResolver(searchCacheKeySchema),
  });

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const { sendRequest, loading } = useFetch(service.getCacheByKey);
  const { handleSubmit, reset } = methods;

  const onSubmitHandler = useCallback(
    async (values: TSearchCacheKeyForm) => {
      const { data, success } = await sendRequest(values.key);
      if (success) {
        setData(data);
        setKey(values.key);
        setOpen(true);
        reset();
      }
    },
    [sendRequest, reset]
  );

  const onClose = () => setOpen(false);

  return (
    <>
      <Modal open={open} onClose={onClose} maxDialogWidth="sm">
        <SearchCacheKeyModal data={data} searchKey={key} />
      </Modal>
      <SettingsCacheContainer
        title={t(translationPath + "search_cache_key")}
        description={t(translationPath + "search_cache_description")}
        headerWidth={!matches ? "120%" : "100%"}
        gap={!matches ? 4 : 0}
      >
        <Form {...methods}>
          <Box
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmitHandler)}
            sx={{
              mt: !matches ? 0 : 2,
              width: !matches ? 700 : "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Grid container columnSpacing={!matches ? 2 : 0}>
              <Grid item xs={8} sm={9} md={9} lg={9}>
                <FormInput
                  size="small"
                  name="key"
                  label={t(translationPath + "search_key")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4} sm={3} md={3} lg={3} textAlign={"end"}>
                <Button
                  variant="contained"
                  disabled={loading ? true : false}
                  type="submit"
                >
                  {loading && (
                    <Stack direction={"row"} spacing={2}>
                      <CircularProgress size={25} sx={{ marginInline: 2 }} />
                    </Stack>
                  )}
                  {!loading && t(translationPath + "search")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Form>
      </SettingsCacheContainer>
    </>
  );
};

export default SearchCacheKey;
