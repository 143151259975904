import { AutoCompleteMulti, ISelectType } from "@4uhub/lib4uhub";
import MainApi from "../../../../services/mainApi.service";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const SOFTWARE_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Software/";

const softwareList = new MainApi<ISelectType>(SOFTWARE_ROUTE);

const EstablishmentSoftwares = () => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={12}>
      <AutoCompleteMulti
        getOptionLabel={(option) => {
          return option.name;
        }}
        size="small"
        label={t("page.register.establishments.softwares") || "Applications"}
        name="softwares"
        request={softwareList.list}
      />
    </Grid>
  );
};

export default EstablishmentSoftwares;
