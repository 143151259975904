import MainApiService from "../../services/mainApi.service";

import { ICreateSalesGrantor, ISalesGrantor } from "../../models/sales-grantors";

export const SALES_GRANTORS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/salesGrantor/";

export const grantorsService = new MainApiService<ISalesGrantor>(SALES_GRANTORS_ROUTE);
export const grantorsFormService = new MainApiService<ICreateSalesGrantor>(SALES_GRANTORS_ROUTE);

export const grantorsTranslationPath = "page.4uSalesContract.registers.grantors.";

export const buildTPath = (field: string, fallback?: string): string => {
    return `${grantorsTranslationPath}${field}` || fallback as string;
};