import { Modal, WhatsappChat } from "@4uhub/lib4uhub";
import {
  Box,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { memo, useState } from "react";
import SmartphoneIcon from "@mui/icons-material/Smartphone";

interface IPreviewProps {
  text: string;
}

export const Preview = memo(({ text }: IPreviewProps) => {
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xl"));

  const openModalHandler = () => setOpen(true);

  const closeModalHandler = () => setOpen(false);

  if (matches) {
    return (
      <>
        <Modal open={open} onClose={closeModalHandler} title="Preview">
          <WhatsappChat
            body={text}
            buttonsList={[]}
            ctaButtons={[]}
            customButtons={[]}
            onCloseAllOptions={() => {}}
            openAllOptions={() => {}}
            showAllOptions={false}
          />
        </Modal>
        <Tooltip title="Ver preview">
          <IconButton onClick={openModalHandler}>
            <SmartphoneIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  return (
    <Box sx={{ width: "20%" }}>
      <WhatsappChat
        body={text}
        buttonsList={[]}
        ctaButtons={[]}
        customButtons={[]}
        onCloseAllOptions={() => {}}
        openAllOptions={() => {}}
        showAllOptions={false}
      />
    </Box>
  );
});
