import { Button, Modal } from "@4uhub/lib4uhub";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface INotAvaiableModalProps {
  open: boolean;
  onClose: () => void;
}

const NotAvaiableModal: React.FC<INotAvaiableModalProps> = ({
  onClose,
  open,
}) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} open={open}>
      <Stack gap={1}>
        <Typography>
          {t("components.scheduleAssistant.newSchedule.notAvaiableModal.description")}
        </Typography>
        <Button variant="contained" onClick={onClose}>
          {t("components.scheduleAssistant.newSchedule.notAvaiableModal.button")}
        </Button>
      </Stack>
    </Modal>
  );
};

export default NotAvaiableModal;
