import { Grid } from "@mui/material";
import { useFieldArray } from "react-hook-form";

import {
  FormArray,
  IInputFile,
  ISelectType,
  useFormContext,
} from "@4uhub/lib4uhub";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import NewsFile from "./ContentFile";
import { TContentForm } from "../ContentSchema";
import { IGetContentMediaFile } from "../../../../models/content";

const translationPath = "page.register.extensions.";

const ContentFiles = ({
  onDeleteFileHandler,
  allLanguages,
  contentMediaFiles,
  mediaTypeCode,
}: {
  onDeleteFileHandler: (file: IInputFile) => void;
  allLanguages: ISelectType[];
  contentMediaFiles: IGetContentMediaFile[];
  mediaTypeCode?: string;
}) => {
  const { t } = useTranslation();
  const { control, formState } = useFormContext<TContentForm>();

  const contentMediasArray = useFieldArray<
    TContentForm,
    "contentMediaFiles",
    "key"
  >({
    control,
    name: "contentMediaFiles",
    keyName: "key",
  });

  const fieldsObject = useCallback(
    (index: number) => (
      <NewsFile
        onFileSelected={onDeleteFileHandler}
        index={index}
        allLanguages={allLanguages}
        mediaTypeCode={mediaTypeCode}
        contentMediaFiles={contentMediaFiles}
      />
    ),
    [onDeleteFileHandler, allLanguages, contentMediaFiles, mediaTypeCode]
  );

  const onDeleteVoidHandler = (data: any) => {
    if (data.file) {
      onDeleteFileHandler(data.file);
    }
  };

  return (
    <Grid item xs={12}>
      <FormArray
        title={t(translationPath + "media_files")}
        name="contentMediaFiles"
        errorMessage={formState.errors?.contentMediaFiles?.message}
        addButtonLabel={`${t(translationPath + "add_media_file")}`}
        formArray={contentMediasArray}
        onDeleteVoid={onDeleteVoidHandler}
        appendValue={{
          file: {
            id: "",
          },
          captions: allLanguages.map((lang) => ({
            language: lang.id,
            caption: "",
          })),
        }}
        fieldsObject={fieldsObject}
      />
    </Grid>
  );
};

export default ContentFiles;
