import { memo } from "react";
import { ContractProductHistory } from "../../../ContractProductHistories/ContractProductHistoryForm/ContractProductHistory";
import { useSalesContract } from "../../../../../../store/contexts/SalesContractContext";
import { SalesProductCtrPriceList } from "../../../SalesProductCtrPriceList/SalesProductCtrPriceListForm/SalesProductCtrPriceList";

export const RenderForms = memo(() => {
  const { propId, tabName } = useSalesContract();
  switch (tabName) {
    case "history":
      return <ContractProductHistory id={propId} />;
    case "priceList":
      return <SalesProductCtrPriceList id={propId} />;
    default:
      return null;
  }
});
