import { z } from "zod";

const translationPath = "components.settingsGeneral.errors.";

export const settingsGeolocalizationSchema = z.object({
  apiGeocodingKey: z.string().min(1, translationPath + "api_geocoding_key"),
});

export type TSettingsGeolocalizationForm = z.infer<
  typeof settingsGeolocalizationSchema
>;
