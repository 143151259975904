import {
  Autocomplete,
  AutocompleteRenderOptionState,
  Chip,
  TextField,
  TextFieldProps,
} from "@mui/material";
import {
  HTMLAttributes,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AxiosResponse } from "axios";
import {
  useDebounce,
  useFetch,
  useFormContext,
  IProfile,
} from "@4uhub/lib4uhub";

type IFormInputProps = {
  name: string;
  request?: (params?: any) => Promise<AxiosResponse<IProfile[]>>;
  options?: IProfile[];
  params?: any;
  onValueSelected?: (data: IProfile) => void;
  SearchAsYouTypeParams?: (typedValue: string) => any;
  getOptionLabel?: ((option: IProfile) => string) | undefined;
  isOptionEqualToValue?: (option: IProfile, value: IProfile) => boolean;
  trigger?: string | string[];
  disable?: boolean;
  renderOption?:
    | ((
        props: HTMLAttributes<HTMLLIElement>,
        option: IProfile,
        state: AutocompleteRenderOptionState
      ) => ReactNode)
    | undefined;
} & TextFieldProps;

const AutoCompleteMulti = ({
  name,
  options,
  params,
  trigger,
  disable = false,
  onValueSelected,
  getOptionLabel = (option) => {
    return option.name;
  },
  SearchAsYouTypeParams,
  isOptionEqualToValue = (o, v) => {
    return o.id === v.id;
  },
  request,
  renderOption = (props, option) => {
    return (
      <li {...props} key={option.id}>
        {option.name}
      </li>
    );
  },
  ...otherProps
}: IFormInputProps) => {
  const { control, trigger: callTrigger } = useFormContext();
  const [realOptions, setOptions] = useState<IProfile[]>(options || []);
  const { loading, sendRequest } = useFetch(request);
  const { t } = useTranslation();
  const [value, setValue] = useState<string>("");

  const searchQuery: string = useDebounce(value, 500);

  const loadRequest = useCallback(
    async (parameters?: any) => {
      const { data } = await sendRequest(parameters);

      if (data) {
        setOptions(data);
      }
    },
    [sendRequest]
  );

  //Debounce get function
  useEffect(() => {
    if (SearchAsYouTypeParams) {
      if (searchQuery !== "" && searchQuery.length > 2) {
        loadRequest(SearchAsYouTypeParams(searchQuery));
      }
    }
  }, [searchQuery, SearchAsYouTypeParams, loadRequest]);

  useEffect(() => {
    if (!SearchAsYouTypeParams) {
      loadRequest(params);
    }
  }, [loadRequest, SearchAsYouTypeParams, params]);

  return (
    <Controller
      render={({ fieldState: { error }, field, formState }) => {
        const { onChange, value, ref } = field;
        return (
          <Autocomplete
            multiple
            value={value || []}
            disabled={disable ? true : false}
            noOptionsText={t("components.autocomplete.noOptions")}
            isOptionEqualToValue={isOptionEqualToValue}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            loading={loading}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option.name}
                  color="default"
                  size="small"
                  {...getTagProps({ index })}
                />
              ))
            }
            options={options ? options : realOptions}
            onChange={(event: any, newValue) => {
              onChange(newValue);
              if (trigger) {
                callTrigger(trigger);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                {...otherProps}
                error={!!error}
                onChange={(e) => setValue(e.target.value)}
                inputRef={ref}
                helperText={
                  error
                    ? t(error?.message || "")
                    : SearchAsYouTypeParams
                    ? t("components.autocomplete.hint")
                    : ""
                }
                fullWidth
              />
            )}
          />
        );
      }}
      defaultValue={[]}
      name={name}
      control={control}
    />
  );
};

export default memo(AutoCompleteMulti);
