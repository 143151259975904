import { MainApiService } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";

export class MainApi<T extends any> extends MainApiService<T> {
  constructor(rp: string) {
    super(rp, GlobalAxios);
  }
}

export default MainApi;
