import { motion } from "framer-motion";
import { memo } from "react";
import { IEventsContainerProps } from "./models";

export const EventsContainer = memo(({ children }: IEventsContainerProps) => {
  return (
    <motion.main
      id="main"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, ease: [0.6, -0.05, 0.01, 0.99] }}
    >
      {children}
    </motion.main>
  );
});
