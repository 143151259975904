import React from "react";
import { Controller } from "react-hook-form";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { IDateTime } from "./models";
import { useFormContext } from "@4uhub/lib4uhub";

export const DateTime = ({ name, label, initialDate }: IDateTime) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={initialDate ? initialDate : null}
      render={(field) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker<any>
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label={label}
              ampm={false}
              format={"dd/MM/yyyy HH:mm"}
              value={field.field.value ? new Date(field.field.value) : null}
              onChange={(v: any) => {
                field.field.onChange(new Date(v));
              }}
              {...field}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};
