import { ptBR, enUS } from "date-fns/locale";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";
import { useDispatch } from "react-redux";

import { IDocument, TDocType } from "../../../../models/documents";
import MainApiService from "../../../../services/mainApi.service";
import DocumentsApiService from "../../../../services/documents.service";
import DocsVersionApiService, {
  IDocsVersion,
} from "../../../../services/docsVersion.service";
import { useAppSelector } from "../../../../store/store";
import { documentsSliceActions } from "../../../../store/slices/documents";

import DocumentsModal from "./DocumentsModal/DocumentsModal";
import DocumentsVersion from "./DocumentsVersion/DocumentsFilter";
import NoData from "../../../../components/Logs/NoData/NoData";
import TableContainer from "../../../../components/TableList/TableContainer";
import { Modal, useFetch, useRole } from "@4uhub/lib4uhub";

const TRANSLATION_PATH = "page.register.documents.";
const DOC_TYPE_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/DocType/";

const service = new MainApiService<TDocType>(DOC_TYPE_ROUTE);
const docListService = new DocumentsApiService();
const lastVersionService = new DocsVersionApiService();

const DocumentsPage = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [docType, setDocType] = useState<TDocType[]>();
  const [itemsList, setItemsList] = useState<IDocument[]>([]);
  const [lastVersion, setLastVersion] = useState<IDocsVersion>();
  const [open, setOpen] = useState(false);
  const [versionId, setVersionId] = useState<string>();
  const document = useAppSelector((state) => state.documents);
  const dispatch = useDispatch();
  const readPermission = useRole(["Read"]);

  const { sendRequest } = useFetch(service.list);
  const { sendRequest: loadItems } = useFetch(docListService.list);
  const { sendRequest: loadVersion } = useFetch(lastVersionService.getItemById);

  const updateItemsList = useCallback(
    async (selectedDocType: TDocType) => {
      const { data } = await loadItems(
        document ? document.id : selectedDocType.id
      );
      if (data) {
        setItemsList(data.items);
      }
    },
    [loadItems, document]
  );

  useEffect(() => {
    const loadRequest = async () => {
      const { data } = await sendRequest(null);
      if (data) {
        setDocType(data);
      }
    };

    loadRequest();
  }, [sendRequest]);

  useEffect(() => {
    const loadItems = async () => {
      if (document) {
        const { data } = await loadVersion(document.id);
        if (data) {
          setLastVersion(data);
        }

        updateItemsList(document);
      } else {
        setLastVersion(undefined);
        setItemsList([]);
      }
    };
    loadItems();
  }, [document, updateItemsList, loadVersion]);

  const handleOpen = (id: string) => {
    setVersionId(id);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "version",
        headerName: `${t(TRANSLATION_PATH + "version") || "Version"}`,
      },
      {
        field: "date",
        headerName: `${t(TRANSLATION_PATH + "date") || "Date"}`,
        renderCell: (params) => {
          const date = new Date(params.value);
          return format(date, "P pp", {
            locale: language === "pt-BR" ? ptBR : enUS,
          });
        },
      },
      {
        field: "document",
        headerName: `${t(TRANSLATION_PATH + "document") || "Document"}`,
        width: 180,
        renderCell: (params) => {
          return (
            <Button
              variant="outlined"
              onClick={() => handleOpen(params.row.id)}
            >
              {t(TRANSLATION_PATH + "see_document") || "See document"}
            </Button>
          );
        },
      },
    ],
    [t, language]
  );

  const handleOnChange = (newValue: TDocType | null) => {
    if (!newValue) {
      dispatch(documentsSliceActions.removeDocument());
    } else {
      dispatch(documentsSliceActions.saveDocument(newValue));
    }
  };

  const rows = itemsList?.map((item) => ({
    id: item.id,
    version: item.version,
    date: item.versionDate,
  }));

  const hasReadPermission = document && !lastVersion?.version && readPermission;

  return (
    <TableContainer
      lastVersion={lastVersion!}
      docTypeSelected={document}
      columns={columns}
      rows={rows || []}
    >
      <Box sx={{ maxWidth: 300 }}>
        <Autocomplete<TDocType>
          value={document}
          size="small"
          onChange={(event: any, newValue) => {
            handleOnChange(newValue);
          }}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          getOptionLabel={(option) => {
            return option.name || "";
          }}
          options={docType || []}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                t(TRANSLATION_PATH + "select_doc_type") ||
                "Select document type"
              }
            />
          )}
        />
      </Box>
      {document && lastVersion?.version && (
        <>
          <Box sx={{ maxWidth: "100%" }}>
            <DocumentsVersion
              lastVersion={lastVersion!}
              docTypeSelected={document.id || ""}
            />
          </Box>
          <Modal open={open} onClose={handleClose} maxDialogWidth="md">
            <DocumentsModal
              open={open}
              onClose={handleClose}
              versionId={versionId || ""}
            />
          </Modal>
        </>
      )}
      {!document && (
        <NoData
          message={
            t(TRANSLATION_PATH + "no_docs_selected") ||
            "No document type was selected"
          }
        />
      )}
      {hasReadPermission && (
        <NoData
          message={
            t(TRANSLATION_PATH + "no_docs_version") ||
            "Document type does not have any version"
          }
        />
      )}
    </TableContainer>
  );
};

export default DocumentsPage;
