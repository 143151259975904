import { GlobalAxios } from "./../axios/axios-config";
import { IGetUserConfig, IUpdateUserConfig } from "../models/configurations";

const CREATE_ROUTE =
  process.env.REACT_APP_IDENTIY +
  "/api/v1/UserConfigurations/CreateUpdateUserConfigurationAdmin";

const GET_ROUTE =
  process.env.REACT_APP_IDENTIY +
  "/api/v1/UserConfigurations/GetUserConfigurationAdmin";

export class ConfigurationsService {
  create = (item: IUpdateUserConfig) => {
    return GlobalAxios.post(CREATE_ROUTE, {
      ...item,
    });
  };

  getAll = () => {
    return GlobalAxios.get<IGetUserConfig>(GET_ROUTE);
  };
}

export default ConfigurationsService;
