import { AutoComplete, ISelectType, useFormContext } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";
import { TProtocolGenerateForm } from "./ProtocolGenerateSchema";

const translationPath = "chatBot.page.service.protocolGenerateRule.";

const Position = ({
  fieldIndex,
  loadDefaultOrder,
  showButton,
}: {
  fieldIndex: number;
  loadDefaultOrder: (lang: ISelectType) => void;
  showButton: (show: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext<TProtocolGenerateForm>();
  const [orderOptions, setOrderOptions] = useState<ISelectType[]>([]);
  const [options] = useState<ISelectType[]>([
    { id: "1", name: "1" },
    { id: "2", name: "2" },
    { id: "3", name: "3" },
    { id: "4", name: "4" },
    { id: "5", name: "5" },
    { id: "6", name: "6" },
  ]);

  const selectedOrderValues = useWatch({
    name: "ticketProtocolRuleDetails",
    control,
  });

  const filterOrderOptions = useCallback(() => {
    if (selectedOrderValues) {
      const selectedOrder = selectedOrderValues.find(
        (lang, indexLang) => indexLang === fieldIndex
      );
      const filteredOptions = options.filter(
        (objeto2) =>
          !selectedOrderValues.some(
            (objeto1) => objeto1.position?.id === objeto2.id
          )
      );

      if (filteredOptions && filteredOptions.length !== 0) {
        showButton(true);
      }
      if (filteredOptions.length === 0) {
        showButton(false);
      }

      loadDefaultOrder(filteredOptions[0]);

      if (selectedOrder && selectedOrder.position) {
        setOrderOptions([...filteredOptions, selectedOrder.position]);
      } else {
        setOrderOptions(filteredOptions);
      }
    }
  }, [fieldIndex, selectedOrderValues, options, loadDefaultOrder, showButton]);

  useEffect(() => {
    filterOrderOptions();
  }, [filterOrderOptions]);

  return (
    <Grid item xs={12} sm={2}>
      <AutoComplete
        name={`ticketProtocolRuleDetails.${fieldIndex}.position`}
        label={t(translationPath + "order")}
        getOptionLabel={(option) => option.id}
        size="small"
        fullWidth
        options={orderOptions}
        autoFocus
      />
    </Grid>
  );
};

export default Position;
