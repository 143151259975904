import { BackButton, Button } from "@4uhub/lib4uhub";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { memo } from "react";
import { useParams } from "react-router-dom";

interface ISettingsPageContainerAction {
  label: string;
  loading: boolean;
  onClick: () => void;
}

const SettingsPageContainer = ({
  title,
  width,
  action,
  children,
}: {
  title: string;
  width?: string | number;
  action?: ISettingsPageContainerAction;
  children: any;
}) => {
  const { eI } = useParams();

  return (
    <Box flexDirection={"column"}>
      <BackButton url={`/e/${eI}/dashboard/settings`} />
      <Box
        width={width}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h5">{title}</Typography>
        {action && (
          <Button
            variant="contained"
            loading={action.loading}
            onClick={action.onClick}
          >
            {!action.loading && action.label}
          </Button>
        )}
      </Box>
      {children}
    </Box>
  );
};

export default memo(SettingsPageContainer);
