import { Box } from "@mui/material";
import {
  eachDayOfInterval,
  lastDayOfMonth,
  startOfMonth,
  startOfWeek,
  endOfWeek,
  isSameMonth,
} from "date-fns";
import { memo, useMemo } from "react";

import useCalendar from "../CalendarContext/useCalendar";
import { getMonthDayInfo } from "../../utils";
import Day from "./Day";

const MonthCalendar = () => {
  const { date, calendars } = useCalendar();

  const firstDateOfMonth = useMemo(() => startOfMonth(date), [date]);

  const lastDateOfMonth = useMemo(() => lastDayOfMonth(date), [date]);

  const MONTH_DAYS = useMemo(
    () =>
      eachDayOfInterval({
        start: startOfWeek(firstDateOfMonth),
        end: endOfWeek(lastDateOfMonth),
      }),
    [firstDateOfMonth, lastDateOfMonth]
  );

  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: `repeat(7, ${100 / 7}%)`,
          gridAutoRows: `${100 / (MONTH_DAYS.length / 7)}%`,
        }}
        height={"calc(100% - 24px)"}
      >
        {MONTH_DAYS.map((d, i) => {
          const { allAvaible, dates, isFull, someAvaiable } = getMonthDayInfo(
            d,
            calendars
          );

          return (
            <Day
              index={i}
              day={d}
              isFull={isFull}
              hasAvaiable={someAvaiable}
              allAvaiable={allAvaible}
              isSameMonth={isSameMonth(date, d)}
              key={d.getTime()}
              events={dates}
            />
          );
        })}
      </Box>
    </>
  );
};

export default memo(MonthCalendar);
