import { Grid } from "@mui/material";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  FormArray,
  ISelectType,
  MainApiService,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { useCallback, useEffect, useState } from "react";
import ProtocolRuleDetail from "./ProtocolRuleDetail";
import { TProtocolGenerateForm } from "./ProtocolGenerateSchema";
import { IGeneric } from "../../../../../../models/generics";
import { GlobalAxios } from "../../../../../../axios/axios-config";

const translationPath = "chatBot.page.service.protocolGenerateRule.";

const GENERICS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const service = new MainApiService<IGeneric>(GENERICS_ROUTE, GlobalAxios);

const ProtocolRuleDetails = () => {
  const { t } = useTranslation();
  const { control, formState } = useFormContext<TProtocolGenerateForm>();
  const [ticketProtocolRuleFieldList, setTicketProtocolRuleFieldList] =
    useState<ISelectType[]>([]);
  const [defaultOrder, setDefaultOrder] = useState<ISelectType>();
  const [showButton, setShowButton] = useState(true);

  const { sendRequest } = useFetch(service.list);

  const loadTicketProtocolRuleField = useCallback(async () => {
    const { data } = await sendRequest({
      identifier: "TicketProtocolRuleFieldType",
    });
    if (data) {
      setTicketProtocolRuleFieldList(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    loadTicketProtocolRuleField();
  }, [loadTicketProtocolRuleField]);

  const protocolRuleDetailsArray = useFieldArray<
    TProtocolGenerateForm,
    "ticketProtocolRuleDetails",
    "key"
  >({
    control,
    name: "ticketProtocolRuleDetails",
    keyName: "key",
  });

  const loadDefaultOrder = useCallback((order: ISelectType) => {
    setDefaultOrder(order);
  }, []);

  const fieldsObject = useCallback(
    (index: number) => (
      <ProtocolRuleDetail
        ticketProtocolRuleFieldList={ticketProtocolRuleFieldList}
        fieldIndex={index}
        loadDefaultOrder={loadDefaultOrder}
        showButton={setShowButton}
      />
    ),
    [ticketProtocolRuleFieldList, loadDefaultOrder]
  );

  return (
    <Grid item xs={12}>
      <FormArray
        title={t(translationPath + "protocol_rule_details")}
        name="protocolRuleDetails"
        errorMessage={formState.errors?.ticketProtocolRuleDetails?.message}
        showAddButton={showButton}
        addButtonLabel={
          t(translationPath + "add_protocol_rule_details") ||
          "Add Protocol Rule Detail"
        }
        formArray={protocolRuleDetailsArray}
        appendValue={{
          fixedCode: null,
          mask: null,
          position: defaultOrder ? defaultOrder : { id: "1", name: "1" },
        }}
        fieldsObject={fieldsObject}
      />
    </Grid>
  );
};

export default ProtocolRuleDetails;
