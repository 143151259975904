import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table } from "@4uhub/lib4uhub";
import MainApi from "../../../../../../services/mainApi.service";
import { IProtocolGenerate } from "../../../../../../models/protocol-generate";
import { useState } from "react";

const SCHEDULE_CONFIRMATION_ROUTE =
  process.env.REACT_APP_CALENDAR + "/api/v1/TasySchedConfirmationRules/";
const protocolGenerateService = new MainApi<IProtocolGenerate>(
  SCHEDULE_CONFIRMATION_ROUTE
);

const translationPath = "page.settings.schedule.scheduleConfirmation.";

const ScheduleConfirmationPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { eI } = useParams();

  const [data, setData] = useState<IProtocolGenerate[]>([]);

  const [deletedData, setDeletedData] = useState<IProtocolGenerate | null>(
    null
  );

  const columns: GridColDef<IProtocolGenerate>[] = [
    {
      field: "time",
      headerName: t(translationPath + "time") || "Time",
      flex: 1,
    },
  ];

  const handleAdd = () => {
    navigate("new");
    setDeletedData(null);
  };

  const handleRowClick = (params: GridRowParams<IProtocolGenerate>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<IProtocolGenerate>
      showDefaultMacroFilters={false}
      service={protocolGenerateService}
      onFetchData={setData}
      onFetchDelete={setDeletedData}
      columns={columns}
      title={t(translationPath + "schedule_confirmation")}
      searchInput={false}
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      backButton
      showButton={data.length === 0 || !!deletedData}
      backButtonUrl={`/e/${eI}/dashboard/settings`}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a schedule confirmation"
      }
      addButtonLabel={
        t(translationPath + "add_schedule_confirmation") ||
        "Add Schedule Confirmation"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default ScheduleConfirmationPage;
