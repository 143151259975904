import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";

const KINSHIP_DEGREE_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/KinshipDegree/";

export const listAllKinshipDegree = (params?: any) => {
  return GlobalAxios.get<ISelectType[]>(
    KINSHIP_DEGREE_ROUTE + "list/all",
    params
  );
};
