import { z } from "zod";

const translationPath = "page.register.cities.errors.";

const provincySchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "provincy" }
);

export const citySchema = z.object({
  id: z.string().optional(),
  name: z.string().nonempty(translationPath + "name"),
  ibgeCode: z.string().nonempty(translationPath + "ibge_code"),
  correioCode: z.string().nonempty(translationPath + "correio_code"),
  provincy: provincySchema,
  enable: z.boolean(),
});

export type TCityForm = z.infer<typeof citySchema>;
