import { memo, useCallback, useEffect, useMemo } from "react";
import { TextField } from "../../../../TextField/TextField";
import { Grid, Tooltip, Typography } from "@mui/material";
import { AutoComplete, ISelectType, useFormContext } from "@4uhub/lib4uhub";
import InfoIcon from "@mui/icons-material/Info";
import { useTemplateWhats } from "../../../../../../store/contexts/TemplateWhatsContext";
import { IAccessSiteActionProps } from "./models";
import { useTranslation } from "react-i18next";

const translationPath = "components.accessSiteAction.";

export const AccessSiteAction = memo(
  ({ index, id }: IAccessSiteActionProps) => {
    const { t } = useTranslation();

    const { getValues, setValue } = useFormContext();

    const selectedUrlType = getValues(`ctaButtons.${index}.urlType`);

    const { isFixedData, updateCtaHandler } = useTemplateWhats();

    const getTextedValue = useCallback(
      (text: string) => {
        updateCtaHandler(id, "text", text);
      },
      [id, updateCtaHandler]
    );

    const URL_TYPES: ISelectType[] = useMemo(() => {
      return [
        { id: "1", name: t(translationPath + "static") },
        { id: "2", name: t(translationPath + "dynamic") },
      ];
    }, [t]);

    const urlPayload = useMemo(
      () => getValues(`ctaButtons.${index}.payload`),
      [getValues, index]
    );

    useEffect(() => {
      if (!urlPayload) return;
      if (urlPayload.match(/\{\{(.+?)\}\}/)?.[1]) {
        setValue(
          `ctaButtons.${index}.urlType`,
          URL_TYPES.find((url) => url.id === "2")
        );
      }
    }, [getValues, setValue, URL_TYPES, urlPayload, index]);

    return (
      <>
        <Grid item xs={12} sm={3}>
          <TextField
            name={`ctaButtons.${index}.text`}
            label={t(translationPath + "text")}
            fullWidth
            size="small"
            maxLength={25}
            onChangeValue={getTextedValue}
            sx={(t) => ({
              background: `linear-gradient(${t.palette.background.default} 40px, transparent 40px)`,
              borderRadius: 1,
            })}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <AutoComplete
            name={`ctaButtons.${index}.urlType`}
            label={t(translationPath + "url_type")}
            getOptionLabel={(option) => option.name}
            defaultValue={
              urlPayload && urlPayload.match(/\{\{(.+?)\}\}/)?.[1]
                ? URL_TYPES[1]
                : URL_TYPES[0]
            }
            options={URL_TYPES}
            clearIcon={false}
            disable={isFixedData}
            fullWidth
            size="small"
            sx={(t) => ({
              background: `linear-gradient(${t.palette.background.default} 40px, transparent 40px)`,
              borderRadius: 1,
            })}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
        >
          <TextField
            name={`ctaButtons.${index}.payload`}
            label={t(translationPath + "payload")}
            placeholder={`${t(translationPath + "url_placeholder")}`}
            fullWidth
            size="small"
            maxLength={2000}
            onChangeValue={(text) => updateCtaHandler(id, "payload", text)}
            sx={(t) => ({
              background: `linear-gradient(${t.palette.background.default} 40px, transparent 40px)`,
              borderRadius: 1,
            })}
          />
          {selectedUrlType && selectedUrlType.id === "2" && (
            <>
              <Typography
                variant="body2"
                sx={(t) => ({
                  color: t.palette.grey[t.palette.mode === "light" ? 600 : 400],
                })}
              >{`{{1}}`}</Typography>
              <Tooltip
                title={t(translationPath + "url_tooltip")}
                placement="right"
              >
                <InfoIcon color="action" sx={{ fontSize: 17 }} />
              </Tooltip>
            </>
          )}
        </Grid>
      </>
    );
  }
);
