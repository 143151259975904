import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

// Schema
import {
  FormContainer,
  FormInput,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import { memo, useCallback, useState } from "react";
import { IEventTypeProps } from "./models";
import { useSearchParams } from "react-router-dom";
import { TEventTypeForm, eventTypeSchema } from "./EventTypeSchema";
import {
  createEventType,
  getEventTypeById,
  updateEventTypeById,
} from "../../../../services/eventType.service";
import FormInformation from "../../../FormInformation/FormInformation";

const translationPath = "page.settings.crm.eventTypes.";

export const EventType = memo(({ changeToGridMode }: IEventTypeProps) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [code, setCode] = useState<string>();

  const backHandler = useCallback(() => {
    changeToGridMode();
    if (searchParams.has("id")) {
      setSearchParams();
    }
  }, [changeToGridMode, setSearchParams, searchParams]);

  const { sendRequest: save, loading: sLoading } = useFetch(createEventType);

  const { sendRequest: update, loading: uLoading } =
    useFetch(updateEventTypeById);

  const { sendRequest: get, loading: gLoading } = useFetch(getEventTypeById);

  const saveHandler = useCallback(
    async (v: TEventTypeForm) => {
      return save({ ...v });
    },
    [save]
  );

  const updateHandler = useCallback(
    async (v: TEventTypeForm, id: string) => {
      return update({
        item: {
          id,
          ...v,
        },
        id,
      });
    },
    [update]
  );

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TEventTypeForm>> => {
      const { data, success } = await get(id);
      if (data) {
        setCode(data.code);
        const newData: TEventTypeForm = {
          ...data,
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <Box sx={{ height: "80vh" }}>
      <FormContainer<TEventTypeForm>
        saveFunction={saveHandler}
        updateFunction={updateHandler}
        getFunction={getHandler}
        backButtonOnClick={backHandler}
        loading={loading}
        title={t(translationPath + "event_type")}
        schema={eventTypeSchema}
        subtitle={t(translationPath + "new_event_type")}
        subtitleWatchField={"name"}
        onSuccess={backHandler}
        redirectOnSave={false}
        isModalForm
        fields={(type) => (
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              display={"flex"}
              justifyContent={type === "create" ? "end" : "space-between"}
              alignItems={"center"}
            >
              {type === "update" && (
                <FormInformation
                  label={t(translationPath + "code")}
                  data={code ?? "-"}
                  minHeight={40}
                />
              )}

              <SwitchInput
                name="enable"
                label={t(translationPath + "enable")}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormInput
                size="small"
                name="name"
                label={t(translationPath + "name")}
                autoFocus
                fullWidth
              />
            </Grid>
          </Grid>
        )}
      />
    </Box>
  );
});
